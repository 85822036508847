// ts-ignore
import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useForm, Controller } from 'react-hook-form';

import {
  Button,
  ButtonContainer,
  Icon,
  InnerContainer,
  MembershipContainer,
  Subtitle,
  TextField,
  Title,
  PhoneInput,
} from 'lib/components';
import useStore from 'lib/hooks/useStore';
import { emailPattern, phonePattern } from 'lib/utils';
import { useMutation } from '@apollo/client';
import { CREATE_SUBSCRIPTIONS_CUSTOMER } from 'lib/graphql/mutations/Subscriptions';
import { useTranslation } from 'react-i18next';

interface Form {
  email: string;
  phone: string;
}

const SubscriptionContactInfo = () => {
  const { setPageIndex, subscriptionOrganization, setSubscriptionCustomer } = useStore();
  const navigate = useNavigate();
  const [createCustomer, { loading }] = useMutation(CREATE_SUBSCRIPTIONS_CUSTOMER);
  const { t: translate } = useTranslation();

  const {
    handleSubmit,
    control,
    formState: { isValid } = {},
    getValues,
  } = useForm<Form>({
    mode: 'onChange',
  });

  useEffect(() => {
    setPageIndex(2);
  }, [setPageIndex]);

  const saveContactInformation = async () => {
    console.warn('input', getValues());
    const input = getValues();
    const { data } = await createCustomer({ variables: { input } });
    if (data?.createMembershipCustomer?.id) {
      setSubscriptionCustomer(data?.createMembershipCustomer);
      navigate(`/memberships/${subscriptionOrganization?.slug}/add-payment-method`);
    }
  };

  const checkKeyDown = (e) => {
    if (e.code === 'Enter' || Number(e.keyCode) === 13) {
      e.preventDefault();
      if (isValid) {
        handleSubmit(saveContactInformation)();
      }
    }
  };

  const goBack = () => {
    navigate(`/memberships/${subscriptionOrganization?.slug}/detail`);
  };

  return (
    <MembershipContainer showBackButton={true} goBackCallback={goBack}>
      <InnerContainer>
        <Icon width={28} height={28} src="chat_alt" />
        <Title>{translate('subscriptions.contactInfo.needYourContact')}</Title>
        <Subtitle>{translate('subscriptions.contactInfo.manageYourPlan')}</Subtitle>
        <Controller
          name="phone"
          control={control}
          defaultValue=""
          rules={{ required: true, pattern: phonePattern }}
          render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
            <TextField
              id="phone"
              variant="filled"
              data-testid="phone"
              data-neuro-label="phone"
              type="tel"
              label={translate('subscriptions.contactInfo.personalNumber')}
              onKeyDown={checkKeyDown}
              InputProps={{
                inputComponent: PhoneInput,
                disableunderline: true,
              }}
              value={value}
              onChange={onChange}
              onBlur={onBlur}
              error={!!error}
              helperText={error ? error?.message : null}
            />
          )}
        />
        <Controller
          name="email"
          control={control}
          defaultValue=""
          rules={{
            required: true,
            pattern: {
              value: emailPattern,
              message: translate('subscriptions.contactInfo.validEmail'),
            },
          }}
          render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => {
            return (
              <TextField
                id="email-input"
                variant="filled"
                data-testid="email"
                data-neuro-label="email"
                label={translate('subscriptions.contactInfo.emailLabel')}
                type="email"
                onKeyDown={checkKeyDown}
                value={value}
                onChange={onChange}
                onBlur={onBlur}
                error={!!error}
                helperText={error ? error?.message : null}
              />
            );
          }}
        />
      </InnerContainer>
      <ButtonContainer>
        <Button p="15px" onClick={saveContactInformation} loading={loading} disabled={loading || !isValid}>
          {translate('common.continue')}
        </Button>
      </ButtonContainer>
    </MembershipContainer>
  );
};

export default SubscriptionContactInfo;
