import useStore from './useStore';

export enum SegmentEventNames {
  CHANGE_LANGUAGE = 'Language Changed',
  OTP_REQUESTED = 'OTP Requested',
  NOT_YOUR_NUMBER = 'Phone Number Change',
  RESEND_CODE = 'OTP Resent',
  OTP_CALL = 'OTP Call',
  SIGNED_IN = 'Signed in',
  SHOW_DETAILS = 'Show Details',
  MAKE_PAYMENT = 'Make a Payment',
  PAYMENT_BACK = 'Payment Canceled',
  PAYMENT_CONFIGURED = 'Payment Configured',
  PAYMENT_METHOD_SELECTED = 'Payment Method Selected',
  PAYMENT_CONFIRMED = 'Payment Confirmed',
  MANAGE_AUTOPAY = 'Manage Autopay',
  AUTOPAY_UPDATED = 'Autopay Updated',
  AUTOPAY_DISABLE = 'Autopay Disable',
  MENU_VIEWED = 'Viewed Menu',
  CHAT_SUPPORT = 'Support Chat',
  SUPPORT_REQUESTED = 'Support Requested',
  SIDEBAR_NAVIGATION = 'Sidebar Navigation',
  EMAIL_UPDATED = 'Email Updated',
  ADD_PAYMENT_METHOD = 'Payment Method Added',
  ORGANIZATION_CALLED = 'Organization Called',
  ORGANIZATION_WEBSITE_VISIT = 'Organization Website Visited',
  SIGNED_OUT = 'Signed Out',
  DUE_DATE_CHANGE = 'Due Date Change',
  PAYMENT_METHOD_REMOVED = 'Payment Method Removed',
  TAKE_HOME_APPROVAL = 'Take Home Approval',
  CALCULATE_PAYOFF = 'Calculate Payoff',
  ONE_TIME_PAYMENT = 'One Time Payment',
  MONTHLY_PAYMENT_PLAN = 'Monthly Payment Plan',
  SETTLEMENT = 'Settlement',
  PHONE_NUMBER_UPDATE = 'Phone Number Update',
}

export enum SegmentPageNames {
  LOGIN = 'landingPage',
  OTP = 'otpRequested',
  PURCHASES = 'patientPurchasesPage',
  APPROVALS = 'patientApprovalsPage',
  MY_ACCOUNT = 'patientPortalMyAccount',
  PAYMENT_SUCCESS = 'paymentMade',
  MODIFY_DUE_DATE = 'updateDueDate',
  PHONE_NUMBER_UPDATE = 'phoneNumberUpdate',
}

const APPLICATION_NAME = 'patientPortal';

function useStandardSegmentMetadata() {
  const { borrower, merchant, organization } = useStore();
  const initialMetadata = {
    patientId: borrower?.id,
    patientName: borrower?.firstName + ' ' + borrower?.lastName,
    patientEmail: borrower?.email,
    patientPhone: borrower?.phone,
    merchantId: merchant?.id,
    organizationId: organization?.id,
    organizationIndustry: organization?.industry,
  };

  const isMetadataValueNullish = <M,>(metadataValue: M) => {
    return metadataValue === null || metadataValue === undefined;
  };

  return Object.fromEntries(
    Object.entries(initialMetadata).filter(([, metadataValue]) => !isMetadataValueNullish(metadataValue)),
  );
}

export const useSegment = () => {
  const standardSegmentMetadata = useStandardSegmentMetadata();
  const trackIdentify = (data?: any) => {
    const { userId, ...rest } = data || {};
    const traits = rest || {};

    try {
      if (userId) {
        // @ts-ignore
        window.analytics.identify(`b-${userId}`, traits);
      } else {
        // @ts-ignore
        window.analytics.identify(traits);
      }
    } catch (e) {
      console.error('Segment Identify Issue');
    }
  };

  const trackPage = (pageName: string, data?: any) => {
    try {
      // @ts-ignore
      window.analytics.page(pageName, { application: APPLICATION_NAME, ...(data || {}) });
    } catch (e) {
      console.error('Segment Track Page Issue');
    }
  };

  const trackSegmentEvent = (eventName: string, data?: any) => {
    try {
      // @ts-ignore
      window.analytics.track(eventName, { application: APPLICATION_NAME, ...(data || {}) });
    } catch (e) {
      console.error(`Segment ${eventName} Issue`);
    }
  };

  return {
    trackPage,
    trackSegmentEvent,
    trackIdentify,
    standardSegmentMetadata,
  };
};
