import { Container, Loading } from 'lib/components';
import useStore from 'lib/hooks/useStore';
import { useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';
import { findPaymentMethodInfo } from 'lib/utils/FindPaymentMethodInfo';

import { Loan, LoanSchedules, LoanCommitment, UpComingPayment } from 'pages/Purchases/type';
import { FormatAmount, isArray } from 'lib/utils';
import { FindPracticeCard } from 'lib/components/FindPracticeCard';
import useGetPaymentMethodsIfNotExists from 'lib/hooks/usePaymentMethods';
import { Body, Button, theme } from '@frontend/cherry-library';
import { CardContainer } from 'lib/components/CardContainer';
import UpcomingPaymentCard from 'pages/Purchases/components/UpcomingPaymentCard';
import { SEGMENT_EVENT_NAMES } from 'lib/constans';
import { useSegment } from 'lib/hooks/useSegment';
import { getSegmentEventBorrowerData } from 'lib/utils/SegmentEventData';

enum scheduleStatuses {
  SCHEDULED = 'SCHEDULED',
  MISSED = 'MISSED',
}

const UpcomingPayments = () => {
  const { setHeaderTitle, loanList, paymentMethods, borrower } = useStore();
  const { trackPage, trackSegmentEvent } = useSegment();
  useGetPaymentMethodsIfNotExists();

  const segmentEventBorrowerData = getSegmentEventBorrowerData(borrower);

  useEffect(() => {
    if (paymentMethods) {
      createUpComingLoans();
    }
  }, [paymentMethods, loanList]);

  const { t: translate } = useTranslation();

  const initialItemsToShow = 3;

  const [sortedUpComingLoans, setSortedUpComingLoans] = useState<UpComingPayment[]>([]);
  const [sortedMissedUpcomingLoans, setSortedMissedUpcomingLoans] = useState<UpComingPayment[]>([]);
  const [itemsToShow, setItemsToShow] = useState(initialItemsToShow);
  let itemsLeft = itemsToShow;

  const groupedByMonth: { [key: string]: UpComingPayment[] } = sortedUpComingLoans.reduce((acc, payment) => {
    const date = new Date(payment?.dueDate).toLocaleString('default', { month: 'long', year: 'numeric' });
    if (!acc[date]) {
      acc[date] = [];
    }
    acc[date].push(payment);
    return acc;
  }, {} as { [key: string]: UpComingPayment[] });

  const showMissedSchedules = paymentMethods && sortedMissedUpcomingLoans?.length !== 0;
  const showPaymentSchedules = paymentMethods && sortedUpComingLoans?.length !== 0;

  const showAllItems = itemsToShow >= sortedUpComingLoans.length;

  useEffect(() => {
    setHeaderTitle('menu.listItems.upcomingPayments');
    trackPage(SEGMENT_EVENT_NAMES.PATIENT_PORTAL.SCHEDULED_PAYMENTS.PAGE_LOAD, segmentEventBorrowerData);
  }, [setHeaderTitle]);

  const createUpComingLoans = useCallback(() => {
    const today = dayjs();
    const upComingLoanList = loanList.reduce((upComingLoans: UpComingPayment[], loan: Loan) => {
      const loanPlans = isArray(loan?.plans)?.[0];
      const isChargeOff = loanPlans?.status === 'CHARGED_OFF' && loanPlans?.subStatus === 'CHARGED_OFF_SUB';
      if (loan?.status === 'AWAITING_FUNDING' || loan?.status === 'FUNDED') {
        const paymentMethodInfo = findPaymentMethodInfo({
          targetId: Number(loan?.installmentPaymentMethodId),
          paymentMethods: paymentMethods,
        });

        let scheduleDetails: UpComingPayment[] = [];
        if (!isChargeOff) {
          scheduleDetails = loan?.schedules
            .filter(
              (schedule) =>
                (schedule?.status === scheduleStatuses.SCHEDULED ||
                  (schedule?.status === scheduleStatuses.MISSED && dayjs(schedule?.dueAt).isAfter(today))) &&
                schedule?.doNotAttempt === false,
            )
            .map((schedule: LoanSchedules) => ({
              loanId: String(loan?.id),
              id: String(schedule?.id),
              displayId: loan?.displayId,
              amount: FormatAmount(schedule?.amount),
              dueDate: schedule?.dueAt,
              practice: loan?.merchant?.name,
              practiceId: loan?.merchant?.id,
              paymentMethod: paymentMethodInfo,
              status: schedule?.status,
            }));
        }

        const commitmentDetails = loan?.commitments
          .filter(
            (commitment) =>
              commitment?.status === 'ACTIVE' &&
              commitment?.progress === 'PENDING' &&
              commitment?.type !== 'PROMISE' &&
              dayjs(commitment?.scheduledDate) >= today,
          )
          .map((commitment: LoanCommitment) => ({
            loanId: String(loan?.id),
            id: String(loan?.id + commitment?.scheduledDate),
            displayId: loan?.displayId,
            amount: FormatAmount(commitment?.amount),
            dueDate: commitment?.scheduledDate,
            practice: loan?.merchant?.name,
            paymentMethod: paymentMethodInfo,
            practiceId: loan?.merchant?.id,
          }));

        upComingLoans.push(...scheduleDetails, ...commitmentDetails);
      }
      return upComingLoans;
    }, [] as UpComingPayment[]);

    const missedPayments = upComingLoanList?.filter((payment: UpComingPayment) => payment.status === 'MISSED');
    const sortedMissedPayments = missedPayments?.sort(
      (a: UpComingPayment, b: UpComingPayment) => new Date(a.dueDate).getTime() - new Date(b.dueDate).getTime(),
    );
    const scheduledPayments = upComingLoanList?.filter(
      (payment: UpComingPayment) => payment.status !== 'MISSED' && dayjs(payment?.dueDate) >= today,
    );
    const sortedScheduledPayments = scheduledPayments?.sort(
      (a: UpComingPayment, b: UpComingPayment) => new Date(a.dueDate).getTime() - new Date(b.dueDate).getTime(),
    );

    setSortedMissedUpcomingLoans(sortedMissedPayments);
    setSortedUpComingLoans(sortedScheduledPayments);
  }, [loanList, paymentMethods]);

  const handleShowMoreItems = () => {
    trackSegmentEvent(
      SEGMENT_EVENT_NAMES.PATIENT_PORTAL.SCHEDULED_PAYMENTS.SHOW_MORE_CLICKED,
      segmentEventBorrowerData,
    );
    setItemsToShow((prevItemsToShow) => {
      if (prevItemsToShow + 5 >= sortedUpComingLoans.length) {
        return sortedUpComingLoans.length;
      } else {
        return prevItemsToShow + 5;
      }
    });
  };

  const missedUpcomingPaymentsList = sortedMissedUpcomingLoans?.map((payment) => (
    <CardContainer key={payment?.id} noPadding>
      <UpcomingPaymentCard payment={payment} />
    </CardContainer>
  ));

  const upcomingPaymentsList = Object.keys(groupedByMonth).map((date) => {
    if (itemsLeft <= 0) return [];
    return (
      <>
        <Wrapper>
          <Line />
          <UppercaseDate color={theme.main.midnightBlue50} size="small">
            {date}
          </UppercaseDate>
          <Line />
        </Wrapper>

        {groupedByMonth[date].map((payment) => {
          if (itemsLeft <= 0) return;
          itemsLeft -= 1;
          return (
            <CardContainer key={payment?.id} noPadding>
              <UpcomingPaymentCard payment={payment} />
            </CardContainer>
          );
        })}
      </>
    );
  });

  return (
    <>
      {paymentMethods ? (
        <>
          {!showPaymentSchedules && !showMissedSchedules && (
            <Container block={true}>
              <CardContainer minHeight="128px">
                <NoData>{translate('upcomingPayments.noUpcomingPayment')}</NoData>
              </CardContainer>
            </Container>
          )}
          {(showMissedSchedules || showPaymentSchedules) && (
            <Container block={true} marginBottom={60}>
              <WrapperWithGap>
                {showMissedSchedules && missedUpcomingPaymentsList}
                {showPaymentSchedules && upcomingPaymentsList}
                {!showAllItems && (
                  <TransparentButton fullWidth variant="secondary" onClick={handleShowMoreItems}>
                    {translate('files.tableRow.loadBtn')}
                  </TransparentButton>
                )}
              </WrapperWithGap>
            </Container>
          )}
          {!showPaymentSchedules && !showMissedSchedules && <FindPracticeCard isSchedulePage />}
        </>
      ) : (
        <Loading transparent={true} />
      )}
    </>
  );
};

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
`;

const Line = styled.div`
  flex-grow: 1;
  height: 1px;
  background-color: ${theme.main.midnightBlue20} !important;
`;

const NoData = styled(Body)`
  z-index: 1;
  padding: 48px 16px;
  align-self: center;
`;

const TransparentButton = styled(Button)`
  background-color: transparent !important;
  margin-top: 16px;
`;

const UppercaseDate = styled(Body)`
  text-transform: uppercase;
  margin: 0px 8px;
`;

const WrapperWithGap = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

export default UpcomingPayments;
