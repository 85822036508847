import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import GenericFileModal from 'pages/Documents/components/GenericFile';

interface Props {
  open: boolean;
  onCloseModal: () => void;
  type: string;
}

const style: any = {
  position: 'absolute',
  display: 'flex',
  flexDirection: 'column',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 480,
  bgcolor: 'background.paper',
  border: '2px solid #fff',
  padding: '0px 20px',
};

export const ErrorModals = ({ onCloseModal, open, type }: Props) => {
  const { t: translate } = useTranslation();

  return (
    <GenericFileModal
      open={open}
      title={translate(`documentRequest.error.${type}`)}
      mainButtonText={translate('general.okay')}
      onMainButtonClick={onCloseModal}
      handleClose={onCloseModal}
    >
      <Text key={`error-modal-text-${type}`}> {translate(`documentRequest.error.subtitle.${type}`)}</Text>
    </GenericFileModal>
  );
};

const Text = styled.div`
  color: ${(props) => props.theme.main.midnightBlue};
  text-align: center;
  font-size: 14px;
  font-weight: 400;
`;
