import { gql } from '@apollo/client/core';

export const GET_APPLICATION_LIST = gql`
  query fetchApplicationList($input: ApplicationListingRequest) {
    fetchApplicationList(input: $input) {
      success
      code
      message
      total
      contents {
        id
        merchantId
        purchaseAmount
        balanceAvailable
        createdAt
        updatedAt
        expireAt
        approvalBoost
        displayId
        status
        organizationId
        approvalExtended
        validity
        maxEligible
        features {
          idFeature
          status
          type
        }
        organization {
          slug
          name
          website
          phone
          id
          code
        }
        creditLineIncrease
        addressInfo {
          id
          createdAt
          street
          zip
          city
          state {
            name
            code
          }
        }
        latestCLI {
          amount {
            requested
          }
          status
        }
      }
    }
  }
`;
