import styled from 'styled-components';

import { RadioButton } from 'lib/components';

interface Props {
  paymentType: string;
  setPaymentType: (value: string) => void;
  children: React.ReactNode;
  type: string;
  inputComponent?: any;
}

export const PaymentTypeItem = ({ paymentType, setPaymentType, children, type, inputComponent }: Props) => {
  const setPaymentTypeHandler = () => {
    setPaymentType(type);
  };

  return (
    <Container
      data-testid="paymentTypeItem"
      onClick={setPaymentTypeHandler}
      checked={paymentType === type}
      paymentType={paymentType}
    >
      <InnerContainer>
        <RadioContainer>
          <RadioButton checked={paymentType === type} value={type} />
        </RadioContainer>
        {children}
      </InnerContainer>
      {inputComponent && paymentType === type && <SubChildren>{inputComponent()}</SubChildren>}
    </Container>
  );
};

const Container = styled.div<{ checked: boolean; paymentType: string }>`
  cursor: pointer;
  min-height: 88px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  border: ${(props) =>
    props.checked ? `2px solid ${props.theme.main.green}` : `2px solid ${props.theme.main.cardBorderColor}`};
  background-color: ${(props) => props.theme.main?.[props.checked ? 'lightGreen' : 'white']};
  opacity: ${(props) => (props.checked || props.paymentType === '' ? 1 : 0.7)};
  border-radius: 4px;
  width: 100%;
  margin-bottom: 16px;
`;

const InnerContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;

const SubChildren = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-self: stretch;
  margin: 0px 24px 24px 24px;
`;

const RadioContainer = styled.div`
  min-width: 20px;
  padding-left: 10px;
`;
