import React, { useRef, useState } from 'react';
import { useAlert } from 'react-alert';
import styled from 'styled-components';

import { convertNewlinesToBr, getBase64 } from 'lib/utils';
import FileList from 'pages/Documents/components/FileList';
import RemoveFileModal from 'pages/Documents/components/RemoveFileModal';
import GenericFileModal from 'pages/Documents/components/GenericFile';
import { ErrorModals } from 'pages/Documents/components/ErrorModals';

import { SUBMIT_INFORMATION_TO_DOCUMENT_REQUEST, UPDATE_FILE_FOR_DOCUMENT_REQUEST } from 'lib/graphql/mutations/File';
import { useApolloClient } from '@apollo/client';
import ConfirmSubmissionModal from 'pages/Documents/components/ConfirmSubmissionModal';
import useStore, { DocumentRequest } from 'lib/hooks/useStore';
import { useTranslation } from 'react-i18next';

interface FileUploadModalProps {
  open: boolean;
  handleClose: () => void;
  documentRequestObject: DocumentRequest;
}
interface UploadFileDocumentResponse {
  data: { updateFileForDocumentRequest: { success: boolean } };
}

interface File {
  fileName: string;
  data: string;
  mime: string;
}

interface ModalConfig {
  open: boolean;
}

interface ErrorModalConfig extends ModalConfig {
  type: any;
}

interface RemoveFileModalConfig extends ModalConfig {
  removedFileIndex: number | null;
}

interface ConfirmSubmissionModalConfig extends ModalConfig {
  numberOfDocs: number;
}
const defaultErrorModalState = { open: false, type: null };
const defaultRemoveFileModalState = { open: false, removedFileIndex: null };

const FileUploadModal = ({ open, handleClose, documentRequestObject }: FileUploadModalProps) => {
  const alert = useAlert();
  const client = useApolloClient();
  const { borrower } = useStore();
  const fileInput = useRef<HTMLInputElement>(null);
  const [fileList, setFileList] = useState<File[]>([]);
  const [errorModal, setErrorModal] = useState<ErrorModalConfig>(defaultErrorModalState);
  const [removeFileModal, setRemoveFileModal] = useState<RemoveFileModalConfig>(defaultRemoveFileModalState);
  const isFileListVisible = fileList?.length > 0;
  const { id, numberOfDocs, documentTitle, externalNote } = documentRequestObject || {};
  const [confirmSubmissionModalConfig, setConfirmSubmissionModalConfig] = useState<ConfirmSubmissionModalConfig>({
    open: false,
    numberOfDocs,
  });
  const [loading, setLoading] = useState(false);
  const { t: translate } = useTranslation();

  const onSelectFile = (event) => {
    if (event?.target?.files?.[0]) {
      const file = event.target.files[0];

      // 20MB check
      if (file?.size > 20000000) {
        setErrorModal({
          open: true,
          type: 'largeFile',
        });
        resetFileInput();
      } else {
        getBase64(file)
          .then((result) => {
            const { type } = file || {};

            if (
              type === 'application/pdf' ||
              type === 'image/jpeg' ||
              type === 'image/jpg' ||
              type === 'image/png' ||
              type === 'image/heic'
            ) {
              addFile({ name: file.name, mime: file?.type, data: result as string, size: file?.size });
            } else {
              setErrorModal({
                open: true,
                type: 'unsupported',
              });
            }
          })
          .catch((err) => {
            console.warn(err);
            alert.info(translate('documentRequest.upload.fail'));
          });
      }
    } else {
      alert.info(translate('documentRequest.upload.fail'));
    }
  };

  const resetFileInput = () => {
    if (fileInput?.current) {
      fileInput.current.value = '';
    }
  };

  const addFile = async ({ name = Date.now().toString() + '.jpg', mime = 'image/jpeg', data, size }) => {
    try {
      const newFiles = [...fileList, { fileName: name, data, mime, size }];
      setFileList(newFiles);
      resetFileInput();
    } catch (error) {
      setLoading(false);
      setErrorModal({
        open: true,
        type: 'failed',
      });
    }
  };

  const openRemoveFileDialog = (index) => {
    if (Number(index) >= 0) {
      setRemoveFileModal({
        open: true,
        removedFileIndex: index,
      });
    }
  };

  const removeFile = (index) => {
    if (Number(index) >= 0) {
      const newFiles = [...fileList];
      if (typeof index === 'number') {
        newFiles.splice(index, 1);
      }
      setFileList(newFiles);
      setRemoveFileModal(defaultRemoveFileModalState);
    }
  };

  const resetErrorModalState = () => {
    setErrorModal(defaultErrorModalState);
  };

  const resetRemovFileModal = () => {
    setRemoveFileModal(defaultRemoveFileModalState);
  };

  const selectFile = () => {
    if (fileList.length === numberOfDocs) {
      setErrorModal({
        open: true,
        type: 'limitReached',
      });
    } else {
      fileInput?.current?.click();
    }
  };
  const openConfirmSubmissionModal = () => {
    setConfirmSubmissionModalConfig({
      open: true,
      numberOfDocs,
    });
  };

  const handleAddMoreDocuments = () => {
    selectFile();
    setConfirmSubmissionModalConfig({
      open: false,
      numberOfDocs,
    });
  };

  const handleSubmitAnyway = () => {
    setConfirmSubmissionModalConfig({
      open: false,
      numberOfDocs,
    });
    uploadFiles();
  };

  const handleSubmit = () => {
    if (fileList?.length === numberOfDocs) {
      uploadFiles();
    } else {
      openConfirmSubmissionModal();
    }
  };

  const uploadFiles = async () => {
    try {
      setLoading(true);

      const serviceCalls: Promise<unknown>[] = [];
      const relations = documentRequestObject.relations?.map(({ id, type }) => ({ id, type }));

      fileList.forEach(({ fileName, data, mime }) => {
        serviceCalls.push(
          client.mutate({
            mutation: UPDATE_FILE_FOR_DOCUMENT_REQUEST,
            variables: {
              input: {
                file: {
                  name: fileName,
                  data,
                  mime,
                },
                relations: [
                  { id: id, type: 'DOCUMENT_REQUEST_ID' },
                  { id: borrower?.id, type: 'BORROWER' },
                  ...relations,
                ],
              },
            },
          }),
        );
      });

      const responses = (await Promise.all(serviceCalls)) as UploadFileDocumentResponse[];

      if (responses?.every((response) => !!response?.data?.updateFileForDocumentRequest?.success)) {
        submitFiles();
      }
    } catch (error) {
      setLoading(false);
      setErrorModal({
        open: true,
        type: 'failed',
      });
    }
  };
  const handleCloseConfirm = () => {
    setConfirmSubmissionModalConfig({
      open: false,
      numberOfDocs,
    });
  };

  const submitFiles = async () => {
    setLoading(true);

    await client.mutate({
      mutation: SUBMIT_INFORMATION_TO_DOCUMENT_REQUEST,
      variables: {
        input: {
          documentRequestId: id,
        },
      },
    });

    setLoading(false);
    handleClose();
  };

  const formattedExternalNote = { __html: convertNewlinesToBr(externalNote) || '' };

  return (
    <>
      <GenericFileModal
        open={removeFileModal.open || errorModal.open || confirmSubmissionModalConfig.open ? false : open}
        title={translate('documentRequest.upload.documents')}
        mainButtonText={isFileListVisible ? translate('general.submit') : translate('documentRequest.upload.documents')}
        onMainButtonClick={isFileListVisible ? handleSubmit : selectFile}
        buttonLeftText={isFileListVisible ? translate('documentRequest.addFile') : null}
        onButtonLeftClick={selectFile}
        handleClose={handleClose}
        loading={loading}
      >
        <ExternalTitle>{documentTitle}</ExternalTitle>
        <SubTitle dangerouslySetInnerHTML={formattedExternalNote} />
        {isFileListVisible ? <FileList fileList={fileList} onRemove={openRemoveFileDialog} /> : null}
        {isFileListVisible && (
          <Label>
            {translate('documentRequest.numberOfDocs', {
              numberOfDocs: numberOfDocs,
            })}
          </Label>
        )}
        <DesktopInput
          accept="image/*,.pdf,.heic"
          type="file"
          data-testid="upload"
          onChange={onSelectFile}
          ref={fileInput}
        />
      </GenericFileModal>
      {!!errorModal.type && errorModal.open ? (
        <ErrorModals open={errorModal.open} type={errorModal.type} onCloseModal={resetErrorModalState} />
      ) : null}
      {typeof removeFileModal.removedFileIndex === 'number' && removeFileModal.removedFileIndex >= 0 ? (
        <RemoveFileModal
          open={removeFileModal.open}
          fileIndex={removeFileModal.removedFileIndex}
          onCloseModal={resetRemovFileModal}
          onRemove={removeFile}
        />
      ) : null}
      <ConfirmSubmissionModal
        open={confirmSubmissionModalConfig.open}
        requiredNumberOfDocuments={confirmSubmissionModalConfig.numberOfDocs}
        addDocument={handleAddMoreDocuments}
        submitDocuments={handleSubmitAnyway}
        currentNumberOfDocuments={fileList?.length}
        handleClose={handleCloseConfirm}
      />
    </>
  );
};

const SubTitle = styled.div`
  text-align: center;
  font-weight: 400;
  font-size: 14px;
  padding-bottom: 12px;
  line-height: 19.07px;
  display: flex;
  justify-content: center;
`;

const ExternalTitle = styled.div`
  color: ${(props) => props.theme.main.textColor};
  font-size: 16px;
  font-weight: 700;
  display: flex;
  justify-content: center;
  margin-bottom: 24px;
`;
const DesktopInput = styled.input`
  display: none;
`;

const Label = styled.div`
  color: ${(props) => props.theme.main.midnightBlue};
  text-align: center;
  font-size: 14px;
  font-weight: 400;
  margin-top: 12px;
`;
export default FileUploadModal;
