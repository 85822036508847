import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import styled from 'styled-components';
import { Container } from 'lib/components';
import { PREQUAL_URL } from 'config';
import useStore from 'lib/hooks/useStore';
import { calculateExpireDay, FormatAmount, FormatPhoneNumber, useFormatDate } from 'lib/utils';
import { ExternalRedirect } from 'lib/utils/ExternalRedirect';
import { Approval, CreditLineIncrease } from 'pages/Approvals/type';
import { ALLE, ExchangeTokenType, SEGMENT_EVENT_NAMES } from 'lib/constans';
import { Body, Heading, theme, Button, Icon } from '@frontend/cherry-library';
import { ReactComponent as CheckIcon } from 'assets/images/check.svg';
import { ReactComponent as GroupIcon } from 'assets/images/group.svg';
import { ReactComponent as HelpCircleIcon } from 'assets/images/help_circle_outline.svg';
import { ReactComponent as MoreHorizontalIcon } from 'assets/images/more_horizontal.svg';
import SliderModal from 'lib/components/SliderModal/SliderModal';
import { useDenialsFAQ } from 'lib/hooks/useFAQModal';
import { useOrganizationLocations } from 'lib/hooks/useOrganizationLocations';
import { CreditLineIncreaseStatus, useCreditLineIncrease } from 'lib/hooks/useCreditLineIncrease';
import { StatusCard } from 'lib/components/StatusCard';
import { isMoreThan24HoursAgo } from 'lib/utils/TimeCalculations';
import { useSentry } from 'lib/hooks/useSentry';
import { useSegment } from 'lib/hooks/useSegment';
import { getApplicationDetailData } from 'lib/utils/ApplicationDetailSegmentEventData';

export const CompletedCard = () => {
  const { id } = useParams<{ id: string }>();
  const { FormatDate } = useFormatDate();
  const { captureException } = useSentry();
  const { t: translate } = useTranslation();
  const navigate = useNavigate();
  const { fetchLatestCreditLineIncrease } = useCreditLineIncrease();
  const { borrower, selectedApplication: approval, setHeaderTitle, locations, applicationList } = useStore();
  const [showRemaining, setShowRemaining] = useState(approval?.maxEligible !== approval?.balanceAvailable);
  const [modalVisibility, setModalVisibility] = useState(false);
  const [creditRequestedAmount, setCreditRequestedAmount] = useState(null);
  const [showCreditLineDeniedModal, setShowCreditLineDeniedModal] = useState(false);
  const denialsFAQ = useDenialsFAQ();
  const { trackPage, trackSegmentEvent } = useSegment();
  const [application, setApplication] = useState<Approval>(approval);
  const { organization, balanceAvailable, expireAt, creditLineIncrease, features } = application || {};
  const { slug, name } = organization || {};
  const [organizationId, setOrganizationId] = useState(approval?.organizationId);
  const [getOrganizationLocations] = useOrganizationLocations(organizationId);

  const segmentEventData = getApplicationDetailData(borrower, approval);
  const hideModal = () => {
    trackSegmentEvent(
      SEGMENT_EVENT_NAMES.PATIENT_PORTAL.APPROVAL_DETAILS.DISMISS_MORE_ACTIONS_CLICKED,
      segmentEventData,
    );
    setModalVisibility(false);
  };

  const openModal = () => {
    trackSegmentEvent(SEGMENT_EVENT_NAMES.PATIENT_PORTAL.APPROVAL_DETAILS.MORE_ACTIONS_CLICKED, segmentEventData);
    setModalVisibility(true);
  };

  const hasExtendApprovalFeature = () => {
    const findFeature = features?.find(
      (feature) => feature?.type === ExchangeTokenType.APPLICATION_APPROVAL_EXTENSION && feature?.status === 'ACTIVE',
    );
    return !!findFeature;
  };

  const [extendedApproval, setExtendedApproval] = useState(!approval?.approvalExtended && hasExtendApprovalFeature());
  const [expireDate, setExpireDate] = useState(calculateExpireDay(expireAt));

  useEffect(() => {
    if (approval?.status === 'DENIED') {
      setHeaderTitle('application.detail.title');
    } else {
      setHeaderTitle('approval.detail.title');
    }
  }, [setHeaderTitle]);

  useEffect(() => {
    const eventName =
      approval?.status === 'DENIED'
        ? SEGMENT_EVENT_NAMES.PATIENT_PORTAL.DENIAL_DETAILS.PAGE_LOAD
        : SEGMENT_EVENT_NAMES.PATIENT_PORTAL.APPROVAL_DETAILS.PAGE_LOAD;

    trackPage(eventName, segmentEventData);

    checkCreditLineIncrease();
  }, []);

  useEffect(() => {
    setExpireDate(calculateExpireDay(application?.expireAt));
    setShowRemaining(application?.maxEligible !== application?.balanceAvailable);
    setExtendedApproval(!application?.approvalExtended && hasExtendApprovalFeature());
    setOrganizationId(application?.organizationId);
  }, [application]);

  useEffect(() => {
    const foundLoan = applicationList.find((loan) => loan.id === id);
    if (foundLoan) {
      setApplication(foundLoan);
      if (foundLoan?.organization?.code !== ALLE) {
        getOrganizationLocations();
      }
    } else {
      navigate('/portal/approvals');
    }
  }, [id, applicationList]);

  const checkCreditLineIncrease = async () => {
    if (application?.id) {
      try {
        const res = await fetchLatestCreditLineIncrease(application?.id);
        if (res?.id) {
          if (res.status === CreditLineIncreaseStatus.REVIEW) {
            setCreditRequestedAmount(res.amount?.requested);
          } else if (res.status === CreditLineIncreaseStatus.DENIED && !isMoreThan24HoursAgo(res.updatedAt)) {
            setShowCreditLineDeniedModal(true);
          }
        }
      } catch (error) {
        captureException(error, { errorContext: 'checkCreditLineIncrease => ApplicationDetails.tsx' });
      }
    }
  };
  const extendApprovalHandler = () => {
    trackSegmentEvent(SEGMENT_EVENT_NAMES.PATIENT_PORTAL.APPROVAL_DETAILS.REQUEST_MORE_TIME_CLICKED, segmentEventData);
    navigate('/portal/extend-approval', { state: { approval } });
  };

  const checkoutHandler = () => {
    trackSegmentEvent(
      SEGMENT_EVENT_NAMES.PATIENT_PORTAL.APPROVAL_DETAILS.REQUEST_LARGER_APPROVAL_CLICKED,
      segmentEventData,
    );
    hideModal();
    ExternalRedirect(`${PREQUAL_URL}/${slug}`);
  };

  const goToDocuments = () => {
    trackSegmentEvent(
      SEGMENT_EVENT_NAMES.PATIENT_PORTAL.DENIAL_DETAILS.VIEW_ADVERSE_ACTION_LETTER_CLICKED,
      segmentEventData,
    );
    navigate('/portal/documents');
  };

  const goToUseMyBalance = () => {
    trackSegmentEvent(SEGMENT_EVENT_NAMES.PATIENT_PORTAL.APPROVAL_DETAILS.USE_MY_APPROVAL_CLICKED, segmentEventData);
    navigate('/portal/use-my-balance');
  };

  const goToPreviewPayments = () => {
    trackSegmentEvent(SEGMENT_EVENT_NAMES.PATIENT_PORTAL.APPROVAL_DETAILS.PREVIEW_PAYMENTS_CLICKED, segmentEventData);
    navigate('/portal/preview-payments');
  };

  const goToChangeLocation = () => {
    trackSegmentEvent(
      SEGMENT_EVENT_NAMES.PATIENT_PORTAL.APPROVAL_DETAILS.CHANGE_LOCATION_PREFERENCE_CLICKED,
      segmentEventData,
    );
    navigate('/portal/change-location');
  };

  function getDaysText(expireDate) {
    const dayText = translate('applicationDetails.day');
    return expireDate > 1 ? `${dayText}s` : dayText;
  }

  return (
    <>
      {application?.status === 'DENIED' ? (
        <Container block={true}>
          <InnerContainer>
            <WrapperWithGap24>
              <Heading level="4" text={translate(`applicationDetails.unableToApprove`)}></Heading>
              <Body>{translate(`applicationDetails.unableToApproveExplanation`)}</Body>
              <Body>{translate(`applicationDetails.unableToApproveActionLetter`)}</Body>
              <WrapperWithGap8>
                <Button onClick={goToDocuments} variant="primary" fullWidth>
                  {translate(`applicationDetails.viewDocument`)}
                </Button>
                <Button
                  onClick={() => {
                    denialsFAQ.openModal(0);
                  }}
                  variant="secondary"
                  fullWidth
                >
                  {translate(`faq.title`)}
                </Button>
              </WrapperWithGap8>
            </WrapperWithGap24>
          </InnerContainer>
        </Container>
      ) : (
        <Container block={true}>
          <InnerContainer>
            <HeaderContainer>
              <Wrapper>
                <FlexContainer>
                  <Uppercase fontWeight={600} color={theme.main.kellyGreen}>
                    {application?.status}
                  </Uppercase>
                  <CheckIcon color={theme.main.kellyGreen} height={24} width={24} />
                </FlexContainer>

                <Heading level="4" text={`${borrower?.firstName} ${borrower?.lastName}`}></Heading>
                <Body color={theme.main.midnightBlue} size="large" fontWeight={400}>
                  {FormatPhoneNumber(borrower?.phone)}
                </Body>
              </Wrapper>
              <Wrapper>
                {showRemaining ? (
                  <>
                    <UppercaseSmall color={theme.main.midnightBlue} fontWeight={400}>
                      {translate(`applicationDetails.remaining`)}
                    </UppercaseSmall>
                    <Amount>{FormatAmount(balanceAvailable)}</Amount>
                  </>
                ) : (
                  <>
                    <Uppercase fontWeight={400} color={theme.main.midnightBlue}>
                      {translate(`applicationDetails.upTo`)}
                    </Uppercase>
                    <Heading level="2" text={FormatAmount(application?.maxEligible)}></Heading>
                  </>
                )}

                <Heading level="4" text={name}></Heading>
                {expireDate > 5 ? (
                  <Body fontWeight={600} color={theme.main.midnightBlue50}>
                    {translate(`applicationDetails.expires`, { date: FormatDate(application?.expireAt) })}
                  </Body>
                ) : (
                  <>
                    <Body fontWeight={600} color={theme.main.cherryRed}>
                      {translate(`applicationDetails.expiresSoon`)}
                    </Body>
                    <Body fontWeight={600} color={theme.main.cherryRed}>
                      {`${FormatDate(application?.expireAt)} (${expireDate} ${getDaysText(expireDate)})`}
                    </Body>
                  </>
                )}
                <Uppercase size="small" fontWeight={600}>
                  {translate('general.applicationId')}: {approval?.displayId}
                </Uppercase>
                {creditRequestedAmount && (
                  <StatusPill>
                    {translate('applicationDetails.creditLine.inReview', {
                      amount: FormatAmount(creditRequestedAmount),
                    })}
                  </StatusPill>
                )}
              </Wrapper>
            </HeaderContainer>
          </InnerContainer>
          {showCreditLineDeniedModal && (
            <StatusCard
              title={translate('applicationDetails.creditLine.denied')}
              subtitle={translate('applicationDetails.creditLine.notification')}
              color={theme.main.midnightBlue10}
              borderColor={theme.main.midnightBlue50}
            />
          )}
          <Button fullWidth onClick={goToUseMyBalance}>
            {translate(`applicationDetails.useMyApproval`)}
          </Button>
          <MenuContainer>
            <MenuItemContainer onClick={goToPreviewPayments}>
              <Icon color={theme.main.kellyGreen} src={GroupIcon} />
              <MenuItemText fontWeight={600} color={theme.main.midnightBlue}>
                {translate(`applicationDetails.previewPayments`)}
              </MenuItemText>
            </MenuItemContainer>
            <MenuItemContainer
              onClick={() => {
                trackSegmentEvent(
                  SEGMENT_EVENT_NAMES.PATIENT_PORTAL.APPROVAL_DETAILS.VIEW_FAQS_CLICKED,
                  segmentEventData,
                );
                denialsFAQ.openModal(1);
              }}
            >
              <Icon color={theme.main.kellyGreen} src={HelpCircleIcon} />
              <MenuItemText fontWeight={600} color={theme.main.midnightBlue}>
                {translate(`applicationDetails.faq`)}
              </MenuItemText>
            </MenuItemContainer>
            {(creditLineIncrease === CreditLineIncrease.ELIGIBLE ||
              extendedApproval ||
              (locations?.length > 1 && application?.organization?.code !== ALLE)) && (
              <MenuItemContainer onClick={openModal}>
                <Icon color={theme.main.kellyGreen} src={MoreHorizontalIcon} />
                <MenuItemText fontWeight={600} color={theme.main.midnightBlue}>
                  {translate(`applicationDetails.moreActions`)}
                </MenuItemText>
              </MenuItemContainer>
            )}
          </MenuContainer>
        </Container>
      )}
      {denialsFAQ.modalVisibility && denialsFAQ.ModalBody}
      {modalVisibility && (
        <SliderModal title={translate(`applicationDetails.moreActions`)} show={modalVisibility} hideModal={hideModal}>
          <WrapperWithGap8>
            {creditLineIncrease === CreditLineIncrease.ELIGIBLE ? (
              <Button fullWidth variant="secondary" onClick={checkoutHandler}>
                {translate(`applicationDetails.requestLargerApproval`)}
              </Button>
            ) : null}

            {extendedApproval ? (
              <Button onClick={extendApprovalHandler} fullWidth variant="secondary">
                {translate(`applicationDetails.requestMoreTime`)}
              </Button>
            ) : null}
            {application?.organization?.code !== ALLE && (
              <Button onClick={goToChangeLocation} fullWidth variant="secondary">
                {translate(`applicationDetails.changeLocationPreferences`)}
              </Button>
            )}
          </WrapperWithGap8>

          <Button variant="secondary" fullWidth onClick={hideModal}>
            {translate(`common.dismiss`)}
          </Button>
        </SliderModal>
      )}
    </>
  );
};

const FlexContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const MenuContainer = styled.div`
  display: flex;
  gap: 8px;
  margin-top: 8px;
`;

const MenuItemText = styled(Body)`
  font-size: 10px !important;
`;

const MenuItemContainer = styled.div`
  min-width: 80px;
  min-height: 56px;
  height: auto;
  width: 100%;
  display: flex;
  padding: 6px;
  border-radius: 4px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex: 1;
  cursor: pointer;

  border-radius: var(--borderRadius, 4px);
  border: 0px solid ${theme.main.midnightBlue};
  background-color: ${theme.main.white};
`;

const Uppercase = styled(Body)`
  text-transform: uppercase;
`;

const UppercaseSmall = styled(Body)`
  text-transform: uppercase;
  font-size: 12px !important;
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const WrapperWithGap24 = styled(Wrapper)`
  gap: 24px;
`;

const WrapperWithGap8 = styled(Wrapper)`
  gap: 8px;
  width: 100%;
`;

const InnerContainer = styled.div`
  margin: 0;
  background-color: ${theme.main.white};
  max-width: 600px;
  padding: 24px;
  flex-direction: column;
  align-items: center;
  align-self: stretch;
  border-radius: 24px;
  margin-bottom: 24px;
  box-shadow: 0px 36px 67px 0px rgba(0, 0, 0, 0.07), 0px 13.141px 24.456px 0px rgba(0, 0, 0, 0.05),
    0px 6.38px 11.873px 0px rgba(0, 0, 0, 0.04), 0px 3.127px 5.82px 0px rgba(0, 0, 0, 0.03),
    0px 1.237px 2.301px 0px rgba(0, 0, 0, 0.02);
`;

const HeaderContainer = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${theme.main.white};
  align-items: center;
  padding: 0;
  gap: 16px;
`;

const Amount = styled.span<{ isExpired?: boolean }>`
  font-weight: 700;
  font-size: 32px;
  line-height: 44px;
  color: ${(props) => (props.isExpired ? props.theme.main.grayColor : props.theme.main.primary)};
`;

const StatusPill = styled.div`
  display: flex;
  padding: 8px 16px;
  flex-direction: column;
  align-items: center;
  border-radius: 40px;
  border: 1px solid ${theme.main.kellyGreen};
  background: ${theme.main.kellyGreen5};
  margin-top: 16px;
`;
