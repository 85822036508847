import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import useStore from 'lib/hooks/useStore';
import { ReactComponent as RightIcon } from 'assets/images/chevron-right.svg';
import { Approval } from 'pages/Approvals/type';
import { Body, theme } from '@frontend/cherry-library';

interface Props {
  approval: Approval;
  index: number;
}

export const DeniedApplication = ({ approval, index }: Props) => {
  const { t: translate } = useTranslation();
  const navigate = useNavigate();
  const { setSelectedApplication } = useStore();

  const { organization } = approval || {};
  const { name } = organization || {};

  const applicationDetailHandler = () => {
    setSelectedApplication(approval);
    navigate(`/portal/approvals/${approval.id}`);
  };

  return (
    <>
      {index !== 0 && <Seperator />}
      <ApplicationsContainer onClick={applicationDetailHandler}>
        <PaymentDetails>
          <Uppercase size="small" color={theme.main.midnightBlue50} fontWeight={600}>
            {translate('deniedApplication.notApproved')}
          </Uppercase>
          <Body fontWeight={700} color={theme.main.midnightBlue}>
            {name}
          </Body>
          <Uppercase size="small" fontWeight={600}>
            {translate('general.applicationId')}: {approval?.displayId}
          </Uppercase>
          <Body fontWeight={400} color={theme.main.midnightBlue} size="small">
            {translate('deniedApplication.notApprovedText')}
          </Body>
        </PaymentDetails>
        <IconContainer>
          <RightIcon data-testid="application-select" width={'24px'} height={'24px'} />
        </IconContainer>
      </ApplicationsContainer>
    </>
  );
};

const Uppercase = styled(Body)`
  text-transform: uppercase;
`;

const PaymentDetails = styled.div`
  display: flex;
  align-items: left;
  align-self: stretch;
  flex-direction: column;
  min-width: 60%;
  margin-bottom: 16px;
`;

const Seperator = styled.div`
  background: ${theme.main.midnightBlue20};
  height: 1px;
  width: 100%;
  margin: 0 0 16px 0;
`;
const ApplicationsContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
  justify-content: space-between;
  cursor: pointer;
`;

const IconContainer = styled.div`
  cursor: pointer;
  height: 24px;
  width: 24px;
`;
