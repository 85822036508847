import { useLazyQuery, useMutation } from '@apollo/client';
import { useAlert } from 'react-alert';
import { useTranslation } from 'react-i18next';

import { PATCH_MERCHANT_PREFERENCE } from 'lib/graphql/mutations';
import { GET_APPLICATION_LIST } from 'lib/graphql/queries';
import useStore from './useStore';

export const useApplicationList = () => {
  const alert = useAlert();
  const { t } = useTranslation();
  const { setApplicationList } = useStore();

  return useLazyQuery(GET_APPLICATION_LIST, {
    fetchPolicy: 'network-only',
    onError: (error) => alert.error(t(`error.${error}`)),
    onCompleted: ({ fetchApplicationList }) => setApplicationList(fetchApplicationList?.contents),
  });
};

export const usePatchLocationPreference = () => {
  const alert = useAlert();
  const { t } = useTranslation();

  return useMutation(PATCH_MERCHANT_PREFERENCE, {
    fetchPolicy: 'network-only',
    onError: (error) => alert.error(t(`error.${error}`)),
  });
};
