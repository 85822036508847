import { ButtonUnstyledProps, useButton } from '@mui/core';
import CircularProgress from '@mui/material/CircularProgress';
import { styled } from '@mui/system';
import clsx from 'clsx';
import React from 'react';

interface SCButtonInput {
  color: ButtonColor;
  m?: string;
  p?: string;
}

export enum ButtonColor {
  'primary' = '#00c37d',
  'secondary' = '#fff',
  'dark' = '#0E202F',
  'red' = '#eb1d5b',
}

const CustomCircularPropgess = styled(CircularProgress)`
  margin-left: 10px;
  position: absolute;
  margin-top: 3px;
`;

const CustomButtonRoot = styled('button')<SCButtonInput>`
  background-color: ${(props) => props.color};
  width: 100%;
  padding: ${(props) => (props.p ? props.p : '9px')};
  border-radius: 6px;
  color: ${(props) => (props.color === ButtonColor.secondary ? '#000' : '#fff')};
  border: ${(props) => (props.color === ButtonColor.secondary ? '1px solid #0e202f' : `1px solid ${props.color}`)};
  font-weight: 600;
  font-family: 'Open Sans', sans-serif;
  font-size: 16px;
  transition: all 200ms ease;
  cursor: pointer;
  // box-shadow: ${(props) =>
    props.color === ButtonColor.secondary
      ? 'none'
      : '0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%)'};
  margin: ${(props) => (props.m ? props.m : ' 0px 0px 20px 0px')};

  &.focusVisible {
    box-shadow: 0 4px 20px 0 rgba(61, 71, 82, 0.1), 0 0 0 5px rgba(0, 127, 255, 0.5);
    outline: none;
  }

  &.disabled {
    opacity: 0.5;
    cursor: unset;
    box-shadow: 0 0 0 0 rgba(0, 127, 255, 0);
    background-color: #dadada;
    color: #9fa6ac;
    border: 1px solid #dadada;
  }
`;

export const Button = React.forwardRef(function CustomButton(
  props: ButtonUnstyledProps | any,
  ref: React.ForwardedRef<any>,
) {
  const { children, loading, color = ButtonColor.primary, m, p } = props;
  const { active, disabled, focusVisible, getRootProps } = useButton({
    ...props,
    ref,
    component: CustomButtonRoot,
  });

  const classes = {
    active,
    disabled,
    focusVisible,
  };

  return (
    <CustomButtonRoot
      className={clsx(classes)}
      data-testid="submit"
      color={color}
      m={m}
      p={p}
      {...getRootProps()}
      type="submit"
    >
      {children}
      {loading && <CustomCircularPropgess size={20} color={'inherit'} />}
    </CustomButtonRoot>
  );
});
