import { useEffect, useState } from 'react';
import { useAlert } from 'react-alert';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { useUpdateBorrower } from 'lib/hooks/useAccount';
import { AnalyticEventNames, useAnalytics } from 'lib/hooks/useAnalytics';
import useStore from 'lib/hooks/useStore';
import { FormatPhoneNumber } from 'lib/utils';
import { SOURCE_TYPES } from 'lib/constans';

import { AddressSection, EmailSection, UpdateEmailModal } from './components';
import { Body, theme } from '@frontend/cherry-library';
import { ReactComponent as EditIcon } from 'assets/images/edit.svg';

const Contact = ({ borrower }) => {
  const { t: translate } = useTranslation();
  const [updateBorrower, { data, loading }] = useUpdateBorrower();

  const alert = useAlert();
  const { trackEvent } = useAnalytics();
  const { patchBorrower } = useStore();

  const [open, setOpen] = useState(false);
  const [email, setEmail] = useState('');

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  useEffect(() => {
    setEmail(borrower.email);
  }, []);

  useEffect(() => {
    if (data?.updateBorrower?.success) {
      patchBorrower({ email });
      alert.success(translate('notif.emailUpdateSuccess'));
      handleClose();
    }
  }, [data]);

  const changeEmailHandler = async (_email) => {
    trackEvent(AnalyticEventNames.ACC_EMAIL_UPDATE);
    setEmail(_email);
    updateBorrower({ variables: { input: { id: borrower?.id, email: _email, source: SOURCE_TYPES.INTERNAL } } });
  };

  return (
    <Container>
      <Label fontWeight={600} size="small">
        {translate('account.contact.name')}
      </Label>
      <Info>{`${borrower?.firstName} ${borrower?.lastName}`}</Info>
      <Seperator />
      <Label fontWeight={600} size="small">
        {translate('account.contact.mailingAddress')}
      </Label>
      <AddressSection borrower={borrower} />
      <Seperator />
      <Label fontWeight={600} size="small">
        {translate('account.contact.phone')}
      </Label>
      <Info>{FormatPhoneNumber(borrower?.phone)}</Info>
      <Seperator />
      <FlexContainer>
        <div>
          <Label fontWeight={600} size="small">
            {translate('account.contact.email')}
          </Label>
          <EmailSection borrower={borrower} handleOpen={handleOpen} />
        </div>
        <EditLogo height={20} width={20} onClick={handleOpen} />
      </FlexContainer>

      <UpdateEmailModal
        open={open}
        email={email}
        loading={loading}
        handleClose={handleClose}
        changeEmailHandler={changeEmailHandler}
      />
    </Container>
  );
};

const Label = styled(Body)`
  color: ${theme.main.midnightBlue50} !important;
  text-transform: uppercase;
`;

const EditLogo = styled(EditIcon)`
  cursor: pointer;
`;

const Info = styled(Body)`
  color: ${theme.main.midnightBlue};
`;

const Seperator = styled.div`
  background: ${theme.main.midnightBlue20};
  height: 1px;
  width: 100%;
  margin: 16px 0;
`;

const Container = styled.div`
  margin-top: 24px;
  margin-bottom: 56px;
  padding: 16px;
  display: flex;
  flex-direction: column;
  background-color: ${theme.main.white};
  border-radius: 8px;
  box-shadow: 0px 6px 9px rgba(0, 0, 0, 0.07), 0px 0.751293px 1.12694px rgba(0, 0, 0, 0.035);
`;

const FlexContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export default Contact;
