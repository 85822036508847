import { useLazyQuery } from '@apollo/client';
import { useAlert } from 'react-alert';
import { useTranslation } from 'react-i18next';

import { GET_FILE, GET_FILES } from 'lib/graphql/queries';
import { FileDownload } from 'lib/utils';

export const useFiles = () => {
  const alert = useAlert();
  const { t } = useTranslation();

  return useLazyQuery(GET_FILES, {
    fetchPolicy: 'network-only',
    onError: (error) => alert.error(t(`error.${error}`)),
  });
};

export const useFile = () => {
  const alert = useAlert();
  const { t } = useTranslation();

  return useLazyQuery(GET_FILE, {
    fetchPolicy: 'network-only',
    onError: (error) => alert.error(t(`error.${error}`)),
    onCompleted: ({ getFile }) => FileDownload(getFile.data),
  });
};
