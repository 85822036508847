import { useApolloClient } from '@apollo/client';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

import { theme } from 'config/theme';
import { fileSearchForLoan } from 'lib/graphql/searches';
import { useFile } from 'lib/hooks/useFile';
import { AutoPayIcon } from '../icons';

import { GET_FILES } from 'lib/graphql/queries';
import { SegmentEventNames, useSegment } from 'lib/hooks/useSegment';
import { Loading } from 'lib/components';

interface Props {
  loan: any;
  type?: string;
  loading?: boolean;
}

export const PaymentPlan = ({ loading, loan, type }: Props) => {
  const navigate = useNavigate();
  const client = useApolloClient();
  const { t: translate } = useTranslation();
  const [getFile] = useFile();
  const { trackSegmentEvent } = useSegment();

  const [monthlyStatementFileId, setMonthlyStatementFileId] = useState<string | null>(null);

  const isAutoPayActive = loan?.autoPay;

  const autoPayHandler = () => {
    trackSegmentEvent(SegmentEventNames.MANAGE_AUTOPAY, { loan_id: loan?.id });
    navigate(`/portal/${loan.id}/autopay`);
  };

  const getMonthlyPdfStatemenet = async () => {
    getFile({ variables: { input: { fileId: monthlyStatementFileId } } });
  };

  const getFileListForMonthlyPdfStatement = async () => {
    const { data: { getFileList } = {} } = await client.query({
      query: GET_FILES,
      variables: {
        input: {
          search: fileSearchForLoan(loan?.id),
        },
      },
    });

    if (getFileList?.contents?.[0]?.id) {
      setMonthlyStatementFileId(getFileList.contents[0].id);
    }
  };

  useEffect(() => {
    getFileListForMonthlyPdfStatement();
  }, []);

  if (loading) {
    return <Loading transparent={true} />;
  }

  return (
    <>
      <PaymentPlanContainer>
        {isAutoPayActive && type !== 'closed' && (
          <DetailButton onClick={autoPayHandler}>
            <AutoPayIcon color={theme.main.primary} />
            {translate('contracts.active.manageAutoPayBtn')}
          </DetailButton>
        )}
        {monthlyStatementFileId && (
          <DetailButton onClick={getMonthlyPdfStatemenet}>
            <FileIcon src="/file_blank_outline.svg" />
            {translate('contracts.monthlyPdfStatement')}
          </DetailButton>
        )}
      </PaymentPlanContainer>
    </>
  );
};

const PaymentPlanContainer = styled.div`
  background-color: ${(props) => props.theme.main.zebraColor};
  margin-top: ${(props) => props.theme.constants.m};
  padding: ${(props) => props.theme.constants.xl};
  border-radius: 4px;
  text-align: center;

  @media (max-width: ${(props) => props.theme.size.mobileXl}) {
    padding: ${(props) => props.theme.constants.l};
  }
`;

const DetailButton = styled.div`
  cursor: pointer;
  align-items: center;
  display: flex;
  justify-content: center;
  font-size: 12px;
  font-weight: 600;
  line-height: 16px;
  padding: 9px 0px;
  border: ${(props) => `1px solid ${props.theme.main.primary}`};
  border-radius: 6px;
  color: ${(props) => props.theme.main.primary};
  margin-bottom: 20px;
  svg {
    margin-right: 10px;
  }
`;

const FileIcon = styled.img`
  margin-right: 10px;
`;
