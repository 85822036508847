import { gql } from '@apollo/client/core';

export const CREATE_SUBSCRIPTIONS_CUSTOMER = gql`
  mutation createMembershipCustomer($input: CreateMembershipCustomerInput) {
    createMembershipCustomer(input: $input) {
      id
      firstName
      lastName
      phone
      email
      createdAt
    }
  }
`;

export const PATCH_SUBSCRIPTIONS_CUSTOMER = gql`
  mutation patchMembershipCustomer($input: PatchMembershipCustomerInput) {
    patchMembershipCustomer(input: $input) {
      id
      firstName
      lastName
      phone
      email
      createdAt
    }
  }
`;

export const CREATE_CUSTOMER_SUBSCRIPTIONS_PLAN = gql`
  mutation createCustomerMembershipsPlan($input: CreateCustomerMembershipsPlanInput) {
    createCustomerMembershipsPlan(input: $input) {
      id
      error
      message
    }
  }
`;

export const CREATE_CUSTOMER_SUBSCRIPTIONS_PAYMENT_METHOD = gql`
  mutation createMembershipsCustomerPaymentMethod($input: CreateMembershipsCustomerPaymentMethodInput) {
    createMembershipsCustomerPaymentMethod(input: $input) {
      id
    }
  }
`;

export const PATCH_CUSTOMER_SUBSCRIPTIONS_PAYMENT_METHOD = gql`
  mutation patchMembershipsCustomerPaymentMethod($input: PatchMembershipsCustomerPaymentMethodInput) {
    patchMembershipsCustomerPaymentMethod(input: $input) {
      id
    }
  }
`;
