import React from 'react';
import styled from 'styled-components';
import { Icon } from 'lib/components/Icon';
import { useNavigate } from 'react-router-dom';
import useStore from '../../hooks/useStore';
import { CheckCircle } from '@mui/icons-material';
import { FormatAmount } from 'lib/utils';

interface Props {
  plan: any;
}

const PRICE_PERIOD_TO_LABEL = (weeklyPaymentFrequency: number) => ({
  YEARLY: 'YEARLY',
  MONTHLY: 'MONTHLY',
  MONTHLY_FLEX: `MONTHLY, `,
  CUSTOM_WEEKLY: weeklyPaymentFrequency === 1 ? 'WEEKLY' : `EVERY ${weeklyPaymentFrequency} WEEKS`,
});

const PlanCard = ({ plan }: Props) => {
  const navigate = useNavigate();
  const { setSelectedSubscriptionPlan, subscriptionOrganization } = useStore();

  const handleDetailClick = () => {
    setSelectedSubscriptionPlan(plan);
    navigate(`/memberships/${subscriptionOrganization?.slug}/detail`);
  };

  const defaultPrice =
    plan?.prices?.find((price) => price.period === 'CUSTOM_WEEKLY') ||
    plan?.prices?.find((price) => price.period === 'MONTHLY') ||
    plan?.prices?.find((price) => price.period === 'MONTHLY_FLEX') ||
    plan?.prices?.find((price) => price.period === 'YEARLY');

  return (
    <Container onClick={handleDetailClick}>
      <LeftHeader>
        <PlanType>{plan?.name.toUpperCase()}</PlanType>
        <Price>${Intl.NumberFormat('en-US').format(defaultPrice.price)}</Price>
        <PaymentType>
          {PRICE_PERIOD_TO_LABEL(defaultPrice.paymentFrequency)[defaultPrice.period]}
          {defaultPrice.period === 'MONTHLY_FLEX' && (
            <>
              <br />
              {FormatAmount(defaultPrice.firstPayment)} TO ACTIVATE
            </>
          )}
        </PaymentType>
      </LeftHeader>
      <Summary>
        {plan.recommended && (
          <RecommendedBadge>
            <CheckCircle style={{ width: 16, height: 16 }} />
            <span>RECOMMENDED</span>
          </RecommendedBadge>
        )}
        <SummaryTitle>SUMMARY</SummaryTitle>
        <ul>
          {plan?.benefits?.map((benefit) => (
            <Benefit>{benefit?.name}</Benefit>
          ))}
        </ul>
      </Summary>
      <PlanDetailIcon>
        <Icon width={48} height={48} src="circle_chevron_right" />
      </PlanDetailIcon>
    </Container>
  );
};

const RecommendedBadge = styled.div`
  font-family: Open Sans;
  font-size: 9px;
  font-weight: 400;
  line-height: 11px;
  letter-spacing: 0.05em;
  text-align: left;
  color: rgba(0, 195, 125, 1);
  background-color: rgba(230, 249, 242, 1);
  display: flex;
  align-items: center;
  gap: 4px;
  height: 20px;
  width: 96px;
  padding: 2px 6px 2px 2px;
  border-radius: 16px;
`;

const Container = styled.div`
  border-radius: 8px;
  box-shadow: 0px 0.75px 1.125px 0px #00000009;
  padding: 0 16px 0 0;
  box-shadow: 0px 6px 9px 0px #00000012;
  overflow: hidden;
  display: flex;

  align-items: center;
`;

const LeftHeader = styled.div`
  background-color: ${(props) => props.theme.main.textColor};
  align-self: stretch;
  padding: 27px 5px;
  width: 95px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  box-sizing: border-box;
`;

const PlanType = styled.div`
  font-family: 'Open Sans';
  font-size: 10px;
  font-weight: 700;
  line-height: 14px;
  letter-spacing: 0em;
  text-align: center;
  color: #00c37d;
`;

const Price = styled.div`
  font-family: 'Open Sans';
  font-size: 24px;
  font-weight: 700;
  line-height: 33px;
  letter-spacing: 0em;
  text-align: center;
  color: #fff;
`;

const PaymentType = styled.div`
  font-family: Open Sans;
  font-size: 9px;
  font-weight: 400;
  line-height: 12px;
  letter-spacing: 0em;
  text-align: center;
  color: #fff;
`;

const Summary = styled.div`
  align-self: flex-start;
  padding: 8px 16px;

  ul {
    padding-inline-start: 20px;
  }
`;

const SummaryTitle = styled.div`
  font-family: Open Sans;
  font-size: 14px;
  font-weight: 700;
  line-height: 19px;
  letter-spacing: 0em;

  color: ${(props) => props.theme.main.textColor};
`;

const Benefit = styled.li`
  font-family: Open Sans;
  font-size: 13px;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0em;
  text-align: left;
  color: ${(props) => props.theme.main.textColor};
`;

const PlanDetailIcon = styled.div`
  img {
    cursor: pointer;
  }
  margin-left: auto;
`;

export default PlanCard;
