import React from 'react';
import { Card as MuiCard } from '@mui/material';
import styled from 'styled-components';

interface CardProps {
  children: React.ReactNode;
  style?: React.CSSProperties;
}

export const Card = ({ children, style }: CardProps) => <Container style={style || {}}>{children}</Container>;

const Container = styled(MuiCard)`
  padding: 16px;
  margin-bottom: 16px;
  border-radius: 8px;
  box-shadow: 0px 6px 9px 0px #00000012;
`;
