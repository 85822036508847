import styled from 'styled-components';
import { Body, theme } from '@frontend/cherry-library';

export const SubMenuTile = ({ text, children, last = false }) => {
  return (
    <PurchaseItemContainer last={last}>
      <PurchaseItem fontWeight={400} size="small" color={theme.main.midnightBlue50}>
        {text}
      </PurchaseItem>
      <PurchaseItem fontWeight={400} size="small">
        {children}
      </PurchaseItem>
    </PurchaseItemContainer>
  );
};

const PurchaseItemContainer = styled.div<{ last?: boolean }>`
  display: flex;
  padding: 12px 0;
  align-items: flex-start;
  gap: 12px;
  border-bottom: ${(props) => (props.last ? 'none' : `1px solid ${theme.main.midnightBlue20}`)};
`;

const PurchaseItem = styled(Body)`
  flex: 1;
`;
