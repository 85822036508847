import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import {
  Button,
  ButtonContainer,
  Icon,
  InnerContainer,
  MembershipContainer,
  PhoneInput,
  Subtitle,
  TextField,
  Title,
} from 'lib/components';
import { theme } from 'config/theme';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Props, SelfUpdateEventTitle } from 'pages/PhoneUpdate/type';
import { useMutation } from '@apollo/client';
import { SELF_UPDATE_OTP } from 'lib/graphql/mutations/PhoneUpdate';
import { OtpReceiveChoiceEnum } from 'lib/hooks/useUser';
import { useAlert } from 'react-alert';
import { useSentry } from 'lib/hooks/useSentry';
import useStore from 'lib/hooks/useStore';
import { SegmentEventNames, SegmentPageNames, useSegment } from 'lib/hooks/useSegment';

const EnterNewPhone = ({ continueFlow, goBack }: Props) => {
  const { t: translate } = useTranslation();
  const { captureException } = useSentry();
  const alert = useAlert();
  const { trackIdentify, trackSegmentEvent, trackPage } = useSegment();

  const { userId, setNewPhoneNumber } = useStore();

  const [loading, setLoading] = useState(false);
  const [selfUpdateOtp] = useMutation(SELF_UPDATE_OTP);

  const {
    handleSubmit,
    control,
    formState: { isValid },
  } = useForm({
    mode: 'onChange',
  });

  useEffect(() => {
    trackPage(SegmentPageNames.PHONE_NUMBER_UPDATE, { title: SelfUpdateEventTitle.ENTER_NEW_PHONE });
  }, []);

  const callSegmentEvents = (phone) => {
    trackIdentify({ phoneNumber: phone });
    trackSegmentEvent(SegmentEventNames.PHONE_NUMBER_UPDATE, {
      title: SelfUpdateEventTitle.OTP_SUBMITTED,
    });
  };

  const onSubmit = async (formData) => {
    try {
      setLoading(true);
      const { phone } = formData;
      callSegmentEvents(phone);
      setNewPhoneNumber(phone);
      const { data } = await selfUpdateOtp({
        variables: {
          input: { phone, otpChannel: OtpReceiveChoiceEnum.SMS, id: userId },
        },
      });

      setLoading(false);
      if (data?.selfUpdateOtp?.success) {
        alert.success(translate('token.form.phone.success'));
        continueFlow?.();
      } else {
        alert.error(translate('error.default'));
      }
    } catch (err) {
      captureException(err, { errorContext: 'selfUpdateOtp => EnterNewPhone.tsx' });
    }
  };

  const onKeyDown = (event: React.KeyboardEvent<HTMLDivElement>): void => {
    if (event.key === 'Enter' && isValid) {
      event.preventDefault();
      event.stopPropagation();
      handleSubmit(onSubmit)();
    }
  };

  const handleClick = () => {
    handleSubmit(onSubmit)();
  };
  return (
    <MembershipContainer goBackCallback={goBack} showBackButton={true} isPhoneUpdate={true}>
      <InnerContainer>
        <Icon width={28} height={28} src="mobile" />
        <Title color={theme.main.primary}> {translate('phoneUpdate.newPhone.title')}</Title>
        <Subtitle m="0px 0px 24px 0px" color={theme.main.midnightBlue}>
          {translate('phoneUpdate.newPhone.subtitle')}
        </Subtitle>
        <Controller
          name="phone"
          control={control}
          defaultValue=""
          rules={{ required: true }}
          render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
            <TextField
              id="phone"
              variant="filled"
              data-testid="phone"
              data-neuro-label="phone"
              type="tel"
              label={translate('subscriptions.contactInfo.personalNumber')}
              onKeyDown={onKeyDown}
              InputProps={{
                inputComponent: PhoneInput,
                disableUnderline: true,
              }}
              value={value}
              onChange={onChange}
              onBlur={onBlur}
              error={!!error}
              helperText={error ? error?.message : null}
            />
          )}
        />
      </InnerContainer>

      <ButtonContainer>
        <Button disabled={!isValid} onClick={handleClick} p="15px" loading={loading}>
          {translate('common.continue')}
        </Button>
        <Text
          dangerouslySetInnerHTML={{
            __html: translate('phoneUpdate.newPhone.footer'),
          }}
        />
      </ButtonContainer>
    </MembershipContainer>
  );
};

export default EnterNewPhone;

const Text = styled.div`
  font-size: 12px;
  color: ${(props) => props.theme.main.primary};
  font-family: Open Sans;
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0em;
  text-align: left;
  padding-bottom: 16px;
  padding-top: 4px;
`;
