// @ts-nocheck
import { YELLOW_AI_SECRET } from 'config';
import { useSentry } from 'lib/hooks/useSentry';
import { Flags } from '../hooks/FeatureManagement/Flags';
import Rox from 'rox-browser';

interface YmConfig {
  bot: string;
  host: string;
  payload: {
    userType: string;
  };
}

interface YellowMessengerPlugin {
  openBot: () => void;
}

declare global {
  interface Window {
    YellowMessengerPlugin: YellowMessengerPlugin;
    ymConfig: YmConfig;
  }
}

function loadYellowMessenger({ bot, host, payload }): void {
  window.ymConfig = {
    bot: bot,
    host: host,
    payload: { ...payload },
  };
  if (window.innerWidth <= 991) {
    window.ymConfig.theme = { botClickIcon: 'https://cdn.yellowmessenger.com/evYQJM0Ywf9D1720539623225.png' };
  }
  if (window?.YellowMessengerPlugin) {
    window.YellowMessengerPlugin.init(window.ymConfig);
  } else {
    (function () {
      try {
        const w = window;
        const ic = null;

        if ('function' === typeof ic) {
          ic('reattach_activator');
          ic('update', window.ymConfig);
        } else {
          const d = document;
          const i = function () {
            // eslint-disable-next-line prefer-rest-params
            i.c(arguments);
          };

          function l() {
            const e = d.createElement('script');
            e.type = 'text/javascript';
            e.async = false;
            e.src = 'https://cdn.yellowmessenger.com/plugin/widget-v2/latest/dist/main.min.js';
            const t = d.getElementsByTagName('script')[0];
            t.parentNode.insertBefore(e, t);
          }

          i.q = [];
          i.c = function (e) {
            i.q.push(e);
          };
          w.YellowMessenger = i;
          if (w.attachEvent) {
            d.attachEvent('onload', l);
          } else {
            l();
          }
        }
      } catch (e) {
        console.error('err', e);
      }
    })();
  }
}

export const initChatNiceWidget = () => {
  const serverHost = 'https://home-c68.nice-incontact.com';
  const busNo = 4606496;
  const poc = '0b4f7d31-e624-4bc5-8e23-be851302f14a';

  const hasNiceChatWidget = document.getElementById('chat-div-wrap');
  if (!hasNiceChatWidget) {
    window.icPatronChat.init({
      serverHost,
      bus_no: busNo,
      poc,
    });
  }
};

export const hideChatNiceWidget = () => {
  const chatWrap = document.querySelectorAll('#chat-div-wrap');
  const chatWrapArray = Array.from(chatWrap);
  chatWrapArray.forEach((element) => {
    element.style.display = 'none';
  });

  const chatButton = document.querySelectorAll('.ie-div-position-customer-chat');
  const chatButtonArray = Array.from(chatButton);
  chatButtonArray.forEach((element) => {
    element.style.display = 'none';
  });
};

export const initChatWidget = async () => {
  const { captureException } = useSentry();

  try {
    const ymConfig = {
      bot: YELLOW_AI_SECRET,
      host: 'https://r4.cloud.yellow.ai',
      payload: { loggedIn: false, userType: 'patient', website: 'patient' },
    };

    await Rox.fetch();
    if (Flags.FEATURES.yellowAiChatBot.isEnabled()) {
      loadYellowMessenger(ymConfig);
      hideChatNiceWidget();
    } else {
      initChatNiceWidget();
      window?.YellowMessengerPlugin?.hide();
    }
  } catch (err) {
    console.error('err', err);
    captureException(err, { errorContext: 'openChat => ChatWidgetButton.tsx' });
  }
};

export const updateChatWidgetAfterLogin = async (phoneNumber: string, userName: string, borrowerIVRToken: string) => {
  const { captureException } = useSentry();
  try {
    const ymConfig = {
      bot: YELLOW_AI_SECRET,
      host: 'https://r4.cloud.yellow.ai',
      payload: {
        loggedIn: true,
        phone: phoneNumber,
        userType: 'patient',
        name: userName,
        website: 'patient',
        borrowerIVRToken,
      },
    };

    await Rox.fetch();
    if (Flags.FEATURES.yellowAiChatBot.isEnabled()) {
      loadYellowMessenger(ymConfig);
      hideChatNiceWidget();
    } else {
      initChatNiceWidget();
      window?.YellowMessengerPlugin?.hide();
    }
  } catch (err) {
    console.error('err', err);
    captureException(err, { errorContext: 'openChat => ChatWidgetButton.tsx' });
  }
};
