import styled from 'styled-components';
import { ReactComponent as RightIcon } from 'assets/images/chevron-right.svg';
import { theme, Icon } from '@frontend/cherry-library';

export const ListItem = ({ children, onClick, last, unclickable = false }) => {
  return (
    <div>
      <>
        {!unclickable ? (
          <ClickablePayment onClick={onClick}>
            <PaymentDetails>{children}</PaymentDetails>
            <Icon color={theme.main.kellyGreen} src={RightIcon} data-testid="upcoming-payment-select" />
          </ClickablePayment>
        ) : (
          <Payment>
            <PaymentDetails>{children}</PaymentDetails>
          </Payment>
        )}
      </>
      {!last && <Seperator />}
    </div>
  );
};

const Payment = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`;

const ClickablePayment = styled(Payment)`
  cursor: pointer;
`;

const PaymentDetails = styled.div`
  display: flex;
  flex-direction: column;
`;

const Seperator = styled.div`
  background: ${theme.main.midnightBlue20};
  height: 1px;
  width: 100%;
  margin: 16px 0;
`;
