const SENTRY_DSN = process.env.REACT_APP_SENTRY_DSN;
const APP_ENV: string | undefined = process.env.REACT_APP_ENV;
const S3_URL: string = process.env.REACT_APP_S3_URL || '';
const GQL_URL = process.env.REACT_APP_GQL_URL;
const GOOGLE_ID: string | undefined = process.env.REACT_APP_GOOGLE_ID;
const API_URL: string | undefined = process.env.REACT_APP_API_URL;
const APP_URL: string | undefined = process.env.REACT_APP_APP_URL;
const PREQUAL_URL: string | undefined = process.env.REACT_APP_PREQUAL_URL;
const FINDER_URL: string | undefined = process.env.REACT_APP_FINDER_URL;
const API_URL_STATIC: string | undefined = process.env.REACT_APP_API_URL_STATIC;
const CLOUDBEES_APIKEY: string | undefined = process.env.REACT_APP_CLOUDBEES_APIKEY;
const APP_USER_AGENT = 'CustomerPortal';
const WEB_SITE = 'https://withcherry.com';
const RELEASE_COMMIT_ID = process.env.REACT_APP_CI_COMMIT_SHORT_SHA;
const COGNITO_PUBLISHABLE_KEY = process.env.REACT_APP_COGNITO_PUBLISHABLE_KEY;
const YELLOW_AI_SECRET = process.env.REACT_APP_YELLOW_AI_SECRET;

export {
  SENTRY_DSN,
  APP_USER_AGENT,
  APP_ENV,
  GOOGLE_ID,
  API_URL,
  API_URL_STATIC,
  GQL_URL,
  APP_URL,
  PREQUAL_URL,
  S3_URL,
  FINDER_URL,
  WEB_SITE,
  CLOUDBEES_APIKEY,
  RELEASE_COMMIT_ID,
  COGNITO_PUBLISHABLE_KEY,
  YELLOW_AI_SECRET,
};
