import { useEffect, useState } from 'react';
import styled from 'styled-components';

import { API_URL } from 'config';
import { useSentry } from 'lib/hooks/useSentry';
import { useLocation, useNavigate } from 'react-router-dom';

export const BankConnectApprove = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { captureException } = useSentry();
  const [plaidUrl, setPlaidUrl] = useState('');

  useEffect(() => {
    const oauthStateId = new URLSearchParams(location?.search)?.get('oauth_state_id');
    const connectionId = new URLSearchParams(location?.search)?.get('connectionId');
    setPlaidUrl(`${API_URL}/v1/bank-connections/plaid-iframe/${connectionId}/oauth?oauth_state_id=${oauthStateId}`);

    window.addEventListener('message', receiveMessage, false);
    return () => {
      window.removeEventListener('message', receiveMessage);
    };
  }, []);

  const receiveMessage = ({ origin, data }) => {
    try {
      if (origin === API_URL) {
        const iFrameEvent = JSON.parse(data);

        switch (iFrameEvent.event) {
          case 'expired':
            break;
          case 'close':
            navigate('/portal/account?oauth=closed');
            break;
          case 'complete':
            navigate('/portal/account?oauth=success');
            break;
          case 'loading':
            break;
          case 'loaded':
            // onIframeLoaded();
            break;
          case 'plaid-event':
            console.warn('event');
            break;
          case 'plaid-error':
            navigate('/portal/account?oauth=fail');
            break;
        }
      }
    } catch (err) {
      navigate('/portal/account?oauth=fail');
      captureException(err, { errorContext: 'receiveMessage => BankConnectApprove', data });
    }
  };

  return (
    <Container>
      {plaidUrl && (
        <iframe data-testid="the-frame" title={'Connect Bank Account'} width="100%" height="100%" src={plaidUrl} />
      )}
    </Container>
  );
};

const Container = styled.div`
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 999;
`;
