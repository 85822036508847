import { theme } from 'config/theme';
import styled from 'styled-components';

interface Props {
  type?: string;
  count: number;
}

interface SCBadgeInput {
  type: string;
  count: number;
}

const colors = {
  primary: theme.main.green,
  secondary: theme.main.red,
};

export const Badge = ({ type = 'primary', count }: Props): React.ReactElement => {
  return (
    <Container type={type} count={count}>
      {count > 0 && <Count>{count}</Count>}
    </Container>
  );
};

const Container = styled.div<SCBadgeInput>`
  width: 28px;
  text-align: center;
  margin-right: 0;
  border-radius: 16px;
  padding: 0px 3px;
  background-color: ${(props) => (props.count > 0 ? colors[props.type] : 'transparent')};
`;

const Count = styled.span`
  font-weight: 700;
  font-size: 10px;
  color: white;
`;
