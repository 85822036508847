import { gql } from '@apollo/client/core';

export const GET_SERVICING_STATUS_HISTORY = gql`
  query getServicingStatusHistory($input: GetServicingStatusHistoryRequest) {
    getServicingStatusHistory(input: $input) {
      status
      subStatus
      loanPlanId
      createdAt
      id
      revisionDate
    }
  }
`;

export const GET_CHARGED_OFF_OFFERS = gql`
  query getChargedOffOffers($input: GetChargedOffOffersRequest) {
    getChargedOffOffers(input: $input) {
      group
      loanId
      daysSinceChargeOff
      discountedChargedOffAmount
      maxTermSettlement
      maxTermPaymentPlan
      discountPercentage
      installmentMap {
        SETTLEMENT_OFFER {
          MONTHLY {
            installmentCount
            installmentAmount
          }
          BI_WEEKLY {
            installmentCount
            installmentAmount
          }
          WEEKLY {
            installmentCount
            installmentAmount
          }
        }
        PAYMENT_PLAN {
          MONTHLY {
            installmentCount
            installmentAmount
          }
          BI_WEEKLY {
            installmentCount
            installmentAmount
          }
          WEEKLY {
            installmentCount
            installmentAmount
          }
        }
      }
    }
  }
`;
