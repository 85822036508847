import HamburgerIcon from '@mui/icons-material/Menu';
import { useApolloClient } from '@apollo/client';
import IconButton from '@mui/material/IconButton';
import { memo, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { WarningOutlined } from '@mui/icons-material';
import useStore from 'lib/hooks/useStore';
import StorageService from 'lib/services/Storage';
import { SegmentEventNames, useSegment } from 'lib/hooks/useSegment';
import { Menu } from '../Menu';
import useGuestMode from 'lib/hooks/useGuestMode';
import { Banner } from 'lib/components/Banner';
import { FETCH_BANNER } from 'lib/graphql/queries/Organization';
import { ReactComponent as HomeIcon } from 'assets/images/home_outline.svg';
import { ReactComponent as LeftIcon } from 'assets/images/short_left.svg';
import { ReactComponent as CherryLogoSolid } from 'assets/images/cherry_logo_solid.svg';
import { goBack } from 'lib/utils/GoBack';
import { getSegmentEventDetailsData } from 'lib/utils/SegmentEventData';
import { goBackSegmentEvents } from 'lib/utils/GoBackSegmentEvents';

interface Banner {
  showForPatient: boolean;
  patientText: string;
}

export const Header = memo(() => {
  const { t } = useTranslation();
  const client = useApolloClient();
  const navigate = useNavigate();
  const location = useLocation();
  const { trackSegmentEvent } = useSegment();
  const { isGuestMode } = useGuestMode();

  const { headerTitle, borrower, getActiveLoan, selectedApplication } = useStore();

  const [showMenu, setShowMenu] = useState(false);
  const [banner, setBanner] = useState<Banner | null>(null);

  const { token, refreshToken } = StorageService.getAuthData();

  useEffect(() => {
    if (token) {
      getBanner();
    }
  }, [token]);

  const goToHome = () => {
    if (token && refreshToken) {
      return navigate('/portal/home');
    }
    navigate('/');
  };

  const getBanner = async () => {
    const { data } = await client.query({
      query: FETCH_BANNER,
      variables: { input: {} },
    });
    if (data.fetchBanner?.length > 0) {
      setBanner(data.fetchBanner[0]);
    }
  };

  const hideMenuHandler = () => {
    setShowMenu(false);
  };

  const showMenuHandler = () => {
    trackSegmentEvent(SegmentEventNames.MENU_VIEWED, { borrowerId: borrower?.id });
    setShowMenu(true);
  };

  const locationPath = location.pathname;
  const pathMatch = locationPath.match(
    /\/portal\/(\d+)\/(purchase|autopay|payment-method|payment-type|payment-review|calculate-payoff)/,
  );
  const pathsWithBackIcon = [
    '/portal/use-my-balance',
    '/portal/preview-payments',
    '/portal/change-location',
    '/portal/extend-approval',
    '/portal/modify-due-date',
  ];
  const pathsWithoutHamburgerButton = ['/', '/login'];
  const visiblePathsForCherryIcon = ['/portal/home', '/login', '/'];

  const isApprovalDetailPage = location?.pathname?.includes('/portal/approvals/');
  const isBackIconVisible =
    pathMatch !== null || pathsWithBackIcon?.includes(location?.pathname) || isApprovalDetailPage;
  const isHamburgerButtonVisible = !pathsWithoutHamburgerButton.includes(location.pathname);
  const isCherryIconVisible = visiblePathsForCherryIcon.includes(location.pathname);
  const isHomeIconVisible = !isBackIconVisible && !isCherryIconVisible;
  const getSegmentDetailsMetaData = () => {
    const parts = locationPath?.split('/');
    const id = parts[2];
    if (id) {
      const loan = getActiveLoan(id);
      if (loan) {
        return getSegmentEventDetailsData(borrower, loan);
      }
    }
  };

  const goBackToPreviousPage = () => {
    goBack();
    const segmentEventDetailsData = getSegmentDetailsMetaData();
    goBackSegmentEvents(locationPath, segmentEventDetailsData, selectedApplication, borrower, trackSegmentEvent);
  };

  return (
    <Container>
      {isGuestMode ? (
        <BadgeContainer>
          <WarningOutlined width={20} height={20} />
          <GuestModeText
            dangerouslySetInnerHTML={{
              __html: t('header.guestMode'),
            }}
          />
        </BadgeContainer>
      ) : null}

      {banner?.showForPatient ? (
        <BadgeContainer>
          <Banner message={banner.patientText} />
        </BadgeContainer>
      ) : null}

      <HeaderContainer>
        {isCherryIconVisible && <CherryLogoIcon data-testid="chery-icon" onClick={goToHome} />}
        {isHomeIconVisible && <CherryHomeIcon data-testid="home-icon" onClick={goToHome} />}
        {isBackIconVisible && <CherryLeftIcon data-testid="cherry-icon" onClick={goBackToPreviousPage} />}
        {headerTitle && (
          <TitleContainer>
            <Title>{t(headerTitle)}</Title>
          </TitleContainer>
        )}
        {isHamburgerButtonVisible && (
          <IconButtonContainer
            data-testid="hamburgerButton"
            aria-label="back"
            color="inherit"
            onClick={showMenuHandler}
          >
            <CherryHamburgerIcon />
          </IconButtonContainer>
        )}
      </HeaderContainer>
      <Menu show={showMenu} hideMenu={hideMenuHandler} />
      <Seperator />
    </Container>
  );
});

const Container = styled.div`
  width: 100%;
  background-color: ${(props) => props.theme.main.white};
`;

const HeaderContainer = styled.header`
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-height: 81px;
  @media (max-width: ${(props) => props.theme.size.tablet}) {
    width: 100%;
  }
`;

const CherryHomeIcon = styled(HomeIcon)`
  z-index: 1;
  cursor: pointer;
  height: 30px;
  padding-left: 24px;
  @media (max-width: ${(props) => props.theme.size.tablet}) {
    width: 32px;
    height: 32px;
  }
`;

const CherryLogoIcon = styled(CherryLogoSolid)`
  z-index: 1;
  cursor: pointer;
  height: 30px;
  padding-left: 24px;
`;

const IconButtonContainer = styled(IconButton)`
  z-index: 1;
  padding: 0px 24px !important;
`;

const CherryHamburgerIcon = styled(HamburgerIcon)`
  width: 30px !important;
  height: 30px !important;
  color: ${(props) => props.theme.main.primary} !important;
`;

const TitleContainer = styled.div`
  display: flex;
  justify-content: center;
  position: absolute;
  left: 0;
  right: 0;
  text-align: center;
`;

const Title = styled.span`
  color: ${(props) => props.theme.fonts.black};
  font-size: 20px;
  font-weight: bold;
  line-height: 33px;
  font-family: ${(props) => props.theme.fonts.primary};
`;

export const BadgeContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${(props) => props.theme.main.guestModeColor};
  padding: ${(props) => props.theme.constants.s} ${(props) => props.theme.constants.l};
`;

const GuestModeText = styled.span`
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
  margin-left: 10px;
`;

const Seperator = styled.div`
  background: ${(props) => props.theme.main.lightGrayishBlue};
  height: 1px;
`;

const CherryLeftIcon = styled(LeftIcon)`
  z-index: 1;
  cursor: pointer;
  height: 30px;
  padding-left: 24px;
`;
