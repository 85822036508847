import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

import { ChargeOffBanner, ChargeOffLoanPaymentPlan, ChargeOffPaymentOptions, PaymentTypes } from 'lib/components';
import { CommitmentSubType, LoanPlanStatuses, PLAN_STATUS, SEGMENT_EVENT_NAMES } from 'lib/constans';
import { SegmentEventNames, useSegment } from 'lib/hooks/useSegment';
import { findNextDueDate, FormatAmount, useFormatDate, isArray } from 'lib/utils';

import { AutoPay } from 'pages/Purchases/components';
import { useCallback, useEffect, useState } from 'react';
import useStore from 'lib/hooks/useStore';
import { paymentSearch } from 'lib/graphql/searches';
import { useGetPaymentMethods } from 'lib/hooks/usePayment';
import { Body, Heading, Icon, theme, Button } from '@frontend/cherry-library';
import { ReactComponent as CalendarIcon } from 'assets/images/calendar-check.svg';
import { ReactComponent as CheckIcon } from 'assets/images/check.svg';
import { ReactComponent as RefundIcon } from 'assets/images/refund.svg';
import { useContractModal, useDisputeModal, useDueDateModal, useHelpModal } from 'lib/hooks/useModal';
import { ReactComponent as InfoCircle } from 'assets/images/info_circle_outlined.svg';
import { Menu } from 'pages/Purchases/components/Menu';
import { SubMenu } from 'pages/Purchases/components/SubMenu';
import { useFile, useFiles } from 'lib/hooks/useFile';
import { fullSearch } from 'lib/graphql/searches/PaidInFull';
import { Contents } from 'pages/Account/Files/type';
import { GenericHeading } from 'pages/Purchases/components/GenericHeading';
import {
  isLoanChargeOff,
  isLoanClosed,
  isLoanDeceased,
  isLoanFraud,
  isLoanFullRefund,
  isLoanPendingPayment,
} from 'lib/utils/LoanCheckers';
import { calculateAmountDue } from 'lib/utils/calculateAmountDue';
import { StatusCard } from 'lib/components/StatusCard';
import { getSegmentEventDetailsData } from 'lib/utils/SegmentEventData';

const enum DocumentTypes {
  AGREEMENT_PIF = 'AGREEMENT_PIF',
  AGREEMENT_SIF = 'AGREEMENT_SIF',
}

const CHARGE_OFF_RELATED_COMMITMENT_TYPES = [CommitmentSubType.SETTLEMENT_OFFER, CommitmentSubType.PAYMENT_PLAN];

export const PaymentPlanDetails = ({ loan, isSold = false }: any) => {
  const { plans, merchant, organization, id, schedules, status, autoPay, commitments, servicing } = loan;

  const contractModal = useContractModal(loan);
  const disputeModal = useDisputeModal(loan);
  const helpModal = useHelpModal();
  const dueDateModal = useDueDateModal(loan, helpModal.openHelpModal);
  const { trackPage, trackSegmentEvent } = useSegment();

  const { t: translate } = useTranslation();
  const { FormatDate } = useFormatDate();
  const navigate = useNavigate();

  const { setSelectedChargeOffLoan, paymentMethods, borrower, debtSaleBuyers } = useStore();

  const [getFile] = useFile();
  const [getPaymentMethods, { loading: paymentLoading }] = useGetPaymentMethods();
  const [paidInFullDocument, setPaidInFullDocument] = useState<Contents>();
  const [settledInFullDocument, setSettledInFullDocument] = useState<Contents>();
  const [getFiles, { loading: documentLoading, data: documentResult }] = useFiles();

  const isPaidInFull = isArray(loan?.plans)[0]?.status === LoanPlanStatuses.CLOSED;
  const isFullRefund = isLoanFullRefund(loan);
  const isSettledInFull = isArray(loan?.plans)[0]?.status === LoanPlanStatuses.SETTLED;
  const isBankruptcy = isArray(loan?.plans)[0]?.status === LoanPlanStatuses.BANKRUPTCY;
  const isAutoPayActive = autoPay;
  const isActivePlan = PLAN_STATUS.includes(isArray(plans)?.[0]?.status);
  const isPaymentPastDue = isArray(plans)?.[0]?.daysPastDue > 0;
  const servicingProcessing = servicing?.status === 'PROCESSING';
  const pendingPayment = isLoanPendingPayment(loan);
  const isProcessing = servicingProcessing && pendingPayment;
  const isOnePaymentLeft = schedules?.filter((schedule) => schedule?.status === 'SCHEDULED')?.length === 1;
  const isDeceased = isLoanDeceased(loan);
  const isFraud = isLoanFraud(loan);
  const isClosed = isLoanClosed(isFraud, isDeceased);
  const isChargeOff = isLoanChargeOff(loan, isClosed, isDeceased, isFraud);
  const defaultView = !(
    isChargeOff ||
    isPaidInFull ||
    isFullRefund ||
    isSettledInFull ||
    isBankruptcy ||
    isSold ||
    isClosed ||
    isFraud ||
    isDeceased
  );

  const title = status === 'AWAITING_SERVICE' ? organization?.name : merchant?.name;
  const nextDuePayment = findNextDueDate(schedules);

  const amountDue = calculateAmountDue({
    daysPastDue: plans[0]?.daysPastDue,
    originalClosedAt: loan?.originalClosedAt,
    amountDue: plans[0]?.amountDue,
    payOff: plans[0]?.payOff,
  });

  const installmentAmount = isPaymentPastDue ? amountDue : nextDuePayment?.amount;
  const debtSaleBuyerDetail = debtSaleBuyers?.find((buyer) => buyer.id === loan?.debtSales?.debtSaleBuyerId);
  const chargeOffPaymentPlans = commitments?.filter((commitment) =>
    CHARGE_OFF_RELATED_COMMITMENT_TYPES.includes(commitment?.subType),
  );

  const fetchPaymentMethods = useCallback(() => {
    getPaymentMethods({
      variables: {
        input: {
          borrowerId: borrower?.id?.toString(),
          search: paymentSearch,
        },
      },
    });
  }, [borrower?.id, getPaymentMethods]);

  const getFullDocuments = () => {
    getFiles({
      variables: {
        input: {
          search: fullSearch(borrower?.id),
          pagination: {
            order: 'DESC',
            orderBy: 'createdAt',
          },
        },
      },
    });
  };

  const segmentEventPaymentPlanDetailsData = getSegmentEventDetailsData(borrower, loan);

  useEffect(() => {
    trackPage(SEGMENT_EVENT_NAMES.PATIENT_PORTAL.PAYMENT_PLAN_DETAILS.PAGE_LOAD, segmentEventPaymentPlanDetailsData);
    window.scrollTo(0, 0);
    if (isPaidInFull || isSettledInFull) {
      getFullDocuments();
    }
  }, []);

  useEffect(() => {
    if (!(paymentMethods && paymentMethods.length > 0) && !!loan?.installmentPaymentMethodId) {
      fetchPaymentMethods();
    }
  }, [fetchPaymentMethods, getPaymentMethods, paymentMethods]);

  useEffect(() => {
    if (documentResult?.getFileList?.contents) {
      findDocument(documentResult?.getFileList?.contents);
    }
  }, [documentResult]);

  const findDocument = (documents: Contents[]) => {
    documents.forEach((document: Contents) => {
      if (document?.name?.split(' ')[0] === loan?.displayId) {
        switch (document?.type) {
          case DocumentTypes.AGREEMENT_PIF:
            setPaidInFullDocument(document);
            break;
          case DocumentTypes.AGREEMENT_SIF:
            setSettledInFullDocument(document);
            break;
          default:
            break;
        }
      }
    });
  };

  const autoPayHandler = () => {
    const { AUTOPAY_PILL_PAYMENT_METHOD_CLICKED, AUTOPAY_PILL_ENABLE_NOW_CLICKED } =
      SEGMENT_EVENT_NAMES.PATIENT_PORTAL.PAYMENT_PLAN_DETAILS;

    const eventName = isAutoPayActive ? AUTOPAY_PILL_PAYMENT_METHOD_CLICKED : AUTOPAY_PILL_ENABLE_NOW_CLICKED;

    trackSegmentEvent(eventName, segmentEventPaymentPlanDetailsData);

    navigate(`/portal/${id}/autopay`);
  };

  const goToPayment = () => {
    trackSegmentEvent(SegmentEventNames.MAKE_PAYMENT, { loan_id: loan?.id });
    trackSegmentEvent(
      SEGMENT_EVENT_NAMES.PATIENT_PORTAL.PAYMENT_PLAN_DETAILS.MAKE_A_PAYMENT_QUICK_ACTION_CLICKED,
      segmentEventPaymentPlanDetailsData,
    );
    navigate(`/portal/${id}/payment-method`);
  };

  const goToOneTimePayment = () => {
    setSelectedChargeOffLoan(loan);
    navigate(`/portal/${id}/one-time-payment`);
  };

  const goToMonthlyPaymentPlan = () => {
    setSelectedChargeOffLoan(loan);
    navigate(`/portal/${id}/monthly-payment-plan`);
  };

  const goToSettlements = () => {
    setSelectedChargeOffLoan(loan);
    navigate(`/portal/${id}/settlement`);
  };

  const getTotalAmount = () => {
    if (isChargeOff) return plans?.[0]?.netChargeOff;
    if (isOnePaymentLeft) return plans?.[0]?.payOff;

    const { dueFees } = plans?.[0] || {};
    let totalAmount = installmentAmount;

    if (dueFees) {
      totalAmount = installmentAmount ? installmentAmount + dueFees : dueFees;
    }

    return totalAmount;
  };

  const handleSelectChargeOffPaymentOption = (selectedOption: PaymentTypes) => {
    if (selectedOption === PaymentTypes.OneTimePayment) {
      goToOneTimePayment();
    } else if (selectedOption === PaymentTypes.MonthlyPaymentPlan) {
      goToMonthlyPaymentPlan();
    } else if (selectedOption === PaymentTypes.Settlement) {
      goToSettlements();
    }
  };

  const getAgreementFile = async (fileId: string, { paidInFull = false } = {}) => {
    if (paidInFull) {
      trackSegmentEvent(
        SEGMENT_EVENT_NAMES.PATIENT_PORTAL.PAYMENT_PLAN_DETAILS.DOWNLOAD_PIF_CLICKED,
        segmentEventPaymentPlanDetailsData,
      );
    } else {
      trackSegmentEvent(
        SEGMENT_EVENT_NAMES.PATIENT_PORTAL.PAYMENT_PLAN_DETAILS.DOWNLOAD_SIF_CLICKED,
        segmentEventPaymentPlanDetailsData,
      );
    }
    getFile({ variables: { input: { fileId } } });
  };

  const openContractModal = () => {
    contractModal.openContractModal();
    trackSegmentEvent(
      SEGMENT_EVENT_NAMES.PATIENT_PORTAL.PAYMENT_PLAN_DETAILS.EXEMPT_LOAN_DOWNLOAD_CONTRACT_CLICKED,
      segmentEventPaymentPlanDetailsData,
    );
  };

  const ChargeOffHeading = (
    <>
      <Title fontWeight={600} size="small" color={theme.main.cherryRed}>
        {translate('contracts.past.loanStatus.outstanding')}
      </Title>
      <HeadingRed level="2" text={FormatAmount(loan?.plans[0]?.netChargeOff)}></HeadingRed>
      <ItemWrapper>
        <ChargeOffBanner planId={plans?.[0]?.id} />
      </ItemWrapper>
    </>
  );

  const ClosedHeading = (
    <>
      <GenericHeading
        loan={loan}
        contractModal={contractModal}
        title={translate('closedLoan.closed')}
        infoTitle={translate('closedLoan.closed')}
        infoBody={translate(`closedLoan.closedNoFurtherAction`, {
          date: FormatDate(loan?.plans[0]?.dateLastCurrent),
        })}
      >
        <WrapperVertical>
          <CenteredBody>{translate(`closedLoan.supportTeam`)}</CenteredBody>
        </WrapperVertical>
      </GenericHeading>
    </>
  );

  const DeceasedHeading = (
    <>
      <GenericHeading
        loan={loan}
        contractModal={contractModal}
        title={translate('closedLoan.closed')}
        infoTitle={translate('closedLoan.closed')}
        infoBody={translate(`deceased.heading`, {
          date: FormatDate(loan?.plans[0]?.dateLastCurrent),
        })}
      >
        <WrapperVertical>
          <CenteredBody>{translate(`deceased.collectionTeam`)}</CenteredBody>
        </WrapperVertical>
      </GenericHeading>
    </>
  );

  const SoldHeading = (
    <>
      <GenericHeading
        loan={loan}
        contractModal={contractModal}
        title={translate('contracts.sold.amountLabel')?.toLowerCase()}
        infoTitle={translate('contractTransferred.title')}
        infoBody={translate(`contracts.sold.soldInfo`, {
          paymentPastDue: 120,
          debtSoldDate: FormatDate(loan?.debtSales?.soldDate),
        })}
      >
        {debtSaleBuyerDetail && (
          <WrapperVertical>
            <Body fontWeight={700}>{debtSaleBuyerDetail?.name}</Body>
            <Body>{debtSaleBuyerDetail?.email}</Body>
            <Body>{debtSaleBuyerDetail?.phone}</Body>
            <Body>{debtSaleBuyerDetail?.address}</Body>
            {debtSaleBuyerDetail?.address2 && <Body>{debtSaleBuyerDetail?.address2}</Body>}
            <Body>{` ${debtSaleBuyerDetail?.city}, ${debtSaleBuyerDetail?.state} ${debtSaleBuyerDetail?.zip}`}</Body>
          </WrapperVertical>
        )}
      </GenericHeading>
    </>
  );

  const BankruptcyHeading = (
    <>
      <GenericHeading
        loan={loan}
        contractModal={contractModal}
        title={translate('paymentPlans.completed.bankruptcy')?.toLowerCase()}
        infoTitle={translate('paymentPlans.completed.bankruptcy')}
        infoBody={translate('bankruptcy.chargedOffForBankruptcy', {
          date: FormatDate(loan?.plans[0]?.dateLastCurrent),
        })}
      >
        <WrapperVertical>
          <CenteredBody>{translate('bankruptcy.forQuestionsContactCherry')}</CenteredBody>
        </WrapperVertical>
      </GenericHeading>
    </>
  );

  const BaseHeading = (
    <>
      <Title fontWeight={600} size="small">
        {translate('contracts.passive.title')?.toLowerCase()}
      </Title>
      <Heading
        level="2"
        text={FormatAmount(status === 'REFUNDED' ? loan?.purchaseAmount : loan?.grossAmount)}
      ></Heading>
    </>
  );

  const FullRefundHeading = (
    <>
      {BaseHeading}
      <IconContainer>
        <Icon src={RefundIcon} color={theme.main.kellyGreen} />
        <Body color={theme.main.kellyGreen}> {translate('fullRefund.refunded')}</Body>
      </IconContainer>
      <CenteredBody>
        {translate('fullRefund.explanation', {
          name: title,
          date: FormatDate(loan?.plans[0]?.dateLastCurrent),
        })}
      </CenteredBody>

      <DownloadButton onClick={openContractModal} variant="secondary" fullWidth>
        {translate('contractModal.downloadContract')}
      </DownloadButton>
    </>
  );

  const PaidInFullHeading = (
    <>
      {BaseHeading}
      <IconContainer>
        <Icon src={CheckIcon} color={theme.main.kellyGreen} />
        <Body color={theme.main.kellyGreen}> {translate('paymentPlans.completed.paidFull')}</Body>
      </IconContainer>
      <CenteredBody>{translate('paidInFull.explanation')}</CenteredBody>
      <DownloadButton
        onClick={() => getAgreementFile(paidInFullDocument?.id as string, { paidInFull: true })}
        variant="secondary"
        fullWidth
        disabled={documentLoading}
      >
        {translate('paidInFull.downloadPaidInFullLetter')}
      </DownloadButton>
    </>
  );

  const SettledInFullHeading = (
    <>
      {BaseHeading}
      <IconContainer>
        <Icon src={CheckIcon} color={theme.main.kellyGreen} />
        <Body color={theme.main.kellyGreen}> {translate('paymentPlans.completed.settledFull')}</Body>
      </IconContainer>
      <CenteredBody>{translate('settledInFull.explanation')}</CenteredBody>
      <DownloadButton
        onClick={() => getAgreementFile(settledInFullDocument?.id as string)}
        variant="secondary"
        fullWidth
        disabled={documentLoading}
      >
        {translate('settledInFull.downloadSettledInFullLetter')}
      </DownloadButton>
    </>
  );

  const DefaultHeading = (
    <>
      <Title fontWeight={600} size="small">
        {translate('paymentDetails.nextPayment')}
      </Title>
      <Heading level="2" text={FormatAmount(getTotalAmount())}></Heading>
    </>
  );

  return (
    <>
      <HeaderContainer>
        {isProcessing && (
          <StatusCard
            title={translate('contracts.active.button.processing')}
            subtitle={translate('paymentPlanDetails.processing.reflected')}
            color={theme.main.alertWarning10}
            borderColor={theme.main.alertWarning}
            icon={InfoCircle}
          ></StatusCard>
        )}
        <Heading level="3" text={title}></Heading>

        {isClosed && ClosedHeading}
        {!isClosed && isFraud && ClosedHeading}
        {!isClosed && isDeceased && DeceasedHeading}
        {isSold && SoldHeading}
        {isBankruptcy && BankruptcyHeading}
        {isChargeOff && ChargeOffHeading}
        {isPaidInFull && PaidInFullHeading}
        {isFullRefund && FullRefundHeading}
        {isSettledInFull && SettledInFullHeading}
        {defaultView && DefaultHeading}

        {isActivePlan && (
          <>
            {isChargeOff && (!chargeOffPaymentPlans || chargeOffPaymentPlans?.length === 0) && (
              <ItemWrapper>
                <ChargeOffPaymentOptions
                  loan={loan}
                  outstandingBalance={plans?.[0]?.netChargeOff}
                  onContinue={handleSelectChargeOffPaymentOption}
                />
              </ItemWrapper>
            )}
            {((isChargeOff && chargeOffPaymentPlans?.length > 0) || isSettledInFull) && (
              <ItemWrapper>
                <ChargeOffLoanPaymentPlan commitments={chargeOffPaymentPlans} />
              </ItemWrapper>
            )}
          </>
        )}

        {defaultView && (
          <>
            {autoPay ? (
              <CommitmentContainer>
                <Icon color={theme.main.kellyGreen} src={CalendarIcon} />

                <Heading
                  level="5"
                  text={`${translate('contracts.active.scheduled.text')} ${FormatDate(nextDuePayment?.dueAt)}`}
                />
              </CommitmentContainer>
            ) : (
              <>
                <Heading
                  level="5"
                  text={translate('paymentDetails.makeAPaymentBy', { date: FormatDate(nextDuePayment?.dueAt) })}
                ></Heading>
                <Heading level="5" text={translate('paymentDetails.orEnableAutopay')}></Heading>
              </>
            )}
            <AutoPay
              loan={loan}
              paymentMethods={paymentMethods}
              loading={paymentLoading}
              installmentPaymentMethodId={loan?.installmentPaymentMethodId?.toString()}
              isAutoPayActive={isAutoPayActive}
              manageHandler={autoPayHandler}
            />
            <Button fullWidth onClick={goToPayment}>
              {translate('contracts.active.paymentBtn')}
            </Button>
            <Menu contractModal={contractModal} loan={loan} id={id as string} />
          </>
        )}
      </HeaderContainer>

      <SubMenu loan={loan} contractModal={contractModal} />
      {contractModal.contractModalVisibility && contractModal.ModalBody}
      {dueDateModal.dueDateModalVisibility && dueDateModal.ModalBody}
      {disputeModal.disputeModalVisibility && disputeModal.ModalBody}
      {helpModal.helpModalVisibility && helpModal.ModalBody}
    </>
  );
};

const CommitmentContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
`;

const HeaderContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const ItemWrapper = styled.div`
  margin-top: 24px;
  box-shadow: 0px 36px 67px 0px rgba(0, 0, 0, 0.07), 0px 13.141px 24.456px 0px rgba(0, 0, 0, 0.05),
    0px 6.38px 11.873px 0px rgba(0, 0, 0, 0.04), 0px 3.127px 5.82px 0px rgba(0, 0, 0, 0.03),
    0px 1.237px 2.301px 0px rgba(0, 0, 0, 0.02);
`;

const Title = styled(Body)`
  margin-top: 16px !important;
  text-transform: uppercase;
`;

const HeadingRed = styled(Heading)`
  color: ${theme.main.cherryRed} !important;
  margin-bottom: 24px !important;
`;

const IconContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
  margin-bottom: 16px;
`;

const DownloadButton = styled(Button)`
  color: ${theme.main.midnightBlue} !important;
  background-color: transparent !important;
  margin-top: 24px;
`;

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

const WrapperVertical = styled(Wrapper)`
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 8px;
`;

const CenteredBody = styled(Body)`
  text-align: center;
`;
