import styled from 'styled-components';
import { Body, Heading, Icon, theme } from '@frontend/cherry-library';
import {
  findLastDueDate,
  findNextCommitmentDate,
  findNextDueDate,
  findNextScheduledDate,
  FormatAmount,
  isArray,
  useFormatDate,
} from 'lib/utils';
import { Loan } from 'pages/Purchases/type';
import Tab from 'lib/components/Tab/Tab';
import { useEffect, useState } from 'react';
import { PaymentTable } from './PaymentTable';
import { useGetServicingStatusHistory } from 'lib/hooks/useLoan';
import { findPaymentMethodInfo } from 'lib/utils/FindPaymentMethodInfo';
import useStore from 'lib/hooks/useStore';
import { ReactComponent as ContractLogo } from 'assets/images/contract.svg';
import { useTranslation } from 'react-i18next';
import { SubMenuTile } from './SubMenuTile';
import { ReactComponent as WarningLogo } from 'assets/images/warning-outline.svg';
import { LoanPlanStatuses, SEGMENT_EVENT_NAMES } from 'lib/constans';
import { findNextLoanPayment, isLoanFullRefund } from 'lib/utils/LoanCheckers';
import { getSegmentEventDetailsData } from 'lib/utils/SegmentEventData';
import { useSegment } from 'lib/hooks/useSegment';

interface Props {
  loan: Loan;
  contractModal;
}

export const SubMenu = ({ loan, contractModal }: Props) => {
  const [getServicingStatusHistory, { data }] = useGetServicingStatusHistory(Number(loan?.plans?.[0]?.id));
  const isFullRefund = isLoanFullRefund(loan);
  const [activeTab, setActiveTab] = useState(isFullRefund ? 1 : 0);
  const isChargeOff = isArray(loan?.plans)?.[0]?.status === 'CHARGED_OFF';
  const isBankruptcy = isArray(loan?.plans)?.[0]?.status === 'BANKRUPTCY';
  const isDeptSale = isArray(loan?.plans)?.[0]?.status === 'DEBT_SALE';
  const isClosed = isArray(loan?.plans)?.[0]?.status === 'CLOSED';
  const isLoanClosed = isClosed || isBankruptcy || isDeptSale;
  const isPaymentPastDue = isArray(loan?.plans)?.[0]?.daysPastDue > 0;
  const [chargedOffStatusChangeDate, setChargeOffStatusChangeDate] = useState();
  const { FormatDate, FormatDateTimeNumeric, FormatDateDay } = useFormatDate();
  const { paymentMethods } = useStore();
  const paymentMethodInfo = findPaymentMethodInfo({
    targetId: Number(loan?.installmentPaymentMethodId),
    paymentMethods: paymentMethods,
  });
  const downPaymentMethodInfo = findPaymentMethodInfo({
    targetId: Number(loan?.downPaymentPaymentMethodId),
    paymentMethods: paymentMethods,
  });

  const { t: translate } = useTranslation();
  const { borrower } = useStore();
  const { trackSegmentEvent } = useSegment();
  const nextDuePayment = findNextDueDate(loan?.schedules);
  const nextCommitmentPlan = findNextCommitmentDate(loan?.commitments);
  const isAutoPayActive = loan?.autoPay;
  const nextScheduledPlan = findNextScheduledDate(nextDuePayment, nextCommitmentPlan, isAutoPayActive);
  const lastDuePayment = findLastDueDate(loan?.schedules);
  const isPaidInFull = isArray(loan?.plans)[0]?.status === LoanPlanStatuses.CLOSED;
  const isSettledInFull = isArray(loan?.plans)[0]?.status === LoanPlanStatuses.SETTLED;

  const nextPayment = FormatDate(findNextLoanPayment(nextScheduledPlan, nextDuePayment));

  const finalPaymentValue = lastDuePayment?.dueAt ? FormatDate(lastDuePayment?.dueAt) : translate('general.na');
  const finalPayment = isFullRefund ? translate('general.na') : finalPaymentValue;

  const remaining = loan?.plans[0]?.dueFees + loan?.plans[0]?.payOff;
  const segmentEventPaymentPlanDetailsData = getSegmentEventDetailsData(borrower, loan);

  useEffect(() => {
    if (loan?.plans?.[0] && !chargedOffStatusChangeDate) {
      getServicingStatusHistory();
    }
  }, [loan?.plans?.[0], chargedOffStatusChangeDate]);

  useEffect(() => {
    if (data?.getServicingStatusHistory) {
      const date = data.getServicingStatusHistory?.find(
        (historyItem) => historyItem?.status === 'CHARGED_OFF',
      )?.createdAt;

      if (date) {
        setChargeOffStatusChangeDate(date);
      }
    }
  }, [data]);

  const dayToShow = FormatDateDay(findNextLoanPayment(nextScheduledPlan, nextDuePayment));

  const ordinalSuffixedDay = () => {
    const j = Number(dayToShow) % 10;
    const k = Number(dayToShow) % 100;
    if (j === 1 && k !== 11) {
      return dayToShow + 'st';
    }
    if (j === 2 && k !== 12) {
      return dayToShow + 'nd';
    }
    if (j === 3 && k !== 13) {
      return dayToShow + 'rd';
    }
    return dayToShow + 'th';
  };

  const paymentDate =
    isChargeOff || isFullRefund
      ? translate('general.na')
      : `${ordinalSuffixedDay()} ${translate('paymentDetails.purchaseDetails.paymentDate.general')}`;

  const changeActiveTab = (index: number) => {
    trackSegmentEvent(
      index === 0
        ? SEGMENT_EVENT_NAMES.PATIENT_PORTAL.PAYMENT_PLAN_DETAILS.PAYMENT_SCHEDULE_TAB_CLICKED
        : SEGMENT_EVENT_NAMES.PATIENT_PORTAL.PAYMENT_PLAN_DETAILS.PURCHASE_DETAILS_TAB_CLICKED,
      segmentEventPaymentPlanDetailsData,
    );
    setActiveTab(index);
  };

  const openContractModal = () => {
    trackSegmentEvent(
      SEGMENT_EVENT_NAMES.PATIENT_PORTAL.PAYMENT_PLAN_DETAILS.PURCHASE_DETAILS_CONTRACT_DOWNLOAD_CLICKED,
      segmentEventPaymentPlanDetailsData,
    );
    contractModal.openContractModal();
  };

  return (
    <>
      <Tabs>
        {!isFullRefund && (
          <Tab
            name={translate('subMenu.paymentSchedule')}
            isActive={activeTab === 0}
            onClick={() => changeActiveTab(0)}
          />
        )}
        <Tab
          name={translate('subMenu.purchaseDetails')}
          isActive={activeTab === 1}
          onClick={() => changeActiveTab(1)}
        />
      </Tabs>
      <ContentWrapper>
        <Heading level="4" text={translate('subMenu.ID', { id: loan?.displayId })} />
        {!activeTab ? (
          <>
            {isPaymentPastDue && (
              <OverDueContainer>
                <Icon src={WarningLogo} color={theme.main.cherryRed} />
                <Body fontWeight={700} size="small" color={theme.main.cherryRed}>
                  {translate('subMenu.yourAccountIsOverdue')}
                </Body>
              </OverDueContainer>
            )}
            {isChargeOff && (
              <ChargedOffContainer>
                <Icon src={WarningLogo} />
                <Body fontWeight={700} size="small">
                  {translate('subMenu.yourAccountIsChargedOff')}
                </Body>
              </ChargedOffContainer>
            )}
            {loan.status !== 'REFUNDED' && (
              <PaymentTable
                isChargeOff={isChargeOff}
                remaining={remaining}
                closed={isLoanClosed}
                loan={loan}
                chargedOffStatusChangeDate={chargedOffStatusChangeDate}
              />
            )}
          </>
        ) : (
          <>
            <TableTitle fontWeight={700}>{translate('subMenu.purchaseAgreement')}</TableTitle>
            <Seperator />
            <SubMenuTile text={translate('subMenu.totalPurchaseAmount')}>{FormatAmount(loan?.totalAmount)}</SubMenuTile>
            <SubMenuTile text={translate('subMenu.contractSigned')}>
              {FormatDateTimeNumeric(loan?.createdAt)}
            </SubMenuTile>
            <SubMenuTile text={translate('subMenu.downPaymentPaid')}>
              {FormatAmount(loan?.downPaymentAmount)}
            </SubMenuTile>
            <SubMenuTile text={translate('subMenu.paymentMethod')}>
              {downPaymentMethodInfo || translate('general.na')}
            </SubMenuTile>
            <SubMenuTile text={translate('subMenu.contract')} last={true}>
              <Contract onClick={openContractModal} color={theme.main.kellyGreen} fontWeight={400} size="small">
                {loan?.displayId} <Icon color={theme.main.kellyGreen} src={ContractLogo} />
              </Contract>
            </SubMenuTile>
            <TableTitle fontWeight={700}>{translate('subMenu.paymentPlan')}</TableTitle>
            <Seperator />
            <SubMenuTile text={translate('subMenu.monthlyPayments')}>{loan?.product?.term}</SubMenuTile>
            <SubMenuTile text={translate('subMenu.paymentAmount')}>
              {' '}
              {FormatAmount(loan?.installmentAmount)}
            </SubMenuTile>
            <SubMenuTile text={translate('subMenu.apr')}>
              {' '}
              {loan?.plans?.[0]?.apr !== null ? loan?.plans?.[0]?.apr?.toFixed(2) : loan?.product?.apr}%{' '}
              {translate('paymentCalculator.summary.apr')}
            </SubMenuTile>
            <SubMenuTile text={translate('subMenu.paymentDate')}>{paymentDate}</SubMenuTile>
            <SubMenuTile text={translate('subMenu.paymentMethod')}>
              {loan?.autoPay ? (
                <PurchaseItem fontWeight={400} size="small">
                  {paymentMethodInfo || translate('general.na')}
                </PurchaseItem>
              ) : (
                <PurchaseItem fontWeight={400} size="small" color={theme.main.cherryRed}>
                  {translate('paymentPlans.active.autopay')}
                </PurchaseItem>
              )}
            </SubMenuTile>
            <SubMenuTile text={translate('subMenu.nextPayment')}>
              {isPaidInFull || isSettledInFull || isChargeOff || isBankruptcy || isDeptSale || isFullRefund
                ? translate('general.na')
                : nextPayment}
            </SubMenuTile>
            <SubMenuTile text={translate('subMenu.finalPayment')} last={true}>
              {finalPayment}
            </SubMenuTile>
          </>
        )}
      </ContentWrapper>
    </>
  );
};

const ContentWrapper = styled.div`
  padding: 20px;
  border-radius: 0 4px 4px 4px;
  background-color: white;
  border-top: none;
`;

const TableTitle = styled(Body)`
  font-style: normal;
  font-size: 11px !important;
  letter-spacing: 0.55px;
  text-transform: uppercase;
  margin-top: 24px !important;
`;

const Seperator = styled.div`
  background: ${theme.main.midnightBlue};
  height: 1px;
  margin: 5px 0;
`;

const PurchaseItem = styled(Body)`
  flex: 1;
`;

const Tabs = styled.div`
  display: flex;
  gap: 8px;
  cursor: pointer;
  margin-top: 48px;
`;

const Contract = styled(PurchaseItem)`
  display: flex;
  align-items: center;
  gap: 4px;
  cursor: pointer;
  text-decoration: underline;
`;

const BadgeContainer = styled.div`
  display: flex;
  padding: 8px;
  align-items: center;
  justify-content: center;
  align-self: stretch;
  gap: 4px;
  border-radius: 40px;
  margin-top: 24px;
`;

const OverDueContainer = styled(BadgeContainer)`
  border: 1px solid ${theme.main.cherryRed};
  background: ${theme.main.cherryRed10};
`;

const ChargedOffContainer = styled(BadgeContainer)`
  border: 1px solid ${theme.main.alertWarning};
  background: #fedda3 !important;
`;
