import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { PREQUAL_URL } from 'config';
import { Button } from 'lib/components';
import { ExternalRedirect } from 'lib/utils/ExternalRedirect';
import { Body, Heading, theme } from '@frontend/cherry-library';
import { useSegment } from 'lib/hooks/useSegment';
import { SEGMENT_EVENT_NAMES } from 'lib/constans';
import { Approval } from 'pages/Approvals/type';

interface Props {
  index: number;
  application: Approval;
}

export const UnfinishedCard = ({ index, application }: Props) => {
  const { t: translate } = useTranslation();
  const { trackSegmentEvent } = useSegment();
  const title = application?.organization?.name;
  const slug = application?.organization?.slug;

  const checkoutHandler = () => {
    const { id, status, balanceAvailable, organization } = application;
    trackSegmentEvent(SEGMENT_EVENT_NAMES.PATIENT_PORTAL.APPLICATIONS_OVERVIEW.FINISH_APPLICATION_CLICKED, {
      applicationId: id,
      applicationStatus: status,
      approvalBalance: balanceAvailable,
      organizationId: organization?.id,
    });
    ExternalRedirect(`${PREQUAL_URL}/${slug}`);
  };

  return (
    <>
      {index !== 0 && <Seperator />}
      <ApplicationsContainer>
        <CardDetails>
          <PendingStatus fontWeight={600} size="small" color={theme.main.midnightBlue50}>
            {translate('approvals.unfinished.status')}
          </PendingStatus>
          <Heading level="4" text={title}></Heading>
          <Uppercase size="small" fontWeight={600}>
            {translate('general.applicationId')}: {application?.displayId}
          </Uppercase>
          <Body fontWeight={400} size="small">
            {translate('applicationsPage.unfinished.subtitle')}
          </Body>
        </CardDetails>
        <Button onClick={checkoutHandler}>{translate('paymentPlans.unfinishedCheckout.finishButton')}</Button>
      </ApplicationsContainer>
    </>
  );
};

const ApplicationsContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 8px;
`;

const PendingStatus = styled(Body)`
  align-self: stretch;
  letter-spacing: 0.6px;
  text-transform: uppercase;
`;

const CardDetails = styled.div`
  display: flex;
  align-items: left;
  align-self: stretch;
  flex-direction: column;
  min-width: 60%;
  padding-bottom: 16px;
`;

const Seperator = styled.div`
  background: ${theme.main.midnightBlue20};
  height: 1px;
  width: 100%;
  margin: 0 0 16px 0;
`;

const Uppercase = styled(Body)`
  text-transform: uppercase;
`;
