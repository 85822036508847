import React from 'react';

import { Button, Card } from 'lib/components';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { theme } from 'config/theme';

export const PlanCard = ({ plan, isDetailPage = false }: any) => {
  const { t: translate } = useTranslation();
  const navigate = useNavigate();

  const showMembershipPlanDetail = () => {
    try {
      navigate(`/portal/memberships/${plan?.id}`);
    } catch (e) {
      console.warn('ERROR', e);
    }
  };

  return (
    <Card style={{ backgroundColor: isDetailPage ? theme.main.textColor : theme.main.white }}>
      <InfoContainer>
        <Username isDetailPage={isDetailPage}>
          {plan?.customer?.firstName} {plan?.customer?.lastName}
        </Username>
        <StatusContainer>
          <StatusText status={plan?.status}>{translate(`memberships.status.${plan?.status}`)}</StatusText>
          <Dot status={plan?.status} />
        </StatusContainer>
      </InfoContainer>
      <PlanName>{plan?.plan?.name}</PlanName>
      <OrganizationName isDetailPage={isDetailPage}>{plan?.organization?.name}</OrganizationName>
      {!isDetailPage && (
        <Button onClick={showMembershipPlanDetail} m={'0px'}>
          {translate('memberships.button.viewDetail')}
        </Button>
      )}
      {isDetailPage && (
        <LogoWrapper>
          <CherryLogo src={'/logo-green.svg'} alt="logo" />
        </LogoWrapper>
      )}
    </Card>
  );
};

const InfoContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 16px;
`;

const StatusContainer = styled.div`
  display: inline-flex;
  align-items: center;
  justify-content: space-between;
`;

const StatusText = styled.span<{ status: string }>`
  color: ${(props) => (props.status === 'ACTIVE' ? props.theme.main.green : props.theme.main.red)};
`;

const Dot = styled.div<{ status: string }>`
  width: 16px;
  height: 16px;
  border-radius: 8px;
  background-color: ${(props) => (props.status === 'ACTIVE' ? props.theme.main.green : props.theme.main.red)};
  margin-left: 4px;
`;

const PlanName = styled.div`
  color: ${(props) => props.theme.main.green};
  font-size: 32px;
  font-weight: 700;
  line-height: 44px;
`;

const Username = styled.div<{ isDetailPage: boolean }>`
  color: ${(props) => (props?.isDetailPage ? props.theme.main.white : props.theme.main.black)};
  font-size: 14px;
  font-weight: 700;
  line-height: 19px;
`;

const OrganizationName = styled(Username)`
  font-style: italic;
  margin-bottom: 16px;
  margin-top: 16px;
`;

const LogoWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
`;

const CherryLogo = styled.img`
  z-index: 1;
  cursor: pointer;
  height: 30px;
`;
