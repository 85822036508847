import React, { useMemo } from 'react';
import styled from 'styled-components';
import { ProgressBar, Step } from 'react-step-progress-bar';

import { Icon } from 'lib/components';

type StepperProps = {
  totalStepCount: number;
  activeStep: number;
};

export const Stepper = ({ totalStepCount, activeStep }: StepperProps) => {
  const stepPercentage = useMemo(() => ((activeStep - 1) / (totalStepCount - 1)) * 100, [totalStepCount, activeStep]);

  const steps = useMemo(
    () =>
      Array.from({ length: totalStepCount }, (_, index) => index + 1).map((step) => (
        <Step key={`Step ${step}`}>
          {() => {
            const isPassed = step < activeStep;
            return (
              <StepItem passed={isPassed} isActive={step === activeStep}>
                {isPassed ? <Icon src="check_white" width={12} height={12} /> : step}
              </StepItem>
            );
          }}
        </Step>
      )),
    [totalStepCount],
  );

  return (
    <StepperContainer stepPercentage={stepPercentage}>
      <ProgressBar percent={stepPercentage}>{steps}</ProgressBar>
    </StepperContainer>
  );
};

const StepperContainer = styled.div<{ stepPercentage: number }>`
  .RSPBprogressBar {
    height: 1px;
    width: 30%;
    line-height: 1;
    border-radius: 10px;
    position: relative;
    background-color: ${(props) => props.theme.main.midnightBlueGray};
    display: flex;
    justify-content: space-between;
    align-items: center;
    z-index: 0;
    margin: 20px auto;
  }

  .RSPBprogressBar .RSPBstep {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    z-index: 0;
    position: absolute;
    transform: translateX(-50%);
    transition-property: all;
    transition-timing-function: ease;
  }

  .RSPBprogressBar .RSPBprogressBarText {
    color: white;
    font-size: 10px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  .RSPBprogressBar .RSPBprogression {
    position: absolute;
    transition: width 0.3s ease;
    left: 0;
    top: 0;
    bottom: 0;
    border-radius: 10px;
    background: ${(props) => props.theme.main.primary};
    z-index: -1;
    width: ${`${(props) => 100 / props.stepPercentage}%`} !important;
  }
`;

const StepItem = styled.div<{ isActive: boolean; passed: boolean }>`
  width: 16px;
  height: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  background-color: ${(props) =>
    props.passed ? props.theme.main.green : props.isActive ? props.theme.main.primary : props.theme.main.white};
  border: 1px solid
    ${(props) =>
      props.passed
        ? props.theme.main.green
        : props.isActive
        ? props.theme.main.primary
        : props.theme.main.midnightBlueGray};
  color: ${(props) => (props.isActive || props.passed ? props.theme.main.white : props.theme.main.midnightBlueGray)};
  font-size: 10px;
  font-weight: 700;
  font-family: 'Open Sans';
`;
