import { useCallback, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { IMaskInput } from 'react-imask';
import { useNavigate, useParams } from 'react-router-dom';
import styled from 'styled-components';

import { ChargeOffBanner, Container } from 'lib/components';
import { Button, ButtonColor, Loading } from 'lib/components';
import { AnalyticEventNames, useAnalytics } from 'lib/hooks/useAnalytics';
import { useLoanList } from 'lib/hooks/useContract';
import useStore from 'lib/hooks/useStore';
import {
  calculatePaymentFeeAmount,
  calculatePaymentFeeTotalAmount,
  findNextDueDate,
  FormatAmount,
  useFormatDate,
  isArray,
} from 'lib/utils';

import { SegmentEventNames, useSegment } from 'lib/hooks/useSegment';
import { HeaderTitle } from '../components';
import { PaymentTypeItem } from './components';
import { BuildTxInfoPayload } from './selector';
import { PaymentBreakdown } from 'pages/Payments/components/PaymentBreakdown';
import { LOAN_PLAN_STATUS, LOAN_PLAN_SUBSTATUS, PAYMENT_TYPE, SEGMENT_EVENT_NAMES } from 'lib/constans';
import { calculateAmountDue } from 'lib/utils/calculateAmountDue';
import { getSegmentEventDetailsData } from 'lib/utils/SegmentEventData';

const enum AmountTypes {
  PAY_CURRENT_BALANCE = 'Pay Current Balance',
  OTHER_AMOUNT = 'Other Amount',
  PAY_OFF_CONTRACT = 'Pay Off Contract',
}

const PaymentType = () => {
  const navigate = useNavigate();
  const { t: translate } = useTranslation();
  const { trackEvent } = useAnalytics();
  const { id } = useParams();
  const {
    loanList,
    setPaymentInfo,
    setAmountOfPayment,
    amountOfPayment,
    getActiveLoan,
    setHeaderTitle,
    paymentMethod,
    paymentMethodFee,
    paymentType,
    setPaymentType,
    borrower,
  } = useStore();
  const [fetchLoanList, { loading }] = useLoanList('FUNDED');
  const { trackSegmentEvent, trackPage } = useSegment();
  const { FormatDate } = useFormatDate();

  const paymentTypeRef = useRef(null);
  const inputRef = useRef<{ maskValue?: string }>(null);

  const activeLoan = getActiveLoan(id) || {};

  const { schedules, plans } = activeLoan;
  const { amountDue, status, daysPastDue, netChargeOff, payOff, subStatus, dueFees } = isArray(plans)[0] || {};
  const segmentEventPaymentPlanDetailsData = getSegmentEventDetailsData(borrower, activeLoan);

  const amountDueValue = calculateAmountDue({
    daysPastDue,
    originalClosedAt: activeLoan?.originalClosedAt,
    amountDue,
    payOff,
  });

  const dueType = status === LOAN_PLAN_STATUS.LATE ? 'late' : 'next';
  const daysPastDueText = `makePayment.paymentMethod.day${daysPastDue === 1 ? '' : 's'}.past`;
  const isPaymentPastDue = daysPastDue > 0;
  const nextSchedule = findNextDueDate(schedules);
  const nextScheduleAmount = nextSchedule?.amount;
  const dueDate = nextSchedule?.dueAt;
  const isLatePayment = dueType === 'late';
  const isChargeOff = status === LOAN_PLAN_STATUS.CHARGED_OFF;
  const payoffAmount = isChargeOff ? netChargeOff : payOff;
  const isOnePaymentLeft = schedules?.filter((schedule) => schedule?.status === 'SCHEDULED')?.length === 1;

  const calculateDueAmount = () => {
    if (isOnePaymentLeft) {
      return payOff;
    } else if (isLatePayment) {
      return amountDueValue + (dueFees ?? 0);
    } else {
      return nextScheduleAmount;
    }
  };

  const showPrincipalOnly =
    status === LOAN_PLAN_STATUS.OPEN && subStatus === LOAN_PLAN_SUBSTATUS.PERFORMING && amountDueValue === 0;

  const isMethodCreditCard = paymentMethod.type === 'CARD' && paymentMethod?.storedCard?.type !== 'DEBIT';
  const paymentFee = paymentMethodFee?.[paymentMethod?.storedCard?.type || '']?.percent;
  const paymentFeeAmount = calculatePaymentFeeAmount(
    paymentMethodFee,
    paymentMethod?.storedCard?.type,
    amountOfPayment,
  );
  const paymentFeeTotalAmount = calculatePaymentFeeTotalAmount(
    paymentMethodFee,
    paymentMethod?.storedCard?.type,
    amountOfPayment,
  );
  const dueAmount = calculateDueAmount();

  const handleOutsideClickCallback = useCallback((e) => handleOutsideClick(e), []);

  useEffect(() => {
    if (inputRef?.current) {
      inputRef.current.maskValue = String(amountOfPayment);
    }
    window.addEventListener('click', handleOutsideClickCallback);
    return () => {
      window.removeEventListener('click', handleOutsideClickCallback);
    };
  }, []);

  useEffect(() => {
    setHeaderTitle('makePayment.title');
    trackPage(
      SEGMENT_EVENT_NAMES.PATIENT_PORTAL.MAKE_PAYMENT_SELECT_AMOUNT.PAGE_LOAD,
      segmentEventPaymentPlanDetailsData,
    );
  }, [setHeaderTitle]);

  useEffect(() => {
    if (loanList.length === 0) {
      fetchLoanList();
    }
  }, [loanList, fetchLoanList]);

  const handleOutsideClick = (e) => {
    // @ts-ignore
    if (paymentTypeRef.current && paymentTypeRef?.current?.contains(e.target)) {
      return;
    }
    BuildTxInfoPayload('0', activeLoan, setPaymentInfo, translate, FormatDate);
  };

  const setPaymentTypeHandler = (value: string) => {
    if (value !== paymentType) {
      setPaymentType(value);

      switch (value) {
        case PAYMENT_TYPE.DUE:
          trackEvent(AnalyticEventNames.PT_PAY_CURRENT_AMOUNT);
          trackSegmentEvent(SEGMENT_EVENT_NAMES.PATIENT_PORTAL.MAKE_PAYMENT_SELECT_AMOUNT.AMOUNT_SELECTED, {
            ...segmentEventPaymentPlanDetailsData,
            selectedOption: AmountTypes.PAY_CURRENT_BALANCE,
          });
          setAmountOfPayment(dueAmount);
          break;
        case PAYMENT_TYPE.CUSTOM:
          trackEvent(AnalyticEventNames.PT_PAY_OTHER_AMOUNT);
          trackSegmentEvent(SEGMENT_EVENT_NAMES.PATIENT_PORTAL.MAKE_PAYMENT_SELECT_AMOUNT.AMOUNT_SELECTED, {
            ...segmentEventPaymentPlanDetailsData,
            selectedOption: AmountTypes.OTHER_AMOUNT,
          });
          setAmountOfPayment(0);
          BuildTxInfoPayload('0', activeLoan, setPaymentInfo, translate, FormatDate);
          break;
        case PAYMENT_TYPE.PAYOFF:
          trackEvent(AnalyticEventNames.PT_PAY_OUTSTAND_AMOUNT);
          trackSegmentEvent(SEGMENT_EVENT_NAMES.PATIENT_PORTAL.MAKE_PAYMENT_SELECT_AMOUNT.AMOUNT_SELECTED, {
            ...segmentEventPaymentPlanDetailsData,
            selectedOption: AmountTypes.PAY_OFF_CONTRACT,
          });
          setAmountOfPayment(payoffAmount);
          break;
        default:
          break;
      }
    }
  };

  const isPaymentTypeSelectBtnActive = () => {
    return (
      (paymentType === PAYMENT_TYPE.DUE && amountOfPayment && parseFloat(amountOfPayment) !== 0) ||
      (paymentType === PAYMENT_TYPE.PAYOFF && amountOfPayment && parseFloat(amountOfPayment) !== 0) ||
      (paymentType === PAYMENT_TYPE.CUSTOM && parseFloat(amountOfPayment) !== 0 && payoffAmount >= amountOfPayment)
    );
  };

  const getPaymentTypeForSegmentEvent = () => {
    let paymentTypeForSegmentEvent = '';
    switch (paymentType) {
      case PAYMENT_TYPE.DUE:
        paymentTypeForSegmentEvent = 'next_amount';
        break;
      case PAYMENT_TYPE.PAYOFF:
        paymentTypeForSegmentEvent = 'pay_off_contract';
        break;
      case PAYMENT_TYPE.CUSTOM:
        paymentTypeForSegmentEvent = 'other_amount';
        break;
      default:
        break;
    }

    return paymentTypeForSegmentEvent;
  };

  const handlePaymentMethodAndGoNext = () => {
    trackSegmentEvent(
      SEGMENT_EVENT_NAMES.PATIENT_PORTAL.MAKE_PAYMENT_SELECT_AMOUNT.CONTINUE_CLICKED,
      segmentEventPaymentPlanDetailsData,
    );
    trackEvent(AnalyticEventNames.PT_SELECT_PM);
    trackSegmentEvent(SegmentEventNames.PAYMENT_CONFIGURED, {
      loan_id: activeLoan.id,
      paymentType: getPaymentTypeForSegmentEvent(),
      paymentAmount: amountOfPayment,
    });
    navigate(`/portal/${activeLoan.id}/payment-review`);
  };

  const goBackHandler = () => {
    trackSegmentEvent(
      SEGMENT_EVENT_NAMES.PATIENT_PORTAL.MAKE_PAYMENT_SELECT_AMOUNT.BACK_CLICKED,
      segmentEventPaymentPlanDetailsData,
    );
    setPaymentType('');
    setAmountOfPayment(0);
    trackSegmentEvent(SegmentEventNames.PAYMENT_BACK, { loan_id: activeLoan.id, screen: 'Payment Amount' });
    navigate(-1);
  };

  const onAcceptHandler = (inputValue: any, inputMask: any) => {
    trackSegmentEvent(SEGMENT_EVENT_NAMES.PATIENT_PORTAL.MAKE_PAYMENT_SELECT_AMOUNT.OTHER_AMOUNT_FILLED, {
      ...segmentEventPaymentPlanDetailsData,
      amount: inputMask.unmaskedValue,
    });

    const value = inputMask._unmaskedValue ? inputMask._unmaskedValue : '0';
    BuildTxInfoPayload(value, activeLoan, setPaymentInfo, translate, FormatDate);
    setAmountOfPayment(parseFloat(value));
  };

  if (loading) {
    return <Loading transparent={true} />;
  }

  const InputComponent = () => {
    if (paymentType === PAYMENT_TYPE.CUSTOM) {
      return (
        <>
          <StyledInput
            data-testid="amountInput"
            // @ts-ignore
            mask={'$num'}
            type="tel"
            lazy={false}
            blocks={{
              num: {
                mask: Number,
                max: 99999999,
                thousandsSeparator: ',',
                radix: '.',
              },
            }}
            onAccept={onAcceptHandler}
            overwrite={true}
            placeholder={translate('makePayment.paymentType.payOtherAmount.placeHolder')}
            // @ts-ignore
            ref={inputRef}
          />

          {!(paymentType === PAYMENT_TYPE.CUSTOM && payoffAmount >= amountOfPayment) && (
            <InputValidation>
              {translate('makePayment.paymentType.payOtherAmount.maxPossiblePayment', {
                maxPossiblePayment: payoffAmount,
              })}
            </InputValidation>
          )}
          <PaymentBreakdown showPrincipalOnly={showPrincipalOnly} paymentPage={false} loanId={activeLoan?.id} />
        </>
      );
    }
    return null;
  };

  return (
    <Container block={true}>
      <InnerContainer ref={paymentTypeRef}>
        <PaymentSelection>
          {isChargeOff && (
            <ChargeOffBannerContainer>
              <ChargeOffBanner planId={activeLoan?.plans?.[0]?.id} />
            </ChargeOffBannerContainer>
          )}

          <HeaderTitle title={translate(`makePayment.paymentType.form.info`)} />

          {!isChargeOff && (
            <PaymentTypeItem setPaymentType={setPaymentTypeHandler} paymentType={paymentType} type={PAYMENT_TYPE.DUE}>
              <RadioTextContainer>
                <span>
                  {dueType === 'next'
                    ? translate('makePayment.paymentType.form.payNextAmount.nextLabel')
                    : translate('makePayment.paymentType.form.payNextAmount.dueAmountLabel')}
                </span>
              </RadioTextContainer>
              <RadioPriceContainer>
                {FormatAmount(dueAmount)}
                {!isPaymentPastDue ? (
                  <RadioSubText>{`${translate('makePayment.paymentType.form.payNextAmount.subText')}: ${FormatDate(
                    dueDate,
                  )}`}</RadioSubText>
                ) : (
                  <label>{translate(daysPastDueText, { daysPastDue: isArray(plans)[0]?.daysPastDue })}</label>
                )}
              </RadioPriceContainer>
            </PaymentTypeItem>
          )}

          <PaymentTypeItem
            setPaymentType={setPaymentTypeHandler}
            paymentType={paymentType}
            type={PAYMENT_TYPE.CUSTOM}
            inputComponent={() => InputComponent()}
          >
            <RadioTextContainer>
              <span>{translate(`makePayment.paymentType.form.payOtherAmount.label`)}</span>
            </RadioTextContainer>
          </PaymentTypeItem>

          <PaymentTypeItem setPaymentType={setPaymentTypeHandler} paymentType={paymentType} type={PAYMENT_TYPE.PAYOFF}>
            <RadioTextContainer>
              <span>
                {isChargeOff
                  ? translate(`makePayment.paymentType.form.payOutstanding.label`)
                  : translate(`makePayment.paymentType.form.payOffLoan.label`)}
              </span>
            </RadioTextContainer>
            <RadioPriceContainer>{FormatAmount(payoffAmount)}</RadioPriceContainer>
          </PaymentTypeItem>

          {isMethodCreditCard && amountOfPayment > 0 && paymentFee > 0 && (
            <PaymentInfoContainer>
              <PaymentInfoText>{translate(`makePayment.paymentType.feeInfo.todayPayment`)}</PaymentInfoText>
              <PaymentInfoAmountText>{FormatAmount(paymentFeeTotalAmount)}</PaymentInfoAmountText>
              <PaymentInfoFeeText
                dangerouslySetInnerHTML={{
                  __html: translate(`makePayment.paymentType.feeInfo.text`, {
                    paymentAmount: FormatAmount(amountOfPayment),
                    paymentFeeAmount: FormatAmount(paymentFeeAmount),
                    paymentFee,
                  }),
                }}
              />
            </PaymentInfoContainer>
          )}

          <ButtonContainer>
            <Button type="button" m={'0px '} color={ButtonColor.secondary} onClick={goBackHandler}>
              {translate('makePayment.backButton')}
            </Button>
            <Button
              type="button"
              m={'0px'}
              disabled={!isPaymentTypeSelectBtnActive()}
              color={ButtonColor.primary}
              onClick={handlePaymentMethodAndGoNext}
            >
              {translate('makePayment.paymentType.submitButton')}
            </Button>
          </ButtonContainer>
        </PaymentSelection>
      </InnerContainer>
    </Container>
  );
};

const InnerContainer = styled.div``;

const PaymentSelection = styled.div`
  display: flex;
  flex-direction: column;
  border-bottom: 1px solid ${(props) => props.theme.main.cardBorderColor};
  padding: 24px;
  background: #ffffff;
  box-shadow: 0px 6px 9px rgba(0, 0, 0, 0.07), 0px 0.751293px 1.12694px rgba(0, 0, 0, 0.035);
  border-radius: 8px;
`;

const RadioTextContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  font-size: 16px;
  line-height: 22px;
  font-weight: normal;

  padding: 15px 10px 15px 5px;

  & > span {
    width: 100%;
  }
`;

const RadioSubText = styled.span`
  font-weight: 400;
  margin-top: 5px;
`;

const RadioPriceContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  font-size: 16px;
  line-height: 21px;
  font-weight: 700;
  padding-right: 24px;

  & > label {
    font-size: 14px;
    line-height: 19px;
    font-weight: 400;
    color: ${(props) => props.theme.main.loanColor};
  }
`;

const StyledInput = styled(IMaskInput)<{ placeholder?: string }>`
  border: 1px ${(props) => props.theme.main.cardBorderColor} solid;
  border-radius: 5px;
  padding: 1em 1em 1em 1em;
  margin: 3px 0;
  font-size: 16px;

  outline: 0;
  \ &:focus {
    // box-shadow: 0 0 0 1px ${(props) => props.theme.main.green} inset;
  }
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin-top: 8px;
  button {
    &:nth-child(1) {
      margin-right: 15px;
    }
  }
  @media (max-width: ${(props) => props.theme.size.tablet}) {
    flex-direction: column-reverse;
    button {
      &:nth-child(1) {
        margin-right: 0px;
      }
      &:nth-child(2) {
        margin-bottom: 15px;
      }
    }
  }
`;

const InputValidation = styled.div`
  color: ${(props) => props.theme.main.red};
  margin: 12px 0;
`;

const PaymentInfoContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  background: linear-gradient(90deg, #ffffff 0%, #ffffff 100%);
  border-radius: 8px 8px 0 0;
  padding: 24px 16px;
  margin-bottom: 20px;
  text-align: center;
  -webkit-filter: drop-shadow(3px 3px 1px black);
  filter: drop-shadow(0px 0px 5px #e1e1e1);

  &::after {
    position: absolute;
    bottom: -20px;
    left: 0px;
    content: '';
    display: block;
    background: radial-gradient(circle, #ffffff00 20px, #ffffff 20px);
    background-size: 35px 40px;
    background-position: -20px -20px;
    width: 100%;
    height: 40px;
    z-index: -1;
  }
`;

const PaymentInfoText = styled.span`
  font-weight: 400;
  font-size: 10px;
  line-height: 14px;
  color: #0e202f;
`;
const PaymentInfoAmountText = styled.span`
  font-weight: 700;
  font-size: 24px;
  line-height: 33px;
  color: #00c37d;
`;
const PaymentInfoFeeText = styled.span`
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
  color: #0e202f;
`;

const ChargeOffBannerContainer = styled.div`
  margin-bottom: 24px;
`;

export default PaymentType;
