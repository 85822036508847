import { useEffect, useState } from 'react';
import { useAlert } from 'react-alert';
import styled from 'styled-components';

import { API_URL } from 'config';
import { CustomLoading } from 'lib/components';
import { useSentry } from 'lib/hooks/useSentry';
import { useTranslation } from 'react-i18next';

interface ThreeDFrameProps {
  onConnect: () => void;
  paymentId: string;
  handleClose: (boolean) => void;
}

export const ThreeDFrame = ({ paymentId, onConnect, handleClose }: ThreeDFrameProps) => {
  const alert = useAlert();
  const [loading, setLoading] = useState(true);
  const { captureException } = useSentry();
  const { t: translate } = useTranslation();

  useEffect(() => {
    window.addEventListener('message', receiveMessage, false);
    return () => {
      window.removeEventListener('message', receiveMessage);
    };
  }, []);

  const receiveMessage = ({ origin, data }) => {
    try {
      if (origin === API_URL) {
        const iFrameEvent = JSON.parse(data);
        switch (iFrameEvent?.status) {
          case 'expired':
          case 'error':
            setTimeout(() => {
              handleClose(false);
              alert.error(iFrameEvent?.message);
            }, 3000);
            break;
          case 'failed':
            setTimeout(() => {
              handleClose(false);
              alert.error(translate('makePayment.paymentReview.threeDFrame.error'));
            }, 3000);
            break;
          case 'success':
            setTimeout(() => {
              handleClose(false);
              onConnect();
            }, 3000);
            break;
          case 'loading':
            setLoading(true);
            break;
        }
      }
    } catch (err) {
      alert.error(translate('error.default'));
      setLoading(false);
      captureException(err, { errorContext: 'receiveMessage => PaymentMethodConnect', data });
    }
  };

  const onIframeLoadHandler = () => {
    setTimeout(() => {
      setLoading(false);
    }, 500);
  };

  if (!paymentId) {
    captureException({}, { errorContext: 'borrowerId Not Found => PaymentMethodConnect' });
    return (
      <ErrorContainer>
        <CustomLoading />
      </ErrorContainer>
    );
  }

  return (
    <Container>
      {paymentId && (
        <iframe
          id="3d-secure-method"
          data-testid="the-frame"
          title={'3ds Security'}
          width="100%"
          height="100%"
          frameBorder="0"
          src={`${API_URL}/v1/payments/${paymentId}/three-d/iframe`}
          onLoad={onIframeLoadHandler}
        />
      )}
      {loading && (
        <LoadingContainer>
          <CustomLoading />
        </LoadingContainer>
      )}
    </Container>
  );
};

const Container = styled.div`
  position: relative;
  height: 100%;
  width: 100%;
  margin-top: 5px;
`;

const LoadingContainer = styled.div`
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 20px;
  left: 0;
  right: 0;
`;

const ErrorContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 48px;
`;
