import { useLazyQuery, useMutation } from '@apollo/client';
import { useAlert } from 'react-alert';
import { useTranslation } from 'react-i18next';

import { VERIFY_OTP_REQUEST, REQUEST_OTP } from 'lib/graphql/mutations';
import { GET_BORROWER_DETAIL } from 'lib/graphql/queries';
import useStore from 'lib/hooks/useStore';

export enum OtpReceiveChoiceEnum {
  SMS = 'SMS',
  VOICE = 'VOICE',
}

const getRawPhoneNumber = (phone) => {
  if (phone) {
    return phone?.replace(/[^0-9]/gi, '');
  }
  return '';
};

export const useLogin = () => {
  const alert = useAlert();
  const { t } = useTranslation();
  const { phoneNumber } = useStore();

  return useMutation(REQUEST_OTP, {
    variables: {
      input: { phone: getRawPhoneNumber(phoneNumber) },
    },
    fetchPolicy: 'network-only',
    onCompleted: (data) => {
      if (!!data?.requestOtp?.success) {
        alert.success(t(`token.form.phone.success`));
      } else if (data?.requestOtp?.message === 'borrower.not_found') {
        alert.error(t(`error.borrower.not.found`));
      } else {
        alert.error(t(`error.default`));
      }
    },
    onError: (error) => {
      alert.error(t(`error.${error}`));
    },
  });
};

export const useOtp = () => {
  const alert = useAlert();
  const { t } = useTranslation();
  const { phoneNumber, otpNumber } = useStore();

  return useMutation(VERIFY_OTP_REQUEST, {
    variables: {
      input: { phone: getRawPhoneNumber(phoneNumber), code: otpNumber },
    },
    fetchPolicy: 'network-only',
    onError: (error) => {
      alert.error(t(`error.${error}`));
    },
  });
};

export const useVoiceCall = () => {
  const alert = useAlert();
  const { t } = useTranslation();
  const { phoneNumber } = useStore();

  return useMutation(REQUEST_OTP, {
    variables: {
      input: { phone: getRawPhoneNumber(phoneNumber), otpChannel: OtpReceiveChoiceEnum.VOICE },
    },
    fetchPolicy: 'network-only',
    onCompleted: (data) => {
      if (data?.requestOtp?.success) {
        alert.success(t(`token.form.phone.successCall`));
      } else {
        alert.error(t(`error.voice.error`));
      }
    },
    onError: (error) => alert.error(t(`error.${error}`)),
  });
};

export const useGetBorrowerDetail = () => {
  const alert = useAlert();
  const { t } = useTranslation();
  const { borrower, signOut } = useStore();

  return useLazyQuery(GET_BORROWER_DETAIL, {
    variables: {
      input: { id: borrower?.id?.toString() },
    },
    fetchPolicy: 'network-only',
    onError: (error) => {
      alert.error(t(`error.${error}`));
      signOut();
    },
  });
};
