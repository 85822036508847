import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { DesktopDatePicker as MuiDesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import dayjs from 'dayjs';
import DateInput from 'pages/ExtendApproval/components/DateInput';
import { useTranslation } from 'react-i18next';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
interface DesktopDatePickerProps {
  onDateChange: (value) => void;
  value: string;
  minDate?: any;
  maxDate?: any;
}

export const DesktopDatePicker = ({
  onDateChange,
  value,
  minDate = dayjs(new Date()),
  maxDate,
}: DesktopDatePickerProps) => {
  const { i18n } = useTranslation();
  const [dateValue, setDateValue] = useState(value);
  const [isDatePickerOpened, setIsDatePickerOpened] = useState(false);

  useEffect(() => {
    if (dateValue !== value) {
      setDateValue(dayjs(value).format('YYYY-MM-DD'));
    }
  }, [value]);

  const toggleDatePicker = () => {
    setIsDatePickerOpened(!isDatePickerOpened);
  };

  const closeDatePicker = () => {
    setIsDatePickerOpened(false);
  };

  return (
    <DatePickerWrapper>
      <LocalizationProvider locale={i18n.language} dateAdapter={AdapterDayjs}>
        <MuiDesktopDatePicker
          PopperProps={{ disablePortal: true }}
          value={dateValue}
          minDate={minDate}
          maxDate={maxDate}
          open={isDatePickerOpened}
          onChange={onDateChange}
          onClose={closeDatePicker}
          renderInput={({ inputRef, inputProps, InputProps }) => (
            <DateInput
              inputRef={inputRef}
              inputProps={inputProps}
              InputProps={InputProps}
              datePickerOpen={toggleDatePicker}
              noPadding={true}
            />
          )}
        />
      </LocalizationProvider>
    </DatePickerWrapper>
  );
};

const DatePickerWrapper = styled.div`
  width: 100%;

  .MuiPaper-root {
    height: 380px !important;

    .PrivatePickersFadeTransitionGroup-root {
      .PrivatePickersSlideTransition-root {
        div[role='grid'] {
          height: 380px !important;
        }
      }
    }

    div[role='grid'] {
      div[role='row'] {
        margin: 0;
      }

      div[role='row']:first-of-type {
        padding-left: 2px;
      }

      div[role='row']:last-of-type {
        padding-right: 2px;
      }
    }

    .MuiTypography-caption {
      margin: 0 3.5px;
      font-size: 12px;
      font-weight: 600;
    }

    .MuiCalendarPicker-root {
      border-bottom-left-radius: 10px;
      border-bottom-right-radius: 10px;
      overflow: hidden;
    }

    .MuiDayPicker-header {
      span {
        width: 39px;
      }
    }

    .MuiPickersDay-root {
      opacity: 1;
      border-radius: 0;
      width: 41.5px;
      height: 42.5px;
      border: 0.5px solid #dfdfdf;
    }

    .MuiButtonBase-root {
      width: 43px;
      height: 44px;
    }

    .Mui-selected {
      background-color: ${(props) => props.theme.main.green} !important;
      color: ${(props) => props.theme.main.white} !important;
    }

    .MuiPickersDay-root,
    .MuiDayPicker-weekContainer,
    .MuiPickersDay-dayWithMargin {
      margin: 0;
      padding: 0;
    }

    .MuiPickersDay-today {
      border: 0.5px solid #dfdfdf !important;
    }
  }

  input {
    border-radius: 4px;
    border: 1px solid ${(props) => props.theme.main.neutralGray};
    background: ${(props) => props.theme.main.white};
    color: ${(props) => props.theme.main.primary};
    font-family: 'Open Sans';
    font-size: 16px;
    font-weight: 400;
    padding: 16px;
  }

  .MuiButtonBase-root {
    margin-bottom: 20px;
  }
`;
