import dayjs from 'dayjs';
import { useEffect, useState } from 'react';
import { useAlert } from 'react-alert';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

import { Button, ButtonColor } from 'lib/components';
import { usePatchExtendApprovalHistory } from 'lib/hooks/useExtendApprovals';
import { ButtonContainer, HeaderText } from 'pages/ExtendApproval/style';
import { Approval, ScreenState } from 'pages/ExtendApproval/type';

import { RadioSelection } from './RadioSelection';
import { Body } from '@frontend/cherry-library';
import { useSegment } from 'lib/hooks/useSegment';
import { SEGMENT_EVENT_NAMES } from 'lib/constans';
import { getApplicationDetailData } from 'lib/utils/ApplicationDetailSegmentEventData';
import useStore from 'lib/hooks/useStore';

interface Props {
  approval: Approval;
  setScreenState: (value: ScreenState) => void;
  extendedApprovalId: string;
}

type RadioSelectionItem = {
  id: string;
  label: string;
  value: string;
  eventName:
    | typeof SEGMENT_EVENT_NAMES.PATIENT_PORTAL.REQUEST_MORE_TIME.BEYOND_60_DAYS_REMINDER_YES_SELECT
    | typeof SEGMENT_EVENT_NAMES.PATIENT_PORTAL.REQUEST_MORE_TIME.BEYOND_60_DAYS_REMINDER_NO_SELECT;
};

export const ApprovalAppointment = ({ setScreenState, approval, extendedApprovalId }: Props) => {
  const { t: translate } = useTranslation();
  const { borrower } = useStore();
  const alert = useAlert();
  const navigate = useNavigate();
  const [selectedReason, setSelectedReason] = useState('');
  const { trackPage, standardSegmentMetadata, trackSegmentEvent } = useSegment();
  const applicationSegmentData = getApplicationDetailData(borrower, approval);
  const pageSegmentEventData = { ...standardSegmentMetadata, ...applicationSegmentData };

  useEffect(() => {
    trackPage(SEGMENT_EVENT_NAMES.PATIENT_PORTAL.REQUEST_MORE_TIME.BEYOND_60_DAYS_PAGE_LOAD, pageSegmentEventData);
  }, []);

  const { id, expireAt } = approval;
  const extendDate = dayjs(expireAt).add(30, 'day').format('MMM DD, YYYY');

  const [patchExtendApprovalHistory, { data, loading }] = usePatchExtendApprovalHistory();

  const SelectionData: RadioSelectionItem[] = [
    {
      id: '1',
      label: translate('general.no'),
      value: '1',
      eventName: SEGMENT_EVENT_NAMES.PATIENT_PORTAL.REQUEST_MORE_TIME.BEYOND_60_DAYS_REMINDER_NO_SELECT,
    },
    {
      id: '2',
      label: translate('general.yes'),
      value: '2',
      eventName: SEGMENT_EVENT_NAMES.PATIENT_PORTAL.REQUEST_MORE_TIME.BEYOND_60_DAYS_REMINDER_YES_SELECT,
    },
  ];

  useEffect(() => {
    if (data?.patchExtendApprovalHistory?.id) {
      setScreenState(ScreenState.REMINDER);
    } else {
      alert.error(data?.patchExtendApprovalHistory.message);
    }
  }, [data]);

  const goBackHandler = () => {
    setScreenState(ScreenState.SELECTION);
    navigate('/portal/approvals');
  };

  const reminderHandler = () => {
    patchExtendApprovalHistory({
      variables: { input: { applicationId: id, approvalExtensionHistoryId: extendedApprovalId, reminder: true } },
    });
  };

  const continueButtonHandler = () => {
    trackSegmentEvent(
      SEGMENT_EVENT_NAMES.PATIENT_PORTAL.REQUEST_MORE_TIME.BEYOND_60_DAYS_REMINDER_CONTINUE_CLICKED,
      pageSegmentEventData,
    );
    if (selectedReason === '1') {
      goBackHandler();
    } else {
      reminderHandler();
    }
  };

  const onRadioClick = (selection: RadioSelectionItem) => {
    trackSegmentEvent(selection.eventName, pageSegmentEventData);
  };

  return (
    <>
      <Body fontWeight={700} size="large">
        {translate('extend.approvals.approval.appointment.title', { appointmentDate: extendDate })}
      </Body>
      <HeaderText>{translate('extend.approvals.approval.appointment.subtitle1')}</HeaderText>
      <HeaderText>{translate('extend.approvals.approval.appointment.subtitle2')}</HeaderText>
      <ContentContainer>
        {SelectionData.map((selection) => (
          <RadioContainer key={selection.id}>
            <RadioSelection
              setSelection={setSelectedReason}
              label={selection.label}
              value={selection.value}
              selection={selection}
              selected={selectedReason}
              onClick={onRadioClick}
            />
          </RadioContainer>
        ))}
      </ContentContainer>
      <ButtonContainer>
        <Button
          color={ButtonColor.primary}
          onClick={continueButtonHandler}
          disabled={!selectedReason || loading}
          loading={loading}
        >
          {translate('makePayment.paymentType.submitButton')}
        </Button>
      </ButtonContainer>
    </>
  );
};

const ContentContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 24px;
  div:first-child {
    margin-right: 8px;
  }
`;

const RadioContainer = styled.div`
  display: flex;
  flex: 1;
`;
