import { useEffect, useState } from 'react';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import styled from 'styled-components';
import { useCalculatePayoff } from 'lib/hooks/useCalculatePayoff';
import { useTranslation } from 'react-i18next';
import { useAlert } from 'react-alert';
import { Body, DatePicker, theme, Button, Heading } from '@frontend/cherry-library';
import { useParams, useNavigate } from 'react-router-dom';
import useStore from 'lib/hooks/useStore';
import { Container } from 'lib/components';
import { useSegment } from 'lib/hooks/useSegment';
import { SEGMENT_EVENT_NAMES } from 'lib/constans';
import { getSegmentEventDetailsData } from 'lib/utils/SegmentEventData';

export const CalculatePayoff = () => {
  const { loanList, setHeaderTitle } = useStore();

  const { id: loanId } = useParams<{ id: string }>();

  const selectedLoan = loanList.find((loan) => loan.id === loanId);

  const futureSchedules = selectedLoan?.schedules?.filter(
    (schedule) => schedule.status !== 'PAID' && schedule?.status !== 'PROCESSING',
  );

  const { trackPage, trackSegmentEvent } = useSegment();

  const sortedFutureSchedules = futureSchedules?.slice().sort((first, second) => {
    const dateFirst = new Date(first.dueAt).getTime();
    const dateSecond = new Date(second.dueAt).getTime();
    return dateSecond - dateFirst;
  });

  const nextDueDate = sortedFutureSchedules?.[0]?.dueAt;

  const { t: translate } = useTranslation();
  const alert = useAlert();
  const navigate = useNavigate();
  const [calculatePayoffEstimation, { loading }] = useCalculatePayoff();
  const { borrower } = useStore();
  const [dateValue, setDateValue] = useState<string | null>(dayjs().format('YYYY-MM-DD'));
  const [calculated, setCalculated] = useState(false);
  const segmentEventData = getSegmentEventDetailsData(borrower, selectedLoan);

  useEffect(() => {
    setHeaderTitle('calculatePayoff.name');
  }, [setHeaderTitle]);

  useEffect(() => {
    trackPage(SEGMENT_EVENT_NAMES.PATIENT_PORTAL.CALCULATE_PAYOFF.PAGE_LOAD, segmentEventData);
  }, []);

  const handleDateChange = (value) => {
    trackSegmentEvent(SEGMENT_EVENT_NAMES.PATIENT_PORTAL.CALCULATE_PAYOFF.DATE_SELECTED, {
      ...segmentEventData,
      date: dayjs(value).format('YYYY-MM-DD'),
    });
    setDateValue(value ? dayjs(value).format('YYYY-MM-DD') : null);
  };

  const goToDocuments = () => {
    trackSegmentEvent(
      SEGMENT_EVENT_NAMES.PATIENT_PORTAL.CALCULATE_PAYOFF_SUCCESS.VIEW_DOCUMENT_CLICKED,
      segmentEventData,
    );
    navigate(`/portal/documents`);
  };
  const goBack = (id: number) => {
    trackSegmentEvent(
      id === 1
        ? SEGMENT_EVENT_NAMES.PATIENT_PORTAL.CALCULATE_PAYOFF_SUCCESS.MAYBE_LATER_CLICKED
        : SEGMENT_EVENT_NAMES.PATIENT_PORTAL.CALCULATE_PAYOFF.BACK_CLICKED,
      segmentEventData,
    );
    window.history.back();
  };

  const handlePayoffEstimation = async () => {
    trackSegmentEvent(SEGMENT_EVENT_NAMES.PATIENT_PORTAL.CALCULATE_PAYOFF.CONTINUE_CLICKED, segmentEventData);
    calculatePayoffEstimation({
      variables: {
        input: {
          loanId,
          payOffDate: dateValue?.toString(),
        },
      },
      onError: (error) => {
        alert.error(translate(`error.${error}`));
      },
    });
    setCalculated(true);
    trackSegmentEvent(SEGMENT_EVENT_NAMES.PATIENT_PORTAL.CALCULATE_PAYOFF_SUCCESS.PAGE_LOAD, segmentEventData);
  };

  return (
    <Container block={true}>
      <InnerContainer>
        {calculated && !loading ? (
          <>
            <Heading level="4" text={translate('calculatePayoff.estimateGenerated')}></Heading>
            <Body fontWeight={400}>{translate('calculatePayoff.estimateAvailableInDocuments')}</Body>
            <SubmitButtonContainer>
              <Button fullWidth onClick={goToDocuments}>
                {translate('calculatePayoff.viewPayoffEstimate')}
              </Button>
              <Button onClick={() => goBack(1)} variant="secondary" fullWidth>
                {translate('calculatePayoff.maybeLater')}
              </Button>
            </SubmitButtonContainer>
          </>
        ) : (
          <>
            <CalendarWrapper>
              <DialogSubtitle> {translate('calculatePayoff.description')}</DialogSubtitle>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <FlexContainer>
                  <DatePicker
                    minDate={dayjs()}
                    maxDate={dayjs(nextDueDate)}
                    disabled={loading}
                    label={translate('files.tableHead.date')}
                    showToolbar={false}
                    onChange={handleDateChange}
                  />
                </FlexContainer>
              </LocalizationProvider>
            </CalendarWrapper>
            <SubmitButtonContainer>
              <Button fullWidth onClick={handlePayoffEstimation} disabled={loading}>
                {translate('genericButton.continue')}
              </Button>
              <Button onClick={() => goBack(2)} variant="secondary" fullWidth>
                {translate('genericButton.back')}
              </Button>
            </SubmitButtonContainer>
          </>
        )}
      </InnerContainer>
    </Container>
  );
};

const InnerContainer = styled.div`
  display: flex;
  padding: 16px;
  flex-direction: column;
  border-radius: 8px;
  background: ${theme.main.white};
  padding: 16px;

  box-shadow: 0px 6px 9px 0px rgba(0, 0, 0, 0.07), 0px 0.751px 1.127px 0px rgba(0, 0, 0, 0.04);
`;

const DialogSubtitle = styled(Body)`
  margin-bottom: 8px;
`;

const SubmitButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin-top: 24px;
`;

const CalendarWrapper = styled.div``;

const FlexContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 24px !important;
  .MuiFormControl-root {
    width: 570px;
  }
  @media (max-width: ${(props) => props.theme.size.tablet}) {
    .MuiFormControl-root {
      width: auto !important;
    }
  }
`;
