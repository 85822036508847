import { Body, Heading } from '@frontend/cherry-library';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { ReactComponent as TrashIcon } from 'assets/images/trash.svg';
import { ReactComponent as DebitCardIcon } from 'assets/images/bank.svg';
import { ReactComponent as CreditCardIcon } from 'assets/images/credit-card.svg';

interface Props {
  data: any;
  paymentMethodLength?: number;
  readOnly?: boolean;
  deleteCard?: (value: string, id: number) => void;
}

export const PaymentItem = ({ data, paymentMethodLength = 0, readOnly = false, deleteCard }: Props) => {
  const { t: translate } = useTranslation();
  const { storedCard, achAccount, rccAccount, activeLoanCount, type } = data || {};

  const accountKey = type === 'ACH' ? achAccount : rccAccount;

  const onClickHandler = () => {
    const paymentMethodInfo = `${data?.type === 'CARD' ? 'Debit Card' : 'Bank Account'} ending in ${
      data?.type === 'CARD'
        ? storedCard?.last4
        : accountKey?.accountNumber.substring(accountKey?.accountNumber.length - 4)
    }`;
    deleteCard && deleteCard(paymentMethodInfo, data?.id);
  };

  const cardInfo = translate('account.payment.paymentItem.cardTitle', {
    last4: storedCard?.last4,
    expireMonth: storedCard?.expireMonth,
    expireYear: storedCard?.expireYear,
  });

  let cardTitleText = storedCard?.network || '';
  if (storedCard?.network !== 'AMEX') {
    cardTitleText += ` - ${storedCard?.type}`;
  }

  if (data?.type === 'CARD') {
    return (
      <Container data-testid="cardPaymentItem">
        <InfoContainer>
          {data?.type === 'CARD' ? <CreditCardLogo height={24} width={24} /> : <DebitCardLogo height={24} width={24} />}
          <CardInfoContainer>
            <CardTitle text={cardTitleText} level="5" />
            <CartDescription
              dangerouslySetInnerHTML={{
                __html: cardInfo?.replace('-', '<br />'),
              }}
              fontWeight={400}
            />
            {activeLoanCount > 0 && (
              <AutopayText fontWeight={400} size="small">
                {translate('account.payment.paymentItem.cardDescription', {
                  activeLoanCount,
                  plural: activeLoanCount > 1 ? 's' : '',
                })}
              </AutopayText>
            )}
          </CardInfoContainer>
        </InfoContainer>
        {!activeLoanCount && !readOnly && paymentMethodLength > 1 && (
          <ActionContainer data-testid="deleteIcon">
            <DeleteLogo onClick={onClickHandler} height={24} width={24} />
          </ActionContainer>
        )}
      </Container>
    );
  }

  return (
    <Container data-testid="achPaymentItem">
      <InfoContainer>
        <DebitCardLogo height={24} width={24} />
        <CardInfoContainer>
          <CardTitle text={accountKey?.bankName} level="5" />
          <CartDescription fontWeight={400}>
            {translate('account.payment.paymentItem.achTitle', {
              last4: accountKey?.accountNumber?.substring(accountKey?.accountNumber?.length - 4),
            })}
          </CartDescription>
          {activeLoanCount > 0 && (
            <AutopayText fontWeight={400} size="small">
              {translate('account.payment.paymentItem.cardDescription', {
                activeLoanCount,
                plural: activeLoanCount > 1 ? 's' : '',
              })}
            </AutopayText>
          )}
        </CardInfoContainer>
      </InfoContainer>
      {!activeLoanCount && !readOnly && paymentMethodLength > 1 && (
        <ActionContainer data-testid="deleteIcon">
          <DeleteLogo onClick={onClickHandler} height={24} width={24} />
        </ActionContainer>
      )}
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: 16px;
`;

const InfoContainer = styled.div`
  display: flex;
  align-items: flex-start;
`;
const CardInfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

const DebitCardLogo = styled(DebitCardIcon)`
  margin-right: 16px;
`;

const CreditCardLogo = styled(CreditCardIcon)`
  margin-right: 16px;
`;

const CardTitle = styled(Heading)`
  margin: 0px;
`;

const CartDescription = styled(Body)`
  font-size: 14px !important;
  line-height: 19px;
  text-align: left;
`;

const AutopayText = styled(Body)`
  font-style: italic !important;
`;

const ActionContainer = styled.div``;

const DeleteLogo = styled(TrashIcon)`
  cursor: pointer;
`;
