import React, { useCallback, useEffect, useState } from 'react';

type LocalPaginationProps = {
  list: any[];
  itemCountPerPage: number;
};

type LocalPaginationReturnType = {
  viewList: any[];
  currentPage: number;
  showMore: () => void;
  resetView: () => void;
};

export const useLocalPagination = ({ list, itemCountPerPage }: LocalPaginationProps): LocalPaginationReturnType => {
  const [viewList, setViewList] = useState<any[]>([]);
  const [chunkedList, setChunkedList] = useState<any[]>();
  const [currentPage, setCurrentPage] = useState<number>(1);

  const chunkList = () => {
    const result: any[] = [];

    for (let i = 0; i < list.length; i += itemCountPerPage) {
      result.push(list.slice(i, i + itemCountPerPage));
    }

    setChunkedList(result);
  };

  const handleViewList = () => {
    const result: any[] = [];

    if (chunkedList?.length) {
      for (let i = 0; i < currentPage; i++) {
        result.push(...chunkedList[i]);
      }
    }

    setViewList(result);
  };

  useEffect(() => {
    chunkList();
  }, [list, itemCountPerPage]);

  useEffect(() => {
    handleViewList();
  }, [chunkedList, currentPage]);

  const showMore = () => {
    if (chunkedList?.length && currentPage < chunkedList.length + 1) {
      setCurrentPage(currentPage + 1);
    }
  };

  const resetView = () => {
    setCurrentPage(1);
  };

  return { viewList, showMore, currentPage, resetView };
};
