import React, { useEffect, useState } from 'react';
import { GET_MEMBERSHIPS_PLAN_DETAIL } from 'lib/graphql/queries/Memberships';
import { useApolloClient } from '@apollo/client';
import useStore, { MembershipPlans } from 'lib/hooks/useStore';
import { Button, ButtonColor, Card, Container, FaqListModal, Loading } from 'lib/components';
import { PlanCard } from '../Memberships/components/PlanCard';
import { useNavigate, useParams } from 'react-router-dom';
import styled from 'styled-components';
import { FormatAmount } from 'lib/utils';
import dayjs from 'dayjs';
import { theme } from 'config/theme';
import { useTranslation } from 'react-i18next';

const MembershipsDetail = () => {
  const client = useApolloClient();
  const { id } = useParams();
  const { setHeaderTitle } = useStore();
  const navigate = useNavigate();
  const { t: translate } = useTranslation();

  const [membershipPlan, setMembershipPlan] = useState<MembershipPlans>();
  const [isFaqVisible, setIsFaqVisible] = useState(false);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setHeaderTitle('menu.listItems.membershipDetail');
  }, [setHeaderTitle]);

  useEffect(() => {
    client
      .query({
        query: GET_MEMBERSHIPS_PLAN_DETAIL,
        variables: {
          input: {
            planId: id,
          },
        },
      })
      .then((res) => {
        setMembershipPlan(res?.data?.fetchCustomerMembershipsPlanDetail);
        setLoading(false);
      })
      .catch(() => setLoading(false));
  }, []);

  const getPlanPeriod = (period, paymentFrequency) => {
    switch (period) {
      case 'MONTHLY':
        return translate('membershipsDetail.month');
      case 'YEARLY':
        return translate('membershipsDetail.year');
      case 'MONTHLY_FLEX':
        return translate('membershipsDetail.monthlyFlex');
      case 'CUSTOM_WEEKLY':
        return translate('membershipsDetail.customWeek', { paymentFrequency });
      default:
        return '';
    }
  };

  const getPlanType = (type) => {
    switch (type) {
      case 'INCLUDED_SERVICE':
        return translate('membershipsDetail.includedService');
      case 'DISCOUNT':
        return translate('membershipsDetail.discount');
      case 'GIFT':
        return translate('membershipsDetail.gift');
      case 'BONUS':
        return translate('membershipsDetail.bonus');
      default:
        return '';
    }
  };

  const toggleFaq = () => {
    setIsFaqVisible((prev) => !prev);
  };

  const navigateToUpdatePaymentPage = () => {
    navigate(
      `/portal/memberships/${membershipPlan?.id}/update-payment-method?last4=${membershipPlan?.paymentMethod?.last4}`,
    );
  };

  const renderRenewMonth = () => {
    switch (membershipPlan?.period) {
      case 'MONTHLY':
        return '';
      case 'YEARLY':
        return '12';
      case 'MONTHLY_FLEX':
        return membershipPlan?.billingTerm;
      case 'CUSTOM_WEEKLY':
        return membershipPlan?.paymentFrequency;
      default:
        break;
    }
  };

  if (loading) {
    return <Loading transparent={true} />;
  }

  return (
    <>
      <Container block={true}>
        <PlanName>{membershipPlan?.plan?.name}</PlanName>
        <Price>
          {FormatAmount(membershipPlan?.price || 0)} /{' '}
          {getPlanPeriod(membershipPlan?.period, membershipPlan?.paymentFrequency)}
        </Price>
        <PlanCard plan={membershipPlan} isDetailPage={true} />
        <LinkButton onClick={navigateToUpdatePaymentPage}>
          {translate('membershipsDetail.updatePaymentMethod')}
        </LinkButton>
        <PlanDescription> {translate('membershipsDetail.planSummary')}</PlanDescription>
        <Card>
          <p>{membershipPlan?.plan?.description}</p>
        </Card>
        <PlanDescription>
          Auto-Renewal:{' '}
          <PlanDescriptionColor active={membershipPlan?.autoRenew}>
            {membershipPlan?.autoRenew ? 'On' : 'Off'}
          </PlanDescriptionColor>
        </PlanDescription>
        <Card>
          {membershipPlan?.autoRenew
            ? translate('membershipsDetail.autoRenews', {
                renewmounth: renderRenewMonth(),
                period: membershipPlan?.period === 'CUSTOM_WEEKLY' ? 'weeks' : 'months',
              })
            : translate('membershipsDetail.notAutoRenews')}
        </Card>
        <PlanDescription>{translate('membershipsDetail.benefits')}</PlanDescription>
        {membershipPlan?.customerBenefits?.map((benefit, index) => {
          return (
            <Card
              key={index}
              style={{
                backgroundColor: !(benefit?.quantity - benefit?.usage) ? '#dadada' : theme.main.white,
              }}
            >
              <BenefitTitle>{benefit?.name}</BenefitTitle>
              <Benefit>{translate('membershipsDetail.type', { type: getPlanType(benefit?.type) })}</Benefit>
              <Benefit>{translate('membershipsDetail.amount', { quantity: benefit?.quantity })}</Benefit>
              <Benefit>{translate('membershipsDetail.frequency', { frequency: benefit?.frequency })}</Benefit>
              <Benefit>{translate('membershipsDetail.usage', { usage: benefit?.usage })}</Benefit>
              {benefit?.frequency !== 'UNLIMITED' && (
                <Benefit>
                  {translate('membershipsDetail.remaining', { remaining: benefit?.quantity - benefit?.usage })}
                </Benefit>
              )}
              <Benefit>
                {translate('membershipsDetail.lastUsed', { date: dayjs(benefit?.updatedAt).format('MM/DD/YYYY') })}
              </Benefit>
            </Card>
          );
        })}
        <Button color={ButtonColor.secondary} onClick={toggleFaq}>
          {translate('membershipsDetail.askedQuestion')}
        </Button>
        <ThanksText>
          {translate('membershipsDetail.thanks', { organizationName: membershipPlan?.organization?.name })}
        </ThanksText>
      </Container>
      <FaqListModal
        hideModal={toggleFaq}
        show={isFaqVisible}
        title={translate('common.haveQuestion')}
        titleMargin={'auto'}
        modalHeight={'80%'}
        modalWidth={'550px'}
        modalTop={'50px'}
        scrollEnabled={true}
      />
    </>
  );
};

export default MembershipsDetail;

const Price = styled.span`
  font-size: 24px;
  line-height: 32px;
  display: block;
  margin-bottom: 16px;
`;
const PlanName = styled(Price)`
  font-weight: 700;
`;

const PlanDescription = styled.span`
  display: flex !important;
  font-size: 16px;
  line-height: 22px;
  font-weight: 700;
  margin-bottom: 16px;
  display: block;
`;

const PlanDescriptionColor = styled(PlanDescription)<{ active?: boolean }>`
  color: ${(props) => (props.active ? theme.main.green : theme.main.red)};
  margin-left: 5px;
  margin-bottom: 0px;
`;

const BenefitTitle = styled.span`
  font-size: 16px;
  margin-bottom: 12px;
  font-weight: 700;
`;

const Benefit = styled.span`
  display: block;
  font-size: 16px;
  line-height: 22px;
`;

interface FaqContainerProps {
  visible: boolean;
}

const FaqContainer = styled.div<FaqContainerProps>`
  position: fixed;
  bottom: 0px;
  overflow: auto;
  height: 60vh;
  box-shadow: 0px -18px 20px 0px rgba(0, 0, 0, 0.2);
  display: ${(props) => (props.visible ? 'block' : 'none')};
`;

const ThanksText = styled.p`
  font-family: Open Sans;
  font-size: 20px;
  font-weight: 600;
  line-height: 26px;
  letter-spacing: 0em;
  text-align: center;
  margin-top: 16px;
  margin-bottom: 16px;
  color: ${(props) => props.theme.main.green};
`;

const LinkButton = styled.a`
  text-align: center;
  color: ${(props) => props.theme.main.green};
  display: block;
  margin-bottom: 16px;
  cursor: pointer;
`;
