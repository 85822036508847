import Fade from '@mui/material/Fade';
import { useEffect, useState } from 'react';
import { useAlert } from 'react-alert';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { Box, Loading, Button as LibButton, ButtonColor } from 'lib/components';
import { PAYMENT_TYPES, paymentMethodTypes, SEGMENT_EVENT_NAMES } from 'lib/constans';
import { paymentSearch } from 'lib/graphql/searches';
import { AnalyticEventNames, useAnalytics } from 'lib/hooks/useAnalytics';
import { useGetPaymentMethods } from 'lib/hooks/usePayment';
import { SortByDate } from 'lib/utils';

import { useSegment } from 'lib/hooks/useSegment';
import { useSentry } from 'lib/hooks/useSentry';
import { BankConnect, PaymentMethodConnect } from '.';
import { API_URL, APP_URL } from '../../../../config';
import useGuestMode from 'lib/hooks/useGuestMode';
import { Body, Chip, Icon, Button } from '@frontend/cherry-library';
import { ReactComponent as BankIcon } from 'assets/images/bank.svg';
import { ReactComponent as CardIcon } from 'assets/images/credit-card.svg';
import { ReactComponent as LockIcon } from 'assets/images/lock-secure.svg';
import { getSegmentEventDetailsData } from 'lib/utils/SegmentEventData';
import useStore from 'lib/hooks/useStore';
import { useParams } from 'react-router-dom';

enum PaymentMethodsForTracking {
  CARD = 'CARD',
  ACCOUNT = 'ACCOUNT',
}
interface Props {
  addDirectly?: boolean;
}

export const AddNewPaymentMethod = ({ handleClose, addPaymentMethod, connectBankAccount, borrower, addDirectly }) => {
  const alert = useAlert();
  const { t: translate } = useTranslation();
  const { trackEvent } = useAnalytics();
  const { captureException } = useSentry();
  const [getPaymentMethods, { data: paymentData }] = useGetPaymentMethods();
  const { trackSegmentEvent, trackPage } = useSegment();
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState('ACH');
  const [plaidUrl, setPlaidUrl] = useState('');
  const [plaidLoading, setPlaidLoading] = useState(false);
  const [loading, setLoading] = useState(false);
  const [addedPaymentType, setAddedPaymentType] = useState('card');
  const [newlyAddedCardId, setNewlyAddedCardId] = useState('');
  const closeConnectAcount = () => setPlaidUrl('');
  const { isGuestMode } = useGuestMode();
  const { getActiveLoan } = useStore();
  const { id } = useParams();
  const activeLoan = getActiveLoan(id);
  const segmentEventData = getSegmentEventDetailsData(borrower, activeLoan);

  useEffect(() => {
    trackPage(SEGMENT_EVENT_NAMES.PATIENT_PORTAL.ADD_NEW_PAYMENT_METHOD.PAGE_LOAD, segmentEventData);
  }, []);

  useEffect(() => {
    if (paymentData?.getPaymentMethods) {
      const achMethods = SortByDate(paymentData?.getPaymentMethods?.contents, 'createdAt')?.filter(
        (it) => it.type === 'ACH',
      );

      const newlyAddedDebitCard = paymentData?.getPaymentMethods?.contents?.find(
        (it) => it.id === newlyAddedCardId?.toString(),
      );
      const newlyAddedBankAccount = achMethods[achMethods?.length - 1];
      alert.success(translate('account.payment.form.addedMessage'));

      if (addedPaymentType === 'card') {
        addPaymentMethod(newlyAddedDebitCard);
      } else {
        connectBankAccount(newlyAddedBankAccount);
      }
      setLoading(false);
    }
  }, [paymentData]);

  const onChangeHandler = (value: string) => {
    setSelectedPaymentMethod(value);
    trackSegmentEvent(SEGMENT_EVENT_NAMES.PATIENT_PORTAL.ADD_NEW_PAYMENT_METHOD.TYPE_SELECTED, {
      ...segmentEventData,
      type: value,
    });
  };
  const onClose = () => {
    trackSegmentEvent(SEGMENT_EVENT_NAMES.PATIENT_PORTAL.ADD_NEW_PAYMENT_METHOD.BACK_CLICKED, segmentEventData);
    handleClose();
  };
  const connectAccount = () => {
    trackSegmentEvent(SEGMENT_EVENT_NAMES.PATIENT_PORTAL.ADD_NEW_PAYMENT_METHOD.CONNECT_BANK_CLICKED, segmentEventData);

    trackEvent(AnalyticEventNames.ACC_NPM_ADD);
    setPlaidLoading(true);
    setPlaidUrl(
      `${API_URL}/v1/bank-connections/plaid-iframe/${borrower.id}?redirectUrl=${APP_URL}/portal/bank-connect-callback`,
    );
  };

  const onConnectBankHandler = (payload: any) => {
    if (payload && payload.event === 'complete') {
      setAddedPaymentType('bank');
      if (payload.data?.error) {
        trackEvent(AnalyticEventNames.ACC_NPM_ERROR);
        captureException(payload.data?.error, { errorContext: 'onConnectBankHandler => AddPaymentMethod.tsx' });
        alert.error(translate(`error.${payload.error?.code}`));
      } else {
        getPaymentMethods({
          variables: {
            input: {
              borrowerId: borrower?.id?.toString(),
              search: paymentSearch,
            },
          },
        });
        setLoading(true);
      }
    }
  };

  const onConnectCardHandler = (payload: any) => {
    trackSegmentEvent(SEGMENT_EVENT_NAMES.PATIENT_PORTAL.ADD_NEW_PAYMENT_METHOD.SAVE_NEW_PAYMENT_METHOD_CLICKED, {
      ...segmentEventData,
      type: PaymentMethodsForTracking.CARD,
    });
    if (payload && payload.status === 'success') {
      setAddedPaymentType('card');
      setNewlyAddedCardId(payload.paymentMethod?.id);
      getPaymentMethods({
        variables: {
          input: {
            borrowerId: borrower?.id?.toString(),
            search: paymentSearch,
          },
        },
      });
      setLoading(true);
    }
  };

  const onIframeLoaded = () => {
    setPlaidLoading(false);
  };
  return (
    <Fade in={true} timeout={750}>
      <Container selectedPaymentMethod={selectedPaymentMethod}>
        <ColumnWrapper>
          <Body>{translate('account.payment.form.info')}</Body>
          <Wrapper>
            {paymentMethodTypes?.map((option: { value: string }) => (
              <FullWidthWrapper key={option?.value}>
                <Chip
                  data-testid="methodItem"
                  disabled={isGuestMode && option?.value === PAYMENT_TYPES.CARD}
                  icon={
                    <Icon height={24} width={24} src={option?.value === PAYMENT_TYPES.CARD ? CardIcon : BankIcon} />
                  }
                  isSelected={selectedPaymentMethod === option?.value}
                  label={translate(`${option?.value === PAYMENT_TYPES.CARD ? 'payment.card' : 'payment.bank'}`)}
                  onClick={() => onChangeHandler(option?.value)}
                />
              </FullWidthWrapper>
            ))}
          </Wrapper>
        </ColumnWrapper>

        {selectedPaymentMethod === 'CARD' && (
          <Box>
            <PaymentMethodConnect borrower={borrower} onConnect={onConnectCardHandler} />
            <CancelContainer>
              <LibButton color={ButtonColor.secondary} onClick={onClose}>
                {translate('account.payment.form.cancel')}
              </LibButton>
            </CancelContainer>
          </Box>
        )}

        {selectedPaymentMethod === 'ACH' && (
          <>
            <Wrapper>
              <Icon height={16} width={16} src={LockIcon} />
              <Body>{translate('account.payment.form.ach.securelyConnect')}</Body>
            </Wrapper>
            <ColumnWrapper>
              <Button
                leftIcon={LockIcon}
                loading={plaidLoading}
                onClick={connectAccount}
                fullWidth
                disabled={isGuestMode}
              >
                {translate('account.payment.form.connectBank')}
              </Button>
              <Button variant="secondary" fullWidth onClick={onClose}>
                {translate('account.payment.form.cancel')}
              </Button>
            </ColumnWrapper>
          </>
        )}
        {loading && <Loading transparent={true} />}

        {plaidUrl && (
          <BankConnect
            plaidUrl={plaidUrl}
            handleClose={closeConnectAcount}
            onConnect={onConnectBankHandler}
            onIframeLoaded={onIframeLoaded}
          />
        )}
      </Container>
    </Fade>
  );
};

const Container = styled.div<{ selectedPaymentMethod: string }>`
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 24px !important;
  margin-bottom: ${(props) => (props.selectedPaymentMethod === 'CARD' ? '30px' : '0px')};
`;

const CancelContainer = styled.div`
  position: absolute;
  left: 0px;
  right: 0px;
  bottom: -45px;
`;

const Wrapper = styled.div`
  display: flex;
  gap: 8px;
`;

const ColumnWrapper = styled(Wrapper)`
  flex-direction: column;
`;

const FullWidthWrapper = styled.div`
  width: 100%;
`;
