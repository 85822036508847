import { FINDER_URL } from 'config';
import { ReactComponent as PaymentFinance } from 'assets/images/payment_finance.svg';
import { ReactComponent as CherryHomeLogo } from 'assets/images/cherry_logo_home.svg';
import { ReactComponent as CalendarPayment } from 'assets/images/calendar_payment.svg';
import { ReactComponent as MoneyDollar } from 'assets/images/money_dollar.svg';
import { ReactComponent as CreditCard } from 'assets/images/credit-card.svg';
import { ReactComponent as FileOutlined } from 'assets/images/file_blank_outline.svg';
import { ReactComponent as User } from 'assets/images/user.svg';
import { ReactComponent as Faq } from 'assets/images/help_circle_outline.svg';
import { ReactComponent as IdCard } from 'assets/images/id_card.svg';
import { ReactComponent as Home } from 'assets/images/home.svg';
import { ReactComponent as LocationPin } from 'assets/images/location_pin.svg';
import { SEGMENT_EVENT_NAMES } from '.';

export const routes = [
  {
    name: 'menu.listItems.home',
    to: '/portal/home',
    trackId: 'home',
    icon: Home,
    eventName: SEGMENT_EVENT_NAMES.PATIENT_PORTAL.NAVIGATION_MENU.HOME_CLICKED,
  },
  {
    name: 'menu.listItems.loans',
    to: '/portal/purchases',
    trackId: 'purchases',
    icon: PaymentFinance,
    eventName: SEGMENT_EVENT_NAMES.PATIENT_PORTAL.NAVIGATION_MENU.PAYMENT_PLANS_CLICKED,
  },
  {
    name: 'menu.listItems.applications',
    to: '/portal/approvals',
    trackId: 'approvals',
    icon: CherryHomeLogo,
    eventName: SEGMENT_EVENT_NAMES.PATIENT_PORTAL.NAVIGATION_MENU.APPLICATIONS_CLICKED,
  },
  {
    name: 'menu.listItems.upcomingPayments',
    to: '/portal/upcoming-payments',
    trackId: 'upcomingPayments',
    icon: CalendarPayment,
    eventName: SEGMENT_EVENT_NAMES.PATIENT_PORTAL.NAVIGATION_MENU.UPCOMING_PAYMENTS_CLICKED,
  },
  {
    name: 'menu.listItems.documents',
    to: '/portal/documents',
    trackId: 'documents',
    icon: FileOutlined,
    eventName: SEGMENT_EVENT_NAMES.PATIENT_PORTAL.NAVIGATION_MENU.DOCUMENTS_CLICKED,
  },
  {
    name: 'menu.listItems.account',
    to: '/portal/account',
    trackId: 'account',
    icon: User,
    eventName: SEGMENT_EVENT_NAMES.PATIENT_PORTAL.NAVIGATION_MENU.ACCOUNT_CLICKED,
  },
  {
    name: 'menu.listItems.faq',
    to: `/portal/help`,
    trackId: 'faq',
    icon: Faq,
    eventName: SEGMENT_EVENT_NAMES.PATIENT_PORTAL.NAVIGATION_MENU.HELP_CLICKED,
  },
  {
    name: 'menu.listItems.findPractice',
    to: FINDER_URL,
    isExternal: true,
    trackId: 'findAProvider',
    icon: LocationPin,
    eventName: SEGMENT_EVENT_NAMES.PATIENT_PORTAL.NAVIGATION_MENU.FIND_A_CHERRY_PRACTICE_CLICKED,
  },

  {
    name: 'menu.listItems.memberships',
    to: '/portal/memberships',
    trackId: 'memberships',
    icon: IdCard,
    eventName: SEGMENT_EVENT_NAMES.PATIENT_PORTAL.NAVIGATION_MENU.MEMBERSHIPS_CLICKED,
  },
];

export const landingPageCards = [
  {
    title: 'menu.listItems.loans',
    pageName: '/portal/purchases',
    icon: PaymentFinance,
    eventName: 'PATIENT_PORTAL.HOMEPAGE.VIEW_PAYMENT_PLANS_TILE_CLICKED',
    trackId: 'purchases',
  },
  {
    title: 'homePage.button.applyForLoan',
    pageName: '/portal/approvals',
    icon: CherryHomeLogo,
    eventName: 'PATIENT_PORTAL.HOMEPAGE.VIEW_APPLICATIONS_TILE_CLICKED',
    trackId: 'loans',
  },
  {
    title: 'menu.listItems.upcomingPayments',
    pageName: '/portal/upcoming-payments',
    icon: CalendarPayment,
    eventName: 'PATIENT_PORTAL.HOMEPAGE.UPCOMING_PAYMENTS_TILE_CLICKED',
    trackId: 'upcomingPayments',
  },
  {
    title: 'homePage.button.payment',
    pageName: '/portal/select-loan',
    icon: MoneyDollar,
    eventName: 'PATIENT_PORTAL.HOMEPAGE.MAKE_A_PAYMENT_TILE_CLICKED',
    trackId: 'payment',
  },
  {
    title: 'homePage.button.paymentMethods',
    pageName: '/portal/account',
    icon: CreditCard,
    eventName: 'PATIENT_PORTAL.HOMEPAGE.PAYMENT_METHODS_TILE_CLICKED',
    trackId: 'paymentMethods',
  },
  {
    title: 'homePage.button.viewDocuments',
    pageName: '/portal/documents',
    icon: FileOutlined,
    eventName: 'PATIENT_PORTAL.HOMEPAGE.VIEW_DOCUMENTS_TILE_CLICKED',
    trackId: 'documents',
  },
  {
    title: 'menu.listItems.account',
    pageName: '/portal/account',
    icon: User,
    eventName: 'PATIENT_PORTAL.HOMEPAGE.MY_ACCOUNT_CLICKED',
    trackId: 'account',
  },
  {
    title: 'menu.listItems.faq',
    pageName: '/portal/help',
    icon: Faq,
    eventName: 'PATIENT_PORTAL.HOMEPAGE.HELP_CLICKED',
    trackId: 'faq',
  },
];
