import { gql } from '@apollo/client/core';

export const GET_ORGANIZATION_SUBSCRIPTION_PLANS = gql`
  query fetchOrganizationsMembershipPlans($input: FetchOrganizationMembershipsPlansInput) {
    fetchOrganizationsMembershipPlans(input: $input) {
      total
      contents {
        id
        name
        organizationId
        description
        detailedDescription
        status
        recommended
        prices {
          id
          period
          price
          planId
          firstPayment
          autoRenew
          billingTerm
          processingFee
          paymentFrequency
        }
        benefits {
          id
          name
          type
          frequency
          quantity
          planId
        }
        createdAt
        createdBy
        updatedAt
        updatedBy
      }
    }
  }
`;

export const GET_ORGANIZATION_ACCOUNT_INFORMATION = gql`
  query fetchOrganizationAccountInformation($input: FetchOrganizationAccountInformationInput) {
    fetchOrganizationAccountInformation(input: $input) {
      organizationAccountId
      cherryPublicKey
      applyFee
    }
  }
`;
