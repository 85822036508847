import { gql } from '@apollo/client/core';

export const FETCH_LATEST_CREDIT_LINE_INCREASE = gql`
  query fetchLatestCreditLineIncrease($input: FetchCreditLineIncreasesInput) {
    fetchLatestCreditLineIncrease(input: $input) {
      amount {
        requested
      }
      id
      status
      updatedAt
    }
  }
`;
