export const calculateExpireDay = (date: Date | string) => {
  try {
    const expireDate = new Date(date);
    const today = new Date();
    // @ts-ignore
    const diffInMs = expireDate - today;
    return Math.ceil(diffInMs / (1000 * 60 * 60 * 24));
  } catch (err) {
    return 0;
  }
};
