import styled from 'styled-components';
import { Icon, Body, theme, Heading, Button } from '@frontend/cherry-library';
import { ReactComponent as WarningImage } from 'assets/images/warning-outline.svg';
import { FormatAmount } from 'lib/utils';
import { useTranslation } from 'react-i18next';
import { SEGMENT_EVENT_NAMES } from 'lib/constans';
import { getSegmentEventDetailsData } from 'lib/utils/SegmentEventData';
import useStore from 'lib/hooks/useStore';
import { useSegment } from 'lib/hooks/useSegment';

export const GenericHeading = ({ contractModal, loan, title, infoTitle, infoBody, children }) => {
  const { t: translate } = useTranslation();
  const { borrower } = useStore();
  const segmentEventPaymentPlanDetailsData = getSegmentEventDetailsData(borrower, loan);
  const { trackSegmentEvent } = useSegment();

  const openContractModal = () => {
    contractModal.openContractModal();
    trackSegmentEvent(
      SEGMENT_EVENT_NAMES.PATIENT_PORTAL.PAYMENT_PLAN_DETAILS.EXEMPT_LOAN_DOWNLOAD_CONTRACT_CLICKED,
      segmentEventPaymentPlanDetailsData,
    );
  };

  return (
    <>
      <Title color={theme.main.cherryRed} fontWeight={600} size="small">
        {title}
      </Title>
      <RedHeading
        level="2"
        text={FormatAmount(loan?.plans[0]?.status === 'REFUNDED' ? loan?.purchaseAmount : loan?.grossAmount)}
      ></RedHeading>
      <DownloadButton onClick={openContractModal} variant="secondary" fullWidth>
        {translate('contractModal.downloadContract')}
      </DownloadButton>

      <InfoContainer>
        <Wrapper>
          <Icon src={WarningImage} />
          <Heading level="5" text={infoTitle} />
        </Wrapper>
        <CenteredBody>{infoBody}</CenteredBody>
        {children}
      </InfoContainer>
    </>
  );
};

const Title = styled(Body)`
  margin-top: 16px !important;
  text-transform: uppercase;
`;

const RedHeading = styled(Heading)`
  color: ${theme.main.cherryRed} !important;
`;

const InfoContainer = styled.div`
  display: flex;
  padding: 16px;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 16px;
  align-self: stretch;
  border-radius: 4px;
  margin-top: 24px;
  border: 1px solid ${theme.main.alertWarning};
  background-color: #ffebc8;
  box-shadow: 0px 36px 67px 0px rgba(0, 0, 0, 0.07), 0px 13.141px 24.456px 0px rgba(0, 0, 0, 0.05),
    0px 6.38px 11.873px 0px rgba(0, 0, 0, 0.04), 0px 3.127px 5.82px 0px rgba(0, 0, 0, 0.03),
    0px 1.237px 2.301px 0px rgba(0, 0, 0, 0.02);
`;

const DownloadButton = styled(Button)`
  color: ${theme.main.midnightBlue} !important;
  background-color: transparent !important;
  margin-top: 24px;
`;

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

const CenteredBody = styled(Body)`
  text-align: center !important;
`;
