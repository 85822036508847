export const browserIsMobile = () => {
  const toMatch = [/Android/i, /webOS/i, /iPhone/i, /iPad/i, /iPod/i, /BlackBerry/i, /Windows Phone/i];

  return toMatch.some((toMatchItem) => {
    return navigator.userAgent.match(toMatchItem);
  });
};

export const browserIsSafari = () => {
  if (navigator.userAgent.includes('Safari') && !navigator.userAgent.includes('Chrome')) {
    return true;
  }
  return false;
};

export const isIOS = () => {
  if (/iPad|iPhone|iPod/.test(navigator.userAgent)) {
    return true;
  } else {
    return navigator.maxTouchPoints && navigator.maxTouchPoints > 2 && /MacIntel/.test(navigator.platform);
  }
};

export const isAndroid = () => {
  return /android/i.test(navigator.userAgent);
};

export const getDeviceType = () => {
  const userAgent = navigator.userAgent;
  if (/android/i.test(userAgent)) {
    return 'android';
  } else if (/iPad|iPhone|iPod/.test(userAgent)) {
    return 'ios';
  } else {
    return 'desktop';
  }
};
