import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { PREQUAL_URL } from 'config';
import { Button } from 'lib/components';
import { useSetExchangeToken } from 'lib/hooks/useExchangeToken';
import { FormatAmount } from 'lib/utils';
import { ExternalRedirect } from 'lib/utils/ExternalRedirect';
import useGuestMode from 'lib/hooks/useGuestMode';

interface Props {
  loan: any;
  merchant: any;
  amount: number;
}

export const CheckoutCard = ({ loan, merchant, amount }: Props) => {
  const { t: translate } = useTranslation();
  const [setExchangeToken, { data: exchangeData, loading }] = useSetExchangeToken();
  const { isGuestMode } = useGuestMode();

  useEffect(() => {
    if (exchangeData && exchangeData?.setExchangeToken && exchangeData?.setExchangeToken?.exchangeTokenId) {
      ExternalRedirect(`${PREQUAL_URL}/${exchangeData?.setExchangeToken?.exchangeTokenId}`);
    }
  }, [exchangeData]);

  const checkoutHandler = async () => {
    setExchangeToken({
      variables: {
        input: {
          target: 'SELF_CHECKOUT',
          payload: {
            organizationId: merchant?.organization?.id,
            merchantId: merchant.id,
            loanId: loan.id,
          },
        },
      },
    });
  };

  return (
    <Container>
      <ContentContainer>
        <Title>{merchant.name}</Title>
        <Amount>{FormatAmount(amount)}</Amount>
        <SubTitle>{translate('purchases.checkout.subtitle')}</SubTitle>
      </ContentContainer>
      <ActionContainer>
        <Button onClick={checkoutHandler} m={'0'} p={'9px 16px 9px 16px'} disabled={loading || isGuestMode}>
          {translate('purchases.checkout.buttonText')}
        </Button>
      </ActionContainer>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  box-shadow: 0px 6px 9px rgba(0, 0, 0, 0.07), 0px 0.751293px 1.12694px rgba(0, 0, 0, 0.035);
  border-radius: ${(props) => props.theme.constants.s};
  margin-bottom: ${(props) => props.theme.constants.l};
  padding: 24px;
  background-color: white;

  @media (max-width: ${(props) => props.theme.size.tablet}) {
    flex-direction: column;
  }
`;

const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  @media (max-width: ${(props) => props.theme.size.tablet}) {
    width: 100%;
  }
`;

const ActionContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  @media (max-width: ${(props) => props.theme.size.tablet}) {
    width: 100%;
    margin-top: 16px;
  }
`;

const Title = styled.span`
  font-weight: bold;
  font-size: 20px;
  line-height: 27px;
`;

const Amount = styled.span`
  font-weight: bold;
  font-size: 32px;
  line-height: 44px;
`;

const SubTitle = styled.span`
  font-weight: normal;
  font-size: 14px;
  line-height: 19px;
`;
