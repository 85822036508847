import styled from 'styled-components';

interface Props {
  message: string;
}

export const Banner = ({ message }: Props) => {
  return (
    <Container>
      <HtmlContent dangerouslySetInnerHTML={{ __html: message }} />
    </Container>
  );
};

const Container = styled.div`
  text-align: center;
  font-weight: bold;
  width: 100%;

  p,
  h4 {
    padding: 0;
    margin: 0;
    font-size: 14px;

    @media (max-width: ${(props) => props.theme.size.mobileXl}) {
      font-size: 12px;
    }
  }

  p {
    font-weight: 500;
  }
`;

const HtmlContent = styled.div``;
