export const getErrorMessage = (key: string) => {
  switch (key) {
    case 'payment_method.not_found':
    case 'loan.not_found':
    case 'payment.amount_required.validation':
    case 'Internal Server Error':
      return 'paymentReview.error.tryAgain';
    case 'payment_method.invalid':
      return 'paymentReview.error.invalid';
    default:
      return 'makePayment.paymentReview.error';
  }
};
