export interface Payments {
  date: string;
  amount: number;
  fallback: number;
}

export interface Product {
  applicationId: number;
  apr: number;
  createdAt: string;
  downPayment: number;
  downPaymentType: string;
  fallbackApr: number;
  id: number;
  maxEligible: number;
  mdf: number;
  minEligible: number;
  moduleString: string;
  periodLength: string;
  promoApr: number;
  promoMdf: number;
  promoTerm: number;
  status: string;
  term: number;
}

export interface CalculationData {
  downPaymentAmount: number;
  financeCharge: number;
  grossAmount: number;
  installmentAmount: number;
  loanAmount: number;
  promo: boolean;
  totalAmount: number;
  paymentDates: Array<string>;
  payments: Array<Payments>;
  product: Product;
}

export const SortWithProductTerm = (data: Array<CalculationData> | null) => {
  return data?.slice()?.sort((a, b) => {
    return a?.product?.term - b?.product?.term;
  });
};
