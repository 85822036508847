/* eslint-disable @typescript-eslint/no-explicit-any */

import { ApolloClient, createHttpLink, from, InMemoryCache } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import * as Sentry from '@sentry/react';
import { GQL_URL } from 'config';
import jwtDecode from 'jwt-decode';

import NavigateService from 'lib/services/Navigate';
import StorageService from 'lib/services/Storage';
import { cancelRequestLink } from './cancelRequest';
import { BORROWER_REFRESH_TOKEN } from './mutations';
import { checkPathIsMembershipPath } from 'lib/utils/CheckPathIsMembershipPath';
import { checkPathIsPhoneUpdate } from 'lib/utils/CheckPathIsPhoneUpdate';
import { IVR_TOKEN_EXCHANGE } from 'lib/graphql/mutations/IVRTokenExchange';
import { updateChatWidgetAfterLogin } from 'lib/utils/ChatWidget';
import useStore from 'lib/hooks/useStore';
import { Flags } from 'lib/hooks/FeatureManagement/Flags';

let isAlreadyFetching = false;

document.addEventListener('visibilitychange', async () => {
  if (document.visibilityState === 'visible') {
    const { token } = StorageService.getAuthData();

    if (isTokenValid(token) && isExpiredToken(token) && !checkPathIsMembershipPath() && !checkPathIsPhoneUpdate()) {
      await resetTokenAndReattemptRequest();
    }
  }
});

const isExpiredToken = (token: string | any = '') => {
  try {
    /* in case the call succeeds at the end of timeout,
    remove the amount of timeout from our current time
    to avoid 401 from server */
    const decodedToken = jwtDecode(token) as any;
    const tokenExpiration = decodedToken.exp * 1000 - 10000;
    const currentTime = Date.now().valueOf();

    return tokenExpiration < currentTime;
  } catch {
    return false;
  }
};

const isTokenValid = (token: string | null | undefined) => {
  return token !== 'undefined' && token !== 'null' && Boolean(token);
};

const resetTokenAndReattemptRequest = async () => {
  try {
    const { refreshToken: resetToken = '' } = StorageService.getAuthData();
    if (!resetToken) {
      const reason = `Couldn't find refresh token!`;
      StorageService.clearUserData();
      NavigateService.navigate('/');
      client.stop();
      return Promise.reject(reason);
    }

    if (!isAlreadyFetching) {
      isAlreadyFetching = true;
      console.warn('--- WARNING: JWT Timeout - Refreshing1! ---');
      const { data } = await client.mutate({
        mutation: BORROWER_REFRESH_TOKEN,
        variables: {
          input: {
            token: resetToken,
          },
        },
      });

      if (!data?.borrowerRefreshToken?.authorization?.token) {
        StorageService.clearUserData();
        NavigateService.navigate('/');
        client.stop();
        return Promise.reject(data);
      }

      const { token, refreshToken } = data?.borrowerRefreshToken?.authorization || {};
      StorageService.setAuthData(token, refreshToken);
      isAlreadyFetching = false;

      const isYellowChatBotEnabled = Flags.FEATURES.yellowAiChatBot.isEnabled();
      if (isYellowChatBotEnabled) {
        const { data: { ivrTokenExchange: { ivrToken = null } = {} } = {} } = await client.mutate({
          mutation: IVR_TOKEN_EXCHANGE,
        });
        const borrower = useStore.getState().borrower;
        updateChatWidgetAfterLogin(borrower?.phone, borrower?.firstName, ivrToken);
      }
    } else {
      return new Promise((resolve) => {
        const intervalId = setInterval(() => {
          const { token } = StorageService.getAuthData();
          if (!isExpiredToken(token)) {
            clearInterval(intervalId);
            resolve('');
          }
        }, 500);
      });
    }
  } catch (err: any) {
    // make sure we don't lock any upcoming request in case of a refresh error
    client.stop();
    StorageService.clearUserData();
    NavigateService.navigate('/');
    Sentry.captureException(err, {
      level: 'error',
      extra: { description: 'resetTokenAndReattemptRequest Catch Error' },
    });
    return Promise.reject(err);
  }
};

const httpLink = createHttpLink({
  uri: GQL_URL,
});

const authMiddleware = setContext(async (_, { headers, ...rest }) => {
  try {
    const { token } = checkPathIsMembershipPath()
      ? StorageService.getSubscriptionsToken()
      : checkPathIsPhoneUpdate()
      ? StorageService.getSelfUpdateToken()
      : StorageService.getAuthData();

    if ((isTokenValid(token) && !isExpiredToken(token)) || _.operationName === 'borrowerRefreshToken') {
      return {
        headers: {
          ...headers,
          Authorization: token ? `Bearer ${token}` : '',
        },
        ...rest,
      };
    } else {
      if (isTokenValid(token) && !checkPathIsMembershipPath() && !checkPathIsPhoneUpdate()) {
        await resetTokenAndReattemptRequest();
      }

      const { token: newToken } = checkPathIsMembershipPath()
        ? StorageService.getSubscriptionsToken()
        : checkPathIsPhoneUpdate()
        ? StorageService.getSelfUpdateToken()
        : StorageService.getAuthData();

      if (
        !(
          !isTokenValid(newToken) &&
          _.operationName !== 'verifyOtpRequest' &&
          _.operationName !== 'requestOtp' &&
          _.operationName !== 'getUserAuthInit'
        )
      ) {
        return {
          headers: {
            ...headers,
            Authorization: isTokenValid(newToken) ? `Bearer ${newToken}` : '',
            'x-client-name': 'CustomerPortal',
          },
          ...rest,
        };
      }
    }
  } catch (error: any) {
    if (error.code !== 'refresh.token.invalid') {
      Sentry.captureException(error, {
        level: 'error',
        extra: { description: 'authMiddleware Catch Error' },
      });
    } else {
      client.stop();
      StorageService.clearUserData();
      NavigateService.navigate('/');
    }
  }
});

const client = new ApolloClient({
  cache: new InMemoryCache(),
  link: from([authMiddleware, cancelRequestLink, httpLink]),
  defaultOptions: {
    watchQuery: {
      fetchPolicy: 'network-only',
      errorPolicy: 'all',
    },
    query: {
      fetchPolicy: 'network-only',
      errorPolicy: 'all',
    },
  },
  name: 'customer-portal',
});

export default client;
