export enum CommitmentProgress {
  FULFILLED = 'FULFILLED',
  UNFULFILLED = 'UNFULFILLED',
  PENDING = 'PENDING',
}

export type Commitment = {
  amount: number;
  progress: CommitmentProgress;
  scheduledDate: string;
  status: string;
  createdAt: string;
};
