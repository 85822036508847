import Typography from '@mui/material/Typography';
import { memo } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { Icon, Subtitle } from 'lib/components';

import useStore, { LanguageEnum } from 'lib/hooks/useStore';
import { theme } from 'config/theme';
import { WEB_SITE } from 'config';

export const Footer = memo(() => {
  const { t } = useTranslation();
  const location = useLocation();
  const { setPhoneUpdateOrigin, defaultLanguage } = useStore();

  const navigate = useNavigate();

  const handlePhoneUpdateClick = () => {
    setPhoneUpdateOrigin(true);
    navigate('/phone-update');
  };
  const isChangeLocationVisible = location.pathname === '/';

  return (
    <FooterContainer>
      {isChangeLocationVisible && (
        <>
          {/* <NumberChangeContainer>
            <Icon width={20} height={20} src={'info_circle'} />
            <TextContainer>
              <Subtitle color={theme.main.black} fontSize="18px">
                {t('phoneUpdate.changeRecently')}
              </Subtitle>
              <SubtitleContainer>
                <Subtitle
                  color={theme.main.black}
                  fontSize="18px"
                  p="6px 0"
                  onClick={handlePhoneUpdateClick}
                  fontWeight="600"
                  cursor="pointer"
                  textDecoration="underline"
                >
                  {t('phoneUpdate.footer.clickHere')}
                </Subtitle>
                <Subtitle color={theme.main.black} fontSize="18px" p="6px 0 6px 3px">
                  {t('phoneUpdate.footer.toUpdate')}
                </Subtitle>
              </SubtitleContainer>
            </TextContainer>
          </NumberChangeContainer> */}
          {defaultLanguage === LanguageEnum.ES && (
            <SpanishDisclosure
              dangerouslySetInnerHTML={{
                __html: t('login.footer.spanishDisclosure.text', {
                  link: `<a target="_blank" href=${WEB_SITE}/spanish'>${t('login.footer.spanishDisclosure.link')}</a>`,
                }),
              }}
            />
          )}
        </>
      )}
      <Typography mb={1} variant="body2">
        © {new Date().getFullYear()} Cherry LLC
      </Typography>
    </FooterContainer>
  );
});

const NumberChangeContainer = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
  text-align: center;
  padding-bottom: 12px;
  margin-bottom: 40px;
`;

const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding-left: 12px;
`;
const SubtitleContainer = styled.div`
  display: flex;
  flex-direction: row;
`;

const FooterContainer = styled.div`
  flex-grow: 0;
  justify-content: center;
  text-align: center;
  width: 100%;
  overflow: hidden;
  width: 100%;
  padding: 10px 0px;
`;

const SpanishDisclosure = styled.span`
  font-size: 14px;

  a {
    color: ${(props) => props.theme.main.linkColor};
  }
`;
