export const theme = {
  main: {
    primary: '#0e202f',
    white: '#ffffff',
    black: '#000000',
    iconColor: '#f1f1f1',
    pageColor: '#F3F4F5',
    green: '#00c37d',
    red: '#EC3360',
    lightGreen: '#00c37d0c',
    loanColor: '#eb1d5b',
    errorColor: '#f44336;',
    textColor: '#0E202F',
    cardBorderColor: '#dbdee0',
    lightGray: '#f2f4f5',
    borderColor: '#eeeeee',
    zebraColor: '#f3f4f5',
    disabledBtn: '#777777',
    grayColor: '#868f97',
    midnightBlue: '#56636D',
    linkColor: '#337ab7',
    menuBackgroundColor: '#3E4D59',
    linkHoverColor: '#23527c',
    preferredColor: '#E6F9F2',
    guestModeColor: '#EBC075',
    midnightBlueGray: '#879097',
    neutralGray: '#dadada',
    lightGrayishBlue: '#CFD2D5',
    lightYellow: '#FFEBC8',
    lightMidnightBlue: '#6e7982',
    kellyGreen5: '#F2FFFB',
    kellyGreen20: '#CCF3E5',
    softGray: '#56636D',
  },
  constants: {
    s: '8px',
    m: '16px',
    l: '24px',
    xl: '32px',
    zIndex: 99,
  },
  size: {
    mobileS: '320px',
    mobileM: '375px',
    mobileL: '425px',
    mobileXl: '680px',
    tablet: '768px',
    laptop: '1024px',
    laptopL: '1440px',
    desktop: '2560px',
    mobile: '456px',
  },
  fonts: {
    primary: "'Open Sans', sans-serif",
    secondary: "'Montserrat', sans-serif",
  },
};
