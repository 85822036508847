import Backdrop from '@mui/material/Backdrop';
import Slide from '@mui/material/Slide';
import styled from 'styled-components';

import { Icon, Title } from 'lib/components';
import FaqListComponent from 'lib/components/Faq';

interface FaqItem {
  title: string;
  content: string;
}

interface Props {
  show: boolean;
  hideModal: () => void;
  title: string;
  titleMargin: string;
  modalHeight?: string;
  modalWidth?: string;
  modalTop?: string;
  scrollEnabled?: boolean;
}

export const FaqListModal = ({
  show,
  hideModal,
  title,
  titleMargin,
  modalHeight,
  modalWidth,
  modalTop,
  scrollEnabled,
}: Props) => {
  const closeModalOnClickAway = (e) => {
    const isCaret = e.target?.getAttribute('data-testid') === 'ExpandMoreIcon';
    if (e?.target?.ariaHidden && !isCaret) {
      hideModal();
    }
  };

  return (
    <Backdrop
      sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
      open={show}
      onClick={closeModalOnClickAway}
    >
      <Slide direction="up" in={show} mountOnEnter={true} unmountOnExit={true}>
        <FaqModalContainer
          top={modalTop || '170px'}
          width={modalWidth || '400px'}
          height={modalHeight || '360px'}
          scrollEnabled={scrollEnabled || false}
        >
          <InnerContainer>
            <FaqModalHeader>
              <IconGap />
              <Title m={'24px 0px'}>{title}</Title>
              <Icon src={'close'} onClick={hideModal} hover={true} />
            </FaqModalHeader>
            <FaqListContainer>
              <FaqListComponent close={hideModal} />
            </FaqListContainer>
          </InnerContainer>
        </FaqModalContainer>
      </Slide>
    </Backdrop>
  );
};

const FaqModalContainer = styled.div<{ height: string; width: string; top: string; scrollEnabled: boolean }>`
  width: 90%;
  position: absolute;
  bottom: -15px;
  background: #ffffff;
  z-index: 1000;
  box-shadow: 0px -36px 67px rgba(0, 0, 0, 0.07), 0px -13.14px 24.4561px rgba(0, 0, 0, 0.0482987),
    0px -6.38px 11.873px rgba(0, 0, 0, 0.0389404), 0px -3.13px 5.82036px rgba(0, 0, 0, 0.0310596),
    0px -1.24px 2.30138px rgba(0, 0, 0, 0.0217013);
  border-radius: 0 0 32px 32px;
  padding: 0 24px 30px;
  height: ${(props) => props.height};

  overflow: ${(props) => (props.scrollEnabled ? 'scroll' : 'visible')};

  @media (min-width: ${(props) => props.theme.size.mobileXl}) {
    top: ${(props) => props.top};
    width: ${(props) => props.width};
    border-radius: 6px;
  }
`;

const FaqModalHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const IconGap = styled.div`
  width: 24px;
  height: 24px;
`;

const InnerContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const FaqListContainer = styled.div`
  display: flex;
  flex-direction: column;
`;
