import React, { useEffect, useState } from 'react';
import { useApolloClient, useMutation } from '@apollo/client';
import styled from 'styled-components';
import { useNavigate, useParams } from 'react-router-dom';
import { useAlert } from 'react-alert';

import {
  ButtonColor,
  Button,
  Icon,
  MembershipContainer,
  Title,
  InnerContainer,
  ButtonContainer,
  Loading,
  FaqListModal,
} from 'lib/components';
import PlanCard from 'lib/components/PlanCard/PlanCard';
import useStore from 'lib/hooks/useStore';
import { GET_AUTH_INIT } from 'lib/graphql/mutations';
import StorageService from 'lib/services/Storage';
import {
  GET_ORGANIZATION_ACCOUNT_INFORMATION,
  GET_ORGANIZATION_SUBSCRIPTION_PLANS,
} from 'lib/graphql/queries/Subscriptions';
import { useTranslation } from 'react-i18next';

const Subscriptions = () => {
  const { slug } = useParams();
  const [getAuthInit] = useMutation(GET_AUTH_INIT);
  const client = useApolloClient();
  const alert = useAlert();
  const navigate = useNavigate();
  const { t: translate } = useTranslation();

  const {
    setSubscriptionPlans: setSubscriptionPlansToStore,
    organizationGroup,
    subscriptionOrganization,
    setSubscriptionOrganization,
    setOrganizationAccountInformation,
    setPageIndex,
  } = useStore();

  const [isFaqVisible, setIsFaqVisible] = useState(false);
  const [loading, setLoading] = useState(true);
  const [subscriptionPlans, setSubscriptionPlans] = useState([]);

  useEffect(() => {
    setPageIndex(0);
  }, [setPageIndex]);

  const toggleFaq = () => {
    setIsFaqVisible((prev) => !prev);
  };

  useEffect(() => {
    const getAuthTokenAndMembershipPlans = async () => {
      try {
        const authInitResponse = await getAuthInit({
          variables: {
            input: { slug },
          },
        });

        const authInit = authInitResponse?.data?.getUserAuthInit;
        if (authInit?.code === 'organization.not_found') {
          alert.error(translate('subscriptions.notfound'));
          navigate('/');
          return;
        }
        setSubscriptionOrganization(authInit?.organization);
        StorageService.setSubscriptionsToken(authInit?.token);

        const { data } = await client.query({
          query: GET_ORGANIZATION_SUBSCRIPTION_PLANS,
          variables: {
            input: {
              organizationId: authInit?.organization?.id,
              search: [
                {
                  key: 'status',
                  value: 'LIVE',
                },
              ],
            },
          },
        });
        const {
          data: { fetchOrganizationAccountInformation },
        } = await client.query({
          query: GET_ORGANIZATION_ACCOUNT_INFORMATION,
          variables: {
            input: {
              organizationId: authInit?.organization?.id,
            },
          },
        });

        setSubscriptionPlans(data?.fetchOrganizationsMembershipPlans?.contents);
        setSubscriptionPlansToStore(data?.fetchOrganizationsMembershipPlans?.contents);
        setOrganizationAccountInformation(fetchOrganizationAccountInformation);
      } catch (error) {
        alert.error(translate('subscriptions.error'));
      } finally {
        setLoading(false);
      }
    };

    getAuthTokenAndMembershipPlans();
  }, []);

  if (loading) {
    return <Loading transparent={true} />;
  }

  return (
    <MembershipContainer showBackButton={false}>
      <InnerContainer>
        <Icon width={28} height={28} src="membership" />
        <TitleContainer>
          <Title>
            {translate('subscriptions.title', { name: subscriptionOrganization?.name || organizationGroup?.name })}
          </Title>
        </TitleContainer>
        {subscriptionPlans?.map((plan) => (
          <PlanCard plan={plan} />
        ))}
      </InnerContainer>
      <Footer isFixed={isFaqVisible}>
        <ButtonContainer>
          <Button p="15px" color={ButtonColor.secondary} onClick={toggleFaq}>
            {isFaqVisible ? translate('subscriptions.dismiss') : translate('subscriptions.frequentlyAsked')}
          </Button>
        </ButtonContainer>
      </Footer>
      <FaqListModal
        hideModal={toggleFaq}
        show={isFaqVisible}
        title={translate('common.haveQuestion')}
        titleMargin={'auto'}
        modalHeight={'80%'}
        modalWidth={'550px'}
        modalTop={'50px'}
        scrollEnabled={true}
      />
    </MembershipContainer>
  );
};

const TitleContainer = styled.div`
  margin-bottom: 12px;
`;

const Footer = styled.div<{ isFixed: boolean }>`
  ${(props) => props.isFixed && 'display: none;'}
  width: 100%;
  bottom: 0;
`;

export default Subscriptions;
