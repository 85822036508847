import styled from 'styled-components';

import { RadioButton } from 'lib/components';
import { Body, theme } from '@frontend/cherry-library';

interface RadioSelectionProps<Selection> {
  label: string;
  value: string;
  selected: string;
  setSelection: (value: string) => void;
  onClick?: (selection: Selection) => void;
  selection: Selection;
}

export const RadioSelection = <Selection,>({
  selection,
  label,
  value,
  selected,
  setSelection,
  onClick,
}: RadioSelectionProps<Selection>) => {
  const onClickHandler = () => {
    onClick?.(selection);
    setSelection(value);
  };

  return (
    <RadioContainer onClick={onClickHandler} selected={selected === value}>
      <RadioButton checked={selected === value} value={value} />
      <Body color={theme.main.midnightBlue} fontWeight={600} size="small">
        {label}
      </Body>
    </RadioContainer>
  );
};

const RadioContainer = styled.div<{ selected: boolean }>`
  flex: 1 !important;
  cursor: pointer;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-shadow: ${(props) => `0px 0px 0px ${props.selected ? '2px rgb(0, 195, 125)' : '1px #56636D'} inset`};
  opacity: 1;
  border-radius: 4px;
  padding: 8px;
  margin-bottom: 8px;
`;
