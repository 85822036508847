enum PaymentMethodType {
  RCC = 'RCC',
  ACH = 'ACH',
  CARD = 'CARD',
}

interface Props {
  targetId: number;
  paymentMethods;
  short?: boolean;
}

export const findPaymentMethodInfo = ({ targetId, paymentMethods, short = false }: Props) => {
  const paymentMethod = paymentMethods?.find((method) => Number(method?.id) === targetId);
  if (!paymentMethod) return '';

  switch (paymentMethod.type) {
    case PaymentMethodType.RCC: {
      const rccAccount = paymentMethod.rccAccount;
      const bankName = rccAccount?.bankName;
      const last4 = rccAccount?.accountNumber.slice(-4);
      if (short) {
        return `${bankName} ...${last4}`;
      }
      return `${bankName} ****${last4}`;
    }
    case PaymentMethodType.ACH: {
      const achAccount = paymentMethod.achAccount;
      const bankName = achAccount?.bankName;
      const accountType = achAccount?.accountType;
      const last4 = achAccount?.accountNumber.slice(-4);
      if (short) {
        return `${bankName.split('-')[0]} ...${last4}`;
      }
      return `${bankName} ${accountType} ****${last4}`;
    }

    case PaymentMethodType.CARD: {
      const storedCard = paymentMethod.storedCard;
      const network = storedCard?.network;
      const cardType = storedCard?.type;
      const last4 = storedCard?.last4;
      if (short) {
        return `${network} ...${last4}`;
      }
      return `${network} ${cardType} ****${last4}`;
    }
  }
};
