import React from 'react';

import ContentLoader from 'react-content-loader';
import styled from 'styled-components';

interface Props {
  count?: number;
  loading: boolean;
  children: any;
  backgroundColor?: string;
  opacity?: number;
}

export const DataLoader = ({ count = 2, loading, children, backgroundColor, opacity }: Props) => {
  const countArray: string[] = [];
  for (let i = 0; i < count; i++) {
    countArray.push('');
  }

  return loading ? (
    <ContentLoaderWrapper backgroundColor={backgroundColor} opacity={opacity}>
      {countArray.map((item, index) => (
        <Rect key={`rect-${index}`} x="0" y={(index + 1) * 24} rx="3" ry="3" height="20" />
      ))}
    </ContentLoaderWrapper>
  ) : (
    children
  );
};

const ContentLoaderWrapper = styled(ContentLoader)`
  width: 100%;
  height: 64px;
  margin-right: 10px;
  margin-top: -20px;
`;

const Rect = styled.rect`
  width: 100%;
`;
