import IconButton from '@mui/material/IconButton';
import LinearProgress from '@mui/material/LinearProgress';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { useEffect, useState } from 'react';
import styled from 'styled-components';

import { useSegment } from 'lib/hooks/useSegment';
import { useNavigation } from 'lib/hooks/useNavigation';
import { useBrowserHistory } from 'lib/hooks/useBrowserHistory';
import { HelpMeModal, Icon, Title } from 'lib/components';
import useStore from 'lib/hooks/useStore';
import StorageService from 'lib/services/Storage';
import { theme } from 'config/theme';
import { useTranslation } from 'react-i18next';

const progressTheme = createTheme({
  palette: {
    primary: {
      main: '#1ec37c',
    },
    secondary: {
      main: '#c5cae9',
    },
  },
  components: {
    MuiLinearProgress: {
      styleOverrides: {
        root: {
          backgroundColor: 'transparent',
        },
      },
    },
  },
});

interface Props {
  pathname: string;
  pageIndex: number;
  showBackButton: boolean;
  backUrl: string;
  goBackCallback?: () => void;
  isPhoneUpdate: boolean;
}

const PAGE_COUNT = 5;

export const MembershipHeader = ({
  pathname,
  pageIndex,
  showBackButton,
  backUrl,
  goBackCallback,
  isPhoneUpdate,
}: Props) => {
  const { trackPage } = useSegment();
  const { navigate, navigateBack } = useNavigation();
  const { pageName, merchantSlug } = useBrowserHistory();
  const { organization, organizationGroup, isDemo, subscriptionOrganization } = useStore();
  const { t: translate } = useTranslation();
  // Modal States
  const [helpMe, setHelpMe] = useState(false);
  const [securityModal, setSecurityModal] = useState(false);

  const goBackHandler = () => {
    if (backUrl) {
      return navigate(backUrl);
    }

    if (goBackCallback) {
      return goBackCallback();
    }

    navigateBack();
  };

  const getProgressValue = () => {
    if (pageIndex > 0) {
      return (100 / PAGE_COUNT) * pageIndex;
    }
    return 0;
  };

  const isProgressVisible = true;

  const backButtonHandlerScreens = [
    'checkout-in-person',
    'update-location',
    'faq',
    'plans',
    'customize-plan',
    'plan-review',
    'add-card',
    'add-remote-check',
    'remote-check-review',
    'down-payment',
    'method-list',
    'autopay',
    'confirm',
    'address-info',
    'verify',
    'choose-plan',
    'request-increase',
    'checking-out',
    'book-an-appointment',
    'preview-my-payments',
    'additional-info',
  ];

  const helpMeToggle = () => {
    setHelpMe(!helpMe);
  };

  const logoutPressed = () => {
    StorageService.clearUserData();
    navigate('');
  };

  const openSecurityModal = () => {
    setSecurityModal(true);
  };

  useEffect(() => {
    if (!backButtonHandlerScreens.includes(document.location.pathname.split('/')[2])) {
      window.history.pushState(null, window.location.pathname);
      window.addEventListener('popstate', onBackButtonEvent);

      return () => {
        window.removeEventListener('popstate', onBackButtonEvent);
      };
    }
  }, []);

  const onBackButtonEvent = (e) => {
    window.history.pushState(null, window.location.pathname);
    openSecurityModal();
  };

  return (
    <Container>
      {isPhoneUpdate && <HelpMeModal show={helpMe} hideModal={() => setHelpMe(false)} />}
      <InnerContainer>
        <HeaderContainer>
          <IconContainer>
            {showBackButton && (
              <IconButton aria-label="back" color="inherit" onClick={goBackHandler}>
                <BackIcon src={'/short_left.svg'} />
              </IconButton>
            )}
          </IconContainer>
          {!isPhoneUpdate ? (
            <TitleContainer>
              <Icon width={16} height={16} src={'cherry-logo'} />
              <InnerTitle>In proud partnership with</InnerTitle>
              <HeaderTitle>{subscriptionOrganization?.name || organizationGroup?.name}</HeaderTitle>
            </TitleContainer>
          ) : (
            <Title color={theme.main.primary}>{translate('phoneUpdate.title')}</Title>
          )}

          <IconContainer onClick={helpMeToggle}>
            <Icon hover={true} width={28} height={28} src="help_circle" />
          </IconContainer>
        </HeaderContainer>
      </InnerContainer>
      {isDemo && (
        <DemoModeContainer>
          <Icon width={20} height={20} src={'warning_outline'} />
          <DemoModeText>
            <div
              dangerouslySetInnerHTML={{
                __html: translate('membershipHeader.demoMode'),
              }}
            />
          </DemoModeText>
        </DemoModeContainer>
      )}
      <ProgressContainer>
        {isProgressVisible && (
          <ThemeProvider theme={progressTheme}>
            <ProgressInnerContainer>
              <LinearProgress variant="determinate" value={getProgressValue()} />
            </ProgressInnerContainer>
          </ThemeProvider>
        )}
      </ProgressContainer>
    </Container>
  );
};

const Container = styled.div`
  width: 100%;
  z-index: 100;
`;

const InnerContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  background-color: ${(props) => props.theme.main.white};
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  box-shadow: inset 0px -1px 0px #dadada;
  width: 100%;
  height: 80px;

  @media (max-width: ${(props) => props.theme.size.mobileXl}) {
    height: 70px;
  }
`;

const HeaderContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: calc((100% - 48px));
`;

const ProgressContainer = styled.div``;

const DemoModeContainer = styled.div`
  display: flex;
  align-items: center;
  background: ${(props) => props.theme.main.demoModeColor};
  padding: ${(props) => props.theme.constants.s} ${(props) => props.theme.constants.l};
`;

const DemoModeText = styled.span`
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
  margin-left: 10px;
`;

const InnerTitle = styled.span`
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: ${(props) => props.theme.main.grayColor};
`;

const HeaderTitle = styled.span`
  font-weight: 700;
  font-size: 20px;
  line-height: 27px;
  color: ${(props) => props.theme.main.midnightBlue};

  @media (max-width: ${(props) => props.theme.size.mobileXl}) {
    font-size: 16px;
    line-height: 22px;
  }
`;

const IconContainer = styled.div`
  width: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`;

const TitleContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const ProgressInnerContainer = styled.div`
  width: 100%;
`;

const BackIcon = styled.img`
  cursor: pointer;
  width: 25px;
  height: 25px;

  @media (max-width: ${(props) => props.theme.size.mobileXl}) {
    width: 23px;
    height: 23px;
  }
`;
