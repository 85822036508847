import { APP_ENV } from 'config';

export enum AnalyticEventNames {
  LOGIN_CLICK = 'LOGIN_CLICK',
  PI_SEND_CODE = 'PI_SEND_CODE',
  PI_PHONE_NUM_BLUR = 'PI_PHONE_NUM_BLUR',
  RESEND_CODE = 'RESEND_CODE',
  NOT_YOUR_NUMBER = 'NOT_YOUR_NUMBER',
  CHANGE_LANGUAGE = 'CHANGE_LANGUAGE',
  BORROWER_NOT_FOUND = 'BORROWER_NOT_FOUND',
  VC_CODE_BLUR = 'VC_CODE_BLUR',
  MENU_ITEM_CLICK = 'MENU_ITEM_CLICK',
  LOGOUT = 'LOGOUT',
  CHANGE_LANGUAGE_MENU = 'CHANGE_LANGUAGE_MENU',
  SUPPORT_EMAIL_CLICK = 'SUPPORT_EMAIL_CLICK',
  SUPPORT_NUMBER_CLICK = 'SUPPORT_NUMBER_CLICK',
  CON_SHOW_DETAILS = 'CON_SHOW_DETAILS',
  CON_HIDE_DETAILS = 'CON_HIDE_DETAILS',
  CON_ACTIVE_CONTRACTS = 'CON_ACTIVE_CONTRACTS',
  CON_PAST_CONTRACTS = 'CON_PAST_CONTRACTS',
  CON_MAKE_A_PAYMENT = 'CON_MAKE_A_PAYMENT',
  CON_AGREEMENT_LINK = 'CON_AGREEMENT_LINK',
  FAQ_ITEM_EXPAND = 'FAQ_ITEM_EXPAND',
  MF_AGREEMENT_LINK = 'MF_AGREEMENT_LINK',
  MF_LOAD_MORE = 'MF_LOAD_MORE',
  ACC_NPM_CANCEL = 'ACC_NPM_CANCEL',
  ACC_NPM_ADD = 'ACC_NPM_ADD',
  ACC_NPM_ERROR = 'ACC_NPM_ERROR',
  ACC_TRASH_CLICK = 'ACC_TRASH_CLICK',
  ACC_MODAL_YES = 'ACC_MODAL_YES',
  ACC_MODAL_CANCEL = 'ACC_MODAL_CANCEL',
  ACC_NPM_INFORMATION = 'ACC_NPM_INFORMATION',
  ACC_PM_DROPDOWN = 'ACC_PM_DROPDOWN',
  ACC_ADD_CARD_INP_BLUR = 'ACC_ADD_CARD_INP_BLUR',
  ACC_EMAIL_UPDATE = 'ACC_EMAIL_UPDATE',
  ACC_EMAIL_UPDATE_MODAL = 'ACC_EMAIL_UPDATE_MODAL',
  ACC_EMAIL_INPUT_BLUR = 'ACC_EMAIL_INPUT_BLUR',
  ACC_EMAIL_CANCEL = 'ACC_EMAIL_CANCEL',
  PT_PAY_CURRENT_AMOUNT = 'PT_PAY_CURRENT_AMOUNT',
  PT_PAY_OUTSTAND_AMOUNT = 'PT_PAY_OUTSTAND_AMOUNT',
  PT_PAY_OTHER_AMOUNT = 'PT_PAY_OTHER_AMOUNT',
  PT_POA_INPUT_BLUR = 'PT_POA_INPUT_BLUR',
  PT_SELECT_PM = 'PT_SELECT_PM',
  PT_AMOUNT_ERROR = 'PT_AMOUNT_ERROR',
  PM_EDIT_PM = 'PM_EDIT_PM',
  PM_ADD_PM = 'PM_ADD_PM',
  PM_TYPE_SELECT = 'PM_TYPE_SELECT',
  MP_AGREEMENT_LINK = 'MP_AGREEMENT_LINK',
  MP_SEE_TERMS = 'MP_SEE_TERMS',
  MP_DISC_CHECKBOX = 'MP_DISC_CHECKBOX',
  MP_MAKE_PAYMENT = 'MP_MAKE_PAYMENT',
  MP_PAYMENT_ERROR = 'MP_PAYMENT_ERROR',
  MF_CREDIT_DISC = 'MF_CREDIT_DISC',
  PDF_LOAD_MORE = 'MPDF_LOAD_MORE',
}

export const useAnalytics = () => {
  const trackEvent = (eventName: AnalyticEventNames, eventLabel?: string) => {
    // @ts-ignore
    window.gtag('event', eventName, {
      event_category: APP_ENV || '',
      event_label: eventLabel || null,
      event_action: '',
      event_value: null,
    });
  };

  return { trackEvent };
};
