interface SegmentEventApplicationData {
  id: string;
  status: string;
}

interface SegmentEventBorrowerData {
  id: string;
  phone: string;
}
export const getApplicationDetailData = (
  borrower: SegmentEventBorrowerData,
  application: SegmentEventApplicationData,
) => {
  return {
    patientId: borrower?.id,
    phone: borrower?.phone,
    applicationId: application?.id,
    applicationStatus: application?.status,
  };
};
