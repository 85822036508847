import { Body, theme } from '@frontend/cherry-library';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

export const AddressSection = ({ borrower }) => {
  const { t: translate } = useTranslation();

  return (
    <AddressContainer>
      {borrower?.address ? (
        <>
          <Body fontWeight={400} color={theme.main.midnightBlue}>
            {borrower.address.city + ', ' + borrower.address?.state?.name + ' ' + borrower.address.zip}
          </Body>
        </>
      ) : (
        <Body color={theme.main.midnightBlue}>{translate('account.contact.address.notExists')}</Body>
      )}
    </AddressContainer>
  );
};

const AddressContainer = styled.div`
  display: flex;
  flex-direction: column;
`;
