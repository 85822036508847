import * as Sentry from '@sentry/react';
import * as SentryBrowser from '@sentry/browser';

import React from 'react';
import { createRoot } from 'react-dom/client';
import { ThemeProvider } from 'styled-components';

import './i18n';
import App from './App';
import './index.css';

import { theme } from 'config/theme';
import { APP_ENV, SENTRY_DSN, RELEASE_COMMIT_ID } from 'config';
import { FeatureFlagsContextProvider } from 'lib/hooks/FeatureManagement/FlagsContext';
const container = document.getElementById('root');
const root = createRoot(container!);

Sentry.init({
  release: RELEASE_COMMIT_ID,
  dsn: SENTRY_DSN,
  integrations: [new SentryBrowser.BrowserTracing()],
  tracesSampleRate: 1.0,
  environment: APP_ENV,
  ignoreErrors: [/Network Error/gm],
  beforeSend(event: Sentry.Event, hint?: Sentry.EventHint): Sentry.Event | null {
    event.extra = event.extra || {};
    if (hint?.originalException != null) {
      // Assign enumerable error properties to extras
      try {
        const keys = Object.keys(hint.originalException);
        if (keys.length) {
          event.extra.errorProperties = {};
          keys.forEach((key: string) => {
            // @ts-ignore // sentry
            event.extra.errorProperties[key] = hint.originalException[key];
          });
        }
      } catch (error) {
        console.warn('[sentry] Failed to assign enumerable error properties to extras', error);
      }
    }

    return event;
  },
});

root.render(
  <React.StrictMode>
    {/*// @ts-ignore*/}
    <ThemeProvider theme={theme}>
      <Sentry.ErrorBoundary fallback={<p>An error has occurred</p>}>
        <FeatureFlagsContextProvider>
          <App />
        </FeatureFlagsContextProvider>
      </Sentry.ErrorBoundary>
    </ThemeProvider>
  </React.StrictMode>,
);
