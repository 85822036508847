import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Accordion, AccordionDetails, AccordionSummary } from '@mui/material';
import React, { memo, useState } from 'react';
import styled from 'styled-components';
import { theme } from '@frontend/cherry-library';
import { useSegment } from 'lib/hooks/useSegment';
import useStore from 'lib/hooks/useStore';
import { getApplicationDetailData } from 'lib/utils/ApplicationDetailSegmentEventData';

interface IFAQ {
  title: string;
  content: string;
}

interface Props {
  data: Array<IFAQ>;
  segmentEventId?: number;
}

const FaqAccordion = memo(({ data, segmentEventId }: Props) => {
  const { trackSegmentEvent } = useSegment();
  const [expandedPanels, setExpandedPanels] = useState<number[]>([0]);
  const { borrower, selectedApplication } = useStore();

  const handleAccordionChange = (panelIndex: number) => (event: React.SyntheticEvent, isExpanded: boolean) => {
    if (isExpanded) {
      setExpandedPanels((prev) => [...prev, panelIndex]);
    } else {
      setExpandedPanels((prev) => prev.filter((index) => index !== panelIndex));
    }
    handleSegmentEvents(panelIndex, isExpanded);
  };

  const handleSegmentEvents = (panelIndex: number, isExpanded: boolean) => {
    let segmentEventName;
    const segmentEventData = {
      ...getApplicationDetailData(borrower, selectedApplication),
      expanded: isExpanded,
    };
    switch (segmentEventId) {
      case 0:
        segmentEventName = `PATIENT_PORTAL.DENIAL_DETAILS.FAQ_${panelIndex + 1}_CLICKED`;
        trackSegmentEvent(segmentEventName, segmentEventData);
        break;
      case 1:
        segmentEventName = `PATIENT_PORTAL.APPROVAL_DETAILS.FAQ_${panelIndex + 1}_CLICKED`;
        trackSegmentEvent(segmentEventName, segmentEventData);
        break;
      case 2:
        segmentEventName = `PATIENT_PORTAL.HELP.FAQ${panelIndex + 1}_CLICKED`;
        trackSegmentEvent(segmentEventName, segmentEventData);
        break;
      default:
        break;
    }
  };

  return (
    <AccordionContainer>
      {data?.map((faq, index) => {
        return (
          <Accordion
            key={`faq_${index}`}
            expanded={expandedPanels.includes(index)}
            onChange={handleAccordionChange(index)}
            data-testid="faqContainer"
          >
            <AccordionSummary
              expandIcon={
                <IconContainer>
                  <ExpandMoreIcon />
                </IconContainer>
              }
            >
              <FaqTitle data-testid="faqTitle">{faq?.title}</FaqTitle>
            </AccordionSummary>
            <AccordionDetails>
              <FaqDesc dangerouslySetInnerHTML={{ __html: faq?.content }} />
            </AccordionDetails>
          </Accordion>
        );
      })}
    </AccordionContainer>
  );
});

const IconContainer = styled.div`
  color: ${(props) => props.theme.main.green};
  svg {
    transform: scale(1.3);
  }
`;

const AccordionContainer = styled.div`
  display: flex;
  flex-direction: column;
  background-color: white;
  border-radius: 8px;
  margin-bottom: 16px;
  & > .MuiPaper-root {
    box-shadow: unset;
    & > .MuiButtonBase-root {
      padding: 0px;
      & > .MuiAccordionSummary-content {
        margin: 16px 0px !important;
      }
    }
    .MuiAccordion-region {
      & > .MuiAccordionDetails-root {
        padding-top: 0px !important;
        padding-left: 0px;
      }
    }

    &:first-child.Mui-expanded {
      margin: 0 !important;
      margin-top: -16px !important;
    }

    &:not(:first-child).Mui-expanded {
      margin: 0 !important;
      border-top: 1px solid rgba(0, 0, 0, 0.12) !important;
    }
  }
`;

const FaqTitle = styled.span`
  font-size: 16px;
  font-weight: bold;
  line-height: 22px;
  color: ${(props) => theme.main.midnightBlue};
`;

const FaqDesc = styled.span`
  font-size: 14px;
  font-weight: normal;
  line-height: 19px;
  color: ${(props) => theme.main.midnightBlue};
  margin-top: -10px;
`;

export default React.memo(FaqAccordion);
