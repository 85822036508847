import { Dialog, DialogContent } from '@mui/material';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

interface IProps {
  closeDisableDialog: () => void;
  status: boolean;
}

export const PaymentAutoDialog = ({ status, closeDisableDialog }: IProps) => {
  const { t: translate } = useTranslation();
  return (
    <>
      <BootstrapDialog onClose={closeDisableDialog} open={status}>
        <DialogContent>
          <Title>{translate(`autoPay.disableDialog.title`)}</Title>
          <Description>{translate(`autoPay.disableDialog.description1`)}</Description>
          <Description>{translate(`autoPay.disableDialog.description2`)}</Description>
          <Description shortContent={true}>{translate(`autoPay.disableDialog.contact1`)}</Description>
          <Description shortContent={true}>{translate(`autoPay.disableDialog.contact2`)}</Description>
          <CloseButton onClick={closeDisableDialog}>
            <span>{translate(`autoPay.disableDialog.closeBtn`)}</span>
          </CloseButton>
        </DialogContent>
      </BootstrapDialog>
    </>
  );
};

const Title = styled.div`
  font-size: 18px;
  font-weight: bold;
  margin: 0 0 20px;
  color: ${(props) => props.theme.main.primary};
  font-family: ${(props) => props.theme.fonts.primary};
  text-align: center;
`;

const Description = styled.p<{ shortContent?: boolean }>`
  font-size: 14px;
  line-height: 18px;
  margin-bottom: ${(props) => (props.shortContent ? 5 : 15)}px;
  margin-top: ${(props) => (props.shortContent ? 0 : 15)}px;
  color: ${(props) => props.theme.main.primary};
`;

const CloseButton = styled.div`
  border: 1px solid black;
  border-radius: 4px;
  padding: 8px;
  margin: 20px 0 4px;
  text-align: center;
  cursor: pointer;

  > span {
    font-size: 16px;
    line-height: 20px;
    font-weight: bold;
  }
`;

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiPaper-root': {
    borderRadius: '12px',
  },
  '& .MuiDialogContent-root': {
    padding: '24px',
  },
}));
