import { useMutation } from '@apollo/client';
import { useAlert } from 'react-alert';
import { useTranslation } from 'react-i18next';

import { UPDATE_BORROWER } from 'lib/graphql/mutations';

export const useUpdateBorrower = () => {
  const alert = useAlert();
  const { t } = useTranslation();

  return useMutation(UPDATE_BORROWER, {
    fetchPolicy: 'network-only',
    onError: (error) => alert.error(t(`error.${error}`)),
  });
};
