import { useEffect } from 'react';
import styled from 'styled-components';

import { API_URL } from 'config';
import { useSentry } from 'lib/hooks/useSentry';

export const BankConnect = ({ plaidUrl, handleClose, onConnect, onIframeLoaded }) => {
  const { captureException } = useSentry();
  useEffect(() => {
    window.addEventListener('message', receiveMessage, false);
    return () => {
      window.removeEventListener('message', receiveMessage);
    };
  }, []);

  const receiveMessage = ({ origin, data }) => {
    try {
      if (origin === API_URL) {
        const iFrameEvent = JSON.parse(data);

        switch (iFrameEvent.event) {
          case 'expired':
            break;
          case 'close':
            handleClose();
            break;
          case 'complete':
            onConnect(iFrameEvent);
            handleClose();
            break;
          case 'loading':
            break;
          case 'loaded':
            onIframeLoaded();
            break;
          case 'plaid-event':
          case 'plaid-error':
            onIframeLoaded();
            break;
        }
      }
    } catch (err) {
      onIframeLoaded();
      captureException(err, { errorContext: 'receiveMessage => BankConnect', data });
    }
  };

  return (
    <Container>
      <iframe data-testid="the-frame" title={'Connect Bank Account'} width="100%" height="100%" src={plaidUrl} />
    </Container>
  );
};

const Container = styled.div`
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 999;
`;
