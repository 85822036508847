import { useSentry } from 'lib/hooks/useSentry';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Loading } from 'lib/components';
import { approvalPagination } from 'lib/graphql/paginations';
import { expiredApprovalSearch } from 'lib/graphql/searches';
import { useApplicationList } from 'lib/hooks/useApproval';
import { SegmentPageNames, useSegment } from 'lib/hooks/useSegment';
import useStore from 'lib/hooks/useStore';
import StorageService from 'lib/services/Storage';
import { calculateExpireDay, SortByDate, SortByExpireDate } from 'lib/utils';
import { ExpiredApplication, UnfinishedCard } from './components';
import { ApplicationList } from './type';
import { RoundedContainer } from 'lib/components/RoundedContainer';
import { ApprovedApplication } from './components/ApprovedApplication';
import { FindPracticeCard } from 'lib/components/FindPracticeCard';
import { DeniedApplication } from './components/DeniedApplication';
import { SEGMENT_EVENT_NAMES } from 'lib/constans';

const Approvals = () => {
  const { t: translate } = useTranslation();

  const { applicationList, borrower, setHeaderTitle, setSelectedApplication } = useStore();
  const { captureException } = useSentry();
  const { trackPage } = useSegment();

  const [fetchApplicationList, { loading }] = useApplicationList();
  const unfinishedStatuses = [
    'INCOME_VERIFICATION',
    'AWAITING_KYC',
    'AWAITING_DOCS',
    'AWAITING_FRAUD',
    'AWAITING_DEFAULT_HIGH_LINE',
  ];

  useEffect(() => {
    setHeaderTitle('menu.listItems.applications');
  }, [setHeaderTitle]);

  useEffect(() => {
    trackPage(SEGMENT_EVENT_NAMES.PATIENT_PORTAL.APPLICATIONS_OVERVIEW.PAGE_LOAD);
    window.scrollTo(0, 0);
    StorageService.setHasApproval(true);
    setSelectedApplication(null);
    if (borrower?.id) {
      fetchApplicationList({
        variables: {
          input: {
            search: expiredApprovalSearch(borrower?.id),
            pagination: approvalPagination,
          },
        },
      });
    } else {
      captureException(borrower, { errorContext: 'fetchApplicationList => Approvals.tsx' });
    }
  }, []);

  const applicationLists: Array<ApplicationList> = [];

  const completedApprovalList = SortByExpireDate(applicationList)?.filter(
    (a) => a?.status === 'APPROVED' && calculateExpireDay(a?.expireAt) > 0,
  );

  const completedApplicationsOrganizationIds = [
    ...new Set([...completedApprovalList.map((approval) => approval?.organization?.id)]),
  ];

  const unfinishedApprovalList = SortByDate(applicationList, 'createdAt', 'desc')?.filter(
    (appList) => unfinishedStatuses.includes(appList.status) && calculateExpireDay(appList?.expireAt) > 0,
  );

  const expiredApprovalList = SortByDate(applicationList, 'createdAt', 'desc')?.filter((appList) => {
    const isNotIncludedInCompleted = !completedApplicationsOrganizationIds.includes(appList?.organization?.id);
    return (
      (appList?.status === 'APPROVED' && calculateExpireDay(appList.expireAt) < 1 && isNotIncludedInCompleted) ||
      (appList?.status === 'DENIED' && isNotIncludedInCompleted)
    );
  });

  unfinishedApprovalList?.length > 0 &&
    applicationLists.push({
      type: 'unfinishedApproval',
      title: 'approvalsPage.application.unfinished',
      children: (
        <>
          {unfinishedApprovalList?.map((list, index) =>
            list.organization ? (
              <UnfinishedCard key={`unfinished-card-${list.id}`} index={index} application={list} />
            ) : null,
          )}
        </>
      ),
    });

  completedApprovalList?.length > 0 &&
    applicationLists.push({
      type: 'completedApproval',
      title: 'applicationsPage.completedCard.title',
      children: (
        <>
          {completedApprovalList?.map((list, index) => (
            <ApprovedApplication key={`approved-card-${list.id}`} index={index} approval={list} />
          ))}
        </>
      ),
    });

  expiredApprovalList?.length > 0 &&
    applicationLists.push({
      type: 'expiredApproval',
      title: 'applicationsPage.title.inactive',
      children: (
        <>
          {expiredApprovalList?.map((list, index) =>
            list?.status === 'APPROVED' ? (
              <ExpiredApplication key={`expired-card-${list?.id}`} index={index} approval={list} />
            ) : (
              <DeniedApplication key={`denied-card-${list?.id}`} index={index} approval={list} />
            ),
          )}
        </>
      ),
    });

  const showFinder =
    applicationLists?.length === 0 || (completedApprovalList?.length === 0 && unfinishedApprovalList?.length > 0);

  if (loading) {
    return <Loading transparent={true} />;
  }

  return (
    <>
      {!loading && (
        <>
          {applicationLists.map((application) => (
            <RoundedContainer
              key={`application-card-${application?.type}`}
              title={translate(application?.title)}
              noData={false}
              minHeight="unset"
            >
              <>{application.children}</>
            </RoundedContainer>
          ))}
        </>
      )}

      {applicationLists?.length === 0 && (
        <>
          <RoundedContainer title="" noDataText={translate('approvalsPage.noApplications')} noData={true} />
        </>
      )}

      {showFinder && <FindPracticeCard isApprovalsPage={true} />}
    </>
  );
};

export default Approvals;
