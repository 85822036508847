import { LocalizationProvider, DesktopDatePicker } from '@mui/x-date-pickers';
import dayjs from 'dayjs';
import { useEffect, useState } from 'react';
import { useAlert } from 'react-alert';
import { useTranslation } from 'react-i18next';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { usePatchExtendApproval } from 'lib/hooks/useExtendApprovals';
import { ButtonContainer } from 'pages/ExtendApproval/style';
import { Approval, ScreenState } from 'pages/ExtendApproval/type';
import DateInput from './DateInput';
import { Body, Button } from '@frontend/cherry-library';
import { useSegment } from 'lib/hooks/useSegment';
import { SEGMENT_EVENT_NAMES } from 'lib/constans';
import { useNavigate } from 'react-router-dom';
import { getApplicationDetailData } from 'lib/utils/ApplicationDetailSegmentEventData';
import useStore from 'lib/hooks/useStore';

interface Props {
  approval: Approval;
  setScreenState: (value: ScreenState) => void;
  setExtendedApprovalId: (value: string) => void;
}

export const AppointmentSelection = ({ approval, setScreenState, setExtendedApprovalId }: Props) => {
  const navigate = useNavigate();
  const { t: translate } = useTranslation();
  const alert = useAlert();
  const { borrower } = useStore();
  const { trackPage, trackSegmentEvent, standardSegmentMetadata } = useSegment();
  const applicationSegmentData = getApplicationDetailData(borrower, approval);
  const pageSegmentEventData = { ...standardSegmentMetadata, ...applicationSegmentData };

  const [patchExtendApproval, { data, loading }] = usePatchExtendApproval();

  const { expireAt, id } = approval;
  const [dateValue, setDateValue] = useState('');
  const [isDatePickerOpened, setIsDatePickerOpened] = useState(false);

  useEffect(() => {
    trackPage(SEGMENT_EVENT_NAMES.PATIENT_PORTAL.REQUEST_MORE_TIME.APPOINTMENT_DATE_PAGE_LOAD, pageSegmentEventData);
  }, []);

  useEffect(() => {
    if (data?.patchExtendApproval?.id) {
      const selectedDate = dayjs(dateValue);
      const expireDate = dayjs(expireAt);
      setExtendedApprovalId(data?.patchExtendApproval?.id);

      if (selectedDate.diff(expireDate, 'd') >= 30) {
        setScreenState(ScreenState.APPROVAL_APPOINTMENT);
      } else {
        setScreenState(ScreenState.EXTEND_SUCCESS);
      }
    } else {
      alert.error(data?.patchExtendApproval?.message);
    }
  }, [data]);

  const goBack = () => {
    trackSegmentEvent(
      SEGMENT_EVENT_NAMES.PATIENT_PORTAL.REQUEST_MORE_TIME.APPOINTMENT_DATE_BACK_CLICKED,
      pageSegmentEventData,
    );
    navigate(-1);
  };

  const continueButtonHandler = () => {
    trackSegmentEvent(
      SEGMENT_EVENT_NAMES.PATIENT_PORTAL.REQUEST_MORE_TIME.APPOINTMENT_DATE_CONTINUE_CLICKED,
      pageSegmentEventData,
    );
    patchExtendApproval({ variables: { input: { applicationId: id, appointmentDate: dateValue } } });
  };

  const handleDateChange = (value) => {
    setDateValue(dayjs(value).format('YYYY-MM-DD'));
  };

  const toggleDatePicker = () => {
    setIsDatePickerOpened(!isDatePickerOpened);
  };

  const closeDatePicker = () => {
    setIsDatePickerOpened(false);
  };

  return (
    <>
      <Body fontWeight={400}> {translate(`appointmentSelection.header`)}</Body>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <DesktopDatePicker
          value={dateValue}
          open={isDatePickerOpened}
          onChange={handleDateChange}
          onClose={closeDatePicker}
          defaultCalendarMonth={dayjs(expireAt)}
          minDate={dayjs(expireAt).add(1, 'day')}
          renderInput={({ inputRef, inputProps, InputProps }) => (
            <DateInput
              inputRef={inputRef}
              inputProps={inputProps}
              InputProps={InputProps}
              datePickerOpen={toggleDatePicker}
            />
          )}
        />
      </LocalizationProvider>
      <ButtonContainer>
        <Button fullWidth onClick={continueButtonHandler} loading={loading} disabled={loading || !dateValue}>
          {translate('makePayment.paymentType.submitButton')}
        </Button>
        <Button fullWidth onClick={goBack} variant="secondary">
          {translate(`genericButton.back`)}
        </Button>
      </ButtonContainer>
    </>
  );
};
