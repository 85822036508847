import styled from 'styled-components';
import dayjs, { Dayjs } from 'dayjs';

import { Commitment, CommitmentProgress, SettlementFrequency } from 'lib/types';
import { ChargeOffLoanStyles } from 'lib/components';
import { FormatAmount, useFormatDate } from 'lib/utils';
import { useTranslation } from 'react-i18next';
import { RECOVERY_TEAM_SUPPORT_MAIL, RECOVERY_TEAM_SUPPORT_PHONE_NUMBER } from 'lib/constans';
import { theme, Button, Body } from '@frontend/cherry-library';

type ChargeOffLoanPaymentPlanProps = {
  commitments: Commitment[];
};

const { Container, Headline, HeadlineText, Paragraph } = ChargeOffLoanStyles;

const getFrequency = (dateList: Dayjs[]): SettlementFrequency | undefined => {
  if (dateList.length >= 2) {
    const timeGap = dateList[1].diff(dateList[0], 'day');

    if (timeGap >= 7 && timeGap < 14) {
      return SettlementFrequency.Weekly;
    } else if (timeGap >= 14 && timeGap < 28) {
      return SettlementFrequency.BiWeekly;
    } else if (timeGap >= 28) {
      return SettlementFrequency.Monthly;
    }
  }

  return undefined;
};

export const ChargeOffLoanPaymentPlan = ({ commitments }: ChargeOffLoanPaymentPlanProps) => {
  const { t: translate } = useTranslation();
  const { FormatDate } = useFormatDate();

  const numberOfPayments = commitments.length;
  const amountPerPeriod = commitments[0]?.amount;
  const agreementDate = commitments[0]?.createdAt;
  const frequency = getFrequency(commitments.map(({ scheduledDate }) => dayjs(scheduledDate)));
  const frequencyText = {
    [SettlementFrequency.Weekly]: translate('monthlyPaymentPlan.week'),
    [SettlementFrequency.BiWeekly]: translate('monthlyPaymentPlan.weeks'),
    [SettlementFrequency.Monthly]: `2 ${translate('monthlyPaymentPlan.month')}`,
  };

  const paymentScheduleList = commitments
    .sort((a, b) => {
      const timestampA = new Date(a.scheduledDate);
      const timestampB = new Date(b.scheduledDate);

      if (timestampA < timestampB) {
        return -1;
      } else if (timestampA > timestampB) {
        return 1;
      } else {
        return 0;
      }
    })
    .map(({ amount, scheduledDate, progress }, index) => {
      const isPaid = progress === CommitmentProgress.FULFILLED;
      const isNext =
        (index === 0 && progress === CommitmentProgress.PENDING) ||
        (commitments[index - 1]?.progress === CommitmentProgress.FULFILLED && progress === CommitmentProgress.PENDING);

      return (
        <PaymentScheduleListItem key={scheduledDate + progress + amount}>
          <span>{index + 1}</span>
          <ScheduledAmountText isPaid={isPaid} isNext={isNext}>{`${FormatAmount(amount)} on ${dayjs(
            scheduledDate,
          ).format('MMM DD, YYYY')}`}</ScheduledAmountText>
          {isPaid && (
            <ScheduledCommitmentStatusText isPaid={true}>{translate('general.paid')}</ScheduledCommitmentStatusText>
          )}
          {isNext && <ScheduledCommitmentStatusText>{translate('general.next')}</ScheduledCommitmentStatusText>}
        </PaymentScheduleListItem>
      );
    });

  const handleEmailSupport = () => {
    const mailtoUrl = `mailto: ${RECOVERY_TEAM_SUPPORT_MAIL}`;
    window.open(mailtoUrl, '_blank');
  };

  const handlePhoneSupport = () => {
    const telUrl = `tel: ${RECOVERY_TEAM_SUPPORT_PHONE_NUMBER}`;
    window.open(telUrl, '_blank');
  };

  return (
    <TopContainer>
      <Headline>
        <CenteredText>
          <HeadlineText>{translate('chargedOffLoan.yourPaymentPlan')}</HeadlineText>
        </CenteredText>
      </Headline>
      <CenteredText>
        <Paragraph>
          {translate('chargeOff.paymentPlan.title', {
            date: FormatDate(agreementDate),
          })}
        </Paragraph>
      </CenteredText>
      <ButtonContainer>
        <Button variant="secondary" fullWidth onClick={handleEmailSupport}>
          {translate('chargedOffLoan.emailSupport')}
        </Button>
        <Button variant="secondary" fullWidth onClick={handlePhoneSupport}>
          {translate('chargedOffLoan.callSupport')}
        </Button>
      </ButtonContainer>
      <PlansContainer>
        <PlansHeader>
          <div>{translate('chargedOffLoan.payments', { payments: numberOfPayments })}</div>
          <div>
            <FlexContainer>
              <AmountText fontWeight={700} size="large">
                {FormatAmount(amountPerPeriod)}
              </AmountText>
              <Body fontWeight={600} size="small" color={theme.main.midnightBlue60}>
                / {translate('monthlyPaymentPlan.month')}
              </Body>
            </FlexContainer>

            {frequency && <SmallHeaderText> / {frequencyText[frequency]}</SmallHeaderText>}
          </div>
        </PlansHeader>
        <PaymentScheduleList>
          <PaymentScheduleListTitle>{translate('chargedOffLoan.paymentSchedule')}</PaymentScheduleListTitle>
          {paymentScheduleList}
        </PaymentScheduleList>
      </PlansContainer>
    </TopContainer>
  );
};

const TopContainer = styled(Container)`
  display: flex;
  flex-direction: column;
  gap: 16px;

  p {
    margin: 0;
  }
`;

const CenteredText = styled.div`
  text-align: center;
  margin: 0 auto;
`;

const ButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

const PlansContainer = styled.div`
  padding: 24px 16px;
  border-radius: 8px;
  background-color: ${theme.main.white};
  border: 1px solid ${theme.main.midnightBlue20};
`;

const PlansHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 16px;
  margin-bottom: 16px;
  border-bottom: 1px solid ${theme.main.neutralGray};
  color: ${theme.main.midnightBlue60};
  font-weight: 600;
`;

const AmountText = styled(Body)`
  color: ${theme.main.midnightBlue};
`;

const SmallHeaderText = styled.span`
  font-size: 12px;
`;

const PaymentScheduleList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const PaymentScheduleListTitle = styled.div`
  text-transform: uppercase;
  font-size: 14px;
  font-weight: 700;
`;

const PaymentScheduleListItem = styled.div`
  font-size: 14px;

  * {
    display: inline-block;
  }

  span:first-of-type {
    font-weight: 700;
    margin-right: 8px;
  }

  span:last-of-type {
    font-style: italic;
  }
`;

const ScheduledAmountText = styled.span<{ isPaid?: boolean; isNext?: boolean }>`
  text-decoration: ${(props) => props.isPaid && 'line-through'};
  font-weight: ${(props) => (props.isNext ? 700 : 400)};
  color: ${(props) => (props.isPaid ? theme.main.midnightBlue50 : theme.main.midnightBlue)};
`;

const ScheduledCommitmentStatusText = styled.span<{ isPaid?: boolean }>`
  color: ${(props) => (props.isPaid ? theme.main.kellyGreen : theme.main.midnightBlue)};
  font-weight: 700;
  font-style: italic;
  display: inline-block;
  margin-left: 8px;
  text-transform: uppercase;
`;

const FlexContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 3px;
`;
