import { memo } from 'react';
import styled from 'styled-components';

interface Props {
  transparent?: boolean;
}

interface SCLoadingInput {
  transparent?: boolean;
}

export const Loading = memo(({ transparent = false }: Props) => {
  return (
    <Container transparent={transparent}>
      <LoadingGif src="/loader-animated.svg" alt="loader" />
    </Container>
  );
});

const Container = styled.div<SCLoadingInput>`
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${(props) => (props.transparent ? 'transparent' : 'white')};
  z-index: 999;
`;

const LoadingGif = styled.img`
  width: 100px;
`;
