import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import Rox from 'rox-browser';

import { Button, Container, Input } from 'lib/components';
import { AnalyticEventNames, useAnalytics } from 'lib/hooks/useAnalytics';
import { SegmentEventNames, SegmentPageNames, useSegment } from 'lib/hooks/useSegment';
import { useSentry } from 'lib/hooks/useSentry';
import useStore from 'lib/hooks/useStore';
import { useLogin, useOtp, useVoiceCall } from 'lib/hooks/useUser';
import StorageService from 'lib/services/Storage';
import useLandingPage from 'lib/hooks/useLandingPage';
import { useUpdateBorrower } from 'lib/hooks/useAccount';
import { SOURCE_TYPES } from 'lib/constans';
import { updateChatWidgetAfterLogin } from 'lib/utils/ChatWidget';
import { useFlags } from 'lib/hooks/FeatureManagement/FlagsContext';
import { useIVRTokenExchange } from 'lib/hooks/useIVRTokenExchange';

interface BorrowerProps {
  id: string;
  phone: string;
}

const Otp = () => {
  const { t: translate } = useTranslation();
  const navigate = useNavigate();
  const { trackEvent } = useAnalytics();
  const { setUser } = useSentry();
  const [updateBorrower] = useUpdateBorrower();

  const {
    setOtp,
    otpNumber,
    phoneNumber,
    setBorrower,
    fallbackUrl,
    setFallbackUrl,
    signOut,
    isLanguageChange,
    defaultLanguage,
    setDefaultLanguage,
    deepLinkUrl,
  } = useStore();

  const [verifyOtpRequest, { data: otpData, loading }] = useOtp();
  const [requestLogin] = useLogin();
  const [voiceCall] = useVoiceCall();
  const { trackPage, trackSegmentEvent, trackIdentify } = useSegment();
  const { checkRouter } = useLandingPage();
  const { flags } = useFlags();

  const rawPhoneNumber: string = phoneNumber?.replace(/[^0-9]/gi, '');
  const buttonDisable = otpNumber.length !== 4;

  const [ivrTokenExchange] = useIVRTokenExchange();

  useEffect(() => {
    if (rawPhoneNumber?.length !== 10) {
      navigate('/');
    }

    trackPage(SegmentPageNames.OTP);
  }, []);

  useEffect(() => {
    if (otpData?.verifyOtpRequest?.success) {
      const { authorization, borrower } = otpData?.verifyOtpRequest || {};
      StorageService.setAuthData(authorization?.token, authorization?.refreshToken);
      if (isLanguageChange && borrower) {
        updateBorrower({
          variables: {
            input: {
              id: borrower?.idBorrower || borrower?.id,
              language: defaultLanguage?.toUpperCase(),
              source: SOURCE_TYPES.GREET,
            },
          },
        });
      }
      getBorrowerData(borrower);

      ivrTokenExchange().then((response) => {
        const { data: { ivrTokenExchange: { ivrToken = null } = {} } = {} } = response;
        updateChatWidgetAfterLogin(borrower?.phone, borrower?.firstName, ivrToken);
      });

      isLanguageChange === false && setDefaultLanguage(borrower.language.toLowerCase());
      Rox.setCustomNumberProperty('borrower_id', Number(borrower?.id));
      Rox.setCustomStringProperty('rox.distinct_id', borrower?.id);
      Rox.setCustomStringProperty('borrower_id_str', borrower?.id?.toString());
    }
  }, [otpData, flags]);

  const getBorrowerData = (borrower: BorrowerProps) => {
    try {
      setBorrower(borrower);
      setUser({ id: borrower?.id, phone: borrower?.phone });
      trackSegmentEvent(SegmentEventNames.SIGNED_IN, { borrowerId: borrower?.id });
      trackIdentify({ userId: borrower?.id, ...borrower });
      if (deepLinkUrl) {
        checkRouter();
      } else if (fallbackUrl) {
        setFallbackUrl('');
        navigate(fallbackUrl);
      } else {
        checkRouter();
        navigate('/portal/home');
      }
    } catch (err) {
      signOut();
      navigate('/');
    }
  };

  const navigateHandler = async () => {
    trackEvent(AnalyticEventNames.LOGIN_CLICK);
    await verifyOtpRequest();
  };

  const backHandler = () => {
    trackEvent(AnalyticEventNames.NOT_YOUR_NUMBER);
    trackSegmentEvent(SegmentEventNames.NOT_YOUR_NUMBER, { old_number: rawPhoneNumber });
    navigate(-1);
  };

  const resendCode = async () => {
    trackEvent(AnalyticEventNames.RESEND_CODE);
    trackSegmentEvent(SegmentEventNames.RESEND_CODE);
    await requestLogin();
  };

  const resendAudioCode = async () => {
    trackEvent(AnalyticEventNames.RESEND_CODE);
    trackSegmentEvent(SegmentEventNames.OTP_CALL);
    await voiceCall();
  };

  const onChangeHandler = (value: string) => {
    setOtp(value);
  };

  const onBlurHandler = () => {
    trackEvent(AnalyticEventNames.VC_CODE_BLUR);
  };

  const onKeyDown = async (event: React.KeyboardEvent<HTMLDivElement>) => {
    if (event.key === 'Enter' && !buttonDisable) {
      event.preventDefault();
      event.stopPropagation();
      await navigateHandler();
    }
  };

  return (
    <Container>
      <Title>{translate('login.info')}</Title>
      <PhoneText>{phoneNumber}</PhoneText>
      <BackText onClick={backHandler}>{translate('login.back')}</BackText>
      <Input
        data-testid="otp"
        type="tel"
        secondary={false}
        onChange={onChangeHandler}
        onBlur={onBlurHandler}
        onKeyDown={onKeyDown}
        value={otpNumber}
        label={'login.form.code.label'}
        mask={'0000'}
        autoFocus={true}
        autoComplete="one-time-code"
      />
      <ButtonContainer>
        <Button disabled={buttonDisable || loading} loading={loading} onClick={navigateHandler}>
          {translate('login.form.submitBtn')}
        </Button>
        <ResendCode onClick={resendCode}>{translate('login.resend')}</ResendCode>
        <ResendCode onClick={resendAudioCode}>{translate('login.callMeWithCodeInstead')}</ResendCode>
      </ButtonContainer>
    </Container>
  );
};

const Title = styled.p`
  font-size: 18px;
  line-height: 28px;
  text-align: center;
  font-weight: 100;
  margin: 0;
`;

const PhoneText = styled.p`
  font-size: 18px;
  line-height: 28px;
  text-align: center;
  font-weight: 700;
  margin: 0;
`;

const BackText = styled.p`
  font-size: 15px;
  line-height: 28px;
  text-align: center;
  font-weight: 700;
  text-decoration: underline;
  cursor: pointer;
  margin: 0 0 20px 0;
`;

const ButtonContainer = styled.div`
  margin-top: 20px !important;
`;

const ResendCode = styled.span`
  text-decoration: underline;
  cursor: pointer;
  display: flex;
  justify-content: center;
  margin-bottom: 5px;
`;

export default Otp;
