import LinearProgress, { linearProgressClasses } from '@mui/material/LinearProgress';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { Body, Heading, theme } from '@frontend/cherry-library';

import { FormatAmount, isArray } from 'lib/utils';

interface IProps {
  isClosed: boolean;
  isPaymentPastDue: boolean;
  loan: any;
  remaining: number;
  paidPrice: number;
}

interface SCProgressProps {
  hasMargin: boolean;
}

export const Progress = ({ isClosed, isPaymentPastDue, loan, remaining, paidPrice }: IProps) => {
  const { t: translate } = useTranslation();
  const { plans, amount, downPaymentAmount, grossAmount } = loan;

  const totalPrice = grossAmount;
  const coveredPercentage = (paidPrice / totalPrice) * 100;

  const progressBarColor = () => {
    if (isPaymentPastDue) {
      return theme.main.cherryRed;
    } else {
      return theme.main.kellyGreen;
    }
  };

  return (
    <ProgressBarContainer>
      <ProgressBarHeaderContainer hasMargin={!isClosed}>
        <ProgressBarHeader>
          <Body size="small">{translate('contracts.past.progressBar.label')}</Body>
          <Heading level="5" text={FormatAmount(paidPrice)}></Heading>
        </ProgressBarHeader>
        <ProgressBarHeader />
        <ProgressBarHeader>
          <Body size="small">{translate('progress.remaining')}</Body>
          <Heading level="5" text={FormatAmount(remaining)}></Heading>
        </ProgressBarHeader>
      </ProgressBarHeaderContainer>

      <BorderLinearProgress
        variant="determinate"
        value={coveredPercentage > 100 ? 100 : coveredPercentage}
        colored={progressBarColor()}
      />
    </ProgressBarContainer>
  );
};

const BorderLinearProgress = styled(LinearProgress)<{ colored: string }>(({ colored }) => ({
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor: theme.main.midnightBlue20,
    height: 8,
  },
  [`& .${linearProgressClasses.bar}`]: {
    backgroundColor: colored,
  },
}));

const ProgressBarContainer = styled.div`
  width: 100%;
`;

const ProgressBarHeaderContainer = styled.div<SCProgressProps>`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  div {
    margin-top: ${(props) => (props.hasMargin ? props.theme.constants.l : 0)};
  }
`;

const ProgressBarHeader = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  margin-top: 24px !important;
  &:nth-child(2) {
    align-items: center;
  }

  &:nth-child(3) {
    align-items: flex-end;
  }

  > h5 {
    margin-bottom: 8px;
  }
`;
