import { ReactNode } from 'react';
import styled from 'styled-components';
import { theme } from '@frontend/cherry-library';

interface CardContainerProps {
  children: ReactNode;
  topRadius?: boolean;
  minHeight?: string;
  noPadding?: boolean;
}

export const CardContainer = ({ children, topRadius, minHeight = '', noPadding = false }: CardContainerProps) => {
  return (
    <Container topRadius={topRadius} minHeight={minHeight} noPadding={noPadding}>
      {children}
    </Container>
  );
};

const Container = styled.div<{ topRadius?: boolean; minHeight?: string; noPadding?: boolean }>`
  padding: ${(props) => (props.noPadding ? '0' : '16px')};
  display: flex;
  flex-direction: column;
  background-color: ${theme.main.white};
  border-radius: ${(props) => (props.topRadius ? '0 0 12px 12px' : '12px')};
  min-height: ${(props) => props.minHeight ?? undefined};
  box-shadow: 0px 6px 9px rgba(0, 0, 0, 0.07), 0px 0.751293px 1.12694px rgba(0, 0, 0, 0.035);
  position: relative;
  overflow: hidden;
  height: auto;

  justify-content: center;
  align-items: flex-start;
  gap: 16px;
  align-self: stretch;
`;
