import WarningIcon from '@mui/icons-material/Warning';
import CheckIcon from '@mui/icons-material/Check';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';

import { useEffect, useState } from 'react';
import styled from 'styled-components';
import { theme } from 'config/theme';
import { useFormatDate, convertNewlinesToBr } from 'lib/utils';
import { useApolloClient } from '@apollo/client';
import { GET_DOCUMENT_REQUEST_FILES, GET_UPLOADED_FILE_FOR_REQUEST } from 'lib/graphql/queries';
import { DOCUMENT_STATUS } from 'lib/constans';
import { useTranslation } from 'react-i18next';

import { Heading, Button } from '@frontend/cherry-library';

export interface DocumentRequest {
  documentTitle: string;
  externalNote?: string;
  submittedBy?: string;
  updatedAt?: string;
  id: string;
}

interface DocumentRequestItemProps {
  documentRequest: DocumentRequest;
  status: string;
  uploadDocument?: (id: string) => void;
  externalUserName?: string;
}

const DocumentRequest = ({ documentRequest, status, uploadDocument, externalUserName }: DocumentRequestItemProps) => {
  const [isExpanded, setIsExpanded] = useState<boolean>(false);
  const client = useApolloClient();
  const [submittedFileList, setSubmittedFileList] = useState([]);
  const isSubmitted = status === DOCUMENT_STATUS.submitted;
  const isNeeded = status === DOCUMENT_STATUS.needed;
  const { t: translate } = useTranslation();
  const { FormatDate } = useFormatDate();

  useEffect(() => {
    if (status === DOCUMENT_STATUS.submitted) {
      getFileList();
    }
  }, [status]);

  const handleSubmitDocumentButton = () => {
    uploadDocument?.(documentRequest.id);
  };

  const handleToggleButton = () => {
    setIsExpanded(!isExpanded);
  };
  const handlePreviewFile = async (id: string) => {
    const response = await client.query({
      query: GET_UPLOADED_FILE_FOR_REQUEST,
      variables: {
        input: {
          documentRequestId: documentRequest.id,
          id,
        },
      },
    });

    const { data, mime: fileMime, name } = response?.data?.getUploadedFile;
    const a = document.createElement('a');
    a.href = `data:image/png;base64,${data}`;
    const mime = fileMime.split('/')[1];
    a.download = `${name}.${mime}`;
    a.click();
  };
  const getFileList = async () => {
    const { data } = await client.query({
      query: GET_DOCUMENT_REQUEST_FILES,
      variables: {
        input: {
          documentRequestId: documentRequest.id,
          pagination: {
            offset: 0,
            limit: 9999,
          },
          search: [
            {
              key: 'relationsType',
              value: 'DOCUMENT_REQUEST_ID',
            },
            {
              key: 'relationsId',
              value: documentRequest.id,
            },
          ],
        },
      },
    });

    setSubmittedFileList(data?.getDocumentRequestFiles?.contents);
  };

  const formattedExternalNote = { __html: convertNewlinesToBr(documentRequest?.externalNote) || '' };

  return (
    <CardContainer>
      <StatusContainer>
        <IconWrapper>
          {isNeeded ? (
            <WarningIcon
              sx={{
                color: theme.main.red,
                width: '19px',
                height: '19px',
                paddingTop: '5px',
              }}
            />
          ) : (
            <CheckIcon
              sx={{
                color: theme.main.green,
                width: '19px',
                height: '19px',
                paddingTop: '5px',
              }}
            />
          )}
        </IconWrapper>
        {isNeeded ? (
          <StatusLabel level="5" status={status} text={translate('documentRequest.document.status.needed')} />
        ) : null}
        {isSubmitted ? (
          <StatusLabel level="5" status={status} text={translate('documentRequest.document.status.submitted')} />
        ) : null}
      </StatusContainer>

      <Content data-testid="submitted">
        {isSubmitted ? (
          <SubmittedContentHeader>
            <Heading level="4" text={documentRequest.documentTitle} />
            <ToggleButton isExpanded={isExpanded}>
              <ToggleExpandMoreIcon onClick={handleToggleButton}></ToggleExpandMoreIcon>
            </ToggleButton>
          </SubmittedContentHeader>
        ) : null}
        {isNeeded ? (
          <NeededContent>
            <NeededContentHeader>
              <Heading level="4" text={documentRequest.documentTitle} />
              <Text dangerouslySetInnerHTML={formattedExternalNote} />
            </NeededContentHeader>
            {isNeeded ? (
              <Button data-testid="submit-document-button" fullWidth onClick={handleSubmitDocumentButton}>
                {translate('documentRequest.submit.document')}
              </Button>
            ) : null}
          </NeededContent>
        ) : null}
        {isSubmitted && isExpanded ? (
          <ExpandedContent>
            <FileList>
              {submittedFileList?.map(({ name, id }, index) => (
                <FileContainer key={index}>
                  <File>{name}</File>
                  <ViewContainer onClick={() => handlePreviewFile(id)}>
                    <ViewText>{translate('general.view')}</ViewText>
                    <OpenInNewIcon
                      sx={{
                        width: '19px',
                        height: '19px',
                        paddingTop: '1px',
                      }}
                    />
                  </ViewContainer>
                </FileContainer>
              ))}
            </FileList>
            <SubmitInfo>
              {translate('documentRequest.document.submitted', {
                date: FormatDate(documentRequest.updatedAt),
                userName: externalUserName,
              })}
            </SubmitInfo>
          </ExpandedContent>
        ) : null}
      </Content>
    </CardContainer>
  );
};

const CardContainer = styled.div`
  background-color: ${(props) => props.theme.main.white};
  box-shadow: 0px 36px 67px 0px rgba(0, 0, 0, 0.07), 0px 13.141px 24.456px 0px rgba(0, 0, 0, 0.05),
    0px 6.38px 11.873px 0px rgba(0, 0, 0, 0.04), 0px 3.127px 5.82px 0px rgba(0, 0, 0, 0.03),
    0px 1.237px 2.301px 0px rgba(0, 0, 0, 0.02);
  margin-top: 16px;
  border-radius: 8px;
  font-size: 14px;
  font-family: ${(props) => props.theme.fonts.primary};
  padding: 24px;
  width: auto;
  gap: 4px;
  display: grid;

  @media (max-width: ${(props) => props.theme.size.mobileXl}) {
    width: unset;
  }
`;
const StatusContainer = styled.div`
  display: flex;
  align-items: center;
`;

const IconWrapper = styled.div`
  margin-right: 8px;
  transform: scale(0.9);
`;
const FileContainer = styled.div`
  display: flex !important;
`;

const StatusLabel = styled(Heading)<{ status: string }>`
  color: ${(props) => (props.status === 'submitted' ? props.theme.main.green : props.theme.main.red)} !important;
  font-size: 16px;
  font-weight: 700;
  line-height: 16px;
`;

const ToggleExpandMoreIcon = styled(ExpandMoreIcon)`
  width: 30px !important;
  height: 30px !important;
  color: black !important;
`;

const ViewContainer = styled.div`
  margin-left: 30px;
  text-decoration: underline;
  display: flex;
  cursor: pointer;
`;

const Content = styled.div`
  width: 100%;
`;

const Text = styled.span`
  color: ${(props) => props.theme.main.primary};
  font-family: 'Open Sans';
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
`;

const ViewText = styled.div`
  padding: 0 5px;
`;

const ToggleButton = styled.span<{ isExpanded: boolean }>`
  border: none;
  background: none;
  cursor: pointer;
  transform: ${(props) => (props.isExpanded ? `rotate(180deg)` : `rotate(0)`)};
  transition: all 0.3s;
  color: black;
`;

const SubmittedContentHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const NeededContentHeader = styled.div`
  width: auto;
  align-items: center;
  gap: 4px;
  display: grid;

  @media (max-width: ${(props) => props.theme.size.mobileXl}) {
    width: 100%;
  }
`;

const NeededContent = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  gap: 16px;

  @media (max-width: ${(props) => props.theme.size.mobileXl}) {
    align-items: flex-start;
  }
`;

const ExpandedContent = styled.div`
  margin-top: 27px;
`;

const SubmitInfo = styled.div`
  color: ${(props) => props.theme.main.midnightBlueGray};
  font-size: 14px;
  font-weight: 400;
`;

const FileList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8.5px;
  margin-bottom: 8.5px;
`;

const File = styled.div`
  color: ${(props) => props.theme.main.black};
  font-size: 14px;
  font-style: italic;
  font-weight: 400;
`;

export default DocumentRequest;
