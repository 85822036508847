import {
  Button,
  ButtonColor,
  Container,
  DesktopDatePicker,
  InnerContainer,
  RadioGroup,
  Stepper,
  Subtitle,
  Title,
} from 'lib/components';
import { GridSelection } from 'lib/components/GridSelection/GridSelection';
import useStore from 'lib/hooks/useStore';
import { FormatAmount, useFormatDate, parseEnumType } from 'lib/utils';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import dayjs from 'dayjs';
import { useNavigate, useParams } from 'react-router-dom';
import { SettlementFrequency, SettlementPaymentTypes } from 'lib/types';
import { useGetChargedOffOffers } from 'lib/hooks/useChargedOffLoan';
import PaymentInstallmentList from 'lib/components/PaymentInstallmentList/PaymentInstallmentList';
import { SEGMENT_EVENT_NAMES, SETTLEMENT_FLOW_STEPPER_CONFIG } from 'lib/constans';
import { useSegment } from 'lib/hooks/useSegment';

enum PaymentTypes {
  Full = 'full',
  OverTime = 'overTime',
}

const Settlement = () => {
  const { t } = useTranslation();
  const { FormatDate } = useFormatDate();
  const navigate = useNavigate();
  const { setHeaderTitle, selectedChargeOffLoan } = useStore();
  const { id } = useParams();
  const { trackSegmentEvent } = useSegment();
  const [getChargedOffOffers, { data, loading }] = useGetChargedOffOffers();

  const [paymentType, setPaymentType] = useState<PaymentTypes | undefined>();
  const [overTimeOption, setOverTimeOption] = useState<SettlementFrequency | undefined>();
  const now = dayjs(new Date());
  const [dateValue, setDateValue] = useState(now.format('YYYY-MM-DD'));
  const [selectedInstallmentAmount, setSelectedInstallmentAmount] = useState<number | null>(1);

  const outstandingBalance = selectedChargeOffLoan?.plans?.[0]?.netChargeOff;
  const frequency = paymentType === PaymentTypes.Full ? undefined : overTimeOption;
  const showInstallmentList = Boolean(
    (paymentType === PaymentTypes.Full || (paymentType === PaymentTypes.OverTime && frequency)) && !!dateValue,
  );
  const { installmentMap, discountPercentage, discountedChargedOffAmount, daysSinceChargeOff } =
    data?.getChargedOffOffers || {};
  const chargedOffDate = dayjs(new Date()).subtract(daysSinceChargeOff, 'days');
  const isContinueButtonEnabled =
    !!dateValue &&
    (paymentType === PaymentTypes.Full ||
      (paymentType === PaymentTypes.OverTime && overTimeOption && selectedInstallmentAmount));
  const amountOfSavedMoney = outstandingBalance - discountedChargedOffAmount;
  const maxDate = dayjs().add(2, 'weeks');

  const options = [
    {
      value: PaymentTypes.Full,
      content: t('settlement.paymentTypeOptions.full'),
    },
    {
      value: PaymentTypes.OverTime,
      content: t('settlement.paymentTypeOptions.overTime'),
    },
  ];

  const overTimeOptions = [
    {
      value: SettlementFrequency.Weekly,
      text: t('settlement.overTimeOptions.weekly'),
    },
    {
      value: SettlementFrequency.BiWeekly,
      text: t('settlement.overTimeOptions.twoWeeks'),
    },
    {
      value: SettlementFrequency.Monthly,
      text: t('settlement.overTimeOptions.monthly'),
    },
  ];

  useEffect(() => {
    trackSegmentEvent(SEGMENT_EVENT_NAMES.PATIENT_PORTAL.SETTLEMENT.PAGE_LOAD);
  }, []);

  useEffect(() => {
    if (frequency === SettlementFrequency.Weekly) {
      trackSegmentEvent(SEGMENT_EVENT_NAMES.PATIENT_PORTAL.SETTLEMENT.WEEKLY_SELECTED);
    } else if (frequency === SettlementFrequency.BiWeekly) {
      trackSegmentEvent(SEGMENT_EVENT_NAMES.PATIENT_PORTAL.SETTLEMENT.BIWEEKLY_SELECTED);
    } else if (frequency === SettlementFrequency.Monthly) {
      trackSegmentEvent(SEGMENT_EVENT_NAMES.PATIENT_PORTAL.SETTLEMENT.MONTHLY_SELECTED);
    }
  }, [frequency]);

  useEffect(() => {
    if (id) {
      getChargedOffOffers({
        variables: {
          input: {
            loanId: Number(id),
          },
        },
      });
    }
  }, [id]);

  useEffect(() => {
    if (overTimeOption) {
      setSelectedInstallmentAmount(null);
    }
  }, [overTimeOption]);

  useEffect(() => {
    setHeaderTitle('makePayment.title');
  }, [setHeaderTitle]);

  useEffect(() => {
    if (paymentType === PaymentTypes.Full) {
      setOverTimeOption(undefined);
    }
  }, [paymentType]);

  const goBack = () => {
    trackSegmentEvent(SEGMENT_EVENT_NAMES.PATIENT_PORTAL.SETTLEMENT.BACK_TO_REPAYMENT_SELECTION_CLICKED);
    navigate(-1);
  };

  const onPaymentTypeChange = (paymentType: string) => {
    const parsedPaymentType = parseEnumType(paymentType, PaymentTypes);
    if (parsedPaymentType !== undefined) {
      if (parsedPaymentType === PaymentTypes.Full) {
        trackSegmentEvent(SEGMENT_EVENT_NAMES.PATIENT_PORTAL.SETTLEMENT.PAY_IN_FULL_SELECTED);
      } else if (parsedPaymentType === PaymentTypes.OverTime) {
        trackSegmentEvent(SEGMENT_EVENT_NAMES.PATIENT_PORTAL.SETTLEMENT.PAY_OVER_TIME_SELECTED);
      }
      setPaymentType(parsedPaymentType);
    }
  };

  const handleOverTimeOptionsChange = (overTimeOption: string) => {
    const parsedOverTimeOption = parseEnumType(overTimeOption, SettlementFrequency);
    if (parsedOverTimeOption !== undefined) {
      setOverTimeOption(parsedOverTimeOption);
    }
  };

  const handleDateChange = (value) => {
    trackSegmentEvent(SEGMENT_EVENT_NAMES.PATIENT_PORTAL.SETTLEMENT.DATE_SELECTED, {
      date: new Date(value).toDateString(),
    });
    setDateValue(new Date(value).toDateString());
  };

  const setDateToToday = () => {
    trackSegmentEvent(SEGMENT_EVENT_NAMES.PATIENT_PORTAL.SETTLEMENT.TODAY_CLICKED);
    setDateValue(new Date().toDateString());
  };

  const onSelectedPaymentAmount = (val) => {
    trackSegmentEvent(SEGMENT_EVENT_NAMES.PATIENT_PORTAL.SETTLEMENT.INSTALLMENT_OPTION_SELECTED, {
      installmentNumber: val,
      amountPerInstallment: FormatAmount(outstandingBalance / val),
    });
    setSelectedInstallmentAmount(val);
  };

  const goToPaymenMethod = () => {
    trackSegmentEvent(SEGMENT_EVENT_NAMES.PATIENT_PORTAL.SETTLEMENT.CONTINUE_TO_PAYMENT_METHOD_CLICKED);
    navigate(`/portal/${id}/payment-method`, {
      state: {
        isSettlementFlow: true,
        amount: discountedChargedOffAmount / (selectedInstallmentAmount || 1),
        paymentDate: dateValue,
        type: SettlementPaymentTypes.SettlementOffer,
        frequency,
        installmentCount: selectedInstallmentAmount,
      },
    });
  };

  return (
    <Container block={true}>
      <InnerContainer>
        <SettlementSelection>
          <Stepper
            totalStepCount={SETTLEMENT_FLOW_STEPPER_CONFIG.TOTAL_PAGES}
            activeStep={SETTLEMENT_FLOW_STEPPER_CONFIG.ACTIVE_PAGE.SETTLEMENT}
          />
          <PageTitle>{t('settlement.title')}</PageTitle>
          <Section>
            <Text>
              <i>{t('settlement.outstandingBalance')}</i>
            </Text>
            <AmountContainer>
              <AmountText>
                <del>{FormatAmount(outstandingBalance)}</del>
                <i>{FormatAmount(discountedChargedOffAmount)}</i>
              </AmountText>
              <AmountBadge>{Math.round(discountPercentage)}% Discount</AmountBadge>
            </AmountContainer>
          </Section>
          <Section>
            <Text>{t('settlement.paymentType')}</Text>
            <RadioGroupContainer>
              <RadioGroup options={options} onChange={onPaymentTypeChange} gapDistance="low" />
            </RadioGroupContainer>
          </Section>
          {paymentType === PaymentTypes.OverTime && (
            <Section>
              <Text>{t('settlement.overTime')}</Text>
              <GridSelectionContainer>
                <GridSelection buttonCountPerRow={3} options={overTimeOptions} onChange={handleOverTimeOptionsChange} />
              </GridSelectionContainer>
            </Section>
          )}
          <Section>
            <Text>{t('monthlyPaymentPlan.paymentDate')}</Text>
            <DateContainer>
              <DesktopDatePicker value={dateValue} onDateChange={handleDateChange} maxDate={maxDate} />
              <TodayButton onClick={setDateToToday}>{t('oneTimePayment.today')}</TodayButton>
            </DateContainer>
            <Footnote>{t('oneTimePayment.dateSubInfo')}</Footnote>
          </Section>
          <Section>
            {overTimeOption && (
              <PaymentInstallmentList
                showList={showInstallmentList}
                paymentStartDate={dateValue}
                frequency={overTimeOption}
                paymentType={SettlementPaymentTypes.SettlementOffer}
                installmentMap={installmentMap}
                onSelectedPaymentAmount={onSelectedPaymentAmount}
              >
                {paymentType === PaymentTypes.Full && (
                  <PaymentPlanContainer>
                    <div>
                      <PaymentPlanAmount>{FormatAmount(discountedChargedOffAmount)}</PaymentPlanAmount>
                      <div>
                        {t('settlement.chargedOn')} {FormatDate(dateValue)}
                      </div>
                    </div>
                    <div>
                      <DiscountText>{Math.round(discountPercentage)}% Discount</DiscountText>
                      <div>
                        {t('settlement.savedMoney')} {FormatAmount(amountOfSavedMoney)}
                      </div>
                    </div>
                  </PaymentPlanContainer>
                )}
                {paymentType === PaymentTypes.OverTime && overTimeOption && (
                  <PaymentPlanContainer>
                    <div>
                      <DiscountText>{Math.round(discountPercentage)}% Discount</DiscountText>
                      <div>
                        {t('settlement.savedMoney')} {FormatAmount(amountOfSavedMoney)}
                      </div>
                    </div>
                  </PaymentPlanContainer>
                )}
              </PaymentInstallmentList>
            )}
          </Section>
          <ButtonContainer>
            <Button color={ButtonColor.secondary} onClick={goBack}>
              {t('genericButton.back')}
            </Button>
            <Button onClick={goToPaymenMethod} disabled={!isContinueButtonEnabled}>
              {t('genericButton.continue')}
            </Button>
          </ButtonContainer>
        </SettlementSelection>
      </InnerContainer>
    </Container>
  );
};

const SettlementSelection = styled.div`
  display: flex;
  gap: 24px;
  flex-direction: column;
  padding: 24px;
  max-width: 600px;
  border-radius: 8px;
  background: ${(props) => props.theme.main.white};
  border-bottom: 1px solid ${(props) => props.theme.main.cardBorderColor};
  box-shadow: 0px 6px 9px rgba(0, 0, 0, 0.07), 0px 0.751293px 1.12694px rgba(0, 0, 0, 0.035);
`;

const PageTitle = styled(Title)`
  color: ${(props) => props.theme.main.primary};
`;

const RadioGroupContainer = styled.div`
  margin-top: 8px;
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 16px;

  button {
    margin: 0;
  }
`;

const AmountText = styled.div`
  font-size: 20px;
  font-style: italic;
  font-weight: 700;

  i {
    color: ${(props) => props.theme.main.green};
    margin-left: 8px;
  }
`;

const Section = styled.div``;

const Text = styled.div``;

const AmountContainer = styled.div`
  display: flex;
  align-items: center;
  margin-top: 8px;
  gap: 8px;
`;

const AmountBadge = styled.div`
  border-radius: 16px;
  background: ${(props) => props.theme.main.preferredColor};
  padding: 4px 8px;
  color: ${(props) => props.theme.main.green};
  font-size: 12px;
  font-weight: 400;
  text-transform: uppercase;
`;

const GridSelectionContainer = styled.div`
  margin-top: 8px;
`;

const DateContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 16px;
  margin-top: 8px;
`;

const TodayButton = styled.button`
  color: ${(props) => props.theme.main.primary};
  font-size: 16px;
  font-weight: 400;
  text-decoration-line: underline;
  background-color: ${(props) => props.theme.main.white};
  border: none;
  outline: none;
  padding: 0;
  margin: 0;
  cursor: pointer;
`;

const Footnote = styled.i`
  color: ${(props) => props.theme.main.primary};
  font-size: 12px;
  font-weight: 400;
`;

const PaymentPlanContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  align-items: center;
  text-align: center;
`;

const PaymentPlanAmount = styled.div`
  color: ${(props) => props.theme.main.primary};
  font-size: 24px;
  font-weight: 700;
`;

const DiscountText = styled.div`
  color: ${(props) => props.theme.main.green};
  font-size: 24px;
  font-weight: 700;
`;

const PaymentPlanFootnote = styled.div`
  font-size: 14px;
  font-weight: 400;
  font-style: italic;

  a {
    color: inherit;
  }
`;

export default Settlement;
