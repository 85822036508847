import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import {
  Button,
  ButtonContainer,
  Icon,
  InnerContainer,
  MembershipContainer,
  Subtitle,
  TextField,
  Title,
} from 'lib/components';
import useStore from 'lib/hooks/useStore';
import { useMutation } from '@apollo/client';
import { CREATE_CUSTOMER_SUBSCRIPTIONS_PLAN } from 'lib/graphql/mutations/Subscriptions';
import styled from 'styled-components';
import { WEB_SITE } from 'config';
import { calculateTotalPrice } from 'lib/utils/MembershipPrice';
import { useTranslation } from 'react-i18next';

const SubscriptionsReview = () => {
  const {
    setPageIndex,
    subscriptionOrganization,
    selectedSubscriptionPlan,
    subscriptionPeriod,
    subscriptionCustomer,
    subscriptionPaymentMethod,
  } = useStore();
  const navigate = useNavigate();
  const [createCustomerPlan, { loading }] = useMutation(CREATE_CUSTOMER_SUBSCRIPTIONS_PLAN);
  const { t: translate } = useTranslation();

  const [termsExpanded, setTermsExpanded] = useState(false);

  const toggleTermsExpanded = () => {
    setTermsExpanded((prev) => !prev);
  };

  useEffect(() => {
    setPageIndex(4);
  }, [setPageIndex]);

  const selectedPrice = selectedSubscriptionPlan?.prices?.find((price) => price.period === subscriptionPeriod);
  const confirm = async () => {
    const { data } = await createCustomerPlan({
      variables: {
        input: {
          organizationId: subscriptionOrganization?.id,
          planId: selectedSubscriptionPlan?.id,
          planPriceId: selectedPrice?.id,
          customerId: subscriptionCustomer?.id,
          paymentMethodId: subscriptionPaymentMethod?.id,
        },
      },
    });

    if (data?.createCustomerMembershipsPlan?.id) {
      navigate(`/memberships/${subscriptionOrganization?.slug}/success`);
    } else if (data?.createCustomerMembershipsPlan?.message === 'provider.connection.unprocessable') {
      navigate(`/memberships/${subscriptionOrganization?.slug}/add-payment-method?status=error`);
    }
  };

  const startOfTerms =
    'By clicking "Confirm and Pay" You authorize Cherry Technologies Inc ("Cherry") to make electronic charges\n' +
    '              from the credit card, debit card, or bank account you have provided. You understand that these charges or\n' +
    '              debits will occur on the aforementioned periodic transaction dates in the amount of your scheduled payment\n' +
    '              amount, including any applicable fees. You understand that if you miss any payment(s), subsequent charges\n' +
    '              or debits may incorporate the amount of the missed payment(s), in addition to the amount of the regularly\n' +
    '              scheduled payment amount then due. If the above noted payment dates fall on a weekend or holiday, you\n' +
    '              understand that the payments may be executed on the next business day. For debits to my checking/savings\n' +
    '              account, you understand that because these are electronic transactions, these funds may be withdrawn from\n' +
    '              my account as soon as the above noted periodic transaction dates. You also authorize Cherry to make\n' +
    '              electronic reversals or credits to your credit card’s account or your debit card’s bank account, as\n' +
    '              applicable, in the event of an erroneous charge or debit.';

  return (
    <MembershipContainer showBackButton={true}>
      <InnerContainer>
        <Icon width={28} height={28} src="glasses" />
        <Title>{translate('subscriptions.review.reviewConfirm')}</Title>
        <Subtitle>
          {translate('subscriptions.review.whenYouClick')}
          <ConfirmItem>
            {' '}
            {translate('subscriptions.review.yourCharged', {
              price: calculateTotalPrice(
                selectedPrice?.firstPayment ? selectedPrice.firstPayment : selectedPrice?.price,
                selectedPrice?.processingFee,
                true,
              ),
            })}
            .
          </ConfirmItem>
          <ConfirmItem>{translate('subscriptions.review.email')}</ConfirmItem>
          <ConfirmItem> {translate('subscriptions.review.membershipActivate')}</ConfirmItem>
        </Subtitle>
        <ContentBox>
          <TermsTitle>{translate('subscriptions.review.terms')}</TermsTitle>
          <TermsText>
            {termsExpanded ? (
              <>
                <p>{startOfTerms}</p>
                <p>{translate('subscriptions.review.terms1')}</p>
                <p>{translate('subscriptions.review.terms2')}</p>
                <p>{translate('subscriptions.review.terms3')}</p>
              </>
            ) : (
              <p>{startOfTerms.slice(0, 450)}...</p>
            )}
          </TermsText>
          <ViewButton onClick={toggleTermsExpanded}>
            {termsExpanded ? 'Hide' : 'View'} {translate('subscriptions.review.fullTerms')}
          </ViewButton>
        </ContentBox>
      </InnerContainer>
      <ButtonContainer>
        <Button p="15px" onClick={confirm} loading={loading}>
          {translate('subscriptions.review.confirmPay')}
        </Button>
        <SmallText>
          {translate('subscriptions.review.description')}
          <InfoColorText href={`${WEB_SITE}/privacy`} target="_blank">
            {translate('subscriptions.review.privacy')}
          </InfoColorText>{' '}
          {translate('subscriptions.review.and')}
          <InfoColorText href={`${WEB_SITE}/terms`} target="_blank">
            {translate('subscriptions.review.termsOfService')}
          </InfoColorText>
        </SmallText>
      </ButtonContainer>
    </MembershipContainer>
  );
};

export default SubscriptionsReview;

const ViewButton = styled.div`
  margin: 16px;
  text-decoration: underline;
  cursor: pointer;
  color: rgba(0, 195, 125, 1);
  font-family: Open Sans;
  font-size: 16px;
  font-weight: 600;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: center;
`;

const TermsText = styled.div`
  font-family: Open Sans;
  font-size: 14px;
  font-weight: 400;
  line-height: 19px;
  letter-spacing: 0em;
  text-align: left;

  overflow: hidden !important;
  display: block;
`;

const TermsTitle = styled.div`
  font-family: Open Sans;
  font-size: 16px;
  font-weight: 700;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: left;
  margin-bottom: 8px;
`;

const ContentBox = styled.div`
  background-color: ${(props) => props.theme.main.lightGray};
  margin-top: 16px;
  padding: 16px;
`;

const ConfirmItem = styled.div`
  display: list-item;
  list-style-position: inside;
`;

const SmallText = styled.div<{ margin?: string; isGray?: boolean }>`
  margin: ${(props) => props?.margin || '24px 0 12px 0'};
  color: ${(props) => (props.isGray ? props.theme.main.grayColor : props.theme.main.midnightBlue)};
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
`;

const InfoColorText = styled.a`
  color: ${(props) => props.theme.main.green};
  font-weight: 700;
  text-decoration: underline;
  cursor: pointer;
`;
