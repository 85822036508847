import create from 'zustand';
import { persist } from 'zustand/middleware';

import StorageService from 'lib/services/Storage';
import { Loan } from 'pages/Purchases/type';
import { Approval } from 'pages/Approvals/type';
import { initChatWidget } from 'lib/utils/ChatWidget';

export enum LanguageEnum {
  'EN' = 'en',
  'ES' = 'es',
}

interface Borrower {
  bankNames: Array<string>;
  createdAt: string;
  email?: string;
  firstName: string;
  id: number;
  kycFailedReason?: any;
  kycStatus: string;
  language: string;
  lastName: string;
  phone: string;
  ssnUW?: any;
  updatedAt: string;
}

interface DebtSaleBuyers {
  id: number;
  name: string;
  email: string;
  phone: string;
  address: string;
  address2: string;
  city: string;
  state: string;
  zip: string;
}

export interface IdentityData {
  templateId: string;
  referenceId: string;
}

export interface DocumentRequest {
  id: string;
  documentTitle: string;
  externalNote: string;
  updatedAt: string;
  status: string;
  numberOfDocs: number;
  documentRequestType: string;
  createdAt: string;
  userId: number;
  relations: Array<{ id: number; type: string }>;
}

export interface DocumentRequestObject {
  documentRequest: DocumentRequest;
  email: string;
  externalUserName: string;
  phone: string;
}

interface Fee {
  fixed: number;
  percent: number;
}

interface PaymentMethodFee {
  DEBIT: Fee;
  CREDIT: Fee;
  PREPAID: Fee;
  UNKNOWN: Fee;
}

export type Price = {
  id: number;
  period: string;
  price: number;
  planId: number;
};

interface SubscriptionPlan {
  id: number;
  name: string;
  organizationId: number;
  description: string;
  detailedDescription: string;
  status: string;
  recommended: boolean;
  prices: [Price];
  benefits: {
    id: number;
    name: string;
    type: string;
    frequency: string;
    quantity: number;
    planId: number;
  };
}

export enum SubscriptionPeriod {
  MONTHLY = 'MONTHLY',
  MONTHLY_FLEX = 'MONTHLY_FLEX',
  YEARLY = 'YEARLY',
  CUSTOM_WEEKLY = 'CUSTOM_WEEKLY',
}

interface Organization {
  id: string;
  name: string;
}

export interface MembershipPlans {
  id: number;
  customer: {
    firstName: string;
    lastName: string;
  };
  plan: {
    name: string;
    detailedDescription: string;
    description: string;
  };
  planId: number;
  organizationId: number;
  organization: {
    name: string;
  };
  status: string;
  price: number;
  period: string;
  firstPayment: number;
  autoRenew: boolean;
  billingTerm: number;
  paymentFrequency: number;
  createdAt: string;
  customerBenefits: [Benefits];
  paymentMethod: {
    last4: string;
  };
}

interface Benefits {
  quantity: number;
  name: string;
  type: string;
  frequency: string;
  amount: number;
  usage: number;
  remaining: number;
  lastUsed: string;
  updatedAt: string;
}

interface Store {
  headerTitle: string;
  setHeaderTitle: (value: string) => void;
  defaultLanguage: LanguageEnum;
  setDefaultLanguage: (value: string) => void;
  phoneNumber: string;
  setPhoneNumber: (value: string) => void;
  phoneUpdateOrigin: boolean;
  setPhoneUpdateOrigin: (value: boolean) => void;
  newPhoneNumber: string;
  setNewPhoneNumber: (value: string) => void;
  selfUpdateSlug: string;
  setSelfUpdateSlug: (value: string) => void;
  otpNumber: string;
  setOtp: (value: string) => void;
  borrower: Borrower;
  setBorrower: (value: Borrower) => void;
  userId: null;
  setUserId: (value: string) => void;
  identityData: null;
  setidentityData: (value: IdentityData) => void;
  loading: boolean;
  setLoading: (value: boolean) => void;
  signOut: () => void;
  loanList: any;
  setLoanList: () => void;
  locations: any;
  setLocatioons: () => void;
  applicationList: any;
  setApplicationList: () => void;
  selectedApplication: Approval;
  setSelectedApplication: () => void;
  checkoutLoanList: any;
  setCheckoutLoanList: () => void;
  fallbackUrl: string;
  setFallbackUrl: () => void;
  deepLinkUrl: string;
  setDeepLinkUrl: () => void;
  debtSaleBuyers: DebtSaleBuyers;
  setDebtSaleBuyers: () => void;
  paymentMethodFee: PaymentMethodFee;
  setPaymentMethodFee: () => void;
  selectedPrincipalOnly: boolean;
  setSelectedPrincipalOnly: (value: boolean) => void;
  checkRouterLoading: boolean;
  setCheckRouterLoading: (status: boolean) => void;
  paymentType: string;
  setPaymentType: (value: string) => void;
  subscriptionPlans: SubscriptionPlan[];
  selectedSubscriptionPlan: SubscriptionPlan;
  setSelectedSubscriptionPlan: (value: SubscriptionPlan[]) => void;
  setSubscriptionPlans: (value: SubscriptionPlan) => void;
  subscriptionPeriod: SubscriptionPeriod;
  setSubscriptionPeriod: (period: SubscriptionPeriod) => void;
  subscriptionOrganization: Organization;
  setSubscriptionOrganization: (value: Organization) => void;
  membershipPlans: [MembershipPlans];
  documentRequests: [DocumentRequestObject];
  setDocumentRequests: (data: DocumentRequestObject) => void;
  selectedChargeOffLoan: Loan;
  setSelectedChargeOffLoan: (loan: Loan) => void;
}

const useStore = create<Store | any>(
  persist(
    (set, get) => ({
      headerTitle: 'Welcome to Cherry!',
      setHeaderTitle: (value: string) => set(() => ({ headerTitle: value })),
      defaultLanguage: LanguageEnum.EN,
      isLanguageChange: false,
      setDefaultLanguage: (value: LanguageEnum) => set(() => ({ defaultLanguage: value, isLanguageChange: true })),
      phoneNumber: '',
      setPhoneNumber: (value: string) => set(() => ({ phoneNumber: value })),
      phoneUpdateOrigin: false,
      setPhoneUpdateOrigin: (value: string) => set(() => ({ phoneUpdateOrigin: value })),
      newPhoneNumber: '',
      setNewPhoneNumber: (value: string) => set(() => ({ newPhoneNumber: value })),
      selfUpdateSlug: null,
      setSelfUpdateSlug: (value: string) => set(() => ({ selfUpdateSlug: value })),
      otpNumber: '',
      setOtp: (value: string) => set(() => ({ otpNumber: value })),
      borrower: {},
      setBorrower: (value: Borrower) => set(() => ({ borrower: value })),
      userId: '',
      setUserId: (value: string) => set(() => ({ userId: value })),
      identityData: {},
      setIdentityData: (value: IdentityData) => set(() => ({ identityData: value })),
      patchBorrower: (value) => set(() => ({ borrower: { ...get().borrower, ...value } })),
      loading: false,
      setLoading: (value: boolean) => set(() => ({ loading: value })),
      paymentMethods: null,
      setPaymentMethods: (data: any) => set(() => ({ paymentMethods: data })),
      documentRequests: [],
      setDocumentRequests: (data: DocumentRequestObject) => set(() => ({ documentRequests: data })),
      signOut: () => {
        StorageService.clearUserData();
        set(() => ({ amountOfPayment: 0, paymentMethods: null, loanList: [], fallbackUrl: '' }));
        initChatWidget();
        return set(() => ({ borrower: {}, phoneNumber: '', otpNumber: '' }));
      },
      loanList: [],
      setLoanList: (data: any) => set(() => ({ loanList: data })),
      locations: [],
      setLocations: (data: any) => set(() => ({ locations: data })),
      applicationList: [],
      setApplicationList: (data: any) => set(() => ({ applicationList: data })),
      selectedApplication: null,
      setSelectedApplication: (data: Approval) => set(() => ({ selectedApplication: data })),
      setCheckoutLoanList: (data: any) => set(() => ({ checkoutLoanList: data })),
      getActiveLoan: (id: string | number) => {
        const activeLoan = get().loanList?.find((loan) => loan?.id === id?.toString());
        return activeLoan;
      },
      amountOfPayment: 0,
      setAmountOfPayment: (data: any) => set(() => ({ amountOfPayment: data })),
      paymentInfo: {},
      setPaymentInfo: (data: any) => set(() => ({ paymentInfo: data })),
      paymentMethod: {},
      setPaymentMethod: (data: any) => set(() => ({ paymentMethod: data })),
      fallbackUrl: null,
      setFallbackUrl: (fallbackUrl: string) => set(() => ({ fallbackUrl })),
      deepLinkUrl: null,
      setDeepLinkUrl: (deepLinkUrl: string) => set(() => ({ deepLinkUrl })),
      debtSaleBuyers: [],
      setDebtSaleBuyers: (debtSaleBuyers: DebtSaleBuyers) => set(() => ({ debtSaleBuyers })),
      paymentMethodFee: [],
      setPaymentMethodFee: (paymentMethodFee: PaymentMethodFee) => set(() => ({ paymentMethodFee })),
      selectedPrincipalOnly: false,
      setSelectedPrincipalOnly: (value: boolean) => set(() => ({ selectedPrincipalOnly: value })),
      checkRouterLoading: false,
      setCheckRouterLoading: (status: boolean) => set(() => ({ checkRouterLoading: status })),
      paymentType: '',
      setPaymentType: (value: string) => set(() => ({ paymentType: value })),
      pageIndex: 0,
      setPageIndex: (value: number) => set(() => ({ pageIndex: value })),
      subscriptionPlans: [],
      setSubscriptionPlans: (subscriptionPlans: SubscriptionPlan[]) => set(() => ({ subscriptionPlans })),
      selectedSubscriptionPlan: {},
      setSelectedSubscriptionPlan: (selectedSubscriptionPlan: SubscriptionPlan) =>
        set(() => ({ selectedSubscriptionPlan })),
      subscriptionPeriod: 'MONTHLY',
      setSubscriptionPeriod: (subscriptionPeriod: SubscriptionPeriod) => set(() => ({ subscriptionPeriod })),
      subscriptionOrganization: {},
      setSubscriptionOrganization: (subscriptionOrganization: Organization) =>
        set(() => ({ subscriptionOrganization })),
      subscriptionCustomer: {},
      setSubscriptionCustomer: (subscriptionCustomer) => set(() => ({ subscriptionCustomer })),
      subscriptionPaymentMethod: {},
      setSubscriptionPaymentMethod: (subscriptionPaymentMethod) => set(() => ({ subscriptionPaymentMethod })),
      organizationAccountInformation: {},
      setOrganizationAccountInformation: (organizationAccountInformation) =>
        set(() => ({ organizationAccountInformation })),
      membershipPlans: [],
      setMembershipPlanList: (membershipPlans: MembershipPlans) => set(() => ({ membershipPlans })),
      selectedChargeOffLoan: null,
      setSelectedChargeOffLoan: (selectedChargeOffLoan: Loan) => set(() => ({ selectedChargeOffLoan })),
    }),
    {
      name: 'customer-storage',
      getStorage: () => localStorage,
      partialize: (state) => ({
        defaultLanguage: state.defaultLanguage,
        borrower: state.borrower,
      }),
    },
  ),
);

export default useStore;
