import { useApolloClient } from '@apollo/client';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { Body, Button, CurrencyInput, Dropdown, Heading, theme } from '@frontend/cherry-library';
import { GET_PRODUCT_CALCULATIONS } from 'lib/graphql/queries';
import { useSentry } from 'lib/hooks/useSentry';
import { CalculationData, FormatAmount, SortWithProductTerm } from 'lib/utils';
import { goBack } from 'lib/utils/GoBack';
import { useSegment } from 'lib/hooks/useSegment';
import useStore from 'lib/hooks/useStore';
import { SEGMENT_EVENT_NAMES } from 'lib/constans';
import { getApplicationDetailData } from 'lib/utils/ApplicationDetailSegmentEventData';

interface PreviewMyPaymentsProps {
  applicationId: string;
  maxPrice: number;
}

interface Option {
  label: string;
  value: string;
}

const PreviewMyPayments = ({ applicationId, maxPrice }: PreviewMyPaymentsProps) => {
  const { t: translate } = useTranslation();
  const client = useApolloClient();
  const { captureException } = useSentry();
  const { trackPage, trackSegmentEvent } = useSegment();
  const [result, setResult] = useState<Array<CalculationData> | null>(null);
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [selectedPrice, setSelectedPrice] = useState<number | null>(null);
  const { borrower, selectedApplication: approval } = useStore();

  const selectedProductCalculation = result?.[selectedIndex];
  const aprRate = selectedProductCalculation?.promo
    ? selectedProductCalculation?.product?.promoApr
    : selectedProductCalculation?.product?.apr;

  let termDropDownData: Option[] = [];
  const minPrice = 200;
  termDropDownData =
    result?.map((option, index) => {
      return {
        label: translate('paymentCalculator.selectedMonth', {
          selectedMonths: option?.product?.term,
        }),
        value: String(index),
      };
    }) ?? [];

  const segmentEventData = getApplicationDetailData(borrower, approval);

  useEffect(() => {
    if (selectedPrice && selectedPrice >= minPrice && selectedPrice <= maxPrice) {
      fetchProductCalculation?.();
    }
  }, [selectedPrice]);

  useEffect(() => {
    trackPage(SEGMENT_EVENT_NAMES.PATIENT_PORTAL.PREVIEW_PAYMENTS.PAGE_LOAD, segmentEventData);
  }, []);

  const fetchProductCalculation = async () => {
    if (applicationId) {
      try {
        const { data } = await client.query({
          query: GET_PRODUCT_CALCULATIONS,
          variables: {
            input: {
              applicationId,
              amount: selectedPrice,
            },
          },
        });

        if (data?.getProductCalculations?.data?.length > 0) {
          const sortedData = SortWithProductTerm(data?.getProductCalculations?.data);
          setResult(sortedData || []);
        }
      } catch (err) {
        captureException(err, { message: 'Get Product Calculation Catch Error' });
      }
    } else {
      captureException('Application Id Not Found in Calculation Slider');
    }
  };

  const handlePriceChange = (e) => {
    if (e?.target?.value) {
      trackSegmentEvent(SEGMENT_EVENT_NAMES.PATIENT_PORTAL.PREVIEW_PAYMENTS.ESTIMATED_PURCHASE_AMOUNT_FILLED, {
        ...segmentEventData,
        estimatedAmount: parseFloat(e?.target?.value),
      });
      setSelectedPrice(parseFloat(e?.target?.value));
    }
  };

  const handleMonthsChange = (e) => {
    setSelectedIndex(e?.target?.value);
    trackSegmentEvent(SEGMENT_EVENT_NAMES.PATIENT_PORTAL.PREVIEW_PAYMENTS.PLAN_LENGTH_SELECTED, {
      ...segmentEventData,
      planLength: result?.[selectedIndex]?.product?.term,
    });
  };

  if (!result && !maxPrice) {
    return null;
  }

  const goBackHandler = () => {
    trackSegmentEvent(SEGMENT_EVENT_NAMES.PATIENT_PORTAL.PREVIEW_PAYMENTS.BACK_CLICKED, segmentEventData);
    goBack();
  };

  return (
    <PaymentContainer>
      <PurchaseContainer>
        <PreviewPaymentsTitle level="4" text={translate('paymentCalculator.title')}></PreviewPaymentsTitle>
        <PurchaseText fontWeight={400} color={theme.main.midnightBlue}>
          {translate('paymentCalculator.purchaseText')}
        </PurchaseText>
        <CurrencyInput
          id="purchase-amount-input"
          label={translate('paymentCalculator.form.purchaseLabel')}
          data-testid="purchase"
          data-neuro-label="purchase"
          onChange={handlePriceChange}
          value={selectedPrice}
          error={!!selectedPrice && (selectedPrice < minPrice || selectedPrice > maxPrice)}
          errorText={translate('previewMyPayments.amountRangeError', {
            maxValue: FormatAmount(maxPrice),
            minValue: FormatAmount(minPrice),
          })}
        />

        <>
          <PlanText>{translate('paymentCalculator.planText')}</PlanText>
          <Dropdown
            value={termDropDownData[selectedIndex]?.value}
            onChange={handleMonthsChange}
            data-testid="number-of-months"
            id="number-of-months"
            label={translate('paymentCalculator.form.planLabel')}
            options={termDropDownData}
          />
        </>

        {selectedIndex !== null &&
          selectedPrice &&
          selectedPrice >= minPrice &&
          selectedPrice <= maxPrice &&
          result && (
            <CalculationSummary>
              <SummaryTitle color={theme.main.midnightBlue} fontWeight={700}>
                {translate('paymentCalculator.summary.title')}
              </SummaryTitle>

              <AmountTitle
                level="3"
                text={translate('paymentCalculator.summary.installmentAmount', {
                  amount: FormatAmount(Number(selectedProductCalculation?.installmentAmount)),
                })}
              ></AmountTitle>

              <Summary
                fontWeight={400}
                color={theme.main.midnightBlue}
                dangerouslySetInnerHTML={{
                  __html: translate('paymentCalculator.summary.downPaymentAmount', {
                    amount: FormatAmount(Number(selectedProductCalculation?.downPaymentAmount)),
                  }),
                }}
              />

              <Summary
                fontWeight={400}
                color={theme.main.midnightBlue}
                dangerouslySetInnerHTML={{
                  __html: translate('paymentCalculator.summary.financeCharge', {
                    amount: FormatAmount(Number(selectedProductCalculation?.financeCharge)),
                  }),
                }}
              />

              <Summary color={theme.main.midnightBlue} fontWeight={400}>
                <b>{aprRate}%</b> {translate('paymentCalculator.summary.apr')}{' '}
                {selectedProductCalculation?.product?.fallbackApr &&
                selectedProductCalculation?.product?.fallbackApr > 0
                  ? translate('paymentCalculator.summary.fallbackApr', {
                      fallbackApr: Number(selectedProductCalculation?.product?.fallbackApr),
                    })
                  : ''}
              </Summary>
            </CalculationSummary>
          )}
        <BackButton fullWidth variant="secondary" onClick={goBackHandler}>
          {translate('genericButton.back')}
        </BackButton>
      </PurchaseContainer>
    </PaymentContainer>
  );
};

export default PreviewMyPayments;

const PaymentContainer = styled.div`
  width: 100%;
  background: ${theme.main.white};
  border-radius: 4px;
  margin-top: 24px;
`;

const PurchaseContainer = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: 8px;
  padding: 24px;
`;

const PreviewPaymentsTitle = styled(Heading)`
  margin-bottom: 24px !important;
`;

const PurchaseText = styled(Body)`
  margin: 8px 0 !important;
`;

const PlanText = styled(PurchaseText)`
  margin-top: 16px;
`;

const CalculationSummary = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 16px;
`;

const Summary = styled(Body)`
  font-size: 12px;
  text-align: center;
  font-style: italic !important;
  margin-bottom: 2px;
`;

const Title = styled(Body)`
  color: ${theme.main.white};
  font-size: 24px;
  font-weight: 700;
  line-height: 33px;
`;

const AmountTitle = styled(Heading)`
  color: ${theme.main.kellyGreen} !important;
  margin-bottom: 8px;
`;

const BackButton = styled(Button)`
  margin-top: 24px;
`;

const SummaryTitle = styled(Body)`
  line-height: 19px;
  text-align: center;
  margin-bottom: 8px !important;
`;
