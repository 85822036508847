import { monthlyPDFStatementsSearch } from 'lib/graphql/searches/MonthlyPDFStatements';
import { AnalyticEventNames, useAnalytics } from 'lib/hooks/useAnalytics';
import { useFile, useFiles } from 'lib/hooks/useFile';
import useStore from 'lib/hooks/useStore';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { Loading } from 'lib/components';
import Table from 'pages/Account/Files/components/Table';
import { Contents } from 'pages/Account/Files/type';

const PAGINATION = {
  limit: 5,
  order: 'DESC',
  orderBy: 'createdAt',
};

export const MonthlyPDFStatements = () => {
  const { limit } = PAGINATION;

  const [getFile] = useFile();
  const { t: translate } = useTranslation();
  const { borrower } = useStore();
  const { trackEvent } = useAnalytics();
  const [getFiles, { loading, data }] = useFiles();

  const [offset, setOffset] = useState<number>(0);
  const [totalCount, setTotalCount] = useState<number>(0);
  const [contents, setContents] = useState<Array<Contents>>([]);

  const getMonthlyPDFStatements = () => {
    getFiles({
      variables: {
        input: {
          search: monthlyPDFStatementsSearch(borrower?.id),
          pagination: { ...PAGINATION, offset },
        },
      },
    });
  };

  useEffect(() => {
    getMonthlyPDFStatements();
  }, [offset]);

  useEffect(() => {
    if (data?.getFileList && data?.getFileList?.contents) {
      setContents([...contents, ...data?.getFileList?.contents]);
      setTotalCount(data?.getFileList?.total);
    }
  }, [data]);

  const loadMore = () => {
    trackEvent(AnalyticEventNames.PDF_LOAD_MORE);
    setOffset(offset + 1);
  };

  const getPDF = async (fileName: string, fileId: string) => {
    trackEvent(fileName === 'Agreement' ? AnalyticEventNames.MF_AGREEMENT_LINK : AnalyticEventNames.MF_CREDIT_DISC);
    getFile({ variables: { input: { fileId } } });
  };

  return (
    <>
      <InnerContainer>
        <Table contents={contents} getAgreementFile={getPDF} type="statements" />
        {(offset + 1) * limit < totalCount && (
          <StyledButton data-testid="show-more-statements" onClick={loadMore}>
            {translate('files.tableRow.loadBtn')}
          </StyledButton>
        )}
      </InnerContainer>
      {loading && <Loading transparent={true} />}
    </>
  );
};

const InnerContainer = styled.div`
  margin-top: 16px;
  padding: 16px;
  display: flex;
  flex-direction: column;
  background-color: ${(props) => props.theme.main.white};
  border-radius: 8px;
  box-shadow: 0px 36px 67px 0px rgba(0, 0, 0, 0.07), 0px 13.141px 24.456px 0px rgba(0, 0, 0, 0.05),
    0px 6.38px 11.873px 0px rgba(0, 0, 0, 0.04), 0px 3.127px 5.82px 0px rgba(0, 0, 0, 0.03),
    0px 1.237px 2.301px 0px rgba(0, 0, 0, 0.02);
`;

const StyledButton = styled.span`
  display: flex;
  padding: 8px 0;
  justify-content: center;
  align-items: flex-start;
  gap: 8px;
  align-self: stretch;
  margin-top: 16px;
  text-decoration: underline;
  cursor: pointer;
`;
