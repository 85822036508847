import { Button, ButtonColor, Container, DesktopDatePicker, InnerContainer, Stepper, Title } from 'lib/components';
import { GridSelection } from 'lib/components/GridSelection/GridSelection';
import useStore from 'lib/hooks/useStore';
import { FormatAmount, parseEnumType } from 'lib/utils';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import styled from 'styled-components';
import dayjs from 'dayjs';
import { SettlementFrequency, SettlementPaymentTypes } from 'lib/types';
import { useGetChargedOffOffers } from 'lib/hooks/useChargedOffLoan';
import PaymentInstallmentList from 'lib/components/PaymentInstallmentList/PaymentInstallmentList';
import { SEGMENT_EVENT_NAMES, SETTLEMENT_FLOW_STEPPER_CONFIG } from 'lib/constans';
import { useSegment } from 'lib/hooks/useSegment';

const MonthlyPaymentPlan = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { setHeaderTitle, selectedChargeOffLoan } = useStore();
  const { id } = useParams();
  const { trackSegmentEvent } = useSegment();
  const [getChargedOffOffers, { data, loading }] = useGetChargedOffOffers();

  const [frequency, setFrequency] = useState<SettlementFrequency | undefined>();
  const now = dayjs(new Date()).format('YYYY-MM-DD');
  const [dateValue, setDateValue] = useState(now);
  const [selectedInstallmentAmount, setSelectedInstallmentAmount] = useState<number | undefined>();

  const outstandingBalance = selectedChargeOffLoan?.plans?.[0]?.netChargeOff;
  const isContinueButtonEnabled = outstandingBalance && selectedInstallmentAmount && dateValue?.length > 0 && frequency;
  const showInstallmentList = Boolean(frequency && dateValue?.length > 0);
  const { installmentMap } = data?.getChargedOffOffers || {};
  const maxDate = dayjs().add(2, 'weeks');

  const options = [
    {
      value: SettlementFrequency.Weekly,
      text: t('monthlyPaymentPlan.paymentFrequencyOptions.weekly'),
    },
    {
      value: SettlementFrequency.BiWeekly,
      text: t('monthlyPaymentPlan.paymentFrequencyOptions.twoWeeks'),
    },
    {
      value: SettlementFrequency.Monthly,
      text: t('monthlyPaymentPlan.paymentFrequencyOptions.monthly'),
    },
  ];

  useEffect(() => {
    trackSegmentEvent(SEGMENT_EVENT_NAMES.PATIENT_PORTAL.PAYMENT_PLAN.PAGE_LOAD);
  }, []);

  useEffect(() => {
    if (frequency === SettlementFrequency.Weekly) {
      trackSegmentEvent(SEGMENT_EVENT_NAMES.PATIENT_PORTAL.PAYMENT_PLAN.WEEKLY_SELECTED);
    } else if (frequency === SettlementFrequency.BiWeekly) {
      trackSegmentEvent(SEGMENT_EVENT_NAMES.PATIENT_PORTAL.PAYMENT_PLAN.BIWEEKLY_SELECTED);
    } else if (frequency === SettlementFrequency.Monthly) {
      trackSegmentEvent(SEGMENT_EVENT_NAMES.PATIENT_PORTAL.PAYMENT_PLAN.MONTHLY_SELECTED);
    }
  }, [frequency]);

  useEffect(() => {
    if (id) {
      getChargedOffOffers({
        variables: {
          input: {
            loanId: Number(id),
          },
        },
      });
    }
  }, [id]);

  useEffect(() => {
    setHeaderTitle('makePayment.title');
  }, [setHeaderTitle]);

  useEffect(() => {
    if (frequency) {
      setSelectedInstallmentAmount(undefined);
    }
  }, [frequency]);

  const handleSelectFrequency = (selectedFrequency: string) => {
    const parsedSelectedFrequency = parseEnumType(selectedFrequency, SettlementFrequency);
    if (parsedSelectedFrequency !== undefined) {
      setFrequency(parsedSelectedFrequency);
    }
  };

  const goBack = () => {
    trackSegmentEvent(SEGMENT_EVENT_NAMES.PATIENT_PORTAL.PAYMENT_PLAN.BACK_TO_REPAYMENT_SELECTION_CLICKED);
    navigate(-1);
  };

  const handleDateChange = (value) => {
    trackSegmentEvent(SEGMENT_EVENT_NAMES.PATIENT_PORTAL.PAYMENT_PLAN.DATE_SELECTED, {
      date: new Date(value).toDateString(),
    });
    setDateValue(new Date(value).toDateString());
  };

  const setDateToToday = () => {
    trackSegmentEvent(SEGMENT_EVENT_NAMES.PATIENT_PORTAL.PAYMENT_PLAN.TODAY_CLICKED);
    setDateValue(new Date().toDateString());
  };

  const onSelectedPaymentAmount = (val) => {
    trackSegmentEvent(SEGMENT_EVENT_NAMES.PATIENT_PORTAL.PAYMENT_PLAN.INSTALLMENT_OPTION_SELECTED, {
      installmentNumber: val,
      amountPerInstallment: FormatAmount(outstandingBalance / val),
    });
    setSelectedInstallmentAmount(val);
  };

  const goToPaymentMethod = () => {
    trackSegmentEvent(SEGMENT_EVENT_NAMES.PATIENT_PORTAL.PAYMENT_PLAN.CONTINUE_TO_PAYMENT_METHOD_CLICKED);
    navigate(`/portal/${id}/payment-method`, {
      state: {
        isSettlementFlow: true,
        amount: outstandingBalance / (selectedInstallmentAmount || 1),
        paymentDate: dateValue,
        type: SettlementPaymentTypes.PaymentPlan,
        frequency,
        installmentCount: selectedInstallmentAmount,
      },
    });
  };

  return (
    <Container block={true}>
      <InnerContainer>
        <PlanSelection>
          <Stepper
            totalStepCount={SETTLEMENT_FLOW_STEPPER_CONFIG.TOTAL_PAGES}
            activeStep={SETTLEMENT_FLOW_STEPPER_CONFIG.ACTIVE_PAGE.PAYMENT_PLAN}
          />
          <PageTitle>{t('monthlyPaymentPlan.title')}</PageTitle>
          <Section>
            <Text>
              <i>{t('monthlyPaymentPlan.outstandingBalance')}</i>
            </Text>
            <AmountText>{FormatAmount(outstandingBalance)}</AmountText>
          </Section>
          <Section>
            <Text>{t('monthlyPaymentPlan.paymentFrequency')}</Text>
            <SelectionContainer>
              <GridSelection buttonCountPerRow={3} onChange={handleSelectFrequency} options={options} />
            </SelectionContainer>
          </Section>
          <Section>
            <Text>{t('monthlyPaymentPlan.paymentDate')}</Text>
            <DateContainer>
              <DesktopDatePicker value={dateValue} onDateChange={handleDateChange} maxDate={maxDate} />
              <TodayButton onClick={setDateToToday}>{t('oneTimePayment.today')}</TodayButton>
            </DateContainer>
            <Footnote>{t('oneTimePayment.dateSubInfo')}</Footnote>
          </Section>
          {frequency && installmentMap && (
            <Section>
              <PaymentInstallmentList
                showList={showInstallmentList}
                paymentStartDate={dateValue}
                frequency={frequency}
                paymentType={SettlementPaymentTypes.PaymentPlan}
                installmentMap={installmentMap}
                onSelectedPaymentAmount={onSelectedPaymentAmount}
              />
            </Section>
          )}
          <ButtonContainer>
            <Button color={ButtonColor.secondary} onClick={goBack}>
              {t('genericButton.back')}
            </Button>
            <Button onClick={goToPaymentMethod} disabled={!isContinueButtonEnabled}>
              {t('genericButton.continue')}
            </Button>
          </ButtonContainer>
        </PlanSelection>
      </InnerContainer>
    </Container>
  );
};

const PlanSelection = styled.div`
  display: flex;
  gap: 24px;
  flex-direction: column;
  padding: 24px;
  max-width: 600px;
  border-radius: 8px;
  background: ${(props) => props.theme.main.white};
  border-bottom: 1px solid ${(props) => props.theme.main.cardBorderColor};
  box-shadow: 0px 6px 9px rgba(0, 0, 0, 0.07), 0px 0.751293px 1.12694px rgba(0, 0, 0, 0.035);
`;

const PageTitle = styled(Title)`
  color: ${(props) => props.theme.main.primary};
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 16px;

  button {
    margin: 0;
  }
`;

const Section = styled.div``;

const Text = styled.div``;

const AmountText = styled.div`
  color: ${(props) => props.theme.main.green};
  font-size: 20px;
  font-style: italic;
  font-weight: 700;
`;

const SelectionContainer = styled.div`
  margin-top: 8px;
`;

const DateContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 16px;
  margin-top: 8px;
`;

const TodayButton = styled.button`
  color: ${(props) => props.theme.main.primary};
  font-size: 16px;
  font-weight: 400;
  text-decoration-line: underline;
  background-color: ${(props) => props.theme.main.white};
  border: none;
  outline: none;
  padding: 0;
  margin: 0;
  cursor: pointer;
`;

const Footnote = styled.i`
  color: ${(props) => props.theme.main.primary};
  font-size: 12px;
  font-weight: 400;
`;

export default MonthlyPaymentPlan;
