import { gql } from '@apollo/client/core';

export const REQUEST_OTP = gql`
  mutation requestOtp($input: BorrowerRequestOtpInput) {
    requestOtp(input: $input) {
      success
      status
      message
      error
    }
  }
`;

export const VERIFY_OTP_REQUEST = gql`
  mutation verifyOtpRequest($input: BorrowerVerifyOtpInput) {
    verifyOtpRequest(input: $input) {
      success
      status
      message
      error
      authorization {
        token
        refreshToken
      }
      borrower {
        id
        createdAt
        updatedAt
        status
        firstName
        lastName
        phone
        email
        dob
        ssn
        identityType
        idChecked
        phoneStatus
        phoneLabel
        language
        genesysId
        balanceUsed
        address {
          city
          id
          createdAt
          updatedAt
          status
          street
          zip
          state {
            name
            code
          }
        }
      }
    }
  }
`;

export const BORROWER_REFRESH_TOKEN = gql`
  mutation borrowerRefreshToken($input: BorrowerRefreshTokenInput) {
    borrowerRefreshToken(input: $input) {
      message
      success
      error
      authorization {
        token
        refreshToken
      }
    }
  }
`;

export const UPDATE_BORROWER = gql`
  mutation updateBorrower($input: UpdateBorrowerInput) {
    updateBorrower(input: $input) {
      success
      error
      status
      message
    }
  }
`;
