import Collapse from '@mui/material/Collapse';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import dayjs from 'dayjs';

import { FEE_CODES, StatementType } from 'lib/constans';
import { CapitalizeFirstLetter, FormatAmount, useFormatDate, isArray, SortByDate } from 'lib/utils';

import { ChevronDownIcon, ChevronUpIcon } from '../icons';
import { getStatements } from '../selector';
import { Body, theme } from '@frontend/cherry-library';

interface ContentProps {
  bold?: boolean;
  error?: boolean;
  paddingRight?: number;
  isDebtRow?: boolean;
  isItalic?: boolean;
  isGreenRow?: boolean;
}

interface TableRowProps {
  clickableRow: boolean;
  noBorder?: boolean;
  last?: boolean;
}

export const PaymentTable = ({ loan, chargedOffStatusChangeDate, remaining, isChargeOff, closed }) => {
  const { t: translate } = useTranslation();
  const { FormatDate } = useFormatDate();
  const [statements, setStatement] = useState<any>([]);
  const [amortizations, setAmortizations] = useState<any>([]);
  const [commitments, setCommitments] = useState<any>([]);

  const { plans, debtSales } = loan || {};
  const cloneAmortizations = amortizations ? [...amortizations] : [];
  const cloneCommitments = commitments ? [...commitments] : [];

  function getLoanAmount(plans, loan) {
    if (plans[0]?.status === 'REFUNDED') {
      return loan?.purchaseAmount;
    }
    return loan?.grossAmount;
  }

  const outstandingClosed = closed ? FormatAmount(getLoanAmount(plans, loan)) : 0;

  const outstandingChargeOff = isChargeOff || closed ? FormatAmount(plans[0]?.netChargeOff) : FormatAmount(remaining);
  const outstanding =
    closed && plans[0]?.subStatus === 'PAID_OFF' ? outstandingChargeOff : outstandingClosed || outstandingChargeOff;

  useEffect(() => {
    setStatement(
      getStatements(loan)?.filter((statement) => {
        return (
          !statement?.servicingTitle?.includes(FEE_CODES.CONVEINCE) &&
          !statement?.servicingTitle?.includes(FEE_CODES.PROCESSING)
        );
      }),
    );
    setAmortizations(loan.amortizations);
    setCommitments(loan.commitments);
  }, [loan]);

  const expendedRowHandler = (statement) => {
    if (isExpandable(statement)) {
      const copyStatements = JSON.parse(JSON.stringify(statements));

      const selectedStatement = copyStatements.find((state) => state.id === statement?.id);
      selectedStatement.expanded = !selectedStatement.expanded;
      setStatement(copyStatements);
    }
  };

  const expendedRowFuturePaymentHandler = (amortization) => {
    if (isExpandableFuturePayments(amortization)) {
      const copyAmortizations = JSON.parse(JSON.stringify(amortizations));

      const selectedStatement = copyAmortizations.find((state) => state.id === amortization.id);
      selectedStatement.expanded = !selectedStatement.expanded;
      setAmortizations(copyAmortizations);
    }
  };

  const isExpandable = (statement) =>
    (statement?.type === StatementType.PAYMENT || statement?.type === StatementType.REFUND) &&
    (statement?.interest || statement?.principal);

  const isExpandableFuturePayments = (amortization) => amortization.interest || amortization.principal;

  const futurePaymentList =
    amortizations?.length > 0
      ? SortByDate(cloneAmortizations, 'date')
          ?.filter((amrtz) => amrtz.type.includes('Forecasted'))
          ?.filter((sch) => new Date(sch?.date)?.getTime() > new Date()?.getTime())
      : [];

  const statementFeeLabel = (statement) => {
    try {
      if (statement?.type === FEE_CODES.FEE) {
        if (statement?.servicingTitle?.includes(FEE_CODES.LATE)) {
          return translate(`contracts.active.feeCodes.assessed_late`);
        } else if (statement?.servicingTitle?.includes(FEE_CODES.NSF))
          return translate(`contracts.active.feeCodes.assessed_nsf`);
      } else if (statement?.type === FEE_CODES.REFUND && statement?.chargeOff) {
        return translate(`contracts.active.statementCodes.charge_off`);
      } else {
        return translate(`contracts.active.statementCodes.${statement?.type.toLowerCase()}`);
      }
    } catch (err) {
      return translate(`contracts.active.statementCodes.${statement?.type.toLowerCase()}`);
    }
  };

  const statementFeeSubLabel = (statement) => {
    if (statement?.type === StatementType.REFUND) {
      if (statement?.servicingTitle?.includes('failed'))
        return translate('contracts.active.details.statement.failedLabel');
    }
  };

  const chargeFeeLabel = (title: string) => {
    if (title.includes(FEE_CODES.CONVEINCE)) return translate(`contracts.active.feeCodes.conveince_fee`);
    else if (title.includes(FEE_CODES.LATE)) return translate(`contracts.active.feeCodes.late_fee`);
    else if (title.includes(FEE_CODES.NSF)) return translate(`contracts.active.feeCodes.nsf_fee`);
    else return title;
  };

  return (
    <>
      {!!plans?.[0]?.netChargeOff && (
        <Table labelColor={theme.main.cherryRed}>
          <TableTitle fontWeight={700}>{translate(`contracts.active.details.statement.tableHead.title`)}</TableTitle>
          <Seperator color={theme.main.cherryRed} />
          <TableBottomContainer clickableRow={false} last={true}>
            <DateContent>{FormatDate(chargedOffStatusChangeDate)}</DateContent>
            <Content>{translate(`contracts.active.details.statement.tableFooter.text`)}</Content>
            <Content>{FormatAmount(isArray(plans)[0].netChargeOff)}</Content>
          </TableBottomContainer>
        </Table>
      )}
      {commitments?.length > 0 && (
        <Table>
          <TableTitle fontWeight={700}>{translate('contracts.active.details.commitments.header')}</TableTitle>
          <Seperator></Seperator>
          {SortByDate(cloneCommitments, 'scheduledDate', 'asc')
            ?.filter((commit) => commit.status.includes('ACTIVE'))
            ?.map((commitment, index) => (
              <div key={`commitment${index}`}>
                <TableBottomContainer clickableRow={false} last={index === commitments.length - 1}>
                  <DateContent>
                    <IconContainer />
                    {dayjs(commitment?.scheduledDate).format('MMM DD, YYYY')}
                  </DateContent>
                  <Content>{translate(`contracts.details.promise`)}</Content>
                  <Content paddingRight={24} isItalic={true}>
                    {CapitalizeFirstLetter(commitment.progress)}
                  </Content>
                  <Content>{FormatAmount(commitment.amount)}</Content>
                </TableBottomContainer>
              </div>
            ))}
        </Table>
      )}
      {futurePaymentList?.length > 0 && isArray(plans)[0].payOff > 0 && (
        <Table>
          <TableTitle fontWeight={700}>{translate('contracts.active.details.paymentPlan.header')}</TableTitle>
          <Seperator></Seperator>
          {futurePaymentList?.map((amortization, index) => (
            <div key={`amortization${index}`}>
              <TableBottomContainer
                last={index === futurePaymentList.length - 1}
                clickableRow={isExpandableFuturePayments(amortization)}
                onClick={() => expendedRowFuturePaymentHandler(amortization)}
              >
                <DateContent>
                  {isExpandableFuturePayments(amortization) ? (
                    <IconContainer>{amortization.expanded ? <ChevronUpIcon /> : <ChevronDownIcon />}</IconContainer>
                  ) : (
                    <IconContainer />
                  )}
                  {FormatDate(amortization.date)}
                </DateContent>
                <Content>
                  {translate(`contracts.details.statement.${loan?.autopay ? 'paymentDue' : 'scheduled'}`)}
                </Content>
                <Content>{FormatAmount(amortization.total)}</Content>
              </TableBottomContainer>
              <Collapse in={amortization.expanded}>
                <CollapseInside>
                  <TableExpandedContainer clickableRow={false}>
                    <ExpandedContent />
                    <ExpandedContent bold={true}>{translate(`contracts.details.statement.principal`)}</ExpandedContent>
                    <ExpandedContent bold={true}>{FormatAmount(amortization.principal)}</ExpandedContent>
                  </TableExpandedContainer>
                  <TableExpandedContainer clickableRow={false}>
                    <ExpandedContent />
                    <ExpandedContent bold={true}>{translate(`contracts.details.statement.interest`)}</ExpandedContent>
                    <ExpandedContent bold={true}>{FormatAmount(amortization.interest)}</ExpandedContent>
                  </TableExpandedContainer>
                  {amortization?.fee > 0 && (
                    <TableExpandedContainer clickableRow={false}>
                      <ExpandedContent />
                      <ExpandedContent bold={true}>{translate(`contracts.details.statement.fee`)}</ExpandedContent>
                      <ExpandedContent bold={true} error={false}>
                        {FormatAmount(amortization?.fee)}
                      </ExpandedContent>
                    </TableExpandedContainer>
                  )}
                </CollapseInside>
              </Collapse>
            </div>
          ))}
        </Table>
      )}
      {statements?.length > 0 && (
        <Table>
          <TableTitle fontWeight={700}>{translate(`contracts.active.details.paymentPlan.history`)}</TableTitle>
          <Seperator></Seperator>
          {statements?.map((statement, index) => {
            return (
              <div key={`statements_${index}`}>
                <TableBottomContainer
                  last={index === statements.length - 1}
                  clickableRow={isExpandable(statement)}
                  onClick={() => expendedRowHandler(statement)}
                >
                  <DateContent>
                    {isExpandable(statement) ? (
                      <IconContainer>{statement?.expanded ? <ChevronUpIcon /> : <ChevronDownIcon />}</IconContainer>
                    ) : (
                      <IconContainer />
                    )}
                    {FormatDate(statement?.createdAt)}
                  </DateContent>
                  <Content
                    isDebtRow={statementFeeLabel(statement)?.includes('Fee')}
                    isGreenRow={statement?.type === StatementType.REFUND && !statement?.chargeOff}
                  >
                    {statementFeeLabel(statement)} {statementFeeSubLabel(statement)}
                  </Content>
                  <Content
                    isDebtRow={statementFeeLabel(statement)?.includes('Fee')}
                    isGreenRow={statement?.type === StatementType.REFUND}
                  >
                    {FormatAmount(statement?.type !== 'BALANCE' ? statement?.amount : loan.grossAmount)}
                  </Content>
                </TableBottomContainer>
                <Collapse in={statement?.expanded}>
                  <CollapseInside>
                    <TableExpandedContainer clickableRow={false}>
                      <ExpandedContent />
                      <ExpandedContent bold={true}>
                        {translate(`contracts.details.statement.principal`)}
                      </ExpandedContent>
                      <ExpandedContent bold={true}>{FormatAmount(statement?.principal)}</ExpandedContent>
                    </TableExpandedContainer>
                    <TableExpandedContainer clickableRow={false}>
                      <ExpandedContent />
                      <ExpandedContent bold={true}>{translate(`contracts.details.statement.interest`)}</ExpandedContent>
                      <ExpandedContent bold={true}>{FormatAmount(statement?.interest)}</ExpandedContent>
                    </TableExpandedContainer>
                    {statement?.fees > 0 &&
                      statement?.charges?.map((charge, index) => {
                        return (
                          <TableExpandedContainer clickableRow={false} key={index}>
                            <ExpandedContent />
                            <ExpandedContent bold={true} error={true}>
                              {chargeFeeLabel(charge.info)}
                            </ExpandedContent>
                            <ExpandedContent bold={true} error={true}>
                              {FormatAmount(charge.amount)}
                            </ExpandedContent>
                          </TableExpandedContainer>
                        );
                      })}
                  </CollapseInside>
                </Collapse>
              </div>
            );
          })}
          {debtSales?.amount && (
            <TableBottomContainer clickableRow={false} last={true}>
              <DateContent isDebtRow={true}>
                <IconContainer />
                {FormatDate(debtSales?.soldDate)}
              </DateContent>
              <Content isDebtRow={true}>
                {translate(`contracts.sold.paymentTableInfo`, {
                  daysPastDue: 120,
                })}
              </Content>
            </TableBottomContainer>
          )}
          <Seperator margin="12px" />
          <TableBottomContainer clickableRow={false} last={true}>
            <Body fontWeight={700} color={isChargeOff || closed ? theme.main.cherryRed : theme.main.midnightBlue}>
              {translate(`contracts.past.loanStatus.unsatisfied`)}
            </Body>
            <Body fontWeight={700} color={isChargeOff || closed ? theme.main.cherryRed : theme.main.midnightBlue}>
              {outstanding}
            </Body>
          </TableBottomContainer>
        </Table>
      )}
    </>
  );
};

const IconContainer = styled.div`
  display: flex;
  width: 20px;
`;

const Table = styled.div<{ labelColor?: string }>`
  display: flex;
  flex-direction: column;
  margin-top: 40px;

  * {
    color: ${(props) => !!props.labelColor && props.labelColor} !important;
    opacity: ${(props) => !!props.labelColor && 1} !important;
  }
`;

const TableTitle = styled(Body)`
  font-style: normal;
  font-size: 11px !important;
  letter-spacing: 0.55px;
  text-transform: uppercase;
`;

const CollapseInside = styled.div`
  padding: 10px 0;
`;

const TableExpandedContainer = styled.div<TableRowProps>`
  display: flex;
  flex: 1;
  margin-bottom: 10px;
  &:last-child {
    margin-bottom: 0;
  }
`;

const TableBottomContainer = styled.div<TableRowProps>`
  cursor: ${(props) => (props.clickableRow ? 'pointer' : 'default')};
  display: flex;
  justify-content: flex-end;
  gap: 8px;
  border-bottom: ${(props) => (props.last ? 'none' : `1px solid ${theme.main.midnightBlue20}`)};
`;

const Content = styled.span<ContentProps>`
  font-size: 12px;
  line-height: 26px;
  color: ${(props) => getColor(props)};
  font-weight: ${(props) => (props.bold ? '700' : 'normal')};
  padding: 10px 0;
  display: flex;
  align-items: center;
  padding-right: ${(props) => `${props.paddingRight}px` || 0};
  font-style: ${(props) => (props.isItalic ? 'italic' : 'normal')};
  &:nth-child(1) {
    width: 100px;
    text-align: left;
  }

  &:nth-child(2) {
    flex: 1;
    text-align: left;
    padding-left: 10px;
  }

  &:nth-child(3) {
    width: 64px;
    justify-content: flex-end;
  }
`;

const DateContent = styled(Content)`
  color: ${theme.main.midnightBlue50};
`;

const Seperator = styled.div<{ color?: string; margin?: string }>`
  background-color: ${(props) => (props.color ? props.color : theme.main.midnightBlue)};
  height: 1px;
  margin-bottom: ${(props) => (props.margin ? props.margin : '5px')};
`;

const ExpandedContent = styled.div<ContentProps>`
  font-size: 10px;
  line-height: 14px;
  color: ${(props) => (props.error ? theme.main.cherryRed : theme.main.midnightBlue50)};
  font-weight: 400;
  display: flex;
  align-items: center;

  &:nth-child(1) {
    width: 100px;
    text-align: left;
  }

  &:nth-child(2) {
    flex: 1;
    text-align: left;
    padding-left: 10px;
  }

  &:nth-child(3) {
    width: 64px;
    justify-content: flex-end;
  }
`;
const getColor = (props: ContentProps) => {
  if (props.isDebtRow) {
    return theme.main.cherryRed;
  } else if (props.isGreenRow) {
    return theme.main.kellyGreen;
  } else {
    return theme.main.midnightBlue;
  }
};
