import {
  Button,
  ButtonColor,
  Container,
  DesktopDatePicker,
  InnerContainer,
  RadioGroup,
  Title,
  Stepper,
} from 'lib/components';
import useStore from 'lib/hooks/useStore';
import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import dayjs from 'dayjs';
import { FormatAmount } from 'lib/utils';
import CurrencyInput from 'react-currency-input-field';
import { useNavigate, useParams } from 'react-router-dom';
import { SettlementPaymentTypes } from 'lib/types';
import { SEGMENT_EVENT_NAMES, SETTLEMENT_FLOW_STEPPER_CONFIG } from 'lib/constans';
import { useSegment } from 'lib/hooks/useSegment';

const OneTimePayment = () => {
  const { t } = useTranslation();
  const { setHeaderTitle, selectedChargeOffLoan } = useStore();
  const navigate = useNavigate();
  const { id } = useParams();
  const { trackSegmentEvent } = useSegment();

  const now = dayjs(new Date()).format('YYYY-MM-DD');
  const [dateValue, setDateValue] = useState(now);
  const [amountSelection, setAmountSelection] = useState<string>();
  const [otherAmount, setOtherAmount] = useState<string>('');
  const [showOtherAmountError, setShowOtherAmountError] = useState<boolean>(false);

  const outstandingBalance = selectedChargeOffLoan?.plans?.[0]?.netChargeOff;
  const selectedAmount = amountSelection === 'Other' ? parseFloat(otherAmount) : amountSelection;
  const isContinueButtonEnabled = dateValue?.length > 0 && selectedAmount && !showOtherAmountError;
  const maxDate = dayjs().add(2, 'weeks');

  useEffect(() => {
    trackSegmentEvent(SEGMENT_EVENT_NAMES.PATIENT_PORTAL.ONE_TIME_PAYMENT.PAGE_LOAD);
  }, []);

  useEffect(() => {
    setHeaderTitle('makePayment.title');
  }, [setHeaderTitle]);

  useEffect(() => {
    if (amountSelection && Number(amountSelection) === outstandingBalance) {
      trackSegmentEvent(SEGMENT_EVENT_NAMES.PATIENT_PORTAL.ONE_TIME_PAYMENT.OUTSTANDING_BALANCE_SELECTED);
    } else if (amountSelection === 'Other') {
      trackSegmentEvent(SEGMENT_EVENT_NAMES.PATIENT_PORTAL.ONE_TIME_PAYMENT.OTHER_AMOUNT_SELECTED);
    }
  }, [amountSelection]);

  const onAmountSelect = (selection: string) => {
    setAmountSelection(selection);
  };

  const handleDateChange = (value) => {
    if (value) {
      trackSegmentEvent(SEGMENT_EVENT_NAMES.PATIENT_PORTAL.ONE_TIME_PAYMENT.DATE_SELECTED, {
        date: new Date(value).toDateString(),
      });
      setDateValue(new Date(value).toDateString());
    }
  };

  const onOtherAmountChange = (nativeEvent) => {
    setOtherAmount(nativeEvent);
    const amount = parseFloat(nativeEvent);
    if (amount > outstandingBalance) {
      setShowOtherAmountError(true);
    } else {
      setShowOtherAmountError(false);
    }
  };

  const onOtherAmountBlur = () => {
    if (otherAmount) {
      trackSegmentEvent(SEGMENT_EVENT_NAMES.PATIENT_PORTAL.ONE_TIME_PAYMENT.OTHER_AMOUNT_FILLED, {
        amount: FormatAmount(parseFloat(otherAmount)),
      });
    }
  };

  const setDateToToday = () => {
    trackSegmentEvent(SEGMENT_EVENT_NAMES.PATIENT_PORTAL.ONE_TIME_PAYMENT.TODAY_CLICKED);
    setDateValue(new Date().toDateString());
  };

  const goBack = () => {
    trackSegmentEvent(SEGMENT_EVENT_NAMES.PATIENT_PORTAL.ONE_TIME_PAYMENT.BACK_TO_REPAYMENT_SELECTION_CLICKED);
    navigate(-1);
  };

  const goToPaymenMethod = () => {
    trackSegmentEvent(SEGMENT_EVENT_NAMES.PATIENT_PORTAL.ONE_TIME_PAYMENT.CONTINUE_TO_PAYMENT_METHOD_CLICKED);
    navigate(`/portal/${id}/payment-method`, {
      state: {
        isSettlementFlow: true,
        amount: selectedAmount,
        paymentDate: dateValue,
        type: SettlementPaymentTypes.ONE_TIME_PAYMENT,
        installmentCount: 1,
      },
    });
  };

  const options = useMemo(
    () => [
      {
        value: outstandingBalance,
        content: (
          <Row>
            <div>{t('oneTimePayment.outstandingBalance')}</div>
            <b>{FormatAmount(outstandingBalance)}</b>
          </Row>
        ),
      },
      {
        value: 'Other',
        content: (
          <div>
            <div>{t('oneTimePayment.other')}</div>
            {amountSelection === 'Other' && (
              <div>
                <CustomCurrencyInput
                  prefix="$ "
                  value={otherAmount}
                  onChange={onOtherAmountChange}
                  allowNegativeValue={false}
                  onBlur={onOtherAmountBlur}
                />
                <ErrorContainer>
                  {showOtherAmountError
                    ? t('oneTimePayment.greaterError', { amount: FormatAmount(outstandingBalance) })
                    : ''}
                </ErrorContainer>
              </div>
            )}
          </div>
        ),
      },
    ],
    [amountSelection, otherAmount, showOtherAmountError],
  );

  return (
    <Container block={true}>
      <InnerContainer>
        <PaymentSelection>
          <Stepper
            totalStepCount={SETTLEMENT_FLOW_STEPPER_CONFIG.TOTAL_PAGES}
            activeStep={SETTLEMENT_FLOW_STEPPER_CONFIG.ACTIVE_PAGE.ONE_TIME_PAYMENT}
          />
          <PageTitle>{t('oneTimePayment.title')}</PageTitle>
          <Section>
            <Text>{t('oneTimePayment.amountToPay')}</Text>
            <RadioGroupContainer>
              <RadioGroup options={options} onChange={onAmountSelect} gapDistance="low" />
            </RadioGroupContainer>
          </Section>
          <Section>
            <Text>{t('oneTimePayment.paymentDate')}</Text>
            <DateContainer>
              <DesktopDatePicker value={dateValue} onDateChange={handleDateChange} maxDate={maxDate} />
              <TodayButton onClick={setDateToToday}>{t('oneTimePayment.today')}</TodayButton>
            </DateContainer>
            <Footnote>{t('oneTimePayment.dateSubInfo')}</Footnote>
          </Section>
          <ButtonContainer>
            <Button color={ButtonColor.secondary} onClick={goBack}>
              {t('genericButton.back')}
            </Button>
            <Button onClick={goToPaymenMethod} disabled={!isContinueButtonEnabled}>
              {t('genericButton.continue')}
            </Button>
          </ButtonContainer>
        </PaymentSelection>
      </InnerContainer>
    </Container>
  );
};

const PaymentSelection = styled.div`
  display: flex;
  gap: 24px;
  flex-direction: column;
  padding: 24px;
  max-width: 600px;
  border-radius: 8px;
  background: ${(props) => props.theme.main.white};
  border-bottom: 1px solid ${(props) => props.theme.main.cardBorderColor};
  box-shadow: 0px 6px 9px rgba(0, 0, 0, 0.07), 0px 0.751293px 1.12694px rgba(0, 0, 0, 0.035);
`;

const PageTitle = styled(Title)`
  color: ${(props) => props.theme.main.primary};
`;

const Section = styled.div``;

const Text = styled.div``;

const RadioGroupContainer = styled.div`
  margin-top: 8px;
`;

const DateContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 16px;
  margin-top: 8px;
`;

const TodayButton = styled.button`
  color: ${(props) => props.theme.main.primary};
  font-size: 16px;
  font-weight: 400;
  text-decoration-line: underline;
  background-color: ${(props) => props.theme.main.white};
  border: none;
  outline: none;
  padding: 0;
  margin: 0;
  cursor: pointer;
`;

const Footnote = styled.i`
  color: ${(props) => props.theme.main.primary};
  font-size: 12px;
  font-weight: 400;
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 16px;

  button {
    margin: 0;
  }
`;

const Row = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const CustomCurrencyInput = styled(CurrencyInput)`
  border: 1px solid ${(props) => props.theme.main.neutralGray};
  background-color: ${(props) => props.theme.main.white};
  color: ${(props) => props.theme.main.primary};
  margin-top: 10px !important;
  border-radius: 4px;
  height: 56px;
  padding-left: 16px;
  outline: none;
  width: 90%;

  font-size: 16px;
  font-weight: 400;
`;

const ErrorContainer = styled.div`
  position: relative;
  font-size: 14px;
  color: ${(props) => props.theme.main.red};
`;

export default OneTimePayment;
