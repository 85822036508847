import { useApolloClient } from '@apollo/client';
import Checkbox from '@mui/material/Checkbox';
import { useEffect, useMemo, useState } from 'react';
import { useAlert } from 'react-alert';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import styled from 'styled-components';
import dayjs from 'dayjs';

import { Container, Stepper, Subtitle, TextField } from 'lib/components';
import { Button, ButtonColor } from 'lib/components';
import { AnalyticEventNames, useAnalytics } from 'lib/hooks/useAnalytics';
import { usePayments } from 'lib/hooks/usePayment';
import useStore from 'lib/hooks/useStore';
import useGuestMode from 'lib/hooks/useGuestMode';

import {
  calculatePaymentFeeAmount,
  calculatePaymentFeeTotalAmount,
  CapitalizeWords,
  findNextDueDate,
  FormatAmount,
  useFormatDate,
  isArray,
  isEmail,
} from 'lib/utils';

import { GET_LOAN_LIST } from 'lib/graphql/queries';
import { SegmentEventNames, useSegment } from 'lib/hooks/useSegment';
import { useSentry } from 'lib/hooks/useSentry';
import { OperatorEnum, SettlementPaymentTypes } from 'lib/types';
import { HeaderTitle } from 'pages/Payments/components';
import { PaymentBreakdown } from 'pages/Payments/components/PaymentBreakdown';
import { PAYMENT_TYPE, SEGMENT_EVENT_NAMES, SETTLEMENT_FLOW_STEPPER_CONFIG } from 'lib/constans';
import { useCreateChargedOffOffer } from 'lib/hooks/useChargedOffLoan';
import { useUpdateBorrower } from 'lib/hooks/useAccount';
import { ThreeDFrame } from 'pages/Account/Payment/components';
import { useErrorModal } from 'lib/hooks/useModal';
import { getSegmentEventDetailsData } from 'lib/utils/SegmentEventData';

const PaymentReview = () => {
  const alert = useAlert();
  const client = useApolloClient();
  const { id } = useParams();
  const { t: translate } = useTranslation();
  const navigate = useNavigate();
  const { FormatDate } = useFormatDate();

  const { trackEvent } = useAnalytics();
  const { captureException } = useSentry();
  const { trackSegmentEvent, trackPage } = useSegment();
  const location = useLocation();

  const [processPayment, { data }] = usePayments();
  const [createChargedOffOffer, { data: chargedOffData }] = useCreateChargedOffOffer();
  const [updateBorrower] = useUpdateBorrower();
  const errorModal = useErrorModal(data?.makePayment?.message);

  const {
    amountOfPayment,
    paymentMethod,
    paymentInfo,
    borrower,
    getActiveLoan,
    paymentMethodFee,
    selectedPrincipalOnly,
    paymentType: paymentSelectedType,
  } = useStore();

  const [checked, setChecked] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [threeDFrameVisibility, setThreeDFrameVisibility] = useState<boolean>(false);
  const [email, setEmail] = useState<string>();
  const [valid, setValid] = useState(false);
  const [showError, setShowError] = useState(false);

  const activeLoan = getActiveLoan(id);

  const {
    isSettlementFlow,
    amount: settlementAmount,
    paymentDate: settlementPaymentDate,
    installmentCount: settlementInstallmentCount,
    frequency: settlementFrequeyncy,
    type: settlementType,
  } = (location?.state as never) || {};

  const { plans } = activeLoan || {};

  const isChargedOff = activeLoan?.isChargedOff;
  const isLatePayment = isArray(plans)[0]?.status === 'LATE';
  const segmentEventData = getSegmentEventDetailsData(borrower, activeLoan);

  const { achAccount, rccAccount, storedCard, type } = paymentMethod || {};
  const paymentType = type === 'CARD' ? 'card' : 'ach';
  const isOtherSelected = paymentSelectedType === PAYMENT_TYPE.CUSTOM;

  const isMethodCreditCard = paymentMethod.type === 'CARD' && paymentMethod?.storedCard?.type !== 'DEBIT';
  const paymentFeeAmount = calculatePaymentFeeAmount(
    paymentMethodFee,
    paymentMethod?.storedCard?.type,
    amountOfPayment,
  );
  const paymentFeeTotalAmount = calculatePaymentFeeTotalAmount(
    paymentMethodFee,
    paymentMethod?.storedCard?.type,
    amountOfPayment,
  );
  const { isGuestMode } = useGuestMode();

  useEffect(() => {
    trackPage(SEGMENT_EVENT_NAMES.PATIENT_PORTAL.MAKE_A_PAYMENT_SUMMARY.PAGE_LOAD);
  }, []);

  useEffect(() => {
    if (!isSettlementFlow && amountOfPayment <= 0) {
      navigate(`/portal/purchases`);
    }
  }, [isSettlementFlow, amountOfPayment]);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    trackSegmentEvent(SEGMENT_EVENT_NAMES.PATIENT_PORTAL.MAKE_A_PAYMENT_SUMMARY.CONSENT_CHECKBOX_CLICKED, {
      ...segmentEventData,
      value: !checked,
    });
    setChecked(event.target.checked);
  };

  const handleCheckboxChange = () => {
    setChecked((prev) => !prev);
  };

  useEffect(() => {
    if (data?.makePayment) {
      setLoading(false);
      if (['APPROVED', 'PENDING'].includes(data?.makePayment?.status) && !data?.makePayment?.threeD) {
        navigate(`/portal/${activeLoan.id}/payment-success`);
      } else if (data?.makePayment?.status === 'PENDING' && data?.makePayment?.threeD) {
        setThreeDFrameVisibility(true);
      } else {
        errorModal.openErrorModal();
      }
    }
  }, [data]);

  const threeDSuccess = () => {
    navigate(`/portal/${activeLoan.id}/payment-success`);
  };

  useEffect(() => {
    if (chargedOffData?.createChargedOffOffers) {
      setLoading(false);
      if (chargedOffData?.createChargedOffOffers?.success) {
        navigate(`/portal/${activeLoan.id}/payment-success`, {
          state: {
            isSettlementFlow: true,
            amount: settlementAmount,
          },
        });
      } else {
        alert.error(translate('makePayment.paymentReview.error'));
      }
    }
  }, [chargedOffData]);

  const handleReview = async () => {
    const paymentAmount = isSettlementFlow ? settlementAmount : amountOfPayment;

    if (isSettlementFlow) {
      if (settlementType === SettlementPaymentTypes.ONE_TIME_PAYMENT) {
        trackSegmentEvent(SEGMENT_EVENT_NAMES.PATIENT_PORTAL.ONE_TIME_PAYMENT.COMPLETE_PAYMENT_CLICKED, {
          loan_id: activeLoan?.id,
          paymentMethodId: paymentMethod?.id,
          paymentAmount: FormatAmount(paymentAmount),
        });
      } else if (settlementType === SettlementPaymentTypes.PaymentPlan) {
        trackSegmentEvent(SEGMENT_EVENT_NAMES.PATIENT_PORTAL.PAYMENT_PLAN.COMPLETE_PAYMENT_CLICKED, {
          loan_id: activeLoan?.id,
          paymentMethodId: paymentMethod?.id,
          paymentAmount: FormatAmount(paymentAmount),
        });
      } else if (settlementType === SettlementPaymentTypes.SettlementOffer) {
        trackSegmentEvent(SEGMENT_EVENT_NAMES.PATIENT_PORTAL.SETTLEMENT.COMPLETE_PAYMENT_CLICKED, {
          loan_id: activeLoan?.id,
          paymentMethodId: paymentMethod?.id,
          paymentAmount: FormatAmount(paymentAmount),
        });
      }
    } else {
      trackSegmentEvent(SegmentEventNames.PAYMENT_CONFIRMED, {
        loan_id: activeLoan?.id,
        paymentMethodId: paymentMethod?.id,
        paymentAmount: paymentAmount,
      });
    }

    try {
      setLoading(true);
      const { data: { fetchLoanList } = {} } = await client.query({
        query: GET_LOAN_LIST,
        variables: {
          input: {
            pagination: { limit: 100 },
            search: [
              {
                key: 'status',
                value: 'FUNDED,REFUNDED',
                operator: OperatorEnum.IN,
              },
            ],
          },
        },
      });
      const activeLoanObject = fetchLoanList?.contents?.find((loan) => loan.id === id);
      if (activeLoanObject?.servicing?.status !== 'PROCESSING') {
        trackEvent(AnalyticEventNames.MP_MAKE_PAYMENT);
        if (isSettlementFlow) {
          updateBorrowerEmail();
          createChargedOffOffer({
            variables: {
              input: {
                loanId: Number(activeLoan?.id),
                amount: settlementAmount * settlementInstallmentCount,
                startDate: new Date(settlementPaymentDate),
                paymentMethodId: Number(paymentMethod?.id),
                installmentCount: settlementInstallmentCount,
                frequency: settlementFrequeyncy,
                subType: settlementType,
              },
            },
          });
        } else {
          trackSegmentEvent(
            SEGMENT_EVENT_NAMES.PATIENT_PORTAL.MAKE_A_PAYMENT_SUMMARY.PROCESS_PAYMENT_CLICKED,
            segmentEventData,
          );
          processPayment({
            variables: {
              input: {
                amount: amountOfPayment,
                extra: isOtherSelected ? 'OTHER' : 'NEXT',
                loanId: activeLoan?.id,
                paymentMethodId: paymentMethod?.id,
                type: 'SELF',
                principalOnly: selectedPrincipalOnly,
              },
            },
          });
        }
      } else {
        setLoading(false);
        alert.info(translate('contracts.isPaymentProcessMessage'));
      }
    } catch (error) {
      setLoading(false);
      captureException(error, { errorContext: 'handleReview => PaymentReview.tsx' });
    }
  };

  const goBackHandler = () => {
    trackSegmentEvent(SEGMENT_EVENT_NAMES.PATIENT_PORTAL.MAKE_A_PAYMENT_SUMMARY.BACK_CLICKED, segmentEventData);
    navigate(-1);
  };

  const paymentMethodInfo = () => {
    if (storedCard) {
      return (
        <>
          <Label>
            {CapitalizeWords(storedCard?.network?.toLowerCase())} - {CapitalizeWords(storedCard?.type?.toLowerCase())}
          </Label>
          <Label>**** {storedCard?.last4}</Label>
        </>
      );
    } else {
      const accountKey = type === 'ACH' ? achAccount : rccAccount;
      return (
        <>
          <Label>{accountKey?.bankName}</Label>
          <Label>**** {accountKey?.accountNumber?.substring(accountKey?.accountNumber?.length - 4)}</Label>
        </>
      );
    }
  };

  const paymentAmount = isMethodCreditCard && paymentFeeAmount > 0 ? paymentFeeTotalAmount : amountOfPayment;
  const formattedAmount = FormatAmount(isSettlementFlow ? settlementAmount : paymentAmount);

  const isScheduledDate = useMemo(
    () =>
      isSettlementFlow &&
      !dayjs(dayjs(new Date(settlementPaymentDate)).format('YYYY-MM-DD')).isSame(
        dayjs(new Date()).format('YYYY-MM-DD'),
      ),
    [isSettlementFlow, settlementPaymentDate],
  );

  const updateBorrowerEmail = () => {
    if (email?.length) {
      updateBorrower({
        variables: { input: { id: borrower?.idBorrower || borrower?.id, email } },
      });
    }
  };

  const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const data = e.target?.value;
    const isValid = isEmail(data);

    setEmail(data || '');
    setValid(isValid);

    if (isValid || data?.length === 0) {
      setShowError(false);
    }
  };

  const handleEmailValidation = () => {
    const isValid = email ? isEmail(email) : false;

    if (settlementType === SettlementPaymentTypes.ONE_TIME_PAYMENT) {
      trackSegmentEvent(SEGMENT_EVENT_NAMES.PATIENT_PORTAL.ONE_TIME_PAYMENT.EMAIL_FILLED, {
        email,
      });
    } else if (settlementType === SettlementPaymentTypes.PaymentPlan) {
      trackSegmentEvent(SEGMENT_EVENT_NAMES.PATIENT_PORTAL.PAYMENT_PLAN.EMAIL_FILLED, {
        email,
      });
    } else if (settlementType === SettlementPaymentTypes.SettlementOffer) {
      trackSegmentEvent(SEGMENT_EVENT_NAMES.PATIENT_PORTAL.SETTLEMENT.EMAIL_FILLED, {
        email,
      });
    }

    setShowError(email === '' ? false : !isValid);
  };

  const emailValid = email && email.length > 0 && !valid && isSettlementFlow;
  const isPayButtonDisabled = !checked || loading || isGuestMode || emailValid;

  const nextSchedule = findNextDueDate(activeLoan?.schedules);
  const dueDate = nextSchedule?.dueAt;

  return (
    <>
      <Container block={true}>
        {!threeDFrameVisibility ? (
          <PaymentSelection>
            {isSettlementFlow && (
              <Stepper
                totalStepCount={SETTLEMENT_FLOW_STEPPER_CONFIG.TOTAL_PAGES}
                activeStep={SETTLEMENT_FLOW_STEPPER_CONFIG.ACTIVE_PAGE.PAYMENT_REVIEW}
              />
            )}
            <HeaderTitle title={translate(`makePayment.paymentReview.info`)} />
            {isScheduledDate && (
              <HeaderSubtitle>
                {translate(`makePayment.paymentReview.scheduled`, {
                  date: FormatDate(settlementPaymentDate),
                })}
              </HeaderSubtitle>
            )}
            <PaymentInfoBox>
              <PaymentMethodContainer>
                <MerchantTitle>{activeLoan?.merchant?.name}</MerchantTitle>
                {paymentMethodInfo()}
              </PaymentMethodContainer>
              {isMethodCreditCard && paymentFeeAmount > 0 && (
                <>
                  <PaymentInfoRow>
                    <PaymentInfoText>{translate('makePayment.paymentReview.paymentAmount')}</PaymentInfoText>
                    <PaymentInfoText>{FormatAmount(amountOfPayment)}</PaymentInfoText>
                  </PaymentInfoRow>

                  <PaymentInfoRow>
                    <PaymentInfoText>{translate('makePayment.paymentReview.cardFee')}</PaymentInfoText>
                    <PaymentInfoText>{FormatAmount(paymentFeeAmount)}</PaymentInfoText>
                  </PaymentInfoRow>
                </>
              )}
              <PaymentInfoRow>
                <PaymentInfoText bold={true}>
                  {translate(
                    isScheduledDate
                      ? 'makePayment.paymentReview.settlement.totalPayment'
                      : 'makePayment.paymentReview.totalPayment',
                  )}
                </PaymentInfoText>
                <PaymentInfoText bold={true}>{formattedAmount}</PaymentInfoText>
              </PaymentInfoRow>
              {!isChargedOff && (
                <>
                  {paymentInfo.dueAmount < 0 && (
                    <PaymentInfoRow>
                      <PaymentInfoText>
                        {isLatePayment
                          ? translate('makePayment.paymentReview.amountPastDue')
                          : translate('makePayment.paymentReview.amountNextInstallment')}
                      </PaymentInfoText>

                      <PaymentInfoText>{FormatAmount(paymentInfo.dueAmount)}</PaymentInfoText>
                    </PaymentInfoRow>
                  )}
                  {paymentInfo.extraForInstallment > 0 && (
                    <PaymentInfoRow>
                      <PaymentInfoText>{translate('makePayment.paymentReview.amountNext')}</PaymentInfoText>
                      <PaymentInfoText>{FormatAmount(paymentInfo.extraForInstallment)}</PaymentInfoText>
                    </PaymentInfoRow>
                  )}
                </>
              )}
              {!isSettlementFlow && (
                <PaymentBreakdown paymentPage={true} loanId={activeLoan?.id} showPrincipalOnly={false} />
              )}

              <InfoBox>{translate('makePayment.paymentReview.achNote')}</InfoBox>

              {activeLoan?.autoPay && dueDate && (
                <InfoBoxWithIcon>
                  <AttentionIcon src="/info_circle.svg" />
                  {translate('makePayment.paymentReview.autoPayNote')} {FormatDate(dueDate)}.
                </InfoBoxWithIcon>
              )}
            </PaymentInfoBox>

            {isSettlementFlow && (
              <EmailUpdateContainer>
                <div>Email address (for automated communications)</div>
                <TextField
                  value={email}
                  placeholder="Email address (optional)"
                  onChange={handleEmailChange}
                  onBlur={handleEmailValidation}
                  error={showError}
                />
                <ErrorContainer>{showError ? translate('general.error.email.notValid') : ''}</ErrorContainer>
              </EmailUpdateContainer>
            )}

            <CheckboxContainer>
              <Checkbox
                data-testid="checkbox"
                onChange={handleChange}
                checked={checked}
                inputProps={{ 'aria-label': 'controlled' }}
                sx={{
                  '&.Mui-checked': {
                    color: '#00c37d',
                  },
                }}
              />
              <StyledP
                onClick={handleCheckboxChange}
                dangerouslySetInnerHTML={{
                  __html: translate(`makePayment.paymentReview.checkboxText.${paymentType}`, {
                    borrowerName: `${borrower.firstName} ${borrower.lastName}`,
                    paymentAmount: formattedAmount,
                    date: isSettlementFlow ? FormatDate(settlementPaymentDate) : FormatDate(new Date()),
                    cardType: storedCard?.type?.toLowerCase() || 'debit',
                  }),
                }}
              />
            </CheckboxContainer>
            <ButtonContainer>
              <Button type="button" m={'0px '} color={ButtonColor.secondary} onClick={goBackHandler}>
                {translate('makePayment.backButton')}
              </Button>
              <Button
                type="button"
                m={'0px'}
                disabled={isPayButtonDisabled}
                color={ButtonColor.primary}
                loading={loading}
                onClick={handleReview}
              >
                {translate('makePayment.paymentReview.makePaymentBtn')} {formattedAmount}
              </Button>
            </ButtonContainer>
          </PaymentSelection>
        ) : (
          <IFrameContainer>
            <ThreeDFrame
              paymentId={data?.makePayment?.id}
              onConnect={threeDSuccess}
              handleClose={setThreeDFrameVisibility}
            />
          </IFrameContainer>
        )}
      </Container>
      {errorModal.errorModalVisibility && errorModal.ModalBody}
    </>
  );
};

const CheckboxContainer = styled.div`
  display: flex;
  font-size: 14px;
  font-family: ${(props) => props.theme.fonts.primary};
  justify-content: flex-start;
  align-items: flex-start;

  p > a {
    color: ${(props) => props.theme.main.linkColor};
    text-decoration: none;

    &:hover {
      color: ${(props) => props.theme.main.linkHoverColor};
      text-decoration: underline;
    }
  }
`;

const StyledP = styled.p`
  margin: 8px 0px 0px 5px;
  cursor: pointer;
`;

const IFrameContainer = styled.div`
  position: absolute;
  top: 90px;
  left: 10px;
  height: calc(100vh - 81px);
  width: 100%;
  background-color: white;
`;

const PaymentSelection = styled.div`
  display: flex;
  flex-direction: column;
  border-bottom: 1px solid ${(props) => props.theme.main.cardBorderColor};
  padding: 24px;
  background: #ffffff;
  box-shadow: 0px 6px 9px rgba(0, 0, 0, 0.07), 0px 0.751293px 1.12694px rgba(0, 0, 0, 0.035);
  border-radius: 8px;
`;

const PaymentInfoBox = styled.div`
  display: flex;
  flex-direction: column;
  border-top: 1px solid #dadada;
  border-radius: 0;
  padding: 16px 0 0;
  margin-bottom: 16px;
  border-bottom: 1px solid #dadada;
`;

const PaymentMethodContainer = styled.div`
  display: flex;
  flex-direction: column;
  border-bottom: 1px solid #dadada;
  padding-bottom: 16px;
  margin-bottom: 8px;
`;

const PaymentInfoRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const PaymentInfoText = styled.span<{ bold?: boolean }>`
  padding-top: 8px;
  font-size: 16px;
  font-weight: ${(props) => (props.bold ? '700' : '400')};
  line-height: 21px;
`;

const MerchantTitle = styled.span`
  font-size: 16px;
  font-weight: 700;
  line-height: 22px;
  color: #0e202f;
  margin-bottom: 8px;
`;

const HeaderSubtitle = styled(Subtitle)`
  font-size: 16px;
  font-weight: 700;
  line-height: 22px;
  color: #0e202f;
  margin-bottom: 8px;
`;

const Label = styled.span`
  font-size: 16px;
  font-weight: 400;
  line-height: 22px;
  color: #0e202f;
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin-top: 24px;
  button {
    &:nth-child(1) {
      margin-right: 15px;
    }
  }
  @media (max-width: ${(props) => props.theme.size.tablet}) {
    flex-direction: column-reverse;
    button {
      &:nth-child(1) {
        margin-right: 0;
      }
      &:nth-child(2) {
        margin-bottom: 15px;
      }
    }
  }
`;

const EmailUpdateContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  font-size: 16px;
  font-weight: 400;
  margin: 8px 0 16px;

  input {
    &::placeholder {
      color: ${(props) => props.theme.main.neutralGray};
      opacity: 1;
    }
  }
`;

const ErrorContainer = styled.div`
  position: relative;
  font-size: 14px;
  color: ${(props) => props.theme.main.red};
`;

const InfoBox = styled.div`
  display: flex;
  justify-content: flex-start;
  margin: 24px 0;
  font-size: 14px;
  font-weight: 400;
  font-family: ${(props) => props.theme.fonts.primary};
`;

const InfoBoxWithIcon = styled(InfoBox)`
  align-items: center;
  font-weight: 600;
  margin-top: 0;
`;

const AttentionIcon = styled.img`
  height: 24px;
  width: 24px;
  margin-right: 10px;
  color: ${(props) => props.theme.main.primary};
`;

export default PaymentReview;
