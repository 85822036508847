import { useLocation, useNavigate, useParams } from 'react-router-dom';
import styled from 'styled-components';
import { Container } from 'lib/components';
import useStore from 'lib/hooks/useStore';
import { useSegment } from 'lib/hooks/useSegment';
import { FormatAmount, calculatePaymentFeeTotalAmount } from 'lib/utils';
import { useEffect } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { HeaderTitle } from '../components';
import useLandingPage from 'lib/hooks/useLandingPage';
import { Button } from '@frontend/cherry-library';
import { SEGMENT_EVENT_NAMES } from 'lib/constans';
import { getSegmentEventDetailsData } from 'lib/utils/SegmentEventData';

const PaymentSuccess = () => {
  const navigate = useNavigate();
  const { t: translate } = useTranslation();
  const { trackPage, trackSegmentEvent } = useSegment();
  const { id } = useParams();
  const location = useLocation();
  const { getAsyncLoanList } = useLandingPage();

  const { amountOfPayment, getActiveLoan, borrower, paymentMethodFee, paymentMethod } = useStore();
  const activeLoan = getActiveLoan(id) || {};
  // @ts-ignore location?.state
  const { isSettlementFlow, amount: settlementAmount } = location?.state || {};
  const amountOfPaymentIncludingFee = calculatePaymentFeeTotalAmount(
    paymentMethodFee,
    paymentMethod?.storedCard?.type,
    amountOfPayment,
  );
  const segmentEventData = getSegmentEventDetailsData(borrower, activeLoan);

  useEffect(() => {
    getAsyncLoanList();
    trackPage(SEGMENT_EVENT_NAMES.PATIENT_PORTAL.MAKE_A_PAYMENT_SUCCESS.PAGE_LOAD, segmentEventData);
  }, []);

  const returnContractDetails = () => {
    trackSegmentEvent(SEGMENT_EVENT_NAMES.PATIENT_PORTAL.MAKE_A_PAYMENT_SUCCESS.OKAY_CLICKED, segmentEventData);
    navigate(`/portal/${id}/purchase`);
  };

  return (
    <Container block={true}>
      <PaymentSelection>
        <HeaderTitle title={translate(`makePayment.paymentSuccess.info`)} />
        <DescriptionContainer>
          <Description>
            <Trans
              i18nKey="makePayment.paymentSuccess.paymentAmount"
              values={{ amount: FormatAmount(isSettlementFlow ? settlementAmount : amountOfPaymentIncludingFee) }}
              components={{ strong: <strong /> }}
            />
          </Description>
          <Description>{translate(`makePayment.paymentSuccess.notifyInfo`)}</Description>
        </DescriptionContainer>
        <Button fullWidth onClick={returnContractDetails}>
          <ButtonText>{translate('general.okay')}</ButtonText>
        </Button>
      </PaymentSelection>
    </Container>
  );
};

const PaymentSelection = styled.div`
  display: flex;
  flex-direction: column;
  border-bottom: 1px solid ${(props) => props.theme.main.cardBorderColor};
  padding: 24px;
  background: #ffffff;
  box-shadow: 0px 6px 9px rgba(0, 0, 0, 0.07), 0px 0.751293px 1.12694px rgba(0, 0, 0, 0.035);
  border-radius: 8px;
`;

const DescriptionContainer = styled.div`
  // margin-bottom: 20px;
`;

const Description = styled.div`
  font-size: 14px;
  font-family: ${(props) => props.theme.fonts.primary};
  margin-bottom: 24px;
`;

const ButtonText = styled.span`
  font-size: 20px;
  font-weight: bold;
`;

export default PaymentSuccess;
