import { Container } from 'lib/components';
import styled from 'styled-components';
import { Heading, theme, Body, Button } from '@frontend/cherry-library';
import useStore from 'lib/hooks/useStore';
import { useEffect } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { goBack } from 'lib/utils/GoBack';
import { useSegment } from 'lib/hooks/useSegment';
import { SEGMENT_EVENT_NAMES } from 'lib/constans';
import { getApplicationDetailData } from 'lib/utils/ApplicationDetailSegmentEventData';

export const UseMyBalance = () => {
  const { selectedApplication: approval, setHeaderTitle, borrower } = useStore();
  const { t: translate } = useTranslation();
  const { trackPage, trackSegmentEvent } = useSegment();
  const segmentEventData = getApplicationDetailData(borrower, approval);

  const { organization } = approval || {};
  const { name } = organization || {};

  useEffect(() => {
    setHeaderTitle('applicationDetails.useMyBalance');
  }, [setHeaderTitle]);

  useEffect(() => {
    trackPage(SEGMENT_EVENT_NAMES.PATIENT_PORTAL.USE_MY_APPROVAL.PAGE_LOAD, segmentEventData);
  }, []);

  const goBackHandler = () => {
    trackSegmentEvent(SEGMENT_EVENT_NAMES.PATIENT_PORTAL.USE_MY_APPROVAL.GOT_IT_CLICKED, segmentEventData);
    goBack();
  };

  return (
    <Container block={true}>
      <WrapperWithGap>
        <Wrapper>
          <Heading level="4" text={translate(`useMyBalance.header`)}></Heading>
          <Body fontWeight={400}>
            <Trans i18nKey="useMyBalance.action" values={{ name }} components={{ bold: <b /> }} />
          </Body>
        </Wrapper>

        <InnerContainer>
          <WrapperWithGapCentered>
            <img height={100} width={175} src="/what-happens-now.png" />
            <Wrapper>
              <Body size="large" fontWeight={400}>
                {translate(`useMyBalance.atCherry`, { name })}
              </Body>
              <Body size="large" fontWeight={700}>
                {translate(`useMyBalance.payingWithCherry`)}
              </Body>
            </Wrapper>
          </WrapperWithGapCentered>
        </InnerContainer>
        <Wrapper>
          <Step>
            <StepHeadingContainer>
              <img width={23} height={24} src="/stepper-first.png"></img>
              <Body fontWeight={700}>{translate(`useMyBalance.step.bookAnAppointment`)}</Body>
            </StepHeadingContainer>
            <Body fontWeight={400}>{translate(`useMyBalance.step.bookAnAppointmentText`)}</Body>
          </Step>
          <Step>
            <StepHeadingContainer>
              <img width={23} height={24} src="/stepper-second.png"></img>
              <Body fontWeight={700}>{translate(`useMyBalance.step.checkout`)}</Body>
            </StepHeadingContainer>
            <Body fontWeight={400} dangerouslySetInnerHTML={{ __html: translate('useMyBalance.step.checkoutText') }} />
          </Step>
          <Step>
            <StepHeadingContainer>
              <img width={23} height={24} src="/stepper-third.png"></img>
              <Body fontWeight={700}>{translate(`useMyBalance.step.selectPaymentPlan`)}</Body>
            </StepHeadingContainer>
            <Body fontWeight={400}>{translate(`useMyBalance.step.selectPaymentPlanText`)}</Body>
          </Step>
        </Wrapper>
        <Button onClick={goBackHandler} fullWidth>
          {translate(`common.gotIt`)}
        </Button>
      </WrapperWithGap>
    </Container>
  );
};

const InnerContainer = styled.div`
  margin: 0;
  background-color: ${theme.main.white};
  max-width: 600px;
  padding: 24px;
  flex-direction: column;
  align-items: center;
  text-align: center;
  align-self: stretch;
  border-radius: 24px;
  margin-bottom: 24px;
  box-shadow: 0px 36px 67px 0px rgba(0, 0, 0, 0.07), 0px 13.141px 24.456px 0px rgba(0, 0, 0, 0.05),
    0px 6.38px 11.873px 0px rgba(0, 0, 0, 0.04), 0px 3.127px 5.82px 0px rgba(0, 0, 0, 0.03),
    0px 1.237px 2.301px 0px rgba(0, 0, 0, 0.02);
`;

const WrapperWithGap = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin-bottom: 16px;
`;

const WrapperWithGapCentered = styled(WrapperWithGap)`
  align-items: center;
  justify-content: center;
`;

const Wrapper = styled.div`
  flex-direction: column;
  display: flex;
  gap: 8px;
  margin-bottom: 8px;
`;

const Step = styled.div`
  display: flex;
  flex-direction: column;
`;

const StepHeadingContainer = styled.div`
  display: flex;
  gap: 12px;
  margin: 12px 0;
`;
