import { useEffect, useState } from 'react';
import { useAlert } from 'react-alert';
import styled from 'styled-components';

import { API_URL } from 'config';
import { CustomLoading } from 'lib/components';
import { useSentry } from 'lib/hooks/useSentry';
import { useTranslation } from 'react-i18next';

export const PaymentMethodConnect = ({ borrower, onConnect }) => {
  const alert = useAlert();
  const [loading, setLoading] = useState(true);
  const { captureException } = useSentry();
  const { t: translate } = useTranslation();

  useEffect(() => {
    window.addEventListener('message', receiveMessage, false);
    return () => {
      window.removeEventListener('message', receiveMessage);
    };
  }, []);

  const receiveMessage = ({ origin, data }) => {
    try {
      if (origin === API_URL) {
        const iFrameEvent = JSON.parse(data);

        switch (iFrameEvent.status) {
          case 'expired':
            break;
          case 'error':
            alert.error(iFrameEvent.message);
            setLoading(false);
            break;
          case 'success':
            onConnect(iFrameEvent, 'debit');
            setLoading(false);
            break;
          case 'loading':
            setLoading(true);
            break;
        }
      }
    } catch (err) {
      alert.error(translate('account.payment.method.error'));
      setLoading(false);
      captureException(err, { errorContext: 'receiveMessage => PaymentMethodConnect', data });
    }
  };

  const onIframeLoadHandler = () => {
    setTimeout(() => {
      setLoading(false);
    }, 500);
  };

  if (!borrower.id) {
    captureException({}, { errorContext: 'borrowerId Not Found => PaymentMethodConnect' });
    return (
      <ErrorContainer>
        <CustomLoading />
      </ErrorContainer>
    );
  }

  return (
    <Container>
      <iframe
        id="add-payment-method"
        data-testid="the-frame"
        title={translate('account.payment.method.title')}
        width="100%"
        height="100%"
        frameBorder="0"
        src={`${API_URL}/v1/borrowers/${borrower?.id}/payment-methods/iframe?view=customer`}
        onLoad={onIframeLoadHandler}
      />
      {loading && (
        <LoadingContainer>
          <CustomLoading />
        </LoadingContainer>
      )}
    </Container>
  );
};

const Container = styled.div`
  position: relative;
  height: 255px;
  margin-top: 5px;
`;

const LoadingContainer = styled.div`
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 20px;
  left: 0;
  right: 0;
`;

const ErrorContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 48px;
`;
