import React, { useEffect, useState } from 'react';
import { CardElement, Elements, useElements, useStripe } from '@stripe/react-stripe-js';
import useStore from 'lib/hooks/useStore';
import { useApolloClient, useMutation } from '@apollo/client';
import { PATCH_CUSTOMER_SUBSCRIPTIONS_PAYMENT_METHOD } from 'lib/graphql/mutations/Subscriptions';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { Button, ButtonContainer, Icon, InnerContainer, Subtitle, Title, Loading } from 'lib/components';
import { loadStripe, Stripe } from '@stripe/stripe-js';
import { GET_ORGANIZATION_ACCOUNT_INFORMATION } from 'lib/graphql/queries/Subscriptions';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

const CheckoutForm = ({ selectedMembershipPlan }) => {
  const stripe = useStripe();
  const elements = useElements();
  const { search } = useLocation();
  const { setSubscriptionPaymentMethod } = useStore();
  const [patchCustomerPaymentMethod] = useMutation(PATCH_CUSTOMER_SUBSCRIPTIONS_PAYMENT_METHOD);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const { t: translate } = useTranslation();

  const urlSearchParams = new URLSearchParams(search);

  const payMoney = async (e) => {
    setLoading(true);
    e.preventDefault();
    if (!stripe || !elements) {
      return;
    }

    const stripeCardElement = elements.getElement(CardElement);

    const payload = await stripe.createPaymentMethod({
      type: 'card',
      card: stripeCardElement!,
    });

    const paymentMethod = payload?.paymentMethod;
    const cardInfo = paymentMethod?.card;
    const expireYear = Number(String(cardInfo?.exp_year).slice(-2));
    const {
      data: { patchMembershipsCustomerPaymentMethod },
    } = await patchCustomerPaymentMethod({
      variables: {
        input: {
          customerId: selectedMembershipPlan?.customer?.id,
          organizationId: selectedMembershipPlan?.organizationId,
          paymentMethodId: selectedMembershipPlan?.paymentMethodId,
          providerId: paymentMethod?.id,
          last4: cardInfo?.last4,
          network: cardInfo?.brand,
          type: paymentMethod?.type,
          expireMonth: cardInfo?.exp_month,
          expireYear,
        },
      },
    });

    setSubscriptionPaymentMethod(patchMembershipsCustomerPaymentMethod);

    navigate(`/portal/memberships/${selectedMembershipPlan?.id}`);
    setLoading(false);
  };

  return (
    <form onSubmit={payMoney}>
      <InnerContainer>
        <Icon width={28} height={28} src="credit_card" />
        <Title>{translate('membershipsDetail.replacePaymentMethod')}</Title>
        <Subtitle>
          {translate('membershipsDetail.currentPaymentMethod', { lastDigit: urlSearchParams?.get('last4') })}
        </Subtitle>
        <CardElement
          className="card"
          options={{
            style: {
              base: {
                iconColor: '#000',
                color: '#000',
                fontWeight: '500',
                fontFamily: 'Open Sans, Segoe UI, sans-serif',
                fontSize: '16px',
                fontSmoothing: 'antialiased',
                ':-webkit-autofill': {
                  color: '#a2a2a2',
                },
                '::placeholder': {
                  color: '#a2a2a2',
                },
              },
              invalid: {
                iconColor: '#FFC7EE',
                color: '#FFC7EE',
              },
            },
          }}
        />
      </InnerContainer>
      <ButtonContainer>
        <Button p="15px" onClick={payMoney} type="submit" loading={loading} disabled={loading}>
          {translate('common.continue')}
        </Button>
      </ButtonContainer>
    </form>
  );
};

const MembershipUpdatePaymentMethod = () => {
  const [loading, setLoading] = useState(true);
  const client = useApolloClient();
  const { id } = useParams();
  const { membershipPlans } = useStore();
  const [stripePromise, setStripePromise] = useState<Promise<Stripe | null>>();
  const [selectedMembershipPlan, setSelectedMembershipPlan] = useState();

  const getOrganizationAccountInformation = async () => {
    setLoading(true);
    const findSelectedMembershipPlan = membershipPlans?.find((item) => item.id === String(id));
    const {
      data: { fetchOrganizationAccountInformation },
    } = await client.query({
      query: GET_ORGANIZATION_ACCOUNT_INFORMATION,
      variables: {
        input: {
          organizationId: findSelectedMembershipPlan?.organizationId,
        },
      },
    });

    setSelectedMembershipPlan(findSelectedMembershipPlan);
    setStripePromise(() =>
      loadStripe(fetchOrganizationAccountInformation?.cherryPublicKey, {
        stripeAccount: fetchOrganizationAccountInformation?.organizationAccountId,
      }),
    );
    setLoading(false);
  };

  useEffect(() => {
    getOrganizationAccountInformation();
  }, []);

  if (loading) {
    return <Loading />;
  }

  return (
    <Main>
      {stripePromise && (
        <Elements stripe={stripePromise}>
          <CheckoutForm selectedMembershipPlan={selectedMembershipPlan} />
        </Elements>
      )}
    </Main>
  );
};

export default MembershipUpdatePaymentMethod;

const Main = styled.div`
  display: flex;
  flex: 1;
  overflow: auto;
  justify-content: center;
  text-align: center;
  background-color: #f0f2f4;
  position: fixed;
  height: 100%;
  width: 100%;
`;
