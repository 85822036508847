import { useState } from 'react';
import { useIdleTimer } from 'react-idle-timer';
import { useLocation, useNavigate } from 'react-router-dom';

import StorageService from 'lib/services/Storage';

export const useIdleTimeout = ({ idleTime = 1 }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const idleTimeout = 60000 * idleTime; // Minutes
  const [isIdle, setIdle] = useState(false);

  const handleIdle = () => {
    const isAuthPage = location.pathname === '/' || location.pathname === '/login';

    try {
      if (!isAuthPage) {
        setIdle(true);
        navigate('');
        StorageService.clearUserData();
      }
    } catch (err) {
      console.warn(err);
    }
  };

  const idleTimer = useIdleTimer({
    timeout: idleTimeout,
    promptBeforeIdle: idleTimeout / 2,
    onIdle: handleIdle,
    debounce: 500,
  });

  return {
    isIdle,
    setIdle,
    idleTimer,
  };
};
