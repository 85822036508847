import React, { useEffect, useMemo, useState } from 'react';
import PaymentInstallmentListItem from './PaymentInstallmentListItem';
import styled from 'styled-components';
import { Button, ButtonColor, Subtitle } from 'lib/components';
import { InstallmentMap, SettlementFrequency, SettlementPaymentTypes } from 'lib/types';
import { useTranslation } from 'react-i18next';
import { useLocalPagination } from 'lib/hooks/useLocalPagination';

type PaymentInstallmentListProps = {
  children?: React.ReactNode | React.ReactNode[];
  showList?: boolean;
  paymentStartDate: string;
  frequency?: SettlementFrequency;
  onSelectedPaymentAmount: (val: number) => void;
  paymentType: SettlementPaymentTypes;
  installmentMap: InstallmentMap;
};

const PaymentInstallmentList = ({
  children,
  showList = true,
  paymentStartDate,
  frequency,
  paymentType,
  installmentMap,
  onSelectedPaymentAmount,
}: PaymentInstallmentListProps) => {
  const { t } = useTranslation();
  const { viewList, showMore, resetView } = useLocalPagination({
    list: frequency ? installmentMap?.[paymentType]?.[frequency] : [],
    itemCountPerPage: 10,
  });

  const [selectedInstallmentAmount, setSelectedInstallmentAmount] = useState<number | null>(null);

  const onSelectPaymentAmountChange = (val) => {
    setSelectedInstallmentAmount(val);
    onSelectedPaymentAmount(val);
  };

  const installmentList = useMemo(() => {
    if (frequency) {
      return installmentMap?.[paymentType]?.[frequency] || [];
    }

    return [];
  }, [viewList]);

  useEffect(() => {
    if (installmentList) {
      setSelectedInstallmentAmount(null);
    }
  }, [installmentList]);

  useEffect(() => {
    resetView();
  }, [frequency]);

  const installmentListItems = useMemo(
    () =>
      frequency &&
      viewList.map((installment) => (
        <PaymentInstallmentListItem
          installmentCount={installment.installmentCount}
          installmentAmountPerMonth={installment.installmentAmount}
          onSelect={onSelectPaymentAmountChange}
          paymentStartDate={paymentStartDate}
          selected={selectedInstallmentAmount === installment.installmentCount}
          frequency={frequency}
        />
      )),
    [viewList, selectedInstallmentAmount, frequency, paymentStartDate],
  );

  return (
    <Container>
      <Title>Choose a Payment Plan</Title>
      {showList ? (
        <>
          <SubInfo>{t('paymentInstallmentList.selected.info')}</SubInfo>
          {installmentListItems}
          {installmentMap[paymentType][frequency]?.length > 10 &&
            viewList.length !== installmentMap[paymentType][frequency]?.length &&
            showMore && (
              <ButtonContainer>
                <Button color={ButtonColor.secondary} onClick={showMore}>
                  {t('general.showMore')}
                </Button>
              </ButtonContainer>
            )}
          {children}
          <PaymentPlanFootnote dangerouslySetInnerHTML={{ __html: t('settlement.paymentPlanFootnote') }} />
        </>
      ) : (
        <>
          <SubInfo>({t('paymentInstallmentList.unselected.info')})</SubInfo>
          {children}
        </>
      )}
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  border-radius: 4px;
  border: 1px solid ${(props) => props.theme.main.lightGrayishBlue};
  background: ${(props) => props.theme.main.lightGray};
  padding: 16px;
`;

const Title = styled(Subtitle)`
  color: ${(props) => props.theme.main.primary};
  text-align: center;
  font-size: 16px;
  font-weight: 700;
  margin-bottom: 8px;
`;

const SubInfo = styled.div`
  color: ${(props) => props.theme.main.midnightBlueGray};
  text-align: center;
  font-size: 16px;
  font-weight: 400;
`;

const PaymentPlanFootnote = styled.div`
  font-size: 14px;
  font-weight: 400;
  font-style: italic;
  text-align: center;

  a {
    color: inherit;
  }
`;

const ButtonContainer = styled.div`
  button {
    background-color: transparent;
  }
`;

export default PaymentInstallmentList;
