import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import DocumentRequest from './components/DocumentRequest';
import { Container, Loading } from 'lib/components';
import NoRequests from './components/NoRequest';
import FileUploadModal from 'pages/Documents/components/FileUploadModal';
import { GET_DOCUMENT_REQUEST_LIST } from 'lib/graphql/queries';
import { useApolloClient } from '@apollo/client';
import useStore from 'lib/hooks/useStore';
import { DOCUMENT_STATUS } from 'lib/constans';
import { useTranslation } from 'react-i18next';
import Files from 'pages/Account/Files';
import { MonthlyPDFStatements } from 'pages/Account/MonthlyPDFStatements';
import { Heading } from '@frontend/cherry-library';

interface FileUploadModalConfig {
  open: boolean;
  documentRequest: any;
}
const Documents = () => {
  const client = useApolloClient();
  const { t: translate } = useTranslation();
  const [fileUploadModalOpen, setFileUploadModalOpen] = useState<FileUploadModalConfig>({
    open: false,
    documentRequest: null,
  });
  const { setHeaderTitle } = useStore();
  useEffect(() => {
    setHeaderTitle('menu.listItems.documents');
  }, [setHeaderTitle]);
  const [documentRequestList, setDocumentRequestList] = useState<any[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const handleUploadDocument = (id: string) => {
    const selectedDocument = documentRequestList.find(
      (document) => document.documentRequest?.id === id,
    )?.documentRequest;

    setFileUploadModalOpen({
      open: true,
      documentRequest: selectedDocument,
    });
  };

  const closeUploadModal = () => {
    setFileUploadModalOpen({
      open: false,
      documentRequest: null,
    });
    getAllDocumentRequests();
  };

  useEffect(() => {
    getAllDocumentRequests();
  }, []);

  const getStatus = (status: string) => {
    if (status === 'SUBMITTED') {
      return DOCUMENT_STATUS.submitted;
    }

    return DOCUMENT_STATUS.needed;
  };

  const getAllDocumentRequests = async () => {
    try {
      const { data } = await client.query({
        query: GET_DOCUMENT_REQUEST_LIST,
      });
      const { contents } = data?.getDocumentRequestList || {};
      if (contents?.length) {
        setDocumentRequestList(contents.filter((content) => content.documentRequest.status !== 'CANCELLED'));
      }
      setLoading(false);
    } catch (error) {
      console.warn({ error });
    }
  };
  return (
    <Container block={true} marginBottom={120}>
      {documentRequestList?.length !== 0 && <Heading level="4" text={translate('documentRequest.title')} />}
      {documentRequestList?.map((documentRequest, index) => {
        const status = getStatus(documentRequest?.documentRequest?.status);
        return (
          <DocumentRequest
            key={index}
            documentRequest={documentRequest?.documentRequest}
            status={status}
            uploadDocument={handleUploadDocument}
            externalUserName={documentRequest?.externalUserName}
          />
        );
      })}
      {loading ? <Loading /> : null}
      <FileUploadModal
        open={fileUploadModalOpen?.open}
        handleClose={closeUploadModal}
        documentRequestObject={fileUploadModalOpen?.documentRequest}
      />
      <CardContainer noMargin={!documentRequestList.length}>
        <Heading level="4" text={translate('files.header')} />
        <Files />
      </CardContainer>
      <CardContainer>
        <Heading level="4" text={translate('account.contact.loanStatements')} />
        <MonthlyPDFStatements />
      </CardContainer>
    </Container>
  );
};

const CardContainer = styled.div<{ noMargin?: boolean }>`
  margin-top: ${(props) => (props.noMargin ? '0' : '56px')};
  @media (max-width: ${(props) => props.theme.size.tablet}) {
    margin-top: ${(props) => (props.noMargin ? '0' : '24px')};
  }
`;

export default Documents;
