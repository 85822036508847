import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { RadioButton } from 'lib/components';
import { theme } from '@frontend/cherry-library';

interface Props {
  loan: any;
  data: any;
  handleCardClick: (id: number) => void;
  activeCardID: number | null;
  isCreditCard?: boolean;
}

export const PaymentItem = ({ data, loan, handleCardClick, activeCardID, isCreditCard = false }: Props) => {
  const { t: translate } = useTranslation();
  const { storedCard, achAccount, rccAccount, type } = data || {};
  let isActive: boolean | null = null;

  const accountKey = type === 'ACH' ? achAccount : rccAccount;

  if (activeCardID === null && loan?.autoPay) {
    isActive = loan?.installmentPaymentMethodId === parseInt(data?.id, 10);
  } else {
    isActive = activeCardID === parseInt(data?.id, 10);
  }

  const cardInfo = translate('account.payment.paymentItem.cardTitle', {
    last4: storedCard?.last4,
    expireMonth: storedCard?.expireMonth,
    expireYear: storedCard?.expireYear,
  });

  const [cardNumber, expire] = cardInfo.split(' - ');

  if (isCreditCard) {
    return (
      <Container disabled>
        <RadioContainer>
          <RadioButton disabled />
        </RadioContainer>
        <InfoContainer>
          <DebitCardLogo src={'/credit_card.svg'} />
          <CardInfoContainer>
            <CartTitle>
              {storedCard?.network} - {storedCard?.type}
            </CartTitle>
            <CartDescription color={theme.main.midnightBlue20}>{cardNumber}</CartDescription>
            <CartDescription color={theme.main.midnightBlue20}>{expire}</CartDescription>
            <CartDescription>{translate('paymentItem.disabledExplanation')}</CartDescription>
          </CardInfoContainer>
        </InfoContainer>
      </Container>
    );
  }

  if (data?.type === 'CARD') {
    return (
      <Container checked={isActive} onClick={() => handleCardClick(parseInt(data?.id, 10))}>
        <RadioContainer>
          <RadioButton checked={isActive} />
        </RadioContainer>
        <InfoContainer>
          <DebitCardLogo checked={isActive} src={'/credit_card.svg'} />
          <CardInfoContainer>
            <CartTitle checked={isActive}>
              {storedCard?.network} - {storedCard?.type}
            </CartTitle>
            <CartDescription>{cardNumber}</CartDescription>
            <CartDescription>{expire}</CartDescription>
          </CardInfoContainer>
        </InfoContainer>
      </Container>
    );
  }

  return (
    <Container checked={isActive} onClick={() => handleCardClick(parseInt(data?.id, 10))}>
      <RadioContainer>
        <RadioButton checked={isActive} />
      </RadioContainer>
      <InfoContainer>
        <DebitCardLogo checked={isActive} src={'/bank.svg'} />
        <CardInfoContainer>
          <CartTitle checked={isActive}>{accountKey?.bankName}</CartTitle>
          <CartDescription checked={isActive}>
            {translate('account.payment.paymentItem.achTitle', {
              last4: accountKey?.accountNumber?.substring(accountKey?.accountNumber?.length - 4),
            })}
          </CartDescription>
        </CardInfoContainer>
      </InfoContainer>
    </Container>
  );
};

const Container = styled.div<{ checked?: boolean; disabled?: boolean }>`
  cursor: pointer;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  border-radius: 12px;
  border: ${(props) => {
    if (props.disabled) {
      return `2px solid ${theme.main.midnightBlue20}`;
    } else if (props.checked) {
      return `2px solid ${theme.main.kellyGreen}`;
    } else {
      return `2px solid #dbdee0`;
    }
  }};

  background-color: ${(props) => (props.disabled ? `${theme.main.midnightBlue5}` : `${theme.main.white}`)};
  cursor: pointer;
  margin-bottom: 24px;
  padding: 14px 0px;
  min-height: 65px;
  &:last-child {
    margin-bottom: 0px;
  }
`;

const InfoContainer = styled.div`
  display: flex;
  align-items: center;
`;
const CardInfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

const CartTitle = styled.span<{ checked?: boolean }>`
  font-size: 14px;
  line-height: 20px;
  font-weight: bold;
  color: ${(props) => props.theme.main?.[props.checked ? 'black' : 'midnightBlue']};
`;

const CartDescription = styled.span<{ checked?: boolean; color?: string }>`
  font-size: 14px;
  line-height: 20px;
  margin-bottom: 5px;
  text-align: left;
  margin-bottom: 0 !important;
  color: ${(props) => {
    if (props.color) {
      return `${props.color}`;
    } else if (props.checked) {
      return `${theme.main.black}`;
    } else {
      return `${theme.main.midnightBlue}`;
    }
  }};
`;

const DebitCardLogo = styled.img<{ checked?: boolean }>`
  width: 20px;
  margin: 8px 18px 8px 9px;
  filter: ${(props) =>
    !props.checked && 'invert(55%) sepia(15%) saturate(204%) hue-rotate(166deg) brightness(99%) contrast(91%)'};
`;

const RadioContainer = styled.div`
  min-width: 20px;
  padding-left: 10px;
`;
