import styled from 'styled-components';
import { Body, Button, Heading, Icon, theme } from '@frontend/cherry-library';
import { ReactComponent as SupportImage } from 'assets/images/support.svg';
import { useTranslation } from 'react-i18next';
import { FINDER_URL } from 'config';
import { CardContainer } from 'lib/components/CardContainer';
import { Container } from 'lib/components/Container';
import { SEGMENT_EVENT_NAMES } from 'lib/constans';
import { useSegment } from 'lib/hooks/useSegment';
import useStore from 'lib/hooks/useStore';
import { getSegmentEventBorrowerData } from 'lib/utils/SegmentEventData';

interface Props {
  isApprovalsPage?: boolean;
  isSchedulePage?: boolean;
  isPaymentPlanOverview?: boolean;
}
export const FindPracticeCard = ({ isApprovalsPage, isSchedulePage, isPaymentPlanOverview }: Props) => {
  const { t: translate } = useTranslation();
  const { trackSegmentEvent } = useSegment();
  const { borrower } = useStore();
  const segmentEventBorrowerData = getSegmentEventBorrowerData(borrower);

  const openFinder = () => {
    let segmentEventName = 'FIND_A_PRACTICE_CLICKED';

    if (isApprovalsPage) {
      segmentEventName = SEGMENT_EVENT_NAMES.PATIENT_PORTAL.APPLICATIONS_OVERVIEW.FIND_A_PRACTICE_CLICKED;
    } else if (isPaymentPlanOverview) {
      segmentEventName = SEGMENT_EVENT_NAMES.PATIENT_PORTAL.PAYMENT_PLAN_OVERVIEW.FIND_A_PRACTICE_CLICKED;
    } else if (isSchedulePage) {
      segmentEventName = SEGMENT_EVENT_NAMES.PATIENT_PORTAL.SCHEDULED_PAYMENTS.FIND_A_PRACTICE_CLICKED;
    }
    trackSegmentEvent(segmentEventName, segmentEventBorrowerData);

    window.open(FINDER_URL, '_blank', 'noopener,noreferrer');
  };

  return (
    <Container block={true}>
      <CardContainer>
        <FindCardHeading
          level="4"
          text={translate('upcomingPayments.startTreatment')}
          theme={{
            color: theme.main.midnightBlue,
          }}
        />
        <FindCardBody color={theme.main.midnightBlue} fontWeight={400} size="default">
          {translate('upcomingPayments.findPracticeText')}
        </FindCardBody>
        <FindCardImageWrapper>
          <Icon height={164} width={133} src={SupportImage} />
        </FindCardImageWrapper>

        <Button fullWidth onClick={openFinder}>
          {translate('upcomingPayments.findPractice')}
        </Button>
      </CardContainer>
    </Container>
  );
};

const FindCardBody = styled(Body)`
  width: 216px;
  z-index: 1;
`;

const FindCardHeading = styled(Heading)`
  z-index: 1;
`;

const FindCardImageWrapper = styled.div`
  position: absolute;
  right: 26px;
  top: 8px;
  @media (max-width: ${(props) => props.theme.size.tablet}) {
    right: -7px;
  }
`;
