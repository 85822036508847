const deleteAllCookies = () => {
  try {
    const cookies = document.cookie.split(';');

    for (const cookie of cookies) {
      const eqPos = cookie.indexOf('=');
      const name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
      document.cookie = name + '=;expires=Thu, 01 Jan 1970 00:00:00 GMT';
    }
  } catch (err) {
    console.warn(err);
  }
};

const clearUserData = () => {
  localStorage.clear();
  sessionStorage.clear();
  deleteAllCookies();
};

const setAuthData = (token, refreshToken) => {
  localStorage.setItem('@authToken', token);
  localStorage.setItem('@refreshToken', refreshToken);
};

const getAuthData = () => {
  const token = localStorage.getItem('@authToken');
  const refreshToken = localStorage.getItem('@refreshToken');

  return { token, refreshToken };
};

const setHasApproval = (hasApproval) => {
  localStorage.setItem('@hasApproval', hasApproval);
};

const getHasApproval = () => {
  return localStorage.getItem('@hasApproval') || '';
};
const setSelfUpdateToken = (token: string) => {
  localStorage.setItem('@selfUpdateAuthToken', token);
};

const getSelfUpdateToken = () => {
  const token = localStorage.getItem('@selfUpdateAuthToken') || '';
  return { token };
};
const setSubscriptionsToken = (token: string) => {
  localStorage.setItem('@subscriptionAuthToken', token);
};

const getSubscriptionsToken = () => {
  const token = localStorage.getItem('@subscriptionAuthToken') || '';
  return { token };
};

const Storage = {
  clearUserData,
  setAuthData,
  getAuthData,
  setHasApproval,
  getHasApproval,
  setSubscriptionsToken,
  getSubscriptionsToken,
  setSelfUpdateToken,
  getSelfUpdateToken,
};

export default Storage;
