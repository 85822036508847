import { CustomLoading } from 'lib/components/CustomLoading';
import { PAYMENT_TYPE, SEGMENT_EVENT_NAMES } from 'lib/constans';
import client from 'lib/graphql/client';
import { GET_ESTIMATE_PAYMENT } from 'lib/graphql/queries';
import { useSentry } from 'lib/hooks/useSentry';
import useStore from 'lib/hooks/useStore';
import { findNextDueDate, FormatAmount, useFormatDate, calculatePaymentFeeAmount } from 'lib/utils';
import { useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';
import { Body, Button, Checkbox, theme } from '@frontend/cherry-library';
import { getSegmentEventDetailsData } from 'lib/utils/SegmentEventData';
import { useSegment } from 'lib/hooks/useSegment';

interface Props {
  paymentPage?: boolean;
  loanId?: string;
  showPrincipalOnly?: boolean;
}

interface EstimatePayment {
  principal: number;
  interest: number;
  fees: number;
}

export const PaymentBreakdown = ({ paymentPage, loanId, showPrincipalOnly }: Props) => {
  const { t: translate } = useTranslation();
  const [openBreakdown, setOpenBreakdown] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(true);
  const [result, setResult] = useState<Array<EstimatePayment>>([]);
  const { captureException } = useSentry();
  const { id } = useParams();
  const { FormatDate } = useFormatDate();
  const { trackSegmentEvent } = useSegment();

  const {
    amountOfPayment,
    paymentMethod,
    paymentMethodFee,
    selectedPrincipalOnly,
    setSelectedPrincipalOnly,
    paymentType,
    getActiveLoan,
    borrower,
  } = useStore();

  const activeLoan = getActiveLoan(id) || {};
  const { schedules } = activeLoan;
  const nextSchedule = findNextDueDate(schedules);
  const dueDate = nextSchedule?.dueAt;
  const [principalOnly, setPrincipalOnly] = useState<boolean>(paymentPage ? selectedPrincipalOnly : false);
  const isDisabled = (amountOfPayment === 0 || principalOnly) && !paymentPage;
  const segmentEventPaymentPlanDetailsData = getSegmentEventDetailsData(borrower, activeLoan);
  const segmentEventData = getSegmentEventDetailsData(borrower, activeLoan);

  const otherSelected = paymentType === PAYMENT_TYPE.CUSTOM;
  useEffect(() => {
    setOpenBreakdown(false);
  }, [amountOfPayment]);

  useEffect(() => {
    if (!paymentPage && principalOnly !== selectedPrincipalOnly) {
      setSelectedPrincipalOnly(principalOnly);
    }
  }, []);

  const paymentFeeAmount = calculatePaymentFeeAmount(
    paymentMethodFee,
    paymentMethod?.storedCard?.type,
    amountOfPayment,
  );

  const handleChange = (checked: boolean) => {
    trackSegmentEvent(SEGMENT_EVENT_NAMES.PATIENT_PORTAL.MAKE_PAYMENT_SELECT_AMOUNT.PRINCIPAL_ONLY_SELECTED, {
      principalOnly: { checked },
      ...segmentEventPaymentPlanDetailsData,
    });
    setSelectedPrincipalOnly(checked);
    setPrincipalOnly(checked);
    if (checked) {
      setOpenBreakdown(false);
    }
  };

  const openPaymentBreakDown = (event) => {
    trackSegmentEvent(
      SEGMENT_EVENT_NAMES.PATIENT_PORTAL.MAKE_PAYMENT_SELECT_AMOUNT.SEE_PAYMENT_BREAKDOWN_CLICKED,
      segmentEventPaymentPlanDetailsData,
    );
    setLoading(true);
    event.preventDefault();
    event.stopPropagation();
    setOpenBreakdown(true);
    fetchEstimatePayment();
    trackSegmentEvent(
      SEGMENT_EVENT_NAMES.PATIENT_PORTAL.MAKE_A_PAYMENT_SUMMARY.SEE_PAYMENT_BREAKDOWN_CLICKED,
      segmentEventData,
    );
  };

  const fetchEstimatePayment = async () => {
    try {
      const {
        data: { estimatePayment = {} },
      } = await client.query({
        query: GET_ESTIMATE_PAYMENT,
        variables: {
          input: { loanId: loanId, amount: amountOfPayment, principalOnly: otherSelected ? principalOnly : true },
        },
      });

      const { __typename: _, ...estimatePaymentObj } = estimatePayment;
      const breakdownData = { ...estimatePaymentObj, ...(paymentFeeAmount > 0 && { processingFee: paymentFeeAmount }) };
      setResult(breakdownData);
      setLoading(false);
    } catch (err) {
      captureException(err, { message: 'Get Estimate Payment Catch Error' });
      setOpenBreakdown(false);
    }
  };

  const renderLoading = () => <CustomLoading />;

  const renderTableContent = () => (
    <TableContainer paymentPage={paymentPage}>
      <TableContent>
        <Body size="small" fontWeight={700}>
          {translate('makePayment.paymentBreakdown.title')}
        </Body>
      </TableContent>
      {Object.keys(result).map((key) => (
        <TableContent key={key} data-testid="fileItem">
          <Body size="small">{translate(`makePayment.paymentBreakdown.${key}`)}</Body>
          <Body size="small">{FormatAmount(result[key])}</Body>
        </TableContent>
      ))}
    </TableContainer>
  );

  const renderButtonContainer = () => (
    <ButtonContainer>
      <TransparentButton disabled={isDisabled} variant="secondary" onClick={openPaymentBreakDown} fullWidth>
        {translate('makePayment.paymentBreakdown.breakdownButton')}
      </TransparentButton>
    </ButtonContainer>
  );

  let paymentBreakdownContent;
  if (paymentPage || otherSelected) {
    if (openBreakdown) {
      paymentBreakdownContent = loading ? renderLoading() : renderTableContent();
    } else {
      paymentBreakdownContent = renderButtonContainer();
    }
  }
  return (
    <>
      {paymentBreakdownContent}
      {showPrincipalOnly && (
        <>
          <PrincipalContainer>
            <Checkbox
              onChange={(event) => {
                handleChange(event?.target?.checked);
              }}
              checked={principalOnly}
              size="small"
            />
            <InfoContainer>
              <Body fontWeight={600}>{translate('makePayment.otherAmount.applyToPrincipalOnly')}</Body>
              <Wrapper>
                <AttentionText size="small">
                  <Trans
                    i18nKey="makePayment.paymentBreakdown.principalOnly.yes.text"
                    values={{ due_date: FormatDate(dueDate) }}
                    components={{ strong: <strong /> }}
                  />
                </AttentionText>
              </Wrapper>
            </InfoContainer>
          </PrincipalContainer>
        </>
      )}
    </>
  );
};

const TableContainer = styled.div<Props>`
  background: ${(props) => (props.paymentPage ? '#F2F4F5' : props.theme.main.white)};
  margin-top: 24px;
`;

const TableContent = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 12px 0px;
  margin: 0px 10px;
`;

const ButtonContainer = styled.div`
  margin-top: 24px;
`;

const InfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 12px 16px 12px 0px;
  color: ${theme.main.midnightBlue} !important;
`;

const AttentionText = styled(Body)`
  color: ${theme.main.midnightBlue};
`;

const TransparentButton = styled(Button)`
  background-color: transparent !important;
`;

const PrincipalContainer = styled.div`
  display: flex;
  border-radius: 4px;
  align-items: flex-start;
  border: 1px solid ${theme.main.midnightBlue50};
  background: ${theme.main.white};
  margin-top: 16px;
`;

const Wrapper = styled.div`
  display: flex;
`;
