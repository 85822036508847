export const LANGUAGES = ['en', 'es'];

export const phone = '(888) 839-7171';
export const yellowVoiceBotPhone = '(833) 504-7468';
export const email = 'support@withcherry.com';
export const ALLE = 'ALLE';

export const MM_DD_YYYY = 'MM-DD-YYYY';

export enum PAYMENT_TYPES {
  ACH = 'ACH',
  CARD = 'CARD',
}

export enum SOURCE_TYPES {
  INTERNAL = 'INTERNAL',
  INITIALIZED = 'INITIALIZED',
  GREET = 'GREET',
  PARTNER_API = 'PARTNER_API',
}

export const paymentMethodTypes = [
  {
    name: 'ach',
    value: PAYMENT_TYPES.ACH,
  },
  { name: 'card', value: PAYMENT_TYPES.CARD },
];

export const PLAN_STATUS = ['OPEN', 'LATE', 'CHARGED_OFF'];

export enum LoanStatuses {
  FUNDED = 'FUNDED',
  REFUNDED = 'REFUNDED',
  AWAITING_FUNDING = 'AWAITING_FUNDING',
}

export enum LoanPlanStatuses {
  CHARGED_OFF = 'CHARGED_OFF',
  BANKRUPTCY = 'BANKRUPTCY',
  CLOSED = 'CLOSED',
  REFUND = 'REFUND',
  SETTLED = 'SETTLED',
}

export enum LoanPlanSubStatuses {
  CHARGED_OFF_SUB = 'CHARGED_OFF_SUB',
  DECEASED = 'DECEASED',
  FRAUD = 'FRAUD',
  REFUND = 'REFUND',
}

export enum StatementType {
  PAYMENT = 'PAYMENT',
  REFUND = 'REFUND',
}

export const STATEMENT_CODES = {
  BALANCE: 'Purchase Made',
  DOWN_PAYMENT: 'Down Payment',
  PAYMENT: 'Payment',
  FEE: 'Assessed Late Fee',
  REFUND: 'Refund',
  CHARGE_OFF: 'Charge Off',
};

export const FEE_CODES = {
  FEE: 'FEE',
  REFUND: 'REFUND',
  PROCESSING: 'Processing',
  LATE: 'Late',
  NSF: 'NSF',
  CONVEINCE: 'Convenience',
  LATE_FEE: 'Late Fee',
  CONVEINCE_FEE: 'Convenience Fee',
  NFS_FEE: 'Insufficient Funds Fee',
  ASSESSED_NFS: 'Assessed Insufficient Funds Fee',
  ASSESSED_LATE: 'Assessed Late Fee',
};

export enum ExchangeTokenType {
  APPLICATION_APPROVAL_EXTENSION = 'APPLICATION_APPROVAL_EXTENSION',
  GUEST_BORROWER = 'GUEST_BORROWER',
  SECURE_DOCUMENT_BORROWER = 'SECURE_DOCUMENT_BORROWER',
  BORROWER = 'BORROWER',
  BORROWER_DOCUMENTS = 'BORROWER_DOCUMENTS',
}

export enum USER_TYPES {
  GUEST_BORROWER = 'GUEST_BORROWER',
}

export enum LOAN_PLAN_STATUS {
  OPEN = 'OPEN',
  LATE = 'LATE',
  CHARGED_OFF = 'CHARGED_OFF',
}
export enum LOAN_PLAN_SUBSTATUS {
  PERFORMING = 'PERFORMING',
}

export enum APPROVAL_STATUS {
  COMPLETED = 'completed',
  UNFINISHED = 'unfinished',
  AWAITING_APPROVAL_BOOST = 'awaiting_approval_boost',
}
export enum PURCHASES_STATUS {
  ACTIVE = 'active',
  CLOSED = 'closed',
}

export enum PAYMENT_TYPE {
  DUE = 'due',
  PAYOFF = 'payoff',
  CUSTOM = 'custom',
}

export enum LOAN_STATUS {
  AWAITING_FUNDING = 'AWAITING_FUNDING',
  INVALID = 'INVALID',
}
export enum DOCUMENT_STATUS {
  needed = 'needed',
  submitted = 'submitted',
}
export const VALID_NOT_COMPLETE = ['INVALID', 'EXPIRED', 'CANCELLED', 'REFUNDED'];

export enum DOCUMENT_ERRORS {
  LARGE_FILE = 'LARGE_FILE',
  LIMIT = 'LIMIT',
  FAILED = 'FAILED',
  UNSUPPORTED = 'UNSUPPORTED',
}

export const SETTLEMENT_FLOW_STEPPER_CONFIG = {
  TOTAL_PAGES: 3,
  ACTIVE_PAGE: {
    ONE_TIME_PAYMENT: 1,
    PAYMENT_PLAN: 1,
    SETTLEMENT: 1,
    PAYMENT_METHOD: 2,
    PAYMENT_REVIEW: 3,
  },
};

export const RECOVERY_TEAM_SUPPORT_MAIL = 'recoverycollections@withcherry.com';
export const RECOVERY_TEAM_SUPPORT_PHONE_NUMBER = '888-724-1473';

export enum CommitmentSubType {
  PROMISE = 'PROMISE',
  SCHEDULED_PAYMENT = 'SCHEDULED_PAYMENT',
  SETTLEMENT_OFFER = 'SETTLEMENT_OFFER',
  ONE_TIME_PAYMENT = 'ONE_TIME_PAYMENT',
  PAYMENT_PLAN = 'PAYMENT_PLAN',
}

export const PHONE = '(888) 839-7171';
export const PHONE_LINK = 'tel:8888397171';
export const MAIL = 'mailto:support@withcherry.com';

export const SEGMENT_EVENT_NAMES = {
  PATIENT_PORTAL: {
    MAKE_PAYMENT_SELECT_AMOUNT: {
      PAGE_LOAD: 'PATIENT_PORTAL.MAKE_PAYMENT_SELECT_AMOUNT.PAGE_LOAD',
      AMOUNT_SELECTED: 'PATIENT_PORTAL.MAKE_PAYMENT_SELECT_AMOUNT.AMOUNT_SELECTED',
      OTHER_AMOUNT_FILLED: 'PATIENT_PORTAL.MAKE_PAYMENT_SELECT_AMOUNT.OTHER_AMOUNT_FILLED',
      SEE_PAYMENT_BREAKDOWN_CLICKED: 'PATIENT_PORTAL.MAKE_PAYMENT_SELECT_AMOUNT.SEE_PAYMENT_BREAKDOWN_CLICKED',
      PRINCIPAL_ONLY_SELECTED: 'PATIENT_PORTAL.MAKE_PAYMENT_SELECT_AMOUNT.PRINCIPAL_ONLY_SELECTED',
      CONTINUE_CLICKED: 'PATIENT_PORTAL.MAKE_PAYMENT_SELECT_AMOUNT.CONTINUE_CLICKED',
      BACK_CLICKED: 'PATIENT_PORTAL.MAKE_PAYMENT_SELECT_AMOUNT.BACK_CLICKED',
      BACK_ARROW_CLICKED: 'PATIENT_PORTAL.MAKE_PAYMENT_SELECT_AMOUNT.BACK_ARROW_CLICKED',
    },
    MAKE_A_PAYMENT_SELECT_PAYMENT_METHOD: {
      PAGE_LOAD: 'PATIENT_PORTAL.MAKE_A_PAYMENT_SELECT_PAYMENT_METHOD.PAGE_LOAD',
      PAYMENT_METHOD_SELECTED: 'PATIENT_PORTAL.MAKE_A_PAYMENT_SELECT_PAYMENT_METHOD.PAYMENT_METHOD_SELECTED',
      ADD_NEW_PAYMENT_METHOD_CLICKED:
        'PATIENT_PORTAL.MAKE_A_PAYMENT_SELECT_PAYMENT_METHOD.ADD_NEW_PAYMENT_METHOD_CLICKED',
      CONTINUE_CLICKED: 'PATIENT_PORTAL.MAKE_A_PAYMENT_SELECT_PAYMENT_METHOD.CONTINUE_CLICKED',
      BACK_CLICKED: 'PATIENT_PORTAL.MAKE_A_PAYMENT_SELECT_PAYMENT_METHOD.BACK_CLICKED',
      BACK_ARROW_CLICKED: 'PATIENT_PORTAL.MAKE_A_PAYMENT_SELECT_PAYMENT_METHOD.BACK_ARROW_CLICKED',
    },
    PAYMENT_PLAN_DETAILS: {
      PAGE_LOAD: 'PATIENT_PORTAL.PAYMENT_PLAN_DETAILS.PAGE_LOAD',
      SEE_OVERDUE_DETAILS_CLICKED: 'PATIENT_PORTAL.PAYMENT_PLAN_DETAILS.SEE_OVERDUE_DETAILS_CLICKED',
      PAST_DUE_SUMMARY_MODAL_MAKE_A_PAYMENT_CLICKED:
        'PATIENT_PORTAL.PAYMENT_PLAN_DETAILS.PAST_DUE_SUMMARY_MODAL_MAKE_A_PAYMENT_CLICKED',
      DISMISS_PAST_DUE_SUMMARY_MODAL_CLICKED:
        'PATIENT_PORTAL.PAYMENT_PLAN_DETAILS.DISMISS_PAST_DUE_SUMMARY_MODAL_CLICKED',
      AUTOPAY_PILL_PAYMENT_METHOD_CLICKED: 'PATIENT_PORTAL.PAYMENT_PLAN_DETAILS.AUTOPAY_PILL_PAYMENT_METHOD_CLICKED',
      AUTOPAY_PILL_ENABLE_NOW_CLICKED: 'PATIENT_PORTAL.PAYMENT_PLAN_DETAILS.AUTOPAY_PILL_ENABLE_NOW_CLICKED',
      MAKE_A_PAYMENT_QUICK_ACTION_CLICKED: 'PATIENT_PORTAL.PAYMENT_PLAN_DETAILS.MAKE_A_PAYMENT_QUICK_ACTION_CLICKED',
      DOWNLOAD_CONTRACT_QUICK_ACTION_CLICKED:
        'PATIENT_PORTAL.PAYMENT_PLAN_DETAILS.DOWNLOAD_CONTRACT_QUICK_ACTION_CLICKED',
      CONFIRM_DOWNLOAD_CONTRACT_MODAL_CLICKED:
        'PATIENT_PORTAL.PAYMENT_PLAN_DETAILS.CONFIRM_DOWNLOAD_CONTRACT_MODAL_CLICKED',
      DISMISS_DOWNLOAD_CONTRACT_MODAL_CLICKED:
        'PATIENT_PORTAL.PAYMENT_PLAN_DETAILS.DISMISS_DOWNLOAD_CONTRACT_MODAL_CLICKED',
      SET_PAYMENT_METHOD_QUICK_ACTION_CLICKED:
        'PATIENT_PORTAL.PAYMENT_PLAN_DETAILS.SET_PAYMENT_METHOD_QUICK_ACTION_CLICKED',
      CHANGE_DUE_DATE_QUICK_ACTION_CLICKED: 'PATIENT_PORTAL.PAYMENT_PLAN_DETAILS.CHANGE_DUE_DATE_QUICK_ACTION_CLICKED',
      DUE_DATE_ALREADY_CHANGED_MODAL_CONTACT_SUPPORT_CLICKED:
        'PATIENT_PORTAL.PAYMENT_PLAN_DETAILS.DUE_DATE_ALREADY_CHANGED_MODAL_CONTACT_SUPPORT_CLICKED',
      DISMISS_DUE_DATE_ALREADY_CHANGED_MODAL_CLICKED:
        'PATIENT_PORTAL.PAYMENT_PLAN_DETAILS.DISMISS_DUE_DATE_ALREADY_CHANGED_MODAL_CLICKED',
      CALCULATE_PAYOFF_QUICK_ACTION_CLICKED:
        'PATIENT_PORTAL.PAYMENT_PLAN_DETAILS.CALCULATE_PAYOFF_QUICK_ACTION_CLICKED',
      FILE_DISPUTE_QUICK_ACTION_CLICKED: 'PATIENT_PORTAL.PAYMENT_PLAN_DETAILS.FILE_DISPUTE_QUICK_ACTION_CLICKED',
      FILE_DISPUTE_NOT_AVAILABLE_ACKNOWLEDGEMENT_CLICKED:
        'PATIENT_PORTAL.PAYMENT_PLAN_DETAILS.FILE_DISPUTE_NOT_AVAILABLE_ACKNOWLEDGEMENT_CLICKED',
      GET_HELP_QUICK_ACTION_CLICKED: 'PATIENT_PORTAL.PAYMENT_PLAN_DETAILS.GET_HELP_QUICK_ACTION_CLICKED',
      CONTACT_SUPPORT_MODAL_CHANNEL_SELECTED:
        'PATIENT_PORTAL.PAYMENT_PLAN_DETAILS.CONTACT_SUPPORT_MODAL_CHANNEL_SELECTED',
      CONTACT_SUPPORT_MODAL_CONTINUE_CLICKED:
        'PATIENT_PORTAL.PAYMENT_PLAN_DETAILS.CONTACT_SUPPORT_MODAL_CONTINUE_CLICKED',
      DISMISS_CONTACT_SUPPORT_MODAL_CLICKED:
        'PATIENT_PORTAL.PAYMENT_PLAN_DETAILS.DISMISS_CONTACT_SUPPORT_MODAL_CLICKED',
      PAYMENT_SCHEDULE_TAB_CLICKED: 'PATIENT_PORTAL.PAYMENT_PLAN_DETAILS.PAYMENT_SCHEDULE_TAB_CLICKED',
      PURCHASE_DETAILS_TAB_CLICKED: 'PATIENT_PORTAL.PAYMENT_PLAN_DETAILS.PURCHASE_DETAILS_TAB_CLICKED',
      PURCHASE_DETAILS_CONTRACT_DOWNLOAD_CLICKED:
        'PATIENT_PORTAL.PAYMENT_PLAN_DETAILS.PURCHASE_DETAILS_CONTRACT_DOWNLOAD_CLICKED',
      CHARGEOFF_REPAYMENT_TYPE_SELECTED: 'PATIENT_PORTAL.PAYMENT_PLAN_DETAILS.CHARGEOFF_REPAYMENT_TYPE_SELECTED',
      CONTINUE_TO_CHARGEOFF_PAYMENT_CLICKED:
        'PATIENT_PORTAL.PAYMENT_PLAN_DETAILS.CONTINUE_TO_CHARGEOFF_PAYMENT_CLICKED',
      CHARGEOFF_REPAYMENT_SUMMARY_CONTACT_SUPPORT_CLICKED:
        'PATIENT_PORTAL.PAYMENT_PLAN_DETAILS.CHARGEOFF_REPAYMENT_SUMMARY_CONTACT_SUPPORT_CLICKED',
      DOWNLOAD_PIF_CLICKED: 'PATIENT_PORTAL.PAYMENT_PLAN_DETAILS.DOWNLOAD_PIF_CLICKED',
      DOWNLOAD_SIF_CLICKED: 'PATIENT_PORTAL.PAYMENT_PLAN_DETAILS.DOWNLOAD_SIF_CLICKED',
      EXEMPT_LOAN_DOWNLOAD_CONTRACT_CLICKED:
        'PATIENT_PORTAL.PAYMENT_PLAN_DETAILS.EXEMPT_LOAN_DOWNLOAD_CONTRACT_CLICKED',
      BACK_ARROW_CLICKED: 'PATIENT_PORTAL.PAYMENT_PLAN_DETAILS.BACK_ARROW_CLICKED',
    },
    PAYMENT_PLAN_OVERVIEW: {
      PAGE_LOAD: 'PATIENT_PORTAL.PAYMENT_PLAN_OVERVIEW.PAGE_LOAD',
      FIND_A_PRACTICE_CLICKED: 'PATIENT_PORTAL.PAYMENT_PLAN_OVERVIEW.FIND_A_PRACTICE_CLICKED',
      PAYMENT_PLAN_CLICKED: 'PATIENT_PORTAL.PAYMENT_PLAN_OVERVIEW.PAYMENT_PLAN_CLICKED',
      BACK_ARROW_CLICKED: 'PATIENT_PORTAL.PAYMENT_PLAN_OVERVIEW.BACK_ARROW_CLICKED',
    },
    SCHEDULED_PAYMENTS: {
      PAGE_LOAD: 'PATIENT_PORTAL.SCHEDULED_PAYMENTS.PAGE_LOAD',
      FIND_A_PRACTICE_CLICKED: 'PATIENT_PORTAL.SCHEDULED_PAYMENTS.FIND_A_PRACTICE_CLICKED',
      SCHEDULED_PAYMENT_CLICKED: 'PATIENT_PORTAL.SCHEDULED_PAYMENTS.SCHEDULED_PAYMENT_CLICKED',
      SHOW_MORE_CLICKED: 'PATIENT_PORTAL.SCHEDULED_PAYMENTS.SHOW_MORE_CLICKED',
      MISSED_PAYMENT_LEARN_MORE_CLICKED: 'PATIENT_PORTAL.SCHEDULED_PAYMENTS.MISSED_PAYMENT_LEARN_MORE_CLICKED',
      LEARN_MORE_MODAL_MAKE_A_PAYMENT_CLICKED:
        'PATIENT_PORTAL.SCHEDULED_PAYMENTS.LEARN_MORE_MODAL_MAKE_A_PAYMENT_CLICKED',
      DISMISS_LEARN_MORE_MODAL_CLICKED: 'PATIENT_PORTAL.SCHEDULED_PAYMENTS.DISMISS_LEARN_MORE_MODAL_CLICKED',
      BACK_ARROW_CLICKED: 'PATIENT_PORTAL.SCHEDULED_PAYMENTS.BACK_ARROW_CLICKED',
    },
    NAVIGATION_MENU: {
      HOME_CLICKED: 'PATIENT_PORTAL.NAVIGATION_MENU.HOME_CLICKED',
      PAYMENT_PLANS_CLICKED: 'PATIENT_PORTAL.NAVIGATION_MENU.PAYMENT_PLANS_CLICKED',
      APPLICATIONS_CLICKED: 'PATIENT_PORTAL.NAVIGATION_MENU.APPLICATIONS_CLICKED',
      UPCOMING_PAYMENTS_CLICKED: 'PATIENT_PORTAL.NAVIGATION_MENU.UPCOMING_PAYMENTS_CLICKED',
      DOCUMENTS_CLICKED: 'PATIENT_PORTAL.NAVIGATION_MENU.DOCUMENTS_CLICKED',
      ACCOUNT_CLICKED: 'PATIENT_PORTAL.NAVIGATION_MENU.ACCOUNT_CLICKED',
      HELP_CLICKED: 'PATIENT_PORTAL.NAVIGATION_MENU.HELP_CLICKED',
      FIND_A_CHERRY_PRACTICE_CLICKED: 'PATIENT_PORTAL.NAVIGATION_MENU.FIND_A_CHERRY_PRACTICE_CLICKED',
      MEMBERSHIPS_CLICKED: 'PATIENT_PORTAL.NAVIGATION_MENU.MEMBERSHIPS_CLICKED',
      SIGN_OUT_CLICKED: 'PATIENT_PORTAL.NAVIGATION_MENU.SIGN_OUT_CLICKED',
      VER_EN_ESPANOL_CLICKED: 'PATIENT_PORTAL.NAVIGATION_MENU.VER_EN_ESPANOL_CLICKED',
    },
    HOME_PAGE: {
      HOME_LOAD: 'PATIENT_PORTAL.HOMEPAGE.PAGE_LOAD',
    },
    REPAYMENT_SELECTION: {
      PAGE_LOAD: 'PATIENT_PORTAL.REPAYMENT_SELECTION.PAGE_LOAD',
      ONE_TIME_PAYMENT_CLICKED: 'PATIENT_PORTAL.REPAYMENT_SELECTION.ONE_TIME_PAYMENT_CLICKED',
      PAYMENT_PLAN_CLICKED: 'PATIENT_PORTAL.REPAYMENT_SELECTION.PAYMENT_PLAN_CLICKED',
      SETTLEMENT_OFFER_CLICKED: 'PATIENT_PORTAL.REPAYMENT_SELECTION.SETTLEMENT_OFFER_CLICKED',
      CONTINUE_CLICKED: 'PATIENT_PORTAL.REPAYMENT_SELECTION.CONTINUE_CLICKED',
    },
    ONE_TIME_PAYMENT: {
      PAGE_LOAD: 'PATIENT_PORTAL.ONE_TIME_PAYMENT.PAGE_LOAD',
      BACK_TO_REPAYMENT_SELECTION_CLICKED: 'PATIENT_PORTAL.ONE_TIME_PAYMENT.BACK_TO_REPAYMENT_SELECTION_CLICKED',
      OUTSTANDING_BALANCE_SELECTED: 'PATIENT_PORTAL.ONE_TIME_PAYMENT.OUTSTANDING_BALANCE_SELECTED',
      OTHER_AMOUNT_SELECTED: 'PATIENT_PORTAL.ONE_TIME_PAYMENT.OTHER_AMOUNT_SELECTED',
      OTHER_AMOUNT_FILLED: 'PATIENT_PORTAL.ONE_TIME_PAYMENT.OTHER_AMOUNT_FILLED',
      DATE_SELECTED: 'PATIENT_PORTAL.ONE_TIME_PAYMENT.DATE_SELECTED',
      DAY_CLICKED: 'PATIENT_PORTAL.ONE_TIME_PAYMENT.DAY_CLICKED',
      TODAY_CLICKED: 'PATIENT_PORTAL.ONE_TIME_PAYMENT.TODAY_CLICKED',
      CONTINUE_TO_PAYMENT_METHOD_CLICKED: 'PATIENT_PORTAL.ONE_TIME_PAYMENT.CONTINUE_TO_PAYMENT_METHOD_CLICKED',
      PAYMENT_METHOD_CLICKED: 'PATIENT_PORTAL.ONE_TIME_PAYMENT.PAYMENT_METHOD_CLICKED',
      ADD_NEW_PAYMENT_METHOD_CLICKED: 'PATIENT_PORTAL.ONE_TIME_PAYMENT.ADD_NEW_PAYMENT_METHOD_CLICKED',
      ADD_PAYMENT_ACCOUNT_CLICKED: 'PATIENT_PORTAL.ONE_TIME_PAYMENT.ADD_PAYMENT_ACCOUNT_CLICKED',
      NEW_PAYMENT_METHOD_TYPE_SELECTED: 'PATIENT_PORTAL.ONE_TIME_PAYMENT.NEW_PAYMENT_METHOD_TYPE_SELECTED',
      NEW_CARD_NUMBER_CLICKED: 'PATIENT_PORTAL.ONE_TIME_PAYMENT.NEW_CARD_NUMBER_CLICKED',
      NEW_CARD_NUMBER_FILLED: 'PATIENT_PORTAL.ONE_TIME_PAYMENT.NEW_CARD_NUMBER_FILLED',
      NEW_CARD_EXPIRATION_CLICKED: 'PATIENT_PORTAL.ONE_TIME_PAYMENT.NEW_CARD_EXPIRATION_CLICKED',
      NEW_CARD_EXPIRATION_FILLED: 'PATIENT_PORTAL.ONE_TIME_PAYMENT.NEW_CARD_EXPIRATION_FILLED',
      NEW_CARD_CVV_CLICKED: 'PATIENT_PORTAL.ONE_TIME_PAYMENT.NEW_CARD_CVV_CLICKED',
      NEW_CARD_CVV_FILLED: 'PATIENT_PORTAL.ONE_TIME_PAYMENT.NEW_CARD_CVV_FILLED',
      CANCEL_NEW_PAYMENT_METHOD_CLICKED: 'PATIENT_PORTAL.ONE_TIME_PAYMENT.CANCEL_NEW_PAYMENT_METHOD_CLICKED',
      OKAY_PROCEED_TO_PLAID_CLICKED: 'PATIENT_PORTAL.ONE_TIME_PAYMENT.OKAY_PROCEED_TO_PLAID_CLICKED',
      CONTINUE_TO_SUMMARY_CLICKED: 'PATIENT_PORTAL.ONE_TIME_PAYMENT.CONTINUE_TO_SUMMARY_CLICKED',
      BACK_TO_REPAYMENT_DETAILS_CLICKED: 'PATIENT_PORTAL.ONE_TIME_PAYMENT.BACK_TO_REPAYMENT_DETAILS_CLICKED',
      EMAIL_CLICKED: 'PATIENT_PORTAL.ONE_TIME_PAYMENT.EMAIL_CLICKED',
      EMAIL_FILLED: 'PATIENT_PORTAL.ONE_TIME_PAYMENT.EMAIL_FILLED',
      CONSENT_CLICKED: 'PATIENT_PORTAL.ONE_TIME_PAYMENT.CONSENT_CLICKED',
      BACK_TO_PAYMENT_METHOD_CLICKED: 'PATIENT_PORTAL.ONE_TIME_PAYMENT.BACK_TO_PAYMENT_METHOD_CLICKED',
      COMPLETE_PAYMENT_CLICKED: 'PATIENT_PORTAL.ONE_TIME_PAYMENT.COMPLETE_PAYMENT_CLICKED',
    },
    PAYMENT_PLAN: {
      PAGE_LOAD: 'PATIENT_PORTAL.PAYMENT_PLAN.PAGE_LOAD',
      BACK_TO_REPAYMENT_SELECTION_CLICKED: 'PATIENT_PORTAL.PAYMENT_PLAN.BACK_TO_REPAYMENT_SELECTION_CLICKED',
      WEEKLY_SELECTED: 'PATIENT_PORTAL.PAYMENT_PLAN.WEEKLY_SELECTED',
      BIWEEKLY_SELECTED: 'PATIENT_PORTAL.PAYMENT_PLAN.BIWEEKLY_SELECTED',
      MONTHLY_SELECTED: 'PATIENT_PORTAL.PAYMENT_PLAN.MONTHLY_SELECTED',
      DATE_SELECTED: 'PATIENT_PORTAL.PAYMENT_PLAN.DATE_SELECTED',
      DAY_CLICKED: 'PATIENT_PORTAL.PAYMENT_PLAN.DAY_CLICKED',
      TODAY_CLICKED: 'PATIENT_PORTAL.PAYMENT_PLAN.TODAY_CLICKED',
      INSTALLMENT_OPTION_SELECTED: 'PATIENT_PORTAL.PAYMENT_PLAN.INSTALLMENT_OPTION_SELECTED',
      COLLECTIONS_EMAIL_CLICKED: 'PATIENT_PORTAL.PAYMENT_PLAN.COLLECTIONS_EMAIL_CLICKED',
      CONTINUE_TO_PAYMENT_METHOD_CLICKED: 'PATIENT_PORTAL.PAYMENT_PLAN.CONTINUE_TO_PAYMENT_METHOD_CLICKED',
      PAYMENT_METHOD_CLICKED: 'PATIENT_PORTAL.PAYMENT_PLAN.PAYMENT_METHOD_CLICKED',
      ADD_NEW_PAYMENT_METHOD_CLICKED: 'PATIENT_PORTAL.PAYMENT_PLAN.ADD_NEW_PAYMENT_METHOD_CLICKED',
      ADD_PAYMENT_ACCOUNT_CLICKED: 'PATIENT_PORTAL.PAYMENT_PLAN.ADD_PAYMENT_ACCOUNT_CLICKED',
      NEW_PAYMENT_METHOD_TYPE_SELECTED: 'PATIENT_PORTAL.PAYMENT_PLAN.NEW_PAYMENT_METHOD_TYPE_SELECTED',
      NEW_CARD_NUMBER_CLICKED: 'PATIENT_PORTAL.PAYMENT_PLAN.NEW_CARD_NUMBER_CLICKED',
      NEW_CARD_NUMBER_FILLED: 'PATIENT_PORTAL.PAYMENT_PLAN.NEW_CARD_NUMBER_FILLED',
      NEW_CARD_EXPIRATION_CLICKED: 'PATIENT_PORTAL.PAYMENT_PLAN.NEW_CARD_EXPIRATION_CLICKED',
      NEW_CARD_EXPIRATION_FILLED: 'PATIENT_PORTAL.PAYMENT_PLAN.NEW_CARD_EXPIRATION_FILLED',
      NEW_CARD_CVV_CLICKED: 'PATIENT_PORTAL.PAYMENT_PLAN.NEW_CARD_CVV_CLICKED',
      NEW_CARD_CVV_FILLED: 'PATIENT_PORTAL.PAYMENT_PLAN.NEW_CARD_CVV_FILLED',
      CANCEL_NEW_PAYMENT_METHOD_CLICKED: 'PATIENT_PORTAL.PAYMENT_PLAN.CANCEL_NEW_PAYMENT_METHOD_CLICKED',
      OKAY_PROCEED_TO_PLAID_CLICKED: 'PATIENT_PORTAL.PAYMENT_PLAN.OKAY_PROCEED_TO_PLAID_CLICKED',
      CONTINUE_TO_SUMMARY_CLICKED: 'PATIENT_PORTAL.PAYMENT_PLAN.CONTINUE_TO_SUMMARY_CLICKED',
      BACK_TO_REPAYMENT_DETAILS_CLICKED: 'PATIENT_PORTAL.PAYMENT_PLAN.BACK_TO_REPAYMENT_DETAILS_CLICKED',
      EMAIL_CLICKED: 'PATIENT_PORTAL.PAYMENT_PLAN.EMAIL_CLICKED',
      EMAIL_FILLED: 'PATIENT_PORTAL.PAYMENT_PLAN.EMAIL_FILLED',
      CONSENT_CLICKED: 'PATIENT_PORTAL.PAYMENT_PLAN.CONSENT_CLICKED',
      BACK_TO_PAYMENT_METHOD_CLICKED: 'PATIENT_PORTAL.PAYMENT_PLAN.BACK_TO_PAYMENT_METHOD_CLICKED',
      COMPLETE_PAYMENT_CLICKED: 'PATIENT_PORTAL.PAYMENT_PLAN.COMPLETE_PAYMENT_CLICKED',
    },
    SETTLEMENT: {
      PAGE_LOAD: 'PATIENT_PORTAL.SETTLEMENT.PAGE_LOAD',
      BACK_TO_REPAYMENT_SELECTION_CLICKED: 'PATIENT_PORTAL.SETTLEMENT.BACK_TO_REPAYMENT_SELECTION_CLICKED',
      PAY_IN_FULL_SELECTED: 'PATIENT_PORTAL.SETTLEMENT.PAY_IN_FULL_SELECTED',
      PAY_OVER_TIME_SELECTED: 'PATIENT_PORTAL.SETTLEMENT.PAY_OVER_TIME_SELECTED',
      WEEKLY_SELECTED: 'PATIENT_PORTAL.SETTLEMENT.WEEKLY_SELECTED',
      BIWEEKLY_SELECTED: 'PATIENT_PORTAL.SETTLEMENT.BIWEEKLY_SELECTED',
      MONTHLY_SELECTED: 'PATIENT_PORTAL.SETTLEMENT.MONTHLY_SELECTED',
      DATE_SELECTED: 'PATIENT_PORTAL.SETTLEMENT.DATE_SELECTED',
      DAY_CLICKED: 'PATIENT_PORTAL.SETTLEMENT.DAY_CLICKED',
      TODAY_CLICKED: 'PATIENT_PORTAL.SETTLEMENT.TODAY_CLICKED',
      INSTALLMENT_OPTION_SELECTED: 'PATIENT_PORTAL.SETTLEMENT.INSTALLMENT_OPTION_SELECTED',
      COLLECTIONS_EMAIL_CLICKED: 'PATIENT_PORTAL.SETTLEMENT.COLLECTIONS_EMAIL_CLICKED',
      CONTINUE_TO_PAYMENT_METHOD_CLICKED: 'PATIENT_PORTAL.SETTLEMENT.CONTINUE_TO_PAYMENT_METHOD_CLICKED',
      PAYMENT_METHOD_CLICKED: 'PATIENT_PORTAL.SETTLEMENT.PAYMENT_METHOD_CLICKED',
      ADD_NEW_PAYMENT_METHOD_CLICKED: 'PATIENT_PORTAL.SETTLEMENT.ADD_NEW_PAYMENT_METHOD_CLICKED',
      ADD_PAYMENT_ACCOUNT_CLICKED: 'PATIENT_PORTAL.SETTLEMENT.ADD_PAYMENT_ACCOUNT_CLICKED',
      NEW_PAYMENT_METHOD_TYPE_SELECTED: 'PATIENT_PORTAL.SETTLEMENT.NEW_PAYMENT_METHOD_TYPE_SELECTED',
      NEW_CARD_NUMBER_CLICKED: 'PATIENT_PORTAL.SETTLEMENT.NEW_CARD_NUMBER_CLICKED',
      NEW_CARD_NUMBER_FILLED: 'PATIENT_PORTAL.SETTLEMENT.NEW_CARD_NUMBER_FILLED',
      NEW_CARD_EXPIRATION_CLICKED: 'PATIENT_PORTAL.SETTLEMENT.NEW_CARD_EXPIRATION_CLICKED',
      NEW_CARD_EXPIRATION_FILLED: 'PATIENT_PORTAL.SETTLEMENT.NEW_CARD_EXPIRATION_FILLED',
      NEW_CARD_CVV_CLICKED: 'PATIENT_PORTAL.SETTLEMENT.NEW_CARD_CVV_CLICKED',
      NEW_CARD_CVV_FILLED: 'PATIENT_PORTAL.SETTLEMENT.NEW_CARD_CVV_FILLED',
      CANCEL_NEW_PAYMENT_METHOD_CLICKED: 'PATIENT_PORTAL.SETTLEMENT.CANCEL_NEW_PAYMENT_METHOD_CLICKED',
      OKAY_PROCEED_TO_PLAID_CLICKED: 'PATIENT_PORTAL.SETTLEMENT.OKAY_PROCEED_TO_PLAID_CLICKED',
      CONTINUE_TO_SUMMARY_CLICKED: 'PATIENT_PORTAL.SETTLEMENT.CONTINUE_TO_SUMMARY_CLICKED',
      BACK_TO_REPAYMENT_DETAILS_CLICKED: 'PATIENT_PORTAL.SETTLEMENT.BACK_TO_REPAYMENT_DETAILS_CLICKED',
      EMAIL_CLICKED: 'PATIENT_PORTAL.SETTLEMENT.EMAIL_CLICKED',
      EMAIL_FILLED: 'PATIENT_PORTAL.SETTLEMENT.EMAIL_FILLED',
      CONSENT_CLICKED: 'PATIENT_PORTAL.SETTLEMENT.CONSENT_CLICKED',
      BACK_TO_PAYMENT_METHOD_CLICKED: 'PATIENT_PORTAL.SETTLEMENT.BACK_TO_PAYMENT_METHOD_CLICKED',
      COMPLETE_PAYMENT_CLICKED: 'PATIENT_PORTAL.SETTLEMENT.COMPLETE_PAYMENT_CLICKED',
    },
    APPLICATIONS_OVERVIEW: {
      PAGE_LOAD: 'PATIENT_PORTAL.APPLICATIONS_OVERVIEW.PAGE_LOAD',
      FIND_A_PRACTICE_CLICKED: 'PATIENT_PORTAL.APPLICATIONS_OVERVIEW.FIND_A_PRACTICE_CLICKED',
      FINISH_APPLICATION_CLICKED: 'PATIENT_PORTAL.APPLICATIONS_OVERVIEW.FINISH_APPLICATION_CLICKED',
      APPLICATION_CLICKED: 'PATIENT_PORTAL.APPLICATIONS_OVERVIEW.APPLICATION_CLICKED',
      REAPPLY_CLICKED: 'PATIENT_PORTAL.APPLICATIONS_OVERVIEW.REAPPLY_CLICKED',
    },
    HELP: {
      PAGE_LOAD: 'PATIENT_PORTAL.HELP.PAGE_LOAD',
      MORE_QUESTIONS_CLICKED: 'PATIENT_PORTAL.HELP.MORE_QUESTIONS_CLICKED',
      SUPPORT_PHONE_NUMBER_CLICKED: 'PATIENT_PORTAL.HELP.SUPPORT_PHONE_NUMBER_CLICKED',
      SUPPORT_EMAIL_CLICKED: 'PATIENT_PORTAL.HELP.SUPPORT_EMAIL_CLICKED',
      CONTACT_SUPPORT_CLICKED: 'PATIENT_PORTAL.HELP.CONTACT_SUPPORT_CLICKED',
      CONTACT_SUPPORT_MODAL_CHANNEL_SELECTED: 'PATIENT_PORTAL.HELP.CONTACT_SUPPORT_MODAL_CHANNEL_SELECTED',
      CONTACT_SUPPORT_MODAL_CONTINUE_CLICKED: 'PATIENT_PORTAL.HELP.CONTACT_SUPPORT_MODAL_CONTINUE_CLICKED',
      DISMISS_CONTACT_SUPPORT_MODAL_CLICKED: 'PATIENT_PORTAL.HELP.DISMISS_CONTACT_SUPPORT_MODAL_CLICKED',
    },
    LOGIN: {
      PAGE_LOAD: 'PATIENT_PORTAL.LOGIN_PAGE.PAGE_LOAD',
      TRY_MOBILE_APP_CLICKED: 'PATIENT_PORTAL.LOGIN_PAGE.TRY_MOBILE_APP_CLICKED',
      GOT_IT_THANKS_CLICKED: 'PATIENT_PORTAL.LOGIN_PAGE.GOT_IT_THANKS_CLICKED',
    },
    DENIAL_DETAILS: {
      PAGE_LOAD: 'PATIENT_PORTAL.DENIAL_DETAILS.PAGE_LOAD',
      VIEW_ADVERSE_ACTION_LETTER_CLICKED: 'PATIENT_PORTAL.DENIAL_DETAILS.VIEW_ADVERSE_ACTION_LETTER_CLICKED',
      VIEW_FAQS_CLICKED: 'PATIENT_PORTAL.DENIAL_DETAILS.VIEW_FAQS_CLICKED',
      DISMISS_FAQS_CLICKED: 'PATIENT_PORTAL.DENIAL_DETAILS.DISMISS_FAQS_CLICKED',
    },
    APPROVAL_DETAILS: {
      PAGE_LOAD: 'PATIENT_PORTAL.APPROVAL_DETAILS.PAGE_LOAD',
      USE_MY_APPROVAL_CLICKED: 'PATIENT_PORTAL.APPROVAL_DETAILS.USE_MY_APPROVAL_CLICKED',
      PREVIEW_PAYMENTS_CLICKED: 'PATIENT_PORTAL.APPROVAL_DETAILS.PREVIEW_PAYMENTS_CLICKED',
      VIEW_FAQS_CLICKED: 'PATIENT_PORTAL.APPROVAL_DETAILS.VIEW_FAQS_CLICKED',
      FAQ_MORE_QUESTIONS_CLICKED: 'PATIENT_PORTAL.APPROVAL_DETAILS.FAQ_MORE_QUESTIONS_CLICKED',
      DISMISS_FAQS_CLICKED: 'PATIENT_PORTAL.APPROVAL_DETAILS.DISMISS_FAQS_CLICKED',
      MORE_ACTIONS_CLICKED: 'PATIENT_PORTAL.APPROVAL_DETAILS.MORE_ACTIONS_CLICKED',
      REQUEST_LARGER_APPROVAL_CLICKED: 'PATIENT_PORTAL.APPROVAL_DETAILS.REQUEST_LARGER_APPROVAL_CLICKED',
      REQUEST_MORE_TIME_CLICKED: 'PATIENT_PORTAL.APPROVAL_DETAILS.REQUEST_MORE_TIME_CLICKED',
      CHANGE_LOCATION_PREFERENCE_CLICKED: 'PATIENT_PORTAL.APPROVAL_DETAILS.CHANGE_LOCATION_PREFERENCE_CLICKED',
      DISMISS_MORE_ACTIONS_CLICKED: 'PATIENT_PORTAL.APPROVAL_DETAILS.DISMISS_MORE_ACTIONS_CLICKED',
      BACK_ARROW_CLICKED: 'PATIENT_PORTAL.APPROVAL_DETAILS.BACK_ARROW_CLICKED',
    },
    USE_MY_APPROVAL: {
      PAGE_LOAD: 'PATIENT_PORTAL.USE_MY_APPROVAL.PAGE_LOAD',
      GOT_IT_CLICKED: 'PATIENT_PORTAL.USE_MY_APPROVAL.GOT_IT_CLICKED',
      BACK_ARROW_CLICKED: 'PATIENT_PORTAL.USE_MY_APPROVAL.BACK_ARROW_CLICKED',
    },
    PREVIEW_PAYMENTS: {
      PAGE_LOAD: 'PATIENT_PORTAL.PREVIEW_PAYMENTS.PAGE_LOAD',
      ESTIMATED_PURCHASE_AMOUNT_CLICKED: 'PATIENT_PORTAL.PREVIEW_PAYMENTS.ESTIMATED_PURCHASE_AMOUNT_CLICKED',
      ESTIMATED_PURCHASE_AMOUNT_FILLED: 'PATIENT_PORTAL.PREVIEW_PAYMENTS.ESTIMATED_PURCHASE_AMOUNT_FILLED',
      PLAN_LENGTH_SELECTED: 'PATIENT_PORTAL.PREVIEW_PAYMENTS.PLAN_LENGTH_SELECTED',
      BACK_CLICKED: 'PATIENT_PORTAL.PREVIEW_PAYMENTS.BACK_CLICKED',
      BACK_ARROW_CLICKED: 'PATIENT_PORTAL.PREVIEW_PAYMENTS.BACK_ARROW_CLICKED',
    },
    REQUEST_MORE_TIME: {
      PAGE_LOAD: 'PATIENT_PORTAL.REQUEST_MORE_TIME.PAGE_LOAD',
      APPT_PAST_EXPIRATION_DATE_SELECTED: 'PATIENT_PORTAL.REQUEST_MORE_TIME.APPT_PAST_EXPIRATION_DATE_SELECTED',
      ADD_SERVICES_SELECTED: 'PATIENT_PORTAL.REQUEST_MORE_TIME.ADD_SERVICES_SELECTED',
      PAYMENT_ISSUE_SELECTED: 'PATIENT_PORTAL.REQUEST_MORE_TIME.PAYMENT_ISSUE_SELECTED',
      OTHER_SELECTED: 'PATIENT_PORTAL.REQUEST_MORE_TIME.OTHER_SELECTED',
      BACK_CLICKED: 'PATIENT_PORTAL.REQUEST_MORE_TIME.BACK_CLICKED',
      CONTINUE_CLICKED: 'PATIENT_PORTAL.REQUEST_MORE_TIME.CONTINUE_CLICKED',
      REASON_PAGE_LOAD: 'PATIENT_PORTAL.REQUEST_MORE_TIME.PAGE_LOAD',
      REASON_APPOINTMENT_PAST_EXPIRATION_DATE_SELECTED:
        'PATIENT_PORTAL.REQUEST_MORE_TIME.APPT_PAST_EXPIRATION_DATE_SELECTED',
      REASON_ADD_SERVICES_SELECTED: 'PATIENT_PORTAL.REQUEST_MORE_TIME.ADD_SERVICES_SELECTED',
      REASON_PAYMENT_ISSUE_SELECTED: 'PATIENT_PORTAL.REQUEST_MORE_TIME.PAYMENT_ISSUE_SELECTED',
      REASON_OTHER_SELECTED: 'PATIENT_PORTAL.REQUEST_MORE_TIME.OTHER_SELECTED',
      REASON_CONTINUE_CLICKED: 'PATIENT_PORTAL.REQUEST_MORE_TIME.CONTINUE_CLICKED',
      REASON_BACK_CLICKED: 'PATIENT_PORTAL.REQUEST_MORE_TIME.BACK_CLICKED',
      APPOINTMENT_DATE_PAGE_LOAD: 'PATIENT_PORTAL.REQUEST_MORE_TIME.APPOINTMENT_DATE.PAGE_LOAD',
      APPOINTMENT_DATE_CONTINUE_CLICKED: 'PATIENT_PORTAL.REQUEST_MORE_TIME.APPOINTMENT_DATE.CONTINUE_CLICK',
      APPOINTMENT_DATE_BACK_CLICKED: 'PATIENT_PORTAL.REQUEST_MORE_TIME.APPOINTMENT_DATE.BACK_CLICK',
      CONFIRMED_PAGE_LOAD: 'PATIENT_PORTAL.REQUEST_MORE_TIME.CONFIRMED.PAGE_LOAD',
      BEYOND_60_DAYS_PAGE_LOAD: 'PATIENT_PORTAL.REQUEST_MORE_TIME.BEYOND_60_DAYS.PAGE_LOAD',
      BEYOND_60_DAYS_REMINDER_YES_SELECT: 'PATIENT_PORTAL.REQUEST_MORE_TIME.BEYOND_60_DAYS.REMINDER_YES_SELECT',
      BEYOND_60_DAYS_REMINDER_NO_SELECT: 'PATIENT_PORTAL.REQUEST_MORE_TIME.BEYOND_60_DAYS.REMINDER_NO_SELECT',
      BEYOND_60_DAYS_REMINDER_CONTINUE_CLICKED: 'PATIENT_PORTAL.REQUEST_MORE_TIME.BEYOND_60_DAYS.CONTINUE_CLICK',
      REMINDER_SET_PAGE_LOAD: 'PATIENT_PORTAL.REQUEST_MORE_TIME.REMINDER_SET.PAGE_LOAD',
    },
    CHANGE_LOCATION_PREFERENCE: {
      PAGE_LOAD: 'PATIENT_PORTAL.CHANGE_LOCATION_PREFERENCE.PAGE_LOAD',
      LOCATION_SELECTED: 'PATIENT_PORTAL.CHANGE_LOCATION_PREFERENCE.LOCATION_SELECTED',
      PHONE_CLICKED: 'PATIENT_PORTAL.CHANGE_LOCATION_PREFERENCE.PHONE_CLICKED',
      WEBSITE_CLICKED: 'PATIENT_PORTAL.CHANGE_LOCATION_PREFERENCE.WEBSITE_CLICKED',
      SAVE_LOCATION_CLICKED: 'PATIENT_PORTAL.CHANGE_LOCATION_PREFERENCE.SAVE_LOCATION_CLICKED',
      CANCEL_CLICKED: 'PATIENT_PORTAL.CHANGE_LOCATION_PREFERENCE.CANCEL_CLICKED',
      BACK_ARROW_CLICKED: 'PATIENT_PORTAL.CHANGE_LOCATION_PREFERENCE.BACK_ARROW_CLICKED',
    },
    MAKE_A_PAYMENT_SUMMARY: {
      PAGE_LOAD: 'PATIENT_PORTAL.MAKE_A_PAYMENT_SUMMARY.PAGE_LOAD',
      SEE_PAYMENT_BREAKDOWN_CLICKED: 'PATIENT_PORTAL.MAKE_A_PAYMENT_SUMMARY.SEE_PAYMENT_BREAKDOWN_CLICKED',
      CONSENT_CHECKBOX_CLICKED: 'PATIENT_PORTAL.MAKE_A_PAYMENT_SUMMARY.CONSENT_CHECKBOX_CLICKED',
      PROCESS_PAYMENT_CLICKED: 'PATIENT_PORTAL.MAKE_A_PAYMENT_SUMMARY.PROCESS_PAYMENT_CLICKED',
      ERROR_MODAL_DISMISSED: 'PATIENT_PORTAL.MAKE_A_PAYMENT_SUMMARY.ERROR_MODAL_DISMISSED',
      BACK_CLICKED: 'PATIENT_PORTAL.MAKE_A_PAYMENT_SUMMARY.BACK_CLICKED',
      BACK_ARROW_CLICKED: 'PATIENT_PORTAL.MAKE_A_PAYMENT_SUMMARY.BACK_ARROW_CLICKED',
    },
    MAKE_A_PAYMENT_SUCCESS: {
      PAGE_LOAD: 'PATIENT_PORTAL.MAKE_A_PAYMENT_SUCCESS.PAGE_LOAD',
      OKAY_CLICKED: 'PATIENT_PORTAL.MAKE_A_PAYMENT_SUCCESS.OKAY_CLICKED',
      BACK_ARROW_CLICKED: 'PATIENT_PORTAL.MAKE_A_PAYMENT_SUCCESS.BACK_ARROW_CLICKED',
    },
    ADD_NEW_PAYMENT_METHOD: {
      PAGE_LOAD: 'PATIENT_PORTAL.ADD_NEW_PAYMENT_METHOD.PAGE_LOAD',
      TYPE_SELECTED: 'PATIENT_PORTAL.ADD_NEW_PAYMENT_METHOD.TYPE_SELECTED',
      CONNECT_BANK_CLICKED: 'PATIENT_PORTAL.ADD_NEW_PAYMENT_METHOD.CONNECT_BANK_CLICKED',
      CARD_NUMBER_CLICKED: 'PATIENT_PORTAL.ADD_NEW_PAYMENT_METHOD.CARD_NUMBER_CLICKED',
      CARD_NUMBER_FILLED: 'PATIENT_PORTAL.ADD_NEW_PAYMENT_METHOD.CARD_NUMBER_FILLED',
      EXPIRATION_DATE_CLICKED: 'PATIENT_PORTAL.ADD_NEW_PAYMENT_METHOD.EXPIRATION_DATE_CLICKED',
      EXPIRATION_DATE_FILLED: 'PATIENT_PORTAL.ADD_NEW_PAYMENT_METHOD.EXPIRATION_DATE_FILLED',
      CVV_CLICKED: 'PATIENT_PORTAL.ADD_NEW_PAYMENT_METHOD.CVV_CLICKED',
      CVV_FILLED: 'PATIENT_PORTAL.ADD_NEW_PAYMENT_METHOD.CVV_FILLED',
      SAVE_NEW_PAYMENT_METHOD_CLICKED: 'PATIENT_PORTAL.ADD_NEW_PAYMENT_METHOD.SAVE_NEW_PAYMENT_METHOD_CLICKED',
      BACK_CLICKED: 'PATIENT_PORTAL.ADD_NEW_PAYMENT_METHOD.BACK_CLICKED',
      BACK_ARROW_CLICKED: 'PATIENT_PORTAL.ADD_NEW_PAYMENT_METHOD.BACK_ARROW_CLICKED',
    },

    SET_PAYMENT_METHOD: {
      PAGE_LOAD: 'PATIENT_PORTAL.SET_PAYMENT_METHOD.PAGE_LOAD',
      PAYMENT_METHOD_SELECTED: 'PATIENT_PORTAL.SET_PAYMENT_METHOD.PAYMENT_METHOD_SELECTED',
      ADD_NEW_PAYMENT_METHOD_CLICKED: 'PATIENT_PORTAL.SET_PAYMENT_METHOD.ADD_NEW_PAYMENT_METHOD_CLICKED',
      CONFIRM_ENABLE_AUTOPAY_CLICKED: 'PATIENT_PORTAL.SET_PAYMENT_METHOD.CONFIRM_ENABLE_AUTOPAY_CLICKED',
      SAVE_NEW_DEFAULT_CLICKED: 'PATIENT_PORTAL.SET_PAYMENT_METHOD.SAVE_NEW_DEFAULT_CLICKED',
      DISABLE_AUTOPAY_CLICKED: 'PATIENT_PORTAL.SET_PAYMENT_METHOD.DISABLE_AUTOPAY_CLICKED',
      DISABLE_AUTOPAY_MODAL_CONTACT_SUPPORT_CLICKED:
        'PATIENT_PORTAL.SET_PAYMENT_METHOD.DISABLE_AUTOPAY_MODAL_CONTACT_SUPPORT_CLICKED',
      CONTACT_SUPPORT_MODAL_CHANNEL_SELECTED:
        'PATIENT_PORTAL.SET_PAYMENT_METHOD.CONTACT_SUPPORT_MODAL_CHANNEL_SELECTED',
      CONTACT_SUPPORT_MODAL_CONTINUE_CLICKED:
        'PATIENT_PORTAL.SET_PAYMENT_METHOD.CONTACT_SUPPORT_MODAL_CONTINUE_CLICKED',
      DISMISS_CONTACT_SUPPORT_MODAL_CLICKED: 'PATIENT_PORTAL.SET_PAYMENT_METHOD.DISMISS_CONTACT_SUPPORT_MODAL_CLICKED',
      DISMISS_DISABLE_AUTOPAY_MODAL_CLICKED: 'PATIENT_PORTAL.SET_PAYMENT_METHOD.DISMISS_DISABLE_AUTOPAY_MODAL_CLICKED',
      BACK_CLICKED: 'PATIENT_PORTAL.SET_PAYMENT_METHOD.BACK_CLICKED',
      BACK_ARROW_CLICKED: 'PATIENT_PORTAL.SET_PAYMENT_METHOD.BACK_ARROW_CLICKED',
    },
    SET_PAYMENT_METHOD_SUCCESS: {
      PAGE_LOAD: 'PATIENT_PORTAL.SET_PAYMENT_METHOD_SUCCESS.PAGE_LOAD',
      MAKE_A_PAYMENT_CLICKED: 'PATIENT_PORTAL.SET_PAYMENT_METHOD_SUCCESS.MAKE_A_PAYMENT_CLICKED',
      NO_THANKS_CLICKED: 'PATIENT_PORTAL.SET_PAYMENT_METHOD_SUCCESS.NO_THANKS_CLICKED',
    },
    SET_PAYMENT_METHOD_FAILURE: {
      OKAY_CLICKED: 'PATIENT_PORTAL.SET_PAYMENT_METHOD_FAILURE.OKAY_CLICKED',
      BACK_ARROW_CLICKED: 'PATIENT_PORTAL.SET_PAYMENT_METHOD_FAILURE.BACK_ARROW_CLICKED',
    },
    CALCULATE_PAYOFF: {
      PAGE_LOAD: 'PATIENT_PORTAL.CALCULATE_PAYOFF.PAGE_LOAD',
      DATE_PICKER_CLICKED: 'PATIENT_PORTAL.CALCULATE_PAYOFF.DATE_PICKER_CLICKED',
      DATE_SELECTED: 'PATIENT_PORTAL.CALCULATE_PAYOFF.DATE_SELECTED',
      CONTINUE_CLICKED: 'PATIENT_PORTAL.CALCULATE_PAYOFF.CONTINUE_CLICKED',
      BACK_CLICKED: 'PATIENT_PORTAL.CALCULATE_PAYOFF.BACK_CLICKED',
      BACK_ARROW_CLICKED: 'PATIENT_PORTAL.CALCULATE_PAYOFF.BACK_ARROW_CLICKED',
    },
    CALCULATE_PAYOFF_SUCCESS: {
      PAGE_LOAD: 'PATIENT_PORTAL.CALCULATE_PAYOFF_SUCCESS.PAGE_LOAD',
      VIEW_DOCUMENT_CLICKED: 'PATIENT_PORTAL.CALCULATE_PAYOFF_SUCCESS.VIEW_DOCUMENT_CLICKED',
      MAYBE_LATER_CLICKED: 'PATIENT_PORTAL.CALCULATE_PAYOFF_SUCCESS.MAYBE_LATER_CLICKED',
    },
    CHANGE_DUE_DATE: {
      PAGE_LOAD: 'PATIENT_PORTAL.CHANGE_DUE_DATE.PAGE_LOAD',
      DATE_PICKER_CLICKED: 'PATIENT_PORTAL.CHANGE_DUE_DATE.DATE_PICKER_CLICKED',
      DATE_SELECTED: 'PATIENT_PORTAL.CHANGE_DUE_DATE.DATE_SELECTED',
      SUBMIT_DUE_DATE_CHANGE_CLICKED: 'PATIENT_PORTAL.CHANGE_DUE_DATE.SUBMIT_DUE_DATE_CHANGE_CLICKED',
      BACK_CLICKED: 'PATIENT_PORTAL.CHANGE_DUE_DATE.BACK_CLICKED',
      BACK_ARROW_CLICKED: 'PATIENT_PORTAL.CHANGE_DUE_DATE.BACK_ARROW_CLICKED',
    },
    CHANGE_DUE_DATE_SUCCESS: {
      PAGE_LOAD: 'PATIENT_PORTAL.CHANGE_DUE_DATE_SUCCESS.PAGE_LOAD',
      OKAY_CLICKED: 'PATIENT_PORTAL.CHANGE_DUE_DATE_SUCCESS.OKAY_CLICKED',
    },
    GO_BACK: {
      DENIED_APPLICATION: 'PATIENT_PORTAL.DENIAL_DETAILS.BACK_ARROW_CLICKED',
      CALCULATE_PAYOFF: 'PATIENT_PORTAL.CALCULATE_PAYOFF.BACK_ARROW_CLICKED',
      DUE_DATE: 'PATIENT_PORTAL.CHANGE_DUE_DATE.BACK_ARROW_CLICKED',
      APPROVAL_DETAILS: 'PATIENT_PORTAL.APPROVAL_DETAILS.BACK_ARROW_CLICKED',
      USE_MY_BALANCE: 'PATIENT_PORTAL.USE_MY_APPROVAL.BACK_ARROW_CLICKED',
      PREVIEW_PAYMENTS: 'PATIENT_PORTAL.PREVIEW_PAYMENTS.BACK_ARROW_CLICKED',
      CHANGE_LOCATION: 'PATIENT_PORTAL.CHANGE_LOCATION_PREFERENCE.BACK_ARROW_CLICKED',
      PAYMENT_REVIEW: 'PATIENT_PORTAL.MAKE_A_PAYMENT_SUMMARY.BACK_ARROW_CLICKED',
      PAYMENT_SUCCESS: 'PATIENT_PORTAL.MAKE_A_PAYMENT_SUCCESS.BACK_ARROW_CLICKED',
      PAYMENT_METHOD: 'PATIENT_PORTAL.ADD_NEW_PAYMENT_METHOD.BACK_ARROW_CLICKED',
      SET_PAYMENT_METHOD: 'PATIENT_PORTAL.SET_PAYMENT_METHOD.BACK_ARROW_CLICKED',
      MORE_TIME: 'PATIENT_PORTAL.REQUEST_MORE_TIME.BACK_ARROW_CLICKED',
    },
  },
} as const;

export const ClientErrors = {
  'borrower.has_approved_applications.unprocessable':
    'We’re sorry, Spanish language support is not available at this time.',
};
