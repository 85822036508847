import { useLazyQuery } from '@apollo/client';
import { FETCH_ORGANIZATION_LOCATIONS } from 'lib/graphql/queries/Organization';
import { useAlert } from 'react-alert';
import { useTranslation } from 'react-i18next';
import useStore from './useStore';

export const useOrganizationLocations = (organizationId) => {
  const alert = useAlert();
  const { t: translate } = useTranslation();
  const { setLocations } = useStore();

  return useLazyQuery(FETCH_ORGANIZATION_LOCATIONS, {
    variables: {
      input: {
        organizationId,
      },
    },
    fetchPolicy: 'network-only',
    onCompleted: ({ getOrganizationLocations }) => {
      setLocations(getOrganizationLocations?.contents);
    },
    onError: (error) => alert.error(translate(`error.${error}`)),
  });
};
