import React, { useEffect, useState } from 'react';
import { AddressElement, CardElement, Elements, useElements, useStripe } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';

import { Button, ButtonContainer, Icon, InnerContainer, MembershipContainer, Subtitle, Title } from 'lib/components';
import useStore, { Price } from 'lib/hooks/useStore';
import styled from 'styled-components';
import { useLocation, useNavigate } from 'react-router-dom';
import { useMutation } from '@apollo/client';
import {
  CREATE_CUSTOMER_SUBSCRIPTIONS_PAYMENT_METHOD,
  PATCH_SUBSCRIPTIONS_CUSTOMER,
} from 'lib/graphql/mutations/Subscriptions';
import { calculateTotalPrice } from 'lib/utils/MembershipPrice';
import { useTranslation } from 'react-i18next';

const SubscriptionsAddPaymentMethod = () => {
  const { setPageIndex, organizationAccountInformation, subscriptionOrganization } = useStore();
  const navigate = useNavigate();

  const [stripePromise] = useState(() =>
    loadStripe(organizationAccountInformation?.cherryPublicKey, {
      stripeAccount: organizationAccountInformation?.organizationAccountId,
    }),
  );

  useEffect(() => {
    setPageIndex(2);
  }, [setPageIndex]);

  const goBack = () => {
    navigate(`/memberships/${subscriptionOrganization?.slug}/contact-information`);
  };

  return (
    <Elements stripe={stripePromise}>
      <MembershipContainer showBackButton={true} goBackCallback={goBack}>
        <CheckoutForm />
      </MembershipContainer>
    </Elements>
  );
};

const CheckoutForm = () => {
  const { search } = useLocation();
  const stripe = useStripe();
  const elements = useElements();
  const {
    subscriptionOrganization,
    setSubscriptionPaymentMethod,
    subscriptionCustomer,
    selectedSubscriptionPlan,
    subscriptionPeriod,
  } = useStore();
  const [createCustomerPaymentMethod] = useMutation(CREATE_CUSTOMER_SUBSCRIPTIONS_PAYMENT_METHOD);
  const [patchCustomer] = useMutation(PATCH_SUBSCRIPTIONS_CUSTOMER);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const { t: translate } = useTranslation();

  const hasPaymentError = () => {
    const urlSearchParams = new URLSearchParams(search);
    return urlSearchParams.get('status') === 'error';
  };

  const selectedPrice = selectedSubscriptionPlan?.prices?.find((price: Price) => price.period === subscriptionPeriod);

  const payMoney = async (e) => {
    setLoading(true);
    e.preventDefault();
    if (!stripe || !elements) {
      return;
    }

    const stripeCardElement = elements.getElement(CardElement);
    const stripeAddressFormValues = elements.getElement(AddressElement);

    const payload = await stripe.createPaymentMethod({
      type: 'card',
      card: stripeCardElement!,
    });

    // @ts-ignore
    const { value: addressPayload } = await stripeAddressFormValues?.getValue();
    console.warn('[PaymentMethod]', payload, addressPayload);

    const paymentMethod = payload?.paymentMethod;
    const cardInfo = paymentMethod?.card;
    const expireYear = Number(String(cardInfo?.exp_year).slice(-2));
    const {
      data: { createMembershipsCustomerPaymentMethod },
    } = await createCustomerPaymentMethod({
      variables: {
        input: {
          customerId: subscriptionCustomer?.id,
          organizationId: subscriptionOrganization?.id,
          providerId: paymentMethod?.id,
          last4: cardInfo?.last4,
          network: cardInfo?.brand,
          type: paymentMethod?.type,
          expireMonth: cardInfo?.exp_month,
          expireYear,
        },
      },
    });

    setSubscriptionPaymentMethod(createMembershipsCustomerPaymentMethod);

    const billingDetails = addressPayload?.address;
    await patchCustomer({
      variables: {
        input: {
          customerId: subscriptionCustomer?.id,
          address: {
            zipCode: billingDetails?.postal_code,
            // @ts-ignore
            street: billingDetails?.line1 + billingDetails?.line2,
            city: billingDetails?.city,
            state: billingDetails?.state,
          },
          firstName: addressPayload?.name?.split(' ')[0],
          lastName: addressPayload?.name?.split(' ')[1],
        },
      },
    });

    navigate(`/memberships/${subscriptionOrganization?.slug}/review`);
    setLoading(false);
  };

  return (
    <form onSubmit={payMoney}>
      <InnerContainer>
        <Icon width={28} height={28} src="credit_card" />
        <Title>
          {hasPaymentError()
            ? translate('subscriptions.addPaymentMethod.tryAnother')
            : translate('subscriptions.addPaymentMethod.howWouldYouLike')}
        </Title>
        {hasPaymentError() && <Subtitle> {translate('subscriptions.addPaymentMethod.oops')} </Subtitle>}
        <Subtitle>
          Your payment of{' '}
          <PaymentText>
            {calculateTotalPrice(
              selectedPrice?.firstPayment ? selectedPrice?.firstPayment : selectedPrice?.price,
              selectedPrice?.processingFee,
              true,
            )}
          </PaymentText>{' '}
          is due today. This method will be used for future payments.
        </Subtitle>
        <CheckoutTitle>{translate('subscriptions.addPaymentMethod.addYourCard')}</CheckoutTitle>
        <CardElement
          className="card"
          options={{
            style: {
              base: {
                iconColor: '#000',
                color: '#000',
                fontWeight: '500',
                fontFamily: 'Open Sans, Segoe UI, sans-serif',
                fontSize: '16px',
                fontSmoothing: 'antialiased',
                ':-webkit-autofill': {
                  color: '#a2a2a2',
                },
                '::placeholder': {
                  color: '#a2a2a2',
                },
              },
              invalid: {
                iconColor: '#FFC7EE',
                color: '#FFC7EE',
              },
            },
          }}
        />
        <CheckoutTitle>{translate('subscriptions.addPaymentMethod.billingAddress')}</CheckoutTitle>
        <AddressElement
          options={{
            blockPoBox: true,
            mode: 'billing',
          }}
        />
      </InnerContainer>
      <ButtonContainer>
        <Button p="15px" onClick={payMoney} type="submit" loading={loading} disabled={loading}>
          {translate('common.continue')}
        </Button>
        <InfoText>{translate('subscriptions.addPaymentMethod.wontCharged')}</InfoText>
      </ButtonContainer>
    </form>
  );
};

export default SubscriptionsAddPaymentMethod;

const InfoText = styled.span`
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  line-height: 19px;
  color: ${(props) => props.theme.main.midnightBlue};
`;

const PaymentText = styled.span`
  color: rgba(0, 195, 125, 1);
  font-weight: bold;
`;

const CheckoutTitle = styled.span`
  color: #0e202f;
  font-size: 16px;
  line-height: 16px;
  font-weight: 700;
  display: block;
  margin-top: 24px;
  margin-bottom: 12px;
`;
