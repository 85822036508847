import React, { useEffect, useMemo, useState } from 'react';
import { RadioGroup as MuiRadioGroup, FormControlLabel as MuiFormControlLabel, Radio } from '@mui/material';
import styled from 'styled-components';

type Option = {
  value: string;
  content: string | React.ReactElement;
};

type RadioGroupProps = {
  options: Option[];
  defaultValue?: string;
  hideRadioButtons?: boolean;
  gapDistance?: 'low' | 'medium' | 'high';
  onChange: (value: string) => void;
};

enum GapDistance {
  low = '8px',
  medium = '16px',
  high = '24px',
}

export const RadioGroup = ({
  options,
  defaultValue,
  hideRadioButtons = false,
  gapDistance = 'medium',
  onChange,
}: RadioGroupProps) => {
  const [value, setValue] = useState<string>(defaultValue || '');

  useEffect(() => {
    if (value) {
      onChange(value);
    }
  }, [value]);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event?.target?.value) {
      setValue(event.target.value);
    }
  };

  const optionElements = useMemo(
    () =>
      options?.map((option) => (
        <FormControlLabel
          key={option?.value}
          value={option?.value}
          control={<Radio />}
          label={option?.content}
          isChecked={value === option.value}
          hideRadioButton={hideRadioButtons}
        />
      )),
    [value, options],
  );

  return (
    <RadioGroupContainer
      value={value}
      defaultValue={defaultValue}
      onChange={handleChange}
      gapDistance={GapDistance[gapDistance]}
    >
      {optionElements}
    </RadioGroupContainer>
  );
};

const RadioGroupContainer = styled(MuiRadioGroup)<{ gapDistance?: string }>`
  display: flex;
  flex-direction: column;
  gap: ${(props) => props.gapDistance};

  .MuiTypography-root {
    font-family: 'Open Sans';
    font-size: 16px;
    font-weight: 400;
    color: ${(props) => props.theme.main.primary};
    width: 100%;
  }

  .Mui-checked {
    color: ${(props) => props.theme.main.green} !important;
  }
`;

const FormControlLabel = styled(MuiFormControlLabel)<{ isChecked: boolean; hideRadioButton?: boolean }>`
  padding: 24px 16px;
  border-radius: 4px;
  background-color: ${(props) => (props.isChecked ? props.theme.main.kellyGreen5 : props.theme.main.white)};
  border: 1px solid ${(props) => (props.isChecked ? props.theme.main.green : props.theme.main.neutralGray)};
  margin: 0 !important;

  .MuiRadio-root {
    padding: 0 16px 0 0;
    display: ${(props) => props.hideRadioButton && 'none'};
  }
`;
