import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { Button as CherryButton, Heading } from '@frontend/cherry-library';
import { Container, Loading } from 'lib/components';
import { useLoanList } from 'lib/hooks/useContract';
import useStore from 'lib/hooks/useStore';
import { isArray, SortByDate } from 'lib/utils';
import { PLAN_STATUS } from 'lib/constans';
import SelectPaymentCard from './SelectPaymentCard';
import { theme } from 'config/theme';

const SelectPaymentPlan = () => {
  const navigate = useNavigate();
  const { t: translate } = useTranslation();
  const { loanList, setHeaderTitle } = useStore();
  const [fetchLoanList, { loading }] = useLoanList('FUNDED');

  const [selectedLoanId, setSelectedLoanId] = useState('');

  const sortedActiveLoans = useMemo(() => {
    const activeLoans = SortByDate(loanList, 'createdAt', 'desc')?.filter(
      (a) =>
        a?.status === 'FUNDED' &&
        a.debtSales?.status !== 'SOLD' &&
        PLAN_STATUS.includes(isArray(a.plans)?.[0]?.status) &&
        a?.fraud === false &&
        a?.deceased === false,
    );

    return activeLoans.sort((a, b) => (a.plans?.[0]?.daysPastDue > b.plans?.[0]?.daysPastDue ? -1 : 1));
  }, [loanList]);

  useEffect(() => {
    setHeaderTitle('homePage.button.payment');
  }, [setHeaderTitle]);

  useEffect(() => {
    if (!loanList.length) {
      fetchLoanList();
    }
  }, []);

  const setLoanToPayHandler = (value: string) => {
    setSelectedLoanId(value);
  };

  const navigateToPaymentMethod = () => {
    navigate(`/portal/${selectedLoanId}/payment-method`);
  };

  const goBackHandler = () => {
    setSelectedLoanId('');
    navigate(-1);
  };

  if (loading) {
    return <Loading transparent={true} />;
  }

  return (
    <Container block={true}>
      {sortedActiveLoans?.length > 0 ? (
        <PaymentSelection>
          <Heading level="4" text={translate(`loansToPay.header.title`)} />
          <LoanContainer>
            {sortedActiveLoans?.map((content) => (
              <SelectPaymentCard
                loan={content}
                setLoanToPay={setLoanToPayHandler}
                selectedLoanId={selectedLoanId}
                key={content?.id}
              />
            ))}
          </LoanContainer>
          <ButtonContainer>
            <CherryButton disabled={selectedLoanId?.length === 0} onClick={navigateToPaymentMethod} fullWidth={true}>
              {translate('makePayment.paymentType.submitButton')}
            </CherryButton>
            <CherryButton onClick={goBackHandler} variant="secondary" fullWidth={true}>
              {translate('makePayment.backButton')}
            </CherryButton>
          </ButtonContainer>
        </PaymentSelection>
      ) : (
        <NoLoans>{translate('contracts.noContracts')}</NoLoans>
      )}
    </Container>
  );
};

const NoLoans = styled.div`
  text-align: center;
  box-shadow: none;
  border: ${(props) => `1px solid ${props.theme.main.cardBorderColor}`};
  border-radius: 8px;
  margin-bottom: 16px;
  padding: 16px;
  font-family: ${(props) => props.theme.fonts.primary};
  font-size: 14px;
`;

const LoanContainer = styled.div`
  padding-top: 24px;
`;

const PaymentSelection = styled.div`
  display: flex;
  flex-direction: column;
  padding: 16px;
  background: ${theme.main.white};
  box-shadow: 0px 6px 9px rgba(0, 0, 0, 0.07), 0px 0.751293px 1.12694px rgba(0, 0, 0, 0.035);
  border-radius: 8px;
`;

const ButtonContainer = styled.div`
  gap: 8px;
  display: flex;
  flex-direction: column;
  padding-top: 8px;
`;

export default SelectPaymentPlan;
