import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import dayjs from 'dayjs';
import { isNil } from 'lodash';

import {
  Button,
  ButtonColor,
  ButtonContainer,
  Icon,
  InnerContainer,
  MembershipContainer,
  Switch,
  Title,
} from 'lib/components';
import useStore, { SubscriptionPeriod } from 'lib/hooks/useStore';
import { FormatAmount } from 'lib/utils';
import { calculateTotalPrice } from 'lib/utils/MembershipPrice';
import { useTranslation } from 'react-i18next';

const today = new Date();
const oneYearLater = new Date();
oneYearLater.setFullYear(oneYearLater.getFullYear() + 1);
const sixMonthsLater = new Date();
sixMonthsLater.setMonth(sixMonthsLater.getMonth() + 6);

const SubscriptionsDetail = () => {
  const {
    setPageIndex,
    selectedSubscriptionPlan,
    setSubscriptionPeriod,
    subscriptionOrganization,
    organizationAccountInformation,
  } = useStore();
  const navigate = useNavigate();

  const monthlyPrices = selectedSubscriptionPlan?.prices?.find(
    (plan) => plan?.period === 'MONTHLY' || plan?.period === 'MONTHLY_FLEX',
  );
  const yearlyPrices = selectedSubscriptionPlan?.prices?.find((plan) => plan?.period === 'YEARLY');
  const weeklyPrices = selectedSubscriptionPlan?.prices?.find((plan) => plan?.period === 'CUSTOM_WEEKLY');
  const { t: translate } = useTranslation();

  const [switchState, setSwitchState] = useState({
    period: weeklyPrices || monthlyPrices ? false : true,
  });

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSwitchState({
      ...switchState,
      [event.target.name]: event.target.checked,
    });
  };

  useEffect(() => {
    setPageIndex(1);
  }, [setPageIndex]);

  const selectPlan = () => {
    let subscriptionPeriod;
    if (switchState?.period) subscriptionPeriod = SubscriptionPeriod.YEARLY;
    else if (weeklyPrices) subscriptionPeriod = SubscriptionPeriod.CUSTOM_WEEKLY;
    else if (monthlyPrices?.firstPayment) subscriptionPeriod = SubscriptionPeriod.MONTHLY_FLEX;
    else subscriptionPeriod = SubscriptionPeriod.MONTHLY;

    setSubscriptionPeriod(subscriptionPeriod);
    navigate(`/memberships/${subscriptionOrganization?.slug}/contact-information`);
  };

  const goBack = () => {
    navigate(`/memberships/${subscriptionOrganization?.slug}`);
  };

  const yearlySaveAmount =
    (monthlyPrices?.firstPayment ? monthlyPrices?.price * 11 + monthlyPrices.firstPayment : monthlyPrices?.price * 12) -
    yearlyPrices?.price;

  return (
    <MembershipContainer showBackButton={true} goBackCallback={goBack}>
      <InnerContainer>
        <Icon width={28} height={28} src="membership" />
        <Title>{translate('subscriptions.detail.title')}</Title>
        <ContentBox>
          <ContentTitle>{selectedSubscriptionPlan?.name}</ContentTitle>
          <SectionTitle>{translate('subscriptions.detail.summary')}</SectionTitle>
          <SectionTextPrice>{selectedSubscriptionPlan?.description}</SectionTextPrice>
        </ContentBox>
        <ContentBox>
          <ContentTitle>{translate('subscriptions.detail.paymentDetails')}</ContentTitle>
          {Boolean(monthlyPrices) && Boolean(yearlyPrices) && (
            <PeriodSelection>
              <Period selected={!switchState.period}>
                {translate('subscriptions.detail.monthlyFlex', {
                  isFlex: monthlyPrices.firstPayment ? 'Flex' : '',
                })}
              </Period>
              <Switch onChange={handleChange} name="period" />
              <Period selected={switchState.period}>
                <div
                  dangerouslySetInnerHTML={{
                    __html: translate('subscriptions.detail.yearlySave', {
                      amount: FormatAmount(yearlySaveAmount),
                    }),
                  }}
                ></div>
              </Period>
            </PeriodSelection>
          )}
          {switchState?.period ? (
            <>
              <SectionTitle>{translate('subscriptions.detail.firstPayment')}</SectionTitle>
              <SectionTextPrice>
                {calculateTotalPrice(yearlyPrices?.price, yearlyPrices?.processingFee)}
              </SectionTextPrice>
              <SectionTitle>{translate('subscriptions.detail.recurringPayment')}</SectionTitle>
              <SectionText>{translate('subscriptions.detail.noMonthly')}</SectionText>
              <SectionText>
                {translate('subscriptions.detail.chargedOnce', {
                  date: today.toLocaleDateString('en-US', { month: 'long', day: 'numeric' }),
                })}
              </SectionText>
              <SectionTitle>{translate('subscriptions.detail.renewals')}</SectionTitle>
              <SectionText>{translate('subscriptions.detail.nonrefundable')}</SectionText>
              <SectionText>{translate('subscriptions.detail.minimumTerm')}</SectionText>
              {yearlyPrices?.autoRenew ? (
                <SectionText>
                  {translate('subscriptions.detail.autoRenews', { date: oneYearLater.toLocaleDateString('en-US') })}
                </SectionText>
              ) : null}
              <SectionTitle>{translate('subscriptions.detail.upgrades')}</SectionTitle>
              <SectionText>{translate('subscriptions.detail.upgrades1')}</SectionText>
              <SectionText>{translate('subscriptions.detail.upgrades2')}</SectionText>
            </>
          ) : (
            <>
              <SectionTitle>{translate('subscriptions.detail.firstPayment')}</SectionTitle>
              <SectionTextPrice>
                {calculateTotalPrice(
                  weeklyPrices?.price ||
                    (monthlyPrices.firstPayment ? monthlyPrices.firstPayment : monthlyPrices?.price),
                  !isNil(weeklyPrices?.processingFee) ? weeklyPrices?.processingFee : monthlyPrices?.processingFee,
                )}
              </SectionTextPrice>
              <SectionTitle>{translate('subscriptions.detail.recurringPayment')}</SectionTitle>
              <SectionText>
                {calculateTotalPrice(
                  weeklyPrices?.price || monthlyPrices?.price,
                  !isNil(weeklyPrices?.processingFee) ? weeklyPrices?.processingFee : monthlyPrices?.processingFee,
                  true,
                )}
                {weeklyPrices
                  ? ` every${weeklyPrices.paymentFrequency <= 1 ? '' : ' ' + weeklyPrices.paymentFrequency} week${
                      weeklyPrices.paymentFrequency <= 1 ? '' : 's'
                    }`
                  : ' / month'}
              </SectionText>
              <SectionText>
                {translate('subscriptions.detail.chargedOn')}
                {weeklyPrices
                  ? translate('subscriptions.detail.date', { date: dayjs().format('dddd') })
                  : translate('subscriptions.detail.eachMonth', {
                      date: today.toLocaleDateString('en-US', { day: 'numeric' }),
                    })}
              </SectionText>
              <SectionTitle>{translate('subscriptions.detail.renewals')}</SectionTitle>
              <SectionText>{translate('subscriptions.detail.nonrefundable')}</SectionText>
              <SectionText>
                {translate('subscriptions.detail.minimumTermLength')}
                {weeklyPrices
                  ? translate('subscriptions.detail.weekscount', {
                      date: weeklyPrices?.paymentFrequency * weeklyPrices?.billingTerm,
                    })
                  : translate('subscriptions.detail.monthcount', { date: monthlyPrices?.billingTerm || 1 })}
                .
              </SectionText>
              {weeklyPrices?.autoRenew || monthlyPrices?.autoRenew ? (
                <SectionText>
                  Auto-renews{' '}
                  {weeklyPrices
                    ? `on ${dayjs()
                        .add(weeklyPrices.paymentFrequency * weeklyPrices.billingTerm, 'week')
                        .format('L')}`
                    : `every ${monthlyPrices.billingTerm ? `${monthlyPrices.billingTerm} month(s)` : 'month'}`}{' '}
                  unless canceled.
                </SectionText>
              ) : null}
              <SectionTitle>{translate('subscriptions.detail.upgrades')}</SectionTitle>
              <SectionText>{translate('subscriptions.detail.upgrades1')}</SectionText>
              <SectionText>{translate('subscriptions.detail.upgrades2')}</SectionText>
            </>
          )}
        </ContentBox>
      </InnerContainer>
      <ButtonContainer>
        <Button p="15px" onClick={selectPlan}>
          {translate('common.continue')}
        </Button>
        <Button p="15px" onClick={goBack} color={ButtonColor.secondary}>
          {translate('subscriptions.detail.backMemberships')}
        </Button>
      </ButtonContainer>
    </MembershipContainer>
  );
};

export default SubscriptionsDetail;

const ContentBox = styled.div`
  background-color: ${(props) => props.theme.main.lightGray};
  padding: 16px;
  padding-bottom: 40px;
`;

const ContentTitle = styled.span`
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  font-weight: 700;
  line-height: 22px;
`;

const SectionTitle = styled(ContentTitle)`
  justify-content: flex-start;
  margin-top: 16px;
  margin-bottom: 8px;
`;

const SectionText = styled.span`
  display: list-item;
  list-style-position: inside;
  font-size: 14px;
  line-height: 19px;
`;

const SectionTextPrice = styled.span`
  display: block;
  font-size: 14px;
  line-height: 19px;
`;

const PeriodSelection = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-around;
  padding: 16px;
`;

const Period = styled.span<{ selected: boolean }>`
  font-size: 18px;
  line-height: 25px;
  font-weight: ${(props) => (props.selected ? 'bold' : 'normal')};

  > div {
    line-height: 8px;
    font-size: 12px;
  }
`;
