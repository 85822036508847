import { calculateExpireDay } from './CalculateExpireDay';

export const SortByDate = (data, dateKey, sort = 'asc') => {
  const cloneData = [...data];
  try {
    if (cloneData && dateKey && sort === 'asc') {
      return cloneData.sort((a, b) => new Date(a[dateKey]).getTime() - new Date(b[dateKey]).getTime());
    } else if (cloneData && dateKey && sort === 'desc') {
      return cloneData.sort((a, b) => new Date(b[dateKey]).getTime() - new Date(a[dateKey]).getTime());
    }
    return [];
  } catch (err) {
    return cloneData;
  }
};

export const SortByExpireDate = (data) => {
  try {
    return [...data]?.sort((a, b) => calculateExpireDay(a?.expireAt) - calculateExpireDay(b?.expireAt)) || [];
  } catch (err) {
    return [...data];
  }
};
