import React, { useEffect, useState } from 'react';
import { PlanCard } from './components/PlanCard';
import { Container, Loading } from 'lib/components';
import useStore from 'lib/hooks/useStore';
import { useApolloClient } from '@apollo/client';
import { GET_MEMBERSHIPS_PLANS } from 'lib/graphql/queries/Memberships';

const Memberships = () => {
  const client = useApolloClient();
  const [membershipPlans, setMembershipPlans] = useState([]);
  const [loading, setLoading] = useState(true);
  const { borrower, setHeaderTitle, setMembershipPlanList } = useStore();

  useEffect(() => {
    setHeaderTitle('menu.listItems.memberships');
  }, [setHeaderTitle]);

  useEffect(() => {
    client
      .query({
        query: GET_MEMBERSHIPS_PLANS,
        variables: {
          input: {
            search: [
              {
                key: 'phone',
                value: borrower?.phone,
              },
            ],
            pagination: {
              limit: 20,
              offset: 0,
              orderBy: 'createdAt',
              order: 'desc',
            },
          },
        },
      })
      .then((res) => {
        setMembershipPlans(res?.data?.fetchCustomerMembershipsPlans?.contents);
        setMembershipPlanList(res?.data?.fetchCustomerMembershipsPlans?.contents);
        setLoading(false);
      })
      .catch(() => setLoading(false));
  }, []);

  if (loading) {
    return <Loading transparent={true} />;
  }

  return (
    <Container block={true}>
      {membershipPlans?.map((plan, index) => (
        <PlanCard key={index} plan={plan} />
      ))}
    </Container>
  );
};

export default Memberships;
