import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { GridSelectionButton } from './GridSelectionButton';
import { RadioGroup } from '@mui/material';

type Option = {
  value: string;
  text: string;
};

type GridSelectionProps = {
  buttonCountPerRow: number;
  options: Option[];
  defaultValue?: string;
  onChange: (value: string) => void;
};

export const GridSelection = ({ options, buttonCountPerRow, defaultValue, onChange }: GridSelectionProps) => {
  const [value, setValue] = useState<string>(defaultValue || '');

  useEffect(() => {
    if (value) {
      onChange(value);
    }
  }, [value]);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event?.target?.value) {
      setValue(event.target.value);
    }
  };

  return (
    <GridSelectionContainer
      value={value}
      defaultValue={defaultValue}
      onChange={handleChange}
      buttonCountPerRow={buttonCountPerRow}
    >
      {options?.map((option) => (
        <GridSelectionButton {...option} isChecked={value === option.value} />
      ))}
    </GridSelectionContainer>
  );
};

const GridSelectionContainer = styled(RadioGroup)<{ buttonCountPerRow: number }>`
  width: 100%;
  display: grid !important;
  gap: 8px;
  grid-template-columns: ${(props) => `repeat(${props.buttonCountPerRow}, 1fr)`};

  @media (max-width: ${(props) => props.theme.size.mobileXl}) {
    grid-template-columns: repeat(1, 1fr);
  }
`;
