import { Modal } from '@mui/material';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { useEffect, useState } from 'react';

import { Button, ButtonColor, Input } from 'lib/components';
import { SegmentEventNames, useSegment } from 'lib/hooks/useSegment';
import useStore from 'lib/hooks/useStore';
import useGuestMode from 'lib/hooks/useGuestMode';
import { emailPattern } from 'lib/utils';
import { useTranslation } from 'react-i18next';

interface Props {
  open: boolean;
  loading: boolean;
  email: string;
  handleClose: () => void;
  changeEmailHandler: (email: string) => void;
}

const style = {
  // eslint-disable-next-line @typescript-eslint/prefer-as-const
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 250,
  bgcolor: 'background.paper',
  border: '2px solid #fff',
  borderRadius: '12px',
  outline: 0,
  p: 4,
};

export const UpdateEmailModal = ({ open, loading, email, handleClose, changeEmailHandler }: Props) => {
  const [localEmail, setLocalEmail] = useState('');
  const { borrower } = useStore();
  const buttonDisable = !emailPattern.test(localEmail);
  const { trackSegmentEvent, trackIdentify } = useSegment();
  const { isGuestMode } = useGuestMode();
  const { t: translate } = useTranslation();

  useEffect(() => {
    if (open) {
      setLocalEmail(email);
    }
  }, [open]);

  const onChangeHandler = (value) => {
    setLocalEmail(value);
  };

  const onButtonHandler = () => {
    trackSegmentEvent(SegmentEventNames.EMAIL_UPDATED, {
      borrowerId: borrower?.id,
      hadEmailPreviously: !!email,
    });
    trackIdentify({ userId: borrower?.id, email: localEmail });
    changeEmailHandler(localEmail);
  };

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      data-testid="changeEmailModal"
    >
      <Box sx={style}>
        <Typography id="modal-modal-title" variant="h6" component="h6" textAlign={'center'}>
          {translate('account.contact.updateEmail')}
        </Typography>
        <Input
          data-testid="accountEmailInput"
          onChange={onChangeHandler}
          label={translate('subscriptions.contactInfo.emailLabel')}
          value={localEmail || ''}
          defaultValue={email}
          unmask={true}
        />
        <Button
          disabled={buttonDisable || loading || isGuestMode}
          loading={loading}
          m={'20px 0px 10px 0px'}
          p={'10px'}
          onClick={onButtonHandler}
        >
          {translate('common.update')}
        </Button>
        <Button disabled={loading} onClick={handleClose} p={'5px'} color={ButtonColor.secondary}>
          {translate('common.cancel')}
        </Button>
      </Box>
    </Modal>
  );
};
