import styled from 'styled-components';

const DateInput = ({ inputRef, inputProps, InputProps, datePickerOpen, noPadding = false }) => {
  return (
    <Container onClick={datePickerOpen}>
      <PhoneContainer noPadding={noPadding}>
        <StyledInput ref={inputRef} {...inputProps} placeholder={'Date (mm/dd/yyyy)'} readOnly={true} />
      </PhoneContainer>
      <DateIconContainer>{InputProps?.endAdornment}</DateIconContainer>
    </Container>
  );
};

const Container = styled.div`
  position: relative;
  cursor: pointer;
`;
const DateIconContainer = styled.div`
  position: absolute;
  right: 20px;
  top: 40px;
`;

const PhoneContainer = styled.div<{ noPadding?: boolean }>`
  position: relative;
  justify-content: center;
  align-items: center;
  display: flex;
  color: ${(props) => props.theme.main.primary};
  padding: ${(props) => (props.noPadding ? 0 : '15px 0px')};
  width: 100%;
  border-radius: 5px;
`;

const StyledInput = styled.input`
  border: 1px ${(props) => props.theme.main.primary} solid;
  padding: 15px;
  margin: auto;
  border-radius: 5px;
  width: 100%;
  font-size: 16px;
  background-color: ${(props) => props.theme.main.white};
  text-align: left;
  border-color: ${(props) => props.theme.main.textColor};
  outline: 0;
  \ &:focus {
    border-color: ${(props) => props.theme.main.green};
  }
`;

export default DateInput;
