import { theme } from 'config/theme';
import styled from 'styled-components';

export const Title = styled.span<{ m?: string; p?: string; color?: string; fontSize?: string; lineHeight?: string }>`
  font-family: ${theme.fonts.primary}, serif;
  margin: ${(props) => props.m};
  padding: ${(props) => props.p};
  font-style: normal;
  font-weight: 700;
  font-size: ${(props) => (props.fontSize ? props.fontSize : '20px')};
  line-height: ${(props) => (props.lineHeight ? props.lineHeight : '27px')};
  color: ${(props) => (props.color ? props.color : props.theme.main.midnightBlue)};

  i {
    color: ${(props) => props.theme.main.green};
    font-style: normal;
  }
`;
