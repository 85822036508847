import { Loan } from 'pages/Purchases/type';
import { isArray } from './Validators';
import { LoanPlanStatuses } from 'lib/constans';

export const isLoanFullRefund = (loan: Loan) => {
  return (
    isArray(loan?.plans)[0]?.status === LoanPlanStatuses.REFUND &&
    isArray(loan?.plans)[0]?.subStatus === LoanPlanStatuses.REFUND
  );
};

export const isLoanPendingPayment = (loan: Loan) => {
  return loan?.status === 'FUNDED' || loan?.status === 'AWAITING_FUNDING';
};

export const isLoanDeceased = (loan: Loan) => {
  return isArray(loan?.plans)[0]?.status === LoanPlanStatuses.CHARGED_OFF && loan?.deceased;
};

export const isLoanFraud = (loan: Loan) => {
  return isArray(loan?.plans)[0]?.status === LoanPlanStatuses.CHARGED_OFF && loan?.fraud;
};

export const isLoanClosed = (isFraud: boolean, isDeceased: boolean) => {
  return isFraud && isDeceased;
};

export const isLoanChargeOff = (loan: Loan, isClosed: boolean, isDeceased: boolean, isFraud: boolean) => {
  return isArray(loan?.plans)?.[0]?.status === LoanPlanStatuses.CHARGED_OFF && !isClosed && !isDeceased && !isFraud;
};

export const findNextLoanPayment = (nextScheduledPlan, nextDuePayment) => {
  return nextScheduledPlan ? nextScheduledPlan?.scheduledDate : nextDuePayment?.dueAt;
};
