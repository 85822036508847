import { Fade } from '@mui/material';
import { useEffect, useState } from 'react';
import { useAlert } from 'react-alert';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { Loading } from 'lib/components';
import { Button, theme } from '@frontend/cherry-library';
import { paymentSearch } from 'lib/graphql/searches';
import { AnalyticEventNames, useAnalytics } from 'lib/hooks/useAnalytics';
import { useDeletePayment } from 'lib/hooks/usePayment';
import { useGetPaymentMethods } from 'lib/hooks/usePayment';
import { SegmentEventNames, useSegment } from 'lib/hooks/useSegment';

import { useLoanList } from 'lib/hooks/useContract';
import useStore from 'lib/hooks/useStore';
import { DeleteCardModal, NoCard, PaymentItem } from './';

export const ListPaymentMethod = ({ borrower, contents, addNewPaymentMethod }) => {
  const alert = useAlert();
  const { t: translate } = useTranslation();
  const { trackEvent } = useAnalytics();
  const [deletePaymentMethod, { data, loading }] = useDeletePayment();
  const [getPaymentMethods] = useGetPaymentMethods();
  const [fetchLoanList] = useLoanList('FUNDED');

  const { loanList } = useStore();
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [deleteModalTitle, setDeleteModalTitle] = useState('');
  const [cardId, setCardId] = useState(0);
  const { trackSegmentEvent } = useSegment();

  const closeModal = () => setShowDeleteModal(false);

  useEffect(() => {
    if (!loanList.length) {
      fetchLoanList();
    }
  }, []);

  useEffect(() => {
    if (data?.deletePaymentMethod?.borrowerId) {
      alert.success(translate('account.payment.listPayment.removeSuccessful'));
      getPaymentMethods({
        variables: {
          input: {
            borrowerId: borrower?.id?.toString(),
            search: paymentSearch,
          },
        },
      });
      setShowDeleteModal(false);
    }
  }, [data]);

  const deleteSelectedCard = (title: string, id: number) => {
    trackEvent(AnalyticEventNames.ACC_TRASH_CLICK);
    setShowDeleteModal(true);
    setDeleteModalTitle(title);
    setCardId(id);
  };

  const getSelectedCardInfoById = (selectedCardId: number) => contents?.find((card) => card?.id === selectedCardId);

  const deleteCardHandler = async () => {
    const { type } = getSelectedCardInfoById(cardId) || {};

    trackSegmentEvent(SegmentEventNames.PAYMENT_METHOD_REMOVED, {
      borrowerId: borrower?.id,
      type,
      paymentMethodId: cardId,
    });
    deletePaymentMethod({ variables: { input: { borrowerId: borrower?.id, cardId } } });
  };

  const paymentMethodContents = (type: string) => {
    return contents?.filter((content) => content.type === type);
  };

  const bankMethodContents = () => {
    return contents?.filter((content) => content.type === 'ACH' || content.type === 'RCC');
  };

  if (contents && contents.length < 1) {
    return <NoCard addNewPaymentMethod={addNewPaymentMethod} />;
  }

  return (
    <Fade in={true} timeout={750}>
      <MainContainer>
        {contents && (
          <Container data-testid="paymentList">
            {paymentMethodContents('CARD').map((paymentMethod, index) => (
              <MainContainer key={`CARD_${paymentMethod?.id}`}>
                <PaymentItem
                  data={paymentMethod}
                  deleteCard={deleteSelectedCard}
                  paymentMethodLength={contents?.length || 0}
                />
                {index < contents?.length - 1 && <Seperator />}
              </MainContainer>
            ))}

            {bankMethodContents().map((paymentMethod, index, bankMethods) => (
              <MainContainer key={`${paymentMethod?.type}_${paymentMethod?.id}`}>
                <PaymentItem
                  data={paymentMethod}
                  deleteCard={deleteSelectedCard}
                  paymentMethodLength={contents?.length || 0}
                />
                {index < bankMethods?.length - 1 && <Seperator />}
              </MainContainer>
            ))}

            <Button fullWidth onClick={addNewPaymentMethod}>
              {translate('account.payment.form.info')}
            </Button>
          </Container>
        )}

        {!contents && <Loading transparent={true} />}

        <DeleteCardModal
          open={showDeleteModal}
          title={deleteModalTitle}
          loading={loading}
          handleClose={closeModal}
          deleteButtonHandler={deleteCardHandler}
        />
      </MainContainer>
    </Fade>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const MainContainer = styled.div``;

const Seperator = styled.div`
  background: ${theme.main.midnightBlue20};
  height: 1px;
  width: 100%;
  margin: 16px 0;
`;
