import { Loan } from 'pages/Purchases/type';
import React from 'react';
import styled from 'styled-components';
import { Body, Label, theme } from '@frontend/cherry-library';
import { RadioButton } from 'lib/components';
import { findNextDueDate, FormatAmount, isArray } from 'lib/utils';
import { useTranslation } from 'react-i18next';
import { calculateAmountDue } from 'lib/utils/calculateAmountDue';

interface SelectPaymentCardProps {
  loan: Loan;
  setLoanToPay: (value: string) => void;
  selectedLoanId: string;
}

export const SelectPaymentCard = ({ loan, setLoanToPay, selectedLoanId }: SelectPaymentCardProps) => {
  const { t: translate } = useTranslation();
  const { plans, schedules } = loan;
  const isChargeOff = isArray(plans)?.[0]?.status === 'CHARGED_OFF';

  const setLoanToPayHandler = () => {
    setLoanToPay(loan?.id);
  };

  const pastDueDays = isArray(plans)?.[0]?.daysPastDue;
  const nextDuePayment = findNextDueDate(schedules);

  const { amountDue, daysPastDue, payOff } = isArray(plans)[0] || {};
  const amountDueValue = calculateAmountDue({
    daysPastDue,
    originalClosedAt: loan?.originalClosedAt,
    amountDue,
    payOff,
  });

  const installmentAmount = pastDueDays ? amountDueValue : nextDuePayment?.amount;
  const isOnePaymentLeft = schedules?.filter((schedule) => schedule?.status === 'SCHEDULED')?.length === 1;
  const daysPastDueText = `loansToPay.detail.overdue${pastDueDays === 1 ? '' : 's'}`;

  const getTotalAmount = () => {
    if (isChargeOff) return plans?.[0]?.netChargeOff;
    if (isOnePaymentLeft) return plans?.[0]?.payOff;

    const { dueFees } = plans?.[0] || {};
    const dueFeesCalculation = installmentAmount ? installmentAmount + dueFees : dueFees;
    return dueFees ? dueFeesCalculation : installmentAmount;
  };

  return (
    <Container data-testid="paymentTypeItem" onClick={setLoanToPayHandler} checked={loan?.id === selectedLoanId}>
      <InnerContainer>
        <RadioContainer>
          <RadioButton checked={loan?.id === selectedLoanId} value={selectedLoanId} />
          <CenteredContainer>
            <Label fontWeight={600} size="large">
              {translate('upcomingPayments.planId')}: {loan?.displayId}
            </Label>

            <Body fontWeight={600} size="default">
              {loan?.merchant?.name}
            </Body>
            <Label fontWeight={600} size="large">
              {translate('loansToPay.detail.remaining', {
                amountDue: FormatAmount(isChargeOff ? isArray(plans)[0]?.netChargeOff : isArray(plans)[0]?.payOff) || 0,
              })}
            </Label>
            {pastDueDays > 0 && getTotalAmount() && (
              <Label fontWeight={600} size="large" color={theme.main.cherryRed}>
                {translate(daysPastDueText, {
                  overdueAmount: FormatAmount(getTotalAmount() as number),
                  overdueDays: pastDueDays,
                })}
              </Label>
            )}
          </CenteredContainer>
        </RadioContainer>
      </InnerContainer>
    </Container>
  );
};

export default SelectPaymentCard;

const Container = styled.div<{ checked: boolean }>`
  cursor: pointer;
  min-height: 88px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  border: ${(props) =>
    props.checked ? `2px solid ${theme.main.kellyGreen}` : `1px solid ${theme.main.midnightBlue50}`};
  background-color: ${(props) => (props.checked ? theme.main.kellyGreen10 : theme.main.white)};
  opacity: ${(props) => (props.checked ? 1 : 0.7)};
  width: 100%;
  margin-bottom: 8px;
`;

const InnerContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;

const RadioContainer = styled.div`
  min-width: 20px;
  padding-left: 10px;
  display: flex;
  flex-direction: row;
`;

const CenteredContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 13px 16px;
`;
