interface FileListingResponse {
  message: string;
  mime: string;
  name: string;
  id: string;
  data: string;
}

export const FileDownload = (data: FileListingResponse) => {
  if (data?.data && data.mime) {
    const base64WithoutPrefix = data.data;
    const mime = data.mime.split('/')[1];

    const bytes = atob(base64WithoutPrefix);
    let length = bytes.length;
    const out = new Uint8Array(length);

    while (length--) {
      out[length] = bytes.charCodeAt(length);
    }

    const blob = new Blob([out], { type: mime === 'pdf' ? 'application/pdf' : `image/${mime}` });
    const url = URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;

    link.download = `${data.name}-${data.id}.${mime}`;
    link.target = '_blank';
    link.click();
  }
};

export const getBase64 = (file) => {
  return new Promise((resolve) => {
    let baseURL: string | ArrayBuffer | null = '';
    const reader = new FileReader();
    reader.readAsDataURL(file);

    reader.onload = () => {
      baseURL = (reader.result as string)?.replace('data:', '')?.replace(/^.+,/, '');
      resolve(baseURL);
    };
  });
};
