import styled from 'styled-components';
import { Icon, Body, theme } from '@frontend/cherry-library';

export const MenuTile = ({ onClick, src, text, disabled = false }) => {
  return (
    <MenuItemContainer onClick={disabled ? undefined : onClick} disabled={disabled}>
      <Icon color={disabled ? theme.main.midnightBlue20 : theme.main.kellyGreen} src={src} />
      <MenuItemText fontWeight={600} color={disabled ? theme.main.midnightBlue30 : theme.main.midnightBlue}>
        {text}
      </MenuItemText>
    </MenuItemContainer>
  );
};

const MenuItemText = styled(Body)`
  font-size: 9px !important;
`;

const MenuItemContainer = styled.div<{ disabled: boolean }>`
  min-width: 80px;
  min-height: 56px;
  height: auto;
  display: flex;
  padding: 6px;
  border-radius: 4px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: ${(props) => (props.disabled ? 'default' : 'pointer')};
  border-radius: 4px;
  border: 0px solid ${theme.main.midnightBlue};
  background-color: ${(props) => (props.disabled ? theme.main.midnightBlue10 : theme.main.white)};
`;
