import { useEffect } from 'react';
import useStore from 'lib/hooks/useStore';
import { useGetPaymentMethods } from 'lib/hooks/usePayment';
import { paymentSearch } from 'lib/graphql/searches';

export const useGetPaymentMethodsIfNotExists = () => {
  const { paymentMethods, borrower } = useStore();
  const [getPaymentMethods] = useGetPaymentMethods();

  const fetchPaymentMethods = () => {
    if (borrower?.id) {
      getPaymentMethods({
        variables: {
          input: {
            borrowerId: borrower.id.toString(),
            search: paymentSearch,
          },
        },
      });
    }
  };

  useEffect(() => {
    if (!paymentMethods) {
      fetchPaymentMethods();
    }
  }, [paymentMethods, borrower]);

  return paymentMethods;
};

export default useGetPaymentMethodsIfNotExists;
