import { LocalizationProvider, DesktopDatePicker } from '@mui/x-date-pickers';
import dayjs from 'dayjs';
import { useEffect, useState } from 'react';
import { useAlert } from 'react-alert';
import { Trans, useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { Container } from 'lib/components';
import { useLoanList } from 'lib/hooks/useContract';
import { useDueDateChanges } from 'lib/hooks/useServicing';
import useStore from 'lib/hooks/useStore';
import { findNextDueDate, MONTH, useFormatDate } from 'lib/utils';
import { SegmentEventNames, SegmentPageNames, useSegment } from 'lib/hooks/useSegment';
import { Body, Button, Heading } from '@frontend/cherry-library';
import TextField from '@mui/material/TextField';
import { MM_DD_YYYY, SEGMENT_EVENT_NAMES } from 'lib/constans';
import { getSegmentEventDetailsData } from 'lib/utils/SegmentEventData';

const ModifyDueDate = () => {
  const { state } = useLocation();
  const { t: translate } = useTranslation();
  const navigate = useNavigate();
  const { trackPage, trackSegmentEvent } = useSegment();
  const { FormatDate } = useFormatDate();
  const [updated, setUpdated] = useState(false);

  const { setHeaderTitle, getActiveLoan, borrower } = useStore();
  const [changeDueDate, { loading, data: dueDateData }] = useDueDateChanges();
  const [fetchLoanList, { data: fetchLoanData }] = useLoanList('FUNDED');

  const [open, setOpen] = useState(false);

  const [dateValue, setDateValue] = useState('');
  const [rawValue, setRawValue] = useState<any>(null);

  // @ts-ignore state?.loanId
  const loan = getActiveLoan(state?.loanId);
  const nextDuePayment = findNextDueDate(loan?.schedules);
  const segmentEventData = getSegmentEventDetailsData(borrower, loan);

  const setInitialDate = () => {
    if (nextDuePayment?.dueAt) {
      const dueAt = new Date(nextDuePayment.dueAt);
      const day = dueAt.getDate();

      if (day < 29) {
        setRawValue(dayjs(dueAt).add(1, 'day').toISOString());
        dueAt.setDate(dueAt.getDate() + 1);
        setDateValue(dayjs(nextDuePayment.dueAt)?.add(1, 'day').format(MM_DD_YYYY));
      } else {
        const nextMonthFirstDay = dayjs(nextDuePayment.dueAt).add(1, 'month').startOf('month');
        setRawValue(nextMonthFirstDay.toISOString());
        setDateValue(nextMonthFirstDay.format(MM_DD_YYYY));
      }
      trackPage(SEGMENT_EVENT_NAMES.PATIENT_PORTAL.CHANGE_DUE_DATE.PAGE_LOAD, segmentEventData);
    }
  };

  useEffect(() => {
    setHeaderTitle(`modifyDueDate.updateTitle`);
    setInitialDate();
  }, []);

  useEffect(() => {
    if (dueDateData?.dueDateChanges) {
      fetchLoanList();
    }
  }, [dueDateData]);

  useEffect(() => {
    if (fetchLoanData?.fetchLoanList) {
      setUpdated(true);
      trackSegmentEvent(SEGMENT_EVENT_NAMES.PATIENT_PORTAL.CHANGE_DUE_DATE_SUCCESS.PAGE_LOAD, segmentEventData);
    }
  }, [fetchLoanData]);

  const goToDetails = () => {
    trackSegmentEvent(SEGMENT_EVENT_NAMES.PATIENT_PORTAL.CHANGE_DUE_DATE_SUCCESS.OKAY_CLICKED, segmentEventData);
    navigate(`/portal/${loan?.id}/purchase`);
  };

  const modifyDueDateHandler = () => {
    if (rawValue && nextDuePayment) {
      const changedDate = nextDuePayment?.dueAt?.substring(0, nextDuePayment?.dueAt?.indexOf('T'));
      const newDate = dayjs(rawValue)?.format('YYYY-MM-DD');

      trackSegmentEvent(SEGMENT_EVENT_NAMES.PATIENT_PORTAL.CHANGE_DUE_DATE.SUBMIT_DUE_DATE_CHANGE_CLICKED, {
        ...segmentEventData,
        date: rawValue,
      });

      changeDueDate({
        variables: {
          input: {
            changedDate,
            newDate,
            // @ts-ignore idLoan: state?.loanId,
            idLoan: state?.loanId,
          },
        },
      });
    }
  };

  const handleDateChange = (value) => {
    // @ts-ignore
    setRawValue(value);
    setDateValue(dayjs(value).format('MM/DD/YYYY'));
    trackSegmentEvent(SEGMENT_EVENT_NAMES.PATIENT_PORTAL.CHANGE_DUE_DATE.DATE_SELECTED, {
      ...segmentEventData,
      date: value,
    });
  };

  const openDatePicker = () => {
    trackSegmentEvent(SEGMENT_EVENT_NAMES.PATIENT_PORTAL.CHANGE_DUE_DATE.DATE_PICKER_CLICKED, segmentEventData);
    setOpen(true);
  };

  const ordinalSuffixedDay = () => {
    const day = dayjs(rawValue).date();
    const j = day % 10;
    const k = day % 100;
    if (j === 1 && k !== 11) {
      return day + 'st';
    }
    if (j === 2 && k !== 12) {
      return day + 'nd';
    }
    if (j === 3 && k !== 13) {
      return day + 'rd';
    }
    return day + 'th';
  };

  const goBackHandler = () => {
    trackSegmentEvent(SEGMENT_EVENT_NAMES.PATIENT_PORTAL.CHANGE_DUE_DATE.BACK_ARROW_CLICKED, segmentEventData);
    navigate(-1);
  };

  const shouldDisableDate = (date) => {
    const day = date.date();
    return day > 28;
  };

  return (
    <Container block={true}>
      <CardContainer>
        {updated && (
          <Wrapper>
            <Heading level="4" text={translate('dueDate.dueDateChanged')}></Heading>
            <Body>{translate('dueDate.changeConfirmation')}</Body>
            <Body>
              <Trans
                i18nKey="dueDate.changeExplanation"
                values={{ date: FormatDate(dateValue, MONTH.long), day: ordinalSuffixedDay() }}
                components={{}}
              />
            </Body>
            <Button fullWidth onClick={goToDetails}>
              {translate('general.okay')}
            </Button>
          </Wrapper>
        )}
        {!updated && (
          <>
            <PaymentContainer>
              <StatusSubHead>{translate(`modifyDueDate.chooseNewDueDate`)}</StatusSubHead>
              <ContentContainer>
                <ul>
                  <li>
                    {translate(`modifyDueDate.desc1`, {
                      dueAt: FormatDate(nextDuePayment?.dueAt),
                    })}
                  </li>
                  <li>{translate('modifyDueDate.desc2')}</li>
                  <li>{translate('modifyDueDate.desc3')}</li>
                </ul>
              </ContentContainer>
            </PaymentContainer>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DesktopDatePicker
                value={dateValue}
                onChange={handleDateChange}
                open={open}
                onOpen={openDatePicker}
                onClose={() => setOpen(false)}
                minDate={dayjs(nextDuePayment?.dueAt).add(1, 'day')}
                maxDate={dayjs(nextDuePayment?.dueAt).add(20, 'day')}
                renderInput={(params) => {
                  return <TextField {...params} onClick={(e) => setOpen(true)} />;
                }}
                shouldDisableDate={shouldDisableDate}
              />
            </LocalizationProvider>
            {dateValue && (
              <InfoText>
                <Trans
                  i18nKey="modifyDueDate.info"
                  values={{ month: FormatDate(dateValue, MONTH.long), day: ordinalSuffixedDay() }}
                  components={{}}
                />
              </InfoText>
            )}
            <ButtonContainer>
              <Button
                fullWidth
                loading={loading}
                disabled={loading || !Boolean(rawValue)}
                onClick={modifyDueDateHandler}
              >
                {translate('autopay.modifyDueDate')}
              </Button>
              <Button fullWidth variant="secondary" onClick={goBackHandler}>
                {translate('genericButton.back')}
              </Button>
            </ButtonContainer>
          </>
        )}
      </CardContainer>
    </Container>
  );
};

const PaymentContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: 8px;
`;

const StatusSubHead = styled.span`
  text-transform: capitalize;
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
  margin-bottom: 8px;
`;

const ContentContainer = styled.div`
  margin-bottom: 0px;

  > ul {
    margin: 0;
    padding-left: 20px;
    > li {
      font-size: 16px;
      font-weight: 400;
      line-height: 21px;
    }
  }
`;

const CardContainer = styled.div`
  margin-top: 24px;
  display: flex;
  flex-direction: column;
  border-bottom: 1px solid ${(props) => props.theme.main.cardBorderColor};
  padding: 24px;
  background: #ffffff;
  box-shadow: 0px 6px 9px rgba(0, 0, 0, 0.07), 0px 0.751293px 1.12694px rgba(0, 0, 0, 0.035);
  border-radius: 8px;
  margin-bottom: 20px;
`;

const InfoText = styled.span`
  font-size: 14px;
`;

const ButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 24px;
  gap: 8px;
`;

const Wrapper = styled.div`
  display: flex;
  gap: 16px;
  flex-direction: column;
`;
export default ModifyDueDate;
