import Rox from 'rox-browser';

export const Flags = {
  FEATURES: {
    yellowAiChatBot: new Rox.Flag(false),
    yellowVoiceBot: new Rox.Flag(false),
  },
};

// Register the feature flags
(Object.keys(Flags) as (keyof typeof Flags)[])?.forEach((namespace) => {
  Rox.register(namespace, Flags[namespace]);
});
