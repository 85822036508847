import { browserIsSafari, isIOS } from './BrowserIsMobile';

export const ExternalRedirect = (link) => {
  if (link) {
    const a = document.createElement('a');
    a.setAttribute('href', link?.replace('www.', 'http://'));

    if (!(browserIsSafari() && isIOS())) {
      a.setAttribute('target', '_blank');
    }

    a.click();
  }
};
