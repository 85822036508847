import { Container } from 'lib/components';
import useStore from 'lib/hooks/useStore';
import { useEffect } from 'react';
import { ApprovalDetail } from './ApprovalDetail';
import { approvalPagination } from 'lib/graphql/paginations';
import { approvalSearch } from 'lib/graphql/searches';
import { useApplicationList } from 'lib/hooks/useApproval';
import styled from 'styled-components';
import { theme } from '@frontend/cherry-library';
import { useNavigate } from 'react-router-dom';

export const ChangeLocation = () => {
  const { selectedApplication: approval, setHeaderTitle, borrower } = useStore();
  const navigate = useNavigate();
  const [fetchApplicationList] = useApplicationList();

  useEffect(() => {
    setHeaderTitle('applicationDetails.changeLocationPreferences');
  }, [setHeaderTitle]);

  useEffect(() => {
    if (!approval) {
      navigate('/portal/approvals');
    }
  }, []);
  const clearSelectedApproval = (fetch = false) => {
    if (fetch) {
      fetchApplicationList({
        variables: {
          input: {
            search: approvalSearch(borrower?.id),
            pagination: approvalPagination,
          },
        },
      });
    }
  };

  return (
    <Container block={true}>
      {approval ? (
        <InnerContainer>
          <ApprovalDetail approval={approval} handleClose={clearSelectedApproval} />
        </InnerContainer>
      ) : null}
    </Container>
  );
};

const InnerContainer = styled.div`
  margin: 0;
  background-color: ${theme.main.white};
  max-width: 600px;
  padding: 24px;
  flex-direction: column;
  align-items: center;
  align-self: stretch;
  border-radius: 24px;
  margin-bottom: 24px;
  box-shadow: 0px 36px 67px 0px rgba(0, 0, 0, 0.07), 0px 13.141px 24.456px 0px rgba(0, 0, 0, 0.05),
    0px 6.38px 11.873px 0px rgba(0, 0, 0, 0.04), 0px 3.127px 5.82px 0px rgba(0, 0, 0, 0.03),
    0px 1.237px 2.301px 0px rgba(0, 0, 0, 0.02);
`;
