import { useMutation } from '@apollo/client';
import { useAlert } from 'react-alert';
import { useTranslation } from 'react-i18next';

import { PATCH_EXTEND_APPROVAL, PATCH_EXTEND_APPROVAL_HISTORY } from 'lib/graphql/mutations';

export const usePatchExtendApproval = () => {
  const alert = useAlert();
  const { t } = useTranslation();

  return useMutation(PATCH_EXTEND_APPROVAL, {
    fetchPolicy: 'network-only',
    onError: (error) => alert.error(t(`error.${error}`)),
  });
};

export const usePatchExtendApprovalHistory = () => {
  const alert = useAlert();
  const { t } = useTranslation();

  return useMutation(PATCH_EXTEND_APPROVAL_HISTORY, {
    fetchPolicy: 'network-only',
    onError: (error) => alert.error(t(`error.${error}`)),
  });
};
