import styled from 'styled-components';

export const CustomLoading = () => {
  return (
    <LoadingContainer>
      <LoadingGif src="/loader-animated.svg" alt="loader" />
    </LoadingContainer>
  );
};

const LoadingContainer = styled.div`
  margin-top: 24px;
  display: flex;
  justify-content: center;
  background-color: 'transparent';
`;

const LoadingGif = styled.img`
  width: 50px;
`;
