import { Body, theme } from '@frontend/cherry-library';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

export const EmailSection = ({ borrower, handleOpen }) => {
  const { t: translate } = useTranslation();

  return (
    <EmailContainer data-testid="accountEmail">
      {borrower.email ? (
        <FlexContainer>
          <Body color={theme.main.midnightBlue}>{borrower.email}</Body>
        </FlexContainer>
      ) : (
        <ButtonText onClick={handleOpen} size="small">
          {translate('account.contact.addEmail')}
        </ButtonText>
      )}
    </EmailContainer>
  );
};

const FlexContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const EmailContainer = styled.div``;

const ButtonText = styled(Body)`
  text-decoration: underline;
  cursor: pointer;
`;
