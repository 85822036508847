import { Body, Button, Heading, Icon, theme } from '@frontend/cherry-library';
import { UpComingPayment } from 'pages/Purchases/type';
import { Trans, useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { ReactComponent as RightIcon } from 'assets/images/chevron-right.svg';
import SliderModal from 'lib/components/SliderModal/SliderModal';
import { useState } from 'react';
import { useFormatDate } from 'lib/utils';
import { useSegment } from 'lib/hooks/useSegment';
import { SEGMENT_EVENT_NAMES } from 'lib/constans';
import { getSegmentEventBorrowerData } from 'lib/utils/SegmentEventData';
import useStore from 'lib/hooks/useStore';

interface UpcomingPaymentCardProps {
  payment: UpComingPayment;
}

function UpcomingPaymentCard({ payment }: UpcomingPaymentCardProps) {
  const navigate = useNavigate();
  const { t: translate } = useTranslation();
  const { FormatDate } = useFormatDate();
  const { trackSegmentEvent } = useSegment();
  const { borrower } = useStore();

  const segmentEventBorrowerData = getSegmentEventBorrowerData(borrower);

  const goToPurchase = () => {
    trackSegmentEvent(SEGMENT_EVENT_NAMES.PATIENT_PORTAL.SCHEDULED_PAYMENTS.SCHEDULED_PAYMENT_CLICKED, {
      ...segmentEventBorrowerData,
      loanId: payment?.loanId,
      practiceId: payment?.practiceId,
      scheduledAt: payment?.dueDate,
      amountScheduled: payment?.amount,
    });
    navigate(`/portal/${payment?.loanId}/purchase`);
  };

  const goToMakeAPayment = () => {
    trackSegmentEvent(
      SEGMENT_EVENT_NAMES.PATIENT_PORTAL.SCHEDULED_PAYMENTS.LEARN_MORE_MODAL_MAKE_A_PAYMENT_CLICKED,
      segmentEventBorrowerData,
    );
    navigate(`/portal/${payment?.loanId}/payment-method`);
  };

  const closeModal = () => {
    hideModal();
    trackSegmentEvent(
      SEGMENT_EVENT_NAMES.PATIENT_PORTAL.SCHEDULED_PAYMENTS.DISMISS_LEARN_MORE_MODAL_CLICKED,
      segmentEventBorrowerData,
    );
  };

  const [modalVisibility, setModalVisibility] = useState(false);
  const hideModal = () => setModalVisibility(false);

  const openLearnMore = () => {
    trackSegmentEvent(SEGMENT_EVENT_NAMES.PATIENT_PORTAL.SCHEDULED_PAYMENTS.MISSED_PAYMENT_LEARN_MORE_CLICKED, {
      ...segmentEventBorrowerData,
      loanId: payment?.loanId,
      practiceId: payment?.practiceId,
      scheduledAt: payment?.dueDate,
      amountScheduled: payment?.amount,
    });
    openModal();
  };

  const openModal = () => {
    setModalVisibility(true);
  };

  const dueDate = new Date(payment?.dueDate);

  const month = dueDate?.toLocaleDateString('en-US', { month: 'short' }).toUpperCase();
  const day = dueDate?.getUTCDate();
  const year = dueDate?.getUTCFullYear();

  const missedPayment = payment?.status === 'MISSED';

  return (
    <>
      <UpcomingPaymentContainer>
        <UpcomingPaymentDateContainer missed={missedPayment}>
          <Body color={theme.main.white}>{month}</Body>
          <CustomHeading color={theme.main.white} level="2" text={String(day)}></CustomHeading>
          <Body size="small" color={theme.main.white}>
            {year}
          </Body>
        </UpcomingPaymentDateContainer>
        <UpcomingPaymentInfoContainer>
          <BodyWrapper onClick={goToPurchase}>
            <BodyContainer>
              {missedPayment && (
                <Uppercase fontWeight={600} size="small" color={theme.main.cherryRed}>
                  {translate('scheduledPayments.missedPayment')}
                </Uppercase>
              )}
              <Heading level="4" text={payment?.amount}></Heading>
              <Body>{payment?.practice}</Body>
              <Italic size="small" color={theme.main.midnightBlue50}>
                {payment?.paymentMethod}
              </Italic>
              <PlanId size="small" fontWeight={600}>
                {translate('upcomingPayments.planId')}: {payment?.displayId}
              </PlanId>
            </BodyContainer>
            <Icon color={theme.main.kellyGreen} src={RightIcon} />
          </BodyWrapper>
          {missedPayment && (
            <ButtonContainer>
              <Button size="small" fullWidth variant="secondary" onClick={openLearnMore}>
                {translate('general.learnMore')}
              </Button>
            </ButtonContainer>
          )}
        </UpcomingPaymentInfoContainer>
      </UpcomingPaymentContainer>
      {modalVisibility && (
        <SliderModal title={translate('scheduledPayments.missedPayment')} show={modalVisibility} hideModal={hideModal}>
          <CenteredBody>
            <Trans
              i18nKey="scheduledPayments.weAreUnableToProcess"
              values={{ amount: payment?.amount, date: FormatDate(payment?.dueDate) }}
            />
          </CenteredBody>
          <CenteredBody>
            <Trans i18nKey="scheduledPayments.weWillTryAgain" values={{ date: FormatDate(payment?.dueDate) }} />
          </CenteredBody>
          <ButtonWrapper>
            <Button onClick={goToMakeAPayment} fullWidth>
              {translate('homePage.button.payment')}
            </Button>
            <Button onClick={closeModal} fullWidth variant="secondary">
              {translate('common.dismiss')}
            </Button>
          </ButtonWrapper>
        </SliderModal>
      )}
    </>
  );
}

const UpcomingPaymentContainer = styled.div`
  cursor: pointer;
  background-color: ${theme.main.white};
  display: flex;
  width: 100%;
`;

const BodyWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const BodyContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const UpcomingPaymentDateContainer = styled.div<{ missed?: boolean }>`
  background-color: ${theme.main.cherryRed};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 80px;
  padding: 10px 0;
  background-color: ${({ missed }) => (missed ? theme.main.cherryRed : theme.main.midnightBlue)};
`;

const UpcomingPaymentInfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 8px;
  padding: 8px 16px;
  width: 100%;
`;

const CustomHeading = styled(Heading)<{ color: string }>`
  color: ${({ color }) => color} !important;
`;

const ButtonContainer = styled.div`
  margin-bottom: 8px;
  width: 100%;
  z-index: 1;
`;

const Italic = styled(Body)`
  font-style: italic !important;
  font-size: 13px !important;
`;

const PlanId = styled(Body)`
  font-size: 10px !important;
`;

const CenteredBody = styled(Body)`
  text-align: center;
`;

const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: 100%;
`;

const Uppercase = styled(Body)`
  text-transform: uppercase;
  font-size: 12px !important;
`;

export default UpcomingPaymentCard;
