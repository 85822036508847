import React, { FocusEvent } from 'react';
import { useTranslation } from 'react-i18next';
import { IMaskInput } from 'react-imask';
import styled from 'styled-components';

interface CustomProps {
  onChange: (event: { target: { name: string; value: string } }) => void;
  onBlur: (event: FocusEvent<HTMLInputElement>) => void;
  name: string;
}

interface SCInputProps {
  align: string;
  error: string;
  secondary: boolean;
}

export const Input = React.forwardRef<HTMLElement, CustomProps | any>(function TextMaskCustom(props, ref) {
  const { t } = useTranslation();

  const onAcceptHandler = (inputValue: string) => {
    onChange(inputValue);
  };

  const onChangeHandler = (e) => {
    onChange(e?.target?.value);
  };

  const {
    onChange,
    onBlur,
    label,
    mask,
    value,
    align = 'center',
    error,
    unmask = false,
    secondary = false,
    ...other
  } = props;
  return (
    <PhoneContainer>
      <StyledPhoneInput
        {...other}
        mask={mask}
        unmask={unmask}
        value={value}
        definitions={{ '#': /[1-9]/ }}
        inputRef={ref}
        onAccept={onAcceptHandler}
        onChange={onChangeHandler}
        onBlur={onBlur}
        overwrite={true}
        align={align}
        error={error}
        secondary={secondary}
      />
      <PhoneLabel align={align} error={error} secondary={secondary}>
        {t(label)}
      </PhoneLabel>
    </PhoneContainer>
  );
});

const PhoneContainer = styled.div`
  position: relative;
  justify-content: center;
  align-items: center;
  display: flex;
  color: ${(props) => props.theme.main.primary};
  padding: 15px 0px;
  width: 100%;
  border-radius: 5px;
`;

const StyledPhoneInput = styled(IMaskInput)<SCInputProps>`
  border: 1px ${(props) => props.theme.main.primary} solid;
  padding: 15px;
  margin: auto;
  border-radius: 5px;
  width: 100%;
  font-size: 16px;
  background-color: ${(props) => (props.secondary ? props.theme.main.pageColor : props.theme.main.white)};
  text-align: ${(props) => props.align};
  border-color: ${(props) => (props.error ? props.theme.main.errorColor : props.theme.main.textColor)};
  outline: 0;
  \ &:focus {
    border-color: ${(props) => (props.error ? props.theme.main.errorColor : props.theme.main.green)};
  }
`;

const PhoneLabel = styled.span<SCInputProps>`
  position: absolute;
  top: 5px;
  left: ${(props) => (props.align === 'center' ? 'unset' : '10px')};
  background-color: ${(props) => (props.secondary ? props.theme.main.pageColor : props.theme.main.white)};
  padding: 0px 10px;
  font-size: 16px;
  color: ${(props) => (props.error ? props.theme.main.errorColor : props.theme.main.textColor)};
  font-weight: 400;
`;
