export const phonePattern = /^\(?([2-9][0-8][0-9])\)? ?([2-9][0-9]{2})[-.●]?([0-9]{4})$/;
export const emailPattern = /^[a-zA-Z0-9&_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/;
export const idPattern = /^(?=.*[0-9])(?=.*[a-zA-Z])([a-zA-Z0-9-]+)$/;
export const ssnPattern = /^(?!(000|666|9))\d{3}-(?!00)\d{2}-(?!0000)\d{4}$/;
export const cardPattern = /^(?:[0-9]{4})-(?:[0-9]{4})-(?:[0-9]{4})-(?:[0-9]{4})$/;
export const numberPattern = /^[0-9]*$/;

export const luhnCheck = (cardNumber: string): boolean => {
  if (!cardNumber?.length) {
    return true;
  }

  cardNumber = cardNumber?.replace(/[^0-9]/gi, '');

  const lastDigit = Number(cardNumber[cardNumber.length - 1]);

  const reverseCardNumber = cardNumber
    .slice(0, cardNumber.length - 1)
    .split('')
    .reverse()
    .map((x) => Number(x));

  let sum = 0;

  for (let i = 0; i <= reverseCardNumber.length - 1; i += 2) {
    reverseCardNumber[i] = reverseCardNumber[i] * 2;
    if (reverseCardNumber[i] > 9) {
      reverseCardNumber[i] = reverseCardNumber[i] - 9;
    }
  }

  sum = reverseCardNumber.reduce((acc, currValue) => acc + currValue, 0);

  return (sum + lastDigit) % 10 === 0;
};

export const isArray = (data) => {
  if (data && Array.isArray(data)) {
    return data;
  }
  return [];
};

export const expireDateCheck = (expDate: string): boolean => {
  const expDateData = expDate?.split('/');

  const exMonth = expDateData[0];
  const exYear = expDateData[1];

  const expiryDate = new Date(`20${exYear}-${exMonth}-01`);

  return !(expiryDate < new Date() || expiryDate.getTime() !== expiryDate.getTime());
};

export const ITINNumberRegex = /(9\d{2})([ \-]?)(5\d|6[0-5]|7\d|8[0-8]|9[0-2,4-9])([ \-]?)(\d{4})/gm;
