import { useLazyQuery } from '@apollo/client';
import { useAlert } from 'react-alert';
import { useTranslation } from 'react-i18next';

import { GET_SERVICING_STATUS_HISTORY } from 'lib/graphql/queries';

export const useGetServicingStatusHistory = (planId: number) => {
  const alert = useAlert();
  const { t } = useTranslation();

  return useLazyQuery(GET_SERVICING_STATUS_HISTORY, {
    variables: {
      input: {
        planId,
      },
    },
    fetchPolicy: 'network-only',
    onError: (error) => alert.error(t(`error.${error}`)),
  });
};
