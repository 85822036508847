import Backdrop from '@mui/material/Backdrop';
import Slide from '@mui/material/Slide';
import styled from 'styled-components';
import { Heading, theme } from '@frontend/cherry-library';
import { useRef } from 'react';

interface SliderModalProps {
  show: boolean;
  hideModal: () => void;
  title: string;
  children: React.ReactNode;
  width?: string;
  padding?: string;
}

const SliderModal = ({ show, hideModal, title, children, width, padding }: SliderModalProps) => {
  const modalRef = useRef<HTMLDivElement>(null);

  const closeModalOnClickAway = (e) => {
    if (!modalRef?.current?.contains(e?.target)) {
      hideModal();
    }
  };

  return (
    <Backdrop
      sx={{ color: theme.main.white, zIndex: (theme) => theme.zIndex.drawer + 99999 }}
      open={show}
      onClick={closeModalOnClickAway}
    >
      <Slide direction="up" in={show} mountOnEnter={true} unmountOnExit={true}>
        <ModalContainer width={width} padding={padding} ref={modalRef}>
          <ModalHeader>
            <Heading level="4" text={title}></Heading>
            <Seperator />
          </ModalHeader>
          <ModalBody>{children}</ModalBody>
        </ModalContainer>
      </Slide>
    </Backdrop>
  );
};

export default SliderModal;

const ModalContainer = styled.div<{ width?: string; padding?: string }>`
  width: ${(props) => (props.width ? props.width : '87%')};
  position: absolute;
  bottom: -15px;
  background: ${theme.main.white};
  box-shadow: 0 -36px 67px rgba(0, 0, 0, 0.07), 0 -13.14px 24.4561px rgba(0, 0, 0, 0.0482987),
    0 -6.38px 11.873px rgba(0, 0, 0, 0.0389404), 0 -3.13px 5.82036px rgba(0, 0, 0, 0.0310596),
    0 -1.24px 2.30138px rgba(0, 0, 0, 0.0217013);
  padding: ${(props) => (props.padding ? props.padding : '0 24px 40px')};
  border-radius: 24px 24px 0 0;
  max-height: 100vh;
  overflow-y: auto;
  @media (min-width: ${(props) => props.theme.size.mobileL}) {
    width: 400px;
    height: fit-content;
    border-radius: 6px;
    padding: 0 24px 24px 24px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) !important;
  }
`;

const ModalHeader = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  margin-top: 24px;
`;

const ModalBody = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 24px;
`;

const Seperator = styled.div`
  background: ${theme.main.midnightBlue20};
  height: 1px;
  width: 100%;
  margin: 24px 0;
`;
