import { Body } from '@frontend/cherry-library';
import styled from 'styled-components';

export const ContentContainer = styled.div`
  margin-top: 24px;
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

export const HeaderText = styled(Body)`
  margin-bottom: 8px !important;
  margin-top: 5px !important;
`;

export const ButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 16px 0px 0px 0px;
`;
