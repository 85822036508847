import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import styled from 'styled-components';

import { Container } from 'lib/components';
import { ExchangeTokenType, SEGMENT_EVENT_NAMES } from 'lib/constans';
import useStore from 'lib/hooks/useStore';
import { AppointmentSelection, ApprovalAppointment, ExtendSuccess, ReasonSelection, Reminder } from './components';
import { ScreenState } from './type';
import { useSegment } from 'lib/hooks/useSegment';
import { getApplicationDetailData } from 'lib/utils/ApplicationDetailSegmentEventData';

const Approvals = () => {
  const { setHeaderTitle, borrower } = useStore();
  const navigate = useNavigate();
  const { trackPage } = useSegment();
  const { state } = useLocation() || {};
  // @ts-ignore approval
  const { approval } = state;
  const [screenState, setScreenState] = useState(ScreenState.SELECTION);
  const [extendedApprovalId, setExtendedApprovalId] = useState<string>('');
  const segmentEventData = getApplicationDetailData(borrower, approval);
  useEffect(() => {
    setHeaderTitle('menu.listItems.extendApproval');
  }, [setHeaderTitle]);

  useEffect(() => {
    trackPage(SEGMENT_EVENT_NAMES.PATIENT_PORTAL.REQUEST_MORE_TIME.PAGE_LOAD, segmentEventData);
    const findFeature = approval?.features?.find(
      (feature) => feature?.type === ExchangeTokenType.APPLICATION_APPROVAL_EXTENSION && feature?.status === 'ACTIVE',
    );

    if (!approval?.id || !findFeature) {
      navigate('/portal/approvals');
    }
  }, []);

  const renderScreenTypes = () => {
    switch (screenState) {
      case ScreenState.SELECTION:
        return <ReasonSelection approval={approval} setScreenState={setScreenState} />;
      case ScreenState.APPOINTMENT:
        return (
          <AppointmentSelection
            approval={approval}
            setScreenState={setScreenState}
            setExtendedApprovalId={setExtendedApprovalId}
          />
        );
      case ScreenState.APPROVAL_APPOINTMENT:
        return (
          <ApprovalAppointment
            setScreenState={setScreenState}
            approval={approval}
            extendedApprovalId={extendedApprovalId}
          />
        );
      case ScreenState.EXTEND_SUCCESS:
        return <ExtendSuccess approval={approval} setScreenState={setScreenState} />;
      case ScreenState.REMINDER:
        return <Reminder approval={approval} setScreenState={setScreenState} />;
      default:
        return <ReasonSelection approval={approval} setScreenState={setScreenState} />;
    }
  };

  return (
    <Container block={true}>
      <InnerContainer>{renderScreenTypes()}</InnerContainer>
    </Container>
  );
};

const InnerContainer = styled.div`
  display: flex;
  flex-direction: column;
  border-bottom: 1px solid ${(props) => props.theme.main.cardBorderColor};
  padding: 24px;
  background: #ffffff;
  box-shadow: 0px 6px 9px rgba(0, 0, 0, 0.07), 0px 0.751293px 1.12694px rgba(0, 0, 0, 0.035);
  border-radius: 8px;
`;

export default Approvals;
