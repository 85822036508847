import { Box, Modal } from '@mui/material';
import { Button, ButtonColor, Loading } from 'lib/components';
import React from 'react';
import styled from 'styled-components';
import CloseIcon from '@mui/icons-material/Close';

interface GenericFileModalProps {
  open: boolean;
  title: string;
  children: React.ReactNode | React.ReactNode[];
  buttonLeftText?: string | null;
  onButtonLeftClick?: (params?: unknown) => void | null;
  mainButtonText: string;
  onMainButtonClick: (params?: unknown) => void;
  handleClose: () => void;
  loading?: boolean;
}

const style: any = {
  position: 'absolute',
  display: 'flex',
  flexDirection: 'column',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  maxWidth: 480,
  width: '90%',
  bgcolor: 'background.paper',
  border: '2px solid #fff',
  padding: '0px 20px',
};
const GenericFileModal = ({
  open,
  title,
  children,
  handleClose,
  buttonLeftText,
  onButtonLeftClick,
  mainButtonText,
  onMainButtonClick,
  loading,
}: GenericFileModalProps) => {
  const isLeftButtonVisible = !!buttonLeftText && !!onButtonLeftClick;

  return (
    <StyledModal
      open={open}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      data-testid="deleteCardModal"
    >
      <Box sx={style}>
        <ModalHeader>
          <Title>{title}</Title>
          <IconContainer>
            <StyledIconButton onClick={handleClose} />
          </IconContainer>
        </ModalHeader>

        {children}
        {loading && <Loading transparent={true}></Loading>}
        <ButtonContainer>
          {isLeftButtonVisible ? (
            <OutlinedButton color={ButtonColor.secondary} onClick={onButtonLeftClick}>
              {buttonLeftText}
            </OutlinedButton>
          ) : null}
          <UploadSubmit onClick={onMainButtonClick}>{mainButtonText}</UploadSubmit>
        </ButtonContainer>
      </Box>
    </StyledModal>
  );
};

const StyledModal = styled(Modal)`
  margin: 0 24px !important;
`;

const ModalHeader = styled.div`
  display: flex;
  font-size: 20px;
  font-weight: 700;
  margin-bottom: 24px;
  border-bottom: 1px solid #dadada;
  padding: 24px 0;
`;

const OutlinedButton = styled(Button)`
  padding: 10px;
  width: 100%;
`;

const UploadSubmit = styled(Button)`
  padding: 10px;
  width: 100%;
`;
const StyledIconButton = styled(CloseIcon)`
  width: 24px;
  height: 24px;
`;
const Title = styled.div`
  font-size: 20px;
  font-weight: 700;
  position: absolute;
  left: 0;
  right: 0;
  justify-content: center;
  display: flex;
`;

const ButtonContainer = styled.div`
  width: 180px;
  height: 40px;
  align-self: center;
  padding-bottom: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
  width: 100%;
  margin-top: 10px;
  padding: 24px 0;
`;

const IconContainer = styled.span`
  margin-left: auto;
  cursor: pointer;
  z-index: 999;
`;

export default GenericFileModal;
