enum TypeEnum {
  CHECKING = 'CHECKING',
  SAVINGS = 'SAVINGS',
  RCC = 'RCC',
  DEBIT = 'DEBIT',
  CREDIT = 'CREDIT',
}

enum AccountType {
  CHECKING = 'CHECKING',
  SAVINGS = 'SAVINGS',
}

export const hasAchOrDebitCard = (paymentMethods) => {
  return paymentMethods?.filter((pm) => {
    if (pm?.type === 'CARD') {
      return pm?.storedCard?.type === 'DEBIT' || pm?.storedCard?.network === 'AMEX';
    } else {
      return true;
    }
  });
};

export const calculatePaymentFeeAmount = (fees, storedCardType, amount) => {
  try {
    const paymentFee = fees?.[storedCardType];
    const paymentFeeFixed = paymentFee?.fixed || 0;
    if (paymentFee?.percent) {
      return paymentFeeFixed + amount * (paymentFee.percent / 100);
    }
    return 0;
  } catch (err) {
    return 0;
  }
};

export const calculatePaymentFeeTotalAmount = (fees, storedCardType, amount) => {
  try {
    const paymentFee = fees?.[storedCardType];
    const paymentFeeFixed = paymentFee?.fixed || 0;
    if (paymentFee?.percent) {
      return paymentFeeFixed + amount + amount * (paymentFee.percent / 100);
    }
    return amount;
  } catch (err) {
    return amount;
  }
};

export const getPaymentMethodsByType = (paymentMethods, type) => {
  switch (type) {
    case TypeEnum.CHECKING:
      return paymentMethods?.filter((content) => content.achAccount?.accountType === AccountType.CHECKING);
    case TypeEnum.SAVINGS:
      return paymentMethods?.filter((content) => content.achAccount?.accountType === AccountType.SAVINGS);
    case TypeEnum.RCC:
      return paymentMethods?.filter((content) => content?.type === TypeEnum.RCC);
    case TypeEnum.DEBIT:
      return paymentMethods?.filter((content) => content.type === 'CARD' && content?.storedCard?.type === 'DEBIT');
    case TypeEnum.CREDIT:
      return paymentMethods?.filter((content) => content.type === 'CARD' && content?.storedCard?.type !== 'DEBIT');
    default:
      break;
  }
};
