import { FormControlLabel as MuiFormControlLabel, Radio } from '@mui/material';
import React from 'react';
import styled from 'styled-components';

type GridSelectionButtonProps = {
  value: string;
  text: string;
  isChecked?: boolean;
};

export const GridSelectionButton = ({ value, text, isChecked = false }: GridSelectionButtonProps) => {
  return <FormControlLabel key={value} value={value} control={<Radio />} label={text} isChecked={isChecked} />;
};

const FormControlLabel = styled(MuiFormControlLabel)<{ isChecked: boolean }>`
  padding: 9px 16px;
  border-radius: 4px;
  background-color: ${(props) => (!props.isChecked ? props.theme.main.white : props.theme.main.green)};
  border: 1px solid ${(props) => props.theme.main.green};
  margin: 0 !important;
  box-sizing: border-box;

  .MuiTypography-root {
    font-family: 'Open Sans';
    color: ${(props) => (props.isChecked ? props.theme.main.white : props.theme.main.green)};
    font-weight: 600;
    font-size: 16px;
    margin: 0 auto;
  }

  .MuiRadio-root {
    display: none;
  }
`;
