import { Loan } from 'pages/Purchases/type';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { theme, Body, Heading, Icon, Button } from '@frontend/cherry-library';
import { FormatAmount, isArray, useFormatDate } from 'lib/utils';
import { useTranslation } from 'react-i18next';
import { ReactComponent as WarningImage } from 'assets/images/warning-outline.svg';
import SliderModal from 'lib/components/SliderModal/SliderModal';
import { LoanPlanStatuses, LoanStatuses, SEGMENT_EVENT_NAMES } from 'lib/constans';
import { calculateAmountDue } from 'lib/utils/calculateAmountDue';
import { getSegmentEventDetailsData } from 'lib/utils/SegmentEventData';
import useStore from 'lib/hooks/useStore';
import { useSegment } from 'lib/hooks/useSegment';

interface Props {
  loan: Loan;
  id: string;
}

export const OverDueBanner = ({ loan, id }: Props) => {
  const navigate = useNavigate();
  const { t: translate } = useTranslation();
  const { FormatDate } = useFormatDate();
  const isPaymentPastDue = isArray(loan?.plans)?.[0]?.daysPastDue > 0;
  const { borrower } = useStore();
  const { trackSegmentEvent } = useSegment();

  const plans = isArray(loan?.plans)[0];
  const amountDue = calculateAmountDue({
    daysPastDue: plans?.daysPastDue,
    originalClosedAt: loan?.originalClosedAt,
    amountDue: plans?.amountDue,
    payOff: plans?.payOff,
  });

  const segmentEventPaymentPlanDetailsData = getSegmentEventDetailsData(borrower, loan);

  const openSeeDetailsModal = () => {
    openModal();
    trackSegmentEvent(
      SEGMENT_EVENT_NAMES.PATIENT_PORTAL.PAYMENT_PLAN_DETAILS.SEE_OVERDUE_DETAILS_CLICKED,
      segmentEventPaymentPlanDetailsData,
    );
  };

  const [modalVisibility, setModalVisibility] = useState(false);
  const hideModal = () => setModalVisibility(false);
  const openModal = () => {
    setModalVisibility(true);
  };

  const goToPayment = () => {
    trackSegmentEvent(
      SEGMENT_EVENT_NAMES.PATIENT_PORTAL.PAYMENT_PLAN_DETAILS.PAST_DUE_SUMMARY_MODAL_MAKE_A_PAYMENT_CLICKED,
      segmentEventPaymentPlanDetailsData,
    );
    navigate(`/portal/${id}/payment-method`);
  };

  const closePastDueModal = () => {
    hideModal();
    trackSegmentEvent(
      SEGMENT_EVENT_NAMES.PATIENT_PORTAL.PAYMENT_PLAN_DETAILS.DISMISS_PAST_DUE_SUMMARY_MODAL_CLICKED,
      segmentEventPaymentPlanDetailsData,
    );
  };

  return (
    <>
      {isPaymentPastDue &&
        loan?.status !== LoanStatuses.AWAITING_FUNDING &&
        isArray(loan?.plans)[0]?.status !== LoanPlanStatuses.REFUND && (
          <OverDueBannerContainer>
            <>
              <Wrapper>
                <Icon color={theme.main.white} src={WarningImage} />
                <OverDueAmount level="5" text={`${FormatAmount(amountDue)} ${translate('overDueBanner.overdue')}`} />
              </Wrapper>
              <Body color={theme.main.white}>
                {translate('overDueBanner.accountOverDue', {
                  date: FormatDate(isArray(loan?.plans)?.[0]?.dateLastCurrent),
                  day: isArray(loan?.plans)?.[0]?.daysPastDue,
                  s: isArray(loan?.plans)?.[0]?.daysPastDue > 1 ? 's' : '',
                })}
              </Body>
              <SeeDetails variant="tertiary" onClick={openSeeDetailsModal} fullWidth>
                {translate('common.seeDetails')}
              </SeeDetails>
            </>
          </OverDueBannerContainer>
        )}
      {modalVisibility && (
        <SliderModal title={translate('pastDueSummaryModal.title')} show={modalVisibility} hideModal={hideModal}>
          <Body>
            {translate('overDueBanner.accountOverDue', {
              date: FormatDate(loan?.plans?.[0]?.dateLastCurrent),
              day: loan?.plans?.[0]?.daysPastDue,
              s: loan?.plans?.[0]?.daysPastDue > 1 ? 's' : '',
            })}
            {translate('overDueBanner.toBringYourAccount')}
          </Body>
          <WrapperFullWidth>
            <Heading level="5" text={translate('pastDueSummaryModal.subtitle')}></Heading>
            <Heading level="5" text={FormatAmount(amountDue)}></Heading>
          </WrapperFullWidth>
          <BreakdownTable>
            <Body>{translate('pastDueSummaryModal.breakdown.title')}</Body>
            <Row>
              <Body size="small" fontWeight={400}>
                {translate('pastDueSummaryModal.breakdown.duePrincipal')}
              </Body>
              <Body size="small" fontWeight={400}>
                {FormatAmount(loan?.plans[0]?.duePrincipal)}
              </Body>
            </Row>
            <Row>
              <Body size="small" fontWeight={400}>
                {translate('pastDueSummaryModal.breakdown.dueInterest')}
              </Body>
              <Body size="small" fontWeight={400}>
                {FormatAmount(loan?.plans[0]?.dueInterest)}
              </Body>
            </Row>
            <Row>
              <Body size="small" fontWeight={400}>
                {translate('pastDueSummaryModal.breakdown.assessedFees')}
              </Body>
              <Body size="small" fontWeight={400}>
                {FormatAmount(loan?.plans[0]?.dueFees)}
              </Body>
            </Row>
          </BreakdownTable>
          <FlexWrapper>
            <Button onClick={goToPayment} fullWidth>
              {translate('homePage.button.payment')}
            </Button>
            <Button variant="secondary" onClick={closePastDueModal} fullWidth>
              {translate('common.dismiss')}
            </Button>
          </FlexWrapper>
        </SliderModal>
      )}
    </>
  );
};

const OverDueBannerContainer = styled.div`
  display: flex;
  padding: 16px 16px 24px 16px;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 16px;
  align-self: stretch;
  background: ${theme.main.cherryRed};
  box-shadow: 0px 36px 67px 0px rgba(0, 0, 0, 0.07), 0px 13.141px 24.456px 0px rgba(0, 0, 0, 0.05),
    0px 6.38px 11.873px 0px rgba(0, 0, 0, 0.04), 0px 3.127px 5.82px 0px rgba(0, 0, 0, 0.03),
    0px 1.237px 2.301px 0px rgba(0, 0, 0, 0.02);
`;

const OverDueAmount = styled(Heading)`
  color: ${theme.main.white} !important;
`;

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

const FlexWrapper = styled(Wrapper)`
  flex-direction: column;
  width: 100%;
`;

const WrapperFullWidth = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  width: 100%;
  justify-content: space-between;
  margin-top: 24px;
`;

const SeeDetails = styled(Button)`
  align-self: center !important;
  max-width: 320px !important;
  color: ${theme.main.cherryRed} !important;
`;

const BreakdownTable = styled.div`
  background-color: ${theme.main.midnightBlue5};
  display: flex;
  padding: 16px;
  flex-direction: column;
  gap: 16px;
  align-self: stretch;
  border-radius: 4px;
`;

const Row = styled.div`
  display: flex;
  justify-content: space-between;
`;
