import styled from 'styled-components';
import { Body, Heading, theme } from '@frontend/cherry-library';
import { Container } from 'lib/components';
import { ReactNode } from 'react';
import { CardContainer } from 'lib/components/CardContainer';

type RoundedContainerProps = {
  title: string;
  noData: boolean;
  noDataText?: string;
  children?: ReactNode;
  marginBottom?: number;
  headingColor?: string;
  headingTextColor?: string;
  minHeight?: string;
};

export const RoundedContainer = ({
  title,
  noData,
  noDataText,
  children,
  marginBottom = 0,
  headingColor = theme.main.midnightBlue,
  headingTextColor = theme.main.white,
  minHeight = '',
}: RoundedContainerProps) => {
  return (
    <Container block={true} marginBottom={marginBottom}>
      {!noData && (
        <CardHeaderContainer headingColor={headingColor}>
          <CardHeaderTitle level="5" text={title} headingTextColor={headingTextColor} />
        </CardHeaderContainer>
      )}
      <CardContainer topRadius={!noData} minHeight={minHeight}>
        {noData && <NoData>{noDataText}</NoData>}
        {children ? <CardBodyContainer>{children}</CardBodyContainer> : null}
      </CardContainer>
    </Container>
  );
};

const CardHeaderContainer = styled.div<{ headingColor: string }>`
  display: flex;
  padding: 8px 16px;
  align-items: flex-start;
  align-self: stretch;
  border-radius: 16px 16px 0 0;
  background: ${(props) => props.headingColor};
`;

const CardHeaderTitle = styled(Heading)<{ headingTextColor: string }>`
  margin: 0;
  color: ${(props) => props.headingTextColor} !important;
`;

const CardBodyContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-self: stretch;

  border-radius: 0 0 16px 16px;
  border: 0px solid ${theme.main.midnightBlue70};
  background: ${theme.main.white};
`;

const NoData = styled(Body)`
  z-index: 1;
  padding: 48px 16px;
  align-self: center;
`;
