import { gql } from '@apollo/client/core';

export const DUE_DATE_CHANGES = gql`
  mutation dueDateChanges($input: DueDatesChangesRequest) {
    dueDateChanges(input: $input) {
      success
      message
      code
    }
  }
`;
