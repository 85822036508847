import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Accordion as MuiAccordion, AccordionDetails, AccordionSummary } from '@mui/material';
import { useNavigation } from 'lib/hooks/useNavigation';
import React from 'react';
import styled from 'styled-components';

import { Button } from 'lib/components';

interface IFAQ {
  title: string;
  content: string;
  hasButton?: boolean;
}

interface Props {
  data: IFAQ[];
  onChange: (value: string) => void;
  expanded: string | boolean;
}

export const Accordion = React.memo(({ data, onChange, expanded }: Props) => {
  const { navigate } = useNavigation();
  const onAccordionChange = (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
    onChange(isExpanded ? panel : '');
  };

  const openPreviewPaymentPage = () => {
    navigate(`preview-my-payments`);
  };

  return (
    <AccordionContainer>
      {data?.map((faq, index) => {
        return (
          <MuiAccordion
            key={`faq_${index}`}
            expanded={expanded === `panel${index}`}
            onChange={onAccordionChange(`panel${index}`)}
            data-testid="faqContainer"
          >
            <AccordionSummary
              expandIcon={
                <IconContainer>
                  <ExpandMoreIcon />
                </IconContainer>
              }
            >
              <FaqTitle data-testid="faqTitle">{faq?.title}</FaqTitle>
            </AccordionSummary>
            <AccordionDetails>
              <FaqDesc dangerouslySetInnerHTML={{ __html: faq?.content }} />
            </AccordionDetails>
          </MuiAccordion>
        );
      })}
    </AccordionContainer>
  );
});

const IconContainer = styled.div`
  color: ${(props) => props.theme.main.green};

  svg {
    transform: scale(1.3);
  }
`;

const AccordionContainer = styled.div`
  margin-top: 10px;
  margin-bottom: 24px;
  padding: 0;
  display: flex;
  flex-direction: column;
  background-color: white;
  box-shadow: none;

  & > ::before {
    display: none;
    border-radius: 0;
  }

  & > div {
    border-bottom: 1px solid rgba(0, 0, 0, 0.12);
    border-radius: 0;
  }

  & > .MuiPaper-root {
    box-shadow: unset;

    a {
      color: #00c37d;
      font-weight: bold;
    }

    & > .MuiButtonBase-root {
      padding: 0;

      & > .MuiAccordionSummary-content {
        margin: 16px 0 !important;
      }
    }

    .MuiAccordion-region {
      & > .MuiAccordionDetails-root {
        padding-top: 0 !important;
        padding-left: 0;
        padding-right: 15px !important;
        text-align: left;

        @media (max-width: ${(props) => props.theme.size.mobileXl}) {
          padding-right: 15px !important;
        }
      }
    }

    &.Mui-expanded {
      margin: 0 !important;
    }
  }
`;

const FaqTitle = styled.span`
  padding: 3px 0;
  font-size: 16px;
  font-weight: bold;
  line-height: 22px;
  color: ${(props) => props.theme.main.primary};
`;

const FaqDesc = styled.span`
  font-size: 14px;
  font-weight: normal;
  line-height: 19px;
  color: ${(props) => props.theme.main.primary};
  margin-top: -10px;
`;
