import { SortByDate } from '.';
import dayjs from 'dayjs';

interface Schedule {
  amount: number;
  attempt: number;
  borrowerId: number;
  createdAt: string;
  dueAt: string;
  id: number;
  loanId: number;
  status: string;
  updatedAt: string;
  doNotAttempt: boolean;
}

interface Commitment {
  amount: number;
  progress: string;
  scheduledDate: string;
  status: string;
}

interface Amortization {
  date: string;
  id: number;
  fee: number;
  interest: number;
  principal: number;
  total: number;
  type: string;
}

const findScheduledPayments = (schedules: Array<Schedule>): Array<Schedule> => {
  return schedules?.filter((item) => item.status === 'SCHEDULED') || [];
};

const sortScheduledPayments = (schedules: Array<Schedule>): Array<Schedule> => {
  return SortByDate(schedules, 'dueAt');
};

export const findNextDueDate = (schedules: Array<Schedule>) => {
  const scheduledPayments = findScheduledPayments(schedules);
  const sortedScheduledPayments = sortScheduledPayments(scheduledPayments);

  if (sortedScheduledPayments.length) {
    return sortedScheduledPayments[0];
  }
  return null;
};

export const findLastDueDate = (schedules: Array<Schedule>) => {
  const sortedScheduledPayments = sortScheduledPayments(schedules);

  if (sortedScheduledPayments.length) {
    return sortedScheduledPayments[sortedScheduledPayments.length - 1];
  }
  return null;
};

export const findNextCommitmentDate = (commitments: Array<Commitment>) => {
  try {
    if (commitments?.length) {
      const nextDuePayment = commitments.find((comm) => {
        const today = dayjs();
        const scheduledDate = dayjs(comm.scheduledDate);
        return scheduledDate >= today && comm.progress === 'PENDING';
      });
      if (nextDuePayment?.scheduledDate) {
        return nextDuePayment;
      }
      return null;
    }
  } catch (err) {
    return null;
  }
};

export const findNextFuturePayment = (amortizations: Array<Amortization>) => {
  try {
    const copyAmortizations = JSON.parse(JSON.stringify(amortizations));
    const futurePaymentList = SortByDate(copyAmortizations, 'date')
      ?.filter((amrtz) => amrtz.type.includes('Scheduled'))
      ?.filter((sch) => new window.Date(sch?.date)?.getTime() > new window.Date()?.getTime());
    const nextDuePayment = futurePaymentList.find((payment) => {
      const today = new Date();
      const scheduledDate = new Date(payment.date);
      return scheduledDate > today;
    });
    if (nextDuePayment?.date) {
      return {
        ...nextDuePayment,
        scheduledDate: nextDuePayment.date,
        amount: nextDuePayment.total,
      };
    }
    return null;
  } catch (err) {
    return null;
  }
};

export const findNextScheduledDate = (nextDue, nextCommitment, isAutoPayActive) => {
  try {
    const today = new Date();
    const nextDueAt = new Date(nextDue?.dueAt);
    if (nextCommitment) {
      return nextCommitment;
    } else if (isAutoPayActive && nextDueAt > today) {
      return {
        ...nextDue,
        scheduledDate: nextDue?.dueAt,
        amount: nextDue?.amount,
      };
    } else {
      return null;
    }
  } catch (err) {
    return null;
  }
};
