import { OperatorEnum } from 'lib/types';

export const fileSearchWithType = (loanId: string) => {
  return [
    {
      key: 'relationType',
      value: 'LOAN',
      operator: OperatorEnum.EQUAL,
    },
    {
      key: 'relationId',
      value: loanId?.toString(),
      operator: OperatorEnum.EQUAL,
    },
    {
      key: 'type',
      value: 'AGREEMENT',
      operator: OperatorEnum.EQUAL,
    },
  ];
};

export const fileSearchForLoan = (loanId: string) => {
  return [
    {
      key: 'relationType',
      value: 'LOAN',
      operator: OperatorEnum.EQUAL,
    },
    {
      key: 'relationId',
      value: loanId,
      operator: OperatorEnum.EQUAL,
    },
    {
      key: 'type',
      value: 'LOAN_STATEMENT',
      operator: OperatorEnum.EQUAL,
    },
  ];
};
