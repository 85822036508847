import { useMutation } from '@apollo/client';
import { useAlert } from 'react-alert';
import { useTranslation } from 'react-i18next';

import { GET_EXCHANGE_TOKEN, SET_EXCHANGE_TOKEN } from 'lib/graphql/mutations';

export const useGetExchangeToken = () => {
  const alert = useAlert();
  const { t } = useTranslation();

  return useMutation(GET_EXCHANGE_TOKEN, {
    fetchPolicy: 'network-only',
    onError: (error) => alert.error(t(`error.${error}`)),
  });
};

export const useSetExchangeToken = () => {
  const alert = useAlert();
  const { t } = useTranslation();

  return useMutation(SET_EXCHANGE_TOKEN, {
    fetchPolicy: 'network-only',
    onError: (error) => alert.error(t(`error.${error}`)),
  });
};
