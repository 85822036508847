import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';
import { memo } from 'react';
import styled from 'styled-components';

interface Props {
  title: string;
}

export const Header = memo(({ title }: Props) => {
  return (
    <Container>
      <IconContainer>
        <AccountBalanceWalletIcon />
      </IconContainer>
      <Title>{title}</Title>
      <StyledBorder />
    </Container>
  );
});

const Container = styled.div`
  margin-top: -10px;
  margin-bottom: 20px;
  display: flex;
  align-items: center;
`;

const Title = styled.div`
  font-family: ${(props) => props.theme.fonts.secondary};
  font-size: 18px;
  color: ${(props) => props.theme.main.primary};
  font-weight: 500;
  line-height: 1.1;
`;

const IconContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 20px;
  width: 40px;
  height: 40px;
  margin-right: 16px;
  background-color: ${(props) => props.theme.main.zebraColor};
  border-radius: 20px;
`;

const StyledBorder = styled.div`
  position: absolute;
  height: 1px;
  margin-top: 80px;
  left: 0;
  right: 0;
  width: 100%;
  background-color: ${(props) => props.theme.main.cardBorderColor};
`;
