import { Container } from 'lib/components';
import useStore from 'lib/hooks/useStore';
import {
  getActiveLoans,
  getAwaitingLoans,
  getChargedOffLoans,
  getClosedLoans,
  getSoldLoans,
} from 'lib/utils/FindLoanTypes';
import { PaymentPlanDetails } from 'pages/Purchases/components/ContractCards/PaymentPlanDetails';
import { AwaitingFundingCard } from 'pages/Purchases/components/ContractCards/AwaitingFundingCard';
import { Loan } from 'pages/Purchases/type';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { OverDueBanner } from 'pages/Purchases/components/OverDueBanner';
import { useLoanList } from 'lib/hooks/useContract';

const Purchase = () => {
  const { id } = useParams<{ id: string }>();
  const [fetchLoanList] = useLoanList('FUNDED,REFUNDED,AWAITING_FUNDING');
  const { loanList, setHeaderTitle } = useStore();
  const [loan, setLoan] = useState<Loan | null>(null);
  const [componentIndex, setComponentIndex] = useState<number | null>(null);

  useEffect(() => {
    setHeaderTitle('purchase.title');
  }, []);

  const chargedOffLoans = getChargedOffLoans(loanList);
  const activeLoans = getActiveLoans(loanList);
  const closedLoans = getClosedLoans(loanList);
  const soldLoans = getSoldLoans(loanList);
  const awaitingLoans = getAwaitingLoans(loanList);

  const loanLists = [chargedOffLoans, activeLoans, closedLoans, soldLoans, awaitingLoans];

  useEffect(() => {
    fetchLoanList();
  }, []);

  useEffect(() => {
    loanLists.some((loanList, index) => {
      const foundLoan = loanList.find((loan) => loan.id === id);
      if (foundLoan) {
        setLoan(foundLoan);
        setComponentIndex(index);
        return true;
      }
      return false;
    });
  }, [id, loanLists]);

  const loanCard = () => {
    switch (componentIndex) {
      case 0:
      case 1:
      case 2:
        return <PaymentPlanDetails loan={loan} />;
      case 3:
        return <PaymentPlanDetails loan={loan} isSold={true} />;
      case 4:
        return <AwaitingFundingCard loan={loan} />;
      default:
        return;
    }
  };

  return (
    loan && (
      <>
        <OverDueBanner loan={loan} id={id as string} />
        <Container>{loanCard()}</Container>
      </>
    )
  );
};

export default Purchase;
