import styled from 'styled-components';
import GenericFileModal from './GenericFile';
import { useTranslation } from 'react-i18next';

interface RemoveFileModalProps {
  onCloseModal: () => void;
  onRemove: (index: number) => void;
  fileIndex: number;
  open: boolean;
}

const RemoveFileModal = ({ onCloseModal, onRemove, fileIndex, open }: RemoveFileModalProps) => {
  const { t: translate } = useTranslation();

  const removeFile = () => {
    onRemove(fileIndex);
  };

  return (
    <>
      <GenericFileModal
        open={open}
        title={translate('documentRequest.remove.title')}
        handleClose={onCloseModal}
        buttonLeftText={translate('documentRequest.remove.no')}
        onButtonLeftClick={onCloseModal}
        mainButtonText={translate('documentRequest.remove.yes')}
        onMainButtonClick={removeFile}
      >
        <Text>{translate('documentRequest.remove.sureText')}</Text>
        <Text>{translate('documentRequest.remove.undoneText')}</Text>
      </GenericFileModal>
    </>
  );
};

const Text = styled.div`
  color: ${(props) => props.theme.main.midnightBlue};
  text-align: center;
  font-size: 14px;
  font-weight: 400;
`;

export default RemoveFileModal;
