import styled from 'styled-components';
import { useDisputeModal, useDueDateModal, useHelpModal } from 'lib/hooks/useModal';
import { Loan } from 'pages/Purchases/type';
import { isArray } from 'lib/utils';
import { useNavigate } from 'react-router-dom';
import { SegmentEventNames, useSegment } from 'lib/hooks/useSegment';
import { MenuTile } from './MenuTile';
import { useTranslation } from 'react-i18next';
import { ReactComponent as CalendarIcon } from 'assets/images/calendar.svg';
import { ReactComponent as ContractLogo } from 'assets/images/contract.svg';
import { ReactComponent as CreditCardLogo } from 'assets/images/credit-card.svg';
import { ReactComponent as CalculatorLogo } from 'assets/images/calculator.svg';
import { ReactComponent as MessageIcon } from 'assets/images/message-close.svg';
import { ReactComponent as HelpIcon } from 'assets/images/help_circle_outline.svg';
import { SEGMENT_EVENT_NAMES } from 'lib/constans';
import { getSegmentEventDetailsData } from 'lib/utils/SegmentEventData';
import useStore from 'lib/hooks/useStore';

interface Props {
  loan: Loan;
  id: string;
  contractModal;
}

export const Menu = ({ loan, id, contractModal }: Props) => {
  const futureSchedules = loan?.schedules?.filter(
    (schedule) => schedule.status !== 'PAID' && schedule?.status !== 'PROCESSING',
  );
  const loanStatus = isArray(loan.plans)[0]?.status;
  const nextDueDate = futureSchedules?.[0]?.dueAt;
  const { trackSegmentEvent } = useSegment();
  const disputeModal = useDisputeModal(loan);
  const helpModal = useHelpModal();
  const dueDateModal = useDueDateModal(loan, helpModal.openHelpModal);
  const { t: translate } = useTranslation();
  const isCalculatePayoffButtonVisible = loan?.id && nextDueDate && (loanStatus === 'OPEN' || loanStatus === 'LATE');
  const { borrower } = useStore();
  const segmentEventPaymentPlanDetailsData = getSegmentEventDetailsData(borrower, loan);

  const isModifyDueDateVisible =
    isArray(loan?.plans)[0]?.subStatus === 'PERFORMING' && loan?.servicing?.dueDateChangeCount === 0;
  const navigate = useNavigate();

  const autoPayHandler = () => {
    trackSegmentEvent(
      SEGMENT_EVENT_NAMES.PATIENT_PORTAL.PAYMENT_PLAN_DETAILS.SET_PAYMENT_METHOD_QUICK_ACTION_CLICKED,
      segmentEventPaymentPlanDetailsData,
    );
    navigate(`/portal/${id}/autopay`);
  };

  const modifyDueDateHandler = () => {
    trackSegmentEvent(SegmentEventNames.DUE_DATE_CHANGE, { loan_id: loan?.id, action_type: 'Change initiated' });
    trackSegmentEvent(
      SEGMENT_EVENT_NAMES.PATIENT_PORTAL.PAYMENT_PLAN_DETAILS.CHANGE_DUE_DATE_QUICK_ACTION_CLICKED,
      segmentEventPaymentPlanDetailsData,
    );
    navigate(`/portal/modify-due-date`, { state: { loanId: loan?.id } });
  };

  const goToCalculatePayoff = () => {
    trackSegmentEvent(
      SEGMENT_EVENT_NAMES.PATIENT_PORTAL.PAYMENT_PLAN_DETAILS.CALCULATE_PAYOFF_QUICK_ACTION_CLICKED,
      segmentEventPaymentPlanDetailsData,
    );
    navigate(`/portal/${id}/calculate-payoff`);
  };

  const openDownloadContractModal = () => {
    contractModal.openContractModal();
    trackSegmentEvent(
      SEGMENT_EVENT_NAMES.PATIENT_PORTAL.PAYMENT_PLAN_DETAILS.DOWNLOAD_CONTRACT_QUICK_ACTION_CLICKED,
      segmentEventPaymentPlanDetailsData,
    );
  };

  const openDisputeModal = () => {
    trackSegmentEvent(
      SEGMENT_EVENT_NAMES.PATIENT_PORTAL.PAYMENT_PLAN_DETAILS.FILE_DISPUTE_QUICK_ACTION_CLICKED,
      segmentEventPaymentPlanDetailsData,
    );
    disputeModal.openDisputeModal();
  };

  const getHelp = () => {
    trackSegmentEvent(
      SEGMENT_EVENT_NAMES.PATIENT_PORTAL.PAYMENT_PLAN_DETAILS.GET_HELP_QUICK_ACTION_CLICKED,
      segmentEventPaymentPlanDetailsData,
    );
    helpModal.openHelpModal();
  };

  return (
    <>
      <MenuContainer>
        <MenuTile
          onClick={openDownloadContractModal}
          src={ContractLogo}
          text={translate('contractModal.downloadContract')}
        />
        <MenuTile onClick={autoPayHandler} src={CreditCardLogo} text={translate('paymentAuto.setPaymentMethod')} />
        <MenuTile onClick={openDisputeModal} src={MessageIcon} text={translate('fileADispute.title')} />
      </MenuContainer>
      <MenuContainer twoColumns={!isCalculatePayoffButtonVisible}>
        <MenuTile
          disabled={loan?.schedules?.length === 0}
          onClick={isModifyDueDateVisible ? modifyDueDateHandler : dueDateModal.openDueDateModal}
          src={CalendarIcon}
          text={translate('autopay.modifyDueDate')}
        />
        {isCalculatePayoffButtonVisible && (
          <MenuTile onClick={goToCalculatePayoff} src={CalculatorLogo} text={translate('calculatePayoff.name')} />
        )}

        <MenuTile onClick={getHelp} src={HelpIcon} text={translate('faq.support.getHelp')} />
      </MenuContainer>
      {dueDateModal.dueDateModalVisibility && dueDateModal.ModalBody}
      {disputeModal.disputeModalVisibility && disputeModal.ModalBody}
      {helpModal.helpModalVisibility && helpModal.ModalBody}
    </>
  );
};

const MenuContainer = styled.div<{ twoColumns?: boolean }>`
  display: grid;
  grid-template-columns: repeat(${(props) => (props.twoColumns ? 2 : 3)}, 1fr);
  gap: 8px;
  margin-top: 8px;
  width: 100%;
`;
