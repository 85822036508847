import { memo } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

interface PROPS {
  children: any;
  to: any;
}

export const RedirectLink = memo(({ children, to }: PROPS) => {
  return <StyledRedirectLink to={to}>{children}</StyledRedirectLink>;
});

const StyledRedirectLink = styled(Link)`
  cursor: pointer;
  text-decoration: underline;
  color: ${(props) => props.theme.main.linkColor};
`;
