import * as Sentry from '@sentry/react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import useStore from 'lib/hooks/useStore';

interface Props {
  paymentMethod: any;
  selectedPaymentMethod: any;
  selectPaymentMethod: (id: number) => void;
  isPreferred: boolean;
  isSettlementFlow?: boolean;
}

interface IconName {
  type: string;
  name: string;
}

const Icons: Array<IconName> = [
  { type: 'CARD', name: '/credit_card.svg' },
  { type: 'ACH', name: '/bank.svg' },
  { type: 'RCC', name: '/remote-check.svg' },
];

enum TypeEnum {
  ACH = 'ACH',
  CARD = 'CARD',
}

const PaymentMethodCard = ({
  paymentMethod,
  selectedPaymentMethod,
  selectPaymentMethod,
  isPreferred,
  isSettlementFlow = false,
}: Props) => {
  const { t: translate } = useTranslation();
  const { paymentMethodFee } = useStore();
  const { id, type, achAccount, rccAccount, storedCard } = paymentMethod || {};

  const accountKey = type === TypeEnum.ACH ? achAccount : rccAccount;
  const cardTitle =
    type === TypeEnum.CARD
      ? `${storedCard?.network.toLowerCase()} - ${storedCard?.type.toLowerCase()}`
      : `${accountKey?.bankName}`;

  const IconNames = () => {
    const findIcon: IconName | undefined = Icons.find((ic) => ic?.type === type);
    return findIcon?.name || '';
  };

  const cardDescription = () => {
    if (type === TypeEnum.CARD) {
      return {
        __html: translate('account.payment.paymentItem.cardTitle', {
          last4: paymentMethod?.storedCard?.last4,
          expireMonth: paymentMethod?.storedCard?.expireMonth,
          expireYear: paymentMethod?.storedCard?.expireYear,
        })?.replace('-', '<br />'),
      };
    } else {
      return {
        __html: translate(`account.payment.paymentItem.achTitle`, {
          last4: accountKey?.accountNumber?.substring(accountKey?.accountNumber?.length - 4),
        }),
      };
    }
  };

  const selectPaymentMethodHandler = () => {
    selectPaymentMethod(paymentMethod);
  };

  const calculateProcessingFee = (methodType) => {
    try {
      return paymentMethodFee?.[methodType]?.percent;
    } catch (err) {
      Sentry.captureMessage(`Calculate Processing Fee`, 'error');
    }
  };

  const isMethodSelected = Number(selectedPaymentMethod?.id) === Number(id);

  return (
    <Card
      key={`${type}_${id}`}
      onClick={selectPaymentMethodHandler}
      checked={isMethodSelected}
      data-testid="payment-method-card"
    >
      <RadioTextContainer>
        <DebitCardLogo src={IconNames()} checked={isMethodSelected} />
        <CardTitleContainer>
          <CartTitle checked={isMethodSelected}>{cardTitle}</CartTitle>
          <CartDescription checked={isMethodSelected} dangerouslySetInnerHTML={cardDescription()} />
        </CardTitleContainer>
        {isPreferred && <PreferredContainer>{translate('paymentMethod.preferred')}</PreferredContainer>}
      </RadioTextContainer>
      {type === TypeEnum.CARD &&
        storedCard.type !== 'DEBIT' &&
        isMethodSelected &&
        !isSettlementFlow &&
        calculateProcessingFee(paymentMethod?.storedCard?.type) > 0 && (
          <InfoContainer>
            <InfoLogo src={'/info.svg'} />
            <InfoText
              dangerouslySetInnerHTML={{
                __html: translate('account.payment.paymentItem.feeInfo', {
                  fee: calculateProcessingFee(paymentMethod?.storedCard?.type),
                }),
              }}
            />
          </InfoContainer>
        )}
    </Card>
  );
};

export default PaymentMethodCard;

const PreferredContainer = styled.div`
  font-size: 10px;
  line-height: 10.89px;
  color: #00c37d;
  background: #e6f9f2;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  padding: 4px 6px 5px;
  gap: 4px;
  border-radius: 16px;
`;

const Card = styled.div<{ checked: boolean; selectedPaymentMethodId?: any }>`
  cursor: pointer;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  border: ${(props) =>
    props.checked ? `2px solid ${props.theme.main.green}` : `2px solid ${props.theme.main.cardBorderColor}`};
  background-color: white;
  opacity: ${(props) => (props.checked || props.selectedPaymentMethodId !== '' ? 1 : 0.7)};
  border-radius: 12px;
  padding: 24px 26px;
  width: 100%;
  margin-bottom: 24px;
`;

const RadioTextContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: 14px;
  font-weight: 700;
  font-family: ${(props) => props.theme.fonts.primary};

  & > span {
    width: 100%;
  }

  & > label {
    margin-top: 5px;
    color: ${(props) => props.theme.main.loanColor};
  }
`;

const DebitCardLogo = styled.img<{ checked: boolean }>`
  height: 24px;
  margin-right: 18px;
  filter: ${(props) =>
    !props.checked && 'invert(55%) sepia(15%) saturate(204%) hue-rotate(166deg) brightness(99%) contrast(91%)'};
`;

const CardTitleContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

const CartTitle = styled.div<{ checked: boolean }>`
  font-size: 16px;
  line-height: 22px;
  font-weight: bold;
  color: ${(props) => props.theme.main?.[props.checked ? 'black' : 'grayColor']};
  text-transform: capitalize;
`;

const CartDescription = styled(CartTitle)<{ checked: boolean }>`
  font-weight: 400;
  color: ${(props) => props.theme.main?.[props.checked ? 'black' : 'grayColor']};
`;

const InfoContainer = styled.div`
  margin-top: 8px;
  display: flex;
  align-items: center;
`;

const InfoLogo = styled.img`
  width: 13px;
  height: 13px;
  margin-right: 13px;
  padding-top: 3px;
`;

const InfoText = styled.span`
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: #0e202f;
`;
