import React from 'react';
import styled from 'styled-components';
import { Subtitle } from 'lib/components';

type RadioContentProps = {
  title?: string;
  content?: string;
  badge?: string;
  badgePosition?: 'top' | 'right';
  selected: boolean;
};

export const RadioContent = ({ title, content, badge, badgePosition = 'right', selected }: RadioContentProps) => {
  return (
    <Container>
      <Wrapper>
        {badge && badgePosition === 'top' && <Badge selected={selected}>{badge}</Badge>}
        {title && <Title>{title}</Title>}
        {content && <Text>{content}</Text>}
      </Wrapper>
      {badge && badgePosition === 'right' && <Badge selected={selected}>{badge}</Badge>}
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
`;

const Wrapper = styled.div``;

const Title = styled(Subtitle)`
  font-size: 16px;
  font-weight: 700;
  color: ${(props) => props.theme.main.primary};
`;

const Text = styled.p`
  margin: 0;
`;

const Badge = styled.div<{ selected: boolean }>`
  min-width: 92px;
  padding: 6px;
  border-radius: 16px;
  background-color: ${(props) => (props.selected ? props.theme.main.kellyGreen20 : props.theme.main.preferredColor)};
  text-align: center;
  width: fit-content;

  color: ${(props) => props.theme.main.green};
  font-size: 12px;
  font-weight: 400;
  letter-spacing: 0.4px;
  text-transform: uppercase;
`;
