import { Loading } from 'lib/components';
import React, { useEffect } from 'react';

const FindProvider = () => {
  useEffect(() => {
    // call http request then create new magic link and navigate to merchant finder project
  }, []);
  return <Loading />;
};

export default FindProvider;
