import Fade from '@mui/material/Fade';
import React from 'react';
import styled from 'styled-components';

import { MembershipHeader } from 'lib/components';
import useStore from 'lib/hooks/useStore';
import { useLocation } from 'react-router-dom';

interface Props {
  children?: React.ReactNode;
  type?: string;
  backUrl?: string;
  showBackButton?: boolean;
  goBackCallback?: () => void;
  isPhoneUpdate?: boolean;
}

export const MembershipContainer = ({
  children,
  type,
  backUrl = '',
  showBackButton = true,
  goBackCallback,
  isPhoneUpdate = false,
}: Props) => {
  const location = useLocation();
  const { pageIndex } = useStore();

  return (
    <Fade in={true} timeout={750}>
      <Main>
        <InnerContainer type={type}>
          <MembershipHeader
            pathname={location.pathname}
            pageIndex={pageIndex}
            showBackButton={showBackButton}
            backUrl={backUrl}
            goBackCallback={goBackCallback}
            isPhoneUpdate={isPhoneUpdate}
          />
          <ChildrenContainer type={type}>{children}</ChildrenContainer>
        </InnerContainer>
      </Main>
    </Fade>
  );
};

const Main = styled.div`
  display: flex;
  flex: 1;
  overflow: auto;
  justify-content: center;
  text-align: center;
  background-color: #f0f2f4;
  position: fixed;
  height: 100%;
  width: 100%;
`;

const InnerContainer = styled.div<{ type?: string }>`
  background-color: white;
  box-shadow: 0 6px 9px rgba(0, 0, 0, 0.07), 0px 0.751293px 1.12694px rgba(0, 0, 0, 0.035);
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  margin-top: 50px;
  width: 600px;
  min-height: 625px;
  height: fit-content;
  text-align: left;
  margin-bottom: 50px;

  @media (max-width: ${(props) => props.theme.size.mobileXl}) {
    margin-top: 0px;
    margin-bottom: 0px;
    min-height: ${(props) => (props.type === 'agreement' ? 'none' : '100%')};
    width: 100%;
  }
`;

const ChildrenContainer = styled.div<{ type?: string }>`
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: space-between;

  @media (max-width: ${(props) => props.theme.size.mobileXl}) {
    height: ${(props) => (props.type === 'agreement' ? 'auto' : 'calc((100% - 110px))')};
  }
`;
