import { gql } from '@apollo/client/core';

export const PATCH_MERCHANT_PREFERENCE = gql`
  mutation patchMerchantPreference($input: PatchMerchantPreferenceRequest) {
    patchMerchantPreference(input: $input) {
      id
      merchantId
    }
  }
`;
