import { Radio } from '@mui/material';

import { theme } from 'config/theme';

interface Props {
  checked?: boolean;
  value?: string | number;
  name?: string;
  disabled?: boolean;
}

export const RadioButton = ({
  checked = false,
  value,
  name = 'payment-type',
  disabled = false,
}: Props): React.ReactElement => {
  return (
    <Radio
      checked={checked}
      disabled={disabled}
      value={value}
      size={'small'}
      name={name}
      sx={{
        '&.Mui-checked': {
          color: theme.main.green,
        },
      }}
    />
  );
};
