import { gql } from '@apollo/client/core';

export const PATCH_EXTEND_APPROVAL = gql`
  mutation patchExtendApproval($input: PatchExtendApprovalInput) {
    patchExtendApproval(input: $input) {
      id
      applicationId
      appointmentDate
      reminder
      error
      message
    }
  }
`;

export const PATCH_EXTEND_APPROVAL_HISTORY = gql`
  mutation patchExtendApprovalHistory($input: PatchExtendApprovalHistoryInput) {
    patchExtendApprovalHistory(input: $input) {
      id
      applicationId
      error
      message
    }
  }
`;
