import { gql } from '@apollo/client/core';

export const SELF_UPDATE_BORROWER = gql`
  mutation selfUpdateBorrower($input: SelfUpdateBorrowerInput) {
    selfUpdateBorrower(input: $input) {
      id
      authorization {
        token
        refreshToken
      }
    }
  }
`;

export const SELF_UPDATE_OTP = gql`
  mutation selfUpdateOtp($input: SelfUpdateOtpInput) {
    selfUpdateOtp(input: $input) {
      success
    }
  }
`;
export const REFRESH_SELF_UPDATE = gql`
  mutation refreshSelfUpdateBorrower($input: RefreshSelfUpdateInput) {
    refreshSelfUpdateBorrower(input: $input) {
      id
      authorization {
        token
        refreshToken
      }
    }
  }
`;
export const SELF_UPDATE_VERIFY_OTP = gql`
  mutation selfUpdateVerifyOtp($input: SelfUpdateVerifyOtpInput) {
    selfUpdateVerifyOtp(input: $input) {
      success
    }
  }
`;

export const SELF_UPDATE_IDENTITY_INITIALIZE = gql`
  mutation selfUpdateIdentityInitialize($input: SelfUpdateIdentityInput) {
    selfUpdateIdentityInitialize(input: $input) {
      templateId
      referenceId
    }
  }
`;

export const SELF_UPDATE_IDENTITY_FINALIZE = gql`
  mutation selfUpdateIdentityFinalize($input: SelfUpdateIdentityInput) {
    selfUpdateIdentityFinalize(input: $input) {
      success
    }
  }
`;

export const SELF_UPDATE_STATUS = gql`
  query selfUpdateStatus($input: SelfUpdateIdentityInput) {
    selfUpdateStatus(input: $input) {
      status
      newPhone
      id
    }
  }
`;
