import { gql } from '@apollo/client/core';

export const ADD_PAYMENT_METHOD = gql`
  mutation addPaymentMethod($input: AddPaymentMethodInput) {
    addPaymentMethod(input: $input) {
      firstName
      lastName
      zip
      status
      type
      storedCard {
        network
        type
        last4
        expireMonth
        expireYear
        status
        id
        createdAt
      }
      achAccount
      id
      createdAt
    }
  }
`;

export const DELETE_PAYMENT_METHOD = gql`
  mutation deletePaymentMethod($input: DeletePaymentMethodInput) {
    deletePaymentMethod(input: $input) {
      success
      message
      borrowerId
    }
  }
`;

export const PATCH_LOAN_METHOD = gql`
  mutation patchLoan($input: PatchLoanRequest) {
    patchLoan(input: $input) {
      amount
      id
      merchant {
        name
      }
      status
    }
  }
`;

export const MAKE_PAYMENT = gql`
  mutation makePayment($input: PaymentsInput) {
    makePayment(input: $input) {
      amount
      createdAt
      id
      loanId
      status
      type
      threeD
      message
      fee
    }
  }
`;

export const CALCULATE_PAYOFF_ESTIMATION = gql`
  mutation calculatePayoffEstimation($input: CalculatePayoffEstimationRequest) {
    calculatePayoffEstimation(input: $input) {
      fileId
      success
    }
  }
`;
