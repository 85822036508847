import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import styled from 'styled-components';

import { Container, Loading, Stepper } from 'lib/components';
import { paymentSearch } from 'lib/graphql/searches';
import { AnalyticEventNames, useAnalytics } from 'lib/hooks/useAnalytics';
import { useLoanList } from 'lib/hooks/useContract';
import { useGetPaymentMethods, usePaymentMethodFee } from 'lib/hooks/usePayment';
import { SegmentEventNames, useSegment } from 'lib/hooks/useSegment';
import useStore from 'lib/hooks/useStore';
import { getPaymentMethodsByType } from 'lib/utils';

import { HeaderTitle } from '../components';
import PaymentMethodCard from './components/PaymentMethodCard';
import { AddNewPaymentMethod } from 'pages/Account/Payment/components';
import { SEGMENT_EVENT_NAMES, SETTLEMENT_FLOW_STEPPER_CONFIG } from 'lib/constans';
import { SettlementPaymentTypes } from 'lib/types';
import { Button } from '@frontend/cherry-library';
import { getSegmentEventDetailsData } from 'lib/utils/SegmentEventData';

enum TypeEnum {
  CHECKING = 'CHECKING',
  SAVINGS = 'SAVINGS',
  RCC = 'RCC',
  DEBIT = 'DEBIT',
  CREDIT = 'CREDIT',
  CARD = 'CARD',
  ACH = 'ACH',
}

const PaymentMethod = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { id } = useParams();
  const { t: translate } = useTranslation();
  const { trackEvent } = useAnalytics();
  const { trackSegmentEvent, trackPage } = useSegment();
  const { borrower, loanList, paymentMethods, paymentMethod, setPaymentMethod, getActiveLoan, setHeaderTitle } =
    useStore();
  const [fetchLoanList, { loading }] = useLoanList('FUNDED');
  const [getPaymentMethods, { loading: paymentLoading }] = useGetPaymentMethods();
  const [fetchPaymentMethodFee] = usePaymentMethodFee();
  const [newPaymentMethod, setNewPaymentMethod] = useState(false);

  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState<any>({});
  const [newlyAdded, setNewlyAdded] = useState<any>({});
  const [isPreferredType, setIsPreferredType] = useState('');
  const activeLoan = getActiveLoan(id);
  const segmentEventPaymentPlanDetailsData = getSegmentEventDetailsData(borrower, activeLoan);

  const {
    isSettlementFlow,
    amount: settlementAmount,
    paymentDate: settlementPaymentDate,
    installmentCount: settlementInstallmentCount,
    frequency: settlementFrequency,
    type: settlementType,
  } = (location?.state as never) || {};

  useEffect(() => {
    fetchPaymentMethodFee();
  }, []);

  useEffect(() => {
    setHeaderTitle('makePayment.title');
    trackPage(
      SEGMENT_EVENT_NAMES.PATIENT_PORTAL.MAKE_A_PAYMENT_SELECT_PAYMENT_METHOD.PAGE_LOAD,
      segmentEventPaymentPlanDetailsData,
    );
  }, [setHeaderTitle]);

  useEffect(() => {
    if (loanList?.length === 0) {
      fetchLoanList();
    }
  }, [loanList]);

  useEffect(() => {
    if (!(paymentMethods && paymentMethods.length > 0)) {
      getPaymentMethods({
        variables: {
          input: {
            borrowerId: borrower?.id?.toString(),
            search: paymentSearch,
          },
        },
      });
    }

    if (paymentMethods?.length > 0) {
      selectPreferredPaymentMethod();
    }
  }, [paymentMethods, newlyAdded]);

  const selectPreferredPaymentMethod = () => {
    let preferredPaymentMethod = {};

    if (getPaymentMethodsByType(paymentMethods, TypeEnum.CHECKING)?.length > 0) {
      const checkingAchMethod = getPaymentMethodsByType(paymentMethods, TypeEnum.CHECKING)?.sort((a, b) => b.id - a.id);
      preferredPaymentMethod = checkingAchMethod?.[0];
      setIsPreferredType('checking');
    } else if (getPaymentMethodsByType(paymentMethods, TypeEnum.DEBIT)?.length > 0) {
      const debitCardMethod = getPaymentMethodsByType(paymentMethods, TypeEnum.DEBIT)?.sort((a, b) => b.id - a.id);
      preferredPaymentMethod = debitCardMethod?.[0];
      setIsPreferredType('debit');
    } else if (getPaymentMethodsByType(paymentMethods, TypeEnum.SAVINGS)?.length > 0) {
      const savingAchMethod = getPaymentMethodsByType(paymentMethods, TypeEnum.SAVINGS)?.sort((a, b) => b.id - a.id);
      preferredPaymentMethod = savingAchMethod?.[0];
      setIsPreferredType('savings');
    } else {
      const creditCardMethod = getPaymentMethodsByType(paymentMethods, TypeEnum.CREDIT)?.sort((a, b) => b.id - a.id);
      preferredPaymentMethod = creditCardMethod?.[0];
      setIsPreferredType('credit');
    }
    if (newlyAdded?.id) {
      setSelectedPaymentMethod(newlyAdded);
    } else if (paymentMethod?.id) {
      setSelectedPaymentMethod(paymentMethod);
    } else {
      setSelectedPaymentMethod(preferredPaymentMethod);
    }
  };

  const calculateLast4 = (method): string => {
    let last4 = '';
    if (method?.type === TypeEnum.CARD) {
      last4 = method?.storedCard?.last4;
    }
    if (method?.type === TypeEnum.ACH) {
      last4 = method?.achAccount?.accountNumber.slice(-4);
    }
    if (method?.type === TypeEnum.RCC) {
      last4 = method?.rccAccount?.accountNumber.slice(-4);
    }
    return last4;
  };

  const selectPaymentMethodHandler = (method) => {
    const last4 = calculateLast4(method);

    trackSegmentEvent(SEGMENT_EVENT_NAMES.PATIENT_PORTAL.MAKE_A_PAYMENT_SELECT_PAYMENT_METHOD.PAYMENT_METHOD_SELECTED, {
      ...segmentEventPaymentPlanDetailsData,
      paymentMethodId: method?.id,
      paymentMethodType: method?.type,
      last4,
    });
    setSelectedPaymentMethod(method);
  };

  const goBackHandler = () => {
    trackSegmentEvent(
      SEGMENT_EVENT_NAMES.PATIENT_PORTAL.MAKE_A_PAYMENT_SELECT_PAYMENT_METHOD.BACK_CLICKED,
      segmentEventPaymentPlanDetailsData,
    );
    if (isSettlementFlow) {
      if (settlementType === SettlementPaymentTypes.ONE_TIME_PAYMENT) {
        trackSegmentEvent(SEGMENT_EVENT_NAMES.PATIENT_PORTAL.ONE_TIME_PAYMENT.BACK_TO_REPAYMENT_DETAILS_CLICKED);
      } else if (settlementType === SettlementPaymentTypes.PaymentPlan) {
        trackSegmentEvent(SEGMENT_EVENT_NAMES.PATIENT_PORTAL.PAYMENT_PLAN.BACK_TO_REPAYMENT_DETAILS_CLICKED);
      } else if (settlementType === SettlementPaymentTypes.SettlementOffer) {
        trackSegmentEvent(SEGMENT_EVENT_NAMES.PATIENT_PORTAL.SETTLEMENT.BACK_TO_REPAYMENT_DETAILS_CLICKED);
      }
    } else {
      trackSegmentEvent(SegmentEventNames.PAYMENT_BACK, { loan_id: activeLoan.id, screen: 'Payment Method' });
    }
    if (window.history.state.idx === 1) {
      navigate(`/portal/home`);
    } else {
      navigate(-1);
    }
  };

  const handlePayment = () => {
    trackEvent(AnalyticEventNames.PM_ADD_PM);
    trackEvent(AnalyticEventNames.PM_TYPE_SELECT, selectedPaymentMethod.type);
    trackSegmentEvent(
      SEGMENT_EVENT_NAMES.PATIENT_PORTAL.MAKE_A_PAYMENT_SELECT_PAYMENT_METHOD.CONTINUE_CLICKED,
      segmentEventPaymentPlanDetailsData,
    );

    setPaymentMethod(selectedPaymentMethod);

    if (isSettlementFlow) {
      if (settlementType === SettlementPaymentTypes.ONE_TIME_PAYMENT) {
        trackSegmentEvent(SEGMENT_EVENT_NAMES.PATIENT_PORTAL.ONE_TIME_PAYMENT.CONTINUE_TO_SUMMARY_CLICKED);
      } else if (settlementType === SettlementPaymentTypes.PaymentPlan) {
        trackSegmentEvent(SEGMENT_EVENT_NAMES.PATIENT_PORTAL.PAYMENT_PLAN.CONTINUE_TO_SUMMARY_CLICKED);
      } else if (settlementType === SettlementPaymentTypes.SettlementOffer) {
        trackSegmentEvent(SEGMENT_EVENT_NAMES.PATIENT_PORTAL.SETTLEMENT.CONTINUE_TO_SUMMARY_CLICKED);
      }

      navigate(`/portal/${activeLoan?.id}/payment-review`, {
        state: {
          isSettlementFlow,
          amount: settlementAmount,
          paymentDate: settlementPaymentDate,
          installmentCount: settlementInstallmentCount,
          frequency: settlementFrequency,
          type: settlementType,
        },
      });
    } else {
      trackSegmentEvent(SegmentEventNames.PAYMENT_METHOD_SELECTED, {
        loan_id: activeLoan.id,
        paymentMethodId: selectedPaymentMethod.id,
      });
      navigate(`/portal/${activeLoan?.id}/payment-type`);
    }
  };

  if (loading || paymentLoading) {
    return <Loading transparent={true} />;
  }
  const addPaymentMethod = async (data = {}) => {
    setNewlyAdded(data);
    setNewPaymentMethod(false);
  };
  const handleClose = () => {
    setNewPaymentMethod(false);
  };

  const addNewPayment = () => {
    trackSegmentEvent(
      SEGMENT_EVENT_NAMES.PATIENT_PORTAL.MAKE_A_PAYMENT_SELECT_PAYMENT_METHOD.ADD_NEW_PAYMENT_METHOD_CLICKED,
      segmentEventPaymentPlanDetailsData,
    );
    setNewPaymentMethod(true);
  };

  return (
    <Container block={true}>
      <PaymentSelection>
        {isSettlementFlow && (
          <Stepper
            totalStepCount={SETTLEMENT_FLOW_STEPPER_CONFIG.TOTAL_PAGES}
            activeStep={SETTLEMENT_FLOW_STEPPER_CONFIG.ACTIVE_PAGE.PAYMENT_METHOD}
          />
        )}
        {!newPaymentMethod ? (
          <>
            <HeaderTitle title={translate(`makePayment.paymentMethod.info`)} />

            {getPaymentMethodsByType(paymentMethods, TypeEnum.CHECKING)?.map((checkingMethod, index) => (
              <PaymentMethodCard
                isSettlementFlow={isSettlementFlow}
                key={`CHECKING-ACH-${index}-${checkingMethod?.id}`}
                paymentMethod={checkingMethod}
                selectPaymentMethod={selectPaymentMethodHandler}
                selectedPaymentMethod={selectedPaymentMethod}
                isPreferred={isPreferredType === 'checking'}
              />
            ))}

            {getPaymentMethodsByType(paymentMethods, TypeEnum.DEBIT)?.map((debitMethod, index) => (
              <PaymentMethodCard
                isSettlementFlow={isSettlementFlow}
                key={`DEBIT-CARD-${index}-${debitMethod?.id}`}
                paymentMethod={debitMethod}
                selectPaymentMethod={selectPaymentMethodHandler}
                selectedPaymentMethod={selectedPaymentMethod}
                isPreferred={isPreferredType === 'debit'}
              />
            ))}

            {getPaymentMethodsByType(paymentMethods, TypeEnum.SAVINGS)?.map((savingsMethod, index) => (
              <PaymentMethodCard
                isSettlementFlow={isSettlementFlow}
                key={`SAVING-ACH-${index}-${savingsMethod?.id}`}
                paymentMethod={savingsMethod}
                selectPaymentMethod={selectPaymentMethodHandler}
                selectedPaymentMethod={selectedPaymentMethod}
                isPreferred={isPreferredType === 'savings'}
              />
            ))}

            {getPaymentMethodsByType(paymentMethods, TypeEnum.RCC)?.map((rccMethod, index) => (
              <PaymentMethodCard
                isSettlementFlow={isSettlementFlow}
                key={`RCC-${index}-${rccMethod?.id}`}
                paymentMethod={rccMethod}
                selectPaymentMethod={selectPaymentMethodHandler}
                selectedPaymentMethod={selectedPaymentMethod}
                isPreferred={false}
              />
            ))}

            {getPaymentMethodsByType(paymentMethods, TypeEnum.CREDIT)?.map((creditMethod, index) => (
              <PaymentMethodCard
                isSettlementFlow={isSettlementFlow}
                key={`RCC-${index}-${creditMethod.id}`}
                paymentMethod={creditMethod}
                selectPaymentMethod={selectPaymentMethodHandler}
                selectedPaymentMethod={selectedPaymentMethod}
                isPreferred={false}
              />
            ))}

            <AddNewPayment onClick={addNewPayment}>{translate(`account.payment.form.info`)}</AddNewPayment>

            <ButtonContainer>
              <Button fullWidth variant="secondary" onClick={goBackHandler}>
                {translate('makePayment.backButton')}
              </Button>
              <Button fullWidth disabled={!selectedPaymentMethod?.id} onClick={handlePayment}>
                {translate('makePayment.paymentType.submitButton')}
              </Button>
            </ButtonContainer>
          </>
        ) : (
          <AddNewPaymentMethod
            borrower={borrower}
            handleClose={handleClose}
            addPaymentMethod={addPaymentMethod}
            connectBankAccount={addPaymentMethod}
            addDirectly={true}
          />
        )}

        {!paymentMethods && <Loading transparent={true} />}
      </PaymentSelection>
    </Container>
  );
};

const PaymentSelection = styled.div`
  display: flex;
  flex-direction: column;
  border-bottom: 1px solid ${(props) => props.theme.main.cardBorderColor};
  padding: 24px;
  background: #ffffff;
  box-shadow: 0px 6px 9px rgba(0, 0, 0, 0.07), 0px 0.751293px 1.12694px rgba(0, 0, 0, 0.035);
  border-radius: 8px;
`;

const AddNewPayment = styled.div`
  color: ${(props) => props.theme.main.green};
  text-decoration: underline;
  text-align: center;
  font-size: 16px;
  font-weight: 600;
  line-height: 21px;
  margin-top: 9px;
  cursor: pointer;
`;
const ButtonContainer = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin-top: 33px;
  button {
    &:nth-child(1) {
      margin-right: 15px;
    }
  }
  @media (max-width: ${(props) => props.theme.size.tablet}) {
    flex-direction: column-reverse;
    button {
      &:nth-child(1) {
        margin-right: 0px;
      }
      &:nth-child(2) {
        margin-bottom: 15px;
      }
    }
  }
`;

const ChargeOffBannerContainer = styled.div`
  margin-bottom: 24px;
`;

export default PaymentMethod;
