import { useEffect, useState } from 'react';
import { useApolloClient } from '@apollo/client';
import { useAlert } from 'react-alert';
import Rox from 'rox-browser';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { Container, Loading } from 'lib/components';
import { ExchangeTokenType } from 'lib/constans';
import { approvalPagination } from 'lib/graphql/paginations';
import { GET_APPLICATION_LIST, GET_BORROWER_DETAIL } from 'lib/graphql/queries';
import { approvalSearch } from 'lib/graphql/searches';
import { useGetExchangeToken } from 'lib/hooks/useExchangeToken';
import useLandingPage from 'lib/hooks/useLandingPage';
import { useSentry } from 'lib/hooks/useSentry';
import useStore from 'lib/hooks/useStore';
import { useTranslation } from 'react-i18next';
import { useGetBorrowerDetail } from 'lib/hooks/useUser';
import StorageService from 'lib/services/Storage';

const Oauth = () => {
  const alert = useAlert();
  const client = useApolloClient();
  const navigate = useNavigate();
  const location = useLocation();
  const { id } = useParams();
  const { setUser } = useSentry();
  const { checkRouter } = useLandingPage();
  const { t: translate } = useTranslation();

  const [getExchangeToken, { data: exchangeData }] = useGetExchangeToken();
  const { setBorrower } = useStore();

  const [getBorrowerInfo] = useGetBorrowerDetail();

  const [loading, setLoading] = useState(true);

  const exchangeTokenTypesArray = [ExchangeTokenType.GUEST_BORROWER, ExchangeTokenType.BORROWER];

  useEffect(() => {
    if (id) {
      getExchangeToken({ variables: { input: { token: id } } });
    } else {
      navigate(`/`);
    }
  }, []);

  const fetchApplicationList = async (borrowerId: number) => {
    return await client.query({
      query: GET_APPLICATION_LIST,
      variables: {
        input: {
          search: approvalSearch(borrowerId),
          pagination: approvalPagination,
        },
      },
    });
  };

  useEffect(() => {
    if (exchangeData?.getExchangeToken) {
      if (exchangeData?.getExchangeToken?.status && exchangeData?.getExchangeToken?.status !== 'EXPIRED') {
        StorageService.setAuthData(exchangeData?.getExchangeToken.token, '');

        const { borrowerId } = exchangeData?.getExchangeToken?.data || {};
        getBorrowerData(borrowerId);

        Rox.setCustomNumberProperty('borrower_id', Number(borrowerId));
        Rox.setCustomStringProperty('rox.distinct_id', borrowerId);
        Rox.setCustomStringProperty('borrower_id_str', borrowerId?.toString());
      } else {
        setLoading(false);
        alert.error(translate('oauth.expiredToken'));
        navigate('/');
      }
    }
  }, [exchangeData]);

  const getBorrowerData = async (borrowerId: string) => {
    try {
      const { data } = await client.query({
        query: GET_BORROWER_DETAIL,
        variables: { input: { id: borrowerId } },
      });

      if (data?.getBorrowerDetail?.success) {
        const exchangeTokenData = exchangeData?.getExchangeToken?.data;
        const oauthStateId = new URLSearchParams(location.search).get('oauth_state_id');
        const borrower = data?.getBorrowerDetail?.data;

        setLoading(false);
        setBorrower(borrower);
        setUser({ id: borrower?.id, phone: borrower?.phone });
        const type = exchangeData?.getExchangeToken?.type;
        if (exchangeTokenTypesArray.includes(type)) {
          getBorrowerInfo();
          checkRouter();
          navigate('/portal/home');
        } else if ([ExchangeTokenType.SECURE_DOCUMENT_BORROWER, ExchangeTokenType.BORROWER_DOCUMENTS].includes(type)) {
          navigate('/portal/documents');
        } else if (exchangeData?.getExchangeToken?.type === ExchangeTokenType.APPLICATION_APPROVAL_EXTENSION) {
          if (borrower?.id) {
            const approvedApplications = await fetchApplicationList(borrower?.id);
            if (approvedApplications.data?.fetchApplicationList?.contents) {
              const findApplication = approvedApplications.data.fetchApplicationList.contents.find(
                (applicationList) => applicationList.id === exchangeTokenData.applicationId,
              );
              if (findApplication) {
                navigate('/portal/extend-approval', { state: { approval: findApplication } });
              } else {
                navigate('/portal/approvals');
              }
            }
          } else {
            navigate('/portal/approvals');
          }
        } else {
          navigate(
            `/portal/bank-connect-callback?oauth_state_id=${oauthStateId}&connectionId=${parseInt(
              exchangeTokenData.bankConnectionId,
              10,
            )}`,
          );
        }
      }
    } catch (err) {
      setLoading(false);
      alert.error(translate('oauth.somethingWrong'));
      navigate('/');
    }
  };

  return <Container>{loading && <Loading transparent={true} />}</Container>;
};

export default Oauth;
