import { OperatorEnum } from 'lib/types';

export const fileSearch = (borrowerId) => {
  return [
    {
      key: 'relationType',
      value: 'BORROWER',
      operator: OperatorEnum.EQUAL,
    },
    {
      key: 'type',
      value:
        'ADVERSE_ACTION_LETTER,CREDIT_SCORE_DISCLOSURE,AGREEMENT,AGREEMENT_PIF,PAY_OFF_ESTIMATION,AGREEMENT_SIF,IDENTITY_VERIFICATION,PAYROLL,PAYSTUB,MANUAL_VERIFICATION,SECURE_DOCUMENT',
      operator: OperatorEnum.IN,
    },
    {
      key: 'relationId',
      value: borrowerId?.toString(),
      operator: OperatorEnum.EQUAL,
    },
  ];
};
