import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import StorageService from 'lib/services/Storage';
import { Button, Container, Input } from 'lib/components';
import { SegmentEventNames, useSegment } from 'lib/hooks/useSegment';
import useStore, { LanguageEnum } from 'lib/hooks/useStore';
import { useLogin } from 'lib/hooks/useUser';
import { phonePattern } from 'lib/utils/Validators';
import { AnalyticEventNames, useAnalytics } from 'lib/hooks/useAnalytics';
import { getRedirectUrl } from 'lib/utils/GetRedirectUrl';
import { ReactComponent as RightIcon } from 'assets/images/device_mobile.svg';
import { ReactComponent as Construction } from 'assets/images/construction.svg';
import { ReactComponent as ShareIos } from 'assets/images/share_ios.svg';
import { ReactComponent as ShareAndroid } from 'assets/images/share_android.svg';
import { Body, Icon, theme, Icon as CherryIcon, Button as CherryButton } from '@frontend/cherry-library';
import SliderModal from 'lib/components/SliderModal/SliderModal';
import { getDeviceType, isAndroid, isIOS } from 'lib/utils';
import { SEGMENT_EVENT_NAMES } from 'lib/constans';

const Login = () => {
  const navigate = useNavigate();
  const { t: translate } = useTranslation();
  const { trackPage, trackSegmentEvent, trackIdentify } = useSegment();
  const { defaultLanguage, setDefaultLanguage } = useStore();
  const { trackEvent } = useAnalytics();
  const [requestLogin, { data, loading }] = useLogin();
  const { setHeaderTitle, setPhoneNumber, phoneNumber } = useStore();
  const { token } = StorageService.getAuthData();
  const [mobileAppModalVisibility, setMobileAppModalVisibility] = useState(false);
  const location = useLocation();

  const buttonDisable = !phonePattern.test(phoneNumber || '');

  const isDeviceAndroid = isAndroid();
  const isDeviceIos = isIOS();

  const isMobile = isDeviceAndroid || isDeviceIos;
  const mobileAppTestKeys = [
    'mobileApp.test.subtitle.homePage',
    'mobileApp.test.subtitle.share',
    'mobileApp.test.subtitle.homeScreen',
  ];

  const desktopKeys = ['mobileApp.test.desktop.title', 'mobileApp.test.desktop.subtitle'];

  useEffect(() => {
    trackPage(SEGMENT_EVENT_NAMES.PATIENT_PORTAL.LOGIN.PAGE_LOAD);
    trackIdentify();
    if (token && location) {
      const redirectUrl = getRedirectUrl(location);
      if (!redirectUrl) {
        navigate('/portal/home');
      }
    }
  }, []);

  useEffect(() => {
    const windowSearch = window?.location?.search;
    const queryParams = new URLSearchParams(window?.location?.search);
    if (!!windowSearch && windowSearch?.includes('qr_code_treatment_plan')) {
      const data = {};

      for (const [key, value] of queryParams?.entries()) {
        data[key] = value;
      }

      trackSegmentEvent(SegmentEventNames.TAKE_HOME_APPROVAL, {
        site: 'patient portal',
        type: 'qr_code',
        utm_campaign: data?.['utm_campaign'],
        utm_medium: data?.['utm_medium'],
        utm_source: data?.['utm_source'],
      });
    }
  }, []);

  useEffect(() => {
    setHeaderTitle('token.title');
  }, [setHeaderTitle]);

  useEffect(() => {
    if (data?.requestOtp?.success) {
      navigate('/login');
    }
  }, [data]);

  const handleLogin = async () => {
    const rawPhoneNumber = phoneNumber?.replace(/[^0-9]/gi, '');
    trackSegmentEvent(SegmentEventNames.OTP_REQUESTED, {
      phone: rawPhoneNumber,
    });
    trackIdentify({ phone: rawPhoneNumber });

    await requestLogin();
  };

  const onKeyDown = async (event: React.KeyboardEvent<HTMLDivElement>) => {
    if (event.key === 'Enter' && !buttonDisable) {
      event.preventDefault();
      event.stopPropagation();
      await handleLogin();
    }
  };

  const changeLanguageHandler = () => {
    const language = defaultLanguage === LanguageEnum.EN ? LanguageEnum.ES : LanguageEnum.EN;
    trackEvent(AnalyticEventNames.CHANGE_LANGUAGE, language);
    trackSegmentEvent(SegmentEventNames.CHANGE_LANGUAGE, {
      language,
    });
    setDefaultLanguage(language);
  };

  const openMobileAppModal = () => {
    trackSegmentEvent(SEGMENT_EVENT_NAMES.PATIENT_PORTAL.LOGIN.TRY_MOBILE_APP_CLICKED, {
      deviceType: getDeviceType(),
    });
    setMobileAppModalVisibility(true);
  };
  const hideMobileAppModal = () => {
    trackSegmentEvent(SEGMENT_EVENT_NAMES.PATIENT_PORTAL.LOGIN.GOT_IT_THANKS_CLICKED);
    setMobileAppModalVisibility(false);
  };

  return (
    <Container>
      <Title>{translate('token.info')}</Title>
      <Input
        data-testid="phone"
        type="tel"
        secondary={false}
        onChange={setPhoneNumber}
        onKeyDown={onKeyDown}
        value={phoneNumber}
        label={'token.form.phone.label'}
        mask={'(#00) 000-0000'}
      />
      <ButtonContainer>
        <Button disabled={buttonDisable || loading} loading={loading} onClick={handleLogin}>
          {translate('token.form.submitBtn')}
        </Button>
      </ButtonContainer>
      <LanguageToogle onClick={changeLanguageHandler}>{translate('footer.language')}</LanguageToogle>
      <CherryApplicationContainer>
        <Icon src={RightIcon} data-testid="login-mobile-app" />
        <MobileApptextContainer>
          <Body fontWeight={700} size="small">
            {translate('login.try')}
          </Body>
          <MobileAppText color={theme.main.kellyGreen} fontWeight={700} size="small" onClick={openMobileAppModal}>
            {translate('login.mobileApp')}!
          </MobileAppText>
        </MobileApptextContainer>
      </CherryApplicationContainer>

      {mobileAppModalVisibility && (
        <SliderModal
          title={translate(`login.mobileApp`)}
          show={mobileAppModalVisibility}
          hideModal={hideMobileAppModal}
          width="90%"
          padding={isMobile ? '0 24px 40px' : '0 24px 24px'}
        >
          <ModalContainer>
            {isMobile ? (
              <>
                <InterestText fontWeight={400} size="small">
                  {translate('mobileApp.test.title')}
                </InterestText>
                {mobileAppTestKeys.map((key) => (
                  <SaveTextContainer key={key}>
                    <SaveText fontWeight={400} size="small">
                      {translate(key)}
                    </SaveText>
                    {key === 'mobileApp.test.subtitle.share' && (
                      <CherryIcon
                        height={'24px'}
                        width={'24px'}
                        color={theme.main.black}
                        src={isDeviceAndroid ? ShareAndroid : ShareIos}
                      />
                    )}
                  </SaveTextContainer>
                ))}

                <ScreenShot src={isDeviceAndroid ? 'add-homescreen-android.png' : '/add-homescreen-ios.png'} />
              </>
            ) : (
              <>
                <CherryIconConTainer>
                  <CherryIcon height={'80px'} width={'80px'} color={theme.main.black} src={Construction} />
                </CherryIconConTainer>

                {desktopKeys.map((key) => (
                  <DesktopText key={key} fontWeight={400} size="small">
                    {translate(key)}
                  </DesktopText>
                ))}
              </>
            )}
            <CherryButton fullWidth onClick={hideMobileAppModal}>
              {translate('mobileApp.test.button')}
            </CherryButton>
          </ModalContainer>
        </SliderModal>
      )}
    </Container>
  );
};

const Title = styled.p`
  font-size: 18px;
  line-height: 28px;
  text-align: center;
  font-weight: 100;
  margin-bottom: 30px;
  font-family: ${(props) => props.theme.fonts.primary};
  margin-top: 0;
`;

const LanguageToogle = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${(props) => props.theme.main.green};
  text-decoration: underline;
  cursor: pointer;
`;

const MobileAppText = styled(Body)`
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${theme.main.kellyGreen};
  text-decoration: underline;
  cursor: pointer;
`;
const MobileApptextContainer = styled(Body)`
  display: flex;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 4px;
`;

const InterestText = styled(Body)`
  text-align: center;
  padding-bottom: 16px !important;
`;

const DesktopText = styled(Body)`
  text-align: center;
  padding-bottom: 24px !important;
`;

const SaveText = styled(Body)`
  display: flex;
  gap: 16px;
`;

const CherryApplicationContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
  padding-top: 24px;
`;

const SaveTextContainer = styled.div`
  display: flex;
  gap: 8px;
  flex-direction: row;
  justify-content: space-between;
  padding-bottom: 8px;
`;

const ModalContainer = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
`;

const ScreenShot = styled.img`
  width: 187.85px;
  height: 268.357px;
  align-self: center;
  padding-bottom: 24px;
`;

const CherryIconConTainer = styled.div`
  padding-bottom: 24px;
`;

const ButtonContainer = styled.div`
  margin-top: 20px !important;
`;

export default Login;
