import { FormatAmount } from './Formatters';

interface TxInfo {
  dueAmount: number;
  remainder: number;
  extraForInstallment: number;
  extraForPrincipal: number;
}

interface DueAmountGreaterParams {
  txInfo: TxInfo;
  enteredAmount: number;
  translationArray: Array<{ key: string; value: string }>;
  dueAmountTranslationKey: string;
  isLatePayment: boolean;
  dueAmount: number;
}

interface DueAmountLessParams {
  txInfo: TxInfo;
  dueAmount: number;
  isLatePayment: boolean;
  enteredAmount: number;
  dueDate: string | undefined;
  nextScheduleAmount: number;
  translationArray: Array<{ key: string; value: string }>;
  dueAmountTranslationKey: string;
  FormatDate: (date?: string | number | Date) => string;
}

export const dueAmountIsGreaterThanEnteredAmount = ({
  txInfo,
  enteredAmount,
  translationArray,
  dueAmountTranslationKey,
  isLatePayment,
  dueAmount,
}: DueAmountGreaterParams) => {
  txInfo.dueAmount = enteredAmount;
  translationArray.push({ key: dueAmountTranslationKey, value: FormatAmount(enteredAmount) });
  if (isLatePayment && enteredAmount > 0) {
    txInfo.remainder = dueAmount - enteredAmount;
    translationArray.push({
      key: 'makePayment.paymentType.form.payOtherAmount.subText.pastDue',
      value: FormatAmount(txInfo.remainder),
    });
  }
};

export const dueAmountIsLessThanEnteredAmount = ({
  txInfo,
  dueAmount,
  isLatePayment,
  enteredAmount,
  dueDate,
  nextScheduleAmount,
  translationArray,
  dueAmountTranslationKey,
  FormatDate,
}: DueAmountLessParams) => {
  txInfo.dueAmount = dueAmount;
  translationArray.push({ key: dueAmountTranslationKey, value: FormatAmount(dueAmount) });
  if (isLatePayment && txInfo.dueAmount === enteredAmount) {
    translationArray.push({
      key: 'makePayment.paymentType.form.payOtherAmount.subText.nextDue',
      value: FormatDate(dueDate),
    });
  }
  if (txInfo.dueAmount !== enteredAmount) {
    if (isLatePayment) {
      txInfo.extraForInstallment = enteredAmount - dueAmount;
      if (nextScheduleAmount && txInfo.extraForInstallment > nextScheduleAmount) {
        txInfo.extraForPrincipal = txInfo.extraForInstallment - nextScheduleAmount;
        txInfo.extraForInstallment = nextScheduleAmount;
      }
    } else {
      txInfo.extraForPrincipal = enteredAmount - dueAmount;
    }

    if (txInfo.extraForInstallment) {
      translationArray.push({
        key: 'makePayment.paymentType.form.payOtherAmount.subText.extraForInstallment',
        value: FormatAmount(txInfo.extraForInstallment),
      });
    }
    if (txInfo.extraForPrincipal) {
      translationArray.push({
        key: 'makePayment.paymentType.form.payOtherAmount.subText.extraForPrincipal',
        value: FormatAmount(txInfo.extraForPrincipal),
      });
    }
  }
};
