import i18n from 'i18next';
import jwtDecode from 'jwt-decode';
import { useEffect, useMemo } from 'react';
import { Navigate, Outlet, Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { staticRequest } from 'config/axios';
import { Footer, Header, Loading } from 'lib/components';
import { LANGUAGES } from 'lib/constans';
import NavigateService from 'lib/services/Navigate';
import StorageService from 'lib/services/Storage';
import { useIdleTimeout } from 'lib/hooks/useIdleTimeout';
import { useSentry } from 'lib/hooks/useSentry';
import useStore from 'lib/hooks/useStore';
import { useGetBorrowerDetail } from 'lib/hooks/useUser';
import { initChatWidget, updateChatWidgetAfterLogin } from 'lib/utils';
import {
  Account,
  Approvals,
  ExtendApproval,
  Faq,
  FindProvider,
  Login,
  ModifyDueDate,
  Oauth,
  Otp,
  PaymentAuto,
  PaymentMethod,
  PaymentReview,
  PaymentSuccess,
  PaymentType,
  Purchases,
  Subscriptions,
  SubscriptionsAddPaymentMethod,
  SubscriptionsContactInfo,
  SubscriptionsDetail,
  SubscriptionsReview,
  SubscriptionsSuccess,
  Memberships,
  MembershipsDetail,
  MembershipUpdatePaymentMethod,
  OneTimePayment,
  MonthlyPaymentPlan,
  Settlement,
  UpcomingPayments,
} from './pages';
import { CalculatePayoff, BankConnectApprove } from 'pages/Account/Payment/components';
import { AxiosResponse } from 'axios';
import { useSegment } from 'lib/hooks/useSegment';
import useLandingPage from 'lib/hooks/useLandingPage';
import { checkPathIsMembershipPath } from 'lib/utils/CheckPathIsMembershipPath';
import Documents from 'pages/Documents/Documents';
import { checkPathIsPhoneUpdate } from 'lib/utils/CheckPathIsPhoneUpdate';
import HomePage from 'pages/Home/HomePage';
import SelectPaymentPlan from 'pages/SelectPaymentPlan';
import Purchase from 'pages/Purchase.tsx';
import { CompletedCard } from 'pages/Approvals/components';
import { UseMyBalance } from 'pages/Approvals/components/UseMyBalance';
import { PreviewPayments } from 'pages/Approvals/components/PreviewPayments';
import { ChangeLocation } from 'pages/Approvals/components/ChangeLocation';
import { useFlags } from 'lib/hooks/FeatureManagement/FlagsContext';
import { useIVRTokenExchange } from 'lib/hooks/useIVRTokenExchange';
import { getRedirectUrl } from 'lib/utils/GetRedirectUrl';

interface ContainerInput {
  isAuthPage: boolean;
}

const Router = (): React.ReactElement => {
  const { checkRouter } = useLandingPage();
  const navigate = useNavigate();
  const location = useLocation();
  const { setUser, captureException } = useSentry();
  const { trackIdentify } = useSegment();
  const { loading, setLoading, setBorrower, setFallbackUrl, checkRouterLoading, setDefaultLanguage, setDeepLinkUrl } =
    useStore();
  const { token } = StorageService.getAuthData();
  const isAuthPage = location.pathname === '/' || location.pathname === '/login';
  const isSubscriptions = checkPathIsMembershipPath();
  const isPhoneUpdate = checkPathIsPhoneUpdate();

  const [getBorrowerInfo, { data: borrowerData }] = useGetBorrowerDetail();
  const [ivrTokenExchange] = useIVRTokenExchange();
  const { flags } = useFlags();

  useIdleTimeout({ idleTime: 45 });

  useEffect(() => {
    setFallbackUrl(
      location?.pathname !== '/' && !checkPathIsMembershipPath() && !isPhoneUpdate ? location?.pathname : null,
    );

    const redirectUrl = getRedirectUrl(location);
    if (redirectUrl) {
      setDeepLinkUrl(redirectUrl);
    }
    if (token) {
      const { borrower } = (jwtDecode(token as string) as { borrower }) || {};
      setBorrower(borrower);
      getBorrowerInfo().then((data) => {
        if (borrower) {
          ivrTokenExchange().then((response) => {
            const { data: { ivrTokenExchange: { ivrToken = null } = {} } = {} } = response;
            updateChatWidgetAfterLogin(borrower?.phone, borrower?.firstName, ivrToken);
          });
        } else {
          initChatWidget();
        }
      });
      checkRouter();
    } else {
      initChatWidget();
    }
    getLocalizationFiles();
    NavigateService.initNavigate(navigate);
  }, [flags]);

  useEffect(() => {
    if (borrowerData?.getBorrowerDetail?.success) {
      const borrowerObject = borrowerData?.getBorrowerDetail?.data || {};
      setBorrower(borrowerObject);
      setUser({ id: borrowerObject?.id, phone: borrowerObject?.phone });
      trackIdentify({ userId: borrowerObject?.id, ...borrowerObject });
      setDefaultLanguage(borrowerObject?.language?.toLowerCase());
    }
  }, [borrowerData]);

  const getLocalizationFiles = async () => {
    try {
      setLoading(true);
      const languagePromises: Array<Promise<AxiosResponse>> = [];
      const sessionTime = new Date().getTime();
      LANGUAGES.forEach((language) => {
        languagePromises.push(staticRequest.get(`/customer-portal/locales/${language}.json?cb=${sessionTime}`));
      });
      const resp: any = await Promise.all(languagePromises);
      LANGUAGES.forEach((language, index) => {
        i18n?.addResourceBundle(language, 'translation', resp[index].data);
      });
      setLoading(false);
    } catch (err) {
      captureException(err, { errorContext: 'getLocalizationFiles => Router.tsx' });
      setLoading(false);
    }
  };

  const AuthenticatedRoute = useMemo(
    () =>
      ({ children }) => {
        return token ? <>{children}</> : <Navigate to="/" />;
      },
    [token],
  );

  return (
    <Container isAuthPage={isAuthPage}>
      <MainContainer>
        {!isSubscriptions && !isPhoneUpdate && <Header />}
        <Routes>
          <Route path="/memberships/:slug" element={<Subscriptions />} />
          <Route path="/memberships/:slug/detail" element={<SubscriptionsDetail />} />
          <Route path="/memberships/:slug/add-payment-method" element={<SubscriptionsAddPaymentMethod />} />
          <Route path="/memberships/:slug/contact-information" element={<SubscriptionsContactInfo />} />
          <Route path="/memberships/:slug/review" element={<SubscriptionsReview />} />
          <Route path="/memberships/:slug/success" element={<SubscriptionsSuccess />} />
          {/* <Route path="/phone-update" element={<PhoneUpdateRouter />} /> */}
          {/* <Route path="/phone-update/:slug" element={<PhoneUpdateRouter />} /> */}
          <Route path="/" element={<Login />} />
          <Route path="/login" element={<Otp />} />
          <Route path="oauth/:id" element={<Oauth />} />
          <Route path="/:id" element={<Oauth />} />
          <Route path="/portal" element={<Navigate to="/portal/home" />} />
          <Route
            path="portal"
            element={
              <AuthenticatedRoute>
                <Outlet />
              </AuthenticatedRoute>
            }
          >
            <Route
              path="home"
              element={
                <AuthenticatedRoute>
                  <HomePage />
                </AuthenticatedRoute>
              }
            />
            <Route
              path="purchases"
              element={
                <AuthenticatedRoute>
                  <Purchases />
                </AuthenticatedRoute>
              }
            />
            <Route
              path=":id/purchase"
              element={
                <AuthenticatedRoute>
                  <Purchase />
                </AuthenticatedRoute>
              }
            />
            <Route
              path=":id/calculate-payoff"
              element={
                <AuthenticatedRoute>
                  <CalculatePayoff />
                </AuthenticatedRoute>
              }
            />
            <Route
              path="memberships"
              element={
                <AuthenticatedRoute>
                  <Memberships />
                </AuthenticatedRoute>
              }
            />
            <Route
              path="memberships/:id"
              element={
                <AuthenticatedRoute>
                  <MembershipsDetail />
                </AuthenticatedRoute>
              }
            />
            <Route
              path="memberships/:id/update-payment-method"
              element={
                <AuthenticatedRoute>
                  <MembershipUpdatePaymentMethod />
                </AuthenticatedRoute>
              }
            />
            <Route
              path="approvals"
              element={
                <AuthenticatedRoute>
                  <Approvals />
                </AuthenticatedRoute>
              }
            />
            <Route
              path="approvals/:id"
              element={
                <AuthenticatedRoute>
                  <CompletedCard />
                </AuthenticatedRoute>
              }
            />
            <Route
              path="use-my-balance"
              element={
                <AuthenticatedRoute>
                  <UseMyBalance />
                </AuthenticatedRoute>
              }
            />
            <Route
              path="preview-payments"
              element={
                <AuthenticatedRoute>
                  <PreviewPayments />
                </AuthenticatedRoute>
              }
            />
            <Route
              path="change-location"
              element={
                <AuthenticatedRoute>
                  <ChangeLocation />
                </AuthenticatedRoute>
              }
            />
            <Route
              path="extend-approval"
              element={
                <AuthenticatedRoute>
                  <ExtendApproval />
                </AuthenticatedRoute>
              }
            />
            <Route
              path="find-provider"
              element={
                <AuthenticatedRoute>
                  <FindProvider />
                </AuthenticatedRoute>
              }
            />
            <Route
              path="help"
              element={
                <AuthenticatedRoute>
                  <Faq />
                </AuthenticatedRoute>
              }
            />
            <Route
              path="account"
              element={
                <AuthenticatedRoute>
                  <Account />
                </AuthenticatedRoute>
              }
            />
            <Route
              path="documents"
              element={
                <AuthenticatedRoute>
                  <Documents />
                </AuthenticatedRoute>
              }
            />
            <Route
              path="upcoming-payments"
              element={
                <AuthenticatedRoute>
                  <UpcomingPayments />
                </AuthenticatedRoute>
              }
            />
            <Route
              path=":id/payment-type"
              element={
                <AuthenticatedRoute>
                  <PaymentType />
                </AuthenticatedRoute>
              }
            />
            <Route
              path=":id/payment-method"
              element={
                <AuthenticatedRoute>
                  <PaymentMethod />
                </AuthenticatedRoute>
              }
            />
            <Route
              path=":id/payment-review"
              element={
                <AuthenticatedRoute>
                  <PaymentReview />
                </AuthenticatedRoute>
              }
            />
            <Route
              path=":id/payment-success"
              element={
                <AuthenticatedRoute>
                  <PaymentSuccess />
                </AuthenticatedRoute>
              }
            />
            <Route
              path=":id/autopay"
              element={
                <AuthenticatedRoute>
                  <PaymentAuto />
                </AuthenticatedRoute>
              }
            />
            <Route
              path="modify-due-date"
              element={
                <AuthenticatedRoute>
                  <ModifyDueDate />
                </AuthenticatedRoute>
              }
            />
            <Route
              path="bank-connect-callback"
              element={
                <AuthenticatedRoute>
                  <BankConnectApprove />
                </AuthenticatedRoute>
              }
            />
            <Route
              path=":id/one-time-payment"
              element={
                <AuthenticatedRoute>
                  <OneTimePayment />
                </AuthenticatedRoute>
              }
            />
            <Route
              path=":id/monthly-payment-plan"
              element={
                <AuthenticatedRoute>
                  <MonthlyPaymentPlan />
                </AuthenticatedRoute>
              }
            />
            <Route
              path=":id/settlement"
              element={
                <AuthenticatedRoute>
                  <Settlement />
                </AuthenticatedRoute>
              }
            />
            <Route
              path="select-loan"
              element={
                <AuthenticatedRoute>
                  <SelectPaymentPlan />
                </AuthenticatedRoute>
              }
            />
          </Route>
          <Route path="*" element={<Navigate to={token ? 'portal/home' : '/'} />} />
        </Routes>
      </MainContainer>

      <Footer />
      {(loading || checkRouterLoading) && <Loading />}
    </Container>
  );
};

const Container = styled.div<ContainerInput>`
  width: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: ${(props) => (props.isAuthPage ? props.theme.main.white : props.theme.main.pageColor)};
`;

const MainContainer = styled.div`
  min-height: 90vh;
`;

export default Router;
