import { Fade } from '@mui/material';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

export const NoCard = () => {
  const { t: translate } = useTranslation();
  return (
    <Fade in={true} timeout={750}>
      <NoPayment>{translate(`autoPay.card.noCard`)}</NoPayment>
    </Fade>
  );
};

const NoPayment = styled.p`
  font-weight: bold;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  margin: 24px 0px 32px 0px;
  color: ${(props) => props.theme.main.primary};
`;
