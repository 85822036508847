import { ApolloLink, Observable } from '@apollo/client';

import NavigateService from 'lib/services/Navigate';
import StorageService from 'lib/services/Storage';
import { checkPathIsMembershipPath } from 'lib/utils/CheckPathIsMembershipPath';
import { checkPathIsPhoneUpdate } from 'lib/utils/CheckPathIsPhoneUpdate';

const connections = {};

export const cancelRequestLink = new ApolloLink(
  (operation, forward) =>
    new Observable((observer) => {
      // Set x-CSRF token (not related to abort use case)
      const context = operation.getContext();
      /** Final touch to cleanup */
      const { token } = checkPathIsMembershipPath()
        ? StorageService.getSubscriptionsToken()
        : checkPathIsPhoneUpdate()
        ? StorageService.getSelfUpdateToken()
        : StorageService.getAuthData();

      const connectionHandle = forward(operation).subscribe({
        next: (...arg) => observer.next(...arg),
        error: (...arg) => {
          cleanUp();
          observer.error(...arg);
        },
        complete: (...arg) => {
          cleanUp();
          observer.complete(...arg);
        },
      });

      const cleanUp = () => {
        connectionHandle?.unsubscribe();
        delete connections[context.requestTrackerId];
      };

      if (context.requestTrackerId) {
        const controller = new AbortController();
        controller.signal.onabort = cleanUp;
        operation.setContext({
          ...context,
          fetchOptions: {
            signal: controller.signal,
            ...context?.fetchOptions,
          },
        });

        if (connections[context.requestTrackerId]) {
          // If a controller exists, that means this operation should be aborted.
          connections[context.requestTrackerId]?.abort();
        }

        connections[context.requestTrackerId] = controller;
      }
      const allowedOperations = [
        'verifyOtpRequest',
        'requestOtp',
        'getUserAuthInit',
        'fetchOrganizationsMembershipPlans',
        'getExchangeToken',
        'selfUpdateBorrower',
      ];
      const isAllowedOperation = allowedOperations.includes(operation?.operationName);
      if ((token === 'undefined' || !Boolean(token)) && !isAllowedOperation) {
        cleanUp();
        StorageService.clearUserData();
        NavigateService.navigate('/');
      }

      return connectionHandle;
    }),
);
