import { ApolloProvider } from '@apollo/client';
import { useEffect } from 'react';
import { positions, Provider as AlertProvider } from 'react-alert';
import { useTranslation } from 'react-i18next';
import { BrowserRouter } from 'react-router-dom';
import Router from 'Router';

import dayjs from 'dayjs';
import 'dayjs/locale/es';

import { Notification } from 'lib/components';
import ApolloClient from 'lib/graphql/client';
import useStore from 'lib/hooks/useStore';

function App(): React.ReactElement {
  const { defaultLanguage } = useStore();
  const { i18n } = useTranslation();

  const alertOptions = {
    timeout: 5000,
    position: positions.TOP_CENTER,
    containerStyle: {
      zIndex: 100,
      pointerEvents: 'inherit',
      cursor: 'pointer',
    },
    pointerEvents: 'inherit',
  };

  useEffect(() => {
    i18n.changeLanguage(defaultLanguage);
    dayjs.locale(i18n.language);
  }, [defaultLanguage, i18n]);

  return (
    <ApolloProvider client={ApolloClient}>
      {/* <Suspense fallback={<Loading />}> */}
      <AlertProvider template={Notification} {...alertOptions}>
        <BrowserRouter>
          <Router />
        </BrowserRouter>
      </AlertProvider>
      {/* </Suspense> */}
    </ApolloProvider>
  );
}

export default App;
