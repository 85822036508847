import { useEffect, useState } from 'react';
import { useAlert } from 'react-alert';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

import { Button, Radio } from '@frontend/cherry-library';
import { usePatchExtendApproval } from 'lib/hooks/useExtendApprovals';
import { FormatAmount, useFormatDate } from 'lib/utils';
import { ButtonContainer, ContentContainer, HeaderText } from 'pages/ExtendApproval/style';
import { Approval, ScreenState } from 'pages/ExtendApproval/type';

import { useSegment } from 'lib/hooks/useSegment';
import { SEGMENT_EVENT_NAMES } from 'lib/constans';
import { getApplicationDetailData } from 'lib/utils/ApplicationDetailSegmentEventData';
import useStore from 'lib/hooks/useStore';

enum SelectionDataValues {
  value1 = '1',
  value2 = '2',
  value3 = '3',
  value4 = '4',
}

interface Props {
  approval: Approval;
  setScreenState: (value: ScreenState) => void;
}

enum RadioInputType {
  PastExpirationDate = 'PAST_EXPIRATION_DATE',
  AddServices = 'ADD_SERVICES',
  PaymentIssue = 'PAYMENT_ISSUE',
  Other = 'OTHER',
}

type SelectionDataItem = {
  id: string;
  label: string;
  value: SelectionDataValues;
  type: RadioInputType;
  segmentEventName: string;
};

function getSegmentEventNameBasedOnRadioType(type: RadioInputType) {
  switch (type) {
    case RadioInputType.PastExpirationDate:
      return SEGMENT_EVENT_NAMES.PATIENT_PORTAL.REQUEST_MORE_TIME.REASON_APPOINTMENT_PAST_EXPIRATION_DATE_SELECTED;
    case RadioInputType.AddServices:
      return SEGMENT_EVENT_NAMES.PATIENT_PORTAL.REQUEST_MORE_TIME.REASON_ADD_SERVICES_SELECTED;
    case RadioInputType.PaymentIssue:
      return SEGMENT_EVENT_NAMES.PATIENT_PORTAL.REQUEST_MORE_TIME.REASON_PAYMENT_ISSUE_SELECTED;
    case RadioInputType.Other:
      return SEGMENT_EVENT_NAMES.PATIENT_PORTAL.REQUEST_MORE_TIME.REASON_OTHER_SELECTED;
  }
}

export const ReasonSelection = ({ approval, setScreenState }: Props) => {
  const { t: translate } = useTranslation();
  const [selectedReason, setSelectedReason] = useState('');
  const [patchExtendApproval, { data, loading }] = usePatchExtendApproval();
  const { expireAt, organization, balanceAvailable, id } = approval;
  const { borrower } = useStore();
  const alert = useAlert();
  const navigate = useNavigate();
  const { FormatDate } = useFormatDate();
  const { trackPage, standardSegmentMetadata, trackSegmentEvent } = useSegment();
  const applicationSegmentData = getApplicationDetailData(borrower, approval);
  const pageSegmentEventData = { ...standardSegmentMetadata, ...applicationSegmentData };

  useEffect(() => {
    trackPage(SEGMENT_EVENT_NAMES.PATIENT_PORTAL.REQUEST_MORE_TIME.REASON_PAGE_LOAD, pageSegmentEventData);
  }, []);

  const SelectionData: SelectionDataItem[] = [
    {
      id: '1',
      label: translate('extend.approvals.selection.option1', { expireDate: FormatDate(expireAt) }),
      value: SelectionDataValues.value1,
      type: RadioInputType.PastExpirationDate,
      segmentEventName: SEGMENT_EVENT_NAMES.PATIENT_PORTAL.REQUEST_MORE_TIME.APPT_PAST_EXPIRATION_DATE_SELECTED,
    },
    {
      id: '2',
      label: translate('extend.approvals.selection.option2'),
      value: SelectionDataValues.value2,
      type: RadioInputType.AddServices,
      segmentEventName: SEGMENT_EVENT_NAMES.PATIENT_PORTAL.REQUEST_MORE_TIME.ADD_SERVICES_SELECTED,
    },
    {
      id: '3',
      label: translate('extend.approvals.selection.option3'),
      value: SelectionDataValues.value3,
      type: RadioInputType.PaymentIssue,
      segmentEventName: SEGMENT_EVENT_NAMES.PATIENT_PORTAL.REQUEST_MORE_TIME.PAYMENT_ISSUE_SELECTED,
    },
    {
      id: '4',
      label: translate('extend.approvals.selection.option4'),
      value: SelectionDataValues.value4,
      type: RadioInputType.Other,
      segmentEventName: SEGMENT_EVENT_NAMES.PATIENT_PORTAL.REQUEST_MORE_TIME.OTHER_SELECTED,
    },
  ];

  useEffect(() => {
    if (data?.patchExtendApproval?.id) {
      setScreenState(ScreenState.EXTEND_SUCCESS);
    } else {
      alert.error(data?.patchExtendApproval.message);
    }
  }, [data]);

  const goBackHandler = () => {
    trackSegmentEvent(SEGMENT_EVENT_NAMES.PATIENT_PORTAL.REQUEST_MORE_TIME.REASON_BACK_CLICKED, pageSegmentEventData);
    navigate(-1);
  };

  const extendApprovalHandler = () => {
    patchExtendApproval({ variables: { input: { applicationId: id } } });
  };

  const continueButtonHandler = () => {
    trackSegmentEvent(
      SEGMENT_EVENT_NAMES.PATIENT_PORTAL.REQUEST_MORE_TIME.REASON_CONTINUE_CLICKED,
      pageSegmentEventData,
    );
    if (selectedReason === '1') {
      setScreenState(ScreenState.APPOINTMENT);
    } else {
      extendApprovalHandler();
    }
  };

  const getRadioChangeHandler = (selection: SelectionDataItem) => () => {
    const eventName = getSegmentEventNameBasedOnRadioType(selection.type);
    trackSegmentEvent(eventName, pageSegmentEventData);
    setSelectedReason(selection.value);
  };

  return (
    <>
      <HeaderText
        fontWeight={400}
        size="small"
        dangerouslySetInnerHTML={{
          __html: translate('extend.approvals.reason.selection.subtitle1', {
            purchaseAmount: FormatAmount(balanceAvailable),
            organizationName: organization.name,
            expireDate: FormatDate(expireAt),
          }),
        }}
      />
      <HeaderText size="small" fontWeight={400}>
        {translate('extend.approvals.reason.selection.subtitle2')}
      </HeaderText>
      <ContentContainer>
        {SelectionData.map((selection) => {
          return (
            <RadioContainer key={selection.id}>
              <Radio
                checked={selection.value === selectedReason}
                frameOn
                label={selection.label}
                onFocusVisible={() => {
                  setSelectedReason(selection.value);
                }}
                size="large"
                onChange={getRadioChangeHandler(selection)}
              />
            </RadioContainer>
          );
        })}
      </ContentContainer>
      <ButtonContainer>
        <Button
          fullWidth
          variant="primary"
          onClick={continueButtonHandler}
          disabled={!selectedReason || loading}
          loading={loading}
        >
          {translate('makePayment.paymentType.submitButton')}
        </Button>
        <Button variant="secondary" onClick={goBackHandler} fullWidth>
          {translate('genericButton.back')}
        </Button>
      </ButtonContainer>
    </>
  );
};

const RadioContainer = styled.div`
  display: flex;
  flex-direction: column;
`;
