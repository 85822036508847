import MenuItem from '@mui/material/MenuItem';
import { useEffect, useState } from 'react';
import { useAlert } from 'react-alert';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { TextField } from 'lib/components';
import { usePatchLocationPreference } from 'lib/hooks/useApproval';
import { useSegment } from 'lib/hooks/useSegment';
import { FormatPhoneNumber } from 'lib/utils';
import { ExternalRedirect } from 'lib/utils/ExternalRedirect';
import { Approval, Address, Communication } from '../type';
import { Body, theme, Button } from '@frontend/cherry-library';
import { goBack } from 'lib/utils/GoBack';
import { useNavigate } from 'react-router-dom';
import useStore from 'lib/hooks/useStore';
import { SEGMENT_EVENT_NAMES } from 'lib/constans';
import { getApplicationDetailData } from 'lib/utils/ApplicationDetailSegmentEventData';
interface Props {
  approval: Approval;
  handleClose: (value?: boolean) => void;
}

export const ApprovalDetail = ({ approval, handleClose }: Props) => {
  const alert = useAlert();
  const { t: translate } = useTranslation();
  const { trackSegmentEvent, trackPage } = useSegment();
  const navigate = useNavigate();

  const [patchLocationPreference, { data, loading }] = usePatchLocationPreference();

  const [selectedLocationId, setSelectedLocationId] = useState<string | null>(null);
  const [selectedLocationAddress, setSelectedLocationAddress] = useState<Address>();
  const [selectedLocationCommunication, setSelectedLocationCommunication] = useState<Communication>();

  const [isSelectionChanged, setIsSelectionChanged] = useState(false);
  const { id, expireAt, organizationId, organization } = approval || {};
  const { website, phone } = organization || {};
  const { locations, borrower } = useStore();

  const sortedLocations = [...locations];
  sortedLocations?.sort((a, b) => a?.name.localeCompare(b?.name));

  const segmentEventData = getApplicationDetailData(borrower, approval);

  useEffect(() => {
    if (locations?.length > 0 && approval) {
      const locationId = approval.merchantId ? approval.merchantId : locations?.[0]?.id;
      setSelectedLocationId(locationId);
      setSelectedLocationAddress(locations?.filter((x) => x.id === selectedLocationId)[0]?.address);
    }
    trackPage(SEGMENT_EVENT_NAMES.PATIENT_PORTAL.CHANGE_LOCATION_PREFERENCE.PAGE_LOAD, segmentEventData);
  }, []);

  useEffect(() => {
    if (data?.patchMerchantPreference?.id) {
      alert.success(translate('approval.detail.update.success'));
      handleClose(true);
      goToApprovals();
    }
  }, [data]);

  const phoneCallHandler = () => {
    trackSegmentEvent(SEGMENT_EVENT_NAMES.PATIENT_PORTAL.CHANGE_LOCATION_PREFERENCE.PHONE_CLICKED, segmentEventData);
    window.location.href = 'tel:' + phone;
  };

  const websiteHandler = () => {
    trackSegmentEvent(SEGMENT_EVENT_NAMES.PATIENT_PORTAL.CHANGE_LOCATION_PREFERENCE.WEBSITE_CLICKED, segmentEventData);
    ExternalRedirect(website);
  };

  const onLocationChangeHandler = (e) => {
    setIsSelectionChanged(true);
    setSelectedLocationId(e?.target?.value);
  };

  const goBackHandler = () => {
    trackSegmentEvent(SEGMENT_EVENT_NAMES.PATIENT_PORTAL.CHANGE_LOCATION_PREFERENCE.CANCEL_CLICKED, segmentEventData);
    goBack();
  };

  const updateSelectedLocation = async () => {
    setIsSelectionChanged(false);
    await patchLocationPreference({
      variables: { input: { applicationId: approval?.id, merchantId: selectedLocationId } },
    });
    trackSegmentEvent(
      SEGMENT_EVENT_NAMES.PATIENT_PORTAL.CHANGE_LOCATION_PREFERENCE.SAVE_LOCATION_CLICKED,
      segmentEventData,
    );
  };

  useEffect(() => {
    const selectedMerchant = locations?.find((location) => location.id === selectedLocationId);
    const selectedMerchantAddress = locations?.find((location) => location.id === selectedLocationId)?.address;
    setSelectedLocationAddress(selectedMerchantAddress);
    setSelectedLocationCommunication(selectedMerchant?.communicationSettings);
    trackSegmentEvent(SEGMENT_EVENT_NAMES.PATIENT_PORTAL.CHANGE_LOCATION_PREFERENCE.LOCATION_SELECTED, {
      ...segmentEventData,
      merchantName: selectedMerchant?.name,
      merchantId: selectedMerchant?.id,
    });
  }, [selectedLocationId]);

  const goToApprovals = () => {
    navigate('/portal/approvals');
  };

  return (
    <>
      {locations?.length > 1 && (
        <Explanation fontWeight={400} color={theme.main.midnightBlue}>
          {translate('approval.detail.multipleLocationsExplanation')}
        </Explanation>
      )}
      <Container>
        <div>
          <FooterContainer>
            {locations?.length > 0 ? (
              <LabelContainer>
                <StyledTextField
                  id="outlined-required"
                  data-testid="locationTypes"
                  placeholder={translate('approval.detail.locationName')}
                  select={true}
                  value={selectedLocationId}
                  onChange={onLocationChangeHandler}
                >
                  {sortedLocations?.map((option) => (
                    <StyledMenuItem data-testid="methodItem" key={option?.id} value={option?.id}>
                      <div dangerouslySetInnerHTML={{ __html: option?.name }} />
                      <div>
                        {option?.address?.street} - {option?.address?.city}
                      </div>
                    </StyledMenuItem>
                  ))}
                </StyledTextField>
              </LabelContainer>
            ) : null}

            {selectedLocationAddress?.street ||
            selectedLocationAddress?.city ||
            selectedLocationAddress?.state?.code ||
            selectedLocationAddress?.zip ? (
              <Subtitle>
                {selectedLocationAddress?.street}
                <br />
                {selectedLocationAddress?.city}, {selectedLocationAddress?.state?.code} {selectedLocationAddress?.zip}
              </Subtitle>
            ) : null}
          </FooterContainer>

          {selectedLocationCommunication?.phone || phone ? (
            <LabelContainer>
              <Label color={theme.main.midnightBlue}>
                <InfoIcon src={'/light_phone.svg'} />
                <LabelLink onClick={phoneCallHandler}>
                  {FormatPhoneNumber(selectedLocationCommunication?.phone || phone)}
                </LabelLink>
              </Label>
            </LabelContainer>
          ) : null}

          {selectedLocationCommunication?.website || website ? (
            <LabelContainer>
              <Label color={theme.main.midnightBlue}>
                <InfoIcon src={'/cursor.svg'} />
                <LabelLink onClick={websiteHandler}>{selectedLocationCommunication?.website || website}</LabelLink>
              </Label>
            </LabelContainer>
          ) : null}

          <ButtonContainer>
            {locations?.length > 0 ? (
              <Button
                fullWidth
                disabled={!selectedLocationId || loading || !isSelectionChanged}
                loading={loading}
                onClick={updateSelectedLocation}
              >
                {translate('approval.detail.saveLocationPreference')}
              </Button>
            ) : null}
            <Button fullWidth variant="secondary" onClick={goBackHandler}>
              {translate('common.cancel')}
            </Button>
          </ButtonContainer>
        </div>
      </Container>
    </>
  );
};

const Container = styled.div`
  padding: 24px;
  display: flex;
  flex-direction: column;
  background-color: #f2f4f5;
  border-radius: 8px;
`;

const FooterContainer = styled.div`
  padding-top: 0px;
`;

const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  margin-top: 8px;
  gap: 8px;
`;

const LabelContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding-bottom: 8px;
  align-items: center;
  gap: 12px;
`;

const Explanation = styled(Body)`
  margin-bottom: 24px !important;
`;

const Label = styled(Body)`
  display: flex;
  align-items: center;
  line-height: 19px;
`;

const LabelLink = styled.a`
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  color: ${theme.main.midnightBlue};
  text-decoration: underline;
  cursor: pointer;
`;

const InfoIcon = styled.img`
  width: 16px;
  height: 16px;
  margin-right: 6px;
`;

const Subtitle = styled.div`
  font-weight: normal;
  font-size: 16px;
  line-height: 22px;
  text-align: center;
  color: ${theme.main.midnightBlue};
  padding: 8px 12px 4px;
  margin-bottom: 8px;
`;

const StyledMenuItem = styled(MenuItem)`
  display: flex;
  flex-direction: column;
  align-items: flex-start !important;
  margin-bottom: 12px !important;
  background-color: ${theme.main.white} !important;
  & div:first-child {
    font-size: 16px;
    font-weight: 600;
  }

  & div {
    font-size: 14px;
  }
`;

const StyledTextField = styled(TextField)`
  text-align: left;
  width: 100%;
  & div:first-child {
    font-size: 16px;
    font-weight: 600;
    background-color: ${theme.main.white};
  }

  & div {
    font-size: 14px;
    font-weight: 400;
  }
`;
