import { useNavigate } from 'react-router-dom';
import useStore from './useStore';

export const useNavigation = () => {
  const domNavigate = useNavigate();

  const navigate = (url: string | number): void => {
    const { organization } = useStore.getState() || {};
    if (url === '') {
      window.location.href = `/${organization?.slug}`;
    } else {
      domNavigate(`/${organization?.slug}/${url}`);
    }
  };

  const navigateBack = () => {
    domNavigate(-1);
  };

  return {
    navigate,
    navigateBack,
  };
};
