import { Fade } from '@mui/material';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { Button, ButtonColor } from 'lib/components';
import { useGetPaymentMethods, usePatchLoan } from 'lib/hooks/usePayment';
import useStore from 'lib/hooks/useStore';

import { Loading } from 'lib/components';
import { paymentSearch } from 'lib/graphql/searches';
import { PaymentItem } from './PaymentItem';

export const FinalizePaymentMethod = ({ newlyAddedPayment, handleCancel }) => {
  const { t: translate } = useTranslation();
  const { loanList, borrower, paymentMethods } = useStore();

  const [cancelLoading, setCancelLoading] = useState(false);
  const [initialLoading, setInitialLoading] = useState(false);

  const autoPayLoans = loanList.filter((item) => item?.autoPay);

  const [patchLoanMethod] = usePatchLoan();
  const [getPaymentMethods, { data: paymentData, loading }] = useGetPaymentMethods();

  useEffect(() => {
    const getAutoPayActivePaymentMethod = paymentMethods.filter((pm) => pm.activeLoanCount > 0);
    if (
      getAutoPayActivePaymentMethod?.[0]?.storedCard?.type === 'CREDIT' &&
      newlyAddedPayment?.storedCard?.type !== 'CREDIT'
    ) {
      setInitialLoading(true);
      attachAddedPaymentMethodToLoan();
    }
  }, []);

  useEffect(() => {
    if (paymentData?.getPaymentMethods) {
      setCancelLoading(false);
      handleCancel();
    }
  }, [paymentData]);

  const getPaymentMethodRequest = () => {
    getPaymentMethods({
      variables: {
        input: {
          borrowerId: borrower?.id?.toString(),
          search: paymentSearch,
        },
      },
    });
  };

  const attachAddedPaymentMethodToLoan = async () => {
    const patchLoanArray: any = [];

    autoPayLoans?.map((loan) =>
      patchLoanArray.push(
        patchLoanMethod({
          variables: { input: { loanId: loan?.id, installmentPaymentMethodId: newlyAddedPayment?.id, autoPay: true } },
        }),
      ),
    );

    await Promise.all(patchLoanArray);
    getPaymentMethodRequest();
  };

  const cancelAttachPayment = () => {
    setCancelLoading(true);
    getPaymentMethodRequest();
  };

  return (
    <Fade in={true} timeout={750}>
      <MainContainer>
        {!initialLoading && (
          <Container data-testid="finalizePayment">
            <PaymentItem data={newlyAddedPayment} readOnly={true} />
            <InfoContainer>
              <CycleIcon src={'/green-cycle.svg'} />
              <Text>
                {translate('account.payment.finalize.autopay1', {
                  autoPayLoanLength: autoPayLoans?.length,
                })}
              </Text>
            </InfoContainer>
            <InfoList>
              <InfoItem>{translate('account.payment.finalize.autopay2')}</InfoItem>
              <InfoItem>{translate('account.payment.finalize.autopay3')}</InfoItem>
            </InfoList>
            <Button disabled={loading} loading={loading} onClick={attachAddedPaymentMethodToLoan}>
              {translate('account.payment.finalize.autopay.confirm')}
            </Button>
            <Button
              disabled={cancelLoading}
              loading={cancelLoading}
              onClick={cancelAttachPayment}
              color={ButtonColor.secondary}
            >
              {translate('account.payment.finalize.autopay.cancel')}
            </Button>
          </Container>
        )}
        {initialLoading && <Loading transparent={true} />}
      </MainContainer>
    </Fade>
  );
};

const Container = styled.div``;
const MainContainer = styled.div``;

const InfoContainer = styled.div`
  display: flex;
  align-items: center;
  padding-left: 20px;
`;

const Text = styled.span`
  font-size: 16px;
  margin-left: 8px;
  line-height: 24px;
  margin-bottom: 16pxpx;
  text-align: left;
`;

const CycleIcon = styled.img`
  width: 25px;
`;

const InfoList = styled.ul`
  margin: 20px 0px 10px 0px;
`;
const InfoItem = styled.li`
  font-size: 14px;
  font-family: ${(props) => props.theme.fonts.primary};
`;
