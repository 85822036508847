import { memo, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { WEB_SITE } from 'config';
import useStore from 'lib/hooks/useStore';

import FaqAccordion from './components/Accordion';
import { IFAQ } from './type';
import { RoundedContainer } from 'lib/components/RoundedContainer';
import { Body, Button, Heading } from '@frontend/cherry-library';
import styled from 'styled-components';
import { Container } from 'lib/components';
import { theme } from 'config/theme';
import { useHelpModal } from 'lib/hooks/useModal';
import { SEGMENT_EVENT_NAMES } from 'lib/constans';
import { useSegment } from 'lib/hooks/useSegment';
import { getSegmentEventBorrowerData } from 'lib/utils/SegmentEventData';

const Faq = memo(() => {
  const { t: translate } = useTranslation();
  const { setHeaderTitle } = useStore();
  const { trackPage, trackSegmentEvent } = useSegment();
  const helpModal = useHelpModal();
  const { borrower } = useStore();

  const segmentEventBorrowerData = getSegmentEventBorrowerData(borrower);

  useEffect(() => {
    setHeaderTitle('menu.listItems.faq');
    trackPage(SEGMENT_EVENT_NAMES.PATIENT_PORTAL.HELP.PAGE_LOAD, segmentEventBorrowerData);
  }, []);

  const faqs: IFAQ[] = [
    {
      title: translate(`faq.firstQuestion.question`),
      content: translate('faq.firstQuestion.answer'),
    },
    {
      title: translate(`faq.secondQuestion.question`),
      content: translate('faq.secondQuestion.answer'),
    },
    {
      title: translate(`faq.thirdQuestion.question`),
      content: translate('faq.thirdQuestion.answer'),
    },
    {
      title: translate(`faq.fourthQuestion.question`),
      content: translate('faq.fourthQuestion.answer'),
    },
    {
      title: translate(`faq.fifthQuestion.question`),
      content: translate('faq.fifthQuestion.answer'),
    },
  ];

  const openFaq = () => {
    trackSegmentEvent(SEGMENT_EVENT_NAMES.PATIENT_PORTAL.HELP.MORE_QUESTIONS_CLICKED, segmentEventBorrowerData);
    window?.open(`${WEB_SITE}/patient-faq/`, '_blank');
  };

  const openHelpModal = () => {
    helpModal.openHelpModal();
    trackSegmentEvent(SEGMENT_EVENT_NAMES.PATIENT_PORTAL.HELP.CONTACT_SUPPORT_CLICKED, segmentEventBorrowerData);
  };

  return (
    <>
      <RoundedContainer title={translate('faq.title')} noData={false} noDataText={''}>
        <FaqAccordion data={faqs} segmentEventId={1} />
        <Button onClick={openFaq} variant="secondary" fullWidth>
          {translate('faq.moreButton')}
        </Button>
      </RoundedContainer>
      <Container block={true}>
        <InnerContainer>
          <FlexColumnContainer>
            <ContactHeading level="4" text={translate('faq.support.title')} />
            <div>
              <Body color={theme.main.primary} fontWeight={400}>
                {translate('faq.support.hours')}
              </Body>
              <Body color={theme.main.primary} fontWeight={400}>
                <BulletContainer>•</BulletContainer> {translate('faq.support.weekdays')}
              </Body>
              <Body color={theme.main.primary} fontWeight={400}>
                <BulletContainer>•</BulletContainer> {translate('faq.support.saturday')}
              </Body>
              <Body color={theme.main.primary} fontWeight={400}>
                <BulletContainer>•</BulletContainer> {translate('faq.support.sunday')}
              </Body>
            </div>
          </FlexColumnContainer>
          <Button onClick={openHelpModal} fullWidth>
            {translate('faq.support.title')}
          </Button>
        </InnerContainer>
      </Container>
      {helpModal.helpModalVisibility && helpModal.ModalBody}
    </>
  );
});

const InnerContainer = styled.div`
  padding: 16px;
  display: flex;
  flex-direction: column;
  background-color: ${(props) => props.theme.main.white};
  border-radius: 16px;
  gap: 16px;
  box-shadow: 0px 36px 67px 0px rgba(0, 0, 0, 0.07), 0px 13.141px 24.456px 0px rgba(0, 0, 0, 0.05),
    0px 6.38px 11.873px 0px rgba(0, 0, 0, 0.04), 0px 3.127px 5.82px 0px rgba(0, 0, 0, 0.03),
    0px 1.237px 2.301px 0px rgba(0, 0, 0, 0.02);
`;

const ContactHeading = styled(Heading)`
  color: ${(props) => props.theme.main.primary} !important;
`;

const FlexColumnContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const BulletContainer = styled.span`
  margin: 0 5px 0 10px;
`;

export default Faq;
