import styled from 'styled-components';
import { Subtitle } from 'lib/components';
import { Body, theme } from '@frontend/cherry-library';

const Container = styled.div`
  padding: 16px;
  border-radius: 4px;
  border: 1px solid ${theme.main.midnightBlue20};
  background: ${theme.main.white};
  color: ${theme.main.midnightBlue};
`;

const Headline = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

const HeadlineText = styled(Subtitle)`
  font-size: 16px;
  font-weight: 700;
  color: ${(props) => props.theme.main.primary};
`;

const Paragraph = styled(Body)`
  margin: 0 0 16px;
`;

const ButtonContainer = styled.div`
  margin-top: 16px;

  button {
    margin: 0;
    padding: 16px;
    font-size: 18px;
  }
`;

const Badge = styled.div`
  display: inline-block;
  padding: 4px 8px;
  border-radius: 16px;
  background-color: ${(props) => props.theme.main.primary};
  color: ${(props) => props.theme.main.white};
  font-size: 12px;
  font-weight: 400;
  letter-spacing: 0.6px;
  text-transform: uppercase;
  margin: 16px 0 8px;
`;

export const ChargeOffLoanStyles = { Container, Headline, HeadlineText, Paragraph, ButtonContainer, Badge };
