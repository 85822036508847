import { Box } from '@mui/material';
import { FormatAmount, useFormatDate } from 'lib/utils';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import dayjs from 'dayjs';
import { SettlementFrequency } from 'lib/types';

type PaymentInstallmentListItemProps = {
  installmentCount: number;
  installmentAmountPerMonth: number;
  selected?: boolean;
  paymentStartDate: string;
  frequency: SettlementFrequency;
  onSelect: (installmentCount: number) => void;
};

const getPeriodToBeAdd = (frequency: SettlementFrequency) => {
  switch (frequency) {
    case SettlementFrequency.Weekly:
      return {
        amount: 1,
        unit: 'week',
      };
    case SettlementFrequency.BiWeekly:
      return {
        amount: 2,
        unit: 'week',
      };
    case SettlementFrequency.Monthly:
      return {
        amount: 1,
        unit: 'month',
      };
    default:
      return null;
  }
};

const PaymentInstallmentListItem = ({
  installmentCount,
  installmentAmountPerMonth,
  selected = false,
  paymentStartDate,
  frequency,
  onSelect,
}: PaymentInstallmentListItemProps) => {
  const { t } = useTranslation();
  const { FormatDate } = useFormatDate();

  const schedule = useMemo(() => {
    const periodToBeAdd = getPeriodToBeAdd(frequency);
    const scheduledPaymentDates: Array<Date | string> = [];

    if (periodToBeAdd) {
      for (let i = 1; i <= installmentCount; i++) {
        if (i === 1) {
          scheduledPaymentDates.push(dayjs(paymentStartDate).toString());
        } else {
          scheduledPaymentDates.push(
            dayjs(scheduledPaymentDates[scheduledPaymentDates.length - 1])
              // @ts-ignore periodToBeAdd.unit
              .add(periodToBeAdd.amount, periodToBeAdd.unit)
              .toString(),
          );
        }
      }
    }

    return scheduledPaymentDates;
  }, [installmentCount, installmentAmountPerMonth, frequency, paymentStartDate]);

  const scheduleListItems = useMemo(
    () =>
      schedule.map((date, index) => (
        <PaymentRow key={`${date}-${index}`}>
          <RowCount>{index + 1}</RowCount>
          <DateText>
            {FormatAmount(installmentAmountPerMonth)} on {FormatDate(date)}
          </DateText>
        </PaymentRow>
      )),
    [schedule],
  );

  const handleInstallmentSelect = () => {
    onSelect(installmentCount);
  };

  return (
    <ContainerButton selected={selected} onClick={handleInstallmentSelect}>
      <ContainerInnerLayout selected={selected}>
        <PaymentRow selected={selected}>
          <div>{`${installmentCount} ${t('monthlyPaymentPlan.payments')}`}</div>
        </PaymentRow>
        <Box>
          <InstallmentAmountText selected={selected}>{FormatAmount(installmentAmountPerMonth)}</InstallmentAmountText> /{' '}
          {frequency === SettlementFrequency.Monthly && t('monthlyPaymentPlan.month')}
          {frequency === SettlementFrequency.Weekly && t('monthlyPaymentPlan.week')}
          {frequency === SettlementFrequency.BiWeekly && `2 ${t('monthlyPaymentPlan.weeks')}`}
        </Box>
      </ContainerInnerLayout>
      {selected && <PaymentScheduleContainer>{scheduleListItems}</PaymentScheduleContainer>}
    </ContainerButton>
  );
};

const ContainerButton = styled.button<{ selected?: boolean }>`
  width: 100%;
  border: none;
  outline: none;
  cursor: pointer;
  background-color: ${(props) => props.theme.main.white};
  font-family: 'Open Sans';
  border-radius: 8px;
  border: 1px solid ${(props) => (props.selected ? props.theme.main.green : props.theme.main.lightGrayishBlue)};
  background: ${(props) => (props?.selected ? props.theme.main.green : props.theme.main.white)};
  padding: 0;
  box-shadow: ${(props) =>
    props.selected
      ? '0px 1px 2px 0px rgba(0, 0, 0, 0.02), 0px 3px 5px 0px rgba(0, 0, 0, 0.03), 0px 6px 11px 0px rgba(0, 0, 0, 0.04), 0px 12px 22px 0px rgba(0, 0, 0, 0.05), 0px 32px 60px 0px rgba(0, 0, 0, 0.07);'
      : 'none'};
`;

const ContainerInnerLayout = styled.div<{ selected?: boolean }>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: ${(props) => (props.selected ? props.theme.main.white : props.theme.main.lightMidnightBlue)};
  font-size: 14px;
  font-weight: 600;
  padding: 24px 16px;
`;

const PaymentRow = styled.div<{ selected?: boolean }>`
  display: flex;
  align-items: center;
  font-weight: ${(props) => (props.selected ? 700 : 400)};
`;

const InstallmentAmountText = styled.span<{ selected?: boolean }>`
  color: ${(props) => (props.selected ? props.theme.main.white : props.theme.main.green)};
  font-size: 18px;
  font-weight: 700;
`;

const PaymentScheduleContainer = styled.div`
  background-color: ${(props) => props.theme.main.white};
  font-size: 14px;
  padding: 16px;
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin: 2px;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
`;

const RowCount = styled.div`
  color: ${(props) => props.theme.main.green};
  font-weight: 700;
  margin-right: 9px;
`;

const DateText = styled.div`
  color: ${(props) => props.theme.main.primary};
  font-style: italic;
  font-weight: 400;
`;

export default PaymentInstallmentListItem;
