import { gql } from '@apollo/client/core';

export const GET_AUTH_INIT = gql`
  mutation getUserAuthInit($input: AuthInitInput!) {
    getUserAuthInit(input: $input) {
      code
      success
      message
      token
      organization {
        active
        flow
        id
        isDemo
        name
        slug
      }
    }
  }
`;
