import jwtDecode from 'jwt-decode';
import { USER_TYPES } from 'lib/constans';
import StorageService from 'lib/services/Storage';
import { useEffect, useState } from 'react';

const useGuestMode = () => {
  const [isGuestMode, setIsGuestMode] = useState<boolean>(false);
  const { token } = StorageService.getAuthData() || {};

  useEffect(() => {
    if (token) {
      const { type } = (jwtDecode(token as string) as { type: string }) || {};
      setIsGuestMode(type === USER_TYPES.GUEST_BORROWER);
    }
  }, [token]);

  return { isGuestMode };
};

export default useGuestMode;
