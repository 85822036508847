import { Badge } from 'lib/components';
import { useSegment } from 'lib/hooks/useSegment';
import useStore from 'lib/hooks/useStore';
import { isArray } from 'lib/utils';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useLocation } from 'react-router-dom';
import styled from 'styled-components';

interface SCNavInput {
  selected: boolean;
}

export const NavItem = ({ route, index, navItemClickHandler, isBadgeShown }): React.ReactElement => {
  const { t } = useTranslation();

  const { loanList, applicationList, borrower, documentRequests, setDocumentRequests } = useStore();
  const location = useLocation();

  const { trackSegmentEvent } = useSegment();
  const applicationBadge = applicationList?.filter((appList) => appList.status === 'APPROVED')?.length;
  const purchaseBadge = loanList.filter(
    (loan) => isArray(loan.plans)[0]?.daysPastDue > 0 || isArray(loan.plans)[0]?.status === 'CHARGED_OFF',
  )?.length;

  const badgeCounts = (pathname: string) => {
    if (pathname === '/portal/purchases') {
      return purchaseBadge;
    } else if (pathname === '/portal/approvals') {
      return applicationBadge;
    } else if (pathname === '/portal/documents') {
      return documentRequests?.length;
    } else {
      return 0;
    }
  };

  const sendTrackSegmentEvent = () => {
    trackSegmentEvent(route.eventName, {
      patientId: borrower?.id,
      phone: borrower?.phone,
    });
  };

  const handleNavigationItemClick = () => {
    sendTrackSegmentEvent();
    navItemClickHandler();
  };

  return route?.isExternal ? (
    <ExternalLink
      href={route.to}
      target="_blank"
      selected={route.to === location.pathname}
      onClick={sendTrackSegmentEvent}
    >
      <NavText selected={route.to === location.pathname}>{t(route.name)}</NavText>
      {isBadgeShown && (
        <Badge count={badgeCounts(route.to)} type={route.to.includes('approvals') ? 'primary' : 'secondary'} />
      )}{' '}
    </ExternalLink>
  ) : (
    <NavLink
      data-testid="hamburgerNavigateItem"
      key={index}
      to={route.to}
      onClick={handleNavigationItemClick}
      selected={route.to === location.pathname}
    >
      <NavText selected={route.to === location.pathname}>{t(route.name)}</NavText>
      {isBadgeShown && (
        <Badge count={badgeCounts(route.to)} type={route.to.includes('approvals') ? 'primary' : 'secondary'} />
      )}{' '}
    </NavLink>
  );
};

const NavLink = styled(Link)<SCNavInput>`
  display: flex;
  align-self: center;
  justify-content: space-between;
  text-decoration: none;
  color: ${(props) => (props.selected ? props.theme.main.green : props.theme.main.white)};
  padding: 18px 24px;
  &:hover {
    text-decoration: ${(props) => (props.selected ? 'unset' : 'underline')};
  }
`;

const ExternalLink = styled.a<SCNavInput>`
  display: flex;
  align-self: center;
  justify-content: space-between;
  text-decoration: none;
  color: ${(props) => (props.selected ? props.theme.main.green : props.theme.main.white)};
  padding: 18px 24px;

  &:hover {
    text-decoration: ${(props) => (props.selected ? 'unset' : 'underline')};
  }
`;

const NavText = styled.span<SCNavInput>`
  font-size: 18px;
  line-height: 24px;
  font-weight: ${(props) => (props.selected ? '700' : '400')};
  white-space: nowrap;
`;
