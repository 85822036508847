import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { Loading } from 'lib/components';
import { AutoPayIcon } from '../icons';
import { Body, Icon, theme } from '@frontend/cherry-library';
import { ReactComponent as WarningIcon } from 'assets/images/warning-outline.svg';
import { findPaymentMethodInfo } from 'lib/utils/FindPaymentMethodInfo';
import { Loan } from 'pages/Purchases/type';

interface StoredCard {
  borrowerId: string;
  expireMonth: number;
  expireYear: number;
  last4: string;
  network: string;
  type: string;
  status: string;
  id: string;
}

export interface UserData {
  activeLoanCount: number;
  firstName: string;
  lastName: string;
  zip: string;
  type: string;
  id: string;
  createdAt: string;
  storedCard: StoredCard;
}

interface Props {
  isAutoPayActive: boolean;
  manageHandler: () => void;
  loading: boolean;
  installmentPaymentMethodId: string;
  paymentMethods: UserData[];
  loan: Loan;
  short?: boolean;
}

export const AutoPay = ({ paymentMethods, loading, isAutoPayActive, manageHandler, loan, short = false }: Props) => {
  const { t: translate } = useTranslation();
  const paymentMethodInfo = findPaymentMethodInfo({
    targetId: Number(loan?.installmentPaymentMethodId),
    paymentMethods: paymentMethods,
    short: true,
  });
  const autoPayActiveText = isAutoPayActive ? paymentMethodInfo : translate('autopay.enableNow');
  const autoPayShortText = isAutoPayActive ? translate('common.disable') : '';
  const dash = short ? '' : '-';

  const activePaymentText = short ? autoPayShortText : autoPayActiveText;

  if (loading) {
    return <Loading transparent={true} />;
  }

  return (
    <AutoPayContainer autopay={isAutoPayActive} short={short}>
      <AutoPlayContentContainer>
        {isAutoPayActive ? (
          <AutoPayIcon color={theme.main.kellyGreen} />
        ) : (
          <Icon height={16} width={16} color={theme.main.cherryRed} src={WarningIcon} />
        )}

        <AutoPayContent color={isAutoPayActive ? theme.main.kellyGreen : theme.main.cherryRed}>
          {isAutoPayActive
            ? `${translate('contracts.pending.details.active')} ${dash}`
            : `${translate('contracts.pending.details.notActive')} ${dash} `}
        </AutoPayContent>

        <ManageAutopay size="small" onClick={manageHandler} fontWeight={700}>
          {activePaymentText}
        </ManageAutopay>
      </AutoPlayContentContainer>
    </AutoPayContainer>
  );
};

const AutoPayContainer = styled.div<{ autopay: boolean; short: boolean }>`
  display: flex;
  padding: 8px 16px;
  flex-direction: column;
  align-items: center;
  border-radius: 40px;
  margin: ${(props) => `${props.short ? '0' : '24px 0'}`};
  border: ${(props) => `1px solid ${props.autopay ? theme.main.kellyGreen : theme.main.cherryRed}`};
  background: ${(props) => `${props.autopay ? theme.main.kellyGreen5 : theme.main.cherryRed10}`};
  min-width: 240px;
  width: auto;
  align-items: center;
  justify-content: center;
`;
const AutoPlayContentContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const ManageAutopay = styled(Body)`
  cursor: pointer;
  text-decoration: underline;
  font-size: 12px !important;
`;

const AutoPayContent = styled(Body)`
  font-size: 13px !important;
  margin: 0 4px 0 8px !important;
`;
