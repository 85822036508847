import React from 'react';

export interface HeaderInput {
  secondary?: boolean;
  textLength: number;
}

export interface ExpireInput {
  expired: boolean;
}

interface Organization {
  slug: string;
  name: string;
  website: string;
  phone: string;
  id: string;
  code: string;
}

export interface Address {
  id: string;
  createdAt: string;
  street: string;
  zip: string;
  city: string;
  state: {
    name: string;
    code: string;
  };
}

export interface Communication {
  phone: string;
  website: string;
}

export interface Locations {
  name: string;
  id: string;
  address: Address;
  communicationSettings: Communication;
}

interface AddressInfo {
  id: string;
  createdAt: string;
  street: string;
  zip: string;
  city: string;
  state: {
    name: string;
    code: string;
  };
}

interface LatestCLI {
  amount: {
    requested: number;
  };
  status: string;
}

interface Features {
  idFeature: string;
  status: string;
  type: string;
}

export enum CreditLineIncrease {
  ELIGIBLE = 'ELIGIBLE',
  INELIGIBLE = 'INELIGIBLE',
}

export interface Approval {
  id: string;
  merchantId: string;
  organizationId: string;
  organization: Organization;
  locations: [Locations];
  balanceAvailable: number;
  expireAt: string;
  creditLineIncrease: CreditLineIncrease;
  addressInfo: AddressInfo;
  latestCLI: LatestCLI;
  maxEligible: number;
  approvalExtended: boolean;
  features: [Features];
  purchaseAmount: number;
  status: string;
  displayId: string;
}

export interface ApplicationList {
  type: string;
  children: React.ReactNode;
  title: string;
  status?: string;
}

export const applicationStatus = [
  'APPROVED',
  'INCOME_VERIFICATION',
  'AWAITING_KYC',
  'AWAITING_DOCS',
  'AWAITING_FRAUD',
  'AWAITING_DEFAULT_HIGH_LINE',
];
