import { memo } from 'react';
import styled from 'styled-components';

interface IProps {
  title: string;
}

export const HeaderTitle = memo(({ title }: IProps) => {
  return <>{title && <Title data-testid="paymentHeaderTitle">{title}</Title>}</>;
});

const Title = styled.div`
  font-size: 20px;
  font-weight: 700;
  line-height: 27px;
  margin-bottom: 24px;
  font-family: ${(props) => props.theme.fonts.primary};
`;
