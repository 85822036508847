import { gql } from '@apollo/client/core';

export const GET_MEMBERSHIPS_PLANS = gql`
  query fetchCustomerMembershipsPlans($input: FetchCustomerMembershipsPlanInput) {
    fetchCustomerMembershipsPlans(input: $input) {
      error
      status
      total
      contents {
        id
        paymentMethodId
        customer {
          id
          firstName
          lastName
        }
        plan {
          id
          name
        }
        organizationId
        organization {
          name
        }
        status
        price
        period
        firstPayment
        autoRenew
        billingTerm
        createdAt
      }
    }
  }
`;

export const GET_MEMBERSHIPS_PLAN_DETAIL = gql`
  query fetchCustomerMembershipsPlanDetail($input: FetchCustomerMembershipsPlanDetailInput) {
    fetchCustomerMembershipsPlanDetail(input: $input) {
      id
      customer {
        firstName
        lastName
      }
      organization {
        name
      }
      paymentMethodId
      plan {
        name
        description
        detailedDescription
        status
      }
      status
      price
      period
      firstPayment
      autoRenew
      billingTerm
      paymentFrequency
      createdAt
      customerBenefits {
        id
        name
        type
        frequency
        quantity
        usage
        updatedAt
      }
      paymentMethod {
        last4
      }
    }
  }
`;
