import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { Button, Body, theme } from '@frontend/cherry-library';
import useGuestMode from 'lib/hooks/useGuestMode';

export const NoCard = ({ addNewPaymentMethod }) => {
  const { t: translate } = useTranslation();
  const { isGuestMode } = useGuestMode();

  return (
    <Container>
      <InfoContainer>
        <Body color={theme.main.midnightBlue}>{translate('account.payment.detail.noCard')}</Body>
      </InfoContainer>
      <Button fullWidth onClick={addNewPaymentMethod} disabled={isGuestMode}>
        {translate('account.payment.form.info')}
      </Button>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
`;

const InfoContainer = styled.div`
  display: flex;
  padding: 40px 0;
  align-items: center;
  justify-content: center;
  gap: 8px;
`;
