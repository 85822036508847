import { gql } from '@apollo/client/core';

export const FETCH_BANNER = gql`
  query fetchBanner {
    fetchBanner {
      showForPatient
      patientText
    }
  }
`;

export const FETCH_ORGANIZATION_LOCATIONS = gql`
  query getOrganizationLocations($input: GetOrganizationLocationsInput) {
    getOrganizationLocations(input: $input) {
      contents {
        id
        name
        address {
          id
          createdAt
          street
          zip
          city
          state {
            name
            code
          }
        }
        roles
        permissions
        status
        communicationSettings {
          phone
          website
        }
      }
    }
  }
`;
