import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import styled from 'styled-components';
import { findPaymentMethodInfo } from 'lib/utils/FindPaymentMethodInfo';
import { Container, Loading } from 'lib/components';
import { useCheckoutLoanList, useGetDebtSaleBuyers, useLoanList } from 'lib/hooks/useContract';
import useStore from 'lib/hooks/useStore';
import {
  findNextCommitmentDate,
  findNextDueDate,
  findNextScheduledDate,
  FormatAmount,
  isArray,
  useFormatDate,
} from 'lib/utils';
import { useSegment } from 'lib/hooks/useSegment';
import { ReactComponent as TickIcon } from 'assets/images/tick.svg';
import { LoanPlans } from './type';
import { CardContainer } from 'lib/components/CardContainer';
import { Body, Button, Heading, Icon, theme } from '@frontend/cherry-library';
import { FindPracticeCard } from 'lib/components/FindPracticeCard';
import { RoundedContainer } from 'lib/components/RoundedContainer';
import { ListItem } from 'lib/components/ListItem';
import useGetPaymentMethodsIfNotExists from 'lib/hooks/usePaymentMethods';
import { LoanPlanStatuses, LoanPlanSubStatuses, LoanStatuses, SEGMENT_EVENT_NAMES } from 'lib/constans';
import {
  getActiveLoans,
  getAwaitingLoans,
  getChargedOffLoans,
  getClosedLoans,
  getSoldLoans,
} from 'lib/utils/FindLoanTypes';
import { useSetExchangeToken } from 'lib/hooks/useExchangeToken';
import { ExternalRedirect } from 'lib/utils/ExternalRedirect';
import { PREQUAL_URL } from 'config';
import { calculateAmountDue } from 'lib/utils/calculateAmountDue';
import { getSegmentEventBorrowerData } from 'lib/utils/SegmentEventData';

const Contracts = () => {
  const { trackSegmentEvent } = useSegment();
  const { FormatDateDayMonth } = useFormatDate();
  const { paymentMethods } = useStore();
  const { t: translate } = useTranslation();
  const navigate = useNavigate();
  const { trackPage } = useSegment();
  const { loanList, checkoutLoanList, setHeaderTitle, setPaymentType, setAmountOfPayment, borrower } = useStore();
  const [fetchLoanList, { loading }] = useLoanList('FUNDED,REFUNDED,AWAITING_FUNDING');
  const [fetchCheckoutLoanList, { loading: checkoutLoding }] = useCheckoutLoanList();
  const [fetchDebtSaleBuyers] = useGetDebtSaleBuyers();
  const activeLoans = getActiveLoans(loanList);
  const chargedOffLoans = getChargedOffLoans(loanList);
  const closedLoans = getClosedLoans(loanList);
  const soldLoans = getSoldLoans(loanList);
  const awaitingLoans = getAwaitingLoans(loanList);
  const [setExchangeToken, { data: exchangeData, loading: exhangeTokenLoading }] = useSetExchangeToken();
  const showActiveCards = activeLoans?.length > 0 || awaitingLoans?.length > 0;
  const segmentEventBorrowerData = getSegmentEventBorrowerData(borrower);

  useEffect(() => {
    fetchLoanList();
  }, []);

  useEffect(() => {
    if (exchangeData && exchangeData.setExchangeToken && exchangeData.setExchangeToken.exchangeTokenId) {
      ExternalRedirect(`${PREQUAL_URL}/${exchangeData.setExchangeToken.exchangeTokenId}`);
    }
  }, [exchangeData]);

  const checkoutHandler = async (loan) => {
    setExchangeToken({
      variables: {
        input: {
          target: 'SELF_CHECKOUT',
          payload: {
            organizationId: loan.merchant?.organization?.id,
            merchantId: loan.merchant?.id,
            loanId: loan.id,
          },
        },
      },
    });
  };
  const goToPurchasePage = (loanId) => {
    const foundLoan = loanList.find((loan) => loan.id === loanId);
    const { id, status, plans, organization, schedules } = foundLoan;
    trackSegmentEvent(SEGMENT_EVENT_NAMES.PATIENT_PORTAL.PAYMENT_PLAN_OVERVIEW.PAYMENT_PLAN_CLICKED, {
      ...segmentEventBorrowerData,
      loanId: id,
      loanStatus: status,
      loanOutstandingBalance: plans[0]?.balance,
      practiceId: organization?.id,
    });
    navigate(`/portal/${id}/purchase`);
  };

  useGetPaymentMethodsIfNotExists();

  const calculateInstallmentAmount = (plans: LoanPlans[], originalClosedAt: string) => {
    const isPaymentPastDue = isArray(plans)?.[0]?.daysPastDue > 0;

    const { amountDue, daysPastDue, payOff } = isArray(plans)[0] || {};

    const amountDueValue = calculateAmountDue({ daysPastDue, originalClosedAt, amountDue, payOff });

    return isPaymentPastDue ? amountDueValue : 0;
  };

  const getTotalAmount = (plans: LoanPlans[], originalClosedAt: string) => {
    const installmentAmount = calculateInstallmentAmount(plans, originalClosedAt);
    const { dueFees } = plans?.[0] || {};

    const dueFeesAmount = installmentAmount ? installmentAmount + dueFees : dueFees;

    return dueFees ? dueFeesAmount : installmentAmount;
  };

  const isChargedOff = (plans) => {
    return plans?.[0]?.status === 'CHARGED_OFF';
  };

  useEffect(() => {
    setHeaderTitle('menu.listItems.loans');
  }, [setHeaderTitle]);

  useEffect(() => {
    window.scrollTo(0, 0);
    fetchCheckoutLoanList();
    fetchDebtSaleBuyers();
    trackPage(SEGMENT_EVENT_NAMES.PATIENT_PORTAL.PAYMENT_PLAN_OVERVIEW.PAGE_LOAD, segmentEventBorrowerData);
    setPaymentType('');
    setAmountOfPayment(0);
  }, []);

  if (loading || checkoutLoding) {
    return <Loading transparent={true} />;
  }

  return (
    <>
      {!(showActiveCards || checkoutLoanList?.length || closedLoans?.length || chargedOffLoans?.length) && (
        <Container block={true}>
          <CardContainer minHeight="128px">
            <NoData>{translate('paymentPlans.noPaymentPlan')}</NoData>
          </CardContainer>
        </Container>
      )}
      {!(showActiveCards || checkoutLoanList?.length || closedLoans?.length) && (
        <FindPracticeCard isPaymentPlanOverview />
      )}
      {chargedOffLoans?.length > 0 && (
        <RoundedContainer
          title={translate('paymentPlans.types.chargedOff')}
          noData={false}
          noDataText=""
          headingColor={theme.main.alertWarning}
          headingTextColor={theme.main.midnightBlue}
        >
          {chargedOffLoans?.map((content, index) => {
            return (
              <ListItem
                key={content?.id}
                onClick={() => {
                  goToPurchasePage(content?.id);
                }}
                last={index === chargedOffLoans?.length - 1}
              >
                <LoanHeader level="4" text={`${FormatAmount(content?.plans[0]?.netChargeOff)}`} />
                <Body color={theme.main.midnightBlue} fontWeight={400}>
                  {content?.merchant?.name}
                </Body>
                <Uppercase size="small" fontWeight={600}>
                  {translate('upcomingPayments.planId')}: {content?.displayId}
                </Uppercase>
                <Body color={theme.main.midnightBlue} fontWeight={400} size="small">
                  {translate('paymentPlans.chargedOff.explanation')}
                </Body>

                <FlexContainer>
                  <Icon color={theme.main.kellyGreen} height={16} width={16} src={TickIcon} />
                  <Italic fontWeight={400} size="small">
                    {translate('paymentPlans.chargedOff.helperText')}
                  </Italic>
                </FlexContainer>
              </ListItem>
            );
          })}
        </RoundedContainer>
      )}
      {checkoutLoanList?.length > 0 && (
        <RoundedContainer title={translate('paymentPlans.types.incompleteCheckout')} noData={false} noDataText="">
          {checkoutLoanList?.map((content, index) => {
            return (
              <ListItem
                onClick={undefined}
                key={content?.id}
                unclickable={true}
                last={index === checkoutLoanList?.length - 1}
              >
                <CheckoutContainer>
                  <CheckoutTextContainer>
                    <Uppercase color={theme.main.midnightBlue50} fontWeight={600} size="small">
                      {translate('paymentPlans.unfinishedCheckout.pendingText')}
                    </Uppercase>
                    <LoanHeader level="4" text={`${FormatAmount(content?.purchaseAmount)}`} />
                    <Body color={theme.main.midnightBlue} fontWeight={400}>
                      {content?.merchant?.name}
                    </Body>
                    <Uppercase size="small" fontWeight={600}>
                      {translate('upcomingPayments.planId')}: {content?.displayId}
                    </Uppercase>
                    <CheckoutText color={theme.main.midnightBlue} fontWeight={400} size="small">
                      {translate('paymentPlans.unfinishedCheckout.explanation')}
                    </CheckoutText>
                  </CheckoutTextContainer>
                  <FinishButton
                    data-testid="finish-checkout-button"
                    onClick={() => checkoutHandler(content)}
                    disabled={exhangeTokenLoading}
                    size={'small'}
                    fullWidth={false}
                  >
                    {translate('paymentPlans.unfinishedCheckout.finishButton')}
                  </FinishButton>
                </CheckoutContainer>
              </ListItem>
            );
          })}
        </RoundedContainer>
      )}
      {showActiveCards && (
        <RoundedContainer title={translate('paymentPlans.types.active')} noData={false} noDataText="">
          {activeLoans?.map((content, index) => {
            const nextDuePayment = findNextDueDate(content?.schedules);
            const nextCommitmentPlan = findNextCommitmentDate(content?.commitments);
            const isAutoPayActive = content?.autoPay;
            const nextScheduledPlan = findNextScheduledDate(nextDuePayment, nextCommitmentPlan, isAutoPayActive);

            const remaining = content?.plans[0]?.payOff + content?.plans[0]?.dueFees;

            return (
              <ListItem
                key={content?.id}
                onClick={() => {
                  goToPurchasePage(content?.id);
                }}
                last={index === activeLoans?.length - 1 && awaitingLoans.length === 0}
              >
                {calculateInstallmentAmount(content?.plans, content?.originalClosedAt) !== 0 && (
                  <Uppercase color={theme.main.cherryRed} fontWeight={600} size="small">
                    {translate('paymentPlans.active.overdue', {
                      amount: FormatAmount(getTotalAmount(content?.plans, content?.originalClosedAt)),
                      day: isArray(content?.plans)?.[0]?.daysPastDue,
                      plural: isArray(content?.plans)?.[0]?.daysPastDue !== 1 ? 's' : '',
                    })}
                  </Uppercase>
                )}
                <Header
                  level="4"
                  text={translate('paymentPlans.active.remaining', {
                    amount:
                      FormatAmount(isChargedOff(content?.plans) ? content?.plans?.[0]?.netChargeOff : remaining) || 0,
                  })}
                />
                <Body color={theme.main.midnightBlue} fontWeight={400}>
                  {content?.merchant?.name}
                </Body>
                <Body color={theme.main.midnightBlue} fontWeight={400} size="small">
                  {content?.schedules?.[0]?.dueAt
                    ? translate('paymentPlans.active.nextPayment', {
                        amount: FormatAmount(nextScheduledPlan?.amount),
                        date: FormatDateDayMonth(nextScheduledPlan?.scheduledDate),
                      })
                    : translate('paymentPlans.active.nextPaymentWithoutDate', {
                        amount: FormatAmount(nextScheduledPlan?.amount),
                      })}
                </Body>
                <Italic color={theme.main.midnightBlue50} fontWeight={400} size="small">
                  {content?.autoPay
                    ? findPaymentMethodInfo({
                        targetId: Number(content?.installmentPaymentMethodId),
                        paymentMethods: paymentMethods,
                      })
                    : translate('paymentPlans.active.autopay')}
                </Italic>
                <Uppercase size="small" fontWeight={600}>
                  {translate('upcomingPayments.planId')}: {content?.displayId}
                </Uppercase>
              </ListItem>
            );
          })}
          {awaitingLoans?.map((content, index) => {
            return (
              <ListItem
                key={content?.id}
                onClick={() => {
                  goToPurchasePage(content?.id);
                }}
                last={index === awaitingLoans?.length - 1}
              >
                <Body color={theme.main.midnightBlue} fontWeight={400} size="small">
                  {translate('paymentPlans.awaitingFunding.header')}
                </Body>
                <LoanHeader level="4" text={`${FormatAmount(content?.grossAmount)}`} />
                <Body color={theme.main.midnightBlue} fontWeight={400}>
                  {content?.merchant?.name}
                </Body>
                <Uppercase size="small" fontWeight={600}>
                  {translate('upcomingPayments.planId')}: {content?.displayId}
                </Uppercase>
              </ListItem>
            );
          })}
        </RoundedContainer>
      )}
      {(closedLoans?.length > 0 || soldLoans?.length > 0) && (
        <RoundedContainer title={translate('paymentPlans.types.completed')} noData={false} noDataText="">
          {closedLoans?.map((content, index) => {
            return (
              <ListItem
                key={content?.id}
                onClick={() => {
                  goToPurchasePage(content?.id);
                }}
                last={index === closedLoans?.length - 1}
              >
                <Uppercase color={theme.main.kellyGreen} fontWeight={600}>
                  {isArray(content?.plans)[0]?.status === LoanPlanStatuses.REFUND &&
                    isArray(content?.plans)[0]?.subStatus === LoanPlanStatuses.REFUND &&
                    translate('paymentPlans.completed.fullRefund')}
                  {isArray(content?.plans)[0]?.status === LoanPlanStatuses.CLOSED &&
                    translate('paymentPlans.completed.paidFull')}
                  {isArray(content?.plans)[0]?.status === LoanPlanStatuses.SETTLED &&
                    translate('paymentPlans.completed.settledFull')}
                </Uppercase>
                <Uppercase color={theme.main.midnightBlue50} fontWeight={600}>
                  {isArray(content?.plans)[0]?.status === LoanPlanStatuses.CHARGED_OFF &&
                    content?.fraud &&
                    content?.deceased &&
                    translate('paymentPlans.completed.chargeOffClosed')}
                  {isArray(content?.plans)[0]?.status === LoanPlanStatuses.BANKRUPTCY &&
                    translate('paymentPlans.completed.bankruptcy')}
                </Uppercase>

                <LoanHeader
                  level="4"
                  text={`${FormatAmount(
                    content?.status === LoanStatuses.REFUNDED ? content?.purchaseAmount : content?.grossAmount,
                  )}`}
                />
                <Body color={theme.main.midnightBlue} fontWeight={400}>
                  {content?.merchant?.name}
                </Body>
                <Uppercase size="small" fontWeight={600}>
                  {translate('upcomingPayments.planId')}: {content?.displayId}
                </Uppercase>
                <Italic color={theme.main.midnightBlue} fontWeight={400} size="small">
                  {isArray(content?.plans)[0]?.status === LoanPlanStatuses.CHARGED_OFF &&
                    isArray(content?.plans)[0]?.subStatus === LoanPlanSubStatuses.DECEASED &&
                    isArray(content?.plans)[0]?.subStatus === LoanPlanSubStatuses.FRAUD &&
                    translate('paymentPlans.completed.chargeOffClosedExplanation')}
                  {isArray(content?.plans)[0]?.status === LoanPlanStatuses.BANKRUPTCY &&
                    translate('paymentPlans.completed.bankruptcyExplanation')}
                </Italic>
              </ListItem>
            );
          })}
          {soldLoans?.map((content, index) => {
            let amountLabel = '';
            let soldInfo = '';
            if (Array.isArray(content?.plans) && content.plans[0]?.daysPastDue > 0) {
              amountLabel = translate('contracts.sold.amountLabel');
              soldInfo = translate('contracts.sold.soldInfo');
            }
            return (
              <ListItem
                key={content?.id}
                onClick={() => {
                  goToPurchasePage(content?.id);
                }}
                last={index === soldLoans?.length - 1}
              >
                <Uppercase size="small" fontWeight={600}>
                  {translate('upcomingPayments.planId')}: {content?.displayId}
                </Uppercase>
                <Uppercase color={theme.main.midnightBlue50} fontWeight={600} size="small">
                  {amountLabel}
                </Uppercase>

                <LoanHeader
                  level="4"
                  text={`${FormatAmount(
                    content?.status === 'REFUNDED' ? content?.purchaseAmount : content?.grossAmount,
                  )}`}
                />
                <Body color={theme.main.midnightBlue} fontWeight={400}>
                  {content?.merchant?.name}
                </Body>
                <Italic color={theme.main.midnightBlue} fontWeight={400} size="small">
                  {soldInfo}
                </Italic>
              </ListItem>
            );
          })}
        </RoundedContainer>
      )}
    </>
  );
};

const NoData = styled(Body)`
  z-index: 1;
  padding: 48px 16px;
  align-self: center;
`;

const Italic = styled(Body)`
  font-style: italic !important;
`;

const CheckoutText = styled(Body)`
  font-style: italic !important;
  padding-top: 8px !important;
`;

const Uppercase = styled(Body)`
  text-transform: uppercase;
  font-size: 12px;
`;

const FinishButton = styled(Button)`
  width: 25% !important;
  height: 40px;
  align-self: center !important;
`;

const LoanHeader = styled(Heading)`
  color: ${theme.main.midnightBlue} !important;
`;

const Header = styled(Heading)`
  color: ${theme.main.midnightBlue} !important;
  margin: 0;
`;

const FlexContainer = styled.div`
  display: flex;
  margin-top: 8px;
`;
const CheckoutTextContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const CheckoutContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 8px;
`;

export default Contracts;
