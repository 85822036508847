import { gql } from '@apollo/client/core';

export const GET_LOAN_LIST = gql`
  query FetchLoanList($input: LoanListingRequest) {
    fetchLoanList(input: $input) {
      success
      code
      message
      total
      contents {
        amount
        transactionType
        applicationId
        autoPay
        borrowerId
        createdAt
        createdBy
        displayId
        downPaymentAmount
        grossAmount
        refundAmount
        id
        originalClosedAt
        installmentAmount
        installmentPaymentMethodId
        downPaymentPaymentMethodId
        productId
        purchaseAmount
        status
        totalAmount
        updatedAt
        updatedBy
        promoUsed
        fraud
        deceased
        scheduledPayments {
          amount
          dueAt
          id
          installmentNumber
          loanId
          loanPlanId
          interest
          principal
          fees
          discount
          endingBalance
        }
        borrower {
          createdAt
          email
          firstName
          id
          language
          lastName
          phone
          updatedAt
        }
        plans {
          amountDue
          apr
          balance
          borrowerId
          closedAt
          createdAt
          dateLastCurrent
          daysPastDue
          dueInterest
          duePrincipal
          dueFees
          id
          loanId
          maxDaysPastDue
          netChargeOff
          payOff
          status
          subStatus
          term
          updatedAt
        }
        merchant {
          id
          name
          organization {
            slug
            id
          }
        }
        servicing {
          dueDateChangeCount
          loanId
          status
        }
        schedules {
          amount
          attempt
          borrowerId
          createdAt
          dueAt
          id
          loanId
          status
          updatedAt
          doNotAttempt
        }
        amortizations {
          date
          id
          fee
          interest
          principal
          total
          type
        }
        statements {
          amount
          borrowerId
          createdAt
          discount
          fees
          id
          interest
          loanId
          principal
          principalBalance
          servicingTitle
          type
          updatedAt
          chargeOff
          charges {
            amount
            chargeApplicationType
            info
          }
        }
        getChargedOffOffers {
          daysSinceChargeOff
          discountedChargedOffAmount
          maxTermSettlement
          maxTermPaymentPlan
          discountPercentage
        }

        product {
          apr
          term
          promoApr
        }
        commitments {
          amount
          progress
          scheduledDate
          status
          createdAt
          subType
          type
        }
        debtSales {
          debtSaleBuyerId
          amount
          soldDate
          status
        }
      }
    }
  }
`;

export const GET_CHECKOUT_LOAN_LIST = gql`
  query FetchLoanList($input: LoanListingRequest) {
    fetchLoanList(input: $input) {
      success
      code
      message
      total
      contents {
        id
        transactionType
        purchaseAmount
        merchant {
          id
          name
          organization {
            slug
            id
          }
        }
      }
    }
  }
`;

export const GET_DEBT_SALE_BUYERS = gql`
  query FetchDebtSaleBuyers {
    fetchDebtSaleBuyers {
      id
      name
      email
      phone
      address
      address2
      city
      state
      zip
    }
  }
`;
