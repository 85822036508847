import React from 'react';

export enum ScreenState {
  SELECTION = 'SELECTION',
  APPOINTMENT = 'APPOINTMENT',
  APPROVAL_APPOINTMENT = 'APPROVAL_APPOINTMENT',
  REMINDER = 'REMINDER',
  EXTEND_SUCCESS = 'EXTEND_SUCCESS',
}

export interface HeaderInput {
  secondary?: boolean;
  textLength: number;
}

export interface ExpireInput {
  expired: boolean;
}

interface Organization {
  slug: string;
  name: string;
  website: string;
  phone: string;
}

interface Locations {
  name: string;
  id: string;
}

interface AddressInfo {
  address1: string;
  zip: string;
  city: {
    name: string;
    state: {
      code: string;
    };
  };
}

interface LatestCLI {
  amount: {
    requested: number;
  };
  status: string;
}

export interface ApplicationList {
  type: string;
  children: React.ReactNode;
}

interface Features {
  idFeature: string;
  status: string;
  type: string;
}

export enum CreditLineIncrease {
  ELIGIBLE = 'ELIGIBLE',
  INELIGIBLE = 'INELIGIBLE',
}

export interface Approval {
  id: string;
  merchantId: string;
  organizationId: string;
  organization: Organization;
  locations: [Locations];
  balanceAvailable: number;
  expireAt: string;
  creditLineIncrease: CreditLineIncrease;
  purchaseAmount: number;
  addressInfo: AddressInfo;
  latestCLI: LatestCLI;
  approvalExtended: boolean;
  features: [Features];
  status: string;
}
