import { useEffect, useMemo, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { Button, Icon, RadioContent, RadioGroup, Subtitle } from 'lib/components';
import { FormatAmount } from 'lib/utils';
import { Loan } from 'pages/Purchases/type';
import { useSegment } from 'lib/hooks/useSegment';
import { SEGMENT_EVENT_NAMES } from 'lib/constans';
import { Body, theme } from '@frontend/cherry-library';
import { getSegmentEventDetailsData } from 'lib/utils/SegmentEventData';
import useStore from 'lib/hooks/useStore';

export enum PaymentTypes {
  OneTimePayment = 'ONE_TIME_PAYMENT',
  MonthlyPaymentPlan = 'MONTHLY_PAYMENT_PLAN',
  Settlement = 'SETTLEMENT',
}

enum CommitmentTypes {
  OneTimePayment = 'One-Time Payment',
  PaymentPlan = 'Payment Plan',
  Settlement = 'Settlement',
}

type Option = {
  title: string;
  content: string;
  badge?: string;
  badgePosition?: 'top' | 'right';
  type: PaymentTypes;
};

type ChargeOffPaymentOptionsProps = {
  outstandingBalance: number;
  hideSettlementOption?: boolean;
  onContinue: (selectedPaymentType: PaymentTypes) => void;
  loan: Loan;
};

export const ChargeOffPaymentOptions = ({
  loan,
  outstandingBalance,
  hideSettlementOption = false,
  onContinue,
}: ChargeOffPaymentOptionsProps) => {
  const { t } = useTranslation();
  const { trackSegmentEvent } = useSegment();
  const { borrower } = useStore();

  const [selectedOption, setSelectedOption] = useState<string>();
  const segmentEventPaymentPlanDetailsData = getSegmentEventDetailsData(borrower, loan);

  const options: Option[] = useMemo(() => {
    const optionList: Option[] = [];

    optionList.push({
      title: t('chargeOffBanner.options.oneTimePayment.title'),
      content: t('chargeOffBanner.options.oneTimePayment.content'),
      badge: t('chargedOffBanner.options.oneTimePayment.badge'),
      badgePosition: 'top',
      type: PaymentTypes.OneTimePayment,
    });

    if (outstandingBalance >= 100) {
      optionList.push({
        title: t('chargeOffBanner.options.monthlyPaymentPlan.title'),
        content: t('chargeOffBanner.options.monthlyPaymentPlan.content'),
        badge: t('chargeOffBanner.options.monthlyPaymentPlan.badge', {
          amount: FormatAmount(
            Math.round(loan?.plans?.[0]?.netChargeOff / loan?.getChargedOffOffers?.maxTermPaymentPlan),
            0,
          ),
        }),
        badgePosition: 'top',
        type: PaymentTypes.MonthlyPaymentPlan,
      });

      optionList.push({
        title: t('chargeOffBanner.options.settlement.title'),
        content: t('chargeOffBanner.options.settlement.content'),
        badge: t('chargeOffBanner.options.settlement.badge', {
          amount: FormatAmount(
            Math.round(loan?.plans?.[0]?.netChargeOff - loan?.getChargedOffOffers?.discountedChargedOffAmount),
            0,
          ),
          percentage: loan?.getChargedOffOffers?.discountPercentage?.toFixed(0),
        }),
        badgePosition: 'top',
        type: PaymentTypes.Settlement,
      });
    }

    return optionList;
  }, [outstandingBalance, loan]);

  useEffect(() => {
    trackSegmentEvent(SEGMENT_EVENT_NAMES.PATIENT_PORTAL.REPAYMENT_SELECTION.PAGE_LOAD);
  }, []);

  useEffect(() => {
    if (selectedOption && options?.[selectedOption]?.type === PaymentTypes.OneTimePayment) {
      trackSegmentEvent(SEGMENT_EVENT_NAMES.PATIENT_PORTAL.REPAYMENT_SELECTION.ONE_TIME_PAYMENT_CLICKED);
    } else if (selectedOption && options?.[selectedOption]?.type === PaymentTypes.MonthlyPaymentPlan) {
      trackSegmentEvent(SEGMENT_EVENT_NAMES.PATIENT_PORTAL.REPAYMENT_SELECTION.PAYMENT_PLAN_CLICKED);
    } else if (selectedOption && options?.[selectedOption]?.type === PaymentTypes.Settlement) {
      trackSegmentEvent(SEGMENT_EVENT_NAMES.PATIENT_PORTAL.REPAYMENT_SELECTION.SETTLEMENT_OFFER_CLICKED);
    }
  }, [selectedOption, options]);
  const continueButtonText = selectedOption ? t('genericButton.continue') : t('genericButton.makeASelectionToContinue');
  const optionsToRender = hideSettlementOption ? options.slice(0, -1) : options;

  const handleContinue = () => {
    if (onContinue && selectedOption) {
      trackSegmentEvent(SEGMENT_EVENT_NAMES.PATIENT_PORTAL.REPAYMENT_SELECTION.CONTINUE_CLICKED, {
        repaymentSelection: options?.[selectedOption]?.type,
        loanId: loan?.id,
      });
      trackSegmentEvent(
        SEGMENT_EVENT_NAMES.PATIENT_PORTAL.PAYMENT_PLAN_DETAILS.CONTINUE_TO_CHARGEOFF_PAYMENT_CLICKED,
        segmentEventPaymentPlanDetailsData,
      );
      onContinue(options?.[selectedOption].type);
    }
  };

  const changeSelectedOption = (index: string) => {
    setSelectedOption(index);
    let commitmentType = '';

    if (options?.[index]?.type === PaymentTypes.OneTimePayment) {
      commitmentType = CommitmentTypes.OneTimePayment;
    } else if (options?.[index]?.type === PaymentTypes.MonthlyPaymentPlan) {
      commitmentType = CommitmentTypes.PaymentPlan;
    } else if (options?.[index]?.type === PaymentTypes.Settlement) {
      commitmentType = CommitmentTypes.Settlement;
    }

    trackSegmentEvent(SEGMENT_EVENT_NAMES.PATIENT_PORTAL.PAYMENT_PLAN_DETAILS.CHARGEOFF_REPAYMENT_TYPE_SELECTED, {
      commitmentType,
      ...segmentEventPaymentPlanDetailsData,
    });
  };

  const faqSegmentEventHandler = () => {
    trackSegmentEvent(
      SEGMENT_EVENT_NAMES.PATIENT_PORTAL.PAYMENT_PLAN_DETAILS.CHARGEOFF_REPAYMENT_SUMMARY_CONTACT_SUPPORT_CLICKED,
      segmentEventPaymentPlanDetailsData,
    );
  };

  return (
    <Container>
      <Headline>
        <Icon src="check" />
        <HeadlineText>
          {t('chargeOffBanner.optionList.title', { outstandingBalance: FormatAmount(outstandingBalance) })}
        </HeadlineText>
      </Headline>
      <Paragraph>
        <Trans i18nKey="chargeOffBanner.options.information" components={{ strong: <b /> }} />
      </Paragraph>

      <UnderlinedLink
        onClick={faqSegmentEventHandler}
        href="https://withcherry.com/patient-faq/#payments-faqs"
        target="_blank"
      >
        {t('general.learnMore')}
      </UnderlinedLink>
      <Row>
        <Badge>
          {t('general.saveAmount', {
            amount: '2.99%',
          })}
        </Badge>
        <Paragraph>{t('chargeOffBanner.optionList.subtitle')}</Paragraph>
      </Row>
      <RadioGroup
        options={optionsToRender.map((option, index) => ({
          value: index.toString(),
          content: <RadioContent {...option} selected={index === Number(selectedOption)} />,
        }))}
        onChange={changeSelectedOption}
        gapDistance="high"
      />
      <ButtonContainer>
        <Button disabled={!selectedOption} onClick={handleContinue}>
          {continueButtonText}
        </Button>
      </ButtonContainer>
    </Container>
  );
};

const Container = styled.div`
  padding: 16px;
  border-radius: 4px;
  border: 1px solid ${theme.main.midnightBlue20};
  background: ${theme.main.white};
  color: ${theme.main.midnightBlue};
`;

const Headline = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  margin-bottom: 16px;
`;

const HeadlineText = styled(Subtitle)`
  font-size: 16px;
  font-weight: 700;
  color: ${theme.main.midnightBlue};
`;

const Paragraph = styled(Body)`
  margin: 0 0 16px !important;
`;

const ButtonContainer = styled.div`
  margin-top: 16px;

  button {
    margin: 0;
    padding: 16px;
    font-size: 18px;
  }
`;

const Badge = styled.div`
  display: inline-block;
  padding: 4px 8px;
  border-radius: 16px;
  background-color: ${theme.main.midnightBlue};
  color: ${theme.main.white};
  font-size: 12px;
  font-weight: 400;
  letter-spacing: 0.6px;
  text-transform: uppercase;
  min-width: 80px;
  text-align: center;
`;

const UnderlinedLink = styled.a`
  display: block;
  color: ${theme.main.midnightBlue};
  font-size: 14px;
  font-weight: 700;
  text-decoration: underline;
`;

const Row = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  margin: 16px 0;

  p {
    margin: 0 !important;
  }
`;
