import React from 'react';
import { useNavigate } from 'react-router-dom';
import useStore from 'lib/hooks/useStore';
import {
  isContractLateOrChargedOff,
  isContractAwaitingFunding,
  isContractIncompleteButValid,
  isValidIncomeOrSubprimeApplication,
  denialStatusLessThan24Hours,
  isValidApprovalWithNotFundedOrAwaitingContract,
  isValidApprovalWİthFundedOrAwaitingContract,
  isActiveContract,
  isClosedContract,
  isApprovalBoost,
} from 'lib/utils/LandingPageRules';
import { APPROVAL_STATUS, PURCHASES_STATUS } from 'lib/constans';
import { useApolloClient } from '@apollo/client';
import { GET_APPLICATION_LIST, GET_LOAN_LIST } from 'lib/graphql/queries';
import { OperatorEnum } from 'lib/types';
import { useSentry } from './useSentry';
import { approvalSearch } from 'lib/graphql/searches';
import { approvalPagination } from 'lib/graphql/paginations';

interface UseLandingPage {
  getAsyncLoanList: () => void;
  checkRouter: () => void;
}

const useLandingPage = (): UseLandingPage => {
  const client = useApolloClient();
  const { setLoanList, setApplicationList, setCheckRouterLoading, borrower, setDeepLinkUrl } = useStore();
  const navigate = useNavigate();
  const { captureException } = useSentry();

  const getAsyncLoanList = async () => {
    try {
      const {
        data: { fetchLoanList },
      } = await client.query({
        query: GET_LOAN_LIST,
        variables: {
          input: {
            pagination: { limit: 100 },
            search: [
              {
                key: 'status',
                value: 'FUNDED,REFUNDED,AWAITING_FUNDING',
                operator: OperatorEnum.IN,
              },
            ],
          },
        },
      });

      const copiedData = fetchLoanList?.contents ? [...fetchLoanList?.contents] : [];
      const sortedData = copiedData?.sort((a, b) => b.plans?.[0]?.daysPastDue - a.plans?.[0]?.daysPastDue);
      setLoanList(sortedData);

      return sortedData;
    } catch (error) {
      captureException(error, { errorContext: 'fetchLoanList => useLandingPage.tsx' });
      return null;
    }
  };

  const getAsyncApplicationList = async () => {
    try {
      const result = await client.query({
        query: GET_APPLICATION_LIST,
        variables: {
          input: {
            search: approvalSearch(borrower?.id),
            pagination: approvalPagination,
          },
        },
      });

      if (result?.data?.fetchApplicationList) {
        const { fetchApplicationList } = result.data;
        setApplicationList(fetchApplicationList?.contents);
        return fetchApplicationList?.contents || [];
      }
    } catch (error) {
      captureException(error, { extra: { description: 'fetchApplicationList => useLandingPage.tsx' } });
      return null;
    }
  };

  const checkRouter = async () => {
    setCheckRouterLoading(true);
    await Promise.all([getAsyncLoanList(), getAsyncApplicationList()]);
    const deepLinkUrl = useStore.getState().deepLinkUrl;
    if (deepLinkUrl) {
      navigate(`/${deepLinkUrl}`);
      setDeepLinkUrl(null);
    }
    setCheckRouterLoading(false);
  };

  return { getAsyncLoanList, checkRouter };
};

export default useLandingPage;
