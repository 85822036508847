import useStore from 'lib/hooks/useStore';
import PreviewMyPayments from './PreviewMyPayments';
import { Container } from 'lib/components';
import { useEffect } from 'react';

export const PreviewPayments = () => {
  const { selectedApplication: approval, setHeaderTitle } = useStore();
  const { balanceAvailable } = approval || {};

  useEffect(() => {
    setHeaderTitle('applicationDetails.previewPayments');
  }, [setHeaderTitle]);

  return (
    <>
      {approval ? (
        <Container block={true}>
          <PreviewMyPayments applicationId={approval?.id} maxPrice={balanceAvailable} />
        </Container>
      ) : null}
    </>
  );
};
