import { OperatorEnum } from 'lib/types';

export const approvalSearch = (borrowerId: number) => {
  return [
    {
      key: 'demo',
      value: 'false',
      operator: OperatorEnum.EQUAL,
    },
    {
      key: 'borrowerId',
      value: borrowerId?.toString(),
      operator: OperatorEnum.EQUAL,
    },
    {
      key: 'validity',
      value: 'ACTIVE',
      operator: OperatorEnum.EQUAL,
    },
    {
      key: 'expireAt',
      value: new Date().toISOString(),
      operator: OperatorEnum.BIGGER_THAN,
    },
  ];
};

export const expiredApprovalSearch = (borrowerId: number) => {
  return [
    {
      key: 'demo',
      value: 'false',
      operator: OperatorEnum.EQUAL,
    },
    {
      key: 'borrowerId',
      value: borrowerId?.toString(),
      operator: OperatorEnum.EQUAL,
    },
    {
      key: 'validity',
      value: 'ACTIVE',
      operator: OperatorEnum.EQUAL,
    },
  ];
};
