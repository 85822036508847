import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { FormatAmount } from 'lib/utils';
import { ButtonContainer, HeaderText } from 'pages/ExtendApproval/style';
import { Approval, ScreenState } from 'pages/ExtendApproval/type';
import { Heading, Button } from '@frontend/cherry-library';
import { useSegment } from 'lib/hooks/useSegment';
import { SEGMENT_EVENT_NAMES } from 'lib/constans';
import { useEffect } from 'react';
import { getApplicationDetailData } from 'lib/utils/ApplicationDetailSegmentEventData';
import useStore from 'lib/hooks/useStore';

interface Props {
  approval: Approval;
  setScreenState: (value: ScreenState) => void;
}

export const ExtendSuccess = ({ approval, setScreenState }: Props) => {
  const { t: translate } = useTranslation();
  const { borrower } = useStore();
  const navigate = useNavigate();
  const { trackPage, standardSegmentMetadata } = useSegment();
  const applicationSegmentData = getApplicationDetailData(borrower, approval);
  const pageSegmentEventData = { ...standardSegmentMetadata, ...applicationSegmentData };

  useEffect(() => {
    trackPage(SEGMENT_EVENT_NAMES.PATIENT_PORTAL.REQUEST_MORE_TIME.CONFIRMED_PAGE_LOAD, pageSegmentEventData);
  }, []);

  const { expireAt, balanceAvailable } = approval;

  const goBackHandler = () => {
    setScreenState(ScreenState.SELECTION);
    navigate('/portal/approvals');
  };

  const extendDate = dayjs(expireAt).add(30, 'day').format('MMM DD, YYYY');

  return (
    <>
      <Heading level="4" text={translate('extend.approvals.success.title')}></Heading>
      <HeaderText
        dangerouslySetInnerHTML={{
          __html: translate('extend.approvals.success.subtitle1', {
            purchaseAmount: FormatAmount(balanceAvailable),
            extendDate,
          }),
        }}
      />
      <ButtonContainer>
        <Button fullWidth onClick={goBackHandler}>
          {translate('general.okay')}
        </Button>
      </ButtonContainer>
    </>
  );
};
