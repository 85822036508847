import { gql } from '@apollo/client/core';

export const GET_EXCHANGE_TOKEN = gql`
  mutation getExchangeToken($input: ExchangeTokenInput) {
    getExchangeToken(input: $input) {
      success
      data {
        borrowerId
        bankConnectionId
        applicationId
      }
      status
      type
      token
    }
  }
`;

export const SET_EXCHANGE_TOKEN = gql`
  mutation setExchangeToken($input: SetExchangeTokenInput) {
    setExchangeToken(input: $input) {
      exchangeTokenId
    }
  }
`;
