import Fade from '@mui/material/Fade';
import React from 'react';
import styled from 'styled-components';

interface Props {
  children: React.ReactNode;
  block?: boolean;
  minHeight?: boolean;
  marginBottom?: number;
  marginTop?: string;
}

interface SCContainerInput {
  block: boolean;
  marginTop: string;
}

export const Container = ({ children, block = false, marginBottom = 0, marginTop = '56px' }: Props) => {
  return (
    <Fade in={true} timeout={750}>
      <Main marginBottom={marginBottom}>
        <InnerContainer block={block} marginTop={marginTop}>
          {children}
        </InnerContainer>
      </Main>
    </Fade>
  );
};

const Main = styled.div<{ marginBottom: number }>`
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  align-self: stretch;
  min-height: unset;
  margin-bottom: ${(props) => props.marginBottom && `${props.marginBottom}px`};

  @media (max-width: ${(props) => props.theme.size.tablet}) {
    margin-bottom: ${(props) => (props.marginBottom ? '40px' : 0)};
  }
`;

const InnerContainer = styled.div<SCContainerInput>`
  margin: ${(props) => `${props.marginTop} auto 0 auto`};
  width: ${(props) => (props.block ? '600px' : '550px')};

  @media (max-width: ${(props) => props.theme.size.tablet}) {
    width: 90%;
    margin: 24px auto 0 auto;
  }
`;
