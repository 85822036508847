import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { PREQUAL_URL } from 'config';
import { Button } from 'lib/components';
import { ExternalRedirect } from 'lib/utils/ExternalRedirect';

interface Props {
  title: string;
  slug: string;
}

export const ApprovalBoostCard = ({ title, slug }: Props) => {
  const { t: translate } = useTranslation();

  const checkoutHandler = () => {
    ExternalRedirect(`${PREQUAL_URL}/${slug}`);
  };

  return (
    <Container>
      <Header>
        <Title>{title}</Title>
      </Header>
      <Content>
        <LargeTitle>{translate('approvals.boost.status')}</LargeTitle>
        <Subtitle>{translate('approvals.boost.card.subtitle')}</Subtitle>
        <Button onClick={checkoutHandler} m={'24px 24px 0px 24px'}>
          {translate('approvals.boost.card.buttonText')}
        </Button>
      </Content>
    </Container>
  );
};

const Container = styled.div`
  position: relative;
  box-shadow: 0px 6px 9px rgba(0, 0, 0, 0.07), 0px 0.751293px 1.12694px rgba(0, 0, 0, 0.035);
  margin: 0px 24px 10px 5px;
  border-radius: 8px;
  background-color: white;
  padding: 24px;
`;

const Header = styled.div`
  display: flex;
  justify-content: center;
  padding-bottom: 8px;
  margin-bottom: 0;
`;

const Title = styled.span`
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 700;
  font-size: 20px;
  line-height: 27px;
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const Subtitle = styled.span`
  font-weight: normal;
  font-size: 16px;
  line-height: 22px;
  text-align: center;
  color: ${(props) => props.theme.main.primary};
  padding: 8px 12px 0;
`;

const LargeTitle = styled(Title)`
  font-size: 32px;
  margin: 8px 0;
`;
