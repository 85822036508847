import * as Sentry from '@sentry/react';

enum SeverityEnums {
  INFO = 'info',
  ERROR = 'error',
}

export const useSentry = () => {
  const captureMessage = (message: string, severity: SeverityEnums = SeverityEnums.INFO) => {
    Sentry.captureMessage(message, severity);
  };

  // https://docs.sentry.io/platforms/javascript/guides/react/enriching-events/identify-user/
  const setUser = (user: Sentry.User) => {
    Sentry.setUser(user);
  };

  // https://docs.sentry.io/platforms/javascript/guides/react/enriching-events/tags/
  const setTag = (tagKey: string, tagValue: string) => {
    Sentry.setTag(tagKey, tagValue);
  };

  // https://docs.sentry.io/platforms/javascript/guides/react/enriching-events/breadcrumbs/
  const addBreadcrumb = (category: string, message: string) => {
    Sentry.addBreadcrumb({
      category,
      message,
    });
  };

  // https://docs.sentry.io/platforms/javascript/guides/react/data-management/sensitive-data/
  const setExtra = (extraKey: string, extraValue: string) => {
    Sentry.setExtra(extraKey, extraValue);
  };

  const setContext = (
    contextKey: string,
    params: {
      [key: string]: string;
    } | null,
  ) => {
    Sentry.setContext(contextKey, params);
  };

  const captureException = (error, extraParams?) => {
    Sentry.captureException(error, { level: 'error', extra: extraParams });
  };

  return {
    addBreadcrumb,
    captureException,
    captureMessage,
    setContext,
    setExtra,
    setTag,
    setUser,
  };
};
