import { SVGProps } from 'react';

export const ChevronUpIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg width="16" height="16" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M12.0002 8.28799L5.99023 14.298L7.40423 15.713L12.0042 11.113L16.6042 15.713L18.0112 14.298L12.0002 8.28799Z"
      fill="#00C37C"
    />
  </svg>
);
