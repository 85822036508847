import { useEffect } from 'react';
import styled from 'styled-components';
import { Container } from 'lib/components';
import useStore from 'lib/hooks/useStore';
import { landingPageCards } from 'lib/constans/route';
import NavigationCard, { NavigationCardProps } from './NavigationCard';
import { useTranslation } from 'react-i18next';
import { useSegment } from 'lib/hooks/useSegment';
import { SEGMENT_EVENT_NAMES } from 'lib/constans';

const HomePage = () => {
  const { setHeaderTitle, borrower } = useStore();
  const { t: translate } = useTranslation();
  const { trackPage } = useSegment();

  useEffect(() => {
    if (borrower) {
      setHeaderTitle(`${translate('homePage.header.text')} ${borrower?.firstName} ${borrower?.lastName}`);
    }
    trackPage(SEGMENT_EVENT_NAMES.PATIENT_PORTAL.HOME_PAGE.HOME_LOAD, {
      borrowerId: borrower?.id,
      phone: borrower?.phone,
    });
  }, []);

  return (
    <Container block={true}>
      <CardGrid>
        {landingPageCards.map((card: NavigationCardProps) => (
          <NavigationCard
            icon={card.icon}
            title={card.title}
            pageName={card.pageName}
            eventName={card.eventName}
            key={`${card.title}`}
            trackId={card.trackId}
          />
        ))}
      </CardGrid>
    </Container>
  );
};

const CardGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  padding: 24px 16px 40px 16px;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  align-self: stretch;

  @media (min-width: ${(props) => props.theme.size.mobileXl}) {
    max-width: 600px;
  }
`;

export default HomePage;
