import { useLazyQuery } from '@apollo/client';
import { useAlert } from 'react-alert';
import { useTranslation } from 'react-i18next';

import { GET_CHECKOUT_LOAN_LIST, GET_DEBT_SALE_BUYERS, GET_LOAN_LIST } from 'lib/graphql/queries';
import { OperatorEnum } from 'lib/types';
import useStore from './useStore';

export const useLoanList = (status: string) => {
  const alert = useAlert();
  const { t } = useTranslation();
  const { setLoanList } = useStore();

  return useLazyQuery(GET_LOAN_LIST, {
    variables: {
      input: {
        pagination: { limit: 100 },
        search: [
          {
            key: 'status',
            value: status,
            operator: OperatorEnum.IN,
          },
        ],
      },
    },
    fetchPolicy: 'network-only',
    onCompleted: ({ fetchLoanList }) => {
      const copiedData = fetchLoanList?.contents ? [...fetchLoanList?.contents] : [];
      const sortedData = copiedData?.sort((a, b) => b.plans?.[0]?.daysPastDue - a.plans?.[0]?.daysPastDue);
      setLoanList(sortedData);
    },
    onError: (error) => {
      alert.error(t(`error.${error}`));
    },
  });
};

export const useCheckoutLoanList = () => {
  const alert = useAlert();
  const { t } = useTranslation();
  const { setCheckoutLoanList } = useStore();

  return useLazyQuery(GET_CHECKOUT_LOAN_LIST, {
    variables: {
      input: {
        pagination: { limit: 100 },
        search: [
          {
            key: 'status',
            value: 'INITIATED',
            operator: OperatorEnum.EQUAL,
          },
          {
            key: 'selfCheckout',
            value: 'true',
            operator: OperatorEnum.EQUAL,
          },
        ],
      },
    },
    fetchPolicy: 'network-only',
    onCompleted: ({ fetchLoanList }) => {
      setCheckoutLoanList(fetchLoanList?.contents);
    },
    onError: (error) => {
      alert.error(t(`error.${error}`));
    },
  });
};

export const useGetDebtSaleBuyers = () => {
  const alert = useAlert();
  const { t } = useTranslation();
  const { setDebtSaleBuyers } = useStore();

  return useLazyQuery(GET_DEBT_SALE_BUYERS, {
    onCompleted: ({ fetchDebtSaleBuyers }) => {
      setDebtSaleBuyers(fetchDebtSaleBuyers);
    },
    onError: (error) => {
      alert.error(t(`error.${error}`, { defaultValue: 'Something went wrong' }));
    },
  });
};
