import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import MuiAlert, { AlertProps } from '@mui/material/Alert';
import Snackbar from '@mui/material/Snackbar';
import React from 'react';
import { AlertComponentPropsWithStyle } from 'react-alert';

const Alert = React.forwardRef<HTMLDivElement, AlertProps>(function AlertFn(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export const Notification = ({ message, options, close }: AlertComponentPropsWithStyle): React.ReactElement => {
  return (
    <Snackbar
      sx={{ width: '98%' }}
      anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      autoHideDuration={3000}
      open={!!message}
    >
      <Alert
        severity={options?.type}
        sx={{ width: '100%' }}
        iconMapping={{
          error: <HighlightOffIcon fontSize="inherit" onClick={close} />,
        }}
      >
        {message}
      </Alert>
    </Snackbar>
  );
};
