import { useApolloClient } from '@apollo/client';
import { FETCH_LATEST_CREDIT_LINE_INCREASE } from 'lib/graphql/queries';
import { useSentry } from 'lib/hooks/useSentry';

export enum CreditLineIncreaseStatus {
  APPROVED = 'APPROVED',
  DENIED = 'DENIED',
  MISSING_INFO = 'MISSING_INFO',
  REVIEW = 'REVIEW',
}

export const useCreditLineIncrease = () => {
  const client = useApolloClient();
  const { captureException } = useSentry();

  const fetchLatestCreditLineIncrease = async (applicationId) => {
    try {
      const { data } = await client.query({
        query: FETCH_LATEST_CREDIT_LINE_INCREASE,
        variables: { input: { applicationId } },
      });
      return data?.fetchLatestCreditLineIncrease;
    } catch (e) {
      captureException(e, { errorContext: 'Fetch Latest Credit Line Increase Error' });
    }
  };

  return {
    fetchLatestCreditLineIncrease,
  };
};
