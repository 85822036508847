import React, { useState } from 'react';

import { Accordion, Button, ButtonColor, Title } from 'lib/components';

import faq from './faq.json';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

interface FaqProps {
  close?: () => void;
}

const Faq = ({ close }: FaqProps) => {
  const [expanded, setExpanded] = useState<string | false>('panel0');
  const { t: translate } = useTranslation();

  const handleChange = (panel: string) => {
    setExpanded(panel);
  };

  return (
    <ContentContainer noMargin>
      <Title m={'10px 0 0 0'}>{translate('faq.title')}</Title>
      <Accordion data={faq} onChange={handleChange} expanded={expanded} />
      {close && (
        <Button block={true} color={ButtonColor.secondary} onClick={close}>
          {translate('common.dismiss')}
        </Button>
      )}
    </ContentContainer>
  );
};

const ContentContainer = styled.div<{ justify?: string; noMargin?: boolean; noPadding?: boolean }>`
  display: flex;
  flex-direction: column;
  flex: 1;
  justify-content: ${(props) => (props.justify ? props.justify : 'flex-start')};
  padding: ${(props) => (props.noPadding ? '0px' : '20px 24px')};
  margin-top: ${(props) => (props.noMargin ? '0px' : '15px')};
  background-color: white;

  @media (max-width: ${(props) => props.theme.size.mobileXl}) {
    // overflow-x: scroll;
  }
`;

export default Faq;
