import { styled } from '@mui/material/styles';
import Tabs from '@mui/material/Tabs';

import { theme } from 'config/theme';

interface StyledTabsProps {
  children?: React.ReactNode;
  value: number;
  indicatorColor: any;
  textColor: any;
  variant: any;
  onChange: (event: React.SyntheticEvent, newValue: number) => void;
}

export const StyledTabs = styled((props: StyledTabsProps) => (
  <Tabs
    {...props}
    // TabIndicatorProps={{ children: <span className="MuiTabs-indicatorSpan" /> }}
  />
))({
  '& .MuiTabs-flexContainer': {
    backgroundColor: theme.main.white,
  },
  '& .MuiButtonBase-root': {
    color: theme.main.primary,
    fontSize: '14px',
    fontWeight: 'bold',
    lineHeight: '19px',
    textTransform: 'uppercase',
    margin: 0,
    padding: '20px 0px 13px 0px',
    fontFamily: "'Open Sans', sans-serif",
  },

  '& button': {
    borderBottom: `5px solid white`,
  },

  '& .Mui-selected': {
    color: `${theme.main.primary} !important`,
    borderBottom: `5px solid ${theme.main.green}`,
  },

  '& .MuiTabs-indicator': {
    display: 'flex',
    justifyContent: 'center',
    backgroundColor: 'transparent',
  },
  '& .MuiTabs-indicatorSpan': {
    maxWidth: 40,
    width: '100%',
    backgroundColor: '#635ee7',
  },
});
