import styled from 'styled-components';

export const Card = ({ title, children }) => {
  return (
    <CardContainer data-testid="contractItem">
      {title && (
        <CardHeader>
          <CardTitle>{title}</CardTitle>
        </CardHeader>
      )}
      {children}
    </CardContainer>
  );
};

const CardContainer = styled.div`
  box-shadow: 0px 6px 9px rgba(0, 0, 0, 0.07), 0px 0.751293px 1.12694px rgba(0, 0, 0, 0.035);
  border-radius: ${(props) => props.theme.constants.s};
  margin-bottom: ${(props) => props.theme.constants.xl};
  margin-top: ${(props) => props.theme.constants.l};
  padding: 24px;
  background-color: white;
`;

const CardHeader = styled.div`
  background: ${(props) => props.theme.main.white};
  margin-bottom: ${(props) => props.theme.constants.m};
  border-bottom: 1px solid ${(props) => props.theme.main.cardBorderColor};
  padding-bottom: ${(props) => props.theme.constants.s};
  text-align: center;
`;

const CardTitle = styled.h3`
  font-size: 24px;
  font-weight: bold;
  color: ${(props) => props.theme.main.primary};
  line-height: 33px;
  margin-block-start: 0;
  margin-block-end: 0;
`;
