import SliderModal from 'lib/components/SliderModal/SliderModal';
import { IFAQ } from 'pages/Faq/type';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import FaqAccordion from 'pages/Faq/components/Accordion';
import { Button } from '@frontend/cherry-library';
import { WEB_SITE } from 'config';
import styled from 'styled-components';
import { ExternalRedirect } from 'lib/utils/ExternalRedirect';
import { useSegment } from 'lib/hooks/useSegment';
import { SEGMENT_EVENT_NAMES } from 'lib/constans';

export const useDenialsFAQ = () => {
  const { t: translate } = useTranslation();
  const { trackSegmentEvent } = useSegment();

  const openFaq = () => {
    trackSegmentEvent(SEGMENT_EVENT_NAMES.PATIENT_PORTAL.APPROVAL_DETAILS.FAQ_MORE_QUESTIONS_CLICKED);
    ExternalRedirect(`${WEB_SITE}/patient-faq/`);
  };

  const [modalVisibility, setModalVisibility] = useState(false);
  const [faqs, setFaqs] = useState(0);

  const handleDismissSegmentEvents = () => {
    if (faqs === 0) {
      return SEGMENT_EVENT_NAMES.PATIENT_PORTAL.DENIAL_DETAILS.DISMISS_FAQS_CLICKED;
    } else {
      return SEGMENT_EVENT_NAMES.PATIENT_PORTAL.APPROVAL_DETAILS.DISMISS_FAQS_CLICKED;
    }
  };

  const hideModal = () => {
    trackSegmentEvent(handleDismissSegmentEvents());
    setModalVisibility(false);
  };

  const openModal = (order: number) => {
    setFaqs(order);
    setModalVisibility(true);
  };

  const denialsFaqs: IFAQ[] = [
    {
      title: translate(`denialsFaq.firstQuestion.question`),
      content: translate('denialsFaq.firstQuestion.answer'),
    },
    {
      title: translate(`denialsFaq.secondQuestion.question`),
      content: translate('denialsFaq.secondQuestion.answer'),
    },
    {
      title: translate(`denialsFaq.thirdQuestion.question`),
      content: translate('denialsFaq.thirdQuestion.answer'),
    },
  ];

  const approvalDetailsFaqs: IFAQ[] = [
    {
      title: translate(`approvalDetailFaq.firstQuestion.question`),
      content: translate('aprovalDetailFaq.firstQuestion.answer'),
    },
    {
      title: translate(`aprovalDetailFaq.secondQuestion.question`),
      content: translate('aprovalDetailFaq.secondQuestion.answer'),
    },
    {
      title: translate(`aprovalDetailFaq.thirdQuestion.question`),
      content: translate('aprovalDetailFaq.thirdQuestion.answer'),
    },
  ];

  const ModalBody = (
    <SliderModal title={translate('faq.title')} show={modalVisibility} hideModal={hideModal}>
      <Fragment>
        <FaqAccordion data={faqs === 0 ? denialsFaqs : approvalDetailsFaqs} segmentEventId={faqs} />
        <Wrapper>
          {faqs === 1 && (
            <Button variant="secondary" fullWidth onClick={openFaq}>
              {translate('faq.moreButton')}
            </Button>
          )}
          <Button variant="secondary" fullWidth onClick={hideModal}>
            {translate('common.dismiss')}
          </Button>
        </Wrapper>
      </Fragment>
    </SliderModal>
  );
  return {
    ModalBody,
    modalVisibility,
    openModal,
  };
};

const Fragment = styled.div``;

const Wrapper = styled.div`
  display: flex;
  gap: 8px;
  flex-direction: column;
`;
