import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { ButtonContainer, HeaderText } from '../style';
import { Approval, ScreenState } from '../type';
import { Heading, Button } from '@frontend/cherry-library';
import { useSegment } from 'lib/hooks/useSegment';
import { SEGMENT_EVENT_NAMES } from 'lib/constans';
import { useEffect } from 'react';
import { getApplicationDetailData } from 'lib/utils/ApplicationDetailSegmentEventData';
import useStore from 'lib/hooks/useStore';

interface Props {
  approval: Approval;
  setScreenState: (value: ScreenState) => void;
}

export const Reminder = ({ approval, setScreenState }: Props) => {
  const { t: translate } = useTranslation();
  const navigate = useNavigate();
  const { borrower } = useStore();
  const { trackPage, standardSegmentMetadata } = useSegment();
  const applicationSegmentData = getApplicationDetailData(borrower, approval);
  const pageSegmentEventData = { ...standardSegmentMetadata, ...applicationSegmentData };

  useEffect(() => {
    trackPage(SEGMENT_EVENT_NAMES.PATIENT_PORTAL.REQUEST_MORE_TIME.REMINDER_SET_PAGE_LOAD, pageSegmentEventData);
  }, []);

  const { organization } = approval;

  const goBackHandler = () => {
    setScreenState(ScreenState.SELECTION);
    navigate('/portal/approvals');
  };

  return (
    <>
      <Heading level="4" text={translate('extend.approvals.reminder.title')}></Heading>
      <HeaderText>
        {translate('extend.approvals.reminder.subtitle1', {
          organizationName: organization.name,
        })}
      </HeaderText>
      <ButtonContainer>
        <Button fullWidth onClick={goBackHandler}>
          {translate('extend.approvals.button.goBack')}
        </Button>
      </ButtonContainer>
    </>
  );
};
