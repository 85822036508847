import Dialog from '@mui/material/Dialog';
import Slide from '@mui/material/Slide';
import { TransitionProps } from '@mui/material/transitions';
import { forwardRef, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import styled from 'styled-components';
import { theme } from 'config/theme';
import { NavItem } from 'lib/components';
import { routes } from 'lib/constans/route';
import { useSegment } from 'lib/hooks/useSegment';
import useStore, { LanguageEnum } from 'lib/hooks/useStore';
import { AnalyticEventNames, useAnalytics } from 'lib/hooks/useAnalytics';
import { useUpdateBorrower } from 'lib/hooks/useAccount';
import { ClientErrors, SOURCE_TYPES, SEGMENT_EVENT_NAMES } from 'lib/constans';
import { useAlert } from 'react-alert';
import { Icon as CherryIcon } from '@frontend/cherry-library';
import { ReactComponent as Logout } from 'assets/images/log_out.svg';
import { ReactComponent as Close } from 'assets/images/close_big.svg';
import { ReactComponent as CherrySymbol } from 'assets/images/cherry_symbol.svg';

const TransitionFunc = forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>,
) {
  return <Slide direction="left" ref={ref} {...props} />;
});

export const Menu = ({ show, hideMenu }) => {
  const { t } = useTranslation();
  const location = useLocation();
  const { trackSegmentEvent } = useSegment();
  const { trackEvent } = useAnalytics();
  const [updateBorrower] = useUpdateBorrower();
  const alert = useAlert();

  const { signOut, applicationList, borrower, defaultLanguage, setDefaultLanguage } = useStore();

  useEffect(() => {
    hideMenu();
  }, [location.pathname]);

  const filterRoutes = () => {
    if (!applicationList || applicationList?.length < 1) {
      return routes?.filter((route) => !route.name.includes('approvals'));
    }
    return routes;
  };

  const handleClose = () => {
    hideMenu();
  };

  const navItemClickHandler = () => {
    hideMenu();
  };

  const signOutHandler = () => {
    trackSegmentEvent(SEGMENT_EVENT_NAMES.PATIENT_PORTAL.NAVIGATION_MENU.SIGN_OUT_CLICKED, {
      patientId: borrower?.id,
      phone: borrower?.phone,
    });

    signOut();
  };

  const changeLanguageHandler = async () => {
    const language = defaultLanguage === LanguageEnum.EN ? LanguageEnum.ES : LanguageEnum.EN;
    trackEvent(AnalyticEventNames.CHANGE_LANGUAGE, language);
    trackSegmentEvent(SEGMENT_EVENT_NAMES.PATIENT_PORTAL.NAVIGATION_MENU.VER_EN_ESPANOL_CLICKED, {
      patientId: borrower?.id,
      phone: borrower?.phone,
    });
    const resp = await updateBorrower({
      variables: {
        input: {
          id: borrower?.idBorrower || borrower?.id,
          language: language?.toUpperCase(),
          source: SOURCE_TYPES.GREET,
        },
      },
    });

    if (resp?.data?.updateBorrower?.success) {
      setDefaultLanguage(language);
    } else {
      const message = resp?.data?.updateBorrower?.message;
      alert.error(ClientErrors[message] || message);
    }
  };

  return (
    <Dialog
      className="menu-dialog"
      PaperProps={{ style: { backgroundColor: theme.main.primary } }}
      fullScreen={true}
      open={show}
      TransitionComponent={TransitionFunc}
      keepMounted={true}
      onClose={handleClose}
      aria-describedby="alert-dialog-slide-description"
      data-testid="hamburgerMenu"
    >
      <CherryLogoItem>
        <CherryIconConTainer>
          <CherryIcon src={CherrySymbol} color={theme.main.white} width={'87px'} height={'24px'} />
        </CherryIconConTainer>
        <IconContainer data-testid="hamburgerCloseButton" onClick={handleClose}>
          <CherryIcon src={Close} color={theme.main.white} width={'40px'} height={'40px'} />
        </IconContainer>
      </CherryLogoItem>
      <BodyContainer>
        <MenuContainer>
          <LinkContainer>
            {filterRoutes().map((route, index) => (
              <ItemContainer key={`${index}-${route.name}`}>
                <CherryIcon
                  src={route.icon}
                  color={route.to === location.pathname ? theme.main.green : theme.main.white}
                />
                <NavItem
                  route={route}
                  index={index}
                  navItemClickHandler={navItemClickHandler}
                  key={`${route.name}-${index}`}
                  isBadgeShown={false}
                />
              </ItemContainer>
            ))}
            <SignOut data-testid="logout" onClick={signOutHandler}>
              <CherryIcon src={Logout} color={theme.main.white} />
              <SignOutText>{t('menu.listItems.signOutBtn')}</SignOutText>
            </SignOut>
          </LinkContainer>
        </MenuContainer>

        <FooterContainer>
          <LinkTextContainer onClick={changeLanguageHandler}>
            <LinkIcon src={'/language-selection.svg'} />
            <LinkText>{t('footer.language')}</LinkText>
          </LinkTextContainer>
        </FooterContainer>
      </BodyContainer>
    </Dialog>
  );
};

const IconContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  cursor: pointer;
`;

const BodyContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: space-between;
  padding-top: 20px;
  -ms-overflow-style: none;
  scrollbar-width: none;
  &::-webkit-scrollbar {
    display: none;
  }
`;

const MenuContainer = styled.div`
  text-align: center;
`;

const LinkContainer = styled.div`
  margin: 0 24px;
  display: flex;
  flex-direction: column;
`;
const ItemContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 52px;
`;

const CherryLogoItem = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 48px 24px 0px 24px;
`;
const CherryIconConTainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  color: ${(props) => props.theme.main.white};
  font-size: 20px;
  font-weight: 400;
  gap: 5.8px;
`;

const SignOut = styled.div`
  cursor: pointer;
  display: flex;
  align-self: stretch;
  text-decoration: none;
  color: ${(props) => props.theme.main.white};
  padding-top: 18px;
  &:hover {
    text-decoration: underline;
  }
`;

const SignOutText = styled.div`
  font-size: 18px;
  line-height: 24px;
  font-weight: 400;
  margin-left: 24px;
`;

const FooterContainer = styled.div`
  display: flex;
  flex-direction: column;
  text-align: flex-start;
  margin-top: 8px;
  margin-left: 24px;
`;

const LinkTextContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 24px;
`;

const LinkText = styled.a`
  cursor: pointer;
  font-size: 18px;
  color: ${(props) => props.theme.main.green};
  text-decoration: underline;
  margin-left: 10px;
`;

const LinkIcon = styled.img`
  width: 16px;
  height: 16px;
  color: ${(props) => props.theme.main.green};
  padding-top: 2px;
`;
