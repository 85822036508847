import SliderModal from 'lib/components/SliderModal/SliderModal';
import { useTranslation } from 'react-i18next';
import { Body, Chip, Icon, Button, theme } from '@frontend/cherry-library';
import { ReactComponent as PhoneIcon } from 'assets/images/phone.svg';
import { ReactComponent as MessageIcon } from 'assets/images/message.svg';
import { GET_FILES } from 'lib/graphql/queries';
import client from 'lib/graphql/client';
import { useCallback, useState } from 'react';
import { fileSearchWithType } from 'lib/graphql/searches';
import { AnalyticEventNames, useAnalytics } from 'lib/hooks/useAnalytics';
import { useFile } from 'lib/hooks/useFile';
import styled from 'styled-components';
import { Loan } from 'pages/Purchases/type';
import { useFormatDate } from 'lib/utils';
import { phone, email, yellowVoiceBotPhone, SEGMENT_EVENT_NAMES } from 'lib/constans/index';
import { Flags } from './FeatureManagement/Flags';
import { getErrorMessage } from 'lib/utils/GetErrorMessage';
import { ReactComponent as WarningIcon } from 'assets/images/warning-outline.svg';
import { useFlags } from './FeatureManagement/FlagsContext';
import { useSegment } from 'lib/hooks/useSegment';
import useStore from 'lib/hooks/useStore';
import { getSegmentEventBorrowerData, getSegmentEventDetailsData } from 'lib/utils/SegmentEventData';
import { useLocation, useParams } from 'react-router-dom';

enum SUPPORT_OPTIONS {
  CHAT = 'CHAT',
  PHONE = 'PHONE',
  EMAIL = 'EMAIL',
}

export const useHelpModal = () => {
  const { t: translate } = useTranslation();
  const { flags } = useFlags();

  const { trackSegmentEvent } = useSegment();
  const { borrower, getActiveLoan } = useStore();
  const { id } = useParams();
  const location = useLocation();

  const segmentEventBorrowerData = getSegmentEventBorrowerData(borrower);
  let segmentEventData = segmentEventBorrowerData;
  if (id) {
    const loan = getActiveLoan(id);
    const segmentEventPaymentPlanDetailsData = getSegmentEventDetailsData(borrower, loan);
    segmentEventData = segmentEventPaymentPlanDetailsData;
  }

  const [selectedSupport, setSelectedSupport] = useState<string>(SUPPORT_OPTIONS.CHAT);
  const [helpModalVisibility, setHelpModalVisibility] = useState(false);

  const closeSegmentEventName = () => {
    if (location.pathname.includes('autopay')) {
      return `${SEGMENT_EVENT_NAMES.PATIENT_PORTAL.SET_PAYMENT_METHOD.DISMISS_CONTACT_SUPPORT_MODAL_CLICKED}`;
    } else {
      return `${SEGMENT_EVENT_NAMES.PATIENT_PORTAL.PAYMENT_PLAN_DETAILS.DISMISS_CONTACT_SUPPORT_MODAL_CLICKED}`;
    }
  };

  const closeHelpModal = () => {
    const closeEventName = closeSegmentEventName();
    trackSegmentEvent(
      id ? closeEventName : SEGMENT_EVENT_NAMES.PATIENT_PORTAL.HELP.DISMISS_CONTACT_SUPPORT_MODAL_CLICKED,
      segmentEventData,
    );
    hideHelpModal();
  };

  const hideHelpModal = () => {
    setHelpModalVisibility(false);
  };

  const openHelpModal = () => {
    setHelpModalVisibility(true);
  };

  const chooseSupport = (option: SUPPORT_OPTIONS) => {
    trackSegmentEvent(SEGMENT_EVENT_NAMES.PATIENT_PORTAL.SET_PAYMENT_METHOD.CONTACT_SUPPORT_MODAL_CHANNEL_SELECTED, {
      ...segmentEventData,
      channel: option,
    });
    setSelectedSupport(option);
    trackSegmentEvent(
      id
        ? SEGMENT_EVENT_NAMES.PATIENT_PORTAL.PAYMENT_PLAN_DETAILS.CONTACT_SUPPORT_MODAL_CHANNEL_SELECTED
        : SEGMENT_EVENT_NAMES.PATIENT_PORTAL.HELP.CONTACT_SUPPORT_MODAL_CHANNEL_SELECTED,
      {
        ...segmentEventData,
        channel: option.toLowerCase(),
      },
    );
  };

  const openChatBot = () => {
    if (Flags.FEATURES.yellowAiChatBot.isEnabled()) {
      window?.YellowMessengerPlugin?.openBot();
    }
  };

  const isYellowVoiceBotEnabled = flags.FEATURES.yellowVoiceBot.isEnabled();

  const makeContact = () => {
    trackSegmentEvent(
      id
        ? SEGMENT_EVENT_NAMES.PATIENT_PORTAL.PAYMENT_PLAN_DETAILS.CONTACT_SUPPORT_MODAL_CONTINUE_CLICKED
        : SEGMENT_EVENT_NAMES.PATIENT_PORTAL.HELP.CONTACT_SUPPORT_MODAL_CONTINUE_CLICKED,
      segmentEventData,
    );
    switch (selectedSupport) {
      case SUPPORT_OPTIONS.CHAT:
        hideHelpModal();
        openChatBot();
        break;
      case SUPPORT_OPTIONS.PHONE:
        window.location.href = `tel:${isYellowVoiceBotEnabled ? yellowVoiceBotPhone : phone}`;
        break;
      case SUPPORT_OPTIONS.EMAIL:
        window.location.href = `mailto:${email}`;
        break;
    }
  };

  const ModalBody = (
    <SliderModal title={translate('faq.support.title')} show={helpModalVisibility} hideModal={hideHelpModal}>
      <FlexCentered>
        <Body>{translate('faq.support.hereToAssist')}</Body>
        <Body>{translate('faq.support.contactUs')}</Body>
      </FlexCentered>

      <WrapperContainer>
        <Chip
          icon={<Icon height={24} width={24} src={MessageIcon} />}
          isSelected={selectedSupport === SUPPORT_OPTIONS.CHAT}
          label={translate('faq.support.viaChat')}
          badgeText={translate('faq.support.fastest')}
          onClick={() => {
            chooseSupport(SUPPORT_OPTIONS.CHAT);
          }}
        />
        <Chip
          icon={<Icon height={20} width={20} src={PhoneIcon} />}
          isSelected={selectedSupport === SUPPORT_OPTIONS.PHONE}
          label={translate('faq.support.phone')}
          onClick={() => {
            chooseSupport(SUPPORT_OPTIONS.PHONE);
          }}
          secondLabel={isYellowVoiceBotEnabled ? yellowVoiceBotPhone : phone}
        />
        <Chip
          icon="mail"
          isSelected={selectedSupport === SUPPORT_OPTIONS.EMAIL}
          label={translate('faq.support.email')}
          onClick={() => {
            chooseSupport(SUPPORT_OPTIONS.EMAIL);
          }}
          secondLabel={email}
        />
      </WrapperContainer>
      <WrapperContainer>
        <Button fullWidth onClick={makeContact}>
          {translate('genericButton.continue')}
        </Button>
        <Button variant="secondary" fullWidth onClick={closeHelpModal}>
          {translate('common.dismiss')}
        </Button>
      </WrapperContainer>
    </SliderModal>
  );
  return {
    ModalBody,
    helpModalVisibility,
    openHelpModal,
  };
};

export const useContractModal = (loan: Loan) => {
  const { t: translate } = useTranslation();
  const { trackEvent } = useAnalytics();
  const [getFile] = useFile();
  const { borrower } = useStore();
  const { trackSegmentEvent } = useSegment();
  const segmentEventPaymentPlanDetailsData = getSegmentEventDetailsData(borrower, loan);

  const [contractModalVisibility, setContractModalVisibility] = useState(false);

  const hideContractModal = () => {
    trackSegmentEvent(
      SEGMENT_EVENT_NAMES.PATIENT_PORTAL.PAYMENT_PLAN_DETAILS.DISMISS_DOWNLOAD_CONTRACT_MODAL_CLICKED,
      segmentEventPaymentPlanDetailsData,
    );
    setContractModalVisibility(false);
  };

  const openContractModal = () => {
    setContractModalVisibility(true);
  };

  const getAgreementFile = useCallback(async () => {
    trackEvent(AnalyticEventNames.CON_AGREEMENT_LINK);
    trackSegmentEvent(
      SEGMENT_EVENT_NAMES.PATIENT_PORTAL.PAYMENT_PLAN_DETAILS.CONFIRM_DOWNLOAD_CONTRACT_MODAL_CLICKED,
      segmentEventPaymentPlanDetailsData,
    );

    const { data: { getFileList } = {} } = await client.query({
      query: GET_FILES,
      variables: {
        input: {
          search: fileSearchWithType(loan?.id),
        },
      },
    });

    if (getFileList?.contents.length) {
      getFile({ variables: { input: { fileId: getFileList?.contents?.[0]?.id } } });
    }

    hideContractModal();
  }, [client, getFile, loan?.id, trackEvent]);

  const ModalBody = (
    <SliderModal
      title={translate('contractModal.downloadContract')}
      show={contractModalVisibility}
      hideModal={hideContractModal}
    >
      <FlexCentered>
        <Body fontWeight={400}>{translate('contractModal.downloadOriginalContract')}</Body>
      </FlexCentered>
      <WrapperContainer>
        <Button onClick={getAgreementFile} fullWidth>
          {translate('contractModal.downloadContract')}
        </Button>
        <Button onClick={hideContractModal} fullWidth variant="secondary">
          {translate('common.dismiss')}
        </Button>
      </WrapperContainer>
    </SliderModal>
  );
  return {
    ModalBody,
    contractModalVisibility,
    openContractModal,
  };
};

export const useDueDateModal = (loan: Loan, helpModalOpen: () => void) => {
  const { t: translate } = useTranslation();
  const { FormatDate } = useFormatDate();
  const { trackSegmentEvent } = useSegment();
  const { borrower } = useStore();
  const segmentEventPaymentPlanDetailsData = getSegmentEventDetailsData(borrower, loan);

  const [dueDateModalVisibility, setDueDateModalVisibility] = useState(false);

  const hideDueDateModal = () => {
    trackSegmentEvent(
      SEGMENT_EVENT_NAMES.PATIENT_PORTAL.PAYMENT_PLAN_DETAILS.DISMISS_DUE_DATE_ALREADY_CHANGED_MODAL_CLICKED,
      segmentEventPaymentPlanDetailsData,
    );
    setDueDateModalVisibility(false);
  };

  const openDueDateModal = () => {
    setDueDateModalVisibility(true);
  };

  const openHelpModal = () => {
    trackSegmentEvent(
      SEGMENT_EVENT_NAMES.PATIENT_PORTAL.PAYMENT_PLAN_DETAILS.DUE_DATE_ALREADY_CHANGED_MODAL_CONTACT_SUPPORT_CLICKED,
      segmentEventPaymentPlanDetailsData,
    );
    hideDueDateModal();
    setTimeout(() => {
      helpModalOpen();
    }, 100);
  };

  const ModalBody = (
    <SliderModal
      title={translate('dueDateModal.dueDateAlreadyChanged')}
      show={dueDateModalVisibility}
      hideModal={hideDueDateModal}
    >
      <FlexCentered>
        <Body fontWeight={400}>
          {translate('dueDateModal.previouslyChangedOn', { date: FormatDate(loan?.plans[0]?.updatedAt) })}
        </Body>
      </FlexCentered>
      <FlexCentered>
        <Body fontWeight={400}>{translate('dueDateModal.toRequestForbearance')}</Body>
      </FlexCentered>
      <WrapperContainer>
        <Button onClick={openHelpModal} fullWidth>
          {translate('faq.support.title')}
        </Button>
        <Button onClick={hideDueDateModal} fullWidth variant="secondary">
          {translate('common.dismiss')}
        </Button>
      </WrapperContainer>
    </SliderModal>
  );
  return {
    ModalBody,
    dueDateModalVisibility,
    openDueDateModal,
  };
};

export const useDisputeModal = (loan: Loan) => {
  const { t: translate } = useTranslation();
  const { borrower } = useStore();
  const segmentEventPaymentPlanDetailsData = getSegmentEventDetailsData(borrower, loan);
  const [disputeModalVisibility, setDisputeModalVisibility] = useState(false);
  const { trackSegmentEvent } = useSegment();

  const hideDisputeModal = () => {
    trackSegmentEvent(
      SEGMENT_EVENT_NAMES.PATIENT_PORTAL.PAYMENT_PLAN_DETAILS.FILE_DISPUTE_NOT_AVAILABLE_ACKNOWLEDGEMENT_CLICKED,
      segmentEventPaymentPlanDetailsData,
    );
    setDisputeModalVisibility(false);
  };

  const openDisputeModal = () => {
    setDisputeModalVisibility(true);
  };

  const ModalBody = (
    <SliderModal
      title={translate('general.featureNotAvailable')}
      show={disputeModalVisibility}
      hideModal={hideDisputeModal}
    >
      <Body fontWeight={400}>{translate('general.featureNotAvailableText')}</Body>
      <Button onClick={hideDisputeModal} fullWidth>
        {translate('general.okay')}
      </Button>
    </SliderModal>
  );
  return {
    ModalBody,
    disputeModalVisibility,
    openDisputeModal,
  };
};

export const useErrorModal = (errorKey: string) => {
  const { t: translate } = useTranslation();
  const [errorModalVisibility, setErrorModalVisibility] = useState(false);
  const { trackSegmentEvent } = useSegment();
  const { borrower, getActiveLoan } = useStore();

  const { id } = useParams();
  const loan = id ? getActiveLoan(id) : null;
  const segmentEventData = id ? getSegmentEventDetailsData(borrower, loan) : null;

  const hideErrorModal = () => {
    trackSegmentEvent(SEGMENT_EVENT_NAMES.PATIENT_PORTAL.MAKE_A_PAYMENT_SUMMARY.ERROR_MODAL_DISMISSED, {
      ...segmentEventData,
      errorCodeResponse: errorKey,
    });
    setErrorModalVisibility(false);
  };

  const openErrorModal = () => {
    setErrorModalVisibility(true);
  };

  const ModalBody = (
    <SliderModal title={translate('errorModal.paymentFailed')} show={errorModalVisibility} hideModal={hideErrorModal}>
      <Icon color={theme.main.cherryRed} src={WarningIcon} height={80} width={80} />
      <CenteredBody fontWeight={400}>{translate(`${getErrorMessage(errorKey)}`)}</CenteredBody>
      <Button variant="secondary" onClick={hideErrorModal} fullWidth>
        {translate('general.okay')}
      </Button>
    </SliderModal>
  );
  return {
    ModalBody,
    errorModalVisibility,
    openErrorModal,
  };
};

const FlexCentered = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  flex-direction: column;
`;

const WrapperContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const CenteredBody = styled(Body)`
  text-align: center;
`;
