import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { FormatAmount, useFormatDate, FormatTime } from 'lib/utils';

import { Card } from 'pages/Purchases/components';

interface ChargedOffProps {
  gap?: boolean;
}

export const AwaitingFundingCard = ({ loan }: any) => {
  const { t: translate } = useTranslation();
  const { FormatDate } = useFormatDate();

  const { merchant, organization, status, grossAmount } = loan;
  const title = status === 'AWAITING_SERVICE' ? organization?.name : merchant?.name;
  return (
    <Card title={title}>
      <AwaitingContainer gap={true}>
        <AmountLabel>{translate('contracts.active.totalAmount')}</AmountLabel>
        <TotalAmount>{FormatAmount(grossAmount)}</TotalAmount>
        <Purchase>{translate('contracts.active.confirmed')}</Purchase>

        {loan?.updatedAt ? (
          <PurchaseInfo>
            {translate('contracts.active.confirmedText', {
              date: FormatDate(loan.updatedAt),
              time: FormatTime(loan.updatedAt),
            })}
          </PurchaseInfo>
        ) : null}
      </AwaitingContainer>
    </Card>
  );
};

const AwaitingContainer = styled.div<ChargedOffProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;

const AmountLabel = styled.span`
  font-size: ${(props) => props.theme.constants.m};
  margin-top: 20px;
  line-height: 21px;
  font-weight: 400;
`;

const TotalAmount = styled.span`
  font-size: 32px;
  line-height: 44px;
  font-weight: bold;
  color: ${(props) => props.theme.main.primary};
`;

const Purchase = styled.span`
  font-weight: 700;
  font-size: 18px;
  line-height: 25px;
  font-weight: 700;
  color: #00c37d;
`;

const PurchaseInfo = styled.span`
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
  text-align: center;
  padding-top: 20px;
`;
