import { SVGProps } from 'react';

export const AutoPayIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg width={12} height={12} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M.667 11.333V6.667h4.666L3.19 8.813A3.945 3.945 0 0 0 6 10a4 4 0 0 0 3.765-2.667h.012c.077-.217.134-.44.173-.666h1.341A5.334 5.334 0 0 1 6 11.333h-.007a5.294 5.294 0 0 1-3.768-1.56l-1.558 1.56Zm1.382-6H.708A5.333 5.333 0 0 1 5.997.667H6a5.293 5.293 0 0 1 3.77 1.56l1.563-1.56v4.666H6.667l2.148-2.146A3.944 3.944 0 0 0 6 2a4 4 0 0 0-3.765 2.667h-.012c-.077.216-.135.44-.173.666Z"
      fill={props.color}
    />
  </svg>
);
