import { Modal } from '@mui/material';
import Box from '@mui/material/Box';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { Button, ButtonColor } from 'lib/components';
import useGuestMode from 'lib/hooks/useGuestMode';

interface Props {
  open: boolean;
  title: string;
  loading: boolean;
  handleClose: () => void;
  deleteButtonHandler: () => void;
}

const style: any = {
  // eslint-disable-next-line @typescript-eslint/prefer-as-const
  position: 'absolute' as 'absolute',
  display: 'flex',
  flexDirection: 'column',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 250,
  bgcolor: 'background.paper',
  border: '2px solid #fff',
  borderRadius: '12px',
  outline: 0,
  padding: '0px 20px',
};

export const DeleteCardModal = ({ open, title, loading, handleClose, deleteButtonHandler }: Props) => {
  const { t: translate } = useTranslation();
  const { isGuestMode } = useGuestMode();

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      data-testid="deleteCardModal"
    >
      <Box sx={style}>
        <Title>{translate('account.payment.removePayment.title')}</Title>
        <SubTitle>
          <div
            dangerouslySetInnerHTML={{
              __html: translate('account.payment.removePayment.description', {
                title,
              }),
            }}
          />
        </SubTitle>
        <Button
          loading={loading}
          disabled={isGuestMode || loading}
          m={'20px 0px 10px 0px'}
          p={'5px'}
          onClick={deleteButtonHandler}
        >
          {translate('account.payment.removePayment.confirm')}
        </Button>
        <Button disabled={loading} onClick={handleClose} p={'5px'} color={ButtonColor.secondary}>
          {translate('account.payment.removePayment.cancel')}
        </Button>
      </Box>
    </Modal>
  );
};

const Title = styled.span`
  text-align: center;
  font-size: 18px;
  font-weight: bold;
  line-height: 24px;
  margin-bottom: 16px;
  color: ${(props) => props.theme.main.primary};
  padding-top: 20px;
`;

const SubTitle = styled.span`
  margin-bottom: 15px;
  text-align: center;
  line-height: 22px;
  font-size: 16px;
  color: ${(props) => props.theme.main.primary};
`;
