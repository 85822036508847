import useStore from 'lib/hooks/useStore';

type DateType = string | number | Date;

export const useFormatDate = () => {
  const { defaultLanguage } = useStore();

  const FormatDate = (date?: DateType, month = MONTH.short) => {
    if (date) {
      return new Date(date).toLocaleDateString(defaultLanguage, {
        month,
        day: '2-digit',
        year: 'numeric',
      });
    }
    return '';
  };

  const FormatDateTime = (date?: DateType) => {
    if (date) {
      return new Date(date).toLocaleDateString(defaultLanguage, {
        hour: '2-digit',
        minute: '2-digit',
        day: '2-digit',
        month: '2-digit',
        year: '2-digit',
      });
    }
    return '';
  };

  const FormatDateTimeNumeric = (date?: DateType) => {
    if (date) {
      return new Date(date).toLocaleDateString(defaultLanguage, {
        hour: '2-digit',
        minute: '2-digit',
        day: '2-digit',
        month: 'long',
        year: 'numeric',
      });
    }
    return '';
  };

  const FormatDateDayMonth = (date?: DateType) => {
    if (date) {
      return new Date(date).toLocaleDateString(defaultLanguage, {
        month: 'long',
        day: 'numeric',
      });
    }
    return '';
  };

  const FormatDateDay = (date?: DateType) => {
    if (date) {
      return new Date(date).toLocaleDateString(defaultLanguage, {
        day: 'numeric',
      });
    }
    return '';
  };

  return {
    FormatDate,
    FormatDateTime,
    FormatDateTimeNumeric,
    FormatDateDayMonth,
    FormatDateDay,
  };
};

export enum MONTH {
  short = 'short',
  long = 'long',
}

export const FormatTime = (date: DateType) => {
  if (date) {
    return new Date(date).toLocaleTimeString('en-US', {
      hour: '2-digit',
      minute: '2-digit',
    });
  }
  return '';
};

export const FormatPhoneNumber = (str: string) => {
  const cleaned = ('' + str)?.replace(/\D/g, '');
  const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);

  if (match) {
    return '(' + match[1] + ') ' + match[2] + '-' + match[3];
  }

  return null;
};

export const FormatAmount = (amount: number, maximumFractionDigits?: number) => {
  if (amount && typeof amount === 'number') {
    const formatter = new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
      maximumFractionDigits: maximumFractionDigits ?? 2,
    });
    return formatter.format(amount);
  }
  return '$0.00';
};

export const CapitalizeFirstLetter = (string) => {
  if (string) {
    const lowerCaseString = string.toLowerCase();
    return lowerCaseString.charAt(0).toUpperCase() + lowerCaseString.slice(1);
  }
  return '';
};

export const CapitalizeWords = (mySentence: string) => {
  if (mySentence) {
    const words = mySentence.split(' ');
    for (let i = 0; i < words.length; i++) {
      words[i] = words[i][0].toUpperCase() + words[i].substr(1) + ' ';
    }
    return words;
  }
  return mySentence;
};
