import { Loan, LoanBorrower } from 'pages/Purchases/type';

export const getSegmentEventBorrowerData = (borrower: LoanBorrower) => {
  return {
    patientId: borrower?.id,
    phone: borrower?.phone,
  };
};

export const getSegmentEventDetailsData = (borrower: LoanBorrower, loan: Loan) => {
  return {
    patientId: borrower?.id,
    phone: borrower?.phone,
    practiceId: loan?.merchant?.id,
    organizationId: loan?.merchant?.organization?.id,
    loanId: loan?.id,
    transactionAmount: loan?.amount,
    loanStatus: loan?.status,
    loanSubStatus: loan?.plans[0]?.subStatus,
    loanPlanStatus: loan?.plans[0]?.status,
    termLength: loan?.product?.term,
    apr: loan?.product?.apr,
  };
};
