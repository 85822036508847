import styled from 'styled-components';
import { Body, Heading, Icon } from '@frontend/cherry-library';
import React from 'react';

interface SliderModalProps {
  title: string;
  subtitle: string;
  color: string;
  borderColor: string;
  icon?: React.FunctionComponent<React.SVGProps<SVGSVGElement>>;
}

export const StatusCard = ({ title, subtitle, color, borderColor, icon }: SliderModalProps) => {
  return (
    <Container color={color} borderColor={borderColor}>
      <HeadingContainer>
        {icon && <Icon src={icon} />}
        <Heading level="5" text={title} />
      </HeadingContainer>
      <Body>{subtitle}</Body>
    </Container>
  );
};

const Container = styled.div<{ color: string; borderColor: string }>`
  display: flex;
  padding: 16px;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  align-self: stretch;
  border-radius: var(--borderRadius, 4px);
  margin-bottom: 24px;
  border: 1px solid ${(props) => props.borderColor};
  background: ${(props) => props.color};
  box-shadow: 0px 36px 67px 0px rgba(0, 0, 0, 0.07), 0px 13.141px 24.456px 0px rgba(0, 0, 0, 0.05),
    0px 6.38px 11.873px 0px rgba(0, 0, 0, 0.04), 0px 3.127px 5.82px 0px rgba(0, 0, 0, 0.03),
    0px 1.237px 2.301px 0px rgba(0, 0, 0, 0.02);
`;

const HeadingContainer = styled.div`
  display: flex;
  gap: 8px;
`;
