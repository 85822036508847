import { useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import styled from 'styled-components';

import { Loading } from 'lib/components';
import { useLoanList } from 'lib/hooks/useContract';
import { usePatchLoan } from 'lib/hooks/usePayment';

import { useSegment } from 'lib/hooks/useSegment';
import { NoCard, PaymentItem } from './';
import useGuestMode from 'lib/hooks/useGuestMode';
import useStore from 'lib/hooks/useStore';
import { AddNewPaymentMethod } from 'pages/Account/Payment/components';
import { Body, Button, Icon, theme } from '@frontend/cherry-library';
import { ReactComponent as PlusLogo } from 'assets/images/plus.svg';
import { SEGMENT_EVENT_NAMES } from 'lib/constans';
import { getSegmentEventDetailsData } from 'lib/utils/SegmentEventData';

export const ListPaymentMethod = ({ contents, loan, loading, setAutopayUpdated }: any) => {
  const navigate = useNavigate();
  const { t: translate } = useTranslation();
  const [fetchLoanList, { loading: loanLoading, data: loanData }] = useLoanList('FUNDED');
  const [patchLoanMethod, { loading: patchLoading, data }] = usePatchLoan();
  const { trackSegmentEvent } = useSegment();
  const { borrower, paymentMethods } = useStore();
  const [activeCardID, setActiveCardID] = useState<number | null>(null);
  const autopayStatus = useRef(loan?.autoPay);
  const [newlyAddedId, setNewlyAddedId] = useState<number | null>(null);
  const [newPaymentMethod, setNewPaymentMethod] = useState(false);
  const isPageLoading = patchLoading || loanLoading;
  const { isGuestMode } = useGuestMode();
  const segmentEventData = getSegmentEventDetailsData(borrower, loan);

  const paymentMethod = paymentMethods?.find((method) => Number(method?.id) === loan?.installmentPaymentMethodId);

  useEffect(() => {
    if (data?.patchLoan) {
      fetchLoanList();
    }
  }, [data]);

  useEffect(() => {
    if (contents) {
      const findActiveCardId = contents?.find(
        (contentData) => loan?.installmentPaymentMethodId === parseInt(contentData?.id, 10),
      );

      if (newlyAddedId) {
        setActiveCardID(newlyAddedId);
      } else if (findActiveCardId && loan?.autoPay) {
        setActiveCardID(parseInt(findActiveCardId.id, 10));
      }
    }
  }, [contents]);

  useEffect(() => {
    if (loanData?.fetchLoanList) {
      setAutopayUpdated(autopayStatus.current);
    }
  }, [loanData]);

  const bankMethodContents = () => {
    return contents?.filter((content) => content.type === 'ACH' || content.type === 'RCC');
  };

  const handleCardClick = (cardId: number) => {
    trackSegmentEvent(SEGMENT_EVENT_NAMES.PATIENT_PORTAL.SET_PAYMENT_METHOD.PAYMENT_METHOD_SELECTED, {
      ...segmentEventData,
      paymentMethodId: cardId,
    });
    setActiveCardID(cardId);
  };

  const goBackHandler = () => {
    trackSegmentEvent(SEGMENT_EVENT_NAMES.PATIENT_PORTAL.SET_PAYMENT_METHOD.BACK_ARROW_CLICKED, segmentEventData);
    navigate(-1);
  };

  const isButtonDisable = useMemo(() => {
    return activeCardID === null;
  }, [activeCardID]);

  const updateAutopayMethod = () => {
    const newPaymentMethod = paymentMethods?.find((method) => Number(method?.id) === activeCardID);
    const isAutopay = loan?.autoPay;

    trackSegmentEvent(
      isAutopay
        ? SEGMENT_EVENT_NAMES.PATIENT_PORTAL.SET_PAYMENT_METHOD.SAVE_NEW_DEFAULT_CLICKED
        : SEGMENT_EVENT_NAMES.PATIENT_PORTAL.SET_PAYMENT_METHOD.CONFIRM_ENABLE_AUTOPAY_CLICKED,
      isAutopay
        ? {
            ...segmentEventData,
            newPaymentMethodId: activeCardID,
            previousDefaultType: paymentMethod?.type,
            newDefaultType: newPaymentMethod?.type,
            previousPaymentMethodId: loan?.installmentPaymentMethodId,
          }
        : segmentEventData,
    );
    patchLoanMethod({
      variables: { input: { loanId: loan?.id, installmentPaymentMethodId: activeCardID, autoPay: true } },
    });
  };

  const addPaymentMethod = async (data) => {
    setNewlyAddedId(parseInt(data?.id, 10));
    setNewPaymentMethod(false);
  };
  const handleClose = () => {
    setNewPaymentMethod(false);
  };

  const addNewPayment = () => {
    trackSegmentEvent(
      SEGMENT_EVENT_NAMES.PATIENT_PORTAL.SET_PAYMENT_METHOD.ADD_NEW_PAYMENT_METHOD_CLICKED,
      segmentEventData,
    );
    setNewPaymentMethod(true);
  };

  if (!contents) {
    return <NoCard />;
  }

  return (
    <>
      {isPageLoading && <Loading transparent={true} />}
      {!newPaymentMethod ? (
        <>
          <ListContainer>
            {contents?.map((paymentMethod, index) => {
              if (paymentMethod?.type === 'CARD' && paymentMethod?.storedCard?.type === 'CREDIT') {
                return (
                  <PaymentItem
                    key={paymentMethod?.id}
                    data={paymentMethod}
                    loan={loan}
                    activeCardID={activeCardID}
                    handleCardClick={handleCardClick}
                    isCreditCard
                  />
                );
              }

              return (
                <PaymentItem
                  key={paymentMethod?.id}
                  data={paymentMethod}
                  loan={loan}
                  activeCardID={activeCardID}
                  handleCardClick={handleCardClick}
                />
              );
            })}

            {bankMethodContents()?.map((paymentMethod, index) => (
              <PaymentItem
                key={paymentMethod?.id}
                data={paymentMethod}
                loan={loan}
                activeCardID={activeCardID}
                handleCardClick={handleCardClick}
              />
            ))}
          </ListContainer>
          <Wrapper>
            <Icon width={20} height={20} color={theme.main.kellyGreen} src={PlusLogo} />
            <AddNewPayment fontWeight={600} color={theme.main.kellyGreen} onClick={addNewPayment}>
              {translate(`account.payment.form.info`)}
            </AddNewPayment>
          </Wrapper>

          <ButtonContainer>
            <Button
              fullWidth
              disabled={isButtonDisable || isPageLoading || isGuestMode}
              loading={isPageLoading}
              onClick={updateAutopayMethod}
            >
              {loan?.autoPay ? translate('general.save') : translate('autopay.details.activateAutopay')}
            </Button>
            <Button variant="secondary" fullWidth onClick={goBackHandler}>
              {translate('genericButton.back')}
            </Button>
          </ButtonContainer>

          {loading && <Loading transparent={true} />}
        </>
      ) : (
        <AddNewPaymentMethod
          borrower={borrower}
          handleClose={handleClose}
          addPaymentMethod={addPaymentMethod}
          connectBankAccount={addPaymentMethod}
          addDirectly={true}
        />
      )}
    </>
  );
};

const ListContainer = styled.div``;

const AddNewPayment = styled(Body)`
  color: ${theme.main.kellyGreen};
  text-decoration: underline;
  text-align: center;
  cursor: pointer;
`;

const ButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin-top: 24px;
`;

const Wrapper = styled.div`
  margin-top: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 12px;
`;
