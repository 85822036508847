import { SEGMENT_EVENT_NAMES } from 'lib/constans';
import { Approval } from 'pages/Approvals/type';
import { LoanBorrower } from 'pages/Purchases/type';

export const getApplicationDetailMetaData = (borrower: LoanBorrower, application: Approval) => {
  return {
    patientId: borrower?.id,
    phone: borrower?.phone,
    applicationId: application?.id,
    applicationStatus: application?.status,
  };
};

export const goBackSegmentEvents = (
  locationPath: string,
  segmentEventDetailsData,
  selectedApplication: Approval,
  borrower: LoanBorrower,
  trackSegmentEvent: (eventName: string, eventData?: any) => void,
) => {
  const eventMap = [
    {
      pattern: /\/portal\/\d+\/purchase/,
      eventName: SEGMENT_EVENT_NAMES.PATIENT_PORTAL.PAYMENT_PLAN_DETAILS.BACK_ARROW_CLICKED,
    },
    {
      pattern: /\/portal\/\d+\/payment-method/,
      eventName: SEGMENT_EVENT_NAMES.PATIENT_PORTAL.MAKE_A_PAYMENT_SELECT_PAYMENT_METHOD.BACK_ARROW_CLICKED,
    },
    {
      pattern: /\/portal\/\d+\/payment-type/,
      eventName: SEGMENT_EVENT_NAMES.PATIENT_PORTAL.MAKE_PAYMENT_SELECT_AMOUNT.BACK_ARROW_CLICKED,
    },
    {
      pattern: /\/portal\/\d+\/calculate-payoff/,
      eventName: SEGMENT_EVENT_NAMES.PATIENT_PORTAL.GO_BACK.CALCULATE_PAYOFF,
    },
    { pattern: /\/portal\/preview-payments/, eventName: SEGMENT_EVENT_NAMES.PATIENT_PORTAL.GO_BACK.PREVIEW_PAYMENTS },
    { pattern: /\/portal\/modify-due-date/, eventName: SEGMENT_EVENT_NAMES.PATIENT_PORTAL.GO_BACK.DUE_DATE },
    {
      pattern: /\/portal\/approvals\/\d+/,
      eventName:
        selectedApplication?.status === 'DENIED'
          ? SEGMENT_EVENT_NAMES.PATIENT_PORTAL.GO_BACK.DENIED_APPLICATION
          : SEGMENT_EVENT_NAMES.PATIENT_PORTAL.GO_BACK.APPROVAL_DETAILS,
      eventData: getApplicationDetailMetaData(borrower, selectedApplication),
    },
    { pattern: /\/portal\/use-my-balance/, eventName: SEGMENT_EVENT_NAMES.PATIENT_PORTAL.GO_BACK.USE_MY_BALANCE },
    { pattern: /\/portal\/change-location/, eventName: SEGMENT_EVENT_NAMES.PATIENT_PORTAL.GO_BACK.CHANGE_LOCATION },
    { pattern: /\/portal\/\d+\/payment-review/, eventName: SEGMENT_EVENT_NAMES.PATIENT_PORTAL.GO_BACK.PAYMENT_REVIEW },
    {
      pattern: /\/portal\/\d+\/payment-success/,
      eventName: SEGMENT_EVENT_NAMES.PATIENT_PORTAL.GO_BACK.PAYMENT_SUCCESS,
    },
    { pattern: /\/portal\/\d+\/autopay/, eventName: SEGMENT_EVENT_NAMES.PATIENT_PORTAL.GO_BACK.SET_PAYMENT_METHOD },
    { pattern: /\/portal\/extend-approval/, eventName: SEGMENT_EVENT_NAMES.PATIENT_PORTAL.GO_BACK.MORE_TIME },
  ];

  const matchedEvent = eventMap.find((entry) => entry.pattern.test(locationPath));

  if (matchedEvent) {
    trackSegmentEvent(matchedEvent.eventName, matchedEvent.eventData || segmentEventDetailsData);
  }
};
