import styled from 'styled-components';
import { theme, Body } from '@frontend/cherry-library';

interface Props {
  name: string;
  isActive: boolean;
  onClick: () => void;
}

const Tab = ({ name, isActive, onClick }: Props) => {
  return (
    <TabWrapper className={`${isActive ? 'active' : ''}`} onClick={onClick}>
      <TabText size="small" fontWeight={400} className={`${isActive ? 'active' : ''}`}>
        {name}
      </TabText>
    </TabWrapper>
  );
};

const TabWrapper = styled.div`
  padding: 16px 12px;
  width: 100%;
  background-color: ${theme.main.midnightBlue10};
  border-bottom: ${theme.main.midnightBlue20};
  border-radius: 8px 8px 0 0;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 8px;
  cursor: pointer;
  &.active {
    border-bottom: ${theme.main.midnightBlue5};
    background-color: ${theme.main.white};
    box-shadow: 0px -6px 9px 0px rgba(0, 0, 0, 0.07), 0px -1.25px 1.127px 0px rgba(0, 0, 0, 0.04);
    margin-top: 0;
  }
`;

const TabText = styled(Body)`
  letter-spacing: 0.6px;
  text-transform: uppercase;
  font-size: 12px !important;
  color: ${theme.main.midnightBlue50} !important;
  &.active {
    color: ${theme.main.midnightBlue} !important;
    font-weight: 700;
  }
`;

export default Tab;
