export enum SettlementPaymentTypes {
  Promise = 'PROMISE',
  ScheduledPayment = 'SCHEDULED_PAYMENT',
  SettlementOffer = 'SETTLEMENT_OFFER',
  PaymentPlan = 'PAYMENT_PLAN',
  ONE_TIME_PAYMENT = 'ONE_TIME_PAYMENT',
}

export enum SettlementFrequency {
  Weekly = 'WEEKLY',
  Monthly = 'MONTHLY',
  BiWeekly = 'BI_WEEKLY',
}

type InstallmentItem = {
  installmentCount: number;
  installmentAmount: number;
};

type InstallmentList = {
  [SettlementFrequency.Monthly]: InstallmentItem[];
  [SettlementFrequency.BiWeekly]: InstallmentItem[];
  [SettlementFrequency.Weekly]: InstallmentItem[];
};

export type InstallmentMap = {
  [SettlementPaymentTypes.SettlementOffer]: InstallmentList;
  [SettlementPaymentTypes.PaymentPlan]: InstallmentList;
};
