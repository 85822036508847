import { LoanPlanStatuses, LoanPlanSubStatuses, LoanStatuses, PLAN_STATUS } from 'lib/constans';
import { SortByDate, isArray } from 'lib/utils';
import { Loan } from 'pages/Purchases/type';

const isChargedOff = (plans) => {
  return (
    plans?.[0]?.status === LoanPlanStatuses.CHARGED_OFF && plans?.[0]?.subStatus === LoanPlanSubStatuses.CHARGED_OFF_SUB
  );
};

export const getChargedOffLoans = (loanList: Loan[]): Loan[] => {
  return SortByDate(loanList, 'createdAt', 'desc')?.filter(
    (a) =>
      a?.status === LoanStatuses.FUNDED &&
      a.debtSales?.status !== 'SOLD' &&
      PLAN_STATUS.includes(isArray(a.plans)?.[0]?.status) &&
      isChargedOff(a.plans),
  );
};

export const getActiveLoans = (loanList: Loan[]): Loan[] => {
  const sortedLoans = SortByDate(loanList, 'createdAt', 'desc');

  const activeLoans = sortedLoans.filter((loan) => {
    const isFunded = loan.status === LoanStatuses.FUNDED;
    const isNotSold = loan.debtSales?.status !== 'SOLD';
    const hasValidPlanStatus = PLAN_STATUS.includes(loan.plans?.[0]?.status);
    const isNotChargedOff = !isChargedOff(loan.plans);

    return isFunded && isNotSold && hasValidPlanStatus && isNotChargedOff;
  });

  return activeLoans.sort((a, b) => {
    const daysPastDueA = a.plans?.[0]?.daysPastDue || 0;
    const daysPastDueB = b.plans?.[0]?.daysPastDue || 0;
    return daysPastDueB - daysPastDueA;
  });
};

export const getClosedLoans = (loanList: Loan[]): Loan[] => {
  return SortByDate(loanList, 'createdAt', 'desc')?.filter(
    (a) =>
      isArray(a?.plans)[0]?.status === LoanPlanStatuses.BANKRUPTCY ||
      (isArray(a?.plans)[0]?.status === LoanPlanStatuses.CHARGED_OFF &&
        isArray(a?.plans)[0]?.subStatus === LoanPlanSubStatuses.DECEASED &&
        isArray(a?.plans)[0]?.subStatus === LoanPlanSubStatuses.FRAUD) ||
      isArray(a?.plans)[0]?.status === LoanPlanStatuses.CLOSED ||
      (isArray(a?.plans)[0]?.status === LoanPlanStatuses.REFUND &&
        isArray(a?.plans)[0]?.subStatus === LoanPlanSubStatuses.REFUND) ||
      isArray(a?.plans)[0]?.status === LoanPlanStatuses.SETTLED ||
      a?.status === LoanStatuses.REFUNDED,
  );
};

export const getSoldLoans = (loanList: Loan[]): Loan[] => {
  return SortByDate(loanList, 'createdAt', 'desc')?.filter(
    (a) => a.debtSales !== null && a.debtSales?.status === 'SOLD',
  );
};

export const getAwaitingLoans = (loanList: Loan[]): Loan[] => {
  return SortByDate(loanList, 'createdAt', 'desc')?.filter((a) => a?.status === LoanStatuses.AWAITING_FUNDING);
};
