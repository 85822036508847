import React from 'react';
import styled from 'styled-components';
import Slide from '@mui/material/Slide';
import Backdrop from '@mui/material/Backdrop';
import { Subtitle, Title, Button, ButtonColor, Icon } from 'lib/components';
import { theme } from 'config/theme';
import { useTranslation } from 'react-i18next';

interface HelpMeModalProps {
  show: boolean;
  hideModal: () => void;
}

export const HelpMeModal = ({ show, hideModal }: HelpMeModalProps) => {
  const { t: translate } = useTranslation();
  return (
    <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={show} onClick={hideModal}>
      <Slide direction="up" in={show} mountOnEnter={true} unmountOnExit={true}>
        <Container>
          <TitleWrapper>
            <Title m="0 0 0 auto" color={theme.main.primary}>
              {translate('menu.listItems.help')}
            </Title>
            <IconContainer>
              <Icon src={'close'} onClick={hideModal} hover={true} />
            </IconContainer>
          </TitleWrapper>
          <Subtitle color={theme.main.primary}> {translate('phoneUpdate.needHelp.text')}</Subtitle>
          <Button m="0" onClick={hideModal} color={ButtonColor.secondary}>
            {translate('common.gotIt')}
          </Button>
        </Container>
      </Slide>
    </Backdrop>
  );
};

const Container = styled.div`
  width: 90%;
  position: absolute;
  bottom: -15px;
  background-color: ${(props) => props.theme.main.white};
  color: ${(props) => props.theme.main.primary};
  z-index: 1000;
  box-shadow: 0px -36px 67px rgba(0, 0, 0, 0.07), 0px -13.14px 24.4561px rgba(0, 0, 0, 0.0482987),
    0px -6.38px 11.873px rgba(0, 0, 0, 0.0389404), 0px -3.13px 5.82036px rgba(0, 0, 0, 0.0310596),
    0px -1.24px 2.30138px rgba(0, 0, 0, 0.0217013);
  padding: 24px 24px 40px 24px;
  display: flex;
  flex-direction: column;
  gap: 24px;

  @media (min-width: ${(props) => props.theme.size.mobileXl}) {
    top: 170px;
    width: 400px;
    height: fit-content;
    border-radius: 6px;
  }
`;

const TitleWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const IconContainer = styled.div`
  margin-left: auto;
  margin-right: 0;
`;
