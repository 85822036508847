import styled from 'styled-components';
import GenericFileModal from 'pages/Documents/components/GenericFile';
import { useTranslation } from 'react-i18next';

interface ConfirmSubmissionModalProps {
  addDocument: () => void;
  submitDocuments: () => void;
  handleClose: () => void;
  requiredNumberOfDocuments: number;
  currentNumberOfDocuments: number;
  open: boolean;
}

const ConfirmSubmissionModal = ({
  requiredNumberOfDocuments,
  currentNumberOfDocuments,
  submitDocuments,
  addDocument,
  handleClose,
  open,
}: ConfirmSubmissionModalProps) => {
  const { t: translate } = useTranslation();

  return (
    <GenericFileModal
      open={open}
      title={translate('documentRequest.confirm.title')}
      buttonLeftText={translate('documentRequest.confirm.addMore')}
      onButtonLeftClick={addDocument}
      mainButtonText={translate('documentRequest.confirm.submit')}
      onMainButtonClick={submitDocuments}
      handleClose={handleClose}
      loading={false}
    >
      <Text>
        {translate('documentRequest.confirm.attachedText', {
          requiredNumberOfDocuments: requiredNumberOfDocuments,
          currentNumber: currentNumberOfDocuments,
        })}
      </Text>
      <Text>{translate('documentRequest.confirm.sure')}</Text>
    </GenericFileModal>
  );
};

const Text = styled.div`
  color: ${(props) => props.theme.main.midnightBlue};
  text-align: center;
  font-size: 14px;
  font-weight: 400;
  padding-top: 12px;
`;

export default ConfirmSubmissionModal;
