import { gql } from '@apollo/client/core';

export const GET_BORROWER_DETAIL = gql`
  query getBorrowerDetail($input: GetBorrowerDetailInput) {
    getBorrowerDetail(input: $input) {
      data {
        address {
          city
          status
          street
          zip
          state {
            name
            code
          }
        }
        language
        email
        firstName
        lastName
        phone
        id
        idChecked
        phoneStatus
      }
      code
      message
      success
    }
  }
`;
