export const getStatements = (loan) => {
  if (loan.statements.length > 1) {
    const downPayment = {
      amount: loan.downPaymentAmount,
      borrowerId: loan.borrowerId,
      createdAt: loan.statements[loan.statements.length - 1].createdAt,
      principal: null,
      principalBalance: null,
      type: 'DOWN_PAYMENT',
    };
    const newStatements = [...loan.statements];
    newStatements.splice(loan.statements.length - 1, 0, downPayment);
    return newStatements.reverse(); // .filter(statement => statement.type !== 'BALANCE');
  } else if (loan.statements.length === 1) {
    const downPayment = {
      amount: loan.downPaymentAmount,
      borrowerId: loan.borrowerId,
      createdAt: loan.statements[loan.statements.length - 1].createdAt,
      principal: null,
      principalBalance: null,
      type: 'DOWN_PAYMENT',
    };
    const newStatements = [...loan.statements];
    newStatements.splice(0, 0, downPayment);
    return newStatements.reverse(); // .filter(statement => statement.type !== 'BALANCE');
  } else {
    return [];
  }
};
