import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import styled from 'styled-components';

import { Container, Loading } from 'lib/components';
import { paymentSearch } from 'lib/graphql/searches';
import { useLoanList } from 'lib/hooks/useContract';
import { useGetPaymentMethods } from 'lib/hooks/usePayment';
import useStore from 'lib/hooks/useStore';
import { findNextDueDate, FormatAmount, isArray, useFormatDate } from 'lib/utils';

import { ListPaymentMethod, PaymentAutoDialog } from './components';
import { Body, Heading, theme, Button } from '@frontend/cherry-library';
import { AutoPay } from 'pages/Purchases/components';
import SliderModal from 'lib/components/SliderModal/SliderModal';
import { useHelpModal } from 'lib/hooks/useModal';
import { calculateAmountDue } from 'lib/utils/calculateAmountDue';
import { useSegment } from 'lib/hooks/useSegment';
import { SEGMENT_EVENT_NAMES } from 'lib/constans';
import { getSegmentEventDetailsData } from 'lib/utils/SegmentEventData';

const PaymentAuto = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const { t: translate } = useTranslation();
  const [status, setDialogStatus] = useState(false);
  const [autopayUpdated, setAutopayUpdated] = useState(false);
  const [updated, setUpdated] = useState(false);
  const { FormatDate } = useFormatDate();

  const [fetchLoanList, { loading }] = useLoanList('FUNDED');
  const [getPaymentMethods, { loading: paymentLoading }] = useGetPaymentMethods();

  const { setHeaderTitle, loanList, paymentMethods, borrower, getActiveLoan } = useStore();

  const loan = getActiveLoan(id);
  const helpModal = useHelpModal();
  const nextSchedule = findNextDueDate(loan?.schedules);
  const nextScheduleAmount = nextSchedule?.amount ?? 0;
  const dueDate = nextSchedule?.dueAt;
  const isLatePayment = loan?.status === 'LATE';
  const plans = isArray(loan?.plans)[0];
  const amountDueValue = calculateAmountDue({
    daysPastDue: plans?.daysPastDue,
    originalClosedAt: loan?.originalClosedAt,
    amountDue: plans?.amountDue,
    payOff: plans?.payOff,
  });
  const { trackPage, trackSegmentEvent } = useSegment();
  const segmentEventData = getSegmentEventDetailsData(borrower, loan);

  const dueAmount = isLatePayment ? amountDueValue : nextScheduleAmount;
  const isAutoPayActive = loan?.autoPay;

  const [modalVisibility, setModalVisibility] = useState(false);
  const hideModal = () => {
    trackSegmentEvent(
      SEGMENT_EVENT_NAMES.PATIENT_PORTAL.SET_PAYMENT_METHOD.DISMISS_DISABLE_AUTOPAY_MODAL_CLICKED,
      segmentEventData,
    );
    setModalVisibility(false);
  };

  const openModal = () => {
    trackSegmentEvent(SEGMENT_EVENT_NAMES.PATIENT_PORTAL.SET_PAYMENT_METHOD.DISABLE_AUTOPAY_CLICKED, segmentEventData);
    setModalVisibility(true);
  };

  useEffect(() => {
    trackPage(SEGMENT_EVENT_NAMES.PATIENT_PORTAL.SET_PAYMENT_METHOD.PAGE_LOAD, {
      ...segmentEventData,
      autopayEnabled: loan?.autoPay,
    });
    setHeaderTitle(translate(`paymentAuto.setPaymentMethod`));
    window.scroll(0, 0);
    return () => {
      setHeaderTitle('');
    };
  }, []);

  useEffect(() => {
    if (loanList?.length === 0) {
      fetchLoanList();
    }
  }, [fetchLoanList, loanList]);

  const fetchPaymentMethods = useCallback(() => {
    getPaymentMethods({
      variables: {
        input: {
          borrowerId: borrower?.id?.toString(),
          search: paymentSearch,
        },
      },
    });
  }, [borrower?.id, getPaymentMethods]);

  useEffect(() => {
    if (!(paymentMethods && paymentMethods?.length > 0)) {
      fetchPaymentMethods();
    }
  }, [fetchPaymentMethods, getPaymentMethods, paymentMethods]);

  const closeDisableDialog = () => {
    setDialogStatus(false);
  };

  const autopayUpdateHandler = (isUpdated: boolean) => {
    setAutopayUpdated(true);
    setUpdated(isUpdated);
    trackSegmentEvent(SEGMENT_EVENT_NAMES.PATIENT_PORTAL.SET_PAYMENT_METHOD_SUCCESS.PAGE_LOAD, segmentEventData);
  };

  const goBackHandler = () => {
    trackSegmentEvent(
      SEGMENT_EVENT_NAMES.PATIENT_PORTAL.SET_PAYMENT_METHOD_SUCCESS.NO_THANKS_CLICKED,
      segmentEventData,
    );
    navigate(`/portal/${id}/purchase`);
  };

  const goToPayment = () => {
    trackSegmentEvent(
      SEGMENT_EVENT_NAMES.PATIENT_PORTAL.SET_PAYMENT_METHOD_SUCCESS.MAKE_A_PAYMENT_CLICKED,
      segmentEventData,
    );
    navigate(`/portal/${id}/payment-method`);
  };

  const goToAccount = () => {
    navigate(`portal/account`);
  };

  const openHelpModal = () => {
    trackSegmentEvent(
      SEGMENT_EVENT_NAMES.PATIENT_PORTAL.SET_PAYMENT_METHOD.DISABLE_AUTOPAY_MODAL_CONTACT_SUPPORT_CLICKED,
      segmentEventData,
    );
    hideModal();
    setTimeout(() => {
      helpModal.openHelpModal();
    }, 100);
  };

  if (loading || paymentLoading) {
    return <Loading transparent={true} />;
  }

  return (
    <>
      <Container block={true}>
        {!updated && (
          <CenteredContainer>
            <AutoPay
              loan={loan}
              paymentMethods={paymentMethods}
              loading={paymentLoading}
              installmentPaymentMethodId={loan?.installmentPaymentMethodId?.toString()}
              isAutoPayActive={isAutoPayActive}
              manageHandler={openModal}
              short={true}
            />
          </CenteredContainer>
        )}

        <PaymentAutoDialog status={status} closeDisableDialog={closeDisableDialog} />
        <PaymentContainer>
          {!autopayUpdated && (
            <PaymentSelection>
              {!isAutoPayActive ? (
                <HeaderContainer>
                  <Heading level="4" text={translate(`paymentAuto.enablesAutoPay`)}></Heading>
                  <WrapperContainer>
                    <FlexContainer>
                      <BulletContainer>•</BulletContainer>
                      <Body color={theme.main.midnightBlue} fontWeight={400}>
                        {translate(`paymentAuto.weWillDeductYourPayment`)}
                      </Body>
                    </FlexContainer>
                    <FlexContainer>
                      <BulletContainer>•</BulletContainer>
                      <Body color={theme.main.midnightBlue} fontWeight={400}>
                        {translate(`paymentAuto.weWillNotifyYou`)}
                      </Body>
                    </FlexContainer>
                  </WrapperContainer>
                </HeaderContainer>
              ) : (
                <SelectTitle level="4" text={translate(`autoPay.selectPaymentMethod`)}></SelectTitle>
              )}
              <ListPaymentMethod
                contents={paymentMethods}
                loan={loan}
                loading={paymentLoading}
                setAutopayUpdated={autopayUpdateHandler}
              />
            </PaymentSelection>
          )}

          {autopayUpdated && (
            <PaymentSelection>
              <UpdatedTitle>
                {updated
                  ? translate('autopay.details.updated.updatedTitle')
                  : translate('autopay.details.updated.activatedTitle')}
              </UpdatedTitle>
              {updated && <UpdatedText>{translate('autopay.details.updatedSubHeader')}</UpdatedText>}
              <UpdatedText
                dangerouslySetInnerHTML={{
                  __html: translate('autopay.details.updatedNote', {
                    balance: FormatAmount(dueAmount),
                    due: FormatDate(dueDate),
                  }),
                }}
              />
              <UpdatedText>{translate('autopay.details.updated.paymentNote')}</UpdatedText>
              <ButtonContainer>
                <Button fullWidth onClick={goToPayment}>
                  {translate('contracts.active.paymentBtn')}
                </Button>
                <Button fullWidth variant="secondary" onClick={goBackHandler}>
                  {translate('autopay.details.updated.cancelButton')}
                </Button>
              </ButtonContainer>
            </PaymentSelection>
          )}
        </PaymentContainer>
        {!paymentLoading && (
          <>
            {(paymentMethods?.length === 1 && loan?.autoPay) ||
              (!paymentMethods?.length && !loan?.autoPay && (
                <Button fullWidth onClick={goToAccount}>
                  {translate('autoPay.reviewBtn')}
                </Button>
              ))}
          </>
        )}
      </Container>
      {helpModal.helpModalVisibility && helpModal.ModalBody}
      {modalVisibility && (
        <SliderModal title={translate('autoPay.details.disable')} show={modalVisibility} hideModal={hideModal}>
          <CenteredContainer>
            <Body>{translate('disableAutoPay.wouldLikeToDisable')}</Body>
          </CenteredContainer>

          <Wrapper>
            <Button fullWidth onClick={openHelpModal}>
              {translate('faq.support.title')}
            </Button>
            <Button variant="secondary" fullWidth onClick={hideModal}>
              {translate('common.dismiss')}
            </Button>
          </Wrapper>
        </SliderModal>
      )}
    </>
  );
};

const HeaderContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin-bottom: 24px;
`;

const PaymentContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
`;

const PaymentSelection = styled.div`
  margin-top: 24px;
  display: flex;
  flex-direction: column;
  border-bottom: 1px solid ${(props) => props.theme.main.cardBorderColor};
  padding: 24px;
  background: #ffffff;
  box-shadow: 0px 6px 9px rgba(0, 0, 0, 0.07), 0px 0.751293px 1.12694px rgba(0, 0, 0, 0.035);
  border-radius: 8px;
`;
const UpdatedTitle = styled.span`
  font-size: 20px;
  font-weight: 700;
  line-height: 27px;
  margin-bottom: 24px;
  color: ${(props) => props.theme.main.primary};
`;

const UpdatedText = styled.span`
  font-size: 16px;
  font-weight: 400;
  line-height: 21px;
  margin-bottom: 24px;
  color: ${(props) => props.theme.main.primary};
`;

const ButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;
`;

const BulletContainer = styled.span`
  margin: 0 5px 0 10px;
`;

const WrapperContainer = styled.div``;

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const WrapperCentered = styled(Wrapper)`
  align-items: center;
  justify-content: center;
`;

const CenteredContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
`;

const FlexContainer = styled.div`
  display: flex;
`;

const SelectTitle = styled(Heading)`
  margin-bottom: 24px !important;
`;

export default PaymentAuto;
