import React, { useEffect } from 'react';
import { Button, Icon, InnerContainer, MembershipContainer, Subtitle, Title } from 'lib/components';
import useStore from 'lib/hooks/useStore';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const SubscriptionsSuccess = () => {
  const { setPageIndex } = useStore();
  const navigate = useNavigate();
  const { t: translate } = useTranslation();

  useEffect(() => {
    setPageIndex(4);
  }, [setPageIndex]);

  const viewMemberships = () => {
    navigate('/');
  };

  return (
    <MembershipContainer showBackButton={false}>
      <InnerContainer>
        <Icon width={28} height={28} src="confetti" />
        <Title>{translate('subscriptions.success.nowActive')}</Title>
        <Subtitle>{translate('subscriptions.success.allSet')}</Subtitle>
        <Button p="15px" onClick={viewMemberships}>
          {translate('subscriptions.success.viewMembership')}
        </Button>
      </InnerContainer>
    </MembershipContainer>
  );
};

export default SubscriptionsSuccess;
