import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import dayjs from 'dayjs';
import useStore from 'lib/hooks/useStore';
import { calculateExpireDay, FormatAmount } from 'lib/utils';
import { ReactComponent as RightIcon } from 'assets/images/chevron-right.svg';
import { Approval } from 'pages/Approvals/type';
import { Body, DateFormatEnum, Heading, theme } from '@frontend/cherry-library';
import { ReactComponent as InfoIcon } from 'assets/images/info_circle_outlined.svg';
import { SEGMENT_EVENT_NAMES } from 'lib/constans';
import { useSegment } from 'lib/hooks/useSegment';
interface Props {
  approval: Approval;
  index: number;
}

export const ApprovedApplication = ({ approval, index }: Props) => {
  const { t: translate } = useTranslation();
  const { trackSegmentEvent } = useSegment();
  const navigate = useNavigate();
  const { setSelectedApplication } = useStore();
  const { organization, balanceAvailable, expireAt } = approval || {};
  const { name } = organization || {};
  const expireDate = calculateExpireDay(expireAt);
  const showRemaining = approval?.maxEligible !== approval?.balanceAvailable;

  const applicationDetailHandler = () => {
    const { id, status, balanceAvailable, organization } = approval;

    trackSegmentEvent(SEGMENT_EVENT_NAMES.PATIENT_PORTAL.APPLICATIONS_OVERVIEW.APPLICATION_CLICKED, {
      applicationId: id,
      applicationStatus: status,
      approvalBalance: balanceAvailable,
      organizationId: organization?.id,
    });

    setSelectedApplication(approval);
    navigate(`/portal/approvals/${approval.id}`);
  };

  return (
    <>
      {index !== 0 && <Seperator />}
      <ApplicationsContainer onClick={applicationDetailHandler}>
        <PaymentDetails>
          <Heading
            level="4"
            text={
              showRemaining
                ? translate('paymentPlans.active.remaining', {
                    amount: FormatAmount(balanceAvailable),
                  })
                : FormatAmount(balanceAvailable)
            }
          ></Heading>
          <Body fontWeight={400} size="default">
            {name}
          </Body>
          <Body
            fontWeight={expireDate > 7 ? 400 : 700}
            size="small"
            color={expireDate > 7 ? theme.main.midnightBlue : theme.main.cherryRed}
          >
            {translate(
              calculateExpireDay(expireAt) > 7
                ? 'approvalsPage.approved.expires'
                : 'approvalsPage.approved.expiresSoon',
              {
                date: dayjs(expireAt).format(DateFormatEnum.MMM_DD_YYYY),
                day: calculateExpireDay(expireAt),
              },
            )}
          </Body>
          <Uppercase size="small" fontWeight={600}>
            {translate('general.applicationId')}: {approval?.displayId}
          </Uppercase>
          {showRemaining ? (
            <RemainingBalanceContainer>
              <InfoIcon data-testid="into-icon" width={'16px'} height={'16px'} />
              <ItalicBody fontWeight={400} size="small">
                {translate('approvalsPage.approved.remainingBalance')}
              </ItalicBody>
            </RemainingBalanceContainer>
          ) : null}
        </PaymentDetails>
        <IconContainer>
          <RightIcon data-testid="application-select" width={'24px'} height={'24px'} />
        </IconContainer>
      </ApplicationsContainer>
    </>
  );
};

const PaymentDetails = styled.div`
  display: flex;
  align-items: left;
  align-self: stretch;
  flex-direction: column;
  min-width: 60%;
  padding-bottom: 16px;
`;

const Seperator = styled.div`
  background: ${theme.main.midnightBlue20};
  height: 1px;
  width: 100%;
  margin: 0 0 16px 0;
`;
const ApplicationsContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
  justify-content: space-between;
  cursor: pointer;
`;

const IconContainer = styled.div`
  cursor: pointer;
  height: 24px;
  width: 24px;
`;

const ItalicBody = styled(Body)`
  font-style: italic !important;
`;
const RemainingBalanceContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding-top: 8px;
  gap: 4px;
`;

const Uppercase = styled(Body)`
  text-transform: uppercase;
`;
