import { AnalyticEventNames, useAnalytics } from 'lib/hooks/useAnalytics';
import { useEffect, useRef, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import styled from 'styled-components';

import { PLAN_STATUS } from 'lib/constans';
import useStore from 'lib/hooks/useStore';
import { hasAchOrDebitCard, isArray, SortByDate } from 'lib/utils';
import { AddNewPaymentMethod, FinalizePaymentMethod, ListPaymentMethod } from './components';

const Payment = ({ borrower, contents }) => {
  const { trackEvent } = useAnalytics();
  const location = useLocation();
  const navigate = useNavigate();
  const bankHandler = useRef(true);

  const { paymentMethods, loanList } = useStore();
  const [isAddingNewPaymentMethod, setIsAddingNewPaymentMethod] = useState(false);
  const [isNewPaymentMethodAdded, setIsNewPaymentMethodAdded] = useState(false);
  const [newlyAddedPayment, setNewlyAddedPayment] = useState({});

  const handleClose = () => setIsAddingNewPaymentMethod(false);

  useEffect(() => {
    if (paymentMethods && location?.search.includes('oauth=success') && bankHandler.current) {
      bankHandler.current = false;
      const achMethods = SortByDate(paymentMethods, 'createdAt')?.filter((it) => it.type === 'ACH');

      const newlyAddedPM = achMethods[achMethods?.length - 1];
      connectBankHandler(newlyAddedPM);
    }
  }, [paymentMethods]);

  const activeLoanList = loanList?.filter((loan) => PLAN_STATUS.includes(isArray(loan?.plans)[0]?.status));
  const autoPayLoans = loanList.filter((item) => item?.autoPay);

  const addNewPaymentMethod = () => {
    trackEvent(AnalyticEventNames.ACC_NPM_INFORMATION);
    setIsAddingNewPaymentMethod(true);
  };

  const addPaymentMethod = async (data) => {
    // Control it for Autopay
    setNewlyAddedPayment(data);

    if (activeLoanList?.length && autoPayLoans?.length) {
      if (data?.storedCard?.type === 'CREDIT') {
        const filteredPaymentMethods = hasAchOrDebitCard(paymentMethods);
        if (filteredPaymentMethods.length) {
          handleCancel();
        } else {
          setIsNewPaymentMethodAdded(true);
        }
      } else {
        setIsNewPaymentMethodAdded(true);
      }
    } else {
      handleCancel();
    }
  };

  const connectBankHandler = (data) => {
    if (activeLoanList.length > 0) {
      setIsNewPaymentMethodAdded(true);
      setIsAddingNewPaymentMethod(true);
      setNewlyAddedPayment(data);
    } else {
      handleCancel();
    }
  };

  const handleCancel = () => {
    setIsAddingNewPaymentMethod(false);
    setIsNewPaymentMethodAdded(false);
    bankHandler.current = true;
    navigate('/portal/account');
  };

  return (
    <Container>
      {!isAddingNewPaymentMethod ? (
        <ListPaymentMethod borrower={borrower} contents={contents} addNewPaymentMethod={addNewPaymentMethod} />
      ) : !isNewPaymentMethodAdded ? (
        <AddNewPaymentMethod
          borrower={borrower}
          handleClose={handleClose}
          addPaymentMethod={addPaymentMethod}
          connectBankAccount={connectBankHandler}
          addDirectly={false}
        />
      ) : (
        <FinalizePaymentMethod newlyAddedPayment={newlyAddedPayment} handleCancel={handleCancel} />
      )}
    </Container>
  );
};

const Container = styled.div`
  margin-top: 24px;
  margin-bottom: 56px;
  padding: 16px;
  display: flex;
  flex-direction: column;
  background-color: white;
  border-radius: 8px;
  box-shadow: 0px 6px 9px rgba(0, 0, 0, 0.07), 0px 0.751293px 1.12694px rgba(0, 0, 0, 0.035);
`;

export default Payment;
