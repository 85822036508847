import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { useFormatDate } from 'lib/utils';
import { Contents } from '../type';
import { Heading, Body } from '@frontend/cherry-library';

import { ReactComponent as DownloadIcon } from 'assets/images/download.svg';
import { theme } from 'config/theme';

interface Props {
  contents: Array<Contents>;
  getAgreementFile: (name: string, id: string) => void;
  type: string;
}

enum DocumentType {
  ADVERSE_ACTION_LETTER = 'ADVERSE_ACTION_LETTER',
  PAY_OFF_ESTIMATION = 'PAY_OFF_ESTIMATION',
  AGREEMENT = 'AGREEMENT',
  CREDIT_SCORE_DISCLOSURE = 'CREDIT_SCORE_DISCLOSURE',
  AGREEMENT_PIF = 'AGREEMENT_PIF',
  AGREEMENT_SIF = 'AGREEMENT_SIF',
  IDENTITY_VERIFICATION = 'IDENTITY_VERIFICATION',
  PAYROLL = 'PAYROLL',
  PAYSTUB = 'PAYSTUB',
  LOAN_STATEMENT = 'LOAN_STATEMENT',
  STATEMENT = 'STATEMENT',
}

const Table = ({ contents, getAgreementFile, type }: Props) => {
  const { t: translate } = useTranslation();
  const { FormatDateTimeNumeric } = useFormatDate();

  const getNameOfDocument = (type: string) => {
    switch (type) {
      case DocumentType.ADVERSE_ACTION_LETTER:
        return translate('documents.table.type.adverseActionLetter');
      case DocumentType.AGREEMENT_PIF:
        return translate('documents.table.type.paidInFullLetter');
      case DocumentType.AGREEMENT_SIF:
        return translate('documents.table.type.settledInFullLetter');
      case DocumentType.IDENTITY_VERIFICATION:
        return translate('documents.table.type.identityVerificationDocument');
      case DocumentType.PAYROLL:
      case DocumentType.PAYSTUB:
        return translate('documents.table.type.incomeVerificationDocument');
      case DocumentType.PAY_OFF_ESTIMATION:
        return translate('documents.table.type.payoffEstimate');
      case DocumentType.CREDIT_SCORE_DISCLOSURE:
        return translate('documents.table.type.creditScoreDisclosure');
      case DocumentType.AGREEMENT:
        return translate('documents.table.type.agreement');
      case DocumentType.STATEMENT:
      case DocumentType.LOAN_STATEMENT:
        return translate('documents.table.type.statement');
      default:
        return translate('documents.table.type.document');
    }
  };

  return contents.length === 0 ? (
    <NoDocumentContainer>
      <Body color={theme.main.primary} fontWeight={400}>
        {type === 'disclosures' && translate('files.noDisclosures')}
        {type === 'statements' && translate('files.noLoanStatements')}
      </Body>
    </NoDocumentContainer>
  ) : (
    <TableContainer data-testid="fileContainer">
      {contents.map((content: any, index: number) => (
        <div key={index}>
          <TableRow data-testid="fileItem" onClick={() => getAgreementFile(content.name, content.id)}>
            <TableRowInfoColumn>
              <Body fontWeight={400} color={theme.main.midnightBlueGray}>
                {FormatDateTimeNumeric(content.createdAt)}
              </Body>
              <Name level="5" text={getNameOfDocument(content.type)} />
            </TableRowInfoColumn>
            <CherryDownloadIcon data-testid="download-document" />
          </TableRow>
          <Seperator />
        </div>
      ))}
    </TableContainer>
  );
};

const TableContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

const TableRow = styled.div`
  display: flex;
  padding: 8px;
  align-items: center;
  gap: 16px;
  align-self: stretch;
  margin-bottom: 16px;
  cursor: pointer;
`;

const TableRowInfoColumn = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  flex: 1 0 0;
`;

const Name = styled(Heading)`
  margin: 0;
  align-self: stretch;
  color: ${(props) => props.theme.main.textColor};
`;

const CherryDownloadIcon = styled(DownloadIcon)`
  cursor: pointer;
  width: 24px;
  height: 24px;
`;

const Seperator = styled.div`
  background: ${(props) => props.theme.main.lightGrayishBlue};
  height: 1px;
`;

const NoDocumentContainer = styled.div`
  height: 128px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export default Table;
