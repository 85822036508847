import { findNextDueDate, isArray } from 'lib/utils';
import { dueAmountIsGreaterThanEnteredAmount, dueAmountIsLessThanEnteredAmount } from 'lib/utils/TxInfoPayloadBuilder';
import { calculateAmountDue } from 'lib/utils/calculateAmountDue';

export const BuildTxInfoPayload = (amount, loan, setPaymentInfo, t, FormatDate) => {
  const { schedules, plans } = loan;
  const { amountDue, status, daysPastDue, payOff } = isArray(plans)[0] || {};

  const amountDueValue = calculateAmountDue({
    daysPastDue,
    originalClosedAt: loan?.originalClosedAt,
    amountDue,
    payOff,
  });

  const dueType = status === 'LATE' ? 'late' : 'next';
  const nextSchedule = findNextDueDate(schedules);
  const nextScheduleAmount = nextSchedule?.amount ?? 0;
  const dueDate = nextSchedule?.dueAt;
  const isLatePayment = dueType === 'late';
  const dueAmount = isLatePayment ? amountDueValue : nextScheduleAmount;

  const enteredAmount = amount ? parseFloat(amount) : 0;
  const txInfo = { dueAmount: 0, remainder: 0, extraForInstallment: 0, extraForPrincipal: 0 };

  const dueAmountTranslationKey = `makePayment.paymentType.form.payOtherAmount.subText.${
    dueType === 'next' ? 'nextInstallmentPayment' : 'dueInstallmentPayment'
  }`;

  const translationArray: Array<{ key: string; value: string }> = [];

  if (dueAmount > enteredAmount) {
    dueAmountIsGreaterThanEnteredAmount({
      txInfo,
      enteredAmount,
      translationArray,
      dueAmountTranslationKey,
      isLatePayment,
      dueAmount,
    });
  } else {
    dueAmountIsLessThanEnteredAmount({
      txInfo,
      dueAmount,
      isLatePayment,
      enteredAmount,
      dueDate,
      nextScheduleAmount,
      translationArray,
      dueAmountTranslationKey,
      FormatDate,
    });
  }

  setPaymentInfo(txInfo);
};
