import { useAlert } from 'react-alert';
import { useTranslation } from 'react-i18next';
import { useLazyQuery, useMutation } from '@apollo/client';
import { CREATE_CHARGED_OFF_OFFERS } from 'lib/graphql/mutations';
import { GET_CHARGED_OFF_OFFERS } from 'lib/graphql/queries';

export const useGetChargedOffOffers = () => {
  const alert = useAlert();
  const { t } = useTranslation();

  return useLazyQuery(GET_CHARGED_OFF_OFFERS, {
    fetchPolicy: 'network-only',
    onError: (error) => alert.error(t(`error.${error}`)),
  });
};

export const useCreateChargedOffOffer = () => {
  const alert = useAlert();
  const { t } = useTranslation();

  return useMutation(CREATE_CHARGED_OFF_OFFERS, {
    fetchPolicy: 'network-only',
    onError: (error) => alert.error(t(`error.${error}`)),
  });
};
