import { useLazyQuery, useMutation } from '@apollo/client';
import { useAlert } from 'react-alert';
import { useTranslation } from 'react-i18next';

import { ADD_PAYMENT_METHOD, DELETE_PAYMENT_METHOD, MAKE_PAYMENT, PATCH_LOAN_METHOD } from 'lib/graphql/mutations';
import { GET_PAYMENT_METHODS, GET_PAYMENT_METHOD_FEES } from 'lib/graphql/queries';
import useStore from 'lib/hooks/useStore';

export const useGetPaymentMethods = () => {
  const alert = useAlert();
  const { t } = useTranslation();
  const { setPaymentMethods } = useStore();

  return useLazyQuery(GET_PAYMENT_METHODS, {
    fetchPolicy: 'network-only',
    onCompleted: ({ getPaymentMethods }) => setPaymentMethods(getPaymentMethods?.contents || []),
    onError: (error) => alert.error(t(`error.${error}`)),
  });
};

export const useAddPayment = () => {
  const alert = useAlert();
  const { t } = useTranslation();

  return useMutation(ADD_PAYMENT_METHOD, {
    fetchPolicy: 'network-only',
    onError: (error) => alert.error(t(`error.${error}`)),
  });
};

export const useDeletePayment = () => {
  const alert = useAlert();
  const { t } = useTranslation();

  return useMutation(DELETE_PAYMENT_METHOD, {
    fetchPolicy: 'network-only',
    onError: (error) => alert.error(t(`error.${error}`)),
  });
};

export const usePatchLoan = () => {
  const alert = useAlert();
  const { t } = useTranslation();

  return useMutation(PATCH_LOAN_METHOD, {
    fetchPolicy: 'network-only',
    onError: (error) => alert.error(t(`error.${error}`)),
  });
};

export const usePayments = () => {
  const alert = useAlert();
  const { t } = useTranslation();

  return useMutation(MAKE_PAYMENT, {
    fetchPolicy: 'network-only',
    onError: (error) => alert.error(t(`error.${error}`)),
  });
};

export const usePaymentMethodFee = () => {
  const alert = useAlert();
  const { t } = useTranslation();
  const { setPaymentMethodFee } = useStore();

  return useLazyQuery(GET_PAYMENT_METHOD_FEES, {
    fetchPolicy: 'network-only',
    onCompleted: ({ getPaymentFees }) => setPaymentMethodFee(getPaymentFees || {}),
    onError: (error) => alert.error(t(`error.${error}`)),
  });
};
