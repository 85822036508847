import { useEffect, useMemo } from 'react';
import dayjs from 'dayjs';
import styled from 'styled-components';
import { Icon, Subtitle, Button, ButtonColor, DataLoader } from 'lib/components';
import { useTranslation } from 'react-i18next';
import { useGetServicingStatusHistory } from 'lib/hooks/useLoan';
import { theme } from 'config/theme';

type ChargeOffBannerProps = {
  planId: string;
  handlePaymentButtonClick?: () => void;
};

export const ChargeOffBanner = ({ planId, handlePaymentButtonClick }: ChargeOffBannerProps) => {
  const { t } = useTranslation();
  const [getServicingStatusHistory, { data: servicingStatusHistory, loading }] = useGetServicingStatusHistory(
    Number(planId),
  );

  useEffect(() => {
    if (planId) {
      getServicingStatusHistory();
    }
  }, [planId]);

  const chargeOffStatusChangeDate = useMemo(
    () =>
      servicingStatusHistory?.getServicingStatusHistory?.find((history) => history.status === 'CHARGED_OFF')?.createdAt,
    [servicingStatusHistory?.getServicingStatusHistory],
  );

  const title = `${t('chargeOffBanner.title')} - ${dayjs(chargeOffStatusChangeDate).format('MMM, DD YYYY')}`;

  return (
    <Container>
      <Content>
        <div>
          <DataLoader loading={loading} backgroundColor={theme.main.primary} opacity={0.3}>
            <TitleWrapper>
              <Icon width={24} height={24} src="warning_outline_black" />
              <Title>{title}</Title>
            </TitleWrapper>
          </DataLoader>
          <DataLoader loading={loading} backgroundColor={theme.main.primary} opacity={0.3}>
            <Description dangerouslySetInnerHTML={{ __html: t('chargeOffBanner.description') }} />
          </DataLoader>
        </div>
      </Content>
      {handlePaymentButtonClick && !loading && (
        <ButtonContainer>
          <Button type="button" m={'0px'} color={ButtonColor.dark} onClick={handlePaymentButtonClick}>
            {t('contracts.active.paymentBtn')}
          </Button>
        </ButtonContainer>
      )}
    </Container>
  );
};

const Container = styled.div`
  padding: 16px;
  border-radius: 4px;
  background-color: ${(props) => props.theme.main.lightYellow};
  border: 1px solid ${(props) => props.theme.main.guestModeColor};
`;

const TitleWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  margin-bottom: 10px;
`;

const Title = styled(Subtitle)`
  color: ${(props) => props.theme.main.primary};
  font-weight: 700;
`;

const Description = styled.p`
  font-size: 14px;
  font-weight: 400;
  color: ${(props) => props.theme.main.primary};
  margin: 0;
`;

const Content = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`;

const ButtonContainer = styled.div`
  margin-top: 10px;
`;
