import { OperatorEnum } from 'lib/types';

export const fullSearch = (borrowerId: number) => {
  return [
    {
      key: 'relationType',
      value: 'BORROWER',
      operator: OperatorEnum.EQUAL,
    },
    {
      key: 'type',
      value: 'AGREEMENT_PIF,AGREEMENT_SIF',
      operator: OperatorEnum.IN,
    },
    {
      key: 'relationId',
      value: borrowerId,
      operator: OperatorEnum.EQUAL,
    },
  ];
};
