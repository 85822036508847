import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { Container } from 'lib/components';
import { paymentSearch } from 'lib/graphql/searches';
import { useGetPaymentMethods } from 'lib/hooks/usePayment';
import useStore from 'lib/hooks/useStore';

import { SegmentPageNames, useSegment } from 'lib/hooks/useSegment';
import Contact from './Contact';
import Payment from './Payment';
import { Heading } from '@frontend/cherry-library';

const Account = () => {
  const { t: translate } = useTranslation();
  const { borrower, paymentMethods, setHeaderTitle } = useStore();

  const [getPaymentMethods] = useGetPaymentMethods();
  const { trackPage } = useSegment();

  useEffect(() => {
    trackPage(SegmentPageNames.MY_ACCOUNT, { borrowerId: borrower?.id?.toString() });
  }, []);

  useEffect(() => {
    setHeaderTitle('menu.listItems.account');
  }, [setHeaderTitle]);

  useEffect(() => {
    getPaymentMethods({
      variables: {
        input: {
          borrowerId: borrower?.id?.toString(),
          search: paymentSearch,
        },
      },
    });
  }, [borrower?.idBorrower || borrower?.id, getPaymentMethods]);

  return (
    <Container block={true}>
      <CardContainer>
        <Heading level="4" text={translate('account.contact.header')} data-testid="accountTitle" />
        <Contact borrower={borrower} />
      </CardContainer>

      <CardContainer>
        <Heading level="4" data-testid="paymentTitle" text={translate('account.payment.header')} />
        <Payment borrower={borrower} contents={paymentMethods} />
      </CardContainer>
    </Container>
  );
};

const CardContainer = styled.div``;

export default Account;
