interface CalculateAmountDueParams {
  daysPastDue: number;
  originalClosedAt: string;
  amountDue: number;
  payOff: number;
}

export function calculateAmountDue({
  daysPastDue,
  originalClosedAt,
  amountDue,
  payOff,
}: CalculateAmountDueParams): number {
  const amountDueValue =
    daysPastDue > 0 && originalClosedAt && new Date() > new Date(originalClosedAt) && amountDue === 0
      ? payOff
      : amountDue;

  return amountDueValue;
}
