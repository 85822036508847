import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import dayjs from 'dayjs';
import { PREQUAL_URL } from 'config';
import { Button } from 'lib/components';
import { ExternalRedirect } from 'lib/utils/ExternalRedirect';
import { Body, DateFormatEnum, Heading, Label, theme } from '@frontend/cherry-library';
import { Approval } from '../type';
import { FormatAmount } from 'lib/utils';
import { SEGMENT_EVENT_NAMES } from 'lib/constans';
import { useSegment } from 'lib/hooks/useSegment';

interface Props {
  approval: Approval;
  index: number;
}

export const ExpiredApplication = ({ approval, index }: Props) => {
  const { t: translate } = useTranslation();
  const { trackSegmentEvent } = useSegment();
  const { organization, expireAt } = approval || {};
  const { slug, name } = organization || {};

  const checkoutHandler = () => {
    const { id, status, balanceAvailable, organization } = approval;

    trackSegmentEvent(SEGMENT_EVENT_NAMES.PATIENT_PORTAL.APPLICATIONS_OVERVIEW.REAPPLY_CLICKED, {
      applicationId: id,
      applicationStatus: status,
      approvalBalance: balanceAvailable,
      organizationId: organization?.id,
    });

    ExternalRedirect(`${PREQUAL_URL}/${slug}`);
  };

  return (
    <>
      {index !== 0 && <Seperator />}
      <ApplicationsContainer>
        <CardDetails>
          <Label fontWeight={600} size="large" color={theme.main.cherryRed}>
            {translate('applicationsPage.inactive.expiredText', {
              date: dayjs(expireAt).format(DateFormatEnum.MMM_DD_YYYY),
            }).toUpperCase()}
          </Label>
          <StyledHeading level="4" text={FormatAmount(approval?.balanceAvailable)}></StyledHeading>
          <Body fontWeight={400} size="default" color={theme.main.midnightBlue20}>
            {name}
          </Body>
          <Uppercase size="small" fontWeight={600}>
            {translate('general.applicationId')}: {approval?.displayId}
          </Uppercase>
          <Body fontWeight={400} size="small">
            {translate('applicationsPage.inactive.reApplyText')}
          </Body>
        </CardDetails>
        <Button onClick={checkoutHandler}>{translate('approvals.completed.card.reapply.buttonText')}</Button>
      </ApplicationsContainer>
    </>
  );
};

const StyledHeading = styled(Heading)`
  color: ${theme.main.midnightBlue20}!important;
`;

const ApplicationsContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 8px;
`;

const CardDetails = styled.div`
  display: flex;
  align-items: left;
  align-self: stretch;
  flex-direction: column;
  min-width: 60%;
`;

const Seperator = styled.div`
  background: ${theme.main.midnightBlue20};
  height: 1px;
  width: 100%;
  margin: 0 0 16px 0;
`;

const Uppercase = styled(Body)`
  text-transform: uppercase;
`;
