import React, { FunctionComponent, SVGProps } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { FINDER_URL, WEB_SITE } from 'config';
import { Icon as CherryIcon, Heading } from '@frontend/cherry-library';
import { theme } from 'config/theme';
import { useTranslation } from 'react-i18next';
import useStore from 'lib/hooks/useStore';
import { FormatAmount, calculateExpireDay } from 'lib/utils';
import { useSegment } from 'lib/hooks/useSegment';
import { applicationStatus } from 'pages/Approvals/type';

export type NavigationCardProps = {
  title: string;
  pageName: string;
  icon: FunctionComponent<SVGProps<SVGSVGElement>>;
  eventName: string;
  trackId: string;
};

type Application = {
  status: string;
  expireAt: Date;
};

const NavigationCard = ({ icon, title, pageName, eventName, trackId }: NavigationCardProps) => {
  const navigate = useNavigate();
  const { trackSegmentEvent } = useSegment();
  const { t: translate } = useTranslation();
  const { applicationList, loanList, borrower } = useStore();

  const approvalsList =
    applicationList?.filter((application: Application) => {
      if (!application) return false;

      const isStatusApproved = applicationStatus?.includes(application?.status);
      const isNotExpired = calculateExpireDay(application?.expireAt) > 0;

      return isStatusApproved && isNotExpired;
    }) ?? [];

  const showBalanceAvailable = trackId === 'loans' && approvalsList.length > 0;

  const navigateToPages = () => {
    trackSegmentEvent(eventName, {
      borrowerId: borrower?.id,
      phone: borrower?.phone,
    });

    if (pageName === 'externalLink') {
      window?.open(`${WEB_SITE}/patient-faq/`, '_blank');
    } else if (trackId === 'loans' && approvalsList.length === 0) {
      window?.open(FINDER_URL, '_blank');
    } else {
      navigate(pageName);
    }
  };

  const getCardTitle = () => {
    if (trackId === 'loans' && approvalsList.length > 0) {
      return `${approvalsList.length} ${
        approvalsList.length === 1 ? translate('general.approval.singular') : translate('menu.listItems.approvals')
      }`;
    } else if (trackId === 'purchases' && loanList.length > 0) {
      return `${loanList.length} ${
        loanList.length === 1 ? translate('homePage.button.paymentPlan') : translate('menu.listItems.loans')
      }`;
    } else {
      return translate(title);
    }
  };

  const getCardIcon = () => {
    const balanceAvailable = approvalsList.reduce((total, approval) => {
      return total + approval?.balanceAvailable;
    }, 0);

    return FormatAmount(balanceAvailable);
  };

  return (
    <CardContainer onClick={navigateToPages}>
      {showBalanceAvailable ? (
        <BalanceHeading text={getCardIcon()} level={'3'} />
      ) : (
        <CherryIcon src={icon} color={theme.main.green} width={'32px'} height={'32px'} />
      )}

      <CardTitle>{getCardTitle()}</CardTitle>
    </CardContainer>
  );
};
const CardContainer = styled.div`
  display: flex;
  padding: 16px 8px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 4px;
  flex: 1 0 0;
  border-radius: 16px;
  border: 0px solid var(--blue-main, #0e202f);
  background: var(--system-neutral, #fff);
  cursor: pointer;
  align-self: stretch;

  font-size: 16px;
  /* Shadow/med */
  box-shadow: 0px 36px 67px 0px rgba(0, 0, 0, 0.07), 0px 13.141px 24.456px 0px rgba(0, 0, 0, 0.05),
    0px 6.38px 11.873px 0px rgba(0, 0, 0, 0.04), 0px 3.127px 5.82px 0px rgba(0, 0, 0, 0.03),
    0px 1.237px 2.301px 0px rgba(0, 0, 0, 0.02);
  @media (min-width: ${(props) => props.theme.size.mobile}) {
    min-width: 200px;
    min-height: 88px;
    font-size: 16px;
    white-space: nowrap;
  }

  @media (max-width: ${(props) => props.theme.size.mobile}) {
    min-width: 136px;
    min-height: 55px;
    font-size: 14px;
    text-align: center;
  }
`;

const CardTitle = styled.div`
  font-style: normal;
  font-weight: 600;
  line-height: normal;
`;
const BalanceHeading = styled(Heading)`
  color: ${(props) => props.theme.main.green} !important;
`;

export default NavigationCard;
